import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Formik } from "formik";
import Modal from "./../../../Component/Kit/Modal";
import { PageTitleLayout, MainContent } from "../../../Component/Kit/Layout";
import { StyledButton } from "../../../Component/Kit/Button";
import { StyledInput, StyledForm } from "../../../Component/Kit/Form";
import { EarnMethod } from "../../../Utility/Services/lines";
import UserDetailsModal from "./user-details-modal";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { toast } from 'react-toastify';
import Swal from "sweetalert2";

function PendingLineDetails({ lineDetails, history }) {
    const [pendingLine, setpendingLine] = useState([]);
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const [userDetailsModal, setuserDetailsModal] = useState(false);

    const userModalHandler = () => {
        setuserDetailsModal(true);
    };
    const activePendingLine = (item) => {
        // activePendingLine
        Swal.fire({
            title: "",
            text: "آیااز ثبت خط مطمئن هستید؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Put(Urls.activePendingLine(pendingLine.id), item)
                    .then((res) => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        history.push("/line/user-lines/pending");
                    })
            }
        });
    };
    useEffect(() => {
        if (Array.isArray(lineDetails)) {
            if (!lineDetails.length) {
                history.push("/line/user-lines/pending");
            }
        } else {
            if (lineDetails.hasOwnProperty("data")) {
                lineDetails.data.earnMethodName = EarnMethod(
                    lineDetails.data.earnMethod
                );
                setpendingLine(lineDetails.data);
            } else {
                history.push("/line/user-lines/pending");
            }
        }
    }, [history, lineDetails]);
    return (
        <>
            <MainContent>
                <div className="col-12 col-lg-12">
                    <PageTitleLayout>
                        <h5>جزئیات خطوط منتظر تایید</h5>
                        <span>شما می‌توانید جزئیات خطوط منتظر تایید را مشاهده و اصلاح نمایید.</span>
                    </PageTitleLayout>
                </div>
                <hr className="mt-4 mx-3" />

                <Formik
                    initialValues={{
                        userName: pendingLine.userName,
                        createdAt: pendingLine.createdAt,
                        lineNumber: pendingLine.number,
                        earnMethodName: pendingLine.earnMethodName,
                        lineLength: pendingLine.lineLengthName,
                        providerName: pendingLine.providerName,
                        type: pendingLine.type,
                    }}
                    validationSchema={Yup.object().shape({
                        lineNumber: Yup.string()
                            .required("این فیلد الزامی است.").nullable()
                            .matches(phoneRegExp, "لطفا فقط عدد وارد کنید")
                            .label("lineNumber")
                            .min(pendingLine.lineLength, "تعداد کاراکتر صحیح نیست")
                            .max(pendingLine.lineLength, "تعداد کاراکتر صحیح نیست"),
                    })}
                    isInitialValid={false}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        let body = {
                            number: parseInt(values.lineNumber),
                            status: 3,
                        };
                        activePendingLine(body);
                    }}
                >
                    {(formik) => (
                        <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <StyledInput
                                        lable="نام کاربری"
                                        focus={true}
                                        error="false"
                                        readOnly
                                        type="text"
                                        value={formik.values.userName}
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <StyledInput
                                        lable="تاریخ ایجاد"
                                        focus={true}
                                        error="false"
                                        readOnly
                                        type="text"
                                        value={formik.values.createdAt}
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <StyledInput
                                        lable="شماره خط"
                                        focus={true}
                                        type="text"
                                        value={formik.values.lineNumber}
                                        error={(formik.errors.lineNumber && formik.touched.lineNumber)
                                            ? formik.errors.lineNumber
                                            : 'false'
                                        }
                                        onChange={(e) =>
                                            formik.setFieldValue("lineNumber", e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <StyledInput
                                        lable="نوع خط"
                                        focus={true}
                                        error="false"
                                        readOnly
                                        type="text"
                                        className="text-right"
                                        value={formik.values.earnMethodName}
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <StyledInput
                                        lable="طول خط"
                                        focus={true}
                                        error="false"
                                        readOnly
                                        type="text"
                                        className="text-right"
                                        value={formik.values.lineLength}
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <StyledInput
                                        lable="ارائه دهنده خدمات"
                                        focus={true}
                                        error="false"
                                        readOnly
                                        type="text"
                                        className="text-right"
                                        value={formik.values.providerName}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <StyledInput
                                        lable="نوع خط"
                                        focus={true}
                                        error="false"
                                        readOnly
                                        className="text-right"
                                        type="text"
                                        value={formik.values.type}
                                    />
                                </div>
                            </div>

                            <div className="text-left">
                                <StyledButton
                                    btnStyle="greenBtn ml-2"
                                    type="button"
                                    onClick={() => userModalHandler()}
                                >
                                    نمایش اطلاعات کاربر
                                </StyledButton>
                                <StyledButton
                                    btnStyle="greenBtn"
                                    disabled={formik.errors.lineNumber !== undefined}
                                    type="submit"
                                >
                                    ثبت خط
                                </StyledButton>
                            </div>
                        </StyledForm>
                    )}
                </Formik>
            </MainContent>
            <Modal
                onClose={() => setuserDetailsModal(false)}
                visible={userDetailsModal}
                onCancel={() => setuserDetailsModal(false)}
                headerTitle="اطلاعات کاربر"
            >
                <UserDetailsModal userName={pendingLine.userName} onCloseModal={(e) => setuserDetailsModal(e)} />

            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({
    lineDetails: state.pendingLines,
});
export default connect(mapStateToProps)(PendingLineDetails);
