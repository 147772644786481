import React from "react";

function PendingGuestForApproveIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={63.148}
      height={52.184}
      {...props}
    >
      <g data-name="Group 152129">
        <path
          data-name="Icon awesome-user-check"
          d="M22.83 26.092A13.046 13.046 0 1 0 9.784 13.046 13.045 13.045 0 0 0 22.83 26.092Zm9.132 3.261h-1.7a17.742 17.742 0 0 1-14.86 0H13.7A13.7 13.7 0 0 0 0 43.051v4.24a4.893 4.893 0 0 0 4.892 4.892h35.876a4.893 4.893 0 0 0 4.892-4.892v-4.24a13.7 13.7 0 0 0-13.698-13.698Z"
          fill="#3f3d56"
        />
        <path
          data-name="Icon awesome-user-check"
          d="m62.801 14.154-2.833-2.867a1.209 1.209 0 0 0-1.712-.01l-10.681 10.6-4.637-4.668a1.209 1.209 0 0 0-1.712-.01l-2.864 2.844a1.209 1.209 0 0 0-.01 1.712l8.327 8.388a1.209 1.209 0 0 0 1.712.01l14.4-14.289a1.218 1.218 0 0 0 .01-1.712Z"
          fill="#a7abc3"
        />
      </g>
    </svg>
  );
}

export default PendingGuestForApproveIcon;
