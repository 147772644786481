import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StyledButton } from "../../../../../Component/Kit/Button";
import { StyledForm, StyledSelect } from "../../../../../Component/Kit/Form";
import Modal from "../../../../../Component/Kit/Modal";
import NumberList from "../../../../../Component/Shared/NumberList";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";
import ProviderEnum from "../../../../../Utility/Model/provider-enum.model";
import { AddLinesToPlansSchema } from "../../../../../Utility/Validation/Schema/AddLinesToPlansSchema";

const AddLinesModal = ({ visible, closeHandler, confirmHandler }) => {
  const [numbersList, setNumbersList] = useState();
  const [providersList, setProvidersList] = useState();
  const numberListHandler = (e) => {
    setNumbersList(e);
  };

  useEffect(() => {
    setProvidersList((prevState) => [
      { name: "انتخاب کنید...", value: 0 },
      ...ProviderEnum,
    ]);
  }, []);
  return (
    <Modal
      onClose={closeHandler}
      visible={visible}
      onCancel={closeHandler}
      headerTitle="افزودن خط به لیست خطوط پلن ها"
    >
      <Formik
        initialValues={{
          providerId: "",
          linesList: "",
        }}
        validationSchema={AddLinesToPlansSchema}
        enableReinitialize={false}
        onSubmit={(values, actions) => {
          const body = {
            providerId: values.providerId,
            numbers: [...numbersList.validArray],
          };
          ApiClient.Post(Urls.plansLines(), body).then((res) => {
            toast.success('خطوط جدید با موفقیت ثبت شدند.');
            confirmHandler();
          });
        }}
      >
        {(formik) => (
          <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
            <StyledSelect
              lable="انتخاب اپراتور"
              focus={true}
              name="providerId"
              value={
                formik.values.providerId !== undefined
                  ? formik.values.providerId
                  : formik.initialValues.providerId
              }
              optionItems={providersList}
              error={
                formik.errors.providerId && formik.touched.providerId
                  ? formik.errors.providerId
                  : "false"
              }
              onChange={(e) =>
                formik.setFieldValue("providerId", e.target.value)
              }
            />

            <NumberList
              defaultValue={formik.values.linesList}
              numberListHandler={(e) => numberListHandler(e)}
              onChange={(e) => formik.setFieldValue("linesList", e)}
              name="linesList"
              id="linesList"
              error={
                formik.errors.linesList && formik.touched.linesList
                  ? formik.errors.linesList
                  : "false"
              }
              lable="لیست خطوط"
            />

            <div className="d-flex justify-content-between w-100">
              <StyledButton
                width="35%"
                btnStyle="grayBtn"
                type="reset"
                onClick={() => {
                  closeHandler(false);
                }}
              >
                بستن
              </StyledButton>
              <StyledButton
                width="35%"
                btnStyle="greenBtn"
                disabled={!formik.isValid}
                type="submit"
              >
                انجام عملیات
              </StyledButton>
            </div>
          </StyledForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddLinesModal;
