import { connect, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HeaderContent, MainContent, WrapperLayout } from '../../../Component/Kit/Layout';
import Table from '../../../Component/Shared/Table/Table';
import { AdminDetailAction } from '../../../Actions/AdminDetail'
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { StyledButton } from '../../../Component/Kit/Button';
import { IdentityRoleService } from '../../../Utility/Services/IdentityRoleService';
import TicketDepartment from '../../../Utility/Services/TicketDepartmentService';

export const AdminsList = ({ history }) => {
    const [adminsListItem, setAdminsListItem] = useState({});
    const dispatch = useDispatch();
    const currentAdminUser = useSelector(state => state.bootstrapper.data);
    const tableConfig = {
        header: {
            fullName: "نام و نام خانوادگی",
            userName: "نام کاربری",
            isSuspendedIcon: "وضعیت بلاک بودن",
            roleName: "رول ها",
            department2: "دپارتمان",
            mobile: "موبایل",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
                condition: () => {
                    return (currentAdminUser?.roleId == 1);
                },
            },
        },
    }

    const tableActions = (items) => {
        // let adminDetails = adminsListItem.data.find((adminItem) => adminItem.adminId === items.adminId);
        dispatch(AdminDetailAction(items.data));
        history.push(`/admins/view/${items.data.adminId}`);
    }

    const clickHandler = () => {
        history.push('/admins/addUserAdmin')
    }

    useEffect(() => {
        ApiClient.Get(Urls.AdminsManagment())
            .then(res => {
                let resData = res.result;
                resData.map((item) => {
                    let roleName = [];
                    if (item.department !== 0)
                        item.department2 = TicketDepartment(item.department);

                    item.isSuspendedIcon = item.isSuspended
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;

                    item.roleIds.forEach(
                        (value, index) => roleName[index] = IdentityRoleService(parseInt(value))
                    );
                    item.roleName = roleName.join()
                    return item;
                })
                setAdminsListItem(resData)
            })
    }, [currentAdminUser])

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                {currentAdminUser?.roleId == 1 &&
                    <HeaderContent>
                        <StyledButton btnStyle="greenBtn" onClick={clickHandler}>
                            ایجاد کاربر ادمین
                        </StyledButton>
                    </HeaderContent>
                }

                <MainContent>

                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <h5 className="mb-3"> لیست ادمین‌های پنل مدیریتی</h5>
                            <span>
                                مدیریت و ایجاد ادمین‌ها از این صفحه امکان‌پذیر است.
                            </span>

                            <Table
                                data={adminsListItem}
                                config={tableConfig}
                                actionDispatched={tableActions}
                            />
                        </div>
                    </div>
                </MainContent>
            </div>
        </WrapperLayout>
    )
}

export default connect()(AdminsList)
