import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { StyledButton } from '../../../../Component/Kit/Button'
import { PageTitleLayout } from '../../../../Component/Kit/Layout'
import Modal from '../../../../Component/Kit/Modal'
import { Loader } from '../../../../Component/Shared/Loader'
import Table from '../../../../Component/Shared/Table/Table'
import ApiClient from '../../../../Requester/ApiClient'
import Urls from '../../../../Requester/Urls'
import { ConvertQueryString } from '../../../../Utility/Services/convert-query-string'
import DateConvert from '../../../../Utility/Services/date-convert'
import AddBlockIPModal from '../AddBlockedIP'

const BlockedIPList = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [blockedIPLog, setBlockedIPLog] = useState({});
    const [showModal, setShowModal] = useState(false);

    const tableConfig = {
        header: {
            blockedIP: "IP مسدود شده",
            description: 'توضیحات',
            isDeleted: 'حذف شده',
            createdAt: "تاریخ مسدود شدن",
        },
        actions: {
            delete: {
                icon: "fa fa-trash",
            }
        },
        mode: ["search", "filter"],
        filters: ["datePickerFilter"]
    }

    const tableActions = (items) => {
        if (items.actionName === 'delete') {
            deleteBlockedIP(items.data.id);
        }
    }

    const deleteBlockedIP = (itemID) => {
        Swal.fire({
            title: '',
            text: `آیا از حذف این IP مسدود شده مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.BlockedIPLog(itemID))
                    .then(() => {
                        toast.success('IP با موفقیت حذف گردید.');
                        getBlockedIPLogList();
                    })
            }
        })
    }

    const getBlockedIPLogList = useCallback(() => {
        ApiClient.Get(Urls.BlockedIPLog(), ConvertQueryString(location.search))
            .then(res => {
                let dataItem = res.result;
                dataItem.items.map((item) => {
                    item.createdAt = DateConvert(item.createdAt);
                    item.isDeleted = item.isDeleted
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    return item;
                })
                setBlockedIPLog(dataItem)
                setLoading(false);
            })
    }, [location.search])

    useEffect(() => {
        getBlockedIPLogList();
    }, [location.search, getBlockedIPLogList])

    const modalDataChange = (e) => {
        if (e) {
            getBlockedIPLogList();
        }
    }

    return (
        <>
            <div className="col-12 col-lg-12">
                <div className="d-flex align-items-center justify-content-between">
                    <PageTitleLayout className="mt-3">
                        <h5>لیست IP های مسدود شده </h5>
                        <span>در این بخش همه IP های مسدود شده  قابل مشاهده است.</span>
                    </PageTitleLayout>
                    <StyledButton
                        btnStyle="greenBtn"
                        onClick={() => setShowModal(true)}
                    >
                        IP افزودن
                    </StyledButton>
                </div>

                <div>
                    {loading === true
                        ?
                        <Loader />
                        :
                        <Table
                            data={blockedIPLog.items}
                            config={tableConfig}
                            actionDispatched={tableActions}
                            pageConfig={{
                                pageNumber: blockedIPLog.pageNumber,
                                pageSize: blockedIPLog.pageSize,
                                totalItemsCount: blockedIPLog.totalItemsCount,
                                totalPagesCount: blockedIPLog.totalPagesCount,
                            }}
                        />
                    }
                </div>
            </div>
            <Modal
                visible={showModal}
                onClose={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                headerTitle="مسدود کردن IP"
            >
                <AddBlockIPModal
                    onCloseModal={(e) => setShowModal(e)}
                    dataChange={(e) => modalDataChange(e)}
                />

            </Modal>
        </>
    )
}

export default BlockedIPList