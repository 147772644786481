import React, {useEffect, useState} from "react";
import {StyledForm, StyledSelect} from "../../../../Component/Kit/Form";
import {StyledButton} from "../../../../Component/Kit/Button";
import {useFormik} from "formik";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import {toast} from "react-toastify";
import * as Yup from "yup";

const PublicLineEditModalSchema = Yup.object().shape({
    selectedPublicLine: Yup.string()
        .required('این فیلد اجباری است.'),
});

function EditDefaultLineModal({onCloseModal, onSubmit}) {
    const [optionList, setOptionList] = useState([]);

    const formik = useFormik({
        initialValues: {
            selectedPublicLine: ""
        },
        validationSchema: PublicLineEditModalSchema,
        onSubmit: (values, actions) => {
            console.log(values)
            ApiClient.Put(Urls.publicSetDefaultLineById(values.selectedPublicLine))
                .then((res) => {
                    toast.success('عملیات با موفقیت انجام شد');
                    onSubmit()
                }).catch().finally(() => {
            })
        }
    });

    useEffect(() => {
        ApiClient.Get(Urls.publicLines()).then(res => {
            let data = res.result;
            setOptionList(data.publicLines.map(item => {
                return {value: item['id'], name: item['number']}
            }))
        })
    }, []);


    return (
        <StyledForm
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}>
            <StyledSelect
                formGroupClass=" col-12 px-0"
                lable="لیست خطوط ویژه"
                focus={true}
                hasDefaultOption
                name="selectedPublicLine"
                value={formik.values.selectedPublicLine}
                optionItems={optionList}
                error={(formik.errors.selectedPublicLine && formik.touched.selectedPublicLine)
                    ? formik.errors.selectedPublicLine
                    : 'false'
                }
                onChange={(e) => {
                    formik.setFieldValue("selectedPublicLine", e.target.value);
                }}
            />
            <div className="d-flex justify-content-between">
                <StyledButton
                    width="35%"
                    btnStyle="grayBtn"
                    type="reset"
                    onClick={() => {
                        onCloseModal(false)
                    }}
                >
                    بستن
                </StyledButton>
                <StyledButton
                    width="35%"
                    disabled={!formik.isValid}
                    btnStyle="greenBtn"
                    type="submit"
                >
                    تایید
                </StyledButton>
            </div>
        </StyledForm>
    );
}

export default EditDefaultLineModal
