import React, { useState } from "react";
import { Formik } from "formik";
import { StyledForm, StyledTextArea } from "../../../Component/Kit/Form";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { UserCommentSchema } from "../../../Utility/Validation/Schema/UserCommentSchema";
import { StyledButton } from "../../../Component/Kit/Button";
import Swal from "sweetalert2";
import { useRouteMatch } from "react-router-dom";
import { toast } from 'react-toastify';

function AddCommentModal({ dataChange, onCloseModal }) {
    const [submitLoading, setsubmitLoading] = useState(false);
    let match = useRouteMatch();
    const commentHandler = (body) => {
        setsubmitLoading(true);
        Swal.fire({
            title: `آیا از ایجاد این نظر مطمئن هستید؟`,
            text: "بعد از ثبت نظر شما قابل حذف نیست! ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Post(Urls.userComments(match.params.id), body)
                    .then(res => {
                        setsubmitLoading(false);
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        dataChange(true);
                    })
            }
        })

    };
    return (
        <>
            <Formik
                initialValues={{
                    commentDescription: "",
                }}
                validationSchema={UserCommentSchema}
                // isInitialValid={false}
                onSubmit={(values, actions) => {
                    commentHandler(JSON.stringify(values.commentDescription));
                    // values.commentDescription = ''
                    actions.resetForm();
                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false)
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}>
                        <StyledTextArea
                            lable="توضیحات"
                            focus={true}
                            name="commentDescription"
                            value={formik.values.commentDescription}
                            error={(formik.errors.commentDescription && formik.touched.commentDescription)
                                ? formik.errors.commentDescription
                                : 'false'
                            }
                            onChange={(e) => {
                                const str = e.target.value.trim() !== '' ? e.target.value : ''
                                formik.setFieldValue("commentDescription", str)
                            }}
                            onReset={(values, formikBag) => {
                                onCloseModal(false)
                            }}
                        />

                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={submitLoading ? 1 : 0}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                اضافه کردن کامنت
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    );
}

export default AddCommentModal;
