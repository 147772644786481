import styled from "styled-components";
import Col from "../../Component/Kit/Column";


export const HeaderLayout = styled.header`
  display: ${props => props.hasToken === false && 'none'};
  background-color: ${props => props.theme.primary};
  /* display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 60px; */
  /* position:fixed;
  z-index: 10;
  top:0px;
  flex-direction : row; */
`;

export const StyledTopBarListItems = styled.ul`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  margin-bottom: 0;
  list-style: none;
  align-items: center;
  outline: none !important;
  margin-right: auto;
  height: 100%;
`;
export const StyledTopBarItem = styled.li`
  border-right: 1px solid #e9eaea;
  padding: 0 10px;
`;

export const StyleDrowpDown = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  svg {
    margin-top: 5px;
    width: 8px;
    height: 8px;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.primary};
    text-align: center;
    display: block;
    line-height: 50px;
  }
`;

export const ProfileImage = styled(Col)`
  background-image: ${(props) => `url(${props.src})`};
  /* background-size: cover; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: .5rem;

  svg {
    vertical-align: middle;
    width: 35px;
    height: 35px;
      /* fill:${props => props.theme.white}; */
  }
`;

export const UserName = styled.span`
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

export const StyleTime = styled.span`
  font-size: 13px;
  color: white;
  padding-right: 15px;
  height: 65px;
  line-height: 65px;
`;

export const StyledTooltip = styled.div`
  box-shadow: 0 0 11px #ccc;
  background-color: white;
  position: absolute;
  width: 100%;
  min-height: 50px;
  margin-left: 0px;
  min-width: 9.4rem;
  padding: 0.5rem;
  z-index: 1;
  top: 42px;

  .d-item {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    white-space: nowrap;
    margin: 0;
    font-size: 12px;
    text-align: right;
    padding: .25rem .25rem;
    line-height: 1.3rem;
    color: inherit;
    -webkit-transition: all 0.3s 0s ease-in-out;
    -moz-transition: all 0.3s 0s ease-in-out;
    -ms-transition: all 0.3s 0s ease-in-out;
    -o-transition: all 0.3s 0s ease-in-out;
    transition: all 0.3s 0s ease-in-out;

    &:hover {
      color: ${props => props.theme.secondary};
    }
  }
`;

export const StyledWrapperSvg = styled.span`
  cursor: pointer;
`;
export const StyleLeftHeader = styled(Col)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;

  svg {
    margin-top: 5px;
    width: 8px;
    height: 8px;
  }
`;
/* export const StyleRightHeader = styled(Col)`
    height: 100%;
    justify-content:flex-start;
    padding-right : 15px;
    svg {
        width : 45px;
        height : 45px;
    }
`; */

/* export const StyledLogo = styled.div`
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  float: right;
  margin-right: 20px;
  border-radius: 50%;
  margin-top: 15px;
`; */

/* export const Styleda = styled.a`
text-decoration: none;
cursor: pointer;
color: ${props => props.theme.primary};
text-align: center;
display: block;
line-height: 50px;
`; */
