import React, { useEffect, useState } from "react";
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from "../../../Component/Kit/Layout";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { TowerOperatorList, TowerStatusList } from "../../../Utility/Model/send-tower.model";
import { StyledButton } from "../../../Component/Kit/Button";
import { RejectedRequestTower } from "./RejectModal";
import { AcceptRequestTower } from "./AcceptModal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DateConvert from "../../../Utility/Services/date-convert";
import BackToPage from "../../../Component/Kit/back";



export function SendTowerSingle() {
    const [request, setRequest] = useState({});
    const [rejectModal, setRejectModal] = useState(false);
    const [acceptModal, setAcceptModal] = useState(false);
    const location = useLocation();
    const param = useParams()
    const history = useHistory()


    useEffect(() => {
        ApiClient.Get(Urls.getSingleSendTower(param.id))
            .then(res => {
                const data = res.result;
                data.totalPrice = res.result.totalPrice?.toLocaleString('fa-IR')
                data.operatorName = TowerOperatorList.find(a => a.value == res.result.operatorId)?.name
                data.stateName = TowerStatusList.find(a => a.value == res.result.state)?.name
                data.basePrice = res.result.basePrice?.toLocaleString('fa-IR')
                data.netPrice = res.result.netPrice?.toLocaleString('fa-IR')
                data.fifteenRemarkPrice = res.result.fifteenRemarkPrice?.toLocaleString('fa-IR')
                data.vatPrice = res.result.vatPrice?.toLocaleString('fa-IR')
                setRequest(data)
            })
    }, [location.search])


    const rejectHandler = () => {
        console.log('ssss')
        setRejectModal(true);
    }


    const acceptPayment = () => {
        Swal.fire({
            title: `آیا از تایید ارسال به اپراتور مطمعن هستید ؟`,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Patch(Urls.sendTowerPaid(param.id)).then(
                    (res) => {
                        toast.success("فرآیند با موفقیت انجام گرفت!");
                        setRequest({ ...request, state: 6 })
                    }
                );
            }
        })
    };


    const acceptReadyToSend = () => {
        Swal.fire({
            title: `آیا از تایید اتمام درخواست مطمعن هستید؟`,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Patch(Urls.sendTowerReady(param.id)).then(
                    (res) => {
                        toast.success("فرآیند با موفقیت انجام گرفت!");
                        history.push('/send-tower/pending')
                    }
                );
            }
        })
    }
    return (
        <WrapperLayout>
            <div className="c-wrapper">
                {Object.keys(request).length > 0 &&
                    <>
                        <HeaderContent className="justify-content-between">
                            <div>
                                {request.state === 1 && (
                                    <>
                                        <StyledButton btnStyle="redBtn mr-2" onClick={rejectHandler}>
                                            <span>عدم تایید</span>
                                        </StyledButton>
                                        <StyledButton btnStyle="greenBtn mr-2" onClick={() => setAcceptModal(true)}>
                                            <span>تایید</span>
                                        </StyledButton>
                                    </>
                                )}
                                {request.state === 5 && (
                                    <>
                                        <StyledButton btnStyle="greenBtn mr-2" onClick={acceptPayment}>
                                            <span>تایید ارسال به اپراتور</span>
                                        </StyledButton>
                                    </>
                                )}
                                {request.state === 6 && (
                                    <>
                                        <StyledButton btnStyle="greenBtn mr-2" onClick={acceptReadyToSend}>
                                            <span>تایید اتمام درخواست</span>
                                        </StyledButton>
                                    </>
                                )}
                            </div>
                            <BackToPage />
                        </HeaderContent>

                        <MainContent>
                            <PageTitleLayout>
                                <h5> ارسال از دکل</h5>
                            </PageTitleLayout>
                            <div className="row">
                                <ul className="params col-lg-6  col-12  px-4">
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>متن پیام</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.messageText}
                                            </span>
                                        </div>
                                    </li>

                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>نام کاربری درخواست کننده</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.userFullName}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>توضیحات عدم تایید</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.rejectDescription ? request.rejectDescription : '- - - - -'}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>اپراتور</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.operatorName}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>وضعیت</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.stateName}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>تعداد مخاطبین</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.recipientsCount}
                                            </span>

                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>تعداد پیامک </span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.messageCount}
                                            </span>
                                        </div>
                                    </li>

                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>تعداد کل پیامک ها</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.totalMessageCount}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>زمان اولین ارسال</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {DateConvert(request.firstSendAt)}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="params col-lg-6  col-12 px-4">
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>فایل آپلود شده</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                <a href={request.fileUrl}>دانلود فایل</a>
                                            </span>

                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span> قیمت پایه</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.basePrice}&nbsp;ریال
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>قیمت اصلی پکیج</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.netPrice}&nbsp;ریال
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>لایحه 15</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.fifteenRemarkPrice}&nbsp;ریال
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>مالیات بر ارزش افزوده</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.vatPrice}&nbsp;ریال
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>مبلغ کل پرداختی</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {request.totalPrice}&nbsp;ریال
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>تاریخ ایجاد</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {DateConvert(request.createdAt)}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>آخرین بروزرسانی</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {DateConvert(request.modifiedAt)}
                                            </span>
                                        </div>
                                    </li>

                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>زمان انقضا</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {DateConvert(request.expireAt)}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </MainContent>

                        <AcceptRequestTower id={param.id}
                            visible={acceptModal}
                            setVisible={setAcceptModal}
                            onCancel={() => false}
                            onClose={() => setAcceptModal(false)} />

                        <RejectedRequestTower id={param.id}
                            visible={rejectModal}
                            setVisible={setRejectModal}
                            onCancel={() => false}
                            onClose={() => setRejectModal(false)}
                        />
                    </>
                }

            </div>
        </WrapperLayout>
    )
}