import { PureComponent } from 'react'

class WindowsDimensions extends PureComponent{
    devicesWidth = {
        mobile: { min: '0', max: '767' },
        tablet: { min: '768', max: '991' },
        desktop: { min: '992' }
    };

    initScreenStatus = {
        width: window.innerWidth,
        deviceMode: 'mobile',
        sidebarMode: 'hidden'
    };

    CheckDeviceType = (width) => {
        let device;
        if (width <= this.devicesWidth.mobile.max) {
            device = 'mobile';
        } else if (width >= this.devicesWidth.tablet.min && width <= this.devicesWidth.tablet.max) {
            device = 'tablet';
        } else {
            device = 'desktop';
        }
        // console.log("device", device);
        // console.log("width", width);

        this.initScreenStatus.width      = width;
        this.initScreenStatus.deviceMode = device;
        return device;
    }

    CheckSideBarMode = (deviceMode) => {
        let sidebarMode;
        switch (deviceMode) {
            case 'mobile':
                sidebarMode = 'hidden';
                break;
            case 'tablet':
                sidebarMode = 'slim';
                break;
            case 'desktop':
                sidebarMode = 'default';
                break;
            default:
                sidebarMode = 'full';
        }
        this.initScreenStatus.sidebarMode = sidebarMode;
        return sidebarMode
    }

    resize = ()=>{
        this.CheckDeviceType(window.innerWidth);
        this.CheckSideBarMode(this.initScreenStatus.deviceMode)

        return (this.initScreenStatus )
    }
}

export default new WindowsDimensions();
