export const UserLegalDataReducer = (state = {}, action) => {
  switch (action.type) {
    case "USER_LEGAL_DATA":
      return { ...state, ...action.data };

    default:
      return state;
  }
};
export default UserLegalDataReducer;
