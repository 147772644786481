import React, {useContext, useEffect, useRef, useState} from "react"
import {useOnClickOutside} from "../../Utility/method/useOnClickOutside";
import {
    HeaderLayout,
    StyleDrowpDown,
    StyledTooltip,
    StyledTopBarListItems,
    StyledTopBarItem,
    ProfileImage,
    UserName,
    StyledWrapperSvg
} from "./styles";
import ArrowIcon from "./ArrowIcon";
import {Link, useHistory} from "react-router-dom";
import LocalToken from "../../Utility/LocalStorge/LocalToken";
import AppContext from "../../Context/AppContext";
import {connect} from "react-redux";
import userIcon from "../../Assets/images/user.jpg";

const Header = (props) => {
    const {screenStatus, setScreenStatus, adminProfile} = props;
    const [dropDownExpand, setDropDownExpand] = useState(false);
    const hasToken = useContext(AppContext).hasToken;
    const setHasToken = useContext(AppContext).setHasToken;
    const ref = useRef();
    const history = useHistory();


    const logOut = () => {
        LocalToken.Remove();
        history.push("/");
        setHasToken(false)
    };

    const onClick = () => setDropDownExpand(!dropDownExpand)
    useOnClickOutside(ref, () => setDropDownExpand(false));

    // call SignalR Connection
    // SignalRServices();

    useEffect(() => {

    }, [screenStatus, hasToken])


    const changeSidebarState = (e) => {
        e.preventDefault();
        switch (screenStatus.deviceMode) {
            case 'mobile':
                if (!screenStatus.sidebarMode || screenStatus.sidebarMode === 'hidden') {
                    setScreenStatus({...screenStatus, sidebarMode: 'full'})

                } else if (screenStatus.sidebarMode === 'full') {
                    setScreenStatus({...screenStatus, sidebarMode: 'hidden'})
                }
                break;

            case 'tablet':
                if (!screenStatus.sidebarMode || screenStatus.sidebarMode === 'slim') {
                    setScreenStatus({...screenStatus, sidebarMode: 'default'})
                } else if (screenStatus.sidebarMode === 'default') {
                    setScreenStatus({...screenStatus, sidebarMode: 'slim'})
                }
                break;

            case 'desktop':
                if (!screenStatus.sidebarMode || screenStatus.sidebarMode === 'default') {
                    setScreenStatus({...screenStatus, sidebarMode: 'slim'})

                } else if (screenStatus.sidebarMode === 'slim') {
                    setScreenStatus({...screenStatus, sidebarMode: 'default'})
                }
                break;
            default:
                break;
        }
    }

    return (
        <HeaderLayout className="panel-container__header">
            <nav className={`main-header navbar navbar-expand navbar-white navbar-light  
                          ${screenStatus.sidebarMode === 'slim' ? 'slim-sidebar' : '' ||
            screenStatus.sidebarMode === 'hidden' ? 'hidden-sidebar' : '' ||
            screenStatus.sidebarMode === 'default' ? 'desktop-sidebar' : ''} `}>

                <div className="navbar-nav p-0">
                    <button onClick={e => changeSidebarState(e)} className="toggle-menu nav-link" data-widget="pushmenu">
                        <i className="fas fa-bars"></i>
                    </button>
                </div>

                <StyledTopBarListItems>
                    <StyledTopBarItem>
                        <StyleDrowpDown
                            onClick={onClick}
                            ref={ref}
                        >
                            {
                                adminProfile &&
                                <>
                                    <ProfileImage
                                        src={(adminProfile.profileImageURL) ? adminProfile.profileImageURL : userIcon}/>
                                    <UserName>
                                        {adminProfile.firstName}&nbsp;{adminProfile.lastName}
                                    </UserName>
                                    <StyledWrapperSvg>
                                        <ArrowIcon/>
                                    </StyledWrapperSvg>
                                </>
                            }
                            {
                                dropDownExpand === true &&
                                <StyledTooltip>
                                    <Link to="/profile" className="d-item">
                                        <i className="fa fa-user yellow-text"></i> پروفایل
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <p className="d-item" onClick={() => logOut()}>
                                        <i className="fa fa-power-off yellow-text"></i> خروج
                                    </p>
                                </StyledTooltip>
                            }
                        </StyleDrowpDown>
                    </StyledTopBarItem>
                </StyledTopBarListItems>
            </nav>

        </HeaderLayout>
    )
}

function mapStateToProps(state) {
    const {bootstrapper} = state
    return {adminProfile: bootstrapper.data}
}

export default connect(mapStateToProps)(Header)
