import React from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import ApiClient from "../../../../Requester/ApiClient";
import { toast } from "react-toastify";
import { StyledForm, StyledCheckBox } from "../../../../Component/Kit/Form";
import { StyledButton } from "../../../../Component/Kit/Button";
import { settingModal } from "../../../../Actions/setting-modal";
import { connect, useDispatch } from "react-redux"
import Urls from "../../../../Requester/Urls";

function ReactionToUserTicketModal({ defaultData, onCloseModal }) {
    let dispatch = useDispatch();
    const returnDefaultData = (values) => {
        values.RealtimeNotification = !!defaultData.includes(1) ? 1 : 0;
        values.SendEmail = !!defaultData.includes(2) ? 2 : 0;
        values.SendSMS = !!defaultData.includes(4) ? 4 : 0;
    }
    return (
        <>
            <Formik
                initialValues={{
                    RealtimeNotification: !!defaultData.includes(1) ? 1 : 0,
                    SendEmail: !!defaultData.includes(2) ? 2 : 0,
                    SendSMS: !!defaultData.includes(4) ? 4 : 0,
                }}
                isInitialValid={false}
                enableReinitialize={true}
                onSubmit={(values) => {
                    let body = 0;
                    for (const item of Object.keys(values)) {
                        body = body + values[item]
                    }
                    Swal.fire({
                        title: `آیا مطمئن هستید؟`,
                        text: "",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonText: "انصراف",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "بله، مطمئن هستم",
                    }).then(result => {
                        if (result.isConfirmed) {
                            ApiClient.Patch(Urls.reactionToUserTicket(), body).then(() => {
                                toast.success('تغییرات  با موفقیت ثبت شد.');
                                dispatch(settingModal(true));
                            })
                        }
                        returnDefaultData(values)
                    })
                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false)
                }}
            >
                {(formik) => (
                    <StyledForm onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <StyledCheckBox
                            lable="نمایش اعلان در داشبورد"
                            focus={true}
                            name="RealtimeNotification "
                            id="RealtimeNotification"
                            checked={formik.values.RealtimeNotification === 1}
                            onChange={(e) => formik.setFieldValue("RealtimeNotification", e.target.checked ? 1 : 0)}
                            disabled={true}
                        />
                        <StyledCheckBox
                            lable="ارسال ایمیل"
                            focus={true}
                            name="SendEmail"
                            id="SendEmail"
                            checked={formik.values.SendEmail === 2}
                            onChange={(e) => formik.setFieldValue("SendEmail", e.target.checked ? 2 : 0)}
                        />
                        <StyledCheckBox
                            lable="ارسال پیامک"
                            focus={true}
                            name="SendSMS"
                            id="SendSMS"
                            checked={formik.values.SendSMS === 4}
                            onChange={(e) => formik.setFieldValue("SendSMS", e.target.checked ? 4 : 0)}
                        />
                        <div className="d-flex justify-content-between mt-3">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false);
                                    returnDefaultData(formik.values)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                تایید
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    )
}

export default connect()(ReactionToUserTicketModal);
