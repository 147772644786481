
const SendSmsLimitationType = [
    { value: 1, name: "RecipientLimitationCountInPerSendSms" , },
    { value: 2, name: "LinkUrlLimitationInSmsContent" },
    { value: 4, name: "ForbiddenWords" },
    { value: 8, name: "NewUser" },
    { value: 16, name: "FrequentMessageText" },
    { value: 32, name: "CalledWebServiceCount" },
];

export default SendSmsLimitationType;
