import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { StyledButton } from "../../../../Component/Kit/Button";
import { PageTitleLayout } from "../../../../Component/Kit/Layout";
import Table from "../../../../Component/Shared/Table/Table";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { ConvertQueryString } from "../../../../Utility/Services/convert-query-string";
import GetProvider from "../../../../Utility/Services/provider";
import AddLinesModal from "./AddLinesModal";
import DateConvert from "../../../../Utility/Services/date-convert";

const PlansLineList = () => {
  const location = useLocation();
  const [linesList, setLinesList] = useState();
  const [lineCount, setLineCount] = useState([]);
  const [isAddLine, setIsAddLine] = useState(false);
  const tableConfig = {
    header: {
      number: "شماره خط",
      providerName: "نام اپراتور",
      isFreeIcon: "آزاد بودن خط",
      userName: "اختصاص خط  به",
      assignedAt: "تاریخ اختصاص خط",
    },
    actions: {
      delete: {
        icon: "fa fa-trash",
        condition: (data) => {
          return data.isFree;
        },
      },
    },
    mode: ["filter", "search"],
    filters: ["onlyFree"],
  };

  const onInit = useCallback(() => {
    ApiClient.Get(Urls.plansLines(), ConvertQueryString(location.search)).then(
      (res) => {
        let data = res.result;
        data.items.map((m) => {
          m.providerName = GetProvider(m.providerId);
          m.isFreeIcon = m.userName
            ? `<i class="fa fa-times-circle text-danger fa-lg"/>`
            : `<i class="fa fa-check-circle text-success fa-lg"/>`;
          m.assignedAt = DateConvert(m.assignedAt) || '- - -';
          m.userName = m.userName || '- - -';
          return m;
        });
        setLinesList(res.result);
      }
    );
    if(!location.search.includes('needCount')){
      ApiClient.Get(Urls.plansLinesCount(), ConvertQueryString(location.search)).then(
          (res) => {
            setLineCount(res.result)
          }
      );
    }

  }, [location.search]);

  const removeLine = (item) => {
    if (item.actionName === "delete") {
      Swal.fire({
        title: "",
        text: `آیا از حذف این خط مطمئن هستید؟`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonText: "انصراف",
        cancelButtonColor: "#d33",
        confirmButtonText: "بله، مطمئن هستم",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiClient.Delete(Urls.plansLines(item.data.id)).then(() => {
            toast.success("قیمت پایه اختصاصی موردنظر حذف شد.");
            onInit();
          });
        }
      });
    }
  };

  const confirmLines = () => {
    setIsAddLine(false);
    onInit();
  };
  useEffect(() => {
    onInit();
  }, [onInit]);
  return (
    <div>
      {linesList && lineCount && (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <PageTitleLayout>
              <h5>لیست خطوط پلن ها</h5>
              <span>لیستی از خطوط پلن ها در این جدول قابل بررسی است.</span>
            </PageTitleLayout>
            <StyledButton
              btnStyle="greenBtn"
              onClick={() => setIsAddLine(true)}
            >
              افزودن خط جدید
            </StyledButton>
          </div>

          <div className="d-flex flex-row align-items-end my-3">
            {lineCount.map((a , index) => (
                <div className="badge-info ml-2 p-2" style={{fontSize: '0.85rem' , borderRadius: 4}} key={index}> {a.numberLength} رقمی  {a.count} عدد </div>
            ))}
          </div>

          <Table
            data={linesList.items}
            config={tableConfig}
            pageConfig={{
              pageNumber: linesList.pageNumber,
              pageSize: linesList.pageSize,
              totalItemsCount: linesList.totalItemsCount,
              totalPagesCount: linesList.totalPagesCount,
            }}
            actionDispatched={removeLine}
          />


        </>
      )}
      <AddLinesModal
        visible={isAddLine}
        closeHandler={() => setIsAddLine(false)}
        confirmHandler={() => confirmLines()}
      />
    </div>
  );
};

export default PlansLineList;
