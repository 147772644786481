import React, { useEffect } from 'react'
import { MainContent } from "../../Component/Kit/Layout";
import FastSendTemplatesPending from "./pending/template-pending";
import FastSendTemplatesNotPending from "./not-pending/template-not-pending";
import { NavLink, Route, Switch, useHistory, useLocation } from "react-router-dom";

const FastSendTemplateList = ({ match }) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === `${match.url}`) {
            history.push(`${match.url}/pending`)
        }
    }, [])
    return (
        <>
            <MainContent>
                <ul className="user-tab-menu">
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/pending`}>
                            منتظر تایید
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/not-pending`}>
                            تعیین وضعیت شده
                        </NavLink>
                    </li>
                </ul>
                <div className="p-3">
                    <Switch>
                        <Route exact path={`${match.path}/pending`}>
                            <FastSendTemplatesPending />
                        </Route>
                        <Route path={`${match.path}/not-pending`}>
                            <FastSendTemplatesNotPending />
                        </Route>
                    </Switch>
                </div>
            </MainContent>
        </>
    )
}

export default FastSendTemplateList
