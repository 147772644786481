import { Formik } from "formik";
import React from "react";
import { StyledButton } from "../../../../Component/Kit/Button";
import { StyledForm, StyledSelect, StyledTextArea } from "../../../../Component/Kit/Form";
import { RejectGuestSchema } from "../../../../Utility/Validation/Schema/RejectGuestSchema";
import OutcomeList from "../../Models/outcome.model";
import { GuestOutcomeDescription } from "../../Services/guest-outcome";

function RejectFormModal({ rejectEvent, onCloseModal }) {

    return (
        <>
            <Formik
                initialValues={{
                    outcome: "",
                    description: "",
                }}
                enableReinitialize={true}
                validationSchema={RejectGuestSchema}
                onSubmit={(values, actions) => {
                    let body = {
                        outcome: parseInt(values.outcome),
                        description: values.description,
                    };
                    rejectEvent(body);
                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false);
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                        className="w-100">
                        <StyledSelect
                            lable="علت"
                            focus={true}
                            name="outcome"
                            hasDefaultOption
                            value={formik.values.outcome}
                            optionItems={OutcomeList}
                            error={(formik.errors.outcome && formik.touched.outcome)
                                ? formik.errors.outcome
                                : 'false'
                            }
                            onChange={(e) => {
                                formik.setFieldValue("outcome", e.target.value);
                                formik.setFieldValue("description", GuestOutcomeDescription(Number(e.target.value)))
                            }}
                        />
                        <StyledTextArea
                            lable="متن عدم تایید"
                            focus={true}
                            name="description"
                            value={formik.values.description}
                            error={(formik.errors.description && formik.touched.description)
                                ? formik.errors.description
                                : 'false'
                            }
                            onChange={(e) => {
                                const str = e.target.value.trim() !== '' ? e.target.value : ''
                                formik.setFieldValue("description", str);
                            }}
                        />
                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                id="reset"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting ? 1 : 0}
                                type="submit"
                            >
                                تایید
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    );
}

export default RejectFormModal;
