import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import { StyledButton } from '../../../Component/Kit/Button';
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import { Loader } from '../../../Component/Shared/Loader';
import Table from '../../../Component/Shared/Table/Table';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { ConvertQueryString } from '../../../Utility/Services/convert-query-string';
import DateConvert from '../../../Utility/Services/date-convert';
import BackToPage from "../../../Component/Kit/back";

export const DiscountCodeLog = () => {
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const [discountCodeList, setDiscountCodeList] = useState({});
    const [loading, setLoading] = useState(true);

    const tableConfig = {
        header: {
            userName: "نام کاربری",
            discountedPrice: 'مبلغ تخفیف خورده',
            isCompleted: 'تکمیل شده',
            createdAt: "تاریخ ایجاد",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            }
        },
    };

    function tableActions(items) {
        switch (items.actionName) {
            case "view":
                history.push(`/userDetail/${items.data.userId}/profile`)
                break;
        }
    }

    const getDiscountCodeList = useCallback(() => {
        ApiClient.Get(Urls.DiscountCodeLogs(params.id), ConvertQueryString(location.search))
            .then((res) => {
                let dataItem = res.result;
                dataItem.items.map((m, i) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.discountedPrice =  m.discountedPrice.toLocaleString('fa-IR') + ' ریال'
                    m.isCompleted = m.isCompleted? '✔' : ''
                    return m;
                });
                setDiscountCodeList(dataItem);
                setLoading(false)
            })
    }, [params.id , location.search])

    useEffect(() => {
        getDiscountCodeList();
    }, [location.search, getDiscountCodeList]);

    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-between">
                <span className="fakeContainer"></span>
                <BackToPage />
            </HeaderContent>
            <MainContent>
                <div className="col-12 col-lg-12">
                    <PageTitleLayout>
                        <h5>تاریخچه استفاده از کدهای تخفیف </h5>
                        <span>در این بخش تاریخچه استفاده از کدهای تخفیف کاربران قابل مشاهده است.</span>
                    </PageTitleLayout>
                    <div>
                        {loading === true
                            ?
                            <Loader />
                            :
                            <Table
                                data={discountCodeList.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: discountCodeList.pageNumber,
                                    pageSize: discountCodeList.pageSize,
                                    totalItemsCount: discountCodeList.totalItemsCount,
                                    totalPagesCount: discountCodeList.totalPagesCount,
                                }}
                            />
                        }
                    </div>
                </div>
            </MainContent>
        </WrapperLayout >
    );
};

export default connect()(DiscountCodeLog)