import {bankProvider, paymentDueDate, paymentDueTime, trackingNumber, payerFullName, paymentDescription } from "../Rules";
import * as Yup from "yup";

export const ModifyLendInvoiceItemSchema = Yup.object().shape({
    bankProvider,
    paymentDueDate,
    paymentDueTime,
    trackingNumber,
    payerFullName,
    paymentDescription
})