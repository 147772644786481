import { Formik } from 'formik';
import React from 'react'
import { useEffect } from 'react';
import { StyledForm, StyledInput } from '../../../Component/Kit/Form';
import { StyledButton } from '../../../Component/Kit/Button';
import { ProviderThresholdCreditSchema } from '../../../Utility/Validation/Schema/ProviderThresholdCreditSchema';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';

export const ModifyProviderThresholdModal = ({ selectedProviderItem, onCloseModal, onSubmit }) => {
    return (
        <>
            <Formik
                initialValues={{
                    providerThresholdCredit: selectedProviderItem.threshold,
                }}
                isInitialValid={false}
                enableReinitialize={true}
                validationSchema={ProviderThresholdCreditSchema}
                onSubmit={(values, actions) => {
                    Swal.fire({
                        title: `آیا مطمئن هستید؟`,
                        text: "",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonText: "انصراف",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "بله، مطمئن هستم",
                    }).then(result => {
                        if (result.isConfirmed) {
                            let value = parseInt(values.providerThresholdCredit);
                            ApiClient.Patch(Urls.ModifyProviderThreshold(selectedProviderItem.providerId), value)
                                .then((res) => {
                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                    onCloseModal(false);
                                    onSubmit();
                                })
                                .catch().finally(() => {
                                    actions.setSubmitting(false)
                                })
                        }
                    })
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                    >
                        <StyledInput
                            lable={`کمینه اعتبار پیش‌فرض  ${selectedProviderItem.providerName}`}
                            focus={true}
                            name="providerThresholdCredit"
                            type="number"
                            value={formik.values.providerThresholdCredit}
                            error={(formik.errors.providerThresholdCredit && formik.touched.providerThresholdCredit)
                                ? formik.errors.providerThresholdCredit
                                : 'false'
                            }
                            onChange={(e) => formik.setFieldValue('providerThresholdCredit', e.target.value)}
                        />

                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                اعمال محدودیت
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}

            </Formik>
        </>
    )
}

export default ModifyProviderThresholdModal;