import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { StyledButton } from '../../../../Component/Kit/Button';
import { StyledForm, StyledInput } from '../../../../Component/Kit/Form';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import { useParams } from 'react-router-dom';

const DedicatedBasePriceStep = (props) => {
    const { step, updateState, initalData, formData } = props;
    const [dedicatedBasePriceInTiers, setDedicatedBasePriceInTiers] = useState([]);
    const [dedicatedBasePriceDisCount, setDedicatedBasePriceDisCount] = useState({});
    const params = useParams();

    const discountFieldName = {
        basePrice: "قیمت پایه",
        discountedBasePrice: "تخفیف قیمت پایه",
    };

    useEffect(() => {
        ApiClient.Get(Urls.DedicatedBasePrice()).then((res) => {
            setDedicatedBasePriceInTiers(res.result);
        });

        if (Boolean(formData) && Object.entries(formData).length !== 0) {
            // Dirty form in Edit mode or create
            setDedicatedBasePriceDisCount(formData.dedicatedBasePriceDisCount);
        }
        else {
            if (Object.entries(initalData).length > 0 && params.id) {
                //  edit mode without changing 
                let dedicatedBasePrices = Object.assign({}, ...initalData.publicDiscountCodeDedicatedBasePrices.map((x) => ({ [x.dedicatedBasePriceId]: x.discountedBasePrice })));
                setDedicatedBasePriceDisCount(dedicatedBasePrices);
            }
        }


    }, [initalData]);

    return (
        <div className='row'>
            <Formik
                initialValues={{
                    dedicatedBasePriceDisCount,
                }}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    /*  let data = {}
                     dedicatedBasePriceInTiers.map((item) => {
                         data[item.id] = Number(values.dedicatedBasePriceDisCount[item.id])
                     })
                     updateState({ step: step + 1, data: data }) */
                    updateState({ step: step + 1, data: values })
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                    >
                        <div className="table-responsive custom-table p-3">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        {discountFieldName &&
                                            Object.entries(discountFieldName).map(
                                                ([item, value]) => (
                                                    <th scope="col" key={`headLine_${item}`}>
                                                        {value}
                                                    </th>
                                                )
                                            )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dedicatedBasePriceInTiers.map((dataItem, index) => (
                                        <>
                                            {dataItem && (
                                                <>
                                                    <tr key={`creditTierId-${dataItem.id}`}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{dataItem.basePrice}</td>
                                                        <td>
                                                            <StyledInput
                                                                formGroupClass="creditTierInput"
                                                                type="text"
                                                                focus={false}
                                                                name={`dedicatedBasePriceDisCount.${dataItem.id}`}
                                                                id={`dedicatedBasePriceDisCount_${dataItem.id}`}
                                                                key={`dedicatedBasePriceDisCount_${dataItem.id}`}
                                                                value={formik.values.dedicatedBasePriceDisCount[dataItem.id] !== undefined
                                                                    ? formik.values.dedicatedBasePriceDisCount[dataItem.id]
                                                                    : formik.initialValues.dedicatedBasePriceDisCount[dataItem.id]
                                                                }
                                                                error={(formik.touched && formik.touched.dedicatedBasePriceDisCount && formik.touched.dedicatedBasePriceDisCount[dataItem.id]) && !formik.values.dedicatedBasePriceDisCount[dataItem.id]
                                                                    ? "این فیلد الزامی است."
                                                                    : "false"
                                                                }
                                                                onChange={(e) => formik.setFieldValue(`dedicatedBasePriceDisCount[${dataItem.id}]`, e.target.value)}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="spacer" key={`spacer${dataItem.creditTierId}`}                                                    >
                                                        <td colSpan="100"></td>
                                                    </tr>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-4 d-flex justify-content-between">
                            <StyledButton
                                onClick={() => updateState({ step: step - 1 })}
                                btnStyle="grayBtn"
                                type="button"
                                id="prevStep"
                            >
                                مرحله قبلی
                            </StyledButton>
                            <StyledButton
                                disabled={
                                    Object.values(formik.values.dedicatedBasePriceDisCount).length == 0 || 
                                    (Object.values(formik.values.dedicatedBasePriceDisCount).length < dedicatedBasePriceInTiers.length) ||
                                    (!initalData.publicDiscountCodeDedicatedBasePrices && (formData && !formData.dedicatedBasePriceDisCount))
                                }
                                btnStyle="greenBtn"
                                type="submit"
                                id="thirdStep"
                            >
                                مرحله بعدی
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </div>
    )
}

export default DedicatedBasePriceStep