import React from "react";
import { Formik } from "formik";
import { LoginFirstStepForm } from "./styles";
import { StyledInput } from "../../../Component/Kit/Form";
import { StyledButton } from "../../../Component/Kit/Button";
import Urls from "../../../Requester/Urls";
import { AdminLoginSchema } from "../../../Utility/Validation/Schema/AdminLoginSchema";
import ApiClient from "../../../Requester/ApiClient";

const LoginFirstStepFormContainer = (props) => {
    const { orderStep, currentStep, setStep, account, setAccount } = props;
    return (
        <>
            <Formik
                initialValues={{
                    username: "",
                    password: "",
                }}

                validationSchema={AdminLoginSchema}
                isInitialValid={false}

                onSubmit={(values, actions) => {
                    let body = {
                        username: values.username,
                        password: values.password,
                    };
                    setAccount({ ...body });

                    ApiClient.Post(Urls.AdminLogin(), body)
                        .then(res => {
                            if (res.result) {
                                setStep(1);
                                actions.setSubmitting(false);
                                actions.resetForm();
                            }else{
                                actions.setSubmitting(false);
                            }
                        })
                        .catch(error => {
                            actions.setSubmitting(false);
                        })
                }}
            >
                {(formik) => (
                    <LoginFirstStepForm
                        account={account}
                        onSubmit={formik.handleSubmit}
                        currentStep={currentStep}
                        orderStep={orderStep}>

                        <h5>ورود به حساب کاربری</h5>

                        <StyledInput
                            value={formik.values.username}
                            placeholder="نام کاربری"
                            type="text"
                            name="username"
                            error={formik.errors.username !== undefined ? formik.errors.username : 'false'}
                            onChange={(e) => formik.setFieldValue("username", e.target.value)}
                        />

                        <StyledInput
                            value={formik.values.password}
                            placeholder="رمز عبور"
                            type="password"
                            name="password"
                            error={formik.errors.password !== undefined ? formik.errors.password : 'false'}
                            onChange={(e) => formik.setFieldValue("password", e.target.value)}
                        />
                        <StyledButton
                            width="100%"
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting ? 1 : 0}
                            type="submit"
                        >
                            دریافت کد
                        </StyledButton>
                    </LoginFirstStepForm>
                )}
            </Formik>
        </>
    );
}

export default LoginFirstStepFormContainer

