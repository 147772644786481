import React from 'react'
import { toast } from 'react-toastify';
import { StyledButton } from '../../../Component/Kit/Button';
import Modal from '../../../Component/Kit/Modal';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';

export const DeleteTemplateModal = (props) => {
    const { templateId, visible, onClose, onCancel, setVisible  ,setChangedata} = props;
   
    const deleteSingleTemplateHandler = () => {
        ApiClient.Delete(Urls.ModifyTicketAnswerTemplateById(templateId))
            .then(res => {
                toast.success('قالب با موفقیت حذف شد.');
                setVisible(false);
                setChangedata(true);
            })
    }

    return (
        <Modal
            size="small"
            visible={visible}
            setVisible={setVisible}
            onCancel={onCancel}
            onClose={onClose}
            headerTitle="حذف قالب"
        >
            <div className="col-12 py-5 text-center">
                <h6>آیا از حذف قالب خود مطمئن هستید؟</h6>

                <div className="mt-5">
                    <StyledButton
                        btnStyle="redBtn ml-3"
                        onClick={() => deleteSingleTemplateHandler()}
                    >
                        مطمئنم
                    </StyledButton>

                    <StyledButton
                        btnStyle="grayBtn"
                        onClick={() => { setVisible(false) }}
                    >
                        بستن
                    </StyledButton>

                </div>

            </div>
        </Modal>
    )
}
