import React, { useState } from "react";
import { Formik } from "formik";
import {
    StyledForm,
    StyledTextArea,
    StyledInput,
} from "../../../Component/Kit/Form";
import { toast } from 'react-toastify';
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { UserNotificationSchema } from "../../../Utility/Validation/Schema/UserNotificationSchema";
import { StyledButton } from "../../../Component/Kit/Button";
import Swal from "sweetalert2";
import { useRouteMatch } from "react-router-dom";

function AddCustomNotification({ dataChange, onCloseModal }) {
    let match = useRouteMatch();
    const [submitLoading, setsubmitLoading] = useState(false);

    const addNotification = async (body) => {
        setsubmitLoading(true);
        await Swal.fire({
            title: `آیا مطمئن هستید؟`,
            text: "این اعلان برای کاربر ارسال می‌شود. احتیاط کن!!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Post(Urls.userCustomNotification(match.params.id), body).then(() => {
                    setsubmitLoading(false);
                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    dataChange(true);
                })

            }
        })
    };
    return (
        <>
            <Formik
                initialValues={{
                    notificationTitle: "",
                    notificationContent: "",
                }}
                validationSchema={UserNotificationSchema}
                //isInitialValid={false}
                onSubmit={(values, actions) => {
                    let body = {
                        title: values.notificationTitle,
                        content: values.notificationContent,
                    };
                    addNotification(body);
                    actions.resetForm();
                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false)
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}>
                        <StyledInput
                            lable="لطفا عنوان اعلان خود را مشخص کنید."
                            focus={true}
                            placeholder="عنوان"
                            type="text"
                            name="notificationTitle"
                            value={formik.values.notificationTitle}
                            error={(formik.errors.notificationTitle && formik.touched.notificationTitle)
                                ? formik.errors.notificationTitle
                                : 'false'
                            }
                            onChange={(e) =>
                                formik.setFieldValue("notificationTitle", e.target.value)
                            }
                        />

                        <StyledTextArea
                            lable="متن اعلان خود را وارد کنید"
                            focus={true}
                            name="notificationContent"
                            value={formik.values.notificationContent}
                            error={(formik.errors.notificationContent && formik.touched.notificationContent)
                                ? formik.errors.notificationContent
                                : 'false'
                            }
                            onChange={(e) => {
                                const str = e.target.value.trim() !== '' ? e.target.value : ''
                                formik.setFieldValue("notificationContent", str)
                            }}
                        />
                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                btnStyle="greenBtn"
                                disabled={!formik.isValid}
                                loading={submitLoading ? 1 : 0}
                                type="submit"
                            >
                                ارسال اعلان
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    );
}

export default AddCustomNotification;
