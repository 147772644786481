import React, {useEffect, useState} from 'react'
import {PageTitleLayout} from '../../../../Component/Kit/Layout';
import Table from '../../../../Component/Shared/Table/Table';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import DateConvert from '../../../../Utility/Services/date-convert';
import {SeprateNumberWithComma} from '../../../../Utility/Services/SeprateNumberWithComma';
import {ConvertQueryString} from "../../../../Utility/Services/convert-query-string";
import {useLocation} from "react-router-dom";

export const InvoiceApproved = (props) => {
    const location = useLocation()
    const {tableConfig, tableActions} = props;
    const [officialInvoiceApprovedList, setOfficialInvoiceApprovedList] = useState({});

    let localTableConfig = {
        ...tableConfig,
        mode: [...tableConfig.mode, "export-excel"],
        // filters: [...tableConfig.filters]
    }

    useEffect(() => {
        let params = {
            ...ConvertQueryString(location.search),
            numbered: true
        }
        ApiClient.Get(Urls.GetOfficialInvoices(), params)
            .then(res => {
                let responseData = res.result;
                responseData.items.map((item, i) => {
                    item.totalPrice = `${SeprateNumberWithComma(item.totalPrice)} ریال`;
                    item.createdAt = DateConvert(item.createdAt);
                    item.canModifyDiscountPrice = item.canModifyDiscountPrice
                    ? `<span class="grass-green-text">دارد</span>`
                    : `<span class="red-text">ندارد</span>`;
                    item.onlyReturns = (item.isReturnInvoice)
                        ? "فاکتور برگشتی"
                        : "فاکتور";
                });
                setOfficialInvoiceApprovedList(responseData);
            })
    }, [location.search]);


    const exportActions = (item) => {
        let params = {
            ...ConvertQueryString(location.search),
        }
        ApiClient.Get(Urls.GetExportFromOfficialNumberedInvoices(item.export), params)
            .then((res) => {
                let resData = res.result;
                if (!Array.isArray(resData)) {
                    const url = `${Urls.base()}/${resData}`
                    window.open(url, '_blank')
                    // window.open(resData, '_blank')
                } else {
                    resData.map((item) => {
                        const url = `${Urls.base()}/${item}`
                        windowOpen(url);
                    })
                }
            })
            .catch((err) => console.log(err))
    }

    const windowOpen = (item) => {
        window.open(item)
    }

    return (
        <div>
            <PageTitleLayout className="mt-4">
                <h5> لیست فاکتورهای با‌شماره</h5>
                <span>در این بخش لیستی از فاکتور‌های تایید شده، قابل مشاهده است.</span>
            </PageTitleLayout>
            {
                officialInvoiceApprovedList && (
                    <Table
                        data={officialInvoiceApprovedList.items}
                        config={localTableConfig}
                        pageConfig={{
                            pageNumber: officialInvoiceApprovedList.pageNumber,
                            pageSize: officialInvoiceApprovedList.pageSize,
                            totalItemsCount: officialInvoiceApprovedList.totalItemsCount,
                            totalPagesCount: officialInvoiceApprovedList.totalPagesCount,
                        }}
                        actionDispatched={tableActions}
                        exportDispatched={exportActions}
                    />
                )
            }
        </div>
    )
}
