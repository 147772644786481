import * as React from "react"

function UserAnswerdTicketIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width={55}
      height={75.186}
      {...props}
    >
      <g data-name="Group 152132">
        <path
          data-name="Icon awesome-envelope-open-text"
          d="M18.906 43.386h17.188a1.718 1.718 0 0 0 1.719-1.719v-1.715a1.718 1.718 0 0 0-1.719-1.719H18.906a1.718 1.718 0 0 0-1.719 1.719v1.719a1.718 1.718 0 0 0 1.719 1.715Zm-1.719 8.6a1.718 1.718 0 0 0 1.719 1.719h17.188a1.718 1.718 0 0 0 1.718-1.719v-1.722a1.718 1.718 0 0 0-1.719-1.719H18.906a1.718 1.718 0 0 0-1.719 1.719ZM27.5 64.995a8.581 8.581 0 0 1-5.034-1.632L0 47.134v22.9a5.156 5.156 0 0 0 5.156 5.152h44.688A5.156 5.156 0 0 0 55 70.03v-22.9L32.534 63.363a8.589 8.589 0 0 1-5.034 1.632Zm25.524-27.309a197.27 197.27 0 0 0-3.181-2.45v-4.737a5.156 5.156 0 0 0-5.156-5.156h-8.33l-.971-.7c-1.806-1.323-5.394-4.494-7.886-4.457-2.492-.038-6.079 3.133-7.886 4.452l-.971.7h-8.331a5.156 5.156 0 0 0-5.156 5.156v4.742c-1.329 1-2.23 1.7-3.181 2.45A5.156 5.156 0 0 0 0 41.748v1.144l10.312 7.45V30.499h34.375v19.843L55 42.892v-1.144a5.156 5.156 0 0 0-1.976-4.062Z"
          fill="#3f3d56"
        />
        <path
          data-name="Path 37761"
          d="M24.118 6.485h-5.059V1.293A1.293 1.293 0 0 0 17.766 0H15.12a1.293 1.293 0 0 0-1.293 1.293v5.192H8.768a1.293 1.293 0 0 0-.987 2.129l7.676 9.065a1.293 1.293 0 0 0 1.974 0l7.674-9.066a1.293 1.293 0 0 0-.987-2.129Z"
          fill="#a7abc3"
        />
        <path
          data-name="Path 37762"
          d="M46.233 11.652h-5.059v5.192a1.293 1.293 0 0 1-1.293 1.293h-2.647a1.293 1.293 0 0 1-1.293-1.293v-5.192h-5.059a1.293 1.293 0 0 1-.987-2.129L37.571.457a1.293 1.293 0 0 1 1.974 0l7.675 9.066a1.293 1.293 0 0 1-.987 2.129Z"
          fill="#a7abc3"
        />
      </g>
    </svg>
  )
}

export default UserAnswerdTicketIcon;