
import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {RoleCanActivate} from "../../../Utility/Services/roleCanActivate";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {ConvertQueryString} from "../../../Utility/Services/convert-query-string";
import TicketDepartment from "../../../Utility/Services/TicketDepartmentService";
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from "../../../Component/Kit/Layout";
import {StyledButton} from "../../../Component/Kit/Button";
import Table from "../../../Component/Shared/Table/Table";
import {DeleteTemplateModal} from "../../TicketAnswerTemplates/DeleteTemplateModal";
import {connect} from "react-redux";
import Modal from "../../../Component/Kit/Modal";
import AssignmentPeriod from "./assignment-period";
import DateConvert from "../../../Utility/Services/date-convert";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

const AutoAssignmentTicket = ({adminProfile}) => {
    const location = useLocation()
    const history = useHistory()
    const [templateData, setTemplateData] = useState({});
    const [visible, setVisible] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [changedata, setChangedata] = useState(true);
    const [adminList, setAdminList] = useState([]);

    const handleClick = () => {
        setVisible(true)
    };


    const templateTableConfig = {
        header: {
            adminFullName: "نام کاربری ادمین",
            start: "شروع بازه",
            end: "پایان بازه",
        },
        actions: {
            delete: {
                icon: "fa fa-trash",
                condition: () => RoleCanActivate(['supporter'], adminProfile.roleId),
            }
        },
        mode: ["filter", "search"],
        filters: ["department"],
    };

    function tableActions(items) {
        switch (items.actionName) {
            case "delete":
                deleteHandler(items.data.id)
                break;

            default:
                break;
        }
    }

    const deleteHandler = (id) => {
        Swal.fire({
            title: "آیا از حذف این بازه اطمینان دارید؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.assignmentTicket(id))
                    .then((res) => {
                        toast.success("حذف  بازه با موفقیت انجام شد.");
                        getData()
                    })
            }
        });

    }


    const getData = useCallback(() => {
        ApiClient.Get(Urls.assignmentTicket(), ConvertQueryString(location.search))
            .then((res) => {
                let resData = res.result
                resData.items.map((item) => {
                    item.start = DateConvert(item.start);
                    item.end = DateConvert(item.end);
                    return item;
                })

                setTemplateData(resData);

                setChangedata(false)
            })

    })

    useEffect(() => {
        getData()

    }, [location.search, changedata])


    useLayoutEffect(() => {
        ApiClient.Get(Urls.AdminSummary()).then((res) => {
            setAdminList(res.result);
        })
    }, [setAdminList])


    return (
        <WrapperLayout>
            <div className="c-wrapper">
                {adminProfile && adminProfile.roleId ?
                    RoleCanActivate(['supporter'], adminProfile.roleId) ? (
                        <HeaderContent>
                            <StyledButton btnStyle="greenBtn" onClick={() => handleClick()}>
                                ایجاد زمان‌ پاسخگویی
                            </StyledButton>
                        </HeaderContent>
                    ) : null
                    : null}


                <MainContent>
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout>
                            <h5>تخصیص خودکار تیکت ها </h5>
                            <span>در این بخش لیست همه ساعات تخصیص تیکت ها را مشاهده است.</span>
                        </PageTitleLayout>
                        <Table
                            data={templateData.items}
                            config={templateTableConfig}
                            actionDispatched={tableActions}
                            pageConfig={{
                                pageNumber: templateData?.pageNumber,
                                pageSize: templateData?.pageSize,
                                totalItemsCount: templateData?.totalItemsCount,
                                totalPagesCount: templateData?.totalPagesCount,
                            }}
                        />

                    </div>
                </MainContent>
            </div>

            <AssignmentPeriod
            visible={visible}
            onClose={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            adminsList={adminList}
            onSubmit={() => {
                setVisible(false)
                getData()

            }}
            />

        </WrapperLayout>
    );
};

function mapStateToProps(state) {
    const {bootstrapper} = state
    return {adminProfile: bootstrapper.data}
}

export default connect(mapStateToProps)(AutoAssignmentTicket);

