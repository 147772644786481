import queryString from "query-string"

export function ConvertQueryString(params) {
    let search;
    if (params) {
        search = params[0] === '?' ? params.substring(1) : params
    } else {
        search = null;
    }
    return search ?
        queryString.parse(search) :
        {};
}
