import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PageTitleLayout } from "../../../Component/Kit/Layout";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { ConvertQueryString } from "../../../Utility/Services/convert-query-string";
import DateConvert from "../../../Utility/Services/date-convert";
import GuestStep from '../Services/guest-step';


const GuestList = () => {
    let history = useHistory();
    let location = useLocation();
    const [guestData, setGuestData] = useState({});

    function tableActions(items) {
        switch (items.actionName) {
            case "view":
                history.push(`/guest/item/${items.data.id}/info`, { from: location.pathname, search: location.search });
                break;
            case 'copy':
                const phoneNumber = items.data.mobile;
                navigator.clipboard.writeText(phoneNumber)
                break;
            default:
                break;
        }
    }

    const tableConfig = {
        header: {
            fullName: "نام و نام خانوادگی",
            mobile: "شماره همراه",
            userName: "نام کاربری",
            step: "وضعیت ثبت نام",
            guestChecksCount: "بررسی مجدد",
            createdAt: "تاریخ ایجاد",
            isDeleted: "منقضی شده",
        },
        actions: {
            view: {
                icon: "fa fa-eye"
            },
            copy: {
                icon: "far fa-copy",
            },
        },
        // add every field that you want :)
        placeholder: ['fullName'],
        mode: ["filter", "search"],
        filters: ["datePickerFilter", "step", "isDeleted"],
    };

    useEffect(() => {
        ApiClient.Get(Urls.getAllGuest(), ConvertQueryString(location.search))
            .then((res) => {
                let data = res.result;
                data.items.map((m, i) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.lastModifiedDateTime = m.lastModifiedDateTime
                        ? DateConvert(m.lastModifiedDateTime)
                        : DateConvert(m.createdAt);
                    m.step = GuestStep(m.step);
                    m.mobile = `0${m.mobile}`;
                    m.isDeleted = m.isDeleted ? "بله" : "خیر";
                    m.guestChecksCount = `${m.guestChecksCount} بار`;
                    return m;
                });
                setGuestData(data);
            })
    }, [location.search]);
    
    return (
        <>

            <PageTitleLayout className="mt-4">
                <h5>کاربران مهمان</h5>
                <span>
                    دراینجا لیستی از کاربرانی که ثبت نام آنها تکمیل نشده است، مورد بررسی قرار می‌گیرد.

                </span>
            </PageTitleLayout>
            <Table
                data={guestData.items}
                config={tableConfig}
                pageConfig={{
                    pageNumber: guestData.pageNumber,
                    pageSize: guestData.pageSize,
                    totalItemsCount: guestData.totalItemsCount,
                    totalPagesCount: guestData.totalPagesCount,
                }}
                actionDispatched={tableActions}
            />

        </>
    );
};

export default GuestList;
