import React, { useEffect, useState } from "react";
import { MainContent, PageTitleLayout, WrapperLayout } from "../../Component/Kit/Layout";
import { Loader } from "../../Component/Shared/Loader";
import Table from "../../Component/Shared/Table/Table";
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import EnumFlag from "../../Utility/Services/enum-flag";
import DateConvert from "../../Utility/Services/date-convert";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ConvertQueryString } from "../../Utility/Services/convert-query-string";

const WhiteUsers = ({ history }) => {
    const location = useLocation();
    const [usersList, setUsersList] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getUserList();
    }, [location.search]);
    const getUserList = () => {
        ApiClient.Get(Urls.getWhiteUsersList(), ConvertQueryString(location.search))
            .then(res => {
                setLoading(false);
                const data = res.result.items.map(item => {
                    const restriction = EnumFlag([1, 2, 4, 8], item.deniedLimitations);
                    item.modifiedAt = DateConvert(item.modifiedAt);
                    item.id = item.userId;
                    item.count = restriction.includes(1)
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    item.link = restriction.includes(2)
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    item.forbiddenWord = restriction.includes(4)
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    item.newUser = restriction.includes(8)
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    return item;
                })
                setUsersList({ ...res.result, items: [...data] });
            })
    }
    const tableConfig = {
        header: {
            userName: "نام کاربری",
            firstName: "نام",
            lastName: "نام خانوادگی",
            count: "تعداد ارسال",
            link: "لینک",
            forbiddenWord: "کلمات ممنوعه",
            newUser: "کاربر جدید",
            modifiedAt: "آخرین به روز رسانی",
        },
        actions: {
            edit: {
                icon: "fa fa-pen",
            },
            delete: {
                icon: "fa fa-trash",
            },
        },
        mode: ["filter", "search"],
        filters: ["limitationType"],
    };
    const tableActions = (item) => {
        switch (item.actionName) {
            case 'edit':
                history.push(`/userDetail/${item.data.id}/user-restriction`);
                break;
            case 'delete':
                deleteWhiteListUser(item.data.id);
                break;
            default:
                break;
        }
    };
    const deleteWhiteListUser = (id) => {
        Swal.fire({
            title: '',
            text: `آیا از حذف کاربر از لیست سفید مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.userDeniedLimitations(id))
                    .then(res => {
                        if (res) {
                            toast.success("کاربر مورد نظر با موفقیت از لیست سفید شد.");;
                            getUserList();
                        }
                    })

            }
        })
    }
    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <MainContent>
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout>
                            <h5>لیست کاربران سفید</h5>
                            <span>در این بخش میتوانید کاربرانی که محدودیت های ارسال برای آنها اعمال نمیشود را ببینید.</span>
                        </PageTitleLayout>
                        <div>
                            {loading === true
                                ?
                                <Loader />
                                :
                                <Table
                                    data={usersList.items}
                                    config={tableConfig}
                                    actionDispatched={tableActions}
                                    pageConfig={{
                                        pageNumber: usersList.pageNumber,
                                        pageSize: usersList.pageSize,
                                        totalItemsCount: usersList.totalItemsCount,
                                        totalPagesCount: usersList.totalPagesCount,
                                    }}
                                />
                            }
                        </div>
                    </div>
                </MainContent>
            </div>
        </WrapperLayout>
    )
}
export default WhiteUsers;
