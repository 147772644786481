import { subject, department, priority, longDescription, ticketFile } from "../Rules";
import * as Yup from "yup";

export const AddTicketSchema = Yup.object().shape({
    subject,
    department,
    priority,
    longDescription,
    ticketFile
});
