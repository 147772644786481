import React from 'react'

export const BuyerDetails = (props) => {
    const { singleInvoiceDetails } = props;

    const buyerDetailsLineOne = {
        fullName: "نام شخص حقیقی/حقوقی ",
        economicCode: 'شماره اقتصادی',
        registrationNumber: 'شماره ثبت',
        companyName: "نام شخص حقیقی/حقوقی ",
        // nationalId: 'کد ملی',
        // legalNationalId: 'شناسه/ کدملی',

    }
    const buyerDetailsLineTwo = {
        address: "نشانی ",
        postalCode: 'کدپستی',
        phoneNumber: "شماره تلفن",

    }
    return (
        <>
            {
                singleInvoiceDetails && (
                    <table className="factor-table">
                        <tbody>
                            <tr className="amber-bg">
                                <td>مشخصات خریدار</td>
                            </tr>
                            <tr className="master">
                                <td>
                                    <table className='w-100'>
                                        <tbody>
                                            <tr>
                                                {
                                                    Object.entries(buyerDetailsLineOne).map(([item, value]) => (
                                                        singleInvoiceDetails[item] &&
                                                        <td key={`item${value}`} className='text-right align-top'>
                                                            <span className="font-weight-bold">{value}</span>
                                                            <span className="mr-2">{singleInvoiceDetails[item]}</span>
                                                        </td>
                                                    ))
                                                }
                                                <td key={`item_nationalId`} className={'align-top'} >
                                                    <span className="font-weight-bold">
                                                        {singleInvoiceDetails.economicCode ? 'شناسه/ کدملی' : 'کدملی'}
                                                    </span>
                                                    <span className="mr-2">{singleInvoiceDetails.nationalId}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='w-100'>
                                        <tbody>
                                            <tr>
                                                {
                                                    Object.entries(buyerDetailsLineTwo).map(([item, value]) => (
                                                        singleInvoiceDetails[item] &&
                                                        <td key={`item${value}`} className={'text-right pt-3'}>
                                                            <span className="font-weight-bold">{value}</span>
                                                            <span className="mr-2">{singleInvoiceDetails[item]}</span>
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )
            }
        </>
    )
}
