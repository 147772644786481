import { Formik } from "formik";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { settingModal } from "../../../../Actions/setting-modal";
import { StyledButton } from "../../../../Component/Kit/Button";
import { StyledCheckBox, StyledForm } from "../../../../Component/Kit/Form";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { FormLayout } from './../../../../Component/Kit/Form';

function EditBlacklistPermissionModal({ defaultData, onCloseModal }) {
    let dispatch = useDispatch();
    return (
        <>
            {defaultData &&
                <div className="custom-modal-body">
                    <Formik
                        initialValues={{
                            defaultData
                        }}
                        isInitialValid={false}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            const data = Object.assign({}, ...values.defaultData.map((x) => ({ [x.value]: JSON.stringify(x.fields) })));
                            Swal.fire({
                                title: `آیا مطمئن هستید؟`,
                                text: "",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonText: "انصراف",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "بله، مطمئن هستم",
                            }).then(result => {
                                if (result.isConfirmed) {
                                    ApiClient.Patch(Urls.updateBlackListTypes(), JSON.stringify(data)).then(() => {
                                        toast.success('تغییرات  با موفقیت ثبت شد.');
                                        dispatch(settingModal(true));
                                    })
                                } else {
                                    actions.resetForm()
                                }
                            })
                        }}
                        onReset={(values, formikBag) => {
                            onCloseModal(false)
                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                            >
                                {formik.values.defaultData.map((method, index) => (
                                    <FormLayout key={index}>
                                        <div className="form-title">
                                            <span>{method.name}</span>
                                        </div>
                                        <div key={index} className="d-flex align-items-center justify-content-start">
                                            <StyledCheckBox
                                                formGroupClass="mt-2 ml-4"
                                                lable='ارسال متن لغو'
                                                focus={true}
                                                name={`defaultData.${index}.fields.ShouldSendCancellationText`}
                                                checked={formik.values.defaultData[index].fields.ShouldSendCancellationText !== undefined
                                                    ? formik.values.defaultData[index].fields.ShouldSendCancellationText
                                                    : formik.initialValues.defaultData[index].fields.ShouldSendCancellationText}
                                                id={`defaultData${index}-ShouldSendCancellationText`}
                                                onChange={(e) => formik.setFieldValue(`defaultData.${index}.fields.ShouldSendCancellationText`, e.target.checked)}
                                            />
                                            <StyledCheckBox
                                                formGroupClass="mt-2 ml-4"
                                                lable='فیلتر اعداد'
                                                focus={true}
                                                name={`defaultData.${index}.fields.ShouldFilterMobiles`}
                                                checked={formik.values.defaultData[index].fields.ShouldFilterMobiles !== undefined
                                                    ? formik.values.defaultData[index].fields.ShouldFilterMobiles
                                                    : formik.initialValues.defaultData[index].fields.ShouldFilterMobiles}
                                                id={`defaultData${index}-fields.ShouldFilterMobiles`}
                                                onChange={(e) => formik.setFieldValue(`defaultData.${index}.fields.ShouldFilterMobiles`, e.target.checked)}
                                            />
                                        </div>
                                    </FormLayout>
                                ))}
                                <div className="d-flex justify-content-between">
                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => {
                                            onCloseModal(false);
                                        }}
                                    >
                                        بستن
                                    </StyledButton>
                                    <StyledButton
                                        width="35%"
                                        btnStyle="greenBtn"
                                        type="submit"
                                    >
                                        تایید
                                    </StyledButton>
                                </div>
                            </StyledForm>
                        )}
                    </Formik>
                </div>
            }

        </>
    )
}

export default connect()(EditBlacklistPermissionModal);
