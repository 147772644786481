import {Formik} from 'formik';
import React, {useState} from 'react'
import {toast} from 'react-toastify';
import {StyledButton} from '../../../Component/Kit/Button';
import {StyledForm, StyledInput} from '../../../Component/Kit/Form';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import {ChangePasswordSchema} from '../../../Utility/Validation/Schema/ChangePasswordSchema';

export const ChangePassword = () => {
    const [loadingFlag, setLoadingFlag] = useState(false);

    return (
        <div className="row mt-3 mx-0">
            <div className="col-12 col-lg-12">
                <div className="descripton form-group">
                    <p>در این بخش می‌توانید رمزعبور خود را تغییر دهید. </p>
                </div>
            </div>

            <div className="col-12 col-lg-12">
                <Formik
                    initialValues={{
                        oldPassword: "",
                        newPassword: "",
                        repeatPassword: "",
                    }}

                    validationSchema={ChangePasswordSchema}
                    enableReinitialize={true}

                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            setLoadingFlag(true)
                        }, 40);
                        let body = {
                            "oldPassword": values.oldPassword,
                            "newPassword": values.newPassword,
                            "repeatPassword": values.repeatPassword,
                        };
                        ApiClient.Patch(Urls.ModifyAdminPassword(), body)
                            .then(() => {
                                toast.success('رمز عبور شما با موفقیت  تغییر کرد.')
                                setTimeout(() => {
                                    setLoadingFlag(false)
                                    actions.resetForm();
                                }, 50);
                            })
                            .catch(() => setLoadingFlag(false))
                    }}
                >
                    {(formik) => (
                        <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur} className="p-0">
                            <div className="row">
                                <div className="col-md-4 col-12">

                                    <StyledInput
                                        formGroupClass="col-12"
                                        lable="رمز عبور قبلی"
                                        focus={true}
                                        name="oldPassword"
                                        type="password"
                                        value={formik.values.oldPassword}
                                        error={(formik.errors.oldPassword && formik.touched.oldPassword)
                                            ? formik.errors.oldPassword
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("oldPassword", e.target.value)}
                                    />
                                    <StyledInput
                                        formGroupClass="col-12"
                                        lable="رمز عبور جدید"
                                        focus={true}
                                        name="newPassword"
                                        type="password"
                                        value={formik.values.newPassword}
                                        error={(formik.errors.newPassword && formik.touched.newPassword)
                                            ? formik.errors.newPassword
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("newPassword", e.target.value)}
                                    />
                                    <StyledInput
                                        formGroupClass="col-12"
                                        lable="تکرار رمز عبور "
                                        focus={true}
                                        name="repeatPassword"
                                        type="password"
                                        value={formik.values.repeatPassword}
                                        error={(formik.errors.repeatPassword && formik.touched.repeatPassword)
                                            ? formik.errors.repeatPassword
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("repeatPassword", e.target.value)}
                                    />

                                </div>
                            </div>

                            <StyledButton
                                width="100%"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={loadingFlag}
                                type="submit"
                                btnStyle="darkGreenBtn" 
                            >
                                ویرایش اطلاعات
                            </StyledButton>
                        </StyledForm>

                    )}
                </Formik>


            </div>
        </div>
    )
}
