import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';


import Urls from '../../../Requester/Urls';
import ApiClient from '../../../Requester/ApiClient';
import {HeaderContent, MainContent, WrapperLayout,} from '../../../Component/Kit/Layout';
import {StyledButton} from '../../../Component/Kit/Button';
import {TickeInfo} from './TicketInfo';
import {CatList} from './ChatList';
import {ReplyToTicket} from './ReplyToTicket';
import {GeTicketDepartmentKey} from '../../../Utility/Services/TicketDepartmentService';
import {ModifyTicketSettingModal} from './ModifyTicketSettingModal';
import BackToPage from '../../../Component/Kit/back';
import {GetTicketPriorityKey} from '../../../Utility/Services/TicketPriorityService';
import FilesListModal from './FilesListModal';


export const TicketDetail = (props) => {
    const ticketParams = props.match.params;
    const [ticketDetailData, setTicketDetailData] = useState({});
    const [isClose, setIsClose] = useState(false);
    const [visible, setVisible] = useState(false);
    const [reload, setReload] = useState(false);
    const [filesList, setFilesList] = useState([]);
    const [filesModal, setFilesModal] = useState(false);
    const [userId, setUserId] = useState(null);
    const history = useHistory();

    const [configSetting, setConfigSetting] = useState({
        department: undefined,
        status: undefined,
        priority: 1,
        isUploadable: null,
        adminId: null,
        admin: '',
        assignedAdminId: ''
    });


    const closeTicket = () => {
        Swal.fire({
            title: 'آیا مطمئن هستید؟',
            text: 'آیا از بستن تیکت مورد نظر اطمینان دارید؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText: 'انصراف',
            cancelButtonColor: '#d33',
            confirmButtonText: 'بله، مطمئن هستم',
        }).then((result) => {
            if (result.isConfirmed) {
                let body = {
                    department: GeTicketDepartmentKey(ticketDetailData.department),
                    status: 5,
                    priority: GetTicketPriorityKey(ticketDetailData.priority),
                    isUploadable: ticketDetailData.isUploadable,
                    adminId: ticketDetailData.adminId,
                    admin: ticketDetailData.admin,
                    assignedAdminId: ticketDetailData.assignedAdminId
                };
                setConfigSetting(body);

                ApiClient.Put(Urls.GetTicketByAdmin(ticketParams.id), body).then(
                    (res) => {
                        toast.success('تیکت با موفقیت بسته شد.');
                        setIsClose(true);
                        history.push('/ticket/list');
                    }
                );
            }
        });
    };

    const showConfig = () => {
        setVisible(true);
    };


    const deleteConfirm = (replyId) => {
        Swal.fire({
            title: "آیا مطمئن هستید؟",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.DeleteTicketByAdmin(ticketParams.id, replyId))
                    .then(res => {
                        callApi()
                        toast.success("تغییرات  با موفقیت ثبت شد.");
                    })
            }
        })
    }


    const callApi = useCallback(() => {
        ApiClient.Get(Urls.GetTicketByAdmin(ticketParams.id)).then((res) => {
            const dataList = res.result;
            dataList.status === 5 ? setIsClose(true) : setIsClose(false);
            setConfigSetting({
                assignedAdminId: dataList.assignedAdminId,
                department: dataList.department,
                status: dataList.status,
                priority: dataList.priority,
                isUploadable: dataList.isUploadable,
                adminId: dataList.adminId,
                admin: dataList.admin,
            });

            setUserId(dataList.userId);

            setTicketDetailData({
                ...dataList,
                contents: dataList.contents.reverse(),
            });
            const files = [];
            dataList.contents.forEach((el) => {
                if (el.attachedFileUrl) {
                    files.push(el.attachedFileUrl);
                }
            });
            setFilesList(files);
        });
    }, [ticketParams.id])


    useEffect(() => {
        callApi()
        const interval = setInterval(() => {
            callApi()
        }, 15000);
        return () => clearInterval(interval);
    }, [isClose, reload, callApi]);


    return (
        <WrapperLayout>
            <div className='c-wrapper'>
                <HeaderContent className='justify-content-between'>
                    <div className='d-flex'>
                        {!isClose && (
                            <StyledButton
                                btnStyle='greenBtn mr-2'
                                onClick={() => showConfig()}
                            >
                                نمایش تنظیمات
                            </StyledButton>
                        )}
                        {!isClose && (
                            <StyledButton
                                btnStyle={`redBtn ${isClose ? 'hide' : 'show'}`}
                                onClick={() => closeTicket()}
                            >
                                بستن تیکت
                            </StyledButton>
                        )}
                    </div>

                    <BackToPage/>
                </HeaderContent>

                <MainContent>
                    <div className='row ticket-top-section'>
                        <div className='col-lg-4 col-md-12 col-12 text-right'>
                            <TickeInfo
                                ticketDetailData={ticketDetailData}
                                filesModal={() => setFilesModal(true)}
                                fileLength={filesList.length}
                            />
                        </div>

                        <div className='col-lg-8 col-md-12 col-12 pr-0 chat-box text-right'>
                            <div className='border-box'>
                                <CatList
                                    deleteConfirm={deleteConfirm}
                                    commentList={ticketDetailData.contents}
                                    userId={ticketDetailData.userId}
                                />

                            </div>
                        </div>
                    </div>
                    {
                        <ReplyToTicket
                            userId={userId}
                            userTicketId={ticketParams.id}
                            setReload={setReload}
                            reload={reload}
                        />
                    }
                </MainContent>
            </div>

            <Modal centered show={visible} onHide={() => {
                setVisible(true)
            }}>
                <Modal.Header>
                    <Modal.Title>تنظیمات</Modal.Title>
                    <i onClick={() => {
                        setVisible(false)
                    }} className="close-btn fa fa-xmark"/>
                </Modal.Header>
                <Modal.Body>
                    <ModifyTicketSettingModal
                        ticketReply={ticketDetailData}
                        setTicketReply={setTicketDetailData}
                        ticketID={ticketParams.id}
                        setVisible={setVisible}
                        configSetting={configSetting}
                        setConfigSetting={setConfigSetting}
                    />
                </Modal.Body>

            </Modal>

            <FilesListModal
                visible={filesModal}
                files={filesList}
                onClose={() => setFilesModal(false)}
            />
        </WrapperLayout>
    );
};
