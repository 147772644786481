import React, { useEffect } from 'react'
import { useState } from 'react'
import { StyledButton } from '../../../Component/Kit/Button';

const CreditPackageItemModal = ({ data, onCloseModal }) => {
  const [creditPackageItem, setCreditPackageItem] = useState({});
  const creditPackageValue = {
    description: "نام پکیج",
    netPriceString: "قیمت اصلی پکیج",
    discountPercentString: 'درصد تخفیف',
    creditCount: "تعداد اعتبار بسته",
    isActiveValue: 'وضعیت',
    soldCount: "تعداد خریداری شده",
  }

  useEffect(() => {
    Object.entries(data).forEach(([item, value]) => {
      switch (item) {
        case 'isActive':
          data.isActiveValue = data.isActive ? 'فعال' : 'غیرفعال';
          break;
        default:
          data[item] = value;
          break;
      }
    })
    setCreditPackageItem(data);
  }, [data]);

  return (
    <>
      <div className="custom-modal-body">
        <ul className="params text-right w-100">
          {
            creditPackageValue &&
            Object.entries(creditPackageValue).map(([item, value]) => (
              <li className="params__list" key={item}>
                <div className="params__list__label">
                  <span>{value}</span>
                </div>
                <div className="params__list__value">
                  <span className="params__list__label--white">{creditPackageItem[item]}</span>
                </div>
              </li>
            ))
          }
        </ul>

        <div className="d-flex justify-content-center w-100">
          <StyledButton
            width="35%"
            btnStyle="grayBtn"
            type="reset"
            onClick={() => onCloseModal(false)}
          >
            بستن
          </StyledButton>
        </div>
      </div>
    </>
  )
}

export default CreditPackageItemModal