import React from 'react'
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import { Loader } from '../../../Component/Shared/Loader';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import DateConvert from '../../../Utility/Services/date-convert';
import GetProvider from '../../../Utility/Services/provider';
import Table from '../../../Component/Shared/Table/Table';
import { SeprateNumberWithComma } from '../../../Utility/Services/SeprateNumberWithComma';
import Modal from '../../../Component/Kit/Modal';
import ModifyProviderThresholdModal from '../ModifyProviderThresholdModal';
import ProviderAmountModal from '../ProviderAmountModal';
import { StyledButton } from '../../../Component/Kit/Button';

export const ProvidersCreditList = () => {
    const [loading, setLoading] = useState(true);
    const [providersCreditList, setProvidersCreditList] = useState([]);
    const [providerThresholdModal, setProviderThresholdModal] = useState(false);
    const [selectedProviderItem, setSelectedProviderItem] = useState({});
    const [providerAmountItemModal, setProviderAmountItemModal] = useState(false);
    const [selectedProviderAmountData, setSelectedProviderAmountData] = useState(false);
    const [balanceUpdateTime, setBalanceUpdateTime] = useState('');
    const [loadingFlag, setLoadingFlag] = useState(false);
    const tableConfig = {
        header: {
            providerName: 'عنوان ارائه‌دهنده',
            balance: 'اعتبار موجود',
            fifteenMinutesUsage: '15 دقیقه اخیر',
            oneHourUsage: '60 دقیقه اخیر',
            dayUsageValue: 'روز جاری',
            thresholdValue: 'کمینه اطلاع‌رسانی',
            notifiedAt: "آخرین اطلاع‌رسانی",
        },
        actions: {
            edit: {
                icon: "fa fa-edit",
            },
            setting: {
                icon: "fa fa-cog",
            },
        },
    };

    function tableActions(items) {
        switch (items.actionName) {
            case 'edit':
                setProviderAmountItemModal(true);
                setSelectedProviderAmountData(items.data)
                break;
            case 'setting':
                setProviderThresholdModal(true);
                setSelectedProviderItem(items.data)
                break;
            default:
                break;
        }
    }

    const GetProvidersCredit = useCallback(() => {
        ApiClient.Get(Urls.GetProvidersCredit())
            .then(res => {
                let dataItem = res?.result.balances;
                dataItem.map((item) => {
                    item.providerName = GetProvider(item.providerId);
                    item.balance = SeprateNumberWithComma(item.balance);
                    item.thresholdValue = SeprateNumberWithComma(item.threshold);
                    item.notifiedAt = item.notifiedAt ? DateConvert(item.notifiedAt) : '-';
                    item.fifteenMinutesUsage = SeprateNumberWithComma(item.fifteenMinutesUsage);
                    item.oneHourUsage = SeprateNumberWithComma(item.oneHourUsage);
                    item.dayUsageValue = item.changeIn24Hours > 0
                        ? `${SeprateNumberWithComma(item.dayUsage)} 
                            <span class="badge badge-success badge-sort"> ${Math.abs(item.changeIn24Hours)}% <i class="fa fa-sort-up"/>
                            </span>`
                        : item.changeIn24Hours < 0
                            ? `${SeprateNumberWithComma(item.dayUsage)} 
                            <span class="badge badge-danger badge-sort"> ${Math.abs(item.changeIn24Hours)}% <i class="fa fa-sort-down"/>
                            </span>`
                            : `${SeprateNumberWithComma(item.dayUsage)} 
                            <span class="badge badge-warning badge-sort"> ${Math.abs(item.changeIn24Hours)}% 
                            </span>`
                    return item;
                })
                setBalanceUpdateTime(DateConvert(res.result.updatedAt));
                setProvidersCreditList(dataItem);
                setLoading(false);
            })
    }, [])

    const updateHandler = () => {
        GetProvidersCredit();
        setLoadingFlag(true);
        setTimeout(() => {
            setLoadingFlag(false);
        }, 30000);
    };

    useEffect(() => {
        GetProvidersCredit();
    }, [])

    return (
        <>
            <WrapperLayout>

                <HeaderContent className='d-flex flex-row-reverse justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <div className='mr-3 rtl'>
                            <span className='fo-12 mr-2'>آخرین بروزرسانی: </span>
                            <span>{balanceUpdateTime && balanceUpdateTime}</span>
                        </div>

                        <StyledButton
                            btnStyle='greenBtn'
                            onClick={() => updateHandler()}
                            disabled={loadingFlag}
                        >
                            بروزرسانی
                        </StyledButton>
                    </div>
                </HeaderContent>

                <MainContent>
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout>
                            <h5>اعتبار ارائه‌دهندگان</h5>
                            <span>در این بخش می‌توانید اعتبار پیامکی را به تفکیک ارائه‌دهندگان و آستانه اطلاع‌رسانی مشاهده نمایید.</span>
                        </PageTitleLayout>
                        <div>
                            {loading === true
                                ?
                                <Loader />
                                :
                                <Table
                                    data={providersCreditList}
                                    config={tableConfig}
                                    actionDispatched={tableActions}
                                />
                            }
                        </div>
                    </div>
                </MainContent>
            </WrapperLayout>

            <Modal
                onClose={() => setProviderThresholdModal(false)}
                visible={providerThresholdModal}
                onCancel={() => setProviderThresholdModal(false)}
                headerTitle="کمینه پیش ‌فرض اطلاع‌رسانی از کمبود اعتبار"
            >
                <ModifyProviderThresholdModal
                    onCloseModal={(e) => setProviderThresholdModal(e)}
                    onSubmit={() => GetProvidersCredit()}
                    selectedProviderItem={selectedProviderItem}
                />

            </Modal>

            <Modal
                onClose={() => setProviderAmountItemModal(false)}
                onCancel={() => setProviderAmountItemModal(false)}
                visible={providerAmountItemModal}
                headerTitle={`جزيیات اعتبار در ${selectedProviderAmountData.providerName}`}

            >
                <ProviderAmountModal
                    selectedProviderAmountData={selectedProviderAmountData}
                    onCloseModal={(e) => setProviderAmountItemModal(e)}
                    onSubmit={() => GetProvidersCredit()}
                />
            </Modal>
        </>
    )
}

export default ProvidersCreditList;
