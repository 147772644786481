import styled from "styled-components";
import Col from "../../Kit/Column";

export const LoaderWrapper = styled.div`
  min-height: 20vh;
  position: relative;
  width : 100%;
  height : 100%;
`
export const LoaderContainer = styled(Col)`
  margin: 0 auto;
  width: 60px;
  height: 35px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  > div {
    height: 100%;
    width: 6px;
    display: inline-block;
    float: left;
    margin-left: 2px;
    -webkit-animation: delay 0.8s infinite ease-in-out;
    animation: delay 0.8s infinite ease-in-out;
  }
  
  .bar1{
    background-color: ${props => props.theme.lightGreen} ;
  }
  .bar2{
    background-color: ${props => props.theme.lightGreen} ;
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .bar3{
    background-color: ${props => props.theme.lightGreen} ;
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .bar4{
    background-color: ${props => props.theme.lightGreen} ;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .bar5{
    background-color: ${props => props.theme.lightGreen} ;
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .bar6{
    background-color: ${props => props.theme.lightGreen} ;
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  @-webkit-keyframes delay {
  0%, 40%, 100% { 
      -webkit-transform: scaleY(0.05)
      
       }  
  20% { -webkit-transform: scaleY(1.0) }
  0% {
    background-color: ${props => props.theme.primary} ;
  }
}

@keyframes delay {
  0%, 40%, 100% { 
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
  0% {
    background-color: ${props => props.theme.primary} ;
  }
}
`
