import React, {useEffect} from "react";
import {NavLink, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import BackToPage from "../../../../Component/Kit/back";
import {HeaderContent, MainContent, WrapperLayout} from "../../../../Component/Kit/Layout";
import {connect} from "react-redux";
import ModifyGuestInformation from "./ModifyGuestInformation";
import ModifyGuestDocument from "./ModifyGuestDocument";

function EditGuest({data}) {
  const history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    if (!Object.entries(data).length) {
      history.push(`/guest/item/${match.params.id}`);
    }
  }, [data, match.params.id]);
  return (
    <WrapperLayout>
      <div className="c-wrapper">
        <HeaderContent className="justify-content-between">
          <span className="fakeContainer"></span>
          <BackToPage toPage={`/guest/item/${match.params.id}`}/>
        </HeaderContent>
      </div>

      <MainContent>
        <ul className="user-tab-menu">
          <li>
            <NavLink activeClassName="is-active" to={`${match.url}/info`}>
              ویرایش اطلاعات کاربر مهمان
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="is-active" to={`${match.url}/document`}>
              ویرایش مستندات
            </NavLink>
          </li>
        </ul>

        <div className="px-3">
          <Switch>
            <Route path={`${match.path}/info`}>
              <ModifyGuestInformation />
            </Route>
            <Route path={`${match.path}/document`}>
              <ModifyGuestDocument />
            </Route>
          </Switch>
        </div>
      </MainContent>
    </WrapperLayout>
  );
}

const mapStateToProps = (state) => ({
  data: state.guestData,
});

export default connect(mapStateToProps)(EditGuest);
