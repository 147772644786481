import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import WhiteSmsIcon from "../../../../Component/Icon/WhiteSmsIcon";
import BackToPage from "../../../../Component/Kit/back";
import { StyledButton } from "../../../../Component/Kit/Button";
import {
  HeaderContent,
  MainContent,
  WrapperLayout,
} from "../../../../Component/Kit/Layout";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import DateConvert from "../../../../Utility/Services/date-convert";
import { BuyerDetails } from "../../TransactionDetails/BuyerDetails";
import { SellerDetails } from "../../TransactionDetails/SellerDetails";
import TransactionTable  from "../../TransactionDetails/TransactionTable";
import { AddNumberToInvoiceModal } from "./AddNumberToInvoiceModal";
import { EditInvoiceDiscountModal } from "./EditInvoiceDiscountModal";
import { SepidarModal } from "./SepidarModal";
import { EditDetailInvoiceModal } from "./EditDetailInvoiceModal";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const OfficialInvoiceItem = (props) => {
  const officialInvoiceId = props.match.params.id;
  const isReturn = props.match.params.isReturn.replace("isReturn=", "");
  const history = useHistory();
  const [singleOfficialInvoice, setSingleOfficialInvoice] = useState({});
  const [invoiceTableDetails, setInvoiceTableDetails] = useState({});
  const [editableInvoiceData, setEditableInvoiceData] = useState(null);
  const [addNumberToInvoiceModalVisible, setAddNumberToInvoiceModalVisible] =
    useState(false);
  const [editInvoiceDiscountModalVisible, setEditInvoiceDiscountModalVisible] =
    useState(false);
  const [sepidarModal, showSepidarModal] = useState(false);
  const [editDetailsModal, showEditDetailsModal] = useState(false);
  const [sepidarInvoice, setSepidarInvoice] = useState(0);
  const [editKey, setEditKey] = useState(0);
  const [vatPercent, setVatPercent] = useState();

  const officialInvoiceName = {
    number: "شماره فاکتور",
    createdAt: "تاریخ فاکتور",
    type: "نوع فاکتور",
  };
  const saveHandler = (isSave) => {
    if (isSave) {
      getData();
    }
  };
  const getData = () => {
    const params = {
      isReturn: isReturn,
    };
    ApiClient.Get(Urls.GetOfficialInvoices(officialInvoiceId), params).then(
      (res) => {
        let responseData = res.result;
        mapData(responseData)
        setSepidarInvoice(responseData?.number)
        setVatPercent(responseData.details[0].vatPercent);
      }
    );

  };

  const mapData = (responseData) => {
    const response = responseData?.key ? responseData.invoice : responseData
    setEditKey(responseData?.key);
    if (response.number) {
      setEditableInvoiceData({
        number: response.number,
        date: response.createdAt,
      });
    }
    response.address = [response.provinceName, response.cityName, response.address].join("  ")
    response.createdAt = DateConvert(response.createdAt);
    setSingleOfficialInvoice(JSON.parse(JSON.stringify(response)));
    setInvoiceTableDetails(JSON.parse(JSON.stringify(response)));
  }
  useEffect(() => {
    getData();
  }, []);

  const addNumberToInvoice = () => {
    setAddNumberToInvoiceModalVisible(true);
  };
  const editDiscountModalHandler = () => {
    setEditInvoiceDiscountModalVisible(true);
  };
  const returnInvoiceHandler = () => {
    history.push(
      `/financial/invoice/returnInvoice?invoiceId=${officialInvoiceId}&userId=${invoiceTableDetails.userId}`
    );
  };
  const cancelInvoiceHandler = () => {
    Swal.fire({
      title: '',
      text: `آیا از ابطال این فاکتور مطمئن هستید؟`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonText: "انصراف",
      cancelButtonColor: "#d33",
      confirmButtonText: "بله، مطمئن هستم",
    }).then(result => {
      if (result.isConfirmed) {
        ApiClient.Post(Urls.cancelInvoice(invoiceTableDetails.userId, officialInvoiceId))
          .then(res => {
            toast.success('فاکتور ابطال شد.');
            getData()
          })
      }
    })

  }


  const removeInvoiceHandler = () => {
    Swal.fire({
      title: '',
      text: `آیا از حذف این فاکتور مطمئن هستید؟`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonText: "انصراف",
      cancelButtonColor: "#d33",
      confirmButtonText: "بله، مطمئن هستم",
    }).then(result => {
      if (result.isConfirmed) {
        ApiClient.Delete(Urls.deleteInvoice(officialInvoiceId))
          .then(res => {
            toast.success('فاکتور حذف شد.');
            history.goBack()
          })
      }
    })

  }


  const onEditInvoice = () => {
    ApiClient.Put(
      Urls.EditInvoiceDetail(invoiceTableDetails.id),
      JSON.stringify(editKey)
    ).then((res) => {
      toast.success("تغییرات فاکتور با موفقیت انجام شد .");
      getData()
    });
  };
  const onAddNumberToInvoice = () => {
    getData();
  };
  return (
    <WrapperLayout>
      <div className="c-wrapper">
        <HeaderContent className="justify-content-between">
          {singleOfficialInvoice.canModifyDiscountPrice ? (
            <StyledButton
              btnStyle="greenBtn mr-3"
              onClick={() => editDiscountModalHandler()}
            >
              ویرایش تخفیف
            </StyledButton>
          ) : (
            <span className="fakeContainer"></span>
          )}

          <BackToPage />
        </HeaderContent>
        <MainContent>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-center">
                <div className="invoice-title">
                  <h4>
                    {isReturn === "true"
                      ? "صورت حساب برگشت فروش کالا"
                      : "صورتحساب فروش کالا"}
                  </h4>
                </div>
              </div>
              {invoiceTableDetails && (
                <>
                  <div className="d-flex justify-content-between align-items-center px-3">
                    <WhiteSmsIcon className="whiteSmsIcon" />

                    <div className="invoice-details d-flex flex-column">
                      <span className="text-center mb-2">
                        {officialInvoiceName.number} :{" "}
                        {invoiceTableDetails.number}
                      </span>
                      <span className="text-center mb-2">
                        {officialInvoiceName.createdAt} :
                        {invoiceTableDetails.createdAt}
                      </span>
                      {invoiceTableDetails.type === 3 &&
                        <span className="text-center mb-2 red-text bold">
                          ابطال شده
                        </span>
                      }
                    </div>
                  </div>

                  <SellerDetails />

                  <BuyerDetails singleInvoiceDetails={invoiceTableDetails} />

                  <TransactionTable
                    singleInvoiceDetails={invoiceTableDetails}
                    vatPercent={vatPercent}
                  />

                  <div className="d-flex align-items-center flex-row-reverse flex-wrap">
                    {Object.keys(invoiceTableDetails).length > 0 && (
                      <StyledButton
                        btnStyle="greenBtn mr-3 mb-3"
                        onClick={() => addNumberToInvoice()}
                      >
                        {!invoiceTableDetails.number ? "تایید فاکتور" : "ویرایش شماره فاکتور"}
                      </StyledButton>
                    )}



                    {Object.keys(invoiceTableDetails).length > 0 && invoiceTableDetails.number && (
                      <StyledButton
                        btnStyle="greenBtn mr-3 mb-3"
                        disabled={!singleOfficialInvoice.hasOwnProperty('isLegal')}
                        onClick={() => {
                          showSepidarModal(true)
                        }}
                      >
                        ثبت در سپیدار
                      </StyledButton>
                    )}
                    {editKey ?
                      (
                        <>

                          <StyledButton
                            btnStyle="greenBtn mr-3 mb-3"
                            onClick={onEditInvoice}
                          >
                            ثبت نهایی تغییرات فاکتور
                          </StyledButton>
                          <StyledButton
                            btnStyle="redBtn mr-3 mb-3"
                            onClick={() => {
                              setEditKey(null)
                              getData()
                            }}
                          >
                            عدم تغییر فاکتور
                          </StyledButton>
                        </>
                      )
                      :

                      <StyledButton
                        btnStyle="greenBtn mr-3 mb-3"
                        onClick={() => {
                          showEditDetailsModal(true)
                        }}
                      >
                        ویرایش مشخصات فاکتور
                      </StyledButton>


                    }


                    {isReturn !== "true" &&
                      invoiceTableDetails.number &&
                      !invoiceTableDetails.hasReturn && (
                        <StyledButton
                          btnStyle="Btn mr-3 mb-3"
                          onClick={() => returnInvoiceHandler()}
                        >
                          ثبت فاکتور برگشتی
                        </StyledButton>
                      )}



                    {invoiceTableDetails.number && invoiceTableDetails.type !== 3 &&
                      <StyledButton
                        btnStyle="redBtn mr-3 mb-3"
                        onClick={() => cancelInvoiceHandler()}
                      >
                        ابطال
                      </StyledButton>
                    }


                    <StyledButton
                      btnStyle="redBtn mr-3 mb-3"
                      onClick={() => {
                        removeInvoiceHandler()
                      }}
                    >
                      حذف
                    </StyledButton>

                    {
                      <EditInvoiceDiscountModal
                        officialInvoiceId={officialInvoiceId}
                        data={singleOfficialInvoice}
                        isSave={saveHandler}
                        setVisible={setEditInvoiceDiscountModalVisible}
                        visible={editInvoiceDiscountModalVisible}
                        onCancel={() =>
                          setEditInvoiceDiscountModalVisible(false)
                        }
                        onClose={() =>
                          setEditInvoiceDiscountModalVisible(false)
                        }
                      />
                    }
                    {
                      <AddNumberToInvoiceModal
                        officialInvoiceId={officialInvoiceId}
                        isReturn={isReturn}
                        data={editableInvoiceData}
                        setVisible={setAddNumberToInvoiceModalVisible}
                        visible={addNumberToInvoiceModalVisible}
                        sepidarData={sepidarInvoice}
                        onSubmit={onAddNumberToInvoice}
                        onCancel={() =>
                          setAddNumberToInvoiceModalVisible(false)
                        }
                        onClose={() => setAddNumberToInvoiceModalVisible(false)}
                      />
                    }
                    {
                      <SepidarModal
                        singleOfficialInvoice={singleOfficialInvoice}
                        officialInvoiceId={officialInvoiceId}
                        data={sepidarInvoice}
                        setVisible={showSepidarModal}
                        visible={sepidarModal}
                        onCancel={() => showSepidarModal(false)}
                        onClose={() => showSepidarModal(false)}
                      />
                    }
                    {<EditDetailInvoiceModal
                      data={invoiceTableDetails}
                      setVisible={showEditDetailsModal}
                      visible={editDetailsModal}
                      onCancel={() => showEditDetailsModal(false)}
                      onClose={() => showEditDetailsModal(false)}
                      onSubmit={(res) => mapData(res.result)}
                    />}
                  </div>
                </>
              )}
            </div>
          </div>
        </MainContent>
      </div>
    </WrapperLayout>
  );
};

export default OfficialInvoiceItem;
