function EnumFlag(flags, value) {
  let res = [];
  flags.sort(function (a, b) {
    return b - a;
  });
  flags.forEach((item) => {
    let x = value - item;
    if (x >= 0) {
      value = x;
      res.push(item);
    }
  });
  return res;
}

export default EnumFlag;
