
import * as React from "react"

function TechnicalTicketsIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={110.386}
            height={76}
            {...props}
        >
            <g data-name="Group 152135">
                <path
                    data-name="Icon awesome-envelope-open-text"
                    d="M47.906 44.2h17.188a1.718 1.718 0 0 0 1.719-1.719v-1.715a1.718 1.718 0 0 0-1.719-1.719H47.906a1.718 1.718 0 0 0-1.719 1.719v1.719a1.718 1.718 0 0 0 1.719 1.715Zm-1.719 8.6a1.718 1.718 0 0 0 1.719 1.719h17.188a1.718 1.718 0 0 0 1.718-1.719v-1.722a1.718 1.718 0 0 0-1.719-1.719H47.906a1.718 1.718 0 0 0-1.719 1.719ZM56.5 65.809a8.581 8.581 0 0 1-5.034-1.632L29 47.948v22.9A5.156 5.156 0 0 0 34.156 76h44.688A5.156 5.156 0 0 0 84 70.844v-22.9L61.534 64.177a8.589 8.589 0 0 1-5.034 1.632ZM82.024 38.5a197.27 197.27 0 0 0-3.181-2.45v-4.737a5.156 5.156 0 0 0-5.156-5.156h-8.33l-.971-.7C62.58 24.134 58.992 20.963 56.5 21c-2.492-.038-6.079 3.133-7.886 4.452l-.971.7h-8.331a5.156 5.156 0 0 0-5.156 5.156v4.742c-1.329 1-2.23 1.7-3.181 2.45A5.156 5.156 0 0 0 29 42.562v1.144l10.312 7.45V31.313h34.375v19.843L84 43.706v-1.144a5.156 5.156 0 0 0-1.976-4.062Z"
                    fill="#3f3d56"
                />
                <path
                    data-name="Icon awesome-cog"
                    d="m110.011 29.126-2.636-1.522a11.921 11.921 0 0 0 0-4.345l2.636-1.522a.748.748 0 0 0 .34-.866 15.414 15.414 0 0 0-3.385-5.851.745.745 0 0 0-.916-.142l-2.637 1.518a11.68 11.68 0 0 0-3.763-2.176v-3.035a.741.741 0 0 0-.581-.724 15.558 15.558 0 0 0-6.758 0 .741.741 0 0 0-.582.724v3.045a12.049 12.049 0 0 0-3.763 2.172l-2.63-1.522a.735.735 0 0 0-.916.142 15.321 15.321 0 0 0-3.385 5.855.74.74 0 0 0 .34.866l2.636 1.522a11.921 11.921 0 0 0 0 4.345l-2.636 1.523a.748.748 0 0 0-.34.866 15.414 15.414 0 0 0 3.385 5.855.745.745 0 0 0 .916.142l2.636-1.522a11.68 11.68 0 0 0 3.763 2.172v3.045a.741.741 0 0 0 .582.724 15.558 15.558 0 0 0 6.758 0 .741.741 0 0 0 .582-.724v-3.045a12.049 12.049 0 0 0 3.763-2.172l2.636 1.522a.735.735 0 0 0 .916-.142 15.321 15.321 0 0 0 3.385-5.855.764.764 0 0 0-.347-.873Zm-14.322 1.257a4.951 4.951 0 1 1 4.951-4.951 4.958 4.958 0 0 1-4.951 4.951Z"
                    fill="#a7abc3"
                />
                <path data-name="Rectangle 1849" fill="none" d="M0 0h29v54H0z" />
            </g>
        </svg>
    )
}

export default TechnicalTicketsIcon;