import  useCookies  from '@js-smart/react-cookie-service';
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { LocationDataAction } from "../../../Actions/locationData";
import { UserLegalDataAction } from "../../../Actions/UserLagalData";
import { UserProfileDataAction } from "../../../Actions/UserProfileData";
import { Loader } from "../../../Component/Shared/Loader";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {
  CompanyActivity,
  CompanyType,
} from "../../../Utility/Services/company";
import DateConvert from "../../../Utility/Services/date-convert";
import ChangeUserMobile from "./ChangeUserMobile";
// import { ConvertUserType } from "./ConvertUserType";
import UserInformation from "./UserInformation";

function UserProfile() {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const [userData, setuserData] = useState({});
  const { setCookie } = useCookies();
  const [loading, setLoading] = useState(true);
  const [convertUserTypeModal, ShowConvertUserTypeModal] = useState(false);
  const [changeUserMobileModal, setChangeUserMobileModal] = useState(false);
  const [provinceItem, setProvinceItem] = useState([]);
  const [cityItem, setCityItem] = useState([]);

  const getData = useCallback(async () => {
    try {
      const getProvince = await ApiClient.Get(Urls.getProvince());
      let userProfile = await ApiClient.Get(Urls.userProfile(match.params.id));
      let data = Object.assign({}, userProfile.result);
      const getCity = await ApiClient.Get(Urls.getCity(data.provinceId));
      setProvinceItem(getProvince.result);
      setCityItem(getCity.result);
      data.accountVerifiedAt = data.accountVerifiedAt
        ? DateConvert(data.accountVerifiedAt)
        : DateConvert(data.createdAt);
      data.createdAt = DateConvert(data.createdAt);
      data.additionalMobile = data.additionalMobile
        ? `0${data.additionalMobile}`
        : data.additionalMobile;
      data.mobile = `0${data.mobile}`;
      data.isActiveName = data.isActive ? "فعال" : "غیرفعال";
      data.isLegalName = data.isLegal ? "حقوقی" : "حقیقی";
      data.isSuspendedName = data.isSuspended ? "Block" : "Unblock";
      data.companyActivityFieldName = CompanyActivity(
        data.companyActivityField
      );
      data.isMaleName = data.isMale ? "مرد" : "زن";
      if (data.legalData) {
        data.legalData.companyTypeName = CompanyType(
          data.legalData.companyType
        );
      }

      const storeProfileData = {
        email: data.email,
        userName: data.userName,
        mobile: data.mobile,
        additionalMobile: data.additionalMobile,
        isLegal: data.isLegal,
        firstName: data.firstName,
        lastName: data.lastName,
        fatherName: data.fatherName,
        nationalId: data.nationalId,
        certificateNo: data.certificateNo,
        birthDate: data.birthDate,
        isMale: data.isMale,
        postalCode: data.postalCode,
        provinceId: data.provinceId,
        cityId: data.cityId,
        address: data.address,
        phoneNumber: data.phoneNumber,
        nationalCardUploadedURL: data.nationalCardUploadedURL,
        gazetteUploadedURL: data.gazetteUploadedURL,
        isVIP: data.isVIP,
        avenue:data.avenue,
        alley: data.alley,
        plaque: data.plaque,
      };
      const legalData = {
        isLegal: data.isLegal,
        companyActivityField: data.companyActivityField,
        ...data.legalData,
      };
      setuserData(data);
      // dispatch(UserProfileDataAction(data));
      dispatch(UserProfileDataAction(storeProfileData));
      dispatch(UserLegalDataAction(legalData));
      dispatch(
        LocationDataAction({
          provinceList: getProvince.result,
          cityList: getCity.result,
        })
      );
      setLoading(false);
    } catch (err) {}
  }, [match.params.id]);

  const loginAsUser = () => {
    ApiClient.Post(Urls.loginAsUser(match.params.id)).then((res) => {
      setCookie("access_token", res.result.accessToken, {
        domain: ".sms.ir",
        path: "/",
      });
      setCookie("refresh_token", res.result.refreshToken, {
        domain: ".sms.ir",
        path: "/",
      });
      window.open("https://app.sms.ir/dashboard/", "_blank");
    });
  };

  const onChangeUserType = () => {
    getData().then();
  };

  useEffect(() => {
    getData().then();
  }, [getData]);

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="row">
          <UserInformation
            userData={userData}
            cityItem={cityItem}
            provinceItem={provinceItem}
          />

          <div className="col-12 p-0">
            <ul className="section-list column-5-part">
              <li>
                <Link
                  to={`/user/${match.params.id}/profile/edit/info`}
                  className="section-list_item"
                >
                  <i className="fas fa-user-edit"></i>
                  ویرایش پروفایل
                </Link>
              </li>
              <li>
                <Link
                  to={`/user/${match.params.id}/profile/edit/document`}
                  className="section-list_item"
                >
                  <i className="fas fa-file-edit"></i>
                  ویرایش مستندات
                </Link>
              </li>
              <li>
                <Link
                  className="section-list_item"
                  to={`/user/${match.params.id}/profile/type`}>
                  <i className="fas fa-file-user"></i>
                  تغییر نوع حساب کاربری
                </Link>
              </li>
              <li>
                <p
                  className="section-list_item"
                  role="button"
                  aria-hidden="true"
                  onClick={() => setChangeUserMobileModal(true)}
                >
                  <i className="fas fa-phone-square-alt"></i>
                  تغییر شماره موبایل
                </p>
              </li>
              {userData.isActive && !userData.isSuspended ? (
                <li>
                  <p
                    className="section-list_item"
                    role="button"
                    disabled={!userData.isActive || userData.isSuspended}
                    onClick={() => loginAsUser()}
                  >
                    <i className="fas fa-sign-in-alt"></i>
                    ورود به حساب کاربر
                  </p>
                </li>
              ) : (
                <li className="deactive">
                  <p className="section-list_item">
                    <i className="fas fa-sign-in-alt"></i>
                    ورود به حساب کاربر
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}

      <>
        {/* <ConvertUserType
          onSubmit={onChangeUserType}
          data={userData}
          userId={match.params.id}
          setVisible={ShowConvertUserTypeModal}
          visible={convertUserTypeModal}
          onCancel={() => ShowConvertUserTypeModal(false)}
          onClose={() => ShowConvertUserTypeModal(false)}
        /> */}
        <ChangeUserMobile
          onSubmit={onChangeUserType}
          userId={match.params.id}
          setVisible={setChangeUserMobileModal}
          visible={changeUserMobileModal}
          onClose={() => setChangeUserMobileModal(false)}
          onCancel={() => setChangeUserMobileModal(false)}
        />
      </>
    </>
  );
}

export default connect()(UserProfile);
