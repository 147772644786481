import React from 'react'
import {useEffect} from 'react';
import {useState} from 'react';

export const SellerDetails = (props) => {
    const {invoce} = props;
    const [sellerDetailLineOne, setSellerDetailLineOne] = useState({});
    const [sellerDetailLineTwo, setSellerDetailLineTwo] = useState({});

    useEffect(() => {
        if (invoce === "unOfficialInvoice") {
            setSellerDetailLineOne({
                "نام فروشنده ": "سامانه پیام‌دهی"
            })
        } else {
            setSellerDetailLineOne({
                "نام شخص حقیقی/حقوقی ": "ایده پردازان الکترونیک جوانان",
                "شماره اقتصادی": "10320315944",
                "شماره ثبت": "380483",
                "شناسه ملی": "10320315944",
            })
            setSellerDetailLineTwo({
                "نشانی": "تهران خيابان آزادي ناحيه نوآوري شريف بلوار اكبري ابتداي كوچه اتكا پلاك۷ برج فناوري طبقه پنجم",
                "کدپستی": "1458834954",
                "شماره تلفن": "0212853",
            })
        }
    }, [])


    return (
        <>
            <table className="factor-table">
                <tbody>
                <tr className="amber-bg">
                    <td className="py-2" colSpan={sellerDetailLineOne.length}>مشخصات فروشنده</td>
                </tr>
                <tr>
                    <td>
                        <table className='w-100'>
                            <tbody>
                            <tr>
                                {
                                    Object.keys(sellerDetailLineOne)?.length > 0 &&
                                    Object.entries(sellerDetailLineOne)?.map(([item, value]) => (
                                        <td key={`item${value + item}`} className='text-right'>
                                            <span className="font-weight-bold">{item}</span>
                                            <span className="mr-2">{value}</span>
                                        </td>

                                    ))
                                }
                            </tr>
                            </tbody>
                        </table>
                        {
                            Object.keys(sellerDetailLineTwo).length > 0 &&
                            <table className='w-100'>
                                <tbody>
                                <tr>
                                    {
                                        Object.entries(sellerDetailLineTwo).map(([item, value]) => (
                                            <td key={`item${value}`} className={'text-right pt-3'}>

                                                <span className="font-weight-bold">{item}</span>
                                                <span className="mr-2">{value}</span>
                                            </td>
                                        ))
                                    }
                                </tr>

                                </tbody>
                            </table>
                        }
                    </td>
                </tr>

                </tbody>
            </table>
        </>
    )
}
