import { Formik } from 'formik';
import React from 'react';
import Swal from 'sweetalert2';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { StyledButton } from '../../Kit/Button';
import { StyledForm } from '../../Kit/Form';
import { StyledSwitchBox } from './../../Kit/Form';
import { toast } from 'react-toastify';
import { LineEarnEnum } from '../../../Utility/Model/lines.model';

export const DeleteGiftLineModal = ({ data, dataChange, onCloseModal }) => {
    return (
        <>
            <Formik
                initialValues={{
                    addToFreeGiftLine: false
                }}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    Swal.fire({
                        title: `آیا از حذف خط مطمئن هستید؟`,
                        text: "",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonText: "انصراف",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "بله، مطمئن هستم",
                    }).then(result => {
                        if (result.isConfirmed) {
                            ApiClient.Delete(Urls.deleteUserGiftLines(data.id), JSON.stringify(values.addToFreeGiftLine)).then(() => {
                                toast.success('خط  با موفقیت حذف شد.');
                                dataChange(true);
                            })
                        } else {
                            actions.resetForm()
                        }
                    })
                }}
                onReset={(values, forwardRef) => {
                    onCloseModal(false)
                }}
            >
                {(formik) => (
                    <StyledForm
                        className='px-2'
                        onSubmit={formik.handleSubmit}
                        onReset={formik.handleReset}
                        onBlur={formik.handleBlur}
                    >
                        {(data.earnMethod === LineEarnEnum.Gift) &&
                            <div className="d-flex justify-content-between">
                                <p>افزودن این خط به لیست خطوط هدیه آزاد</p>
                                <StyledSwitchBox
                                    focus={true}
                                    checked={formik.values.addToFreeGiftLine !== undefined
                                        ? formik.values.addToFreeGiftLine
                                        : formik.initialValues.addToFreeGiftLine}
                                    onChange={(e) => formik.setFieldValue('addToFreeGiftLine', e.target.checked)}
                                />
                            </div>
                        }
                        <br />
                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => onCloseModal(false)}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                btnStyle="redBtn"
                                type="submit"
                            >
                                حذف خط
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    )
}

export default DeleteGiftLineModal;
