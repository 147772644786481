import React from "react";
import styled from "styled-components";

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.5rem 0;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .step-root {
    padding-left: 8px;
    padding-right: 8px;

    @media screen and (max-width: 920px) {
     margin-bottom: 1rem;
    }

    .step-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .step-number {
      font-weight: bold;
      font-size: 2.125rem;
      margin-left: 0.625rem;
      color: rgba(50, 71, 92, 0.87);
      margin-bottom: 0;
    }

    .step-title {
      font-weight: 700;
      font-size: 1.2rem;
      margin-bottom: 0.25rem;
      color: #32475c;
    }

    .step-subtitle {
      font-size: 0.85rem;
      margin-bottom: 0;
      color: #32475c;
    }
  }

  .step-item {
    display: flex;
    -webkit-box-align: center;
    align-items: center;

    &.mui-disabled {
      cursor: default;
    }

    &.mui-disabled {
      .step-number {
        color: rgba(50, 71, 92, 0.38);
      }
      P {
        color: #808080;
      }
    }

    &.mui-active {
      .step-label-icon-container {
        flex-shrink: 0;
        display: flex;
        padding-left: 8px;

        div {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border-style: solid;
          border-color: rgb(105, 108, 255);
          border-width: 5px;
          background-color: rgb(255, 255, 255);
        }
      }
    }
  }

  .step-label-icon-container {
    flex-shrink: 0;
    display: flex;
    padding-left: 8px;

    .box-root {
      width: 20px;
      height: 20px;
      border-width: 3px;
      border-radius: 50%;
      border-style: solid;
      border-color: rgba(105, 108, 255, 0.16);
    }

    &.mui-active {
      flex-shrink: 0;
      display: flex;
      padding-left: 8px;

      div {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border-style: solid;
        border-color: rgb(105, 108, 255);
        border-width: 5px;
        background-color: rgb(255, 255, 255);
      }
    }
  }

  .step-connector-line {
    display: block;
    border: 3px solid rgb(189, 189, 189);
    border-radius: 3px;
  }

  .step-connector {
    flex: 1 1 auto;

    &.mui-active {
      .step-connector-line {
        border-color: rgb(105, 108, 255);
      }
    }

    &.mui-disabled {
      .step-connector-line {
        border-color: rgba(105, 108, 255, 0.16);
      }
    }

    @media screen and (max-width: 920px) {
      display: none;
    }
  }
`;
