export const DiscountCodeDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case "DISCOUNT_CODE_DETAILS":
      return { ...state, ...action.data };

    default:
      return state;
  }
};

export default DiscountCodeDetailReducer;
