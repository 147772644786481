import React, {useCallback, useEffect, useState} from 'react';
import {DashboardLayuot} from './styles';
import {Badge} from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';

import moment from 'moment-jalaali';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {HeaderContent, WrapperLayout,} from '../../Component/Kit/Layout';
import ApiClient from '../../Requester/ApiClient';
import Urls from '../../Requester/Urls';
import {RoleCanActivate} from '../../Utility/Services/roleCanActivate';
import {StyledButton} from '../../Component/Kit/Button';
import {Loader} from '../../Component/Shared/Loader';

import NewTicketIcon from '../../Component/Icon/NewTicketIcon';
import PendingLineIcon from '../../Component/Icon/PendingLineIcon';
import PendingTemplatesIcon from '../../Component/Icon/PendingTemplatesIcon';
import PendingGuestForApproveIcon from '../../Component/Icon/PendingGuestForApproveIcon';
import PendingManualPaymentsIcon from '../../Component/Icon/PendingManualPaymentsIcon';
import TechnicalTicketsIcon from "../../Component/Icon/TechnicalTicketsIcon";
import UserAnswerdTicketIcon from '../../Component/Icon/UserAnswerdTicketIcon';
import InProgressTicketsIcon from '../../Component/Icon/InProgressTicketsIcon';
import FinancialTicketsIcon from '../../Component/Icon/FinancialTicketsIcon';
import {SeprateNumberWithComma} from '../../Utility/Services/SeprateNumberWithComma';
import {SystemLineProviderEnum} from "../../Utility/Model/provider-enum.model";
import {groupBy} from "../../Utility/method/groupBy";
import {ProviderList} from "./provider-list";

const Dashboard = ({adminProfile}) => {
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [dashboardItems, setDashboardItems] = useState({});
    const [monitoringData, setMonitoringData] = useState(null);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [ticketList, setTicketList] = useState([]);
    const [otherList, setOtherList] = useState([]);

    const history = useHistory();
    const callApi = useCallback(() => {
        ApiClient.Get(Urls.GetDashboard()).then((response) => {
            setDashboardItems(response.result);
            setLoading(false);
        });
        ApiClient.Get(Urls.GetMonitoringProviderStatus(), {}).then((response) => {
            response.result.map((a, index) => {
                a.name = SystemLineProviderEnum.find(d => d.value === a.providerId).name;
                a.prefix = SystemLineProviderEnum.find(d => d.value === a.providerId).prefix;
            })

            const data = Array.from(groupBy(response.result, item => item.providerId).values())
            setMonitoringData(data)
            setLoading(false);
        });
    }, []);


    const ticketItems = [
        {
            name: 'openTicketCount',
            title: 'تیکت‌های جدید',
            link: '/ticket/list?status=1',
            icon: <NewTicketIcon/>,
        },
        {
            name: 'userAnswerdTicketCount',
            title: 'تیکت‌های با پاسخ کاربر',
            link: '/ticket/list?status=3',
            icon: <UserAnswerdTicketIcon/>,
        },
        {
            name: 'inProgressTicketsCount',
            title: 'تیکت های درحال بررسی',
            link: '/ticket/list?status=4',
            icon: <InProgressTicketsIcon/>,
        },
        {
            name: 'notClosedTicketsCountForFinance',
            title: 'تیکت درحال بررسی مالی',
            link: '/ticket/list?department=3&status=4',
            icon: <FinancialTicketsIcon/>,
        },
        {
            name: 'notClosedTicketsCountForTechnical',
            title: 'تیکت درحال بررسی فنی',
            link: '/ticket/list?department=2&status=4',
            icon: <TechnicalTicketsIcon/>,
        },
        /* {
                name: "notClosedTicketsCountForTechnical",
                title: 'تیکت دپارتمان فنی',
                link: '/ticket/list?department=2',
                icon: <TechnicalTicketsIcon/>
            },
            {
                name: "notClosedTicketsCountForSupport",
                title: 'تیکت دپارتمان پشتیبانی',
                link: '/ticket/list?department=1',
                icon: <SupportTicketsIcon/>
            }, */
    ];

    const listItems = [
        {
            name: 'pendingFastSendTemplatesCount',
            title: 'تایید قالب های در انتظار',
            link: '/fast-send/pending',
            icon: <PendingTemplatesIcon/>,
            accessList: ['supporter'],
        },
        {
            name: 'pendingGuestCount',
            title: 'تایید مهمان های در انتظار',
            link: '/guests/finished',
            icon: <PendingGuestForApproveIcon/>,
            accessList: ['supporter', 'sales'],
        },
        {
            name: 'pendingLinesCount',
            title: 'تایید خطوط در انتظار',
            link: '/line/user-lines/pending',
            icon: <PendingLineIcon/>,
            accessList: ['supporter'],
        },
        {
            name: 'pendingManualPaymentsCount',
            title: 'تایید پرداخت دستی',
            link: '/financial/manual-payment',
            icon: <PendingManualPaymentsIcon/>,
            accessList: ['supporter'],
        },
        {
            name: 'pendingSendViaTowerRequestsCount',
            title: 'ارسال از دکل',
            link: '/send-tower/pending',
            icon: <i className="fas fa-broadcast-tower fa-4x"></i>,
        },
        {
            name: 'pendingSettlementsCount',
            title: 'درخواست های تسویه',
            link: '/sale-cooperation/settlement',
            icon: <i className="fas fa-money-check-edit-alt fa-4x"></i>,
        },

        {
            name: 'pendingUserToVerifyCompletionRequirementCount',
            title: 'تایید اطلاعات کاربران',
            link: '/users/verify-info',
            icon: <i className="fas fa-user fa-4x"></i>,
            accessList: ['supporter', 'sales'],
        },

        {
            name: 'pendingUserServiceLinesCount',
            title: 'خدماتی سازی خطوط',
            link: '/line/user-service-line/pending',
            icon: <i className="fas fa-phone fa-4x"></i>,
            accessList: ['supporter', 'sales'],
        },
    ];

    const clickHandler = (target) => {
        history.push(target);
    };

    const updateHandler = () => {
        callApi();
        setLoadingFlag(true);
        setTimeout(() => {
            setLoadingFlag(false);
        }, 30000);
    };

    useEffect(() => {
        callApi();
        if (adminProfile) {
            listItems.map((m) => {
                m.isShow = m.accessList
                    ? RoleCanActivate(m.accessList, adminProfile.roleId)
                    : true;
                return false;
            });
            ticketItems.map((m) => {
                m.isShow = m.accessList
                    ? RoleCanActivate(m.accessList, adminProfile.roleId)
                    : true;
                return false;
            });
            setTicketList(ticketItems);
            setOtherList(listItems);
        }
        const interval = setInterval(() => {
            callApi();
        }, 900000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminProfile, callApi]);

    return (
        <DashboardLayuot>
            <WrapperLayout>
                <div className='c-wrapper'>
                    <HeaderContent className='d-flex flex-row-reverse justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div className='mr-3 rtl'>
                                <span className='fo-12 mr-2'>آخرین بروزرسانی: </span>
                                <span>{moment().format('HH:mm:ss')}</span>
                            </div>

                            <StyledButton
                                btnStyle='greenBtn'
                                onClick={() => updateHandler()}
                                disabled={loadingFlag}
                            >
                                بروزرسانی
                            </StyledButton>

                        </div>
                        {adminProfile?.roleId == 1 && (
                            <div className='d-flex align-items-center mr-3 rtl'>
                                <span className='bulet'></span>
                                <span className='fo-12 mr-2'> فروش امروز: </span>
                                <span>
                  {dashboardItems.totalPaymentToday &&
                      SeprateNumberWithComma(
                          dashboardItems.totalPaymentToday
                      )}{' '}
                                    ریال
                </span>
                            </div>
                        )}
                    </HeaderContent>


                    <div className='row dashboardContainer'>
                        <div className='col-12 col-lg-12'>
                            <h5 className={'pointer'} onClick={() => {
                                setOpen(!open)
                            }}>
                                وضعیت اپراتورها
                                <i className={open ? 'fa fa-chevron-left mr-3 active': 'fa fa-chevron-left mr-3'}></i>
                            </h5>

                            <Collapse in={open}>
                                <div>
                                    <div className={'row'}>
                                        <ProviderList data={monitoringData}/>
                                    </div>
                                </div>
                            </Collapse>

                        </div>
                        <div className='col-12 col-lg-12'>
                            <h5>تیکت‌ها</h5>
                            <div className='row'>
                                {loading === true ? (
                                    <Loader/>
                                ) : (
                                    dashboardItems &&
                                    ticketList.map((item) =>
                                        item.isShow ? (
                                            <div className='gridItem' key={item.name}>
                                                <div
                                                    className='cardBox'
                                                    onClick={() => clickHandler(item.link)}
                                                >
                                                    <div className='icon'>{item.icon}</div>
                                                    <h4>
                                                        {item.title}
                                                        {

                                                            dashboardItems[item.name] > 0 ?

                                                                <Badge pill className="text-dark pt-2  font-80-p  mr-2"
                                                                       variant="warning">


                                                                    {dashboardItems[item.name]}


                                                                </Badge>

                                                                :

                                                                dashboardItems[item.name] === 0 ?
                                                                    <>
                                                                        <i className="fa fa-check-circle text-success  mr-2"/>
                                                                    </>
                                                                    : <></>

                                                        }
                                                    </h4>

                                                </div>
                                            </div>
                                        ) : null
                                    )
                                )}
                            </div>
                        </div>

                        <div className='col-12 col-lg-12 mt-3'>
                            <h5>تاییدیه‌ها</h5>
                            <div className='row'>
                                {loading === true ? (
                                    <Loader/>
                                ) : (
                                    dashboardItems &&
                                    otherList.map((item) =>
                                        item.isShow ? (
                                            <div className='gridItem' key={item.name}>
                                                <div
                                                    className='cardBox'
                                                    onClick={() => clickHandler(item.link)}
                                                >
                                                    <div className='icon'>{item.icon}</div>
                                                    <h4>
                                                        {item.title}
                                                        {

                                                            dashboardItems[item.name] > 0 ?

                                                                <Badge pill className="text-dark pt-2  font-80-p  mr-2"
                                                                       variant="warning">

                                                                    {dashboardItems[item.name]}


                                                                </Badge>

                                                                :

                                                                dashboardItems[item.name] === 0 ?
                                                                    <>
                                                                        <i className="fa fa-check-circle text-success mr-2"/>
                                                                    </>
                                                                    : <></>

                                                        }


                                                    </h4>
                                                </div>
                                            </div>
                                        ) : null
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </WrapperLayout>
        </DashboardLayuot>
    );
};

function mapStateToProps(state) {
    const {bootstrapper} = state;
    return {adminProfile: bootstrapper.data};
}

export default connect(mapStateToProps)(Dashboard);
