import React, {useContext, useEffect, useMemo, useState} from 'react'
import {Redirect, Route, useHistory, useLocation} from 'react-router-dom';

import AppContext from "../../Context/AppContext";
import { ContentLayout, FooterLayout } from '../../Component/Kit/Layout';
import { HandleResizeWindow } from '../../Utility/method/HandleResizeWindow';
import { Loader } from "../../Component/Shared/Loader"
import Header from '../Header';
import SideBar from '../SideBar';
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import { bootstrapper } from "../../Actions/bootstrapper";
import { connect } from "react-redux";
import { RoleCanActivate } from "../../Utility/Services/roleCanActivate";
import queryString from "query-string";

const PrivateRoute = ({ component: Component, dispatch, adminProfile, roles, ...rest }) => {

    const {search} = useLocation();
    const history = useHistory();
    let hasToken = useContext(AppContext).hasToken;
    const [loading, setLoading] = useState(true);
    const [screenStatus, setScreenStatus] = useState(HandleResizeWindow());

    useEffect(() => {
        const query = queryString.parse(search);
        if('needCount' in query) {
            delete query.needCount;
            history.push({
                search: '?' + queryString.stringify({...query}),
            });
        }
        setLoading(false)
        ApiClient.Get(Urls.adminBootstrapper())
            .then(res => {
                let data = res.result;
                dispatch(bootstrapper(data));
            })
    }, [screenStatus]);
    return (
        <>

            <aside>
                <SideBar screenStatus={screenStatus} setScreenStatus={setScreenStatus} />
            </aside>

            <main className="panel-container">

                <Header screenStatus={screenStatus} setScreenStatus={setScreenStatus} />

                {screenStatus && adminProfile && (
                    <section
                        className={`panel-container__mf scrollbar-style 
                           ${screenStatus.sidebarMode === "slim"
                                ? "slim-sidebar"
                                : "" || screenStatus.sidebarMode === "hidden"
                                    ? "hidden-sidebar"
                                    : "" || screenStatus.sidebarMode === "default"
                                        ? "desktop-sidebar"
                                        : ""
                            } `}
                    >

                        <ContentLayout className="panel-container__main">
                            {
                                loading === true
                                    ?
                                    <Loader />
                                    :
                                    <Route {...rest} render={props => (
                                        hasToken && RoleCanActivate(roles, adminProfile?.roleId)
                                            ?
                                            <Component {...props} />
                                            :
                                            <Redirect to="/" />
                                    )} />

                            }
                        </ContentLayout>

                        <FooterLayout className="panel-container__footer">
                            <div className="footer">
                                <div>1389-1401© تمامی حقوق برای شرکت ایده پردازان محفوظ است.</div>
                                {/*<a className="pointer push-left">حریم خصوصی</a>*/}
                                {/*<a className="pointer pr-3">شرایط و قوانین</a>*/}
                            </div>
                        </FooterLayout>

                    </section>
                )}
            </main>

        </>
    )
}

function mapStateToProps(state) {
    const { bootstrapper } = state
    return { adminProfile: bootstrapper.data }
}

export default connect(mapStateToProps)(PrivateRoute);
