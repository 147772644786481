import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {MainContent, PageTitleLayout} from "../../../Component/Kit/Layout";
import Table from "../../../Component/Shared/Table/Table";
import {useHistory, useLocation} from "react-router-dom";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {ConvertQueryString} from "../../../Utility/Services/convert-query-string";
import DateConvert from "../../../Utility/Services/date-convert";
import SaleCooperationTransactionTypeModal from "../../../Utility/Model/SaleCooperationTransaction.Modal";

function SaleCooperationTransaction() {
    const [transaction, setTransaction] = useState({});
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        ApiClient.Get(Urls.getSaleCooperationTransaction(), ConvertQueryString(location.search))
            .then(res => {
                res.result.items.map((item) => {
                    item.createdAt = DateConvert(item.createdAt);
                    item.type = SaleCooperationTransactionTypeModal.find(a => a.value === item.type).name
                    item.amount =  item.amount?.toLocaleString('fa-IR')
                })
                setTransaction(res.result)
            })
    }, [location.search])
    const tableConfig = {
        header: {
            amount: "مبلغ",
            presenterUserName: 'بازاریاب',
            createdAt: "تاریخ",
            description: "توضیحات",
            type: "نوع",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            }
        },
        mode: ["search", "filter"],
        filters: ["datePickerFilter"]
    };
    const tableActions = (item) => {
        if (item.actionName === 'view') {
            history.push(`/userDetail/${item.data.presenterId}/profile`)
        }
    };

    return (
        <>
            <MainContent>
                <PageTitleLayout>
                    <h5> تراکنش های بخش همکاری در فروش</h5>
                    <span>در اینجا می‌توانید همه‌ی تراکنش های بخش همکاری در فروش را مشاهده نمایید.</span>
                </PageTitleLayout>
                <Table
                    data={transaction.items}
                    config={tableConfig}
                    pageConfig={{
                        pageNumber: transaction.pageNumber,
                        pageSize: transaction.pageSize,
                        totalItemsCount: transaction.totalItemsCount,
                        totalPagesCount: transaction.totalPagesCount,
                    }}
                    actionDispatched={tableActions}
                />
            </MainContent>
        </>
    )
}
export default SaleCooperationTransaction;