import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Formik} from "formik";
import {toast} from "react-toastify";
import Swal from "sweetalert2";


import {StyledButton} from "../../../../../Component/Kit/Button";
import {StyledCheckBox, StyledForm, StyledInput,} from "../../../../../Component/Kit/Form";
import Modal from "../../../../../Component/Kit/Modal";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";


export const SepidarModal = (props) => {
    const {
        singleOfficialInvoice,
        officialInvoiceId,
        data,
        visible,
        setVisible,
        onCancel,
        onClose,
        hasOldPanel,
    } = props;
    const history = useHistory();
    const [isOldPanel, setIsOldPanel] = useState(false);

    const submitForm = (values, actions) => {
        const body = JSON.stringify(values);

        actions.setSubmitting(true);
        if (isOldPanel) {
            ApiClient.Post(
                Urls.registerOldPanelSepidar(officialInvoiceId),
                body
            ).then((res) => {
                setVisible(false);
                Swal.fire({
                    title: "درخواست شما در سامانه سپیدار ثبت شد",
                    html:
                        `<p> نام مشتری : ${
                            res.result?.customerFullName
                                ? res.result?.customerFullName
                                : "- - -"
                        }</p>` +
                        `<p> وضعیت مشتری : ${
                            res.result?.isExistCustomer ? "وجود داشت" : "ثبت شد"
                        }</p>` +
                        `<p> کد مشتری : ${res.result?.customerCode}</p>` +
                        `<p> شناسه مشتری : ${
                            res.result?.customerId
                                ? res.result?.customerId
                                : "- - -"
                        }</p>` +
                        `<p> کد فاکتور : ${
                            singleOfficialInvoice.type === 2
                                ? res.result?.returnedInvoiceCode
                                : res.result?.invoiceCode
                        }</p>` +
                        `<p> شناسه فاکتور : ${
                            singleOfficialInvoice.type === 2
                                ? res.result?.returnedInvoiceId
                                : res.result?.invoiceId
                        }</p>` +
                        `<p> کد رسید : ${
                            res.result?.receiptCode
                                ? res.result?.receiptCode
                                : "- - -"
                        }</p>` +
                        `<p> شناسه رسید : ${
                            res.result?.receiptId
                                ? res.result?.receiptId
                                : "- - -"
                        }</p>`,
                    text: "",
                    icon: "info",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "تایید",
                }).then((result) => {
                    if (result.isConfirmed) {
                        actions.setSubmitting(false);
                        toast.success("فرآیند با موفقیت انجام گرفت!");
                        history.push(`/financial/invoices/old-panel-pending`);
                    }
                });
            });
        } else {
            ApiClient.Post(
                singleOfficialInvoice.type === 2
                    ? Urls.registerSepidarReturnInvoice(officialInvoiceId)
                    : Urls.registerSepidar(officialInvoiceId),
                body
            ).then((res) => {
                setVisible(false);
                Swal.fire({
                    title: "درخواست شما در سامانه سپیدار ثبت شد",
                    html:
                        `<p> نام مشتری : ${
                            res.result?.customerFullName
                                ? res.result?.customerFullName
                                : "- - -"
                        }</p>` +
                        `<p> وضعیت مشتری : ${
                            res.result?.isExistCustomer ? "وجود داشت" : "ثبت شد"
                        }</p>` +
                        `<p> کد مشتری : ${res.result?.customerCode}</p>` +
                        `<p> شناسه مشتری : ${
                            res.result?.customerId
                                ? res.result?.customerId
                                : "- - -"
                        }</p>` +
                        `<p> کد فاکتور : ${
                            singleOfficialInvoice.type === 2
                                ? res.result?.returnedInvoiceCode
                                : res.result?.invoiceCode
                        }</p>` +
                        `<p> شناسه فاکتور : ${
                            singleOfficialInvoice.type === 2
                                ? res.result?.returnedInvoiceId
                                : res.result?.invoiceId
                        }</p>` +
                        `<p> کد رسید : ${
                            res.result?.receiptCode
                                ? res.result?.receiptCode
                                : "- - -"
                        }</p>` +
                        `<p> شناسه رسید : ${
                            res.result?.receiptId
                                ? res.result?.receiptId
                                : "- - -"
                        }</p>`,
                    text: "",
                    icon: "info",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "تایید",
                }).then((result) => {
                    if (result.isConfirmed) {
                        actions.setSubmitting(false);
                        toast.success("فرآیند با موفقیت انجام گرفت!");
                        history.push(`/financial/invoices`);
                    }
                });
            });
        }
    }

    useEffect(() => {
        setIsOldPanel(hasOldPanel || false);
    }, [hasOldPanel]);
    return (
        <Modal
            visible={visible}
            onClose={() => onClose()}
            onCancel={onCancel}
            headerTitle={"ثبت در سپیدار"}
        >
            {data && (
                <div className="col-12">
                    <div className="row">
                        <Formik
                            initialValues={{
                                sendSms: false,
                                sepidarRegisterOptions: {
                                    intendedFirstName: "",
                                    intendedLastName: "",
                                    isNewNameBeRegistered: false,
                                    ignoreCheckToRegistrationNewCustomer: false
                                }
                            }}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                                submitForm(values, actions);
                            }}
                            onReset={(values, formikBag) => {
                                setVisible(false);
                            }}
                        >
                            {(formik) => (
                                <StyledForm
                                    onSubmit={formik.handleSubmit}
                                    onReset={formik.handleReset}
                                >

                                    <div className="row justify-content-between justify-content-md-around">

                                        <StyledCheckBox
                                            formGroupClass=""
                                            lable="ارسال پیامک"
                                            focus={true}
                                            name="sendSms"
                                            id="sendSms"
                                            checked={formik.values.sendSms !== undefined
                                                ? formik.values.sendSms
                                                : formik.initialValues.sendSms}
                                            error="false"
                                            onChange={(e) => formik.setFieldValue("sendSms", e.target.checked)}
                                        />

                                        <StyledCheckBox
                                            formGroupClass=""
                                            lable="ثبت با نام جدید"
                                            focus={true}
                                            name="isNewNameBeRegistered"
                                            id="isNewNameBeRegistered"
                                            checked={formik.values.sepidarRegisterOptions.isNewNameBeRegistered !== undefined
                                                ? formik.values.sepidarRegisterOptions.isNewNameBeRegistered
                                                : formik.initialValues.sepidarRegisterOptions.isNewNameBeRegistered}
                                            error="false"
                                            onChange={(e) => formik.setFieldValue("sepidarRegisterOptions", {
                                                ...formik.values.sepidarRegisterOptions,
                                                isNewNameBeRegistered: e.target.checked,
                                            })}
                                        />

                                        <StyledCheckBox
                                            formGroupClass=""
                                            lable="نادیده گرفتن بررسی مشتری"
                                            focus={true}
                                            name="ignoreCheckToRegistrationNewCustomer"
                                            id="ignoreCheckToRegistrationNewCustomer"
                                            checked={formik.values.sepidarRegisterOptions.ignoreCheckToRegistrationNewCustomer !== undefined
                                                ? formik.values.sepidarRegisterOptions.ignoreCheckToRegistrationNewCustomer
                                                : formik.initialValues.sepidarRegisterOptions.ignoreCheckToRegistrationNewCustomer}
                                            error="false"
                                            onChange={(e) => formik.setFieldValue("sepidarRegisterOptions", {
                                                ...formik.values.sepidarRegisterOptions,
                                                ignoreCheckToRegistrationNewCustomer: e.target.checked,
                                            })}
                                        />




                                    </div>

                                    <div className="row">
                                        {
                                            formik.values.sepidarRegisterOptions.isNewNameBeRegistered &&
                                            <>
                                                <div className="col-12 col-md-6">
                                                    <StyledInput
                                                        lable="نام"
                                                        focus={true}
                                                        type="text"
                                                        name="intendedFirstName"
                                                        value={formik.values.sepidarRegisterOptions.intendedFirstName || ""}
                                                        error="false"
                                                        onChange={(e) =>
                                                            formik.setFieldValue("sepidarRegisterOptions", {
                                                                    ...formik.values.sepidarRegisterOptions,
                                                                    intendedFirstName: e.target.value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <StyledInput
                                                        lable="نام خانوادگی"
                                                        focus={true}
                                                        type="text"
                                                        name="intendedLastName"
                                                        value={formik.values.sepidarRegisterOptions.intendedLastName || ""}
                                                        error="false"
                                                        onChange={(e) =>
                                                            formik.setFieldValue("sepidarRegisterOptions", {
                                                                    ...formik.values.sepidarRegisterOptions,
                                                                    intendedLastName: e.target.value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className="d-flex justify-content-between mt-3">
                                        <StyledButton
                                            btnStyle="greenBtn ml-3"
                                            width="38%"
                                            disabled={
                                                !formik.isValid || formik.isSubmitting
                                            }
                                            loading={formik.isSubmitting ? 1 : 0}
                                            type="submit"
                                        >
                                            تایید
                                        </StyledButton>

                                        <StyledButton width="35%" btnStyle="grayBtn" type="reset">
                                            بستن
                                        </StyledButton>
                                    </div>
                                </StyledForm>
                            )}
                        </Formik>
                    </div>
                </div>
            )}
        </Modal>
    );
};
