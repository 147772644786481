const UserServiceLineStatusEnum = {
    Created: 1,
    RejectedByAdmin: 2,
    AcceptedByAdmin: 3,
    RejectedByProvider: 4,
    ModifiedByUser: 5,
    AcceptedByProvider: 6,
    Finished: 7
}

const UserServiceLineFileStatusEnum = {
    NotProvided: 1,
    Provided: 2,
    RejectedByAdmin: 3,
    AcceptedByAdmin: 4,
    RejectedByProvider: 5,
    ModifiedByUser: 6,
    AcceptedByProvider: 7
}

const ServiceLineFileTypeEnum = {
    CommitmentLetter : 1,
    Contacts : 2,
    PromissoryNote : 3,
    OwnerNationalId : 4
}


const UserServiceLineStatusModel = [
    {name: 'ایجاد شده', value: UserServiceLineStatusEnum.Created},
    {name: 'رد شده توسط ادمین', value: UserServiceLineStatusEnum.RejectedByAdmin},
    {name: 'تایید شده توسط ادمین', value: UserServiceLineStatusEnum.AcceptedByAdmin},
    {name: 'رد شده توسط اپراتور', value: UserServiceLineStatusEnum.RejectedByProvider},
    {name: 'ویرایش شده', value: UserServiceLineStatusEnum.ModifiedByUser},
    {name: 'تایید  توسط اپراتور', value: UserServiceLineStatusEnum.AcceptedByProvider},
    {name: 'خدماتی شده', value: UserServiceLineStatusEnum.Finished},
];

const UserServiceLineFileStatusModel = [
    {name: 'فراهم نشده', value: UserServiceLineFileStatusEnum.NotProvided},
    {name: 'فراهم شده', value: UserServiceLineFileStatusEnum.Provided},
    {name: 'رد شده توسط ادمین', value: UserServiceLineFileStatusEnum.RejectedByAdmin},
    {name: 'تایید شده توسط ادمین', value: UserServiceLineFileStatusEnum.AcceptedByAdmin},
    {name: 'رد شده توسط اپراتور', value: UserServiceLineFileStatusEnum.RejectedByProvider},
    {name: 'ویرایش شده', value: UserServiceLineFileStatusEnum.ModifiedByUser},
    {name: 'تایید شده توسط اپراتور', value: UserServiceLineFileStatusEnum.AcceptedByProvider},
];

const ServiceLineFileTypeModel = [
    {name: 'تعهدنامه', value: ServiceLineFileTypeEnum.CommitmentLetter},
    {name: 'شماره‌ها', value: ServiceLineFileTypeEnum.Contacts},
    {name: 'سفته', value: ServiceLineFileTypeEnum.PromissoryNote},
    {name: 'کارت ملی', value: ServiceLineFileTypeEnum.OwnerNationalId},
];



export {UserServiceLineStatusModel, UserServiceLineStatusEnum, UserServiceLineFileStatusModel, UserServiceLineFileStatusEnum, ServiceLineFileTypeModel, ServiceLineFileTypeEnum};

