import {Formik} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {StyledButton} from "../../../Component/Kit/Button";
import {FormError, StyledCheckBox, StyledForm, StyledInput, StyledSelect} from "../../../Component/Kit/Form";
import ProviderEnum, {SystemLineProviderEnum} from "../../../Utility/Model/provider-enum.model";
import {UserLineEarnMethod} from "../../../Utility/Model/lines.model";
import * as Yup from "yup";
import {addLineNumber} from "../../../Utility/Validation/Rules";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {toast} from "react-toastify";
import {Loader} from "../../../Component/Shared/Loader";
import {LinePrebuyStatus} from "../../../Utility/Services/lines";

const AddLineToUserSchema = Yup.object().shape({
    addLineNumber,
    providerId: Yup.string()
        .required("این فیلد الزامی است."),
    earnMethod: Yup.string()
        .required("این فیلد الزامی است."),

});

function AddLineToUser({onCloseModal, dataChange}) {
    let match = useRouteMatch();
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);

    const getPrebuyLine = useCallback(() => {
        ApiClient.Get(Urls.userLinePrebuy(match.params.id))
            .then((res) => {
                let data = res.result;
                data.map((m) => {
                    m.status = LinePrebuyStatus(m.status)
                });

                setLoading(false);
                setTableData(data)
            });
    }, [match.params.id])

    const deletePrebuyLine = (prebuyId) => {
        ApiClient.Delete(Urls.deleteUserLinePrebuy(match.params.id, prebuyId))
            .then((res) => {
                toast.success('خط با موفقیت حذف شد.')
                setLoading(true);
                getPrebuyLine();
            });
    }


    useEffect(() => {
        getPrebuyLine();
    }, [getPrebuyLine]);

    return (
        <>
            <Formik
                initialValues={{
                    addLineNumber: "",
                    providerId: "",
                    earnMethod: "",
                    isPrebuy: false,
                    price: 0
                }}
                validate={values => {
                    const errors = {};
                    if (values.areaCode === 2 || values.areaCode === 1) {
                        if (values.addLineNumber.length < 2) {
                            errors.addLineNumber = "حداقل تعداد مجاز کاراکتر 2 می‌باشد."
                        }
                    } else if (values.areaCode === 5) {
                        if (values.addLineNumber.length > 4) {
                            errors.addLineNumber = " حداکثر تعداد مجاز کاراکتر برای خط آسیاتک 4 می‌باشد."
                        }
                    } else {
                        if (values.addLineNumber.length < 4) {
                            errors.addLineNumber = "حداقل تعداد مجاز کاراکتر 4 می‌باشد."
                        }
                    }
                    return errors;
                }}
                validationSchema={AddLineToUserSchema}
                enableReinitialize={false}
                onSubmit={(values, actions) => {
                    const body = {
                        "userId": parseInt(match.params.id),
                        "linePrefix": parseInt(SystemLineProviderEnum.find(f => f.value === values.providerId).prefix),
                        "isPrebuyLine": values.isPrebuy,
                        "pureNumber": values.addLineNumber,
                        "providerId": parseInt(values.providerId),
                        "lineEarnType": values.earnMethod,
                    }
                    if (values.isPrebuy) {
                        body['linePrice'] = parseInt(values.price)
                        if (parseInt(values.price) <= 0) {
                            toast.warning('قیمت وارد شده نامعتبر است.')
                            return
                        }
                    }
                    ApiClient.Post(Urls.userLine(), JSON.stringify(body))
                        .then((res) => {
                            toast.success('خط با موفقیت افزوده شد.')
                            if (!values.isPrebuy) {
                                onCloseModal(false);
                            }
                            dataChange(true);
                            getPrebuyLine();
                        })
                        .catch(err => console.error(err))

                    actions.resetForm();
                }}>


                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                    >
                        <div className="d-flex align-items-center">
                            <StyledSelect
                                formGroupClass="col-12 col-sm-6 pr-0"
                                hasDefaultOption={true}
                                lable='ارائه دهنده'
                                focus={true}
                                name='providerId'
                                value={
                                    formik.values.providerId !== undefined
                                        ? formik.values.providerId
                                        : formik.initialValues.providerId
                                }
                                optionItems={ProviderEnum}
                                error={
                                    formik.errors.providerId &&
                                    formik.touched.providerId
                                        ? formik.errors.providerId
                                        : 'false'
                                }
                                onChange={(e) =>
                                    formik.setFieldValue('providerId', Number(e.target.value))
                                }
                            />

                            <StyledSelect
                                formGroupClass="col-12 col-sm-6 pl-0"
                                hasDefaultOption={true}
                                lable='نوع دریافت'
                                focus={true}
                                name='earnMethod'
                                value={
                                    formik.values.earnMethod !== undefined
                                        ? formik.values.earnMethod
                                        : formik.initialValues.earnMethod
                                }
                                optionItems={UserLineEarnMethod}
                                error={
                                    formik.errors.earnMethod &&
                                    formik.touched.earnMethod
                                        ? formik.errors.earnMethod
                                        : 'false'
                                }
                                onChange={(e) =>
                                    formik.setFieldValue('earnMethod', Number(e.target.value))
                                }
                            />

                        </div>

                        {

                            <div className="input-group mb-3" dir="ltr">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        {SystemLineProviderEnum.map(m => (
                                            (m.value === formik.values.providerId) ? m.prefix : null
                                        ))}

                                        {
                                            !formik.values.providerId &&
                                            <>...</>
                                        }
                                    </div>


                                </div>
                                <input type="text"
                                       className="form-control"
                                       id="inlineFormInputGroup"
                                       placeholder="XXXX"
                                       name="addLineNumber"
                                       value={formik.values.addLineNumber}
                                       onChange={(e) => {
                                           formik.setFieldValue("addLineNumber", e.target.value)
                                       }
                                       }/>
                                <FormError show={(formik.errors.addLineNumber && formik.touched.addLineNumber)
                                    ? formik.errors.addLineNumber
                                    : 'false'}
                                >
                                    {(formik.errors.addLineNumber && formik.touched.addLineNumber)
                                        ? formik.errors.addLineNumber
                                        : 'false'}
                                </FormError>
                            </div>
                        }


                        <div className="d-flex flex-wrap justify-content-between align-items-center  w-100">
                            <StyledCheckBox
                                formGroupClass={`col-12 px-0 ${formik.values.isPrebuy ? 'mb-0 pt-2' : ' mb-3'}`}
                                lable="افزودن به عنوان آماده خرید"
                                focus={true}
                                name="isPrebuy"
                                id="isPrebuy"
                                checked={formik.values.isPrebuy !== undefined
                                    ? formik.values.isPrebuy
                                    : formik.initialValues.isPrebuy}
                                error="false"
                                onChange={(e) => {
                                    formik.setFieldValue("isPrebuy", e.target.checked)
                                }}
                            />

                            {
                                formik.values.isPrebuy &&

                                <StyledInput
                                    formGroupClass="col-12 col-sm-6 mt-2 px-0"
                                    lable="قیمت (ریال)"
                                    focus={true}
                                    placeholder=""
                                    name="price"
                                    value={formik.values.price}
                                    error={(formik.errors.price && formik.touched.price)
                                        ? formik.errors.price
                                        : 'false'}

                                    onChange={(e) => formik.setFieldValue("price", e.target.value)}
                                />


                            }

                        </div>


                        <div className="d-flex justify-content-between w-100">

                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>


                            <StyledButton
                                width="35%"
                                btnStyle="greenBtn"
                                disabled={!formik.isValid}
                                type="submit"
                                // loading={loading}
                                // onClick={() => deactiveUserLines(data)}
                            >
                                انجام عملیات
                            </StyledButton>


                        </div>
                    </StyledForm>
                )}
            </Formik>

            {
                loading
                    ?
                    <Loader/>
                    :

                    <>
                        <div className="table-responsive">
                            <table className="factor-table">
                                <thead>
                                <tr className="gray-bg fo-13">
                                    <th>#</th>
                                    <th>شماره</th>
                                    <th>وضعیت</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {tableData &&
                                    tableData.map((item, key) => (
                                        <tr className="fo-13" key={`item${key}`}>
                                            <td>{key + 1}</td>
                                            <td>{item.number}</td>
                                            <td>{item.status}</td>
                                            <td><i onClick={(e) => deletePrebuyLine(item.id)}
                                                   className="fa fa-trash text-danger pointer"></i></td>
                                        </tr>
                                    ))
                                }
                                {
                                    tableData && tableData.length === 0 &&
                                    <tr>
                                        <td colSpan={5}>موردی وجود ندارد.</td>
                                    </tr>
                                }

                                </tbody>
                            </table>
                        </div>
                    </>

            }

        </>
    )
}

export default AddLineToUser;
