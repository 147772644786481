
import React from "react";
import styled from "styled-components";
import Col from "./Column";
import userIcon from "../../Assets/images/user.jpg";

export const UploadImage = styled.div`
    display: inline-flex;
`;

export const ImageContainer = styled.div`
    position: relative;
`;

export const UploadAvatarConatiner = styled(Col)`
  justify-content: flex-end;
  display: flex;
  align-items: end;
svg{
    width: 120px;
    height: 120px;
    margin: auto;
    display: block;
    margin-bottom: 30px;
  }
`;

export const ImageBox = styled.div`
  width: 120px;
  height: 120px;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.4rem;
  &.remove-btn_flag{
      &:before{
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          border-radius: .4rem;
          content: "";
          opacity: 0;
          position: absolute;
          background-color: rgba(30,44,71,.4);
          width: 20px;
          height: 20px;
          transition: .3s ease-in-out;
      }
  }
  &:hover {
      &.remove-btn_flag{
          &:before{
              opacity: 1;
              width: 120px;
              height: 120px;
              transition: .3s ease-in-out;
          }
      }
      #del {
          cursor: pointer;
          opacity: 1;
          transition: 0.3s ease-in-out;
      }
  }
  label,
  button,
  span {
      position: absolute;
      border-radius: 3px !important;
      width: 30px;
      height: 30px;
      padding: 0;
      z-index: 109;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
          line-height: 0;
      }
      &#del {
          opacity: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: 0.3s ease-in-out;
      }
      &#pen {
          bottom: -10%;
          right: 0;
          transform: translateX(50%);
          transition: 0.3s ease-in-out;
      }
  }
  button ,label{
      cursor: pointer;
      color :  ${(props) => props.theme.white};
      background-color: ${(props) => props.theme.secondaryGreen};
      border-color: ${(props) => props.theme.secondaryGreen};
      font-size: 0.8rem;
      line-height: 1.5;
      outline: none !important;
      vertical-align: middle;
      border: none;
  }
`;

export const ProfileImage = styled(Col)`
  background-image: ${(props) => `url(${props.src})`};
  /* background-size: cover; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  `;

export const BrowseFile = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    display: none;
  `;

export const StyledUploadAvatar = (props) => {
  const { HasUrl } = props;

  return (
    <>
      <UploadImage>
        <ImageContainer>
          {/* <div className={`_circle ${(HasUrl !== null) ? 'remove-btn_flag': '' }`}> */}
          {/* <div className="circle"> */}
          <ImageBox>
            <ProfileImage className="_icon" src={(HasUrl !== null) ? HasUrl : userIcon} />

            {/* <input type="file" className="_browse" accept=".jpg,.jpeg,.png" id="file-input" {...props} /> */}

            <BrowseFile type="file" accept=".jpg,.jpeg,.png" id="file-input" {...props} />

            <label id="pen" htmlFor="file-input">
              <i className="fas fa-camera"></i>
            </label>

            {/*
                        for delete avatar
                         {
                            (HasUrl !== null) && (
                                <span id="del" className="red-text" {...props} onClick={()=> deleteHandler()} >
                                    <i className="fas fa-times fa-3x text-white"></i>
                                </span>
                            )
                        } */}
          </ImageBox>
          {/* </div> */}
        </ImageContainer>
      </UploadImage>
    </>
  )
};
