let errorTranslateEnum = [
    { value:400, key: "Bad Request: Logic not considered!", name: "400: Bad Request" },
    { value: 1,  key: "ContactExistsInThisGroup", name: "ContactExistsInThisGroup" },
    { value: 2,  key: "DuplicateTitleOrName", name: "عنوان وارد شده تکراری است." },
    { value: 3,  key: "FileIsInvalid", name: "محتوای فایل آپلود شده قابل قبول نمی‌باشد." },
    { value: 4,  key: "NotAllowedContactsCount", name: "NotAllowedContactsCount" },
    { value: 5,  key: "CacheKeyNotFound", name: "CacheKeyNotFound" },
    { value: 6,  key: "ContactAlreadyExists", name: "ContactAlreadyExists" },
    { value: 7,  key: "MobileUsedByAnotherUser", name: "شماره وارد شده قبلا در سامانه ثبت شده است!" },
    { value: 8,  key: "UserNotFound", name: "کاربری با این مشخصات وجود ندارد." },
    { value: 9,  key: "UserIsSuspended", name: "حساب کاربری کاربر مسدود است." },
    { value: 10, key: "MobileVerificationCodeIsWrong", name: "کد وارد شده اشتباه است." },
    { value: 11, key: "UserApiKeyLimitationCount", name: "UserApiKeyLimitationCount" },
    { value: 12, key: "AlreadyReturnInvoiceIssued", name: "قبلا فاکتور برگشتی صادر شده است." },
    { value: 13, key: "UserCouldNotHasOfficialInvoice", name: "امکان صدور فاکتور رسمی برای این کاربر وجود ندارد." },
    { value: 14, key: "PasswordIsWrong", name: "رمز عبور صحیح نمی‌باشد." },
    { value: 15, key: "UserDeactivationAllowed24Hours", name: "پس از گذشت حداکثر 24 ساعت انجام این عملیات امکان پذیر است." },
    { value: 16, key: "DuplicateEmailAddress", name: "ایمیل تکراری است." },
    { value: 17, key: "DuplicateUserName", name: "نام کاربری انتخاب شده تکراری است." },
    { value: 18, key: "DuplicateOfficialInvoiceNumber", name: "شماره فاکتور رسمی تکراری است." },
    { value: 19, key: "DiscountCodeIsWrong", name: "کد تخفیف اشتباه است." },
    { value: 20, key: "DiscountCodeHasExpired", name: "کد تخفیف منقضی شده است." },
    { value: 21, key: "DiscountCodeUsageLimitationReached", name: "به تعداد حداکثر دفعات مجاز، از این کد استفاده نموده‌اید." },
    { value: 22, key: "UserLinePrebuyExists", name: "خط توسط کاربر دیگری موقتا رزرو شده است." },
    { value: 23, key: "DuplicateGiftLineNumber", name: "شماره‌ی خط هدیه تکراری می‌باشد." },
    { value: 24, key: "DuplicateUserLineNumber", name: "شماره‌ی خط کاربر تکراری می‌باشد." },
    { value: 25, key: "CantModifyStartedABTest", name: "کمپین شروع شده را نمی‌توانید ویرایش کنید." },
    { value: 26, key: "ItemCountLimitation", name: "حداکثر آیتم مجاز" },
    { value: 27, key: "EventIsRelatedToTheCampaign", name: "این رویداد در کمپین استفاده شده است. لطفا ابتدا کمپین را حذف کنید." },
    { value: 28, key: "ContactGroupUsedInCampaign", name: "این گروه از مخاطبین در یک کمپین مورد استفاده قرار گرفته است." },
    { value: 29, key: "EmailVerificationAllowedPer2Minutes ", name: "تأیید ایمیل پس از گذشت دو دقیقه مجاز است" },
    { value: 30, key: "LineUsedOnAnotherModule", name: "خط در ماژول دیگری استفاده شده است." },
    { value: 31, key: "SmsSecretaryBehaviorLimitationCount", name: "محدودیت تعداد عملگر منشی پیامکی" },
    { value: 32, key: "DuplicateParameter", name: "پارامتر تکراری است." },
    { value: 33, key: "CreditNotEnough", name: "کردیت کافی نیست." },
    { value: 34, key: "KeyWordIsDuplicated", name: "کلمه کلیدی، تکراری است." },
    { value: 35, key: "TestFastSendLimitationCount", name: "محدودیت در تست ارسال سریع" },
    { value: 36, key: "RetryOperation", name: "RetryOperation" },
    { value: 37, key: "OverlapInRanges", name: "تداخل در بازه‌های زمانی انتخاب شده" },
    { value: 38, key: "UsableSystemLine", name: "خط انتخاب شده درحال استفاده می باشد" },
    { value: 39, key: "SystemLineExist", name: "خط وارد شده در سیستم وجود دارد" },
    { value: 40, key: "DuplicateBlockedIP", name: "آدرس IP تکراری است." },
    { value: 41, key: "TempRefCodeIsExpired", name: "کد معرف منقضی شده است" },
    { value: 42, key: "ParameterUsedInBlackListKeywords", name: "پارامتر استفاده شده در کلیدواژه های لیست سیاه شما می‌باشد" },
    { value: 43, key: "CooperationMessageParameterLimitationCount", name: "محدودیت در تعداد کلیدواژهای بخش همکاری در فروش" },
    { value: 44, key: "ParameterUsedOnAnotherModule", name: "کلیدواژه در ماژول دیگری استفاده شده است" },
    { value: 45, key: "UserHasOpenRequest ", name: "کاربر درخواست باز دارد" },
    { value: 46, key: "RegistrationThroughTempCodeHasAlreadyBeenDone", name: "ثبت نام از طریق این لینک قبلا انجام شده است." },
    { value: 47, key: "SepidarRegisterCustomerFailed", name: "ثبت طرف حساب در سپیدار با مشکل مواجه شد" },
    { value: 48, key: "SepidarRegisterInvoiceFailed ", name: "ثبت فاکتور در سپیدار با مشکل مواجه شد" },
    { value: 49, key: "SepidarRegisterReceiptFailed ", name: "ثبت رسید دریافتی در سپیدار با مشکل مواجه شد" },
    { value: 55, key: "LegalUserMustModifyUnderSupervisionFinancialDepartment", name: "کاربر موردنظر فاکتور ثبت شده دارد. لطفا برای تغییر اطلاعات حقوقی به واحد مالی ارجاع دهید." },
    { value: 56, key: "DowngradePlanIsNotPermitted", name: "خرید این پلن برای شما امکان پذیر نیست. لطفا پلن دیگری را انتخاب کنید." },
    { value: 57, key: "AlreadyHasNotExpiredPlan", name: "امکان تمدید پلن وجود ندارد." },
    { value: 58, key: "PlanItemAccessibility", name: "اعتبار پلن شما به پایان رسید. لطفا پلن خود را تمدید کنید." },
    { value: 59, key: "PlanItemLimitationCountUserTemplate", name: "PlanItemLimitationCountUserTemplate" },
    { value: 60, key: "PlanItemLimitationCountBlackListInquiryCount", name: "PlanItemLimitationCountBlackListInquiryCount" },
    { value: 61, key: "PlanItemLimitationCountFastSend", name: "PlanItemLimitationCountFastSend" },
    { value: 62, key: "PlanItemLimitationApiKey", name: "PlanItemLimitationApiKey" },
    { value: 63, key: "PlanItemLimitationSmsTransmissionApiWithParameter", name: "PlanItemLimitationSmsTransmissionApiWithParameter" },
    { value: 64, key: "PlanItemLimitationSmsTransmissionEmailWithParameter", name: "PlanItemLimitationSmsTransmissionEmailWithParameter" },
    { value: 65, key: "PlanItemLimitationSmsTransmissionMobileWithParameter", name: "PlanItemLimitationSmsTransmissionMobileWithParameter" },
    { value: 66, key: "PlanItemLimitationSmsSecretaryWithParameter", name: "PlanItemLimitationSmsSecretaryWithParameter" },
    { value: 67, key: "PlanItemLimitationCodeReaderParameters", name: "PlanItemLimitationCodeReaderParameters" },
    { value: 68, key: "PlanItemLimitationContacts", name: "PlanItemLimitationContacts" },
    { value: 69, key: "PlanItemLimitationContactGroups", name: "PlanItemLimitationContactGroups" },
    { value: 70, key: "PlanItemLimitationContactEvents", name: "PlanItemLimitationContactEvents" },
    { value: 71, key: "PlanItemLimitationConcurrentOpenTicketsCount", name: "PlanItemLimitationConcurrentOpenTicketsCount" },
    { value: 72, key: "PreviousDaysHasPendingInvoice", name: "فاکتور شماره گذاری نشده از روز قبل دارید." },
    { value: 73, key: "MobileIsBlocked", name: "شماره موبایل شما بلاک شده است. لطفا با پشتیبانی تماس بگیرید." },
    { value: 74, key: "MobileIsAlreadyBlocked", name: "این شماره موبایل در حال حاضر بلاک می باشد." },
    { value: 80, key: "TwiceCancelInvoice", name: " فاکتور از قبل باطل شده است." },
    { value: 81, key: "NotSupportedSepidarLocation", name: "شهر کاربر در سپیدار پشتیبانی نشده است" },
    { value: 82, key: "NotSupportedSepidarBankType", name: "نوع بانک در سپیدار پشتیبانی نشده است" },
    { value: 86, key: "DuplicateAdvertisingCampaignName", name: "نام کمپین تکراری است." },
    { value: 87, key: "DuplicateAdvertisingCampaignCode", name: "کد کمپین تکراری است." },
    { value: 89, key: "InternalOperationsLineMustBeUnique", name: "فرایندهای داخلی باید شامل خط اختصاصی باشد." },
    { value: 90, key: "SystemLineTypesMustBeTaken", name: "تایپ مورد استفاده برای (عملگر) خطوط حتما باید دارای خط باشد." },
    { value: 91, key: "SystemLineHasTypeMustBeActive", name: "خط غیرفعال نمیتواند دارای عملگر باشد." },
    { value: 95, key: "LegalDataIncompleteForRegisterInSepidar", name: "اطلاعات حقوقی ناقص میباشد." },
    { value: 96, key: "CustomerNationalIdForRegisterInSepidarEmpty", name: "شناسه ملی کاربر ناقص میباشد." },
    { value: 97, key: "HasConflictCustomerNameInSepidar", name: "اطلاعات کاربر با سپیدار تناقض  دارد." },
    { value: 98, key: "CustomerHasSameNameExistInSepidar", name: "کاربر تشابه اسمی دارد." },
    { value: 107, key: "CouldNotConnectToSepidar", name: "سپیدار از دسترس خارج شده است."},
];

export default errorTranslateEnum
