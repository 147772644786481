import React, {useCallback, useEffect, useState} from 'react';
import ApiClient from '../../Requester/ApiClient';
import Urls from '../../Requester/Urls';
import Table from '../../Component/Shared/Table/Table';
import {HeaderContent, MainContent, PageTitleLayout,} from '../../Component/Kit/Layout';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {StyledButton} from '../../Component/Kit/Button';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import BackToPage from "../../Component/Kit/back";
import {ConvertQueryString} from "../../Utility/Services/convert-query-string";


function SingleRepeatVerifySMs() {
    let params = useParams();
    let history = useHistory();
    let location = useLocation()
    const [pendingData, setpendingData] = useState({});
    const [messages, setMessages] = useState([]);
    const [selectedList, setSelectedList] = useState([]);

    const tableConfig = {
        header: {
            messageText: 'متن پیامک',
            lineNumber: 'شماره خط',
            isPending: 'درحال انتظار',
        },
        actions: {},
        mode: ["filter"],
        filters: ["onlyPendings"],
        hasSelectCol: true,
    };

    const getSMSQueue = useCallback(() => {
        ApiClient.Get(Urls.highConsumptionVerifyUserMessages(params.id), ConvertQueryString(location.search)).then((res) => {
                let data = res.result;
                data.items.map((item) => {
                    item.isPending = item.isPending
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    return item;
                })
                setMessages(data)
            }
        )
    }, [location.search]);


    const queueSmsHandler = async (item, isApprove, type) => {
        try {
            let result = await Swal.fire({
                title: '',
                text: `آیا از ${
                    isApprove ? 'تایید' : 'عدم تایید'
                } این ${type === 'messages' ? 'متن' : 'کاربر'} اطمینان دارید؟`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonText: 'انصراف',
                cancelButtonColor: '#d33',
                confirmButtonText: 'بله، مطمئن هستم',
            });
            if (result.isConfirmed) {
                if (type === 'messages') {
                    await ApiClient.Patch(Urls.highConsumptionVerifyUserMessages(params.id), JSON.stringify(
                        {
                            shouldAccept: isApprove,
                            messageIds: [
                                ...item
                            ],
                            selectAll: false
                        }
                    ));
                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    setSelectedList([])
                    getSMSQueue();
                } else {
                    await ApiClient.Patch(Urls.highConsumptionVerifyPerUser(params.id));
                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    history.push('/pending-sms/webservice-repeat')
                }
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const tableSelectedList = (item) => {
        setSelectedList([...item.map(a => a.id)]);
    };
    const onApprovedUser = () => {
        queueSmsHandler(null, true, 'user')
    }

    const selectAllAction = async (isApprove) => {
        try {
            let result = await Swal.fire({
                title: '',
                text: `آیا از ${
                    isApprove ? 'تایید' : 'عدم تایید'
                } پیام های این کاربر اطمینان دارید؟`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonText: 'انصراف',
                cancelButtonColor: '#d33',
                confirmButtonText: 'بله، مطمئن هستم',
            });
            if (result.isConfirmed) {
                ApiClient.Patch(Urls.highConsumptionVerifyUserMessages(params.id), JSON.stringify(
                    {
                        shouldAccept: isApprove,
                        messageIds: [],
                        selectAll: true
                    }
                )).then(result => {
                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    getSMSQueue();
                    history.push('/pending-sms/webservice-repeat')
                });
            }
        } catch (error) {
            toast.error(error);
        }
    }

    useEffect(() => {
        ApiClient.Get(Urls.highConsumptionVerifyPerUser(params.id), ConvertQueryString(location.search)).then(async (res) => {
            let data = res.result;
            data.messages.items.map((item) => {
                item.isPending = item.isPending
                    ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                    : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                return item;
            })
            setpendingData(data);
            if (!ConvertQueryString(location.search).pageNumber) {
                setMessages(data.messages)
            }
        });
    }, []);

    useEffect(() => {

        if (pendingData.hasOwnProperty('userName')) {
            getSMSQueue()
        } else {
            if (!location.search.includes('onlyPendings')) {
                history.push('?onlyPendings=true')
            }
        }
    }, [getSMSQueue])

    return (
        <>
            <HeaderContent className="justify-content-end pending-sms">
                <div className="pending-sms-row d-flex align-items-center justify-content-start w-100">
                    <StyledButton
                        btnStyle="greenBtn mx-1"
                        onClick={onApprovedUser}
                    >
                        بازنشانی گنجایش
                    </StyledButton>

                </div>
                <BackToPage toPage="/pending-sms/webservice-repeat"/>
            </HeaderContent>
            <MainContent>

                <PageTitleLayout>
                    <div className="d-flex">
                        <h5 className="w-50">اطلاعات کاربر</h5>
                        <div className="pending-sms-row d-flex align-items-center justify-content-end w-100">
                            <StyledButton
                                btnStyle="redBtn mx-1"
                                onClick={() => {
                                    selectAllAction(false)
                                }}
                            >
                                عدم تائید کلی پیام ها
                            </StyledButton>
                            <StyledButton
                                btnStyle="greenBtn mx-1"
                                onClick={() => {
                                    selectAllAction(true)
                                }}
                            > تائید کلی پیام ها </StyledButton>
                        </div>

                    </div>
                </PageTitleLayout>
                <div className="row">
                    <div className="col-12">
                        <ul className="params text-right w-100">
                            <li className="params__list">
                                <div className="params__list__label">
                                    <span>نام کاربری</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{pendingData.userName}</span>
                                </div>
                            </li>
                            <li className="params__list">
                                <div className="params__list__label">
                                    <span>شماره موبایل</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{pendingData.mobile}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <div className="pending-sms-row d-flex align-items-center justify-content-end w-100">
                            <StyledButton
                                btnStyle="redBtn mx-1"
                                disabled={!selectedList.length}
                                onClick={() => {
                                    queueSmsHandler(selectedList, false, 'messages')
                                }}
                            >
                                عدم تائید پیام های انتخاب شده
                            </StyledButton>
                            <StyledButton
                                btnStyle="greenBtn mx-1"
                                disabled={!selectedList.length}
                                onClick={() => {
                                    queueSmsHandler(selectedList, true, 'messages')
                                }}
                            > تائید پیام های انتخاب شده</StyledButton>
                        </div>
                        <Table
                            data={messages.items}
                            config={tableConfig}
                            pageConfig={{
                                pageNumber: messages.pageNumber,
                                pageSize: messages.pageSize,
                                totalItemsCount: messages.totalItemsCount,
                                totalPagesCount: messages.totalPagesCount
                            }}
                            selectedDispatch={tableSelectedList}
                        />
                    </div>
                </div>
            </MainContent>
        </>
    );
}

export default SingleRepeatVerifySMs;
