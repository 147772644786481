import React, {useEffect, useRef} from "react";
import {useFormik} from "formik";
import {
    FormLable,
    FormLayout,
    StyledCheckBox,
    StyledForm,
    StyledInput,
    StyledRadioBtn,
    StyledSelect
} from "../../../../Component/Kit/Form";

function Bank({defaultData, updateBankAction}) {
    const isMounted = useRef(false);

    const officialTypeOptions = [
        {name: "رسمی - غیر رسمی", value: "null"},
        {name: "رسمی", value: 'true'},
        {name: "غیر رسمی", value: 'false'},
    ];

    const formik = useFormik({
        initialValues: {
            ...defaultData,
        },
    });

    const convertDataForm = (data) => {
        const convertModel = [
            {name: null, value: 'null'},
            {name: false, value: 'false'},
            {name: true, value: 'true'},
        ];
        const updatedObject = Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
                const findModel = convertModel.find(f => f.value === value)?.name;
                const updatedValue = findModel !== undefined ? findModel : value
                return [key, updatedValue];
            })
        );
        delete updatedObject.title;

        updateBankAction(updatedObject)
    }

    useEffect(() => {
        if (isMounted.current) {
            convertDataForm(formik.values)
        } else {
            isMounted.current = true;
        }
    }, [formik.values]);


    return (
        <>
            <div className="px-0">
                <StyledForm
                    className="px-0"
                    style={{fontSize: '.85rem'}}
                >
                    <FormLayout key={defaultData.id}>
                        <div className="form-title ">
                            <span>{defaultData.title}</span>
                        </div>
                        <div key={defaultData.id} className="d-flex align-items-center justify-content-start flex-wrap">
                            <StyledInput
                                formGroupClass="col-12 col-sm-6 col-xl-3"
                                lable="عنوان نمایشی"
                                focus={true}
                                type="text"
                                name='showTitle'
                                value={formik.values.showTitle || ''}
                                error={(formik.errors.showTitle && formik.touched.showTitle)
                                    ? formik.errors.showTitle
                                    : 'false'
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("showTitle", e.target.value)
                                }
                            />
                            <StyledSelect
                                formGroupClass="col-12 col-sm-6 col-xl-3"
                                lable="نوع"
                                focus={true}
                                name="isOfficial"
                                value={formik.values.isOfficial !== undefined
                                    ? formik.values.isOfficial
                                    : formik.initialValues.isOfficial}
                                optionItems={officialTypeOptions}
                                error={(formik.errors.isOfficial && formik.touched.isOfficial)
                                    ? formik.errors.isOfficial
                                    : 'false'
                                }
                                onChange={(e) => {
                                    formik.setFieldValue("isOfficial", e.target.value)
                                }}
                            />


                            <StyledCheckBox
                                formGroupClass="col-12 col-sm-6 col-xl-6 pt-2"
                                lable="درگاه پرداخت آنلاین دارد"
                                focus={true}
                                name="hasGateway"
                                id="hasGateway"
                                checked={
                                    formik.values.hasGateway !== undefined
                                        ? formik.values.hasGateway
                                        : formik.initialValues.hasGateway
                                }
                                error="false"
                                onChange={(e) =>
                                    formik.setFieldValue("hasGateway", e.target.checked)
                                }
                            />


                            <div className="col-12 col-sm-6 col-xl-3">
                                <FormLable show={true}>
                                    بانک پیش فرض
                                </FormLable>
                                <div className="d-flex align-items-center">
                                    <StyledRadioBtn label='بله' value={'true'} name={`isDefault-${defaultData.id}`}
                                                    id={`isDefault-${defaultData.id}-official`}
                                                    checked={formik.values[`isDefault`] === 'true'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`isDefault`, e.target.value)
                                                    }
                                                    }/>
                                    <StyledRadioBtn value={'false'} name={`isDefault-${defaultData.id}`}
                                                    id={`isDefault-${defaultData.id}-notOfficial`}
                                                    label='خیر'
                                                    checked={formik.values[`isDefault`] === 'false'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`isDefault`, e.target.value)
                                                    }}
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-xl-3">
                                <FormLable show={true}>
                                    وضعیت
                                </FormLable>
                                <div className="d-flex align-items-center">
                                    <StyledRadioBtn label='فعال' value={'true'} name={`isActive-${defaultData.id}`}
                                                    id={`isActive-${defaultData.id}-active`}
                                                    checked={formik.values[`isActive`] === 'true'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`isActive`, e.target.value)
                                                    }
                                                    }/>
                                    <StyledRadioBtn value={'false'} name={`isActive-${defaultData.id}`}
                                                    id={`isActive-${defaultData.id}-notActive`}
                                                    label='غیر فعال'
                                                    checked={formik.values[`isActive`] === 'false'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`isActive`, e.target.value)
                                                    }}
                                    />
                                </div>
                            </div>


                        </div>
                    </FormLayout>

                </StyledForm>
            </div>


        </>
    )
}

export default (Bank);
