import {Formik} from "formik";
import moment from "moment-jalaali";
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import BackToPage from "../../../../../Component/Kit/back";
import {StyledButton} from "../../../../../Component/Kit/Button";
import {
  FormLable,
  StyledForm,
  StyledInput,
  StyledRadioBtn,
  StyledSelect,
  StyledTextArea
} from "../../../../../Component/Kit/Form";
import {HeaderContent, MainContent, PageTitleLayout} from "../../../../../Component/Kit/Layout";
import Modal from "../../../../../Component/Kit/Modal";
import {MyDatePicker} from "../../../../../Component/Shared/DatePicker";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";
import {CompanyActivityList, CompanyTypeList} from "../../../../../Utility/Model/company.model";
import {EditGuestLegalSchema, EditGuestSchema} from "../../../../../Utility/Validation/Schema/EditGuestSchema";
import GuestStep from "../../../Services/guest-step";
import {connect} from "react-redux";

function ModifyGuestInformation({data}) {
  let match = useRouteMatch();
  let history = useHistory();
  const [nationalCard, setNationalCard] = useState(false);
  const [gazette, setGazette] = useState(false);
  const [guestData, setGuestData] = useState({});
  const [provinceItem, setProvinceItem] = useState([]);
  const [cityItem, setCityItem] = useState([]);

  const init = useCallback(async () => {
    setGuestData(data)
    const province = await ApiClient.Get(Urls.getProvince());
    setProvinceItem(province.result)
    if (data.provinceId) {
      const city = await ApiClient.Get(Urls.getCity(data.provinceId))
      setCityItem(city.result)
    }
  }, [data])

  useEffect(() => {
    init().then()
  }, [init]);
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12">
          <PageTitleLayout>
            <h5> ویرایش کاربر مهمان</h5>
            <span>در این بخش می‌توانید اطلاعات کاربر مهمان را ویرایش نمایید.</span>
          </PageTitleLayout>
          <hr className="mt-4 mx-2" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8">
          <Formik
            initialValues={{
              guestFirstName: guestData.firstName,
              guestLastName: guestData.lastName,
              guestFatherName: guestData.fatherName,
              nationalId: guestData.nationalId,
              guestCertificateNo: guestData.certificateNo,
              guestIsMale: guestData.isMale,
              guestBirthDate: guestData.birthDate,
              guestPhoneNumber: guestData.phoneNumber,
              guestPostalCode: guestData.postalCode,
              guestAddress: guestData.address,
              cityId: guestData.cityId,
              provinceId: guestData.provinceId,
              guestCompanyActivityField: guestData.companyActivityField,
              guestCompanyName: guestData.companyName,
              guestRegistrationNumber: guestData.registrationNumber,
              guestLegalNationalId: guestData.legalNationalId,
              guestCompanyType: guestData.companyType,
              avenue:guestData?.avenue,
              alley: guestData?.alley,
              plaque: guestData?.plaque,
            }}
            validationSchema={guestData.isLegal ? EditGuestLegalSchema : EditGuestSchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              let body = {
                "firstName": values.guestFirstName,
                "lastName": values.guestLastName,
                "fatherName": values.guestFatherName,
                "nationalId": values.nationalId,
                "certificateNo": values.guestCertificateNo,
                "isMale": values.guestIsMale,
                "birthDate": parseInt(values.guestBirthDate),
                "phoneNumber": values.guestPhoneNumber,
                "postalCode": values.guestPostalCode,
                "cityId": values.cityId,
                "provinceId": values.provinceId,
                "address": values.guestAddress,
                "companyActivityField": parseInt(values.guestCompanyActivityField),
                "companyName": values.guestCompanyName,
                "registrationNumber": values.guestRegistrationNumber,
                "legalNationalId": values.guestLegalNationalId,
                "companyType": parseInt(values.guestCompanyType),
                "avenue":values?.avenue,
                "alley": values?.alley,
                "plaque": values?.plaque,
              }
              Swal.fire({
                title: "ویرایش کاربر مهمان",
                text: "آیا تغییرات ذخیره شود؟",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "انصراف",
                cancelButtonColor: "#d33",
                confirmButtonText: "بله، مطمئن هستم",
              }).then((result) => {
                if (result.isConfirmed) {
                  ApiClient.Put(Urls.guest(match.params.id), body)
                    .then(res => {
                      toast.success('تغییرات  با موفقیت ثبت شد.');
                      history.push(`/guest/item/${match.params.id}`)
                    })

                }
              });

            }}
          >
            {(formik) => (
              <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledInput
                      lable="نام"
                      focus={true}
                      placeholder="نام"
                      type="text"
                      name="guestFirstName"
                      value={formik.values.guestFirstName}
                      error={(formik.errors.guestFirstName && formik.touched.guestFirstName)
                        ? formik.errors.guestFirstName
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("guestFirstName", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledInput
                      lable="نام خانوادگی"
                      focus={true}
                      placeholder="نام خانوادگی"
                      type="text"
                      name="guestLastName"
                      value={formik.values.guestLastName}
                      error={(formik.errors.guestLastName && formik.touched.guestLastName)
                        ? formik.errors.guestLastName
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("guestLastName", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledInput
                      lable="نام پدر"
                      focus={true}
                      placeholder="نام پدر"
                      type="text"
                      name="guestFatherName"
                      value={formik.values.guestFatherName}
                      error={(formik.errors.guestFatherName && formik.touched.guestFatherName)
                        ? formik.errors.guestFatherName
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("guestFatherName", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledInput
                      lable="کدملی"
                      focus={true}
                      placeholder="کدملی"
                      maxLength="10"
                      inputMode="numeric"
                      name="nationalId"
                      value={formik.values.nationalId}
                      error={(formik.errors.nationalId && formik.touched.nationalId)
                        ? formik.errors.nationalId
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("nationalId", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledInput
                      lable="شماره شناسنامه"
                      focus={true}
                      placeholder="شماره شناسنامه"
                      type="text"
                      name="guestCertificateNo"
                      value={formik.values.guestCertificateNo}
                      error={(formik.errors.guestCertificateNo && formik.touched.guestCertificateNo)
                        ? formik.errors.guestCertificateNo
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("guestCertificateNo", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <FormLable show={true}>
                      تاریخ تولد
                    </FormLable>
                    <MyDatePicker
                      defaultValue={formik.values.guestBirthDate}
                      value={(event) => {
                        formik.setFieldValue(
                          "guestBirthDate",
                          event.baseFormat
                        )
                      }}
                      name="guestBirthDate"
                      max={moment().subtract(18, 'years')}
                      error={
                        formik.errors.guestBirthDate !== undefined
                          ? formik.errors.guestBirthDate
                          : false
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledInput
                      lable="شماره تلفن"
                      focus={true}
                      placeholder="شماره تلفن"
                      type="text"
                      name="guestPhoneNumber"
                      value={formik.values.guestPhoneNumber}
                      error={(formik.errors.guestPhoneNumber && formik.touched.guestPhoneNumber)
                        ? formik.errors.guestPhoneNumber
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("guestPhoneNumber", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledInput
                      lable="کدپستی"
                      focus={true}
                      placeholder="کدپستی"
                      type="text"
                      name="guestPostalCode"
                      value={formik.values.guestPostalCode}
                      error={(formik.errors.guestPostalCode && formik.touched.guestPostalCode)
                        ? formik.errors.guestPostalCode
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("guestPostalCode", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3">
                    <FormLable show={true}> جنسیت </FormLable>
                    <div className="d-flex align-items-center justify-content-between">
                      <StyledRadioBtn id='male-gender' name='gender' value={true} label='مرد'
                                      checked={formik.values.guestIsMale}
                                      onChange={(e) =>
                                        formik.setFieldValue("guestIsMale", (e.target.value === 'true'))
                                      } />
                      <StyledRadioBtn id='female-gender' name='gender' value={false}
                                      label='زن'
                                      checked={!formik.values.guestIsMale}
                                      onChange={(e) =>
                                        formik.setFieldValue("guestIsMale", (e.target.value === 'true'))
                                      } />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledSelect
                      lable="نوع فعالیت"
                      hasDefaultOption
                      focus={true}
                      name="guestCompanyActivityField"
                      value={formik.values.guestCompanyActivityField}
                      optionItems={CompanyActivityList}
                      error={(formik.errors.guestCompanyActivityField && formik.values.guestCompanyActivityField)
                        ? formik.errors.guestCompanyActivityField
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("guestCompanyActivityField", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledSelect
                      lable="استان"
                      hasDefaultOption
                      focus={true}
                      name="provinceId"
                      value={formik.values.provinceId}
                      optionItems={provinceItem}
                      error={(formik.errors.provinceId && formik.values.provinceId)
                        ? formik.errors.provinceId
                        : 'false'
                      }
                      onChange={(e) => {
                        formik.setFieldValue("provinceId", e.target.value);
                        ApiClient.Get(Urls.getCity(e.target.value)).then(
                          (res2) => {
                            setCityItem(res2.result)
                            formik.setFieldValue("cityId", res2.result[0].value)
                          }
                        )

                      }
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <StyledSelect
                      lable="شهر"
                      hasDefaultOption
                      focus={true}
                      name="cityId"
                      value={formik.values.cityId}
                      optionItems={cityItem}
                      error={(formik.errors.cityId && formik.values.cityId)
                        ? formik.errors.cityId
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("cityId", e.target.value)
                      }
                    />
                  </div>
                  {guestData.isLegal ? (
                    <>
                      <div className="col-12 col-md-6 col-lg-3">
                        <StyledInput
                          lable="نام شرکت"
                          focus={true}
                          placeholder="نام شرکت"
                          type="text"
                          name="guestCompanyName"
                          value={formik.values.guestCompanyName}
                          error={(formik.errors.guestCompanyName && formik.touched.guestCompanyName)
                            ? formik.errors.guestCompanyName
                            : 'false'
                          }
                          onChange={(e) =>
                            formik.setFieldValue("guestCompanyName", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <StyledInput
                          lable="شماره ثبت"
                          focus={true}
                          placeholder="شماره ثبت"
                          type="text"
                          name="guestRegistrationNumber"
                          value={formik.values.guestRegistrationNumber}
                          error={(formik.errors.guestRegistrationNumber && formik.touched.guestRegistrationNumber)
                            ? formik.errors.guestRegistrationNumber
                            : 'false'
                          }
                          onChange={(e) =>
                            formik.setFieldValue("guestRegistrationNumber", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <StyledInput
                          lable="شناسه ملی"
                          focus={true}
                          placeholder="شناسه ملی"
                          type="text"
                          name="guestLegalNationalId"
                          value={formik.values.guestLegalNationalId}
                          error={(formik.errors.guestLegalNationalId && formik.touched.guestLegalNationalId)
                            ? formik.errors.guestLegalNationalId
                            : 'false'
                          }
                          onChange={(e) =>
                            formik.setFieldValue("guestLegalNationalId", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <StyledSelect
                          lable="نوع شرکت"
                          hasDefaultOption
                          focus={true}
                          name="guestCompanyType"
                          value={formik.values.guestCompanyType}
                          optionItems={CompanyTypeList}
                          error={(formik.errors.guestCompanyType && formik.values.guestCompanyType)
                            ? formik.errors.guestCompanyType
                            : 'false'
                          }
                          onChange={(e) =>
                            formik.setFieldValue("guestCompanyType", e.target.value)
                          }
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                  <div className="row">
                      <div className="col-12 col-md-4">
                          <StyledInput
                              lable="خیابان"
                              focus={true}
                              name="avenue"
                              value={formik.values.avenue}
                              error={(formik.errors.avenue && formik.touched.avenue)
                                  ? formik.errors.avenue
                                  : 'false'
                              }
                              onChange={(e) =>
                                  formik.setFieldValue(
                                      "avenue",
                                      e.target.value
                                  )
                              }
                          />
                      </div>
                      <div className="col-12 col-md-4">
                          <StyledInput
                              lable="کوچه"
                              focus={true}
                              name="alley"
                              value={formik.values.alley}
                              error={(formik.errors.alley && formik.touched.alley)
                                  ? formik.errors.alley
                                  : 'false'
                              }
                              onChange={(e) =>
                                  formik.setFieldValue(
                                      "alley",
                                      e.target.value
                                  )
                              }
                          />
                      </div>
                      <div className="col-12 col-md-4">
                          <StyledInput
                              lable="پلاک"
                              focus={true}
                              name="plaque"
                              value={formik.values.plaque}
                              error={(formik.errors.plaque && formik.touched.plaque)
                                  ? formik.errors.plaque
                                  : 'false'
                              }
                              onChange={(e) =>
                                  formik.setFieldValue(
                                      "plaque",
                                      e.target.value
                                  )
                              }
                          />
                      </div>
                  </div>
                <div className="row">
                  <div className="col-12">
                    <StyledTextArea
                      lable="آدرس"
                      focus={true}
                      name="guestAddress"
                      value={formik.values.guestAddress}
                      error={(formik.errors.guestAddress && formik.touched.guestAddress)
                        ? formik.errors.guestAddress
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue(
                          "guestAddress",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="py-2">
                  <StyledButton
                    type="submit"
                    width="100%"
                    btnStyle="darkGreenBtn"
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    ویرایش
                  </StyledButton>
                </div>
              </StyledForm>

            )}

          </Formik>
        </div>
        <div className="col-12 col-lg-4">
          {guestData.nationalCardUploadedURL ? (
            <div>
              <div
                className="guest-image-wrapper"
                onClick={() => setNationalCard(true)}
              >
                <img
                  src={guestData.nationalCardUploadedURL}
                  alt="nationalCard"
                />
              </div>
              <Modal
                onClose={() => setNationalCard(false)}
                visible={nationalCard}
                headerTitle="اطلاعات هویتی"
              >
                <div className="guest-image-wrapper">
                  <img
                    src={guestData.nationalCardUploadedURL}
                    alt="nationalCard"
                  />
                </div>
              </Modal>
            </div>
          ) : (
            <div
              className="alert alert-dark text-right d-flex align-items-center"
              role="alert"
            >
              <i className="fa fa-exclamation-circle ml-2" />
              <span className="small"> مدارک شناسایی آپلود نشده است.</span>
            </div>
          )}
          {guestData.isLegal ? (
            guestData.gazetteUploadedURL ? (
              <div>
                <div
                  className="guest-image-wrapper"
                  onClick={() => setGazette(true)}
                >
                  <img src={guestData.gazetteUploadedURL} alt="gazette" />
                </div>
                <Modal
                  onClose={() => setGazette(false)}
                  visible={gazette}
                  headerTitle="روزنامه رسمی"
                >
                  <div className="guest-image-wrapper">
                    <img src={guestData.gazetteUploadedURL} alt="gazette" />
                  </div>
                </Modal>
              </div>
            ) : (
              <div
                className="alert alert-dark text-right d-flex align-items-center"
                role="alert"
              >
                <i className="fa fa-exclamation-circle ml-2" />
                <span className="small"> روزنامه رسمی آپلود نشده است.</span>
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  data: state.guestData,
});

export default connect(mapStateToProps)(ModifyGuestInformation);
