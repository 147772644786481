

import { date, number } from "../Rules";
import * as Yup from "yup";

export const NumberingInvoiceSchema =   Yup.object().shape({
    number,
    date,
})

