import React, {useEffect, useState} from 'react'
import Table from "../../Component/Shared/Table/Table";
import {useHistory, useLocation} from "react-router-dom";
import {StyledButton} from '../../Component/Kit/Button';
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from '../../Component/Kit/Layout';
import ApiClient from '../../Requester/ApiClient';
import Urls from '../../Requester/Urls';
import TicketDepartment from '../../Utility/Services/TicketDepartmentService';
import {DeleteTemplateModal} from './DeleteTemplateModal';
import {ConvertQueryString} from "../../Utility/Services/convert-query-string";
import {connect} from "react-redux";
import {RoleCanActivate} from "../../Utility/Services/roleCanActivate";

const TicketAnswerTemplates = ({adminProfile}) => {
    const location = useLocation()
    const [templateData, setTemplateData] = useState({});
    const [templateItems, setTemplateItems] = useState([]);

    // const [tableFilter, setTableFilter] = useState({});
    const history = useHistory();

    const [visible, setVisible] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [changedata, setChangedata] = useState(true);

    const handleClick = () => {
        history.push("/ticket/template/add");
    };


    const deleteTemplateHandle = (target) => {
        setTemplateId(target.id);
        setVisible(true);
    }

    const deleteTemplateModalHanlder = (modalState) => {
        setVisible(modalState);
    }

    const templateTableConfig = {
        header: {
            title: "عنوان",
            department: "دپارتمان",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
            delete: {
                icon: "fa fa-trash",
                condition: () => RoleCanActivate(['supporter'], adminProfile.roleId),
            }
        },
        mode: ["filter", "search"],
        filters: ["department"],
    };

    function tableActions(items) {
        switch (items.actionName) {
            case "view":
                history.push(`/ticket/template/id=${items.data.id}`);
                break;
            case "delete":
                deleteTemplateHandle(items.data)
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        ApiClient.Get(Urls.TicketAnswerTemplates(), ConvertQueryString(location.search))
            .then((res) => {
                let resData = res.result
                resData.items.map((item) => {
                    item.department = TicketDepartment(item.department);
                    return item;
                })

                setTemplateData(resData);
                setTemplateItems(resData.items);

                setChangedata(false)
            })

    }, [location.search, changedata])


    return (
        <WrapperLayout>
            <div className="c-wrapper">
                {adminProfile && adminProfile.roleId ?
                    RoleCanActivate(['supporter'], adminProfile.roleId) ? (
                        <HeaderContent>
                            <StyledButton btnStyle="greenBtn" onClick={() => handleClick()}>
                                ایجاد قالب جدید
                            </StyledButton>
                        </HeaderContent>
                    ) : null
                    : null}


                <MainContent>
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout>
                            <h5>لیست قالب تیکت </h5>
                            <span>در این بخش لیست همه قالب‌های تیکت قابل مشاهده است.</span>
                        </PageTitleLayout>
                        <Table
                            data={templateItems}
                            config={templateTableConfig}
                            actionDispatched={tableActions}
                            pageConfig={{
                                pageNumber: templateData.pageNumber,
                                pageSize: templateData.pageSize,
                                totalItemsCount: templateData.totalItemsCount,
                                totalPagesCount: templateData.totalPagesCount,
                            }}
                        ></Table>
                    </div>
                </MainContent>
            </div>


            <DeleteTemplateModal
                setChangedata={setChangedata}
                templateId={templateId}
                setVisible={setVisible}
                visible={visible}
                onClose={() => deleteTemplateModalHanlder(false)}
                onCancel={() => deleteTemplateModalHanlder(false)}
            />

        </WrapperLayout>

    )
}

function mapStateToProps(state) {
    const {bootstrapper} = state
    return {adminProfile: bootstrapper.data}
}

export default connect(mapStateToProps)(TicketAnswerTemplates);
