
import * as Yup from "yup";

export const AddAssignmentTicket = Yup.object().shape({
    adminIds: Yup.array().required("این فیلد الزامی است."),
    startDate: Yup.string().required("این فیلد الزامی است."),
    endDate:  Yup.string().required("این فیلد الزامی است."),
    startTime:  Yup.string().required("این فیلد الزامی است."),
    endTime:  Yup.string().required("این فیلد الزامی است.")
});
