import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { PageTitleLayout } from "../../../Component/Kit/Layout";
import DateConvert from "../../../Utility/Services/date-convert";
import { VerifyStatusService } from "../../../Utility/Services/fast-send-template";
import Modal from "../../../Component/Kit/Modal";
import UserFastSendTemplateView from "./user-fast-send-view";
import { ConvertQueryString } from "../../../Utility/Services/convert-query-string";

function UserCustomNotifications() {
    let match = useRouteMatch();
    let location = useLocation();
    const [templateList, settemplateList] = useState({});
    const [isModal, setIsModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [isReject, setIsReject] = useState(false)
    const tableConfig = {
        header: {
            title: "عنوان",
            id: "شناسه قالب",
            verifyStatusName: "وضعیت قالب",
            createdAt: "تاریخ ایجاد",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
        },
        mode: ["filter", "search"],
        filters: ["datePickerFilter", "templateStatus"],
    };

    const tableActions = (item) => {
        if (item.actionName === 'view') {
            // let data = templateList.items.find(m => m.id === item.userId)
            setModalData(item.data);
            setIsModal(true)
        }
    };
    const modalDataChange = (item) => {
        if (item) getUserFastSendTemplate()
    }
    const getUserFastSendTemplate = useCallback(() => {
        ApiClient.Get(Urls.userFastSendTemplate(match.params.id), ConvertQueryString(location.search))
            .then((res) => {
                let data = res.result;
                data.items.map((m) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.verifyStatusName = VerifyStatusService(m.verifyStatus);
                    return m
                });
                settemplateList(data);
            });
    }, [match.params.id, location.search])
    useEffect(() => {
        getUserFastSendTemplate()
    }, [match.params.id, location.search, getUserFastSendTemplate]);
    return (
        <>
            <PageTitleLayout>
                <h5>قالب های ارسال سریع</h5>
                <span>شما می‌توانید قالب های ارسال سریع مخصوص این کاربر را ایجاد نمایید.</span>
            </PageTitleLayout>

            <Table
                data={templateList.items}
                config={tableConfig}
                pageConfig={{
                    pageNumber: templateList.pageNumber,
                    pageSize: templateList.pageSize,
                    totalItemsCount: templateList.totalItemsCount,
                    totalPagesCount: templateList.totalPagesCount,
                }}
                actionDispatched={tableActions}
            />
            <Modal
                onClose={() => {
                    setIsModal(false)
                    setIsReject(false)
                }}
                onCancel={() => {
                    setIsModal(false)
                    setIsReject(false)
                }}
                visible={isModal}
            >
                <UserFastSendTemplateView data={modalData}
                    dataChange={modalDataChange}
                    modalReject={isReject}
                    rejectHandler={(event) => setIsReject(event)}

                    onCloseModal={(e) => {
                        setIsModal(e);
                        setIsReject(e)
                    }} />
            </Modal>
        </>
    );
}

export default UserCustomNotifications;
