import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { StyledButton } from "../../../../Component/Kit/Button";
import { StyledForm, StyledInput } from "../../../../Component/Kit/Form";
import { BasePriceDisCountSchema } from "../../../../Utility/Validation/Schema/ModifyDiscountCodeSchema";
import { SeprateNumberWithComma } from "../../../../Utility/Services/SeprateNumberWithComma";
import { useParams } from "react-router-dom";

const BasePriceDiscountCodeStep = (props) => {
    const { step, updateState, initalData, formData, tiers } = props;
    const [basePriceDisCount, setBasePriceDisCount] = useState([]);
    const params = useParams();
    
    const discountFieldName = {
        fromPrice: "از قیمت",
        toPrice: "تا قیمت",
        basePrice: "قیمت پایه",
        discountedBasePrice: "تخفیف قیمت پایه",
    };

    useEffect(() => {
        if (Boolean(formData) && Object.entries(formData).length !== 0) {
            // Dirty form in Edit mode or create
            setBasePriceDisCount(formData.basePriceDisCount)
        }
        else {
            if (Object.entries(initalData).length > 0 && params.id) {
                //  edit mode without changing 
                const discountList = initalData.discountedBasePriceInTiers
                    .filter(item => item.discountedBasePrice)
                    .map(obj => String(obj.discountedBasePrice));
                setBasePriceDisCount(discountList)
            }
            else {
                // first time create 
                setBasePriceDisCount(Array(Object.entries(tiers).length).fill());
            }
        }
    }, [tiers]);

    return (
        <div className="row">
            {
                // Object.entries(basePriceDisCount).length > 0 && 
                basePriceDisCount.length > 0 &&
                <Formik
                    initialValues={{
                        basePriceDisCount,
                    }}
                    validationSchema={BasePriceDisCountSchema}
                    onSubmit={(values, actions) => {
                        /* let data = {}
                        Object.values(tiers).map((item, index) => {
                            data[item.id] = Number(values.basePriceDisCount[index])
                        })
                        updateState({ step: step + 1, data: data }) */
                        updateState({ step: step + 1, data: values })
                    }}
                >
                    {(formik) => (
                        <FieldArray name="basePriceDisCount">
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onBlur={formik.handleBlur}
                                onReset={formik.handleReset}
                            >
                                <div className="table-responsive custom-table p-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                {discountFieldName &&
                                                    Object.entries(discountFieldName).map(
                                                        ([item, value]) => (
                                                            <th scope="col" key={`headLine_${item}`}>
                                                                {value}
                                                            </th>
                                                        )
                                                    )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values(tiers).map((dataItem, index) => (
                                                <>
                                                    <tr key={`creditTierId-${dataItem.id}`}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{SeprateNumberWithComma(dataItem.fromPrice)}</td>
                                                        <td>{SeprateNumberWithComma(dataItem.toPrice)}</td>
                                                        <td>{dataItem.basePrice}</td>
                                                        <td>
                                                            <StyledInput
                                                                formGroupClass="creditTierInput"
                                                                type="text"
                                                                focus={false}
                                                                name={`basePriceDisCount[${index}]`}
                                                                id={`basePriceDisCount.${index}`}
                                                                key={`basePriceDisCount.${index}`}
                                                                value={formik.values.basePriceDisCount[index] !== undefined
                                                                    ? formik.values.basePriceDisCount[index]
                                                                    : formik.initialValues.basePriceDisCount[index]}
                                                                // error={(formik.errors && formik.errors.basePriceDisCount && formik.errors.basePriceDisCount[index])
                                                                //     ? formik.errors.basePriceDisCount[index]
                                                                //     : 'false'
                                                                // }
                                                                error={(formik.touched && formik.touched.basePriceDisCount && formik.touched.basePriceDisCount[index] && formik.values.basePriceDisCount[index] == 0)
                                                                    ? "این فیلد الزامی است."
                                                                    : ((formik.errors && formik.errors.basePriceDisCount && formik.errors.basePriceDisCount[index]) ? formik.errors.basePriceDisCount[index] : 'false')
                                                                }
                                                                onChange={(e) => formik.setFieldValue(`basePriceDisCount.${index}`, e.target.value)} />
                                                        </td>
                                                    </tr>
                                                    <tr className="spacer" key={`spacer${dataItem.creditTierId}`}                                                    >
                                                        <td colSpan="100"></td>
                                                    </tr>
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-4 d-flex justify-content-between">
                                    <StyledButton
                                        onClick={() => updateState({ step: step - 1 })}
                                        btnStyle="grayBtn"
                                        type="button"
                                        id="prevStep"
                                    >
                                        مرحله قبلی
                                    </StyledButton>
                                    <StyledButton
                                        disabled={!formik.isValid || (!formik.dirty && !initalData.discountedBasePriceInTiers && (formData && !formData.basePriceDisCount))}
                                        btnStyle="greenBtn"
                                        id="secondStep"
                                        type="submit"
                                    >
                                        مرحله بعدی
                                    </StyledButton>
                                </div>
                            </StyledForm>
                        </FieldArray>
                    )}

                </Formik>
            }
        </div >
    );
};

const mapStateToProps = (state) => ({
    tiers: state.BasePriceTiersReducer,
});

export default connect(mapStateToProps)(BasePriceDiscountCodeStep);
