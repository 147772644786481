import React from "react";
import { StyledButton } from "../../../Component/Kit/Button";

const notificationTitle = {
    title: "عنوان اعلان",
    createdAt: "تاریخ",
    isReadTitle: "وضعیت",
    content: "توضیحات",
}

function NotificationView({ data, onCloseModal }) {
    return (
        <>
            <div className="w-100">
                <ul className="params">
                    {
                        notificationTitle && data &&
                        Object.entries(notificationTitle).map(([key, value]) => (

                            <li className="params__list" key={value}>
                                <div className="params__list__label">
                                    <span>{value}: </span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{data[key]}</span>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className="d-flex justify-content-center w-100">
                <StyledButton
                    width="35%"
                    btnStyle="grayBtn"
                    type="reset"
                    onClick={() => {
                        onCloseModal(false)
                    }}
                >
                    بستن
                </StyledButton>
            </div>
        </>
    )
}

export default NotificationView;
