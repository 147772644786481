import React, { useCallback, useEffect, useState } from 'react';
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import DateConvert from "../../../Utility/Services/date-convert";
import { PageTitleLayout } from "../../../Component/Kit/Layout";
import Table from "../../../Component/Shared/Table/Table";
import Modal from "../../../Component/Kit/Modal"
import { VerifyStatusService } from "../../../Utility/Services/fast-send-template";
import FastSendTemplateNotPendingModal from "./not-pending-modal";
import {useHistory, useLocation} from "react-router-dom";
import { ConvertQueryString } from "../../../Utility/Services/convert-query-string";
import { FastSendTemplateStatus } from '../../../Utility/Model/fast-send-template.model';


const FastSendTemplatesNotPending = () => {
    const location = useLocation()
    const history = useHistory();
    const [notPendingData, setNotPendingData] = useState([])
    const [isModal, setIsModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [isReject, setIsReject] = useState(false)
    const tableConfig = {
        header: {
            userName: "نام کاربری",
            id: "شناسه قالب",
            title: "عنوان قالب",
            templateText: "متن قالب",
            verifyStatusName: "وضعیت قالب",
            createdAt: "تاریخ ایجاد",
        },
        tooltipTitle: ['templateText'],
        actions: {
            edit: {
                icon: 'fa fa-user',
            },
            view: {
                icon: "fa fa-eye",
            },

        },
        mode: ["filter", "search"],
        filters: ["notPendingTemplateStatus"],

    };

    const tableActions = (item) => {
        if (item.actionName === 'edit') {
            history.push(`/userDetail/${item.data.userId}/profile`)
        }
        if (item.actionName === 'view') {
            let data = item.data;
            setModalData(data)
            setIsModal(true)
        }
    };

    const modalDataChange = (item) => {
        if (item) getFastSendTemplateNotPendingList()
    }

    const getFastSendTemplateNotPendingList = useCallback(() => {
        const status = [FastSendTemplateStatus.IsVerified, FastSendTemplateStatus.UnVerified]
        let params = {
            ...ConvertQueryString(location.search),
            statuses: status.join(',')
        }
        ApiClient.Get(Urls.getFastSendTemplates(), params)
            .then(res => {
                let data = res.result;
                data.items.map(m => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.verifyStatusName = VerifyStatusService(m.verifyStatus);
                    return m;
                })
                setNotPendingData(data)
            })
    }, [location.search])

    useEffect(() => {
        getFastSendTemplateNotPendingList()
    }, [location.search, getFastSendTemplateNotPendingList])

    return (
        <>
            <PageTitleLayout className="mt-4">
                <h5>قالب‌ ماژول ارسال سریع </h5>
                <span>  </span>
            </PageTitleLayout>
            <Table
                data={notPendingData.items}
                config={tableConfig}
                pageConfig={{
                    pageNumber: notPendingData.pageNumber,
                    pageSize: notPendingData.pageSize,
                    totalItemsCount: notPendingData.totalItemsCount,
                    totalPagesCount: notPendingData.totalPagesCount,
                }}
                actionDispatched={tableActions}
            />
            <Modal
                headerTitle="تعیین وضعیت قالب ارسال سریع"
                onClose={() => {
                    setIsModal(false);
                    setIsReject(false)
                }}
                visible={isModal}
                onCancel={() => {
                    setIsModal(false);
                    setIsReject(false)
                }}
            >
                <FastSendTemplateNotPendingModal data={modalData}
                    dataChange={modalDataChange}
                    modalReject={isReject}
                    rejectHandler={(event) => setIsReject(event)}
                    onCloseModal={(e) => {
                        setIsModal(e);
                        setIsReject(e)
                    }} />
            </Modal>
        </>
    )
}

export default FastSendTemplatesNotPending;
