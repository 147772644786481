import OutcomeList, { OutcomeDescriptionList } from '../Models/outcome.model'

function GuestOutcome(value) {
  let result = OutcomeList.find((item) => item.value === value);
  return result.name
}
export default GuestOutcome


export function GuestOutcomeDescription(value) {
  let result = OutcomeDescriptionList.find((item) => item.value === value);
  return result.name
}

