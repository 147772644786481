import {Formik} from 'formik';
import React, {useRef} from 'react'
import {StyledButton} from "../../../../Component/Kit/Button";
import {FormLable, StyledForm, StyledInput, StyledSelect} from "../../../../Component/Kit/Form";
import {MyDatePicker} from "../../../../Component/Shared/DatePicker";
import moment from "moment-jalaali";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";


export const CreateAgreementModal = ({data, onSubmit, onCloseModal}) => {
    const SaleCooperationStatusEnum = {
        Pending: 1,
        InProgress: 2,
        Done: 3,
        Rejected: 4
    }
    const statusRef = useRef()
    const SaleCooperationStatusModal = [
        {value: 3, name: 'انجام شده'},
        {value: 4, name: 'رد شده'},
    ];
    return (
        <Formik
            initialValues={{
                    trackingNumber: '',
                    status: '',
                    settledAt: ''
                }}
            enableReinitialize={true}
            validate={(values) => {
                const errors = {};
                if (Number(values.status) === SaleCooperationStatusEnum.Done){
                    if (values.trackingNumber === '') {
                        errors.trackingNumber = 'این فیلد الزامی است.'
                    }
                    if (values.settledAt === '') {
                        errors.notificationEndDate = 'این فیلد الزامی است.'
                    }
                }
                return errors;
            }}
            onSubmit={(values, actions) => {
                if (data.status === SaleCooperationStatusEnum.Pending) {
                    values.status = SaleCooperationStatusEnum.InProgress;
                }
                ApiClient.Put(Urls.editSaleCooperationTransaction(data.id), values)
                    .then(res => {
                        actions.resetForm()
                        actions.setSubmitting(false);
                        onSubmit()
                    }).catch(
                    ()=> {
                        actions.setSubmitting(false);
                    }
                )
            }}
            onReset={(values, formikBag) => {
                onCloseModal()
            }}
        >
            {(formik) => (
                <StyledForm
                    onSubmit={formik.handleSubmit}
                    onBlur={formik.handleBlur}
                    onReset={formik.handleReset}
                >
                    {data.status == SaleCooperationStatusEnum.Pending &&
                        <div className="row my-4">
                            <p className="m-2">
                                تغییر وضعیت توافقنامه به حالت بررسی
                            </p>

                        </div>
                    }
                    {data.status === SaleCooperationStatusEnum.InProgress &&
                        <div className="row my-2">
                            <StyledSelect
                                formGroupClass="col-12"
                                lable="وضعیت"
                                hasDefaultOption
                                focus={true}
                                name="status"
                                value={formik.values.status}
                                optionItems={SaleCooperationStatusModal}
                                error={(formik.errors.status && formik.touched.status)
                                    ? formik.errors.status
                                    : 'false'
                                }
                                onChange={(e) => {
                                    formik.setFieldValue("status", e.target.value);
                                    statusRef.current = e.target.value;
                                }
                                }
                            />
                        </div>
                    }
                    {formik?.values?.status == SaleCooperationStatusEnum.Done &&
                        <div className="row">
                            <div className="col-12 mb-4">
                                <FormLable show={true}>تاریخ ثبت تسویه</FormLable>
                                <MyDatePicker
                                    defaultValue={formik.values.settledAt}
                                    value={(event) => {
                                        formik.setFieldValue(
                                            "settledAt",
                                            event.baseFormat
                                        )
                                    }}
                                    name="settledAt"
                                    min={moment()}
                                    error={
                                        formik.errors.settledAt !== undefined
                                            ? formik.errors.settledAt
                                            : false
                                    }
                                />
                            </div>
                            <StyledInput
                                formGroupClass="col-12"
                                lable="شماره پیگیری"
                                focus={true}
                                name="trackingNumber"
                                value={formik.values.trackingNumber}
                                error={(formik.errors.trackingNumber && formik.touched.trackingNumber)
                                    ? formik.errors.trackingNumber
                                    : 'false'
                                }
                                onChange={(e) => {
                                    formik.setFieldValue("trackingNumber", e.target.value)
                                }}
                            />
                        </div>
                    }
                    <div className="d-flex justify-content-between">
                        <StyledButton
                            width="35%"
                            btnStyle="grayBtn"
                            type="reset"
                        >
                            بستن
                        </StyledButton>
                        <StyledButton
                            width="35%"
                            disabled={!formik.isValid}
                            btnStyle="greenBtn"
                            type="submit"
                            loading={formik.isSubmitting ? 1 : 0}
                        >
                            ثبت تغییرات
                        </StyledButton>
                    </div>

                </StyledForm>
            )}

        </Formik>
    )
}

export default CreateAgreementModal;