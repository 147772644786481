import React, {useEffect, useState} from "react";
import {Accordion, Badge} from "react-bootstrap";
import {useHistory, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {Button, StyledButton} from "../../../../Component/Kit/Button";
import {PageTitleLayout} from "../../../../Component/Kit/Layout";
import Modal from "../../../../Component/Kit/Modal";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import {CompanyActivity, CompanyType} from "../../../../Utility/Services/company";
import GuestStep from "../../Services/guest-step";
import DateConvert from '../../../../Utility/Services/date-convert';
import GuestLogs from "./guest-logs";
import RejectFormModal from './reject-form-modal';
import {ConvertGuestType} from "../../ConvertGuestType";
import {GuestData} from "../../../../Actions/guest-data";
import {useDispatch} from "react-redux";

function GuestViewPage() {
    let match = useRouteMatch();
    let history = useHistory();
    const dispatch = useDispatch()
    const [nationalCard, setNationalCard] = useState(false);
    const [gazette, setGazette] = useState(false);
    const [guestRejectModal, setGuestRejectModal] = useState(false);
    const [guestData, setGuestData] = useState({});
    const [convertUserTypeModal, ShowConvertUserTypeModal] = new useState(false)

    const guestAccept = () => {
        Swal.fire({
            title: "آیا مطمئن هستید؟",
            text: "آیا از تایید کاربر مورد نظر اطمینان دارید؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Post(Urls.guest(match.params.id))
                    .then((res) => {
                        toast.success("تغییرات  با موفقیت ثبت شد.");
                        history.push("/guests");
                    })
            }
        });
    };

    const guestStepHandler = () => {
        Swal.fire({
            title: "آیا مطمئن هستید؟",
            text: "آیا از تغییر وضعیت به منتظر تایید اطمینان دارید؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Patch(Urls.guestState(match.params.id))
                    .then((res) => {
                        toast.success("تغییرات  با موفقیت ثبت شد.");
                        history.push("/guests/finished");
                    });
            }
        });
    }

    const guestReject = (data) => {
        ApiClient.Patch(Urls.guest(match.params.id), data)
            .then((res) => {
                setGuestRejectModal(false)
                history.push("/guests/finished");
            });
    };

    const onChangeUserType = () => {
        getGuestUser()
    }

    const getGuestUser = () => {
        ApiClient.Get(Urls.guest(match.params.id))
            .then((res) => {
                let data = res.result;
                if (data !== null) {
                    dispatch(GuestData(data));
                    data.birthDate = DateConvert(data.birthDate);
                    data.createdAt = DateConvert(data.createdAt);
                    data.lastModifiedAt = data.lastModifiedAt ? DateConvert(data.lastModifiedAt) : data.createdAt;
                    data.mobile = `0${data.mobile}`;
                    data.stepTitle = GuestStep(data.step);
                    data.companyActivityField = data.companyActivityField ? CompanyActivity(data.companyActivityField) : '-';
                    data.companyType = data.companyType ? CompanyType(data.companyType) : '-';
                    setGuestData(data);
                } else {
                    history.push('/guest');
                }
            })
    }

    useEffect(() => {
        getGuestUser()
    }, [match.params.id, history]);

    return (<>
            <div className="row">
                <div className="col-12 pt-4 pb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <PageTitleLayout>
                            <h5>
                                بررسی مهمان
                            </h5>
                            <span>
                                بررسی اطلاعات <strong className="red-text">
                                    {guestData.firstName && `${guestData.firstName} ${guestData.lastName}`}
                                </strong>
                            </span>

                            {
                                guestData.advertisingCampaignName &&

                            <Badge pill className="font-weight-normal mr-3 "
                                   variant="warning">

                                {guestData.advertisingCampaignName}


                            </Badge>
                            }


                        </PageTitleLayout>
                        <div className="d-flex flex-row-reverse">
                            {guestData.step && (<StyledButton
                                    btnStyle={"greenBtn"}
                                    onClick={() => {
                                        guestData.step === 4 ? history.push(`/guest/${match.params.id}/edit/info`) : guestStepHandler()
                                    }}
                                >
                                    {guestData.step === 4 ? 'ویرایش کاربر مهمان' : 'تغییر به منتظر تایید'}
                                </StyledButton>)}
                            <div className="ml-3">
                                {guestData.step && guestData.step === 4 && (<StyledButton
                                        btnStyle={"greenBtn"}
                                        onClick={() => {
                                            ShowConvertUserTypeModal(true)
                                        }}
                                    >
                                        {guestData.isLegal ? 'تغییر حساب  کاربر به حقیقی' : 'تغییر حساب  کاربر به حقوقی'}

                                    </StyledButton>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-8">
                    <div className="accordian-wrapper ">
                        <Accordion defaultActiveKey="1">
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                <span> اطلاعات حساب</span>
                                <i className="far fa-angle-down"/>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <ul className="params">
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>نام کاربری</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.userName}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>نوع حساب</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.isLegal ? "حقوقی" : "حقیقی"}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>تاریخ ایجاد حساب</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.createdAt}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>آخرین بروزرسانی</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.lastModifiedAt}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>وضعیت ثبت نام</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.stepTitle}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>حوزه فعالیت</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.companyActivityField}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </Accordion.Collapse>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                <span> اطلاعات فردی</span>
                                <i className="far fa-angle-down"/>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <ul className="params">
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>نام و نام خانوادگی</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {`${guestData.firstName} ${guestData.lastName}`}{" "}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>کد ملی</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.nationalId}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>شماره شناسنامه</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.certificateNo}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>نام پدر</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.fatherName}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>تاریخ تولد</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.birthDate}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>جنسیت</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.isMale ? "مرد" : "زن"}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>تابعیت</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.isIranian ? "ایرانی" : "غیر ایرانی"}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </Accordion.Collapse>
                            {guestData.isLegal ? (<>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        <span> اطلاعات شرکت</span>
                                        <i className="far fa-angle-down"/>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <ul className="params">
                                            <li className="params__list">
                                                <div className="params__list__label">
                                                    <span>نام شرکت</span>
                                                </div>
                                                <div className="params__list__value">
                                                    <span className="params__list__label--white">
                                                        {guestData.companyName}
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="params__list">
                                                <div className="params__list__label">
                                                    <span>نوع شرکت</span>
                                                </div>
                                                <div className="params__list__value">
                                                    <span className="params__list__label--white">
                                                        {guestData.companyType}
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="params__list">
                                                <div className="params__list__label">
                                                    <span>کداقتصادی</span>
                                                </div>
                                                <div className="params__list__value">
                                                    <span className="params__list__label--white">
                                                        {guestData.economicCode}
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="params__list">
                                                <div className="params__list__label">
                                                    <span>کد حقوقی</span>
                                                </div>
                                                <div className="params__list__value">
                                                    <span className="params__list__label--white">
                                                        {guestData.legalNationalId}
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="params__list">
                                                <div className="params__list__label">
                                                    <span>کد ثبت</span>
                                                </div>
                                                <div className="params__list__value">
                                                    <span className="params__list__label--white">
                                                        {guestData.registrationNumber}
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </Accordion.Collapse>
                                </>) : null}
                            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                <span> اطلاعات تماس</span>
                                <i className="far fa-angle-down"/>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <ul className="params">
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>شماره تلفن ثابت</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.phoneNumber}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>شماره همراه</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.mobile}
                                            </span>
                                        </div>
                                    </li>
                                    {guestData.additionalMobile ? (<li className="params__list">
                                            <div className="params__list__label">
                                                <span>شماره دوم</span>
                                            </div>
                                            <div className="params__list__value">
                                                <span className="params__list__label--white">
                                                    {guestData.additionalMobile}
                                                </span>
                                            </div>
                                        </li>) : null}
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>استان</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.province}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>شهر</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.city}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>خیابان</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.avenue}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>کوچه</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.alley}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>پلاک</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.plaque}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>آدرس پستی</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.address}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>کدپستی</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.postalCode}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="params__list">
                                        <div className="params__list__label">
                                            <span>ایمیل</span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white">
                                                {guestData.email}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </Accordion.Collapse>
                        </Accordion>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    {guestData.nationalCardUploadedURL ? (<div>
                            <div
                                className="guest-image-wrapper"
                                onClick={() => setNationalCard(true)}
                            >
                                <img
                                    src={guestData.nationalCardUploadedURL}
                                    alt="nationalCard"
                                />
                            </div>
                            <Modal
                                onClose={() => setNationalCard(false)}
                                visible={nationalCard}
                                headerTitle="اطلاعات هویتی"
                            >
                                <div className="guest-image-wrapper">
                                    <img
                                        src={guestData.nationalCardUploadedURL}
                                        alt="nationalCard"
                                    />
                                </div>
                            </Modal>
                        </div>) : (<div
                            className="alert alert-dark text-right d-flex align-items-center"
                            role="alert"
                        >
                            <i className="fa fa-exclamation-circle ml-2"/>
                            <span className="small"> مدارک شناسایی آپلود نشده است.</span>
                        </div>)}
                    {guestData.isLegal ? (guestData.gazetteUploadedURL ? (<div>
                                <div
                                    className="guest-image-wrapper"
                                    onClick={() => setGazette(true)}
                                >
                                    <img src={guestData.gazetteUploadedURL} alt="gazette"/>
                                </div>
                                <Modal
                                    onClose={() => setGazette(false)}
                                    visible={gazette}
                                    headerTitle="روزنامه رسمی"
                                >
                                    <div className="guest-image-wrapper">
                                        <img src={guestData.gazetteUploadedURL} alt="gazette"/>
                                    </div>
                                </Modal>
                            </div>) : (<div
                                className="alert alert-dark text-right d-flex align-items-center"
                                role="alert"
                            >
                                <i className="fa fa-exclamation-circle ml-2"/>
                                <span className="small"> روزنامه رسمی آپلود نشده است.</span>
                            </div>)) : null}
                </div>
            </div>
            {guestData.guestChecks ? (guestData.guestChecks.length ? (<div className="row">
                        <div className="col-12">
                            <GuestLogs data={guestData.guestChecks}/>
                        </div>
                    </div>) : null) : null}
            <div className="row justify-content-end mt-3 ">
                <div className="col-12 ">
                    <div className="d-flex justify-content-end border-top pt-3">
                        <div>
                            <StyledButton
                                btnStyle="redBtn"
                                onClick={() => setGuestRejectModal(true)}
                                disabled={guestData.step !== 4}
                            >
                                <div className="d-flex align-items-center justify-content-between">
                                    <span className="ml-2">عدم تایید</span>
                                    <i className="far fa-times"/>
                                </div>
                            </StyledButton>
                        </div>
                        <div className="mr-2">
                            <StyledButton
                                btnStyle="greenBtn"
                                onClick={() => guestAccept()}
                                disabled={guestData.step !== 4}
                            >
                                <div className="d-flex align-items-center justify-content-between">
                                    <span className="ml-2">تایید</span>
                                    <i className="far fa-check"/>
                                </div>
                            </StyledButton>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                onClose={() => setGuestRejectModal(false)}
                visible={guestRejectModal}
                onCancel={() => setGuestRejectModal(false)}
                headerTitle="عدم تایید کاربر"
            >
                <RejectFormModal
                    rejectEvent={guestReject}
                    onCloseModal={(e) => setGuestRejectModal(e)}
                />
            </Modal>

            <ConvertGuestType
                onSubmit={onChangeUserType}
                data={guestData}
                userId={match.params.id}
                setVisible={ShowConvertUserTypeModal}
                visible={convertUserTypeModal}
                onCancel={() => ShowConvertUserTypeModal(false)}
                onClose={() => ShowConvertUserTypeModal(false)}
            />

        </>

    );
}

export default GuestViewPage;
