import TicketDepartmentList from "../Model/TicketDepartmentModel";

function TicketDepartment(value) {
    let result = TicketDepartmentList.find((item) => item.value ===value);
    return result.name
  }
  export default TicketDepartment;


  export function GeTicketDepartmentKey(name) {
    let result = TicketDepartmentList.find((item) => item.name === name);
    return result.value
  }