import React from "react";

function NewTicketIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width={55}
      height={55}
      {...props}
    >
      <path
        data-name="Icon awesome-envelope-open-text"
        d="M18.906 23.2h17.188a1.718 1.718 0 0 0 1.719-1.719v-1.715a1.718 1.718 0 0 0-1.719-1.719H18.906a1.718 1.718 0 0 0-1.719 1.719v1.719a1.718 1.718 0 0 0 1.719 1.715Zm-1.719 8.6a1.718 1.718 0 0 0 1.719 1.719h17.188a1.718 1.718 0 0 0 1.718-1.719v-1.722a1.718 1.718 0 0 0-1.719-1.719H18.906a1.718 1.718 0 0 0-1.719 1.719ZM27.5 44.809a8.581 8.581 0 0 1-5.034-1.632L0 26.948v22.9A5.156 5.156 0 0 0 5.156 55h44.688A5.156 5.156 0 0 0 55 49.844v-22.9L32.534 43.177a8.589 8.589 0 0 1-5.034 1.632ZM53.024 17.5a197.27 197.27 0 0 0-3.181-2.45v-4.737a5.156 5.156 0 0 0-5.156-5.156h-8.33l-.971-.7C33.58 3.134 29.992-.037 27.5 0c-2.492-.038-6.079 3.133-7.886 4.452l-.971.7h-8.331a5.156 5.156 0 0 0-5.156 5.156v4.742c-1.329 1-2.23 1.7-3.181 2.45A5.156 5.156 0 0 0 0 21.562v1.144l10.312 7.45V10.313h34.375v19.843L55 22.706v-1.144a5.156 5.156 0 0 0-1.976-4.062Z"
        fill="#3f3d56"
      />
    </svg>
  );
}

export default NewTicketIcon;


