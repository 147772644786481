import React from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import ApiClient from "../../../Requester/ApiClient";
import { toast } from "react-toastify";
import { StyledForm, StyledTextArea } from "../../../Component/Kit/Form";
import { StyledButton } from "../../../Component/Kit/Button";
import { settingModal } from "../../../Actions/setting-modal";
import { connect, useDispatch } from "react-redux";
import { SMSContentSchema } from "../../../Utility/Validation/Schema/SMSContentSchema";

function SMSContentModal({ defaultData, onCloseModal, url }) {
    let dispatch = useDispatch();
    return (
        <>
            <Formik
                initialValues={{
                    smsContent: defaultData,
                }}
                validationSchema={SMSContentSchema}
                isInitialValid={false}
                enableReinitialize={true}
                onSubmit={(values) => {
                    Swal.fire({
                        title: `آیا مطمئن هستید؟`,
                        text: "",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonText: "انصراف",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "بله، مطمئن هستم",
                    }).then(result => {
                        if (result.isConfirmed) {
                            let value = JSON.stringify(values.smsContent);
                            ApiClient.Patch(url, value).then(() => {
                                toast.success('تغییرات  با موفقیت ثبت شد.');
                                values.smsContent = defaultData;
                                dispatch(settingModal(true));
                            })
                        }
                        values.smsContent = defaultData;
                    })

                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false)
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}>
                        <StyledTextArea
                            lable="متن پیام"
                            focus={true}
                            name="smsContent"
                            value={formik.values.smsContent}
                            error={(formik.errors.smsContent && formik.touched.smsContent)
                                ? formik.errors.smsContent
                                : 'false'
                            }
                            onChange={(e) =>
                                formik.setFieldValue("smsContent", e.target.value)
                            }
                        />
                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                ویرایش
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    )
}

export default connect()(SMSContentModal);
