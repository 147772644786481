import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import { StyledButton } from '../../../Component/Kit/Button';
import { Loader } from '../../../Component/Shared/Loader';
import Table from '../../../Component/Shared/Table/Table';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import CreditPackageItemModal from '../CreditPackageItemModal';
import Modal from '../../../Component/Kit/Modal';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { connect, useDispatch } from 'react-redux';
import { CreditPackageItemAction } from '../../../Actions/CreditPackageItem';

const CreditPackageList = ({ packageItem }) => {
    const [creditPackageList, setCreditPackageList] = useState({});
    const [creditPackageModal, setCreditPackageModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modalData, setModalData] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();
    const tableConfig = {
        header: {
            description: "نام پکیج",
            netPriceString: "قیمت اصلی پکیج",
            discountedBasePrice: 'قیمت پایه با تخفیف',
            creditCount: "تعداد اعتبار",
            soldCount: "تعداد خریداری شده",
            isActiveString: 'وضعیت',
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
            edit: {
                icon: "fa fa-edit",
            },
            delete: {
                icon: "fa fa-trash",
            }
        },
    };

    function tableActions(items) {
        switch (items.actionName) {
            case 'view':
                setModalData(items.data);
                setCreditPackageModal(true);
                break;
            case 'delete':
                deleteCreditPackage(items.data.id);
                break;
            case 'edit':
                dispatch(CreditPackageItemAction(items.data));
                history.push(`credit-package/${items.data.id}`);
                break;
            default:
                break;
        }
    }

    const deleteCreditPackage = (packageId) => {
        Swal.fire({
            title: '',
            text: `آیا از حذف این بسته شارژ مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.ModifyCreditPackage(packageId))
                    .then(() => {
                        toast.success('کد تخفیف با موفقیت حذف گردید.');
                        getCreditPackageList();
                    })
            }
        })
    }

    const getCreditPackageList = useCallback(() => {
        ApiClient.Get(Urls.GetCreditPackage())
            .then(res => {
                const dataList = res.result;
                dataList.map((m, i) => {
                    m.netPriceString = m.netPrice.toLocaleString();
                    m.isActiveString = m.isActive
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    return m;
                });
                setCreditPackageList(dataList)
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        getCreditPackageList()
    }, [getCreditPackageList])


    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-end">
                <div className="d-flex align-items-center justify-content-start w-100">
                    <StyledButton
                        btnStyle="greenBtn mx-1"
                        onClick={() => history.push('/credit-package/add')}
                    >
                        ایجاد بسته شارژ شگفت انگیز
                    </StyledButton>
                </div>
            </HeaderContent>
            <MainContent>
                <div className="col-12 col-lg-12">
                    <PageTitleLayout>
                        <h5>لیست بسته شارژ شگفت انگیز </h5>
                        <span>در این بخش لیست همه بسته شارژهای شگفت انگیز قابل مشاهده است.</span>
                    </PageTitleLayout>
                    <div>
                        {loading === true
                            ?
                            <Loader />
                            :
                            <Table
                                data={creditPackageList}
                                config={tableConfig}
                                actionDispatched={tableActions}
                            />
                        }
                    </div>
                </div>
            </MainContent>

            <Modal
                onClose={() => setCreditPackageModal(false)}
                onCancel={() => setCreditPackageModal(false)}
                visible={creditPackageModal}
                headerTitle="مشاهده جزئیات بسته شگفت‌انگیز"
            >
                <CreditPackageItemModal
                    data={modalData}
                    onCloseModal={(e) => setCreditPackageModal(e)} />
            </Modal>
        </WrapperLayout>
    )
}


export default connect()(CreditPackageList)