import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'


import { MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout'
import Table from '../../../Component/Shared/Table/Table'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import { ConvertQueryString } from '../../../Utility/Services/convert-query-string'
import DateConvert from '../../../Utility/Services/date-convert'

export const SepidarLogs = () => {
    const [logs, setLogs] = useState({});
    const location = useLocation();

    const tableConfig = {
        header: {
            customerName: "نام مشتری",
            invoiceNumber: "شماره فاکتور",
            description: "توضیحات",
            createdAt: "تاریخ و زمان ایجاد",
        },
        mode: ["filter", "search"],
        filters: ["datePickerFilter"],
    }

    useEffect(() => {
        ApiClient.Get(Urls.ErrorLogSepidar(), ConvertQueryString(location.search))
            .then(res => {
                let responseData = res.result;
                responseData.items.map((item, i) => {
                    item.createdAt = DateConvert(item.createdAt);
                });
                setLogs(responseData);
            })
    }, [location.search]);


    const tableActions = (items) => {
    }

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <MainContent>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <PageTitleLayout>
                                <h5>لیست عملیات های سپیدار</h5>
                                <span>در این بخش لیست عملیات های سپیدار قابل مشاهده است.</span>
                            </PageTitleLayout>
                            <Table
                                data={logs.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: logs.pageNumber,
                                    pageSize: logs.pageSize,
                                    totalItemsCount: logs.totalItemsCount,
                                    totalPagesCount: logs.totalPagesCount,
                                }}
                            ></Table>
                        </div>
                    </div>
                </MainContent>
            </div>
        </WrapperLayout>
    )
}
