import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { PageTitleLayout } from "../../../Component/Kit/Layout";
import { StyledButton } from "../../../Component/Kit/Button";
import DateConvert from "../../../Utility/Services/date-convert";
import Modal from "../../../Component/Kit/Modal";
import AddCustomNotification from "./add-custom-notification";
import NotificationView from "./notification-view";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import { ConvertQueryString } from "../../../Utility/Services/convert-query-string";

function UserFastSendTemplates() {
    let match = useRouteMatch();
    let location = useLocation();
    const [notificationList, setnotificationList] = useState({});
    const [modalDetail, setmodalDetail] = useState({});
    const [addNotifModal, setaddNotifModal] = useState(false);
    const [notifDetailModal, setnotifDetailModal] = useState(false);
    const tableConfig = {
        header: {
            title: "عنوان",
            createdAt: "تاریخ ایجاد",
            startDate: "تاریخ شروع",
            endDate: "تاریخ پایان",
            isReadTitle: "وضعیت",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
            delete: {
                icon: "fa fa-trash",
            },
        },
        mode: ["filter", "search"],
        filters: ["datePickerFilter", "isRead"],
    };

    const tableActions = (item) => {
        switch (item.actionName) {
            case "view":
                // let rowDetails = notificationList.items.find(
                //     (m) => m.id === item.userId
                // );
                setmodalDetail(item.data);
                setnotifDetailModal(true);
                break;
            case "delete":
                deleteNotification(item.data.id)
                break;

            default:
                break;
        }
    };
    const deleteNotification = (notificationId) => {
        Swal.fire({
            title: `آیا مطمئن هستید؟`,
            text: "ممکن است کاربر دیده باشد. احتیاط کنید!!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.customNotification(match.params.id, notificationId)).then(() => {
                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    getNotification();
                })
            }
        })
    }
    const notificationHandler = (event) => {
        if (event) {
            setaddNotifModal(false)
            getNotification();
        }
    };
    const getNotification = useCallback(() => {
        ApiClient.Get(Urls.userCustomNotification(match.params.id), ConvertQueryString(location.search))
            .then((res) => {
                let data = res.result;
                data.items.map((m) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.isReadTitle = m.isRead ? "خوانده شده" : "خوانده نشده";
                    m.startDate = m.startDate ? DateConvert(m.startDate) : "----";
                    m.endDate = m.endDate ? DateConvert(m.endDate) : "----";
                    return m;
                });
                setnotificationList(data);
            });
    }, [match.params.id, location.search])

    useEffect(() => {
        getNotification();
    }, [match.params.id, location.search, getNotification]);
    return (
        <>
            <PageTitleLayout>
                <h5>اعلان‌های ادمینی</h5>
                <span>
                    شما می‌توانید اعلان‌های مخصوص این کاربر را ایجاد نمایید.
                </span>
            </PageTitleLayout>
            <div className="pb-3 text-left">
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setaddNotifModal(true)}
                >
                    ایجاد اعلان جدید
                </StyledButton>
            </div>
            <Table
                data={notificationList.items}
                config={tableConfig}
                pageConfig={{
                    pageNumber: notificationList.pageNumber,
                    pageSize: notificationList.pageSize,
                    totalItemsCount: notificationList.totalItemsCount,
                    totalPagesCount: notificationList.totalPagesCount,
                }}
                actionDispatched={tableActions}
            />

            <Modal
                onClose={() => setnotifDetailModal(false)}
                onCancel={() => setnotifDetailModal(false)}
                visible={notifDetailModal}
                headerTitle="نمایش اعلان"
            >
                <NotificationView data={modalDetail} onCloseModal={(e) => setnotifDetailModal(e)} />
            </Modal>

            <Modal
                onClose={() => setaddNotifModal(false)}
                onCancel={() => setaddNotifModal(false)}
                visible={addNotifModal}
                headerTitle="اضافه کردن اعلان"
            >
                <AddCustomNotification dataChange={notificationHandler} onCloseModal={(e) => setaddNotifModal(e)} />
            </Modal>
        </>
    );
}

export default UserFastSendTemplates;
