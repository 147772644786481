import React, { useEffect, useState } from 'react'
import Navigation from './Navigation';
import Logo from '../../Component/Icon/Logo';
import LogoMinimize from "../../Component/Icon/logo-minimize";
import { connect } from "react-redux";
import { RoleCanActivate } from "../../Utility/Services/roleCanActivate";

const SideBar = (props) => {
    const { adminProfile } = props;
    const { screenStatus, setScreenStatus } = props;
    const [menuItems, setMenuItems] = useState([]);
    const items = [
        {
            title: 'دشبورد',
            icon: 'home',
            link: '/dashboard',
            subMenus: null,
        },
        {
            title: 'مدیریت مهمان‌ها',
            icon: 'user',
            link: '/guests/finished',
            subMenus: null,
            accessList: ['supporter', 'sales', 'supervisor']
        },
        {
            title: 'مدیریت کاربران',
            icon: 'user-cog',
            link: '/users/list',
            subMenus: null

        },
        {
            title: ' مدیران',
            icon: 'user-lock',
            link: '/admins',
            subMenus: [
                { link: '/admins', title: 'لیست مدیران', icon: null },
                { link: '/admins/logs', title: 'سوابق فعالیت‌ها', icon: null },
            ],
            accessList: ['supervisor']
        },
        {
            title: 'صف ارسال پیامک',
            icon: 'pause-circle',
            link: '/pending-sms/list',
            subMenus: null,
            accessList: ['supporter', 'supervisor']
        },
        {
            title: 'قالب ارسال سریع',
            icon: 'share-all',
            link: '/fast-send/pending',
            subMenus: null,
            accessList: ['supporter', 'supervisor'],
        },
        {
            title: 'ارسال از دکل',
            icon: 'broadcast-tower',
            link: '/send-tower',
            subMenus: null,
        },
        {
            title: 'خطوط',
            icon: 'phone',
            link: '/line/',
            accessList: ['supporter', 'supervisor'],
            subMenus: [
                { link: '/line/system', title: 'خطوط سیستمی', icon: null },
                { link: '/line/user-service-line', title: 'خطوط خدماتی', icon: null },
                { link: '/line/public', title: 'خطوط ویژه', icon: null },
                { link: '/line/access-public', title: 'تخصیص خطوط ویژه', icon: null },
                { link: '/line/plans', title: 'خطوط پلن ها', icon: null },
                { link: '/line/user-lines', title: 'خطوط کاربران', icon: null },
                { link: '/line/user-lines/pending', title: 'خطوط منتظر تایید', icon: null }
            ]
        },
        {
            title: 'تیکت',
            icon: 'envelope-open-text',
            link: '/ticket/',
            subMenus: [
                { link: '/ticket/list', title: 'لیست تیکت‌ها', icon: null },
                { link: '/ticket/template', title: 'لیست قالب‌ها', icon: null, accessList: ['supporter', 'sales', 'supervisor'] },
                { link: '/ticket/auto-assignment', title: 'تنظیمات تخصیص خودکار', icon: null , accessList: ['supervisor']},
            ]
        },
        {
            title: 'مالی',
            icon: 'money-bill',
            link: '/financial/',
            subMenus: [
                { link: '/financial/online-payment', title: 'لیست پرداخت ها', icon: null, },
                { link: '/financial/manual-payment', title: 'پرداخت‌های دستی', icon: null, accessList: ['supporter', 'supervisor'], },
                { link: '/financial/invoices/pending', title: 'لیست فاکتور‌ها', icon: null },
                { link: '/financial/sepidar-logs', title: 'عملیات های سپیدار', icon: null, accessList: ['accountant'] },
                { link: '/financial/invoice/lend?isPaid=false', title: 'لیست پرداخت‌های قرضی', icon: null },
            ]
        },
        {
            title: 'اعلان ها',
            icon: 'bell',
            link: '/notifications',
            subMenus: null,
            accessList: ['supporter', 'sales', 'supervisor'],

        },
        {
            title: 'لیست کاربران سفید',
            icon: 'shield-check',
            link: '/white-users',
            subMenus: null,
            accessList: ['supervisor'],
        },
        {
            title: 'لیست سیاه',
            icon: 'file-word',
            link: '/blacklist',
            subMenus: null,
            accessList: ['supervisor'],
        },
        {
            title: 'کدتخفیف',
            icon: 'percent',
            link: '/discount-code',
            subMenus: null,
            accessList: ['supervisor'],
        },
        {
            title: 'کلمات ممنوعه',
            icon: 'ban',
            link: '/forbidden-words',
            subMenus: null,
            accessList: ['supervisor'],
        },
        {
            title: 'همکاری در فروش',
            icon: 'handshake',
            link: '/sale-cooperation',
            subMenus: [
                { link: '/sale-cooperation/transaction', title: 'تراکنش ها ', icon: null, },
                { link: '/sale-cooperation/settlement', title: 'درخواست تسویه ', icon: null, },
            ],
        },
        {
            title: 'کمپین تبلیغاتی',
            icon: 'ad',
            link: '/campaigns',
            subMenus: [
                { link: '/campaigns', title: 'کمپین ها', icon: null, },
                { link: '/campaigns/members', title: 'اعضای کمپین', icon: null, },
            ],
        },
        {
            title: 'مسدود سازی',
            icon: 'do-not-enter',
            link: '/suspended/blocked-ip',
            subMenus: null,
            accessList: ['supporter', 'supervisor'],
        },
        {
            title: 'پلن ها',
            icon: 'bullseye-arrow',
            link: '/plan',
            subMenus: null,
            accessList: ['supervisor'],
        },
        {
            title: 'ارائه‌دهندگان',
            icon: 'router',
            link: '/providers',
            subMenus: [
                { link: '/providers/credit', title: 'اعتبار', icon: null },
                { link: '/providers/state', title: 'وضعیت', icon: null },
                { link: '/providers/notification', title: 'تنظیمات اعلانات', icon: null }
            ],
        },
        {
            title: 'بسته شارژ تخفیف دار',
            icon: 'box-full',
            link: '/credit-package',
            subMenus: null,
        },
        {
            title: 'تعرفه',
            icon: 'weight-hanging',
            link: '/base-price',
            subMenus: [
                { link: '/base-price/dedicated', title: 'تعرفه اختصاصی', icon: null, },
            ],
            accessList: ['supervisor']
        },
        {
            title: 'بازخورد کاربران',
            icon: 'comment-dots',
            link: '/feedback',
            subMenus: null,
        },
        {
            title: 'تنظیمات',
            icon: 'cog',
            link: '/settings/limitation',
            subMenus: null,
            accessList: ['supporter', 'supervisor'],
        },

    ];

    const changeSidebarState = (sidebarMode, deviceMode) => {

        if (deviceMode === 'mobile') {
            if (sidebarMode === 'hidden') {
                setScreenStatus({ ...screenStatus, sidebarMode: 'full' })
                // this.store.dispatch(AppActions.modifySidebarMode({ mode: 'full' }));
            } else if (sidebarMode === 'full') {
                setScreenStatus({ ...screenStatus, sidebarMode: 'hidden' })
                // this.store.dispatch(AppActions.modifySidebarMode({ mode: 'hidden' }));
            }
        }
    }

    useEffect(() => {
        if (adminProfile) {
            items.map(m => {

                m['isShow'] = m.accessList ? RoleCanActivate(m.accessList, adminProfile.roleId) : true;

                if (m.subMenus && m.subMenus.length > 0) {
                    m.subMenus.map(subMenuItem => {
                        subMenuItem['isShow'] = subMenuItem.accessList ? RoleCanActivate(subMenuItem.accessList, adminProfile.roleId) : true;
                        return false
                    })
                }
                return false
            })
            setMenuItems([...items]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenStatus, adminProfile]);

    return (
        <nav className={`sidebar scrollbar-style 
            ${screenStatus.sidebarMode === 'slim' ? 'slim-sidebar' : '' ||
                screenStatus.sidebarMode === 'hidden' ? 'hidden-sidebar' : '' ||
                    screenStatus.sidebarMode === 'default' ? 'desktop-sidebar' : '' ||
                        screenStatus.sidebarMode === 'full' ? 'full-sidebar' : ''} `}>

            <div className="sidebar-section">
                <div className="logo-box text-center">
                    {screenStatus.sidebarMode !== 'slim' ?
                        <Logo className="img-responsive center-block pt-3 pb-2"
                              style={{width: 176, height: 'auto'}}
                              fill="#ffffff" shapefill="#1e2c47" /> :
                        <LogoMinimize className="img-responsive center-block" />}
                    <button className={`toggle-menu 
                        ${(screenStatus.deviceMode === 'tablet' ||
                            screenStatus.sidebarMode === 'slim' ||
                            screenStatus.deviceMode === 'desktop') ? 'd-none' : ''} `}
                        onClick={(e) => {
                            e.preventDefault()
                            changeSidebarState(screenStatus.sidebarMode, screenStatus.deviceMode)
                        }}>
                        <i className="fa fa-times-circle"></i>
                    </button>
                </div>
            </div>
            <div className="menu">
                {
                    menuItems &&
                    menuItems.map(menuItem =>
                        <Navigation screenStatus={screenStatus} setScreenStatus={setScreenStatus}
                            menuItem={menuItem} key={`menuItem_${menuItem.title}`} />
                    )
                }
            </div>
        </nav>
    )
}

function mapStateToProps(state) {
    const { bootstrapper } = state
    return { adminProfile: bootstrapper.data }
}

export default connect(mapStateToProps)(SideBar);
