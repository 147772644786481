import React, {useEffect, useState} from 'react';
import {Badge} from "react-bootstrap";
import {StyledButton} from "../../../../Component/Kit/Button";
import PurchaseRestrictionsModal from "./PurchaseRestrictionsModal";

function PurchaseRestrictions({data}) {
    const [isModal, setIsModal] = useState(false);
    const [value, setValue] = useState({})
    useEffect(() => {
        if (data) {
            const dataObj = JSON.parse(data);
            dataObj.FromTime = `${dataObj.FromTime.slice(0, 2)}${dataObj.FromTime.slice(3, 5)}${dataObj.FromTime.slice(6)}`;
            dataObj.ToTime = `${dataObj.ToTime.slice(0, 2)}${dataObj.ToTime.slice(3, 5)}${dataObj.ToTime.slice(6)}`;
            setValue(dataObj)
        }
    }, [data])
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">محدودیت در زمان و تعداد پرداخت : </span>
                    <Badge className="font-weight-normal" variant="primary">{value.AllowedPaymentCount}</Badge>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>
            <PurchaseRestrictionsModal
                data={value}
                visible={isModal}
                setVisible={(e) => setIsModal(e)}
                onCancel={() => setIsModal(false)}
                onClose={() => setIsModal(false)}/>
        </>
    )

}

export default PurchaseRestrictions;