import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {MainContent, PageTitleLayout} from "../../../Component/Kit/Layout";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import DateConvert from "../../../Utility/Services/date-convert";
import {ConvertQueryString} from "../../../Utility/Services/convert-query-string";
import {useLocation} from "react-router-dom";
import Modal from '../../../Component/Kit/Modal';
import CreateAgreementModal from "./Create-agreement";
import SaleCooperationSettlementModal from "../../../Utility/Model/SaleCooperationSettlement.Modal";
import {toast} from "react-toastify";

function SaleCooperationSettlement() {

    const SaleCooperationStatusEnum = {
        Pending: 1,
        InProgress: 2,
        Done: 3,
        Rejected: 4
    }
    const [settlement, setSettlement] = useState({});
    const [agreementModal, setAgreementModal] = useState(false);
    const [createAgreementModal, setCreateAgreementModal] = useState(false);
    const [selectedData , setSelectedData] = useState({});

    const location = useLocation();
    const getSaleCooperationSettlement = () => {
        ApiClient.Get(Urls.getSaleCooperationSettlement(), ConvertQueryString(location.search))
            .then(res => {
                if (res){
                    res.result.items.map((item) => {
                        item.createdAt = DateConvert(item.createdAt);
                        item.amount =  item.amount?.toLocaleString('fa-IR')
                        item.statusName = SaleCooperationSettlementModal?.find(a => a.value === item.status)?.name
                    })
                    setSettlement(res.result)
                }
            })
    }
    useEffect(() => {
        getSaleCooperationSettlement()
    }, [location.search])

    const tableConfig = {
        header: {
            amount: "مبلغ",
            createdAt: "تاریخ",
            shebaCode: 'شماره شبا',
            userName: 'کاربر',
            statusName: "وضعیت",
        },
        actions: {
            edit: {
                icon: "fa fa-edit",
                condition: (data) => {
                    if (data.status !== SaleCooperationStatusEnum.Done && data.status !== SaleCooperationStatusEnum.Rejected) return true;
                    return false;
                },
            },
            view: {
                icon: "fa fa-eye",
                condition: (data) => {
                    if (data.agreement) return true;
                    return false;
                },
            },
        },
        mode: ["filter"],
        filters: ["datePickerFilter"],
    };

    const tableActions = (item) => {
        setSelectedData(item.data)
        switch (item.actionName) {
            case 'view':
                setAgreementModal(true)
                break;
            case 'edit':
                setCreateAgreementModal(true)
                break;
            default:
                break;
        }
    };
    const onEditAgreement = () => {
        setCreateAgreementModal(false);
        toast.success('عملیات با موفقیت انجام شد');
        getSaleCooperationSettlement()
    }

    return (
        <>
            <MainContent>
                <PageTitleLayout>
                    <h5> درخواست های تسویه بخش همکاری در فروش</h5>
                    <span>در اینجا می‌توانید همه‌ی درخواست های تسویه بخش همکاری در فروش را مشاهده نمایید.</span>
                </PageTitleLayout>
                <Table
                    data={settlement.items}
                    config={tableConfig}
                    pageConfig={{
                        pageNumber: settlement.pageNumber,
                        pageSize: settlement.pageSize,
                        totalItemsCount: settlement.totalItemsCount,
                        totalPagesCount: settlement.totalPagesCount,
                    }}
                    actionDispatched={tableActions}
                />
            </MainContent>

            <Modal
                closable={true}
                onClose={() => setCreateAgreementModal(false)}
                onCancel={() => setCreateAgreementModal(false)}
                visible={createAgreementModal}
                headerTitle="ویرایش توافقنامه"
            >
                {selectedData.status &&
                    <CreateAgreementModal onCloseModal={() => setCreateAgreementModal(false)} onSubmit={onEditAgreement} data={selectedData}></CreateAgreementModal>
                }
            </Modal>

            <Modal
                closable={true}
                onClose={() => setAgreementModal(false)}
                onCancel={() => setAgreementModal(false)}
                visible={agreementModal}
                headerTitle="نمایش توافقنامه"
            >
                <div className="m-4">
                    <span className="px-2">شماره پیگیری :</span>
                    <span className="px-2">{selectedData?.agreement?.trackingNumber}</span>
                </div>
                <div className="m-4">
                    <span className="px-2">تاریخ:</span>
                    <span className="px-2">{DateConvert(selectedData?.agreement?.settledAt)}</span>
                </div>
            </Modal>


        </>
    )
}

export default SaleCooperationSettlement;