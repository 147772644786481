import React, { useEffect, useState } from 'react';
import { NavLink, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { HeaderContent, MainContent } from '../../../Component/Kit/Layout';
import GuestCommentsList from '../GuestComments/GuestCommentsList';
import IncommingCalls from '../IncommingCalls';
import BackToPage from './../../../Component/Kit/back';
import GuestViewPage from './GuestView/index';


const GuestInformation = ({ match }) => {
    const history = useHistory();
    const location = useLocation();
    const [prev, setPrev] = useState('');

    useEffect(() => {
        if (location.pathname === `${match.url}`) {
            history.push(`${match.url}/info`)
        }

        if (location.state && location.state.from === '/guests/list') {
            setPrev(`${location.state.from}${location.state.search}`);
        } else if (location.state && location.state.from === '/guests/finished') {
            setPrev(location.state.from);
        }
        if (!location.state) {
            setPrev('/guests/finished');
        }
    }, [location.state])

    return (
        <>
            <HeaderContent className="justify-content-end">
                <BackToPage toPage={prev} />
            </HeaderContent>

            <MainContent>
                <ul className="user-tab-menu">
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/info`}>
                            بررسی مهمان
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/comment`}>
                            کامنت های مهمان
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/incomming-calls`}>
                            تماس ها
                        </NavLink>
                    </li>
                </ul>

                <div className="px-3">
                    <Switch>
                        <Route exact path={`${match.path}/info`}>
                            <GuestViewPage />
                        </Route>
                        <Route path={`${match.path}/comment`}>
                            <GuestCommentsList />
                        </Route>
                        <Route path={`${match.path}/incomming-calls`}>
                            <IncommingCalls />
                        </Route>
                    </Switch>
                </div>
            </MainContent>
        </>
    )
}

export default GuestInformation
