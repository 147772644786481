import React, {useState} from "react";
import {StyledButton} from "../../../../Component/Kit/Button";
import {Badge} from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";
import NumberLimitationModal from "../../modals/limitation-modal";
import Urls from "../../../../Requester/Urls";

function AllowTotalSmsSendInADay({data}) {
    const [isModal, setIsModal] = useState(false);
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">محدودیت در تعداد ارسال در روز : </span>
                    <Badge className="font-weight-normal" variant="primary">{data}</Badge>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>


            <Modal centered show={isModal} onHide={() => {
                setIsModal(false)
            }}>
                <Modal.Header>
                    <Modal.Title>محدودیت در تعداد ارسال در روز</Modal.Title>
                    <i onClick={() => {
                        setIsModal(false)
                    }} className="close-btn fa fa-xmark"/>
                </Modal.Header>
                <Modal.Body>
                    <NumberLimitationModal defaultData={data} onCloseModal={() => setIsModal(false)} url={Urls.totalSmsInDay()} />
                </Modal.Body>

            </Modal>

        </>
    )
}

export default AllowTotalSmsSendInADay
