import React, {useCallback, useEffect, useState} from "react";
import * as Yup from "yup";
import {Formik} from 'formik';
import {StyledForm, StyledInput, StyledRadioBtn, StyledSelect, StyledTextArea} from "../../../../Component/Kit/Form";
import {StyledButton} from "../../../../Component/Kit/Button";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import {toast} from "react-toastify";
import {useRouteMatch} from "react-router-dom";
import Modal from "../../../../Component/Kit/Modal";

const specialRegex = /^[1-9]\d*(\.\d{1})?$/;
const baseSchema = {
    creditCount: Yup.string()
        .required("این فیلد الزامی است.")
        .matches(specialRegex, "مقدار وارد شده نا معتبر است. (تا یک رقم اعشار و کاراکتر اول غیر صفر باشد)")
        .test(
            'creditCount',
            'مقدار وارد شده بیش از حد مجاز است. (حد مجاز: 2,000,000)',
            value => {
                const userInput = parseFloat(value);
                return (userInput <= 2000000)
            }
        )

        .label("creditCount"),

    description: Yup.string()
        .label("description")
        .required("این فیلد الزامی است."),

}
export const CreditTransferModal = ({visible, setVisible, onCancel, onClose, onsubmit, dedicatedPrice}) => {


    let match = useRouteMatch();
    const [currentCredit, setCurrentCredit] = useState(0)
    const [submitLoading, setSubmitLoading] = useState(false);
    const [formType, setFormType] = useState(true)
    const [basePrice, setBasePrice] = useState([]);
    const [schema, setSchema] = useState(() => {
        return dedicatedPrice ? {
            ...baseSchema
        } : {
            ...baseSchema,
            basePrice: Yup.string()
                .when("typeTransfer", {
                    is: true,
                    then: Yup.string()
                        .label("basePrice")
                        .required("این فیلد الزامی است.")
                }),
        }
    })


    const getData = useCallback(async () => {
        try {
            let userCredit = await ApiClient.Get(Urls.GetUserCreditCount(match.params.id));
            setCurrentCredit(userCredit.result)

            let creditTier = await ApiClient.Get(Urls.GetCreditTier());
            let temp = [];
            creditTier.result.map((item) => {
                temp = [...temp, {name: item.basePrice, value: item.id}];
                return false
            })
            setBasePrice(temp)

        } catch (err) {
        }
    }, [match.params.id])

    useEffect(() => {
        getData()
    }, [getData]);

    return (
        <>
            <Modal
                visible={visible}
                onClose={onClose}
                onCancel={onCancel}
                closable={true}
                headerTitle="افزایش اعتبار حساب"
            >

                <Formik
                    initialValues={{
                        creditCount: '',
                        basePrice: '',
                        description: '',
                        typeTransfer: formType
                    }}
                    validationSchema={
                        Yup.object().shape(schema)
                    }
                    onSubmit={(values, actions) => {
                        setSubmitLoading(true)
                        let creditCount = Math.abs(values.creditCount);
                        if (!values.typeTransfer) {
                            creditCount = -creditCount;
                        }
                        let body = {
                            creditCount,
                            description: values.description
                        };

                        if (values.typeTransfer) {
                            const basePriceValue = dedicatedPrice ? null : basePrice.find((item) => item.value === +values.basePrice).name;
                            body = {...body, basePrice: basePriceValue}
                        }
                        ApiClient.Post(Urls.creditTransfer(match.params.id), JSON.stringify(body))
                            .then((res) => {
                                toast.success(values.typeTransfer ? 'افزایش اعتبار با موفقیت انجام شد!' : 'کاهش اعتبار با موفقیت انجام شد!')
                                onsubmit();
                            })
                            .catch(() => actions.resetForm())
                            .finally(() => {
                                setSubmitLoading(false)
                                setVisible(false);
                            })
                    }}
                    onReset={() => {
                        setVisible(false);
                        // setFormType(true)
                    }}
                >
                    {(formik) => (
                        <StyledForm
                            onSubmit={formik.handleSubmit}
                            onReset={formik.handleReset}
                            onBlur={formik.handleBlur}
                        >
                            <div className="row">
                                <div className="col-12">
                                    <StyledInput
                                        lable="تعداد کریدیت"
                                        placeholder=""
                                        focus={true}
                                        disabled={!formik.values.typeTransfer && currentCredit === 0}
                                        name="creditCount"
                                        value={formik.values.creditCount}
                                        error={(formik.errors.creditCount && formik.touched.creditCount)
                                            ? formik.errors.creditCount
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("creditCount", e.target.value)}
                                    />
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                        <span className="current-charge"
                                              onClick={() => {
                                                  if (currentCredit !== 0) {
                                                      formik.setFieldValue("creditCount", currentCredit.toFixed(1))
                                                  }
                                              }
                                              }>اعتبار فعلی: {currentCredit}</span>

                                    {
                                        dedicatedPrice &&
                                        <span className=" mx-3 current-charge cursor-normal "
                                        >تعرفه اختصاصی فعلی: {dedicatedPrice}</span>
                                    }
                                </div>

                            </div>
                            {formik.values.typeTransfer && dedicatedPrice < 1 &&


                                <div className="row">
                                    <div className="col-12">
                                        <StyledSelect
                                            lable="تعرفه"
                                            focus={true}
                                            name="basePrice"
                                            hasDefaultOption
                                            value={formik.values.basePrice !== undefined
                                                ? formik.values.basePrice
                                                : formik.initialValues.basePrice}
                                            optionItems={basePrice}
                                            error={(formik.errors.basePrice && formik.touched.basePrice)
                                                ? formik.errors.basePrice
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("basePrice", e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="row ">
                                <div className="col-6 my-2">
                                    <StyledRadioBtn id="credit-increase"
                                                    name='typeTransfer'
                                                    value={true}
                                                    label='افزایش اعتبار'
                                                    checked={formik.values.typeTransfer}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("typeTransfer", (e.target.value === 'true'))
                                                        setFormType((e.target.value === 'true'))
                                                    }}
                                    />
                                </div>
                                <div className="col-6 my-2">
                                    <StyledRadioBtn id='credit-decrease'
                                                    name='typeTransfer'
                                                    value={false}
                                                    label='کاهش اعتبار'
                                                    disabled={currentCredit === 0}
                                                    checked={!formik.values.typeTransfer}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("typeTransfer", (e.target.value === 'true'))
                                                        setFormType((e.target.value === 'true'))
                                                    }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <StyledTextArea
                                        lable="توضیحات"
                                        focus={true}
                                        name="description"
                                        value={formik.values.description}
                                        error={(formik.errors.description && formik.touched.description)
                                            ? formik.errors.description
                                            : 'false'
                                        }
                                        onChange={(e) => {
                                            const str = e.target.value.trim() !== '' ? e.target.value : '';
                                            formik.setFieldValue("description", str)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                <StyledButton
                                    btnStyle="greenBtn ml-3"
                                    width="38%"
                                    disabled={!formik.isValid || !formik.dirty || submitLoading}
                                    loading={submitLoading ? 1 : 0}
                                    type="submit"
                                >
                                    ثبت اعتبار
                                </StyledButton>

                                <StyledButton
                                    width="35%"
                                    btnStyle="grayBtn"
                                    type="reset"
                                >
                                    انصراف
                                </StyledButton>
                            </div>
                        </StyledForm>

                    )}
                </Formik>
            </Modal>
        </>
    );
}
