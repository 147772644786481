import React, {useEffect, useState} from "react";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {Loader} from "../../../Component/Shared/Loader";
import Bank from "./bank";
import {StyledButton} from "../../../Component/Kit/Button";
import {toast} from "react-toastify";

function BanksSetting() {
    const [loading, setLoading] = useState(true);
    const [bankData, setBankData] = useState([]);
    const [requestData, setRequestData] = useState([]);


    const deepCopy = (data) => {
        return JSON.parse(JSON.stringify(data));
    }

    const removeTitleKey = (list) => {
        return list.map((item) => {
            const {title, ...rest} = item;
            return rest;
        });
    };

    const updateBank = (event) => {
        setRequestData(requestData.map(obj => (obj.id === event.id ? event : obj)));
    }

    const getData = () => {
        const convertModel = [
            {name: null, value: 'null'},
            {name: false, value: 'false'},
            {name: true, value: 'true'},
        ];

        ApiClient.Get(Urls.getbankSetting()).then(res => {
            const baseArray = deepCopy(res.result);
            const secondaryArray = deepCopy(res.result);

            baseArray.map((item) => {
                item.isOfficial = convertModel.find(f => f.name === item.isOfficial)?.value
                item.isDefault = convertModel.find(f => f.name === item.isDefault)?.value
                item.isActive = convertModel.find(f => f.name === item.isActive)?.value
            })


            setBankData(baseArray);
            setRequestData(removeTitleKey(secondaryArray));
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    const onSubmit = () => {
        setLoading(true);
        ApiClient.Put(Urls.getbankSetting(), JSON.stringify(requestData)).then(res => {
            toast.success('تغییرات  با موفقیت ثبت شد.');
            getData();
        }).catch(() => {
            toast.error('خطایی رخ داده است.');
            setLoading(false);
        })

    }


    useEffect(() => {
        getData();
    }, [])

    return (
        <div>
            {loading === true
                ?
                <Loader/>
                :
                <>
                    {
                        bankData.map(bank => (
                            <Bank key={bank.id} defaultData={bank} updateBankAction={updateBank}/>
                        ))
                    }

                    <div className="d-flex justify-content-end">

                        <StyledButton
                            width="20%"
                            // disabled={!formik.isValid || !formik.dirty}
                            btnStyle="greenBtn"
                            onClick={e => {
                                onSubmit()
                            }}
                        >
                            ذخیره اطلاعات
                        </StyledButton>
                    </div>
                </>
            }
        </div>

    )
}

export default BanksSetting
