import SendSmsLimitationType from "../Models/SendSmsLimitationType";
function SendSmsLimitation(value , key) {
    let result = [];
    SendSmsLimitationType.forEach((item,i) => {
        result.push(value.includes(item.value));
    });
    return result
}

export default SendSmsLimitation;

