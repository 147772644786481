import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../Component/Kit/Button';
import { StyledForm, StyledInput } from '../../../../Component/Kit/Form';
import Modal from '../../../../Component/Kit/Modal';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import { ModifyDedicatedBasePriceSchema } from '../../../../Utility/Validation/Schema/ModifyDedicatedBasePriceSchema';

const ModifyDedicatedBasePriceModal = (props) => {
    const { data, visible, setVisible, onCancel, onClose, onSubmit } = props;
    const editMode = data !== null;

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            closable={true}
            headerTitle={`${editMode ? 'ویرایش' : 'ایجاد'} قیمت پایه اختصاصی`}
        >
            <div className="col-12">
                <div className="row">
                    <Formik
                        initialValues={{
                            dedicatedBasePrice: (data && data.basePrice) || '',
                        }}
                        enableReinitialize={true}
                        validationSchema={ModifyDedicatedBasePriceSchema}
                        onSubmit={(values, actions) => {
                            if (editMode) {
                                ApiClient.Put(Urls.DedicatedBasePrice(data.id), values.dedicatedBasePrice)
                                    .then(res => {
                                        toast.success('قیمت پایه اختصاصی با موفقیت ویرایش شد.')
                                        setVisible(false);
                                        actions.resetForm();
                                        onSubmit();
                                    })
                                    .catch(() => actions.resetForm())
                            } else {
                                ApiClient.Post(Urls.DedicatedBasePrice(), values.dedicatedBasePrice)
                                    .then(res => {
                                        toast.success('قیمت پایه اختصاصی با موفقیت افزوده شد.')
                                        setVisible(false);
                                        actions.resetForm();
                                        onSubmit();
                                    })
                                    .catch(() => actions.resetForm())
                            }
                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                                onBlur={formik.handleBlur}
                            >
                                <div className="row">
                                    <StyledInput
                                        formGroupClass="col-12"
                                        lable=" قیمت پایه اختصاصی هر پیامک"
                                        focus={true}
                                        type="text"
                                        name='dedicatedBasePrice'
                                        value={formik.values.dedicatedBasePrice !== undefined
                                            ? formik.values.dedicatedBasePrice
                                            : formik.initialValues.dedicatedBasePrice}
                                        error={(formik.errors.dedicatedBasePrice && formik.touched.dedicatedBasePrice)
                                            ? formik.errors.dedicatedBasePrice
                                            : 'false'
                                        }
                                        onChange={(e) =>
                                            formik.setFieldValue("dedicatedBasePrice", e.target.value)
                                        }
                                    />
                                </div>

                                <div className="mt-3 d-flex justify-content-between">
                                    <StyledButton
                                        btnStyle="greenBtn"
                                        type="submit"
                                        disabled={(!formik.isValid || !formik.dirty)}
                                    >
                                        {editMode ? 'ویرایش' : 'ایجاد'} قیمت پایه اختصاصی
                                    </StyledButton>
                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => setVisible(false)}
                                    >
                                        انصراف
                                    </StyledButton>
                                </div>

                            </StyledForm>
                        )}

                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

export default ModifyDedicatedBasePriceModal