import React, {useCallback, useEffect, useState} from 'react';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import DateConvert from '../../../Utility/Services/date-convert';
import {sendSMSType} from '../../../Utility/Services/send-sms';
import limitationType from '../Services/send-sms-limitation-type';
import EnumFlag from '../../../Utility/Services/enum-flag';
import Table from '../../../Component/Shared/Table/Table';
import {HeaderContent, PageTitleLayout, WrapperLayout,} from '../../../Component/Kit/Layout';
import {useHistory, useLocation} from 'react-router-dom';
import {StyledButton} from '../../../Component/Kit/Button';
import moment from 'moment-jalaali';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {ConvertQueryString} from '../../../Utility/Services/convert-query-string';

let interval;

function PendingSMS() {
    let history = useHistory();
    const location = useLocation();
    const [pendingData, setpendingData] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [refreshDate, setRefreshDate] = useState(moment().format('HH:mm:ss'));

    const intervalTime = 90000;

    const tableConfig = {
        header: {
            userName: 'نام کاربری',
            messageText: 'متن پیامک',
            smsTypeTitle: 'نوع ارسال',
            lineNumber: 'شماره خط',
            limitationTypeTitle: 'دلایل محدودیت',
            forbiddenWords: 'کلمه فیلتر',
            recipientCount: 'تعداد مخاطبین',
            sentAt: 'زمان ارسال',
        },
        tooltipTitle: ['messageText'],
        actions: {
            view: {
                icon: 'fa fa-eye',
            },
        },
        hasSelectCol: true,
    };
    const tableActions = (item) => {
        if (item.actionName === 'view') {
            history.push(`/sms/${item.data.id}/${item.data.smsStorage}`);
        }
    };
    const getSMSQueue = useCallback(() => {
        ApiClient.Get(
            Urls.getPendingSMS(),
            ConvertQueryString(location.search)
        ).then((res) => {
            setRefreshDate(moment().format('HH:mm:ss'))
            let data = res.result;
            data.items.map((m) => {
                m.sentAt = DateConvert(m.sentAt);
                m.smsTypeTitle = sendSMSType(m.smsType);
                m.limitationTypeTitle = limitationType(
                    EnumFlag([1, 2, 4, 8], m.limitationType)
                );
                m.forbiddenWords = m.forbiddenWords ? m.forbiddenWords : '-----';
                return m;
            });
            setpendingData(data);
        });
    }, [location.search]);

    const tableSelectedList = (item) => {
        setSelectedList([...item]);
        if (item.length) {
            clearInterval(interval);
        } else {
            autoRefresh();
        }
    };

    const updateHandler = () => {
        getSMSQueue();
        setLoadingFlag(true);
        setTimeout(() => {
            setLoadingFlag(false);
        }, 30000);
    };

    const queueSmsHandler = async (item) => {
        try {
            let result = await Swal.fire({
                title: '',
                text: `آیا از ${
                    item ? 'تایید' : 'عدم تایید'
                } ارسال این پیامک ها مطمئن هستید؟`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonText: 'انصراف',
                cancelButtonColor: '#d33',
                confirmButtonText: 'بله، مطمئن هستم',
            });
            if (result.isConfirmed) {
                let queueList = new Map();
                selectedList.forEach((f) => {
                    queueList.set(f.id.toString(), f.smsStorage.toString());
                });
                let body = {
                    confirm: item,
                    items: Object.fromEntries(queueList),
                };
                await ApiClient.Patch(Urls.getPendingSMS(), body);
                toast.success('تغییرات  با موفقیت ثبت شد.');
                setSelectedList([]);
                getSMSQueue();
                autoRefresh();
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const autoRefresh = useCallback(() => {
        interval = setInterval(() => {
            getSMSQueue();
        }, intervalTime);
    }, [getSMSQueue]);

    useEffect(() => {
        getSMSQueue();
        autoRefresh();
        return () => clearInterval(interval);
    }, [autoRefresh, getSMSQueue]);

    return (
        <WrapperLayout>
            <div className='c-wrapper'>
                <HeaderContent className='d-flex flex-row-reverse justify-content-between align-items-center flex-wrap'>
                    <div className='col-12 col-lg-4 d-flex justify-content-end align-items-center p-0'>
                        <div className='d-flex mr-3 rtl'>
                            <span className='fo-12 mr-2'>آخرین بروزرسانی: </span>
                            <span>{refreshDate}</span>
                        </div>
                        <StyledButton
                            btnStyle='greenBtn'
                            onClick={() => updateHandler()}
                            disabled={loadingFlag}
                        >
                            بروزرسانی
                        </StyledButton>
                    </div>
                    <div className='col-12 col-lg-8 mt-3 mt-lg-0 d-flex justify-content-end justify-content-lg-start align-items-center p-0'>
                        <StyledButton
                            btnStyle='greenBtn mb-1 mb-xl-0'
                            onClick={() => queueSmsHandler(true)}
                            disabled={!selectedList.length}
                        >
                            تائید پیام های انتخاب شده
                        </StyledButton>
                        <StyledButton
                            btnStyle='redBtn ml-2'
                            onClick={() => queueSmsHandler(false)}
                            disabled={!selectedList.length}
                        >
                            عدم تائید پیام های انتخاب شده
                        </StyledButton>
                    </div>
                </HeaderContent>
                <div className="p-4">
                    <PageTitleLayout>
                        <h5>صف ارسال پیامک</h5>
                        <span>
              دراین بخش می‌توانید پیامک‌هایی که نیاز به بررسی برای ارسال دارند
              را مدیریت نمایید.
            </span>
                    </PageTitleLayout>
                    <Table
                        data={pendingData.items}
                        config={tableConfig}
                        pageConfig={{
                            pageNumber: pendingData.pageNumber,
                            pageSize: pendingData.pageSize,
                            totalItemsCount: pendingData.totalItemsCount,
                            totalPagesCount: pendingData.totalPagesCount,
                        }}
                        actionDispatched={tableActions}
                        selectedDispatch={tableSelectedList}
                    />
                </div>
            </div>
        </WrapperLayout>
    );
}

export default PendingSMS;
