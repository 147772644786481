

import { toDate, fromDate, usageLimitationPerUser, discountCode, basePriceDisCount } from "../Rules";
import * as Yup from "yup";

export const ModifyDiscountCodeSchema = Yup.object().shape({
    fromDate,
    toDate,
});

export const AddDiscountCodeSchema = Yup.object().shape({
    fromDate,
    toDate,
    usageLimitationPerUser,
    discountCode
});

export const AddBasicInformationStepSchema = Yup.object().shape({
    fromDate,
    toDate,
    usageLimitationPerUser,
    discountCode
});

export const ModifyBasicInformationStepSchema = Yup.object().shape({
    fromDate,
    toDate,
});

export const BasePriceDisCountSchema = Yup.object().shape({
    basePriceDisCount
});
