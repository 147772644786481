import React, {useEffect, useState} from "react";
import {Badge} from 'react-bootstrap';
import {StyledButton} from "../../../../Component/Kit/Button";
import Urls from "../../../../Requester/Urls";
import Modal from "react-bootstrap/Modal";
import {connect, useDispatch} from "react-redux";
import {Formik} from "formik";
import Swal from "sweetalert2";
import ApiClient from "../../../../Requester/ApiClient";
import {toast} from "react-toastify";
import {settingModal} from "../../../../Actions/setting-modal";
import {StyledForm, StyledSwitchBox} from "../../../../Component/Kit/Form";
import {SystemLineProviderEnum} from "../../../../Utility/Model/provider-enum.model";

function ModifyOffKeywordSettingByProvider({data}) {
    let dispatch = useDispatch();
    const [isModal, setIsModal] = useState(false);
    const [modalData, setModalData] = useState();

    useEffect(() => {
        if (data) {
            setModalData(JSON.parse(data))
        }
    }, [data]);


    const convertedData = (data) => {
        return Object.keys(data).map(provider => {
            const enumEntry = SystemLineProviderEnum.find(entry => entry.Eng === provider);
            return {
                providerId: enumEntry.value,
                isActive: data[provider]
            };
        });
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">تنظیمات عبارت لغو11</span>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>

            <Modal centered show={isModal} onHide={() => {
                setIsModal(false)
            }}>
                <Modal.Header>
                    <Modal.Title>تنظیمات عبارت لغو11</Modal.Title>
                    <i onClick={() => {
                        setIsModal(false)
                    }} className="close-btn fa fa-xmark"/>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalData &&
                        <>
                            <Formik
                                initialValues={{
                                    ...modalData
                                }}
                                isInitialValid={false}
                                enableReinitialize={true}
                                onSubmit={(values) => {
                                    Swal.fire({
                                        title: `آیا مطمئن هستید؟`,
                                        text: "",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonText: "انصراف",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "بله، مطمئن هستم",
                                    }).then(result => {
                                        if (result.isConfirmed) {
                                            const body = convertedData(values)
                                            ApiClient.Patch(Urls.offKeyword(), JSON.stringify(body))
                                                .then((res) => {
                                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                                    dispatch(settingModal(true));
                                                })
                                        }

                                    })

                                }}
                                onReset={(values, formikBag) => {
                                    setIsModal(false)
                                }}
                            >
                                {(formik) => (
                                    <StyledForm
                                        className={'p-0'}
                                        onSubmit={formik.handleSubmit}
                                        onBlur={formik.handleBlur}
                                        onReset={formik.handleReset}>

                                        <ul className="settings-list">

                                            {Object.keys(formik.values).map((item, index) => (
                                                <li key={item} className="settings-list-item">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <span className="ml-1">
                                                                {SystemLineProviderEnum.find(p => p.Eng === item)?.name}
                                                            </span>
                                                            <Badge className="font-weight-normal" variant="primary">
                                                                {
                                                                    (formik.values[item]) ? 'فعال' : 'غیرفعال'
                                                                }
                                                            </Badge>
                                                        </div>
                                                        <StyledSwitchBox checked={(formik.values[item])}
                                                                         onChange={(e) => formik.setFieldValue(item, e.target.checked)}/>
                                                    </div>
                                                </li>
                                            ))}

                                        </ul>

                                        <div className="d-flex justify-content-between">
                                            <StyledButton
                                                width="35%"
                                                btnStyle="grayBtn"
                                                type="reset"
                                            >
                                                بستن
                                            </StyledButton>
                                            <StyledButton
                                                width="35%"
                                                disabled={!formik.isValid || !formik.dirty}
                                                btnStyle="greenBtn"
                                                type="submit"
                                            >
                                                اعمال تغییرات
                                            </StyledButton>
                                        </div>
                                    </StyledForm>
                                )}
                            </Formik>
                        </>
                    }
                </Modal.Body>

            </Modal>

        </>
    )
}

export default connect()(ModifyOffKeywordSettingByProvider);
