
import styled from "styled-components";

export const ProviderStateLayout = styled.div`
  .main-box {
    padding: 1rem;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(25% - 16px);
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: 0 3px 16px #d9d9d9;
    background: #ffffff;
    border-radius: 0.25rem;
    cursor: pointer;

    & canvas{
      width: 10px;
      height: 20px;
    }
    .inner-container {
      position: relative;
      min-height: 100px;
      .custom-budge {
        color: #fff;
        border-radius: 1rem;
        padding: .6rem;
        display: flex;
        align-items: center;

        &.healthy {
          background: #4fd18f;
        }

        &.unhealthy {
          background: #d14f4f;
        }

        &.unknown {
          background: #dc8759;
        }
      }


      .resend-btn {
        border-radius: 10px;
        border: none;
        font-size: .7rem;
        color: #fff;
        padding: .5rem 1rem;
        &:focus{
          outline: none;
          border: none;
        }

        &.healthy {
          background: #449f70;
        }

        &.unhealthy {
          background: #922d2d;
        }

        &.unknown {
          background: #99603a;
        }
      }

      .last-delivered {
        height: 38px;
        background: #eee;
        border-radius: 12px;
        & .healthy {
          color: #449f70;
          font-weight: bold;
        }

        & .unhealthy {
          color: #922d2d;
          font-weight: bold;
        }

        & .unknown {
          color: #99603a;
          font-weight: bold;
        }
      }
    }
  }
`
