import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { StyledButton } from "../../../Component/Kit/Button";
import { StyledForm, StyledTextArea } from "../../../Component/Kit/Form";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { FastSendTemplateStatus } from "../../../Utility/Model/fast-send-template.model";
import { FastSendTemplateRejectSchema } from "../../../Utility/Validation/Schema/FastSendTemplateRejectSchema";

const fastSendTemlateModalTitle = {
    title: "عنوان قالب",
    userName: 'نام کاربری',
    verifyStatusName: "وضعیت قالب",
    createdAt: "تاریخ ایجاد",
    templateText: "متن قالب",
}

function FastSendTemplateNotPendingModal({ data, dataChange, modalReject, rejectHandler, onCloseModal }) {

    const templateHandler = (item) => {
        if (item === FastSendTemplateStatus.UnVerified) {
            ApiClient.Put(Urls.putFastSendTemplates(data.id), { status: FastSendTemplateStatus.IsVerified })
                .then(res => {
                    dataChange(true)
                    onCloseModal(false)
                    toast.success("تغییرات  با موفقیت ثبت شد.");
                })
        } else if (item === FastSendTemplateStatus.IsVerified) {
            rejectHandler(true)
        }
    }
    return (
        <>
            {modalReject ? (
                <Formik
                    initialValues={{
                        fastSendTemplateRejectDescription: "",
                    }}
                    validationSchema={FastSendTemplateRejectSchema}
                    isInitialValid={false}
                    onSubmit={(values, actions) => {
                        const payload = {
                            description: values.fastSendTemplateRejectDescription,
                            status: FastSendTemplateStatus.UnVerified
                        }
                        ApiClient.Put(Urls.putFastSendTemplates(data.id), payload)
                            .then(res => {
                                dataChange(true)
                                toast.success("تغییرات  با موفقیت ثبت شد.");
                                onCloseModal(false)
                            })
                    }}
                    onReset={(values, formikBag) => {
                        onCloseModal(false)
                    }}
                >
                    {(formik) => (
                        <StyledForm
                            onSubmit={formik.handleSubmit}
                            onBlur={formik.handleBlur}
                            onReset={formik.handleReset}>
                            <StyledTextArea
                                lable="توضیحات"
                                focus={true}
                                name="fastSendTemplateRejectDescription"
                                value={formik.values.fastSendTemplateRejectDescription}
                                error={(formik.errors.fastSendTemplateRejectDescription && formik.touched.fastSendTemplateRejectDescription)
                                    ? formik.errors.fastSendTemplateRejectDescription
                                    : 'false'
                                }
                                onChange={(e) => {
                                    const str = e.target.value.trim() !== '' ? e.target.value : '';
                                    formik.setFieldValue("fastSendTemplateRejectDescription", str)
                                }}
                            />
                            <div className="d-flex justify-content-between w-100">
                                <StyledButton
                                    width="35%"
                                    btnStyle="grayBtn"
                                    type="reset"
                                    onClick={() => onCloseModal(false)}
                                >
                                    بستن
                                </StyledButton>
                                <StyledButton
                                    width="35%"
                                    disabled={!formik.isValid || !formik.dirty}
                                    btnStyle='redBtn'
                                    type="submit"
                                >
                                    عدم تایید
                                </StyledButton>
                            </div>
                        </StyledForm>
                    )}
                </Formik>
            ) : (
                <>
                    <div className="w-100">
                        <ul className="params">
                            {
                                fastSendTemlateModalTitle && data &&
                                Object.entries(fastSendTemlateModalTitle).map(([key, value]) => (

                                    <li className="params__list" key={`${key}_${data.id}`}>
                                        <div className="params__list__label">
                                            <span>{value}: </span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white" style={{ whiteSpace: 'pre-wrap' }}>{data[key]}</span>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    {/* <div className="d-flex flex-column w-100">
                        <h4>{data.title}</h4>
                        <hr />
                        <p className="mt-2">{data.templateText}</p>
                    </div> */}
                    <div className="d-flex justify-content-between mt-2 w-100">
                        <StyledButton
                            width="35%"
                            btnStyle="grayBtn"
                            type="reset"
                            onClick={() => onCloseModal(false)}
                        >
                            بستن
                        </StyledButton>
                        <StyledButton width="35%"
                            onClick={() => templateHandler(data.verifyStatus)}
                            btnStyle={data.verifyStatus === FastSendTemplateStatus.IsVerified ? "redBtn" : "greenBtn"}
                        >
                            <span className="ml-2">{data.verifyStatus === FastSendTemplateStatus.IsVerified ? "عدم تایید" : "تایید"}</span>
                        </StyledButton>
                    </div>
                </>
            )}
        </>
    )
}

export default FastSendTemplateNotPendingModal;
