let UserDeactivationReasonModel = [
    { name: 'نگرانی از حریم شخصی', value: 1 },
    { name: 'عدم کارامد بودن سیستم', value: 2 },
    { name: 'دریافت پیامک های زیاد', value: 3 },
    { name: 'مهاجرت به سیستم جدید', value: 4 },
    { name: 'عدم استفاده از سامانه', value: 5 },
    { name: 'بالا بودن هزینه ها', value: 6 },
    { name: 'پیچیدگی نرم افزار', value: 7 },
    { name: 'بقیه موارد', value: 8 },
];

export default UserDeactivationReasonModel;