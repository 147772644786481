import React, { useEffect, useState } from 'react';
import { MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import Table from './../../../Component/Shared/Table/Table';
import DateConvert from './../../../Utility/Services/date-convert';
import { AdminActivityOperationService } from './../../../Utility/Services/AdminActivityOperationService';
import { ConvertQueryString } from '../../../Utility/Services/convert-query-string';
import { useLocation } from 'react-router-dom';

const AdminActivityLogs = ({ history }) => {
    const location = useLocation();
    const [adminsLogList, setAdminsLogList] = useState({});

    const tableConfig = {
        header: {
            adminUserName: "نام کاربری ادمین",
            userName: "نام کاربری کاربر",
            operation: "عملیات",
            description: "توضیحات",
            createdAt: "تاریخ و ساعت",
        },
        actions: {},
        placeholder: ['userName', 'adminUserName'],
        mode: ["filter", "search"],
        filters: ["operation", "datePickerFilter"],
    }


    useEffect(() => {
        ApiClient.Get(Urls.GetAdminActivityLog(), ConvertQueryString(location.search))
            .then(res => {
                res.result.items.map(m => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.operation = AdminActivityOperationService(m.operation)
                })
                setAdminsLogList(res.result)
            })
    }, [location.search])



    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <MainContent>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <PageTitleLayout>
                                <h5 className="mb-3"> سوابق فعالیت‌های مدیران</h5>
                                <span>در این بخش، کلیه فعالیت‌های مدیران و سوابق آن قابل مشاهده است.
                                </span>
                            </PageTitleLayout>

                            <Table
                                data={adminsLogList.items}
                                config={tableConfig}
                                pageConfig={{
                                    pageNumber: adminsLogList.pageNumber,
                                    pageSize: adminsLogList.pageSize,
                                    totalItemsCount: adminsLogList.totalItemsCount,
                                    totalPagesCount: adminsLogList.totalPagesCount,
                                }}
                            />
                        </div>
                    </div>
                </MainContent>
            </div>
        </WrapperLayout>
    )
}

export default AdminActivityLogs