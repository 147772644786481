import React from "react";

function Logo(props) {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 170 50"
            {...props}
        >
            <defs>
                <style>
                    {
                        `\n            .cls-1 {\n            fill: #ffbe00;\n            }\n\n            .cls-2 {\n            fill: ${props.fill ? props.fill : '#2e2e2e'};\n            }\n        `
                    }
                </style>
            </defs>
            <path
                className="cls-1"
                d="m54.16,30.3l3.73-3.13,3.92-3.31-2.95-7.01-6.98-4.42-2.37-4.86,2.09-2.59,5.41-3.06v-.92H12.07c-4.07,0-7.37,3.3-7.37,7.37v14.75c0,4.07,3.3,7.37,7.37,7.37h42.08v-.2Z"
            />
            <g>
                <path fill={props.shapefill ? props.shapefill :'#2e2e2e' } d="m39.05,26.33c-1.13-.59-2.35-1.04-3.63-1.31-.95-.2-1.93-.31-2.94-.31-.79,0-1.56.06-2.32.19-.89.15-1.76.38-2.59.68-.19.07-.38.15-.57.22-1,.42-2.05.75-3.13.89-.52.07-1.05.1-1.59.1-.77,0-1.53-.07-2.26-.21-.5-.09-.98-.22-1.46-.37-.16-.05-.31-.1-.46-.16-.27-.1-.53-.21-.79-.32-.18-.1-.35-.2-.52-.3-.28-.17-.56-.36-.82-.55-.7-.51-1.34-1.09-1.91-1.74-.81-.91-1.48-1.94-1.99-3.06,1.85,1.01,3.98,1.58,6.24,1.58.47,0,.94-.02,1.39-.07,0,0,0,0,0,0,.17-.02.34-.04.51-.06,0,0,0,0,0,0,.36-.05.71-.12,1.05-.2h.04s.02-.02.02-.02c.12-.03.24-.06.35-.09h0s1.77-.45,1.77-.45l2.89-.73s0,0,0,0c0,0,0,0,0,0h0s0,0,0,0l.24-.06c.95-.23,1.94-.35,2.96-.35.46,0,.91.03,1.35.07.72.12,1.42.31,2.08.56.14.05.27.1.4.16.14.07.27.14.4.22.02,0,.03.02.05.03,2.27,1.29,4.09,3.27,5.19,5.66Z" />
                <path fill={props.shapefill ? props.shapefill :'#2e2e2e' } className="col-3" d="m36.23,21.44c-.83-.7-1.76-1.28-2.78-1.74-.75-.34-1.55-.6-2.38-.79-.66-.14-1.31-.23-1.96-.26-.77-.04-1.53,0-2.27.1-.2.03-.4.06-.6.1-.32.06-.65.13-.98.16-.99.08-2.01.02-3.03-.2-.64-.14-1.25-.34-1.84-.59-.4-.17-.78-.36-1.14-.57-.12-.07-.24-.14-.35-.22-.2-.13-.4-.27-.6-.41-.13-.11-.26-.23-.38-.35-.2-.19-.4-.4-.58-.61-.49-.55-.91-1.15-1.27-1.79-.51-.9-.88-1.88-1.09-2.9,1.36,1.17,3.02,2.03,4.89,2.44.39.09.78.15,1.17.19,0,0,0,0,0,0,.15.02.29.03.44.04,0,0,0,0,0,0,.3.02.61.03.91.03h.04s.02,0,.02,0c.1,0,.21,0,.31,0h0s1.55-.05,1.55-.05l2.53-.08s0,0,0,0c0,0,0,0,0,0h0s0,0,0,0h.21c.83-.03,1.67.05,2.52.24.38.08.75.19,1.11.31.57.23,1.12.52,1.63.84.1.07.21.13.31.2.1.08.2.17.3.25.01,0,.02.02.04.03,1.65,1.48,2.8,3.45,3.28,5.63Z" />
                <path fill={props.shapefill ? props.shapefill :'#2e2e2e' } className="col-3" d="m34.5,16.51c-.58-.71-1.26-1.36-2.04-1.9-.57-.4-1.2-.75-1.87-1.04-.52-.22-1.06-.4-1.6-.53-.64-.16-1.28-.25-1.91-.28-.17,0-.35-.01-.52-.01-.28,0-.56,0-.84-.03-.84-.09-1.68-.31-2.5-.66-.51-.22-.99-.48-1.44-.78-.3-.2-.59-.43-.86-.66-.09-.08-.18-.16-.26-.24-.15-.14-.29-.29-.43-.44-.09-.11-.18-.23-.26-.35-.14-.19-.27-.39-.39-.6-.32-.54-.58-1.11-.78-1.7-.28-.83-.43-1.71-.45-2.6.95,1.2,2.19,2.18,3.69,2.82.31.13.63.25.95.35,0,0,0,0,0,0,.12.04.24.07.36.1,0,0,0,0,0,0,.25.07.5.12.76.17h.03s.02,0,.02,0c.09.02.17.03.26.04h0s1.31.21,1.31.21l2.13.34s0,0,0,0c0,0,0,0,0,0h0s0,0,0,0l.17.03c.7.12,1.39.32,2.07.61.3.13.6.28.88.43.44.29.85.61,1.22.97.08.07.15.14.22.22.07.09.14.17.21.26,0,.01.02.02.02.03,1.14,1.5,1.79,3.34,1.83,5.23Z" />
            </g>
            <path d="m47.58,24.35c1.69.97,4.25,1.82,6.91,1.82,3.33,0,5.2-1.57,5.2-3.91,0-2.16-1.45-3.43-5.07-4.75-4.73-1.68-7.74-4.21-7.74-8.31,0-4.69,3.92-8.21,10.12-8.21,3.1,0,5.37.66,6.86,1.41l-1.25,4.22c-1.03-.54-3.03-1.36-5.71-1.36-3.32,0-4.72,1.76-4.72,3.45,0,2.19,1.65,3.23,5.47,4.68,4.97,1.9,7.36,4.39,7.36,8.5,0,4.6-3.47,8.59-10.85,8.59-3.03,0-6.17-.86-7.73-1.82l1.16-4.33Z" />
            <path d="m112.36,24.35c1.69.97,4.25,1.82,6.91,1.82,3.33,0,5.2-1.57,5.2-3.91,0-2.16-1.45-3.43-5.07-4.75-4.73-1.68-7.74-4.21-7.74-8.31,0-4.69,3.92-8.21,10.12-8.21,3.1,0,5.37.66,6.86,1.41l-1.25,4.22c-1.03-.54-3.03-1.36-5.71-1.36-3.32,0-4.72,1.76-4.72,3.45,0,2.19,1.65,3.23,5.47,4.68,4.97,1.9,7.36,4.39,7.36,8.5,0,4.6-3.47,8.59-10.85,8.59-3.03,0-6.17-.86-7.73-1.82l1.16-4.33Z" />
            <path d="m98.01,18.46c-.18-3.81-.37-8.39-.35-12.41h-.12c-.97,3.62-2.21,7.6-3.48,11.33l-4.33,12.76h-4.15l-3.91-12.59c-1.16-3.79-2.21-7.79-2.98-11.5h-.1c-.13,3.86-.3,8.58-.53,12.59l-.66,11.85h-5.02l1.96-29.5h7.08l3.84,11.85c1.06,3.49,2.01,6.99,2.71,10.27h.13c.79-3.22,1.81-6.83,2.95-10.3l4.09-11.82h6.99l1.69,29.5h-5.25l-.57-12.03Z" />
            <path d="m152.37,6.88c0,1.14-.8,2.08-2.13,2.08-1.21,0-2.02-.94-2.02-2.08,0-1.15.86-2.08,2.08-2.08s2.06.91,2.07,2.08Zm-3.74,23.62V12.08h3.33v18.41h-3.33Z" />
            <path d="m156.21,17.82c0-2.17-.05-4.03-.15-5.74h2.94l.13,3.61h.12c.86-2.47,2.89-4.03,5.13-4.03.36,0,.63.02.93.09v3.15c-.34-.07-.68-.1-1.14-.1-2.36,0-4.03,1.78-4.48,4.28-.09.47-.14,1.02-.14,1.56v9.84h-3.33v-12.68Z" />
            <circle cx={139} cy={28.65} r={1.84} />
            <g>
                <path className="cls-2" d="m5.9,39.78v9.22h-1.2v-9.22h1.2Z" />
                <path
                    className="cls-2"
                    d="m10.32,39.91c.72-.12,1.58-.2,2.51-.2,1.69,0,2.9.4,3.7,1.15.82.74,1.29,1.8,1.29,3.27s-.47,2.71-1.31,3.55c-.86.85-2.27,1.32-4.04,1.32-.84,0-1.54-.03-2.14-.1v-8.98Zm1.18,8.08c.3.05.74.06,1.19.06,2.53,0,3.89-1.4,3.89-3.87.01-2.16-1.21-3.52-3.69-3.52-.61,0-1.07.06-1.38.12v7.21Z"
                />
                <path
                    className="cls-2"
                    d="m26.48,44.68h-3.57v3.33h4v.99h-5.19v-9.22h4.98v.99h-3.79v2.92h3.57v.99Z"
                />
                <path
                    className="cls-2"
                    d="m31.95,39.78v3.86h4.46v-3.86h1.2v9.22h-1.2v-4.32h-4.46v4.32h-1.2v-9.22h1.2Z"
                />
                <path
                    className="cls-2"
                    d="m42.01,39.96c.57-.1,1.32-.18,2.27-.18,1.16,0,2.02.27,2.56.76.5.44.8,1.1.8,1.92s-.25,1.48-.71,1.96c-.63.67-1.64,1-2.8,1-.36,0-.68-.01-.96-.08v3.67h-1.18v-9.04Zm1.18,4.41c.26.08.59.1.98.1,1.43,0,2.3-.7,2.3-1.95s-.86-1.8-2.16-1.8c-.52,0-.91.05-1.12.09v3.56Z"
                />
                <path
                    className="cls-2"
                    d="m51.93,46.1l-.95,2.9h-1.23l3.14-9.22h1.43l3.15,9.22h-1.27l-.99-2.9h-3.27Zm3.03-.93l-.91-2.65c-.2-.6-.34-1.15-.48-1.68h-.03c-.13.54-.28,1.1-.46,1.67l-.91,2.67h2.79Z"
                />
                <path
                    className="cls-2"
                    d="m61.14,39.98c.6-.12,1.46-.2,2.27-.2,1.25,0,2.08.24,2.65.75.45.4.72,1.02.72,1.74,0,1.2-.77,2-1.72,2.33v.03c.7.24,1.12.9,1.34,1.85.3,1.28.52,2.15.7,2.51h-1.23c-.14-.26-.35-1.04-.6-2.19-.27-1.25-.77-1.74-1.84-1.77h-1.12v3.96h-1.18v-9.02Zm1.18,4.16h1.21c1.26,0,2.07-.69,2.07-1.74,0-1.19-.86-1.7-2.1-1.71-.57,0-.97.05-1.17.1v3.34Z"
                />
                <path
                    className="cls-2"
                    d="m70.85,39.97c.72-.12,1.57-.19,2.49-.19,1.68,0,2.88.4,3.67,1.14.81.73,1.28,1.78,1.28,3.24s-.46,2.69-1.3,3.53c-.86.84-2.26,1.31-4.02,1.31-.84,0-1.53-.03-2.13-.1v-8.92Zm1.18,8.02c.3.05.73.06,1.18.06,2.51,0,3.86-1.39,3.86-3.84.01-2.14-1.2-3.49-3.67-3.49-.61,0-1.06.06-1.37.12v7.16Z"
                />
                <path
                    className="cls-2"
                    d="m83.52,46.1l-.95,2.9h-1.23l3.14-9.22h1.43l3.15,9.22h-1.27l-.99-2.9h-3.27Zm3.03-.93l-.91-2.65c-.2-.6-.34-1.15-.48-1.68h-.03c-.13.54-.28,1.1-.46,1.67l-.91,2.67h2.79Z"
                />
                <path
                    className="cls-2"
                    d="m92.11,48.3l5.11-7.48v-.04h-4.68v-1h6.22v.73l-5.08,7.45v.04h5.15v1h-6.73v-.7Z"
                />
                <path
                    className="cls-2"
                    d="m104.1,46.1l-.95,2.9h-1.23l3.14-9.22h1.43l3.15,9.22h-1.27l-.99-2.9h-3.27Zm3.03-.93l-.91-2.65c-.2-.6-.34-1.15-.48-1.68h-.03c-.13.54-.28,1.1-.46,1.67l-.91,2.67h2.79Z"
                />
                <path
                    className="cls-2"
                    d="m113.29,49v-9.22h1.31l2.95,4.66c.67,1.08,1.21,2.05,1.64,3h.03c-.11-1.24-.13-2.37-.13-3.8v-3.86h1.12v9.22h-1.21l-2.93-4.67c-.63-1.03-1.25-2.08-1.71-3.09h-.04c.07,1.18.09,2.29.09,3.82v3.94h-1.12Z"
                />
                <path
                    className="cls-2"
                    d="m132.51,48.79c-.21.1-.64.21-1.18.21-1.26,0-2.2-.8-2.2-2.26s.94-2.34,2.33-2.34c.55,0,.91.12,1.06.2l-.14.47c-.22-.1-.53-.18-.9-.18-1.05,0-1.74.67-1.74,1.84,0,1.09.63,1.79,1.71,1.79.35,0,.71-.07.94-.18l.12.46Z"
                />
                <path
                    className="cls-2"
                    d="m138.19,46.65c0,1.53-.93,2.34-2.07,2.34s-2-.91-2-2.26.87-2.34,2.06-2.34,2.01.93,2.01,2.25Zm-3.46.07c0,.95.52,1.81,1.42,1.81s1.43-.84,1.43-1.85c0-.89-.46-1.81-1.42-1.81s-1.43.88-1.43,1.86Z"
                />
                <path
                    className="cls-2"
                    d="m144.07,46.98c-.04-.64-.08-1.41-.07-1.98h-.02c-.16.53-.35,1.11-.58,1.74l-.81,2.23h-.45l-.75-2.19c-.22-.65-.4-1.24-.53-1.78h-.02c-.02.57-.05,1.34-.09,2.03l-.12,1.97h-.56l.32-4.6h.76l.78,2.23c.19.56.34,1.07.46,1.55h.02c.12-.47.28-.97.49-1.55l.82-2.23h.76l.29,4.6h-.58l-.11-2.02Z"
                />
                <path
                    className="cls-2"
                    d="m146.85,44.49c.28-.05.66-.09,1.13-.09.58,0,1.01.13,1.28.38.25.22.4.55.4.95s-.12.74-.35.98c-.31.33-.82.5-1.39.5-.18,0-.34,0-.48-.04v1.83h-.59v-4.51Zm.59,2.2c.13.04.3.05.49.05.71,0,1.15-.35,1.15-.97s-.43-.9-1.08-.9c-.26,0-.45.02-.56.05v1.77Z"
                />
                <path
                    className="cls-2"
                    d="m151.81,47.55l-.47,1.44h-.62l1.56-4.6h.71l1.57,4.6h-.63l-.49-1.44h-1.63Zm1.51-.46l-.46-1.32c-.1-.3-.17-.57-.24-.84h-.02c-.07.27-.14.55-.23.83l-.45,1.33h1.39Z"
                />
                <path
                    className="cls-2"
                    d="m156.4,49v-4.6h.65l1.47,2.32c.34.54.6,1.02.82,1.5h.02c-.05-.62-.06-1.18-.06-1.89v-1.93h.56v4.6h-.6l-1.46-2.33c-.32-.52-.63-1.04-.86-1.54h-.02c.04.59.04,1.14.04,1.9v1.97h-.56Z"
                />
                <path
                    className="cls-2"
                    d="m163.17,49v-1.95l-1.46-2.65h.68l.65,1.27c.17.35.32.63.46.95h.01c.13-.3.28-.6.46-.95l.66-1.27h.67l-1.54,2.64v1.96h-.6Z"
                />
            </g>
        </svg>
    );
}

export default Logo;
