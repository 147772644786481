import React, {useState} from 'react';
import {Link, NavLink, useHistory} from 'react-router-dom';

const Navigation = (props) => {
    const {menuItem, screenStatus, setScreenStatus} = props;
    const history = useHistory();
    const currentRoute = history.location.pathname;

    const removeClass = (event) => {
        const m = document.getElementsByClassName('menu__item__link--expanded');
        // Array.prototype.forEach.call(m, (el) => {
        //     if (el) {
        //         el.classList.remove('menu__item__link--expanded', 'menu__item__link--active');
        //     }
        Array.prototype.forEach.call(m, (el) => {
            if (el !== event.currentTarget) {
                if (el.children[1]) {
                    el.classList.remove('menu__item__link--expanded', 'menu__item__link--active');
                }
            }
        });
    }
    const [isOpen, setIsOpen] = useState(false)
    const expandedMenu = (event) => {
        event.stopPropagation()
        removeClass(event);
        if (screenStatus.sidebarMode === 'slim') {
            setScreenStatus({...screenStatus, sidebarMode: "default"})
        }
        if (event.currentTarget.classList.value.includes('menu__item__link--expanded') &&
            event.currentTarget.classList.value.includes('menu__item__link--active')) {
            event.currentTarget.closest("div.parent").classList.remove('menu__item__link--expanded', 'menu__item__link--active');
        } else {
            event.currentTarget.closest("div.parent").classList.add('menu__item__link--expanded', 'menu__item__link--active');
        }
    }

    const changeSidebarState = () => {
        switch (screenStatus.deviceMode) {
            case 'mobile':
                if (screenStatus.sidebarMode === 'full') {
                    setScreenStatus({...screenStatus, sidebarMode: 'hidden'})
                }
                break;
            default:
                break;
        }
    }
    return (
        <>
            <div className="menu__item">
                {
                    !menuItem.subMenus
                        ?
                        (
                            menuItem.isShow ?
                                (<NavLink
                                    key={`Items${menuItem.title}`}
                                    className="menu__item__link"
                                    activeClassName="menu__item__link--active"
                                    to={menuItem.link}
                                    alt={menuItem.title}
                                    onClick={e => {
                                        removeClass(e);
                                        changeSidebarState();
                                    }}
                                >
                                    <div className="menu__item__link__box">
                                        <div className="menu__item__link__box__icon-box">
                                            <i className={`fa fa-${menuItem.icon}`}></i>
                                        </div>
                                        <span className="menu__item__link__box__title">{menuItem.title}</span>
                                    </div>
                                </NavLink>) : null
                        )
                        :
                        (
                            menuItem.isShow ? (
                                <div
                                    key={`Items${menuItem.title}`}
                                    className={`menu__item__link parent `}
                                    // activeClassName="active"
                                    // activeClassName={currentRoute.includes(menuItem.link) ? 'menu__item__link--expanded menu__item__link--active' : ''}
                                    // to="#javascript"
                                    // alt={menuItem.title}
                                    onClick={e => expandedMenu(e)}
                                >
                                    <div className="menu__item__link__box">
                                        <div className="menu__item__link__box__icon-box">
                                            <i className={`fa fa-${menuItem.icon}`}></i>
                                        </div>

                                        <span className="menu__item__link__box__title">{menuItem.title}</span>
                                        <i className="menu__item__link__box__indicator fa fa-chevron-left"></i>
                                    </div>

                                    <ul className="menu__item__link__submenu">
                                        {
                                            menuItem.subMenus.map(SItem => (
                                                SItem.isShow ? (
                                                    <li className="menu__item__link__submenu__item"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            changeSidebarState();
                                                        }}
                                                        key={`li_${SItem.title}`}>
                                                        <NavLink
                                                            key={`SItem_${SItem.title}`}
                                                            className="menu__item__link__submenu__item__link"
                                                            to={SItem.link}
                                                            alt={SItem.title}
                                                            exact
                                                            activeClassName="menu__item__link__submenu__item__link--active"

                                                        >
                                                    <span className="menu__item__link__submenu__item__link__title">
                                                        {SItem.title}
                                                        <i className="menu__item__link__submenu__item__link__title__indicator fa fa-arrow-left"></i>
                                                    </span>
                                                        </NavLink>
                                                    </li>
                                                ) : null

                                            ))
                                        }

                                    </ul>
                                </div>
                            ) : null
                        )
                }

            </div>

        </>
    )
}


export default Navigation;
