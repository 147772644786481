import React, { useEffect, useState } from "react";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import DateConvert from "../../../Utility/Services/date-convert";
import {
  CompanyActivity,
  CompanyType,
} from "../../../Utility/Services/company";
import {StyledButton} from "../../../Component/Kit/Button";

function UserDetailsModal({ userName,onCloseModal }) {
  const [userData, setuserData] = useState({});
  useEffect(() => {
    if (userName) {
      ApiClient.Get(Urls.userProfile(userName)).then((res) => {
        let data = res.result;
        data.birthDate = DateConvert(data.birthDate);
        data.isMaleName = data.isMale ? "مرد" : "زن";
        data.companyActivityFieldName = CompanyActivity(
          data.companyActivityField
        );
        if (data.legalData) {
          data.legalData.companyTypeName = CompanyType(
            data.legalData.companyType
          );
        }

        setuserData(data);
      });
    }
  }, [userName]);
  return (
    <>
      <ul className="params w-100 px-4">
        <li className="params__list">
          <div className="params__list__label">
            <span>نام و نام خانوادگی</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {`${userData.firstName} ${userData.lastName}`}{" "}
            </span>
          </div>
        </li>
        <li className="params__list">
          <div className="params__list__label">
            <span>نام پدر</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {userData.fatherName}
            </span>
          </div>
        </li>
        <li className="params__list">
          <div className="params__list__label">
            <span>تاریخ تولد</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {userData.birthDate}
            </span>
          </div>
        </li>
        <li className="params__list">
          <div className="params__list__label">
            <span>کدملی</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {userData.nationalId}
            </span>
          </div>
        </li>
        <li className="params__list">
          <div className="params__list__label">
            <span>شماره شناسنامه</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {userData.certificateNo}
            </span>
          </div>
        </li>
        <li className="params__list">
          <div className="params__list__label">
            <span>جنسیت</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {userData.isMaleName}
            </span>
          </div>
        </li>
        <li className="params__list">
          <div className="params__list__label">
            <span>زمینه فعالیت</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {userData.companyActivityFieldName}
            </span>
          </div>
        </li>
        <li className="params__list">
          <div className="params__list__label">
            <span>تلفن تماس</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {userData.phoneNumber}
            </span>
          </div>
        </li>
        <li className="params__list">
          <div className="params__list__label">
            <span>کدپستی</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {userData.postalCode}
            </span>
          </div>
        </li>
        <li className="params__list">
          <div className="params__list__label">
            <span>آدرس</span>
          </div>
          <div className="params__list__value">
            <span className="params__list__label--white">
              {userData.address}
            </span>
          </div>
        </li>
        {userData.legalData ? (
          <>
            <li className="params__list">
              <div className="params__list__label">
                <span>نام شرکت</span>
              </div>
              <div className="params__list__value">
                <span className="params__list__label--white">
                  {userData.legalData.companyName}
                </span>
              </div>
            </li>
            <li className="params__list">
              <div className="params__list__label">
                <span>شماره ثبت</span>
              </div>
              <div className="params__list__value">
                <span className="params__list__label--white">
                  {userData.legalData.registrationNumber}
                </span>
              </div>
            </li>
            <li className="params__list">
              <div className="params__list__label">
                <span>شناسه ملی</span>
              </div>
              <div className="params__list__value">
                <span className="params__list__label--white">
                  {userData.legalData.companyNationalId}
                </span>
              </div>
            </li>
            <li className="params__list">
              <div className="params__list__label">
                <span>کد اقتصادی</span>
              </div>
              <div className="params__list__value">
                <span className="params__list__label--white">
                  {userData.legalData.economicCode}
                </span>
              </div>
            </li>
            <li className="params__list">
              <div className="params__list__label">
                <span>نوع شرکت</span>
              </div>
              <div className="params__list__value">
                <span className="params__list__label--white">
                  {userData.legalData.companyTypeName}
                </span>
              </div>
            </li>
          </>
        ) : null}
      </ul>
      <div className="d-flex justify-content-center w-100">
        <StyledButton
            width="35%"
            btnStyle="grayBtn"
            type="reset"
            onClick={() => {
              onCloseModal(false)
            }}
        >
          بستن
        </StyledButton>
      </div>
    </>
  );
}

export default UserDetailsModal;
