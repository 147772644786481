import React from "react";
import styled from "styled-components";

export const PlanSectionStyled = styled.div`
  border: 2px dashed #d6d6e0;
  border-radius: 6px;
  padding: 2rem 1rem 3rem 1rem;
  margin-bottom: 1.5rem;

  /*   .price-per-plan {
    .head-row,
    .cell-row {
      grid-template-columns: 0.5fr repeat(4, 1fr);
      border-bottom: 5px solid #fff;
      background-color: #f3f3f3;
      column-gap: 1rem;
      align-content: center;
      display: grid;
      height: 52px;
      div {
        padding: 5px 0.75rem;
      }
    }
    .head-row {
      text-align: center;
      border-radius: 7px 7px 0 0;
      height: 60px;
      div {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
    .cell-row {
      > div {
        &:first-child {
          background-color: #f3f3f3;
          font-weight: bold;
          font-size: 1.2rem;
          display: inline-grid;
          align-items: center;
          text-indent: 0.5em;
        }
      }
      &:last-child {
        border-radius: 0 0 7px 7px;
      }
    }
  } */

  .price-per-plan {
    grid-template-columns: 0.5fr repeat(4, 1fr);
    /* column-gap: 0.5rem; */
    display: grid;
    .head-row,
    .cell-row {
      row-gap: 5px;
      display: grid;
      > div {
        padding: 5px 0.75rem;
        background-color: #f3f3f3;
        height: 52px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
    .head-row {
      div {
        justify-content: flex-start;
        font-weight: bold;
        font-size: 1.2rem;
        text-indent: 10px;
        &:first-child {
          border-radius: 0px 7px 0 0;
          height: 60px;
        }
        &:last-child {
          border-radius: 0px 0px 7px 0;
          /* height: 60px; */
        }
      }
    }
    .cell-row {
      > div {
        &:first-child {
          background-color: #f3f3f3;
          font-weight: bold;
          font-size: 1.2rem;
          display: inline-grid;
          align-items: center;
          text-indent: 0.5em;
          height: 60px;
          text-align: center;
          width: 100%;
        }
      }

      &:last-child {
        > div {
          &:first-child {
            border-radius: 7px 0 0px 0px;
          }
          &:last-child {
            border-radius: 0px 0px 0 7px;
          }
        }
      }
    }
  }
`;
