// import React from "react";

const ColumnsGenerator = (header) => {
  // let columns = React.useMemo(() => [], []);
  const columns = [];
  if (header !== undefined) {
    for (const item of Object.keys(header)) {
      columns.push({
        accessor: item,
        header: header[item],
      });
    }
  }
  return columns;
};

export default ColumnsGenerator;
