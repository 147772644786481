
import * as React from "react"

function InProgressTicketsIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width={55}
            height={70.974}
            {...props}
        >
            <g data-name="Group 152133">
                <path
                    data-name="Icon awesome-envelope-open-text"
                    d="M18.906 39.174h17.188a1.718 1.718 0 0 0 1.719-1.719V35.74a1.718 1.718 0 0 0-1.719-1.719H18.906a1.718 1.718 0 0 0-1.719 1.719v1.719a1.718 1.718 0 0 0 1.719 1.715Zm-1.719 8.6a1.718 1.718 0 0 0 1.719 1.719h17.188a1.718 1.718 0 0 0 1.718-1.719v-1.722a1.718 1.718 0 0 0-1.719-1.719H18.906a1.718 1.718 0 0 0-1.719 1.719ZM27.5 60.783a8.581 8.581 0 0 1-5.034-1.632L0 42.922v22.9a5.156 5.156 0 0 0 5.156 5.152h44.688A5.156 5.156 0 0 0 55 65.818v-22.9L32.534 59.151a8.589 8.589 0 0 1-5.034 1.632Zm25.524-27.309a197.27 197.27 0 0 0-3.181-2.45v-4.737a5.156 5.156 0 0 0-5.156-5.156h-8.33l-.971-.7c-1.806-1.323-5.394-4.494-7.886-4.457-2.492-.038-6.079 3.133-7.886 4.452l-.971.7h-8.331a5.156 5.156 0 0 0-5.156 5.156v4.742c-1.329 1-2.23 1.7-3.181 2.45A5.156 5.156 0 0 0 0 37.536v1.144l10.312 7.45V26.287h34.375V46.13L55 38.68v-1.144a5.156 5.156 0 0 0-1.976-4.062Z"
                    fill="#3f3d56"
                />
                <path
                    data-name="Icon awesome-ellipsis-h"
                    d="M32.501 5.063A5.063 5.063 0 1 1 27.438.001a5.059 5.059 0 0 1 5.063 5.062ZM39.814.001a5.063 5.063 0 1 0 5.062 5.062A5.059 5.059 0 0 0 39.813.001Zm-24.75 0a5.063 5.063 0 1 0 5.062 5.062A5.059 5.059 0 0 0 15.063.001Z"
                    fill="#a7abc3"
                />
            </g>
        </svg>
    )
}
export default InProgressTicketsIcon;
