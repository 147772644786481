import React, {useCallback, useEffect, useState} from 'react'
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from "../../../Component/Kit/Layout";
import {ProviderStateLayout} from "./styles";
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title , CategoryScale } from 'chart.js';
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {ProviderNotificationModel} from "../../../Utility/Model/ProviderNotificationSetting";
import {FieldArray, Formik} from "formik";
import {AddDiscountCodeSchema} from "../../../Utility/Validation/Schema/ModifyDiscountCodeSchema";
import {toast} from "react-toastify";
import {FormLable, StyledForm, StyledInput, StyledSwitchBox} from "../../../Component/Kit/Form";
import {DisCountDateWrapper} from "../../DiscountCode/style";
import {MyDatePicker} from "../../../Component/Shared/DatePicker";
import {StyledButton} from "../../../Component/Kit/Button";
import BackToPage from "../../../Component/Kit/back";

ChartJS.register(LineElement, PointElement, LinearScale, Title , CategoryScale);


export const ProviderNotification = () => {
        const [providerNotification , setProviderNotification] = useState([])



    const callApi = useCallback(()=> {


    } , [])


    useEffect(
        ()=> {
            ApiClient.Get(Urls.ProviderNotificationSetting()).then((response) => {
                // console.log(response.result)
                setProviderNotification(response.result)
            })
            } , []);

    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-end mx-4">
                <PageTitleLayout>
                    <h5>تنظیمات اعلانات ارائه‌دهندگان</h5>
                    <span>در این بخش می‌توانید تنظیمات ارائه‌دهندگان مشاهده نمایید.</span>
                </PageTitleLayout>
            </HeaderContent>

                <Formik
                    initialValues={providerNotification}
                    enableReinitialize={true}
                    validationSchema={AddDiscountCodeSchema}
                    onSubmit={(values, actions) => {
                        ApiClient.Put(Urls.ProviderNotificationSetting() , values).then((response) => {
                            toast.success('عملیات با موفقیت ثبت شد.');
                        })
                    }}
                >
                {(formik) => (
                        <StyledForm
                            onSubmit={formik.handleSubmit}
                            // onBlur={formik.handleBlur}
                            onReset={formik.handleReset}
               >
                {providerNotification.length > 0 && formik.values.length > 0 && providerNotification.map((notification , index) => (
                    <MainContent className="row" key={notification.type}>
                        <div className="col-12">
                            <div className="d-flex align-items-center pb-2">
                                <h5 className="ml-3">{ProviderNotificationModel.find(a => a.value === notification.type)?.name}</h5>
                                <StyledSwitchBox
                                    focus={true}
                                    name={`[${index}].isActive`}
                                    checked={formik.values[index].isActive}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="row">
                                <div className="col-md-9 col-12">

                                    <StyledInput
                                        lable="پیام ارسالی:"
                                        focus={true}
                                        type="text"
                                        name={`[${index}].messageText`}
                                        value={formik.values[index].messageText}
                                        error={"false"}
                                        onChange={formik.handleChange}
                                    />
                                </div>

                                <div className="col-md-3 col-12">
                                    <StyledInput
                                        lable="فواصل زمانی ارسال ها :"
                                        focus={true}
                                        type="number"
                                        name={`[${index}].messageInterval`}
                                        value={formik.values[index].messageInterval}
                                        error={ "false"}
                                        onChange={formik.handleChange}
                                    />

                                </div>
                        </div>
                            <div className="row">
                                <div className="col-6">
                                    <label>موبایل ها</label>

                                    <FieldArray
                                        name={`[${index}].recipients`}
                                        render={(arrayHelpers) => (
                                            <div>
                                                {formik.values[index].recipients.map((mobiles, i) => (
                                                    <div key={i} style={{ display: "flex", alignItems: 'center' }}>
                                                        <StyledInput
                                                            key={i}
                                                            focus={true}
                                                            type="tel"
                                                            name={`[${index}].recipients.[${i}]`}
                                                            value={formik.values[index].recipients[i]}
                                                            error={ "false"}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <StyledButton
                                                            disabled={formik.values[index].recipients?.length === 1}
                                                            type="button"
                                                            btnStyle="redBtn mr-3"
                                                            onClick={() => arrayHelpers.remove(i)}
                                                        >
                                                            حذف
                                                        </StyledButton>
                                                    </div>
                                                ))}
                                                <StyledButton
                                                    type="button"
                                                    btnStyle="darkGreenBtn"
                                                    onClick={() => arrayHelpers.push("")}
                                                >
                                                    افزودن
                                                </StyledButton>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </MainContent>
                ))}

                            <div className="mx-4 mt-4 d-flex justify-content-between">
                                <StyledButton
                                    disabled={!formik.isValid}
                                    width="100%"
                                    btnStyle="greenBtn"
                                    type="submit"
                                >
                                    تایید
                                </StyledButton>
                            </div>
                        </StyledForm>
                    )}
                </Formik>



        </WrapperLayout>
    )
}

export default ProviderNotification;
