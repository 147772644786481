import Disconnected from "../../../Component/Icon/_Disconnected";
import React from "react";
import {useHistory} from "react-router-dom";
import {Loader} from "../../../Component/Shared/Loader";

export const ProviderList = (props) => {
    const history = useHistory();
    const {data} = props;
    const checkProvideState = (state) => {
        switch (state) {
            case 1 :
                return "healthy"
            case 2 :
                return 'unknown'
            case 3 :
                return 'unhealthy'
            default:
                return "unhealthy"
        }
    }

    return (
        <>
            {
                data ?
                    data.map((provider, index) =>
                        <div className='col-lg-3 col-md-6 col-12' key={provider[0].id}
                             onClick={() => history.push('/providers/state')}>
                            <div
                                className='providerCard'
                                onClick={() => {
                                }}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                        <h5 className="ml-2">{provider[0].name}</h5>
                                        {!provider[0].isAvailable && <Disconnected color="#333"/>}
                                    </div>
                                    <h5>{provider[0].prefix}</h5>
                                </div>
                                <div className="d-flex">
                                    <div className="flex-grow-1 mx-1">
                                        <div
                                            className={checkProvideState(provider[0].isAvailable ? provider[0].state : 3) + " inner-container d-flex flex-column justify-content-center align-items-center p-1"}>
                                            <h6>همراه اول</h6>
                                            <span
                                                style={{fontSize: '0.8rem'}}><b>{provider[0].delay}</b> ثانیه تاخیر</span>

                                        </div>
                                    </div>
                                    <div className="flex-grow-1 mx-1">
                                        <div
                                            className={checkProvideState(provider[1].isAvailable ? provider[1].state : 3) + " inner-container d-flex flex-column justify-content-center align-items-center p-1"}>
                                            <h6>ایرانسل</h6>
                                            <span
                                                style={{fontSize: '0.8rem'}}><b>{provider[1].delay}</b> ثانیه تاخیر</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    : <>
                        <Loader/>
                    </>
            }
        </>
    );
}
