import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Dropdown} from 'react-bootstrap';
import {getFilter, TableFilters} from './filters';
import ReactTooltip from 'react-tooltip';
import ColumnsGenerator from './config';
import {useHistory, useLocation} from 'react-router-dom';
import {ConvertQueryString} from '../../../Utility/Services/convert-query-string';
import {CheckBox} from '../../Kit/Checkbox';
import queryString from 'query-string';

const Styles = styled.div`
  .table-search span {
    width: 250px;
    height: 32px;
    text-indent: 10px;
    font-size: 0.9em;
    border-radius: 5px;
    border: 1px solid #cecece;
    display: flex;
    align-items: center;
  }

  .table-search span i {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-search input {
    width: calc(100% - 32px);
    height: 100%;
    font-size: 0.9em;
    border-radius: 5px;
    border: 0;
  }

  .table-search input:focus {
    outline: none;
  }

  table .number-col {
    width: 36px;
  }

  table th.select-col {
    width: 36px;
    padding: 15px 0.625em;

    .table-checkbox {
      margin: 0;

      label {
        margin: 0;
        justify-content: center;

        input {
          margin: 0;
        }
      }
    }
  }

  table td.select-col {
    width: 36px;

    .table-checkbox {
      margin: 0;

      label {
        margin: 0;
        justify-content: center;

        input {
          margin: 0;
        }
      }
    }
  }

  .dropdown-menu {
    width: 250px;
    text-align: right;
  }

  .filter-wrapper {
    display: flex;
    //flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px 0;
  }

  .filter-export__wrapper {
    display: flex;
    align-items: center;
  }

  .filter-row {
    select {
      width: 100%;
    }
  }

  .filter-button {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    color: #737373;
    border: 1px solid #cecece;
    margin-left: 5px;
  }

  .long-text {
    width: 100px;

    > span {
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      width: 100px !important;
      display: block;
    }

    .tooltip-size {
      width: 250px;

      p {
        color: #fff;
      }
    }
  }

  .filter-button:focus {
    box-shadow: none;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: right;
  }

  table thead {
    background: #ebefff;
    color: #6c6c6c;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
  }

  table tr {
    padding: 0.35em;
  }

  table th,
  table td {
    padding: 0.625em;
    text-align: center;
  }

  table th {
    font-size: 0.85em;
    padding: 15px 0;
  }

  table td {
    font-size: 0.75em;
  }

  table tbody {
    border-bottom: 1px solid #e3e3e3;
  }

  table tbody tr:nth-child(odd) {
    background-color: #fbfbfb;
  }

  .actions-wrapper ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    transition: all 500ms ease;
  }

  table tbody tr .actions-td {
    width: 125px;
  }

  .actions-wrapper ul li {
    cursor: pointer;
    transition: all 500ms ease;
    font-size: 1.35em;
    margin: 0 8px;
  }

  .actions-wrapper ul li.view-action:hover {
    color: #03a9f4;
  }

  .actions-wrapper ul li.delete-action:hover {
    color: #f44336;
  }

  .actions-wrapper ul li.edit-action:hover {
    color: #4caf50;
  }

  .pagination-row {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pagination-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pagination {
    display: flex;
    align-items: center;
  }

  select {
    font-size: 0.9em;
    width: 50px;
    border-radius: 5px;
    border: 1px solid #cecece;
    height: 32px;
    color: #626262;
  }

  .pagination .pagination-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin: 0 2px;
    font-size: 1.1em;
  }

  .pagination .paginate-row {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .pagination .paginate-row li {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin: 0 2px;
    font-size: 0.8em;
  }

  .pagination .paginate-row li.active {
    border-color: #6c7293;
    color: #fff;
    background: #6c7293;
  }

  .pagination .paginate-row li.page:hover {
    cursor: pointer;
    border-color: #6c7293;
    color: #6c7293;
  }

  .pagination .paginate-row li.page a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-row .count-show {
    font-size: 0.8em;
    margin: 0 5px;
  }

  @media screen and (max-width: 767px) {
    .long-text {
      width: 100%;

      > span {
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        width: 150px !important;
        display: block;
      }

      .tooltip-size {
        display: none !important;
      }
    }

    .pagination-row {
      display: flex;
      flex-direction: column;
      align-items: self-start;

      .pagination-col {
        justify-content: space-between;
        width: 100%;
      }

      .count-show {
        margin-top: 15px;
      }
    }

    table {
      border: 0;
    }

    table .number-col {
      width: 100%;
    }

    table caption {
      font-size: 1.3em;
    }

    table tr > th:not(.select-col){
      display: none;  
    }
    
    table thead {
      //border: none;
      //clip: rect(0 0 0 0);
      //height: 1px;
      //margin: -1px;
      //overflow: hidden;
      //padding: 0;
      //position: absolute;
      //width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;

      .actions-td {
        width: 100% !important;
      }
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    table td::before {
      content: attr(data-label);
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
  @media screen and (max-width: 500px) {
    .filter-wrapper {
      flex-direction: column;

      .filter-export__wrapper {
        justify-content: space-between;
        width: 100%;
      }

      .table-search {
        width: 100%;
        margin: 15px 0 0;

        > span {
          width: 100%;
        }

        input {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .pagination-row {
      align-items: center;

      .pagination-col {
        flex-direction: column;

        .pagination .paginate-row li {
          width: 26px;
          height: 26px;
        }

        .pagination .pagination-btn {
          width: 26px;
          height: 26px;
        }

        select {
          width: 100%;
          margin-top: 15px;
        }
      }
    }
  }

  .dropdown-item {
    &:active {
      background-color: #ffffff;
    }
  }

  .filter-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    li.filter-list__item {
      border: 1px solid #e4e4e4;
      font-size: 11px;
      padding: 5px 10px;
      margin-left: 5px;
      border-radius: 27px;
      cursor: pointer;
      margin-bottom: 10px;

      &:hover {
        border-color: #ff5a5a;
        background: #ff5a5a;
        color: #fff;
      }
    }
  }

  .export-menu {
    padding: 0;

    .export-item {
      border-bottom: 1px solid #e1e1e1;
      padding: 10px 15px;
      margin: 0;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: #f5f5f5;
      }

      &:active {
        color: #000;
      }
    }
  }
`;
const {useRef} = React;
let tableFilter = {};
let pageData = null

function Table({
                   data = [],
                   config,
                   actionDispatched,
                   pageConfig = {},
                   filterDispatch,
                   selectedDispatch,
                   exportDispatched,
               }) {
    const [rows, setRows] = useState([]);
    const columns = ColumnsGenerator(config.header);

    const actionsFn = (event, item, actionName) => {
        event.stopPropagation();
        let value = {
            data: item,
            actionName,
        };
        actionDispatched(value);
    };
    const exportHandler = (item) => {
        let value = {
            export: item,
        };
        exportDispatched(value);
    };

    const TdCellData = ({cell}) => {
        return (
            <>
                {Array.isArray(cell) ? (
                    cell.map((m, x) => (cell.length - 1 === x ? m : m + ' ، '))
                ) : (
                    <span dangerouslySetInnerHTML={{__html: cell}}/>
                )}
            </>
        );
    };
    const history = useHistory();
    const location = useLocation();
    const childRef = useRef();
    const isBool = true;

    const [selectAll, setSelectAll] = useState(false);
    const [defaultPageSize, setDefaultPageSize] = useState(0);
    const [defaultPhrase, setDefaultPhrase] = useState();
    const [pageOptions, setPageOptions] = useState([]);

    const openRow = (event, row) => {
        if (
            config.hasOwnProperty('actions') &&
            config.actions.hasOwnProperty('view')
        ) {
            if (config.actions['view'].hasOwnProperty('condition')) {
                if (config.actions['view'].condition(row)) {
                    actionsFn(event, row, 'view');
                }
            } else {
                actionsFn(event, row, 'view');
            }
        }
    };
    const cursorHandler = (row) => {
        if (
            config.hasOwnProperty('actions') &&
            config.actions.hasOwnProperty('view')
        ) {
            if (config.actions['view'].hasOwnProperty('condition')) {
                if (config.actions['view'].condition(row)) {
                    return true;
                }
            } else {
                return true;
            }
        }
        return false;
    };
    const tableChange = (mode, pageState, appliedFilter = false) => {
        if (mode) {
            tableFilter = {
                ...tableFilter,
                ...pageState,
            };
            for (const item of Object.keys(getFilter)) {
                tableFilter[item] = getFilter[item].defaultValue;
            }
            if (pageState) {
                for (const item of Object.keys(pageState)) {
                    tableFilter[item] = pageState[item];
                }
            }
            if (pageConfig.pageSize !== defaultPageSize && !tableFilter['pageSize']) {
                tableFilter['pageSize'] = pageConfig.pageSize;
            }
            if (defaultPhrase && tableFilter['phrase'] === undefined) {
                tableFilter['phrase'] = defaultPhrase;
            }
            setDefaultPhrase(tableFilter['phrase']);

            if (tableFilter['pageNumber'] === 1) {
                delete tableFilter['pageNumber'];
            }

            for (const tableFilterKey in tableFilter) {
                if (
                    tableFilter[tableFilterKey] === undefined ||
                    tableFilter[tableFilterKey] === null
                ) {
                    delete tableFilter[tableFilterKey];
                }
            }
            if (appliedFilter) {
                pageData = {};
                delete tableFilter['needCount'];

            } else {
                if (pageConfig.totalItemsCount) {
                    pageData = pageConfig
                }
                tableFilter['needCount'] = false
            }

            history.push({
                search: '?' + queryString.stringify({...tableFilter}),
            });
        } else {
            if (childRef.current) {
                childRef.current.resetFilters();
                history.push({
                    search: '?' + queryString.stringify({}),
                });
            }
        }
    };

    const removeFilter = (item) => {
        childRef.current.removeFilter('', item);
        tableFilter = {
            ...tableFilter,
        };
        if (tableFilter.hasOwnProperty('pageNumber')) {
            delete tableFilter['pageNumber'];
        }
        if (defaultPhrase && tableFilter['phrase'] === undefined) {
            tableFilter['phrase'] = defaultPhrase;
        }
        for (const item of Object.keys(getFilter)) {
            tableFilter[item] = getFilter[item].defaultValue;
        }
        delete tableFilter['needCount'];
        if (tableFilter.hasOwnProperty(item)) {
            delete tableFilter[item];
            history.push({
                search: '?' + queryString.stringify(tableFilter),
            });
            // filterDispatch(tableFilter);
        } else {
            history.push({
                search: '?' + queryString.stringify(tableFilter),
            });
            // filterDispatch(tableFilter);
        }
    };
    const headSelectHandler = (item) => {
        let page = [...rows];
        let selectedList = [];
        page.forEach((f) => {
            f.isSelected = item.target.checked;
            if (item.target.checked) {
                selectedList.push(f.original);
            } else {
                selectedList.length = 0;
            }
        });
        setRows([...page]);
        setSelectAll(item.target.checked);
        selectedDispatch(selectedList);
    };
    const rowSelectHandler = (item, id) => {
        let selectedList = [];
        let page = [...rows];
        page[id].isSelected = item.target.checked;
        page.forEach((f) => {
            if (f.isSelected) {
                selectedList.push(f.original);
            }
        });
        setRows([...page]);
        const selectedAll = page.every((e) => e.isSelected);
        const unselectedAll = page.every((e) => !e.isSelected);
        if (!selectedAll && !unselectedAll) {
            setSelectAll(null);
        } else {
            setSelectAll(selectedAll ? true : false);
        }
        selectedDispatch(selectedList);
    };
    const createTableRows = (list) => {
        const tableRows = [];
        if (list.length) {
            list.forEach((f) => {
                let currentObj = {};
                columns.forEach((col) => {
                    if (f.hasOwnProperty(col.accessor)) {
                        currentObj[col.accessor] = f[col.accessor];
                    }
                });
                tableRows.push({
                    cells: {...currentObj},
                    original: {...f},
                    id: f.id,
                    isSelected: false,
                });
            });
            setRows(tableRows);
        } else {
            if (rows.length) {
                setRows([]);
            }
        }
    };
    const createPaginationList = () => {
        if (pageConfig.hasOwnProperty('pageNumber')) {
            let pageList = [];
            for (let i = 0; i < (pageConfig.totalPagesCount ? pageConfig.totalPagesCount : pageData.totalPagesCount); i++) {
                pageList.push(i);
            }
            setPageOptions([...pageList]);
        }
    };
    useEffect(() => {
        createTableRows(data);
        if (data.length) {
            createPaginationList();
        }
        setSelectAll(false);
        let urlData = ConvertQueryString(location.search);
        if (!urlData['pageSize']) {
            setDefaultPageSize(pageConfig.pageSize);
        }
        if (urlData['phrase']) {
            setDefaultPhrase(urlData['phrase']);
        }
        if (config.mode && config.mode.includes('filter')) {
            if (
                urlData &&
                (urlData.pageNumber || urlData.pageSize || urlData.phrase)
            ) {
                delete urlData['pageNumber'];
                delete urlData['pageSize'];
                delete urlData['phrase'];
            }
            childRef.current.addFilters(urlData);
        }
        return () => {
            if (config.mode && config.mode.includes('filter')) {
                tableFilter = {
                    pageNumber: 1,
                };
                childRef.current.resetFilters();
            }
        };
    }, [data]);

    return (
        <>
            <Styles>
                <div className='filter-wrapper'>
                    {config.mode ? (
                        <div className='filter-export__wrapper'>
                            {config.mode.includes('filter') ? (
                                <Dropdown>
                                    <Dropdown.Toggle className='filter-button'>
                                        فیلتر
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu renderOnMount={isBool}>
                                        <TableFilters filtersList={config.filters} ref={childRef}/>
                                        <Dropdown.Item>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <button
                                                    className='btn btn-secondary ml-1'
                                                    onClick={() => {
                                                        tableChange(false);
                                                    }}
                                                >
                                                    ریست
                                                </button>
                                                <button
                                                    className='btn btn-primary'
                                                    onClick={() => {
                                                        tableChange(true, null, true);
                                                    }}
                                                >
                                                    تایید
                                                </button>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : null}
                            {config.mode.includes("export-excel") && (
                                <div className="export-wrapper">
                                    <button type="button" disabled={(data.length === 0)} className="btn filter-button"
                                            onClick={() => exportHandler('excel')}>
                                        Excel خروجی
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : null}
                    {config.mode ? (
                        config.mode.includes('search') ? (
                            <div className='table-search'>
                <span>
                  <i className='fas fa-search'/>
                  <input
                      placeholder={
                          config.placeholder && config.placeholder.length > 0
                              ? config.placeholder.map((x) => ` ${config.header[x]}`)
                              : 'جستجو'
                      }
                      onChange={(e) => {
                          e.persist();
                          setTimeout(() => {
                              tableChange(true, {
                                  phrase: e.target.value,
                                  pageNumber: 1,
                              }, true);
                          }, 1000);
                      }}
                  />
                </span>
                            </div>
                        ) : null
                    ) : null}
                </div>
                <div>
                    {config.mode && config.mode.includes('filter') ? (
                        <ul className='filter-list'>
                            {Object.keys(getFilter).map((item, index) => (
                                getFilter[item].title &&
                                <li
                                    className='filter-list__item'
                                    onClick={() => removeFilter(item)}
                                    key={index}
                                >
                                    {getFilter[item].title}
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </div>
                <table>
                    {config && (
                        <>
                            <thead>
                            <tr>
                                {config.hasSelectCol ? (
                                    <th className='select-col'>
                                        <CheckBox className={'table-checkbox'}>
                                            <label>
                                                <input
                                                    type='checkbox'
                                                    name='table-select-col'
                                                    className={
                                                        selectAll === null ? 'null-checkBox' : ''
                                                    }
                                                    onChange={(e) => headSelectHandler(e)}
                                                    disabled={!data.length}
                                                    checked={selectAll}
                                                />
                                            </label>
                                        </CheckBox>
                                    </th>
                                ) : null}
                                <th className='number-col'>#</th>

                                {columns.length &&
                                    columns.map((headTitle) => (
                                        <th key={headTitle.accessor}>{headTitle.header}</th>
                                    ))}
                                {config.actions ? <th></th> : null}
                            </tr>
                            </thead>
                            {rows && rows.length ? (
                                <tbody>
                                {rows.map((row, i) => {
                                    return (
                                        <tr
                                            style={
                                                config &&
                                                config.rowColor &&
                                                config.rowColor.condition(row.original)
                                                    ? {background: config.rowColor.color}
                                                    : {}
                                            }
                                            className={
                                                cursorHandler(row.original) ? 'pointer' : null
                                            }
                                            onClick={(event) => openRow(event, row.original)}
                                            key={`row-${i}`}
                                        >
                                            {config.hasSelectCol ? (
                                                <td
                                                    className='select-col'
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    <CheckBox className={'table-checkbox'}>
                                                        <label>
                                                            <input
                                                                type='checkbox'
                                                                name={`select-row`}
                                                                id={`select-row-${i + 1}`}
                                                                checked={row.isSelected}
                                                                onChange={(e) => rowSelectHandler(e, i)}
                                                                onClick={(event) => event.stopPropagation()}
                                                            />
                                                        </label>
                                                    </CheckBox>
                                                </td>
                                            ) : null}

                                            {Object.keys(pageConfig).length !== 0 ? (
                                                <td
                                                    className='number-col'
                                                    data-label='#'
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    {pageConfig.pageSize * pageConfig.pageNumber +
                                                        (i + 1) -
                                                        pageConfig.pageSize}
                                                </td>
                                            ) : (
                                                <td
                                                    style={{width: '36px'}}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    {i + 1}
                                                </td>
                                            )}
                                            {Object.keys(row.cells).map((cell) =>
                                                    config.tooltipTitle ? (
                                                        config.tooltipTitle.includes(cell) ? (
                                                            <td
                                                                data-label={config.header[cell]}
                                                                className='long-text'
                                                                key={`${cell}-${i}`}
                                                            >
                                <span data-tip data-for={`${cell}-${i}`}>
                                  <TdCellData cell={row.cells[cell]}/>
                                </span>
                                                                <ReactTooltip
                                                                    className='tooltip-size'
                                                                    id={`${cell}-${i}`}
                                                                    aria-haspopup='true'
                                                                    role='example'
                                                                >
                                                                    <p>
                                                                        <TdCellData cell={row.cells[cell]}/>
                                                                    </p>
                                                                </ReactTooltip>
                                                            </td>
                                                        ) : (
                                                            <td
                                                                data-label={config.header[cell]}
                                                                key={`${cell}-${i}`}
                                                            >
                                                                <TdCellData cell={row.cells[cell]}/>
                                                            </td>
                                                        )
                                                    ) : (
                                                        <td
                                                            data-label={config.header[cell]}
                                                            key={`${cell}-${i}`}
                                                        >
                                                            <TdCellData cell={row.cells[cell]}/>
                                                        </td>
                                                    )
                                            )}
                                            {config.actions ? (
                                                <td className='actions-td'>
                                                    <div className='actions-wrapper'>
                                                        <ul>
                                                            {Object.keys(config.actions).map((item, i) =>
                                                                config.actions[item].hasOwnProperty(
                                                                    'condition'
                                                                ) ? (
                                                                    config.actions[item].condition(
                                                                        row.original
                                                                    ) ? (
                                                                        <li
                                                                            key={i}
                                                                            onClick={(event) =>
                                                                                actionsFn(event, row.original, item)
                                                                            }
                                                                            className={`${item}-action`}
                                                                        >
                                                                            <i
                                                                                className={config.actions[item].icon}
                                                                            />
                                                                        </li>
                                                                    ) : null
                                                                ) : (
                                                                    <li
                                                                        key={i}
                                                                        onClick={(event) =>
                                                                            actionsFn(event, row.original, item)
                                                                        }
                                                                        className={`${item}-action`}
                                                                    >
                                                                        <i
                                                                            className={config.actions[item].icon}
                                                                        />
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </td>
                                            ) : null}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            ) : (
                                <tbody>
                                <tr>
                                    <td colSpan={Object.keys(config.header).length + 1}>
                                        آیتمی جهت نمایش وجود ندارد.
                                    </td>
                                </tr>
                                </tbody>
                            )}
                        </>
                    )}
                </table>
                {Object.keys(pageConfig).length !== 0 && data.length ? (
                    <div className='pagination-row'>
                        <div className='pagination-col'>
                            <div className='pagination'>
                                <button
                                    onClick={() => tableChange(true, {pageNumber: 1})}
                                    disabled={pageConfig.pageNumber === 1}
                                    className='pagination-btn'
                                >
                                    {<i className='far fa-angle-double-right'/>}
                                </button>
                                {' '}
                                <button
                                    onClick={() =>
                                        tableChange(true, {pageNumber: pageConfig.pageNumber - 1})
                                    }
                                    disabled={pageConfig.pageNumber === 1}
                                    className='pagination-btn'
                                >
                                    <i className='far fa-angle-right'/>
                                </button>
                                {' '}
                                {(pageConfig.totalPagesCount ? pageConfig.totalPagesCount : pageData.totalPagesCount) > 5 ? (
                                    <ul className='paginate-row'>
                                        {pageConfig.pageNumber > 2 &&
                                        pageConfig.pageNumber <= (pageConfig.totalPagesCount ? pageConfig.totalPagesCount : pageData.totalPagesCount)
                                            ? pageOptions.map((count, index) =>
                                                index >= pageConfig.pageNumber - 3 &&
                                                index <= pageConfig.pageNumber + 1 ? (
                                                    <li
                                                        key={index}
                                                        className={
                                                            pageConfig.pageNumber === count + 1
                                                                ? 'active'
                                                                : 'page'
                                                        }
                                                    >
                                                        {pageConfig.pageNumber === count + 1 ? (
                                                            <span>{count + 1}</span>
                                                        ) : (
                                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                            <a
                                                                onClick={() =>
                                                                    tableChange(true, {pageNumber: count + 1})
                                                                }
                                                            >
                                                                {' '}
                                                                {count + 1}
                                                            </a>
                                                        )}
                                                    </li>
                                                ) : null
                                            )
                                            : pageOptions.map((count, index) =>
                                                index < 5 ? (
                                                    <li
                                                        key={index}
                                                        className={
                                                            pageConfig.pageNumber === count + 1
                                                                ? 'active'
                                                                : 'page'
                                                        }
                                                    >
                                                        {pageConfig.pageNumber === count + 1 ? (
                                                            <span>{count + 1}</span>
                                                        ) : (
                                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                            <a
                                                                onClick={() =>
                                                                    tableChange(true, {pageNumber: count + 1})
                                                                }
                                                            >
                                                                {' '}
                                                                {count + 1}
                                                            </a>
                                                        )}
                                                    </li>
                                                ) : null
                                            )}
                                    </ul>
                                ) : (
                                    <ul className='paginate-row'>
                                        {pageOptions.map((count, index) => (
                                            <li
                                                key={index}
                                                className={
                                                    pageConfig.pageNumber === count + 1
                                                        ? 'active'
                                                        : 'page'
                                                }
                                            >
                                                {pageConfig.pageNumber === count + 1 ? (
                                                    <span>{count + 1}</span>
                                                ) : (
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a
                                                        onClick={() =>
                                                            tableChange(true, {pageNumber: count + 1})
                                                        }
                                                    >
                                                        {' '}
                                                        {count + 1}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <button
                                    onClick={() =>
                                        tableChange(true, {pageNumber: pageConfig.pageNumber + 1})
                                    }
                                    disabled={
                                        pageConfig.pageNumber === (pageConfig.totalPagesCount ? pageConfig.totalPagesCount : pageData.totalPagesCount)
                                    }
                                    className='pagination-btn'
                                >
                                    <i className='far fa-angle-left'/>
                                </button>
                                {' '}
                                <button
                                    onClick={() =>
                                        tableChange(true, {
                                            pageNumber: (pageConfig.totalPagesCount ? pageConfig.totalPagesCount : pageData.totalPagesCount),
                                        })
                                    }
                                    disabled={
                                        pageConfig.pageNumber === (pageConfig.totalPagesCount ? pageConfig.totalPagesCount : pageData.totalPagesCount)
                                    }
                                    className='pagination-btn'
                                >
                                    <i className='far fa-angle-double-left'/>
                                </button>
                            </div>
                            <select
                                value={pageConfig.pageSize}
                                onChange={(e) => {
                                    tableChange(true, {
                                        pageNumber: 1,
                                        pageSize: Number(e.target.value),
                                    }, true);
                                }}
                            >
                                {[10, 20, 50, 100].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {isNaN(pageConfig.pageNumber) ? null : (
                            <span className='count-show'>
                {pageConfig.totalItemsCount ? pageConfig.totalItemsCount : pageData.totalItemsCount} رکورد | صفحه{' '}
                                {pageConfig.pageNumber} از {pageConfig.totalPagesCount ? pageConfig.totalPagesCount : pageData.totalPagesCount}
              </span>
                        )}
                    </div>
                ) : null}
            </Styles>
        </>
    );
}

export default Table;
