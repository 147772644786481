import React, {useEffect, useState} from 'react';
import DatePicker, {DateObject} from "react-multi-date-picker";
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import {FormError} from '../../Kit/Form';
import {DatePickerContainer} from '../DatePicker';

export const TimePickerStyled = (props) => {
    const {defaultValue, name, withoutSecond = false} = props;
    const [timePickerValue, setTimePickerValue] = useState('');

    const changeHandler = (event) => {
        if (event) {
            let result = event.format(withoutSecond ? 'HH:mm' : 'HH:mm:ss');
            let baseFormat = "";
            result.split(":").map((m) => {
                baseFormat = baseFormat + m;
                return m
            });
            event.baseFormat = baseFormat;
            setTimePickerValue(event);
            props.value(event);
        }
        // if (event === null) setTimePickerValue(null)
    };

    const removeHandler = () => {
        setTimePickerValue(undefined)
        props.value('');
    }

    useEffect(() => {
        if (defaultValue) {
            const defaultVal = defaultValue.toString();
            const valueObj = new DateObject().set({
                hour: defaultVal.slice(0, 2),
                minute: defaultVal.slice(2, 4),
            })
            setTimePickerValue(valueObj)
        } else {
            setTimePickerValue(null);
        }
    }, [defaultValue])


    return (
        <>
            <DatePickerContainer>
                <DatePicker
                    name={name}
                    value={timePickerValue}
                    onChange={changeHandler}
                    disableDayPicker
                    format={withoutSecond ? "HH:mm" : "HH:mm:ss"}
                    plugins={[
                        <TimePicker hideSeconds={withoutSecond} position="bottom"/>
                    ]}
                />
                <button
                    className="clear-date-picker"
                    type="button"
                    onClick={() => removeHandler()}
                >
                    <i className="fal fa-times"/>
                </button>
            </DatePickerContainer>
            <FormError show={props.error}>{props.error} </FormError>
        </>
    )
}

export {TimePicker};

