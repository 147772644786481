import React, {useEffect, useState} from 'react';
import Modal from '../../../../Component/Kit/Modal';
import UserDeactivationReasonService from '../../../../Utility/Services/UserDeactivationReasonService';
import {UserInformationItem, UserInformationWrapper} from './style';
import DateConvert from '../../../../Utility/Services/date-convert';
import EnumFlag from "../../../../Utility/Services/enum-flag";
import getUserIncompleteFields from "../Services/user-incomplete-fields";
import {StyledButton} from "../../../../Component/Kit/Button";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import {toast} from "react-toastify";
import {useHistory, useLocation, useParams} from "react-router-dom";


const UserInformation = ({userData, cityItem, provinceItem}) => {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const [userInformationData, setuserInformationData] = useState();
    const [gazette, setGazette] = useState(false);
    const [nationalCard, setNationalCard] = useState(false);
    const [loadingApproveButt, setLoadingApproveButt] = useState(false);
    useEffect(() => {
        if (userData.provinceId && provinceItem.length > 0 && cityItem.length > 0) {
            const province = provinceItem.find((item) => item.value === userData.provinceId);
            userData.provinceName = province ? province.name : '';
            const city = cityItem.find((item) => item.value === userData.cityId);
            userData.cityName = city ? city.name : '';
            userData.isVIPName = userData.isVIP ? 'VIP' : '-';
            userData.birthDateTime = DateConvert(userData.birthDate);
            userData.completionRequirement = getUserIncompleteFields(EnumFlag([1, 2, 4, 8, 16], userData.completionRequirement));
            setuserInformationData(userData)
        }

    }, [provinceItem, cityItem, userData])

    const userInformationTitle = {
        firstName: "نام",
        lastName: "نام خانوادگی",
        fatherName: "نام پدر",
        mobile: "شماره همراه",
        userName: "نام کاربری",
        nationalId: "کدملی",
        certificateNo: "شماره شناسنامه",
        birthDateTime: "تاریخ تولد",
        isVIPName: "کاربر ویژه",
        isMaleName: "جنسیت",
        email: "ایمیل",
        isLegalName: "نوع حساب",
        createdAt: "تاریخ ایجاد حساب",

        additionalMobile: "شماره همراه دوم",
        phoneNumber: "تلفن",
        provinceName: "استان",
        cityName: "شهر",

        balance: "مقدار شارژ",
        isActiveName: "وضعیت حساب",
        isSuspendedName: "معلق شدن",
        companyActivityFieldName: "زمینه فعالیت",
        postalCode: "کدپستی",
        avenue: "خیابان",
        alley: "کوچه",
        plaque: "پلاک",
    }

    const userInformationLegalTitle = {
        companyName: "نام شرکت",
        economicCode: "کداقتصادی",
        companyNationalId: "شناسه ملی",
        registrationNumber: "شماره ثبت",
        companyTypeName: "نوع شرکت",
    }


    const userApprove = (boolValue) => {
        setLoadingApproveButt(true);
        ApiClient.Patch(Urls.userApproveInformation(params.id), JSON.stringify(boolValue))
            .then(() => {
                toast.success('تغییرات  با موفقیت ثبت شد.');
                setLoadingApproveButt(false);
                history.push(`/users/verify-info`, {
                    from: location.search,
                    someOtherProp: '',
                });
            }).catch(() => {
            toast.error('دوباره سعی کنید.');
        })
    }
    return (
        <>
            {userInformationData && (
                <>
                    {userInformationData.isActive === false && (
                        <div className="col-12 wrapper-box">
                            <div className="alert alert-warning m-2 py-3" role="alert">
                                <p className="m-0">کاربر اکانت خود را به علت
                                    <strong>
                                        &nbsp;{UserDeactivationReasonService(userInformationData.deactivationReason)} &nbsp;
                                    </strong>
                                    غیرفعال کرده است .
                                </p>
                                {userInformationData.deactivationReasonDescription && (
                                    <p className="mt-3 mb-0">
                                        <h6><strong>علت این تصمیم:</strong></h6>
                                        <span>
                                            کاربر اکانت خود را به علت کاربر اکانت خود را به علت کاربر اکانت خود را به علت کاربر اکانت خود را به علت کاربر اکانت خود را به علت
                                            {userInformationData.deactivationReasonDescription}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    {
                        (userInformationData?.state === 2 || userInformationData?.state === 3)
                        &&
                        <div className="col-12 wrapper-box d-flex flex-wrap px-0">


                            <div className='col-12 col-lg-6 col-xl-4 pr-3 pl-3 pl-lg-0 pl-xl-0'>
                                <UserInformationWrapper>
                                    <UserInformationItem
                                        className='col-100'
                                        label={'نواقص'}
                                        dataItem={
                                            userInformationData['completionRequirement'].map((item) => (userInformationTitle[item])).join("  ")
                                        }/>
                                </UserInformationWrapper>
                            </div>

                            {userInformationData?.state === 2 &&

                                <div className="col-12 col-lg-6 col-xl-8 pl-3 pr-0">
                                    <div className="d-flex justify-content-end flex-wrap pl-2">
                                        <div className=" my-1 my-sm-0 ml-sm-2 ">
                                            <StyledButton
                                                btnStyle="redBtn"
                                                onClick={() => userApprove(false)}
                                                disabled={loadingApproveButt}
                                            >
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className="ml-2">عدم تایید</span>
                                                    <i className="far fa-times"/>
                                                </div>
                                            </StyledButton>
                                        </div>
                                        <div className="my-1 my-sm-0 mr-3 mr-sm-0">
                                            <StyledButton
                                                btnStyle="greenBtn"
                                                onClick={() => userApprove(true)}
                                                disabled={loadingApproveButt}
                                            >
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className="ml-2">تایید</span>
                                                    <i className="far fa-check"/>
                                                </div>
                                            </StyledButton>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    }


                    <div className="col-12 wrapper-box">
                        <UserInformationWrapper>
                            {Object.entries(userInformationTitle).map(([key, value]) => (
                                <UserInformationItem label={value} dataItem={userInformationData[key]} key={key}/>
                            ))}

                            {userInformationData.legalData &&
                                Object.entries(userInformationLegalTitle).map(([key, value]) => (
                                    <UserInformationItem label={value} dataItem={userInformationData.legalData[key]}
                                                         key={key}/>
                                ))
                            }

                            <UserInformationItem label="نشانی" dataItem={userInformationData.address}
                                                 className="col-100"/>

                            {userInformationData.advertisingCampaignName &&
                                <UserInformationItem label="کمپین"
                                                     dataItem={userInformationData.advertisingCampaignName}
                                                     className="col-100"/>
                            }


                        </UserInformationWrapper>
                    </div>

                    <div className="col-12 wrapper-box">
                        <div className="row">
                            {userInformationData.nationalCardUploadedURL ? (
                                <div className="col-12 col-md-6 mb-4">
                                    <div
                                        className="user-image-wrapper"
                                        onClick={() => setNationalCard(true)}
                                    >
                                        <img
                                            src={userInformationData.nationalCardUploadedURL}
                                            alt="nationalCard"
                                        />
                                    </div>
                                    <Modal
                                        onClose={() => setNationalCard(false)}
                                        visible={nationalCard}
                                        headerTitle="اطلاعات هویتی"
                                    >
                                        <div className="modal-image-wrapper">
                                            <img
                                                src={userInformationData.nationalCardUploadedURL}
                                                alt="nationalCard"
                                            />
                                        </div>
                                    </Modal>
                                </div>
                            ) : (
                                <div className="col-6">
                                    <div
                                        className="alert alert-dark text-right d-flex align-items-center"
                                        role="alert"
                                    >
                                        <i className="fa fa-exclamation-circle ml-2"></i>
                                        <span className="small"> تصویری جهت نمایش وجود ندارد</span>
                                    </div>
                                </div>
                            )}
                            {userInformationData.isLegal ? (
                                <div className="col-12 col-md-6">
                                    <div
                                        className="user-image-wrapper"
                                        onClick={() => setGazette(true)}
                                    >
                                        <img src={userInformationData.gazetteUploadedURL} alt="gazette"/>
                                    </div>
                                    <Modal
                                        onClose={() => setGazette(false)}
                                        visible={gazette}
                                        headerTitle="روزنامه رسمی"
                                    >
                                        <div className="modal-image-wrapper">
                                            <img src={userInformationData.gazetteUploadedURL} alt="gazette"/>
                                        </div>
                                    </Modal>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default UserInformation
