import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import UploadIcon from "../../../../Component/Icon/UploadIcon";
import { StyledButton } from "../../../../Component/Kit/Button";
import { StyledForm, StyledInput, StyledInputUpload, StyledSelect, } from "../../../../Component/Kit/Form";
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout, } from "../../../../Component/Kit/Layout";
import BackToPage from "../../../../Component/Kit/back";

import Modal from "../../../../Component/Kit/Modal";
import { FileNamePreview, ImageBox, UploadConatiner, UploadTitle, } from "../../../../Component/Kit/UploadZone";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { CompanyTypeList } from "../../../../Utility/Model/company.model";
import { theme } from "../../../../Utility/Theme";
import { ConvertUserTypeSchema } from "../../../../Utility/Validation/Schema/ConvertUserTypeSchema";
import EditUserInvoiceModal from "../EditUserInvoiceModal";
import Swal from "sweetalert2";

const ConvertUserType = ({ userProfileData }) => {
  const [uploadPreview, setUploadPreview] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [invoiceInformation, setInvoiceInformation] = useState(null);

  let match = useRouteMatch();
  const history = useHistory();

  const closeModal = () => {
    setInvoiceModal(false);
    history.push(`/userDetail/${match.params.id}/profile`);
    toast.success("تغییرات  با موفقیت ثبت شد.");
  };

  const invoiceHandler = (result) => {
    if (result) {
      ApiClient.Patch(Urls.modifyUserInvoice(match.params.id), result).then(
        (res) => {
          closeModal();
        }
      );
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (Object.keys(userProfileData).length === 0) {
      history.push(`/userDetail/${match.params.id}/profile`);
    }
  }, [userProfileData, history, match.params.id]);

  const handleOnBlur = (e) => {
    if (e.target.files[0] && e.target.length !== 0) {
      const file = e.target.files[0];
      setUploadPreview(URL.createObjectURL(file));
      setUploadFile(file);
    }
  };

  return (
    <WrapperLayout>
      <div className="c-wrapper">
        <HeaderContent className="justify-content-between">
          <span className="fakeContainer"></span>
          <BackToPage toPage={`/userDetail/${match.params.id}/profile`} />
        </HeaderContent>
      </div>

      <MainContent>
        <div clas="row">
          <div className="col-12">
            <PageTitleLayout>
              <h5>{userProfileData.isLegal ? 'تبدیل حقوقی کاربر به حقیقی' : 'تبدیل کاربر حقیقی به حقوقی'}</h5>
              <span>
                {!userProfileData.isLegal &&
                  <p className="text-right"> نوع حساب کاربر حقیقی می‌باشد و برای تبدیل آن به حقوقی نیاز به وارد کردن اطلاعات شرکت است. </p>
                }
              </span>
            </PageTitleLayout>
            <hr class="mt-4 mx-2" />
          </div>
        </div>

        <div>
          <Formik
            initialValues={{
              isLegal: "",
              companyName: "",
              registrationNumber: "",
              nationalIdCompany: "",
              companyType: "",
              gazetteUploadedFile: "",
            }}
            validationSchema={
              userProfileData.isLegal ? null : ConvertUserTypeSchema
            }
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              Swal.fire({
                title: `آیا مطمئن هستید؟`,
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "انصراف",
                cancelButtonColor: "#d33",
                confirmButtonText: "بله، مطمئن هستم",
              }).then(result => {
                if (result.isConfirmed) {
                  const formData = new FormData();
                  formData.append("IsLegal", !userProfileData.isLegal);
                  if (!userProfileData.isLegal) {
                    formData.append("CompanyName", values.companyName);
                    formData.append(
                      "RegistrationNumber",
                      values.registrationNumber
                    );
                    formData.append("NationalId", values.nationalIdCompany);
                    formData.append("CompanyType", values.companyType);
                    formData.append(
                      "GazetteUploadedFile",
                      values.gazetteUploadedFile
                    );
                  }

                  ApiClient.Put(Urls.transformUsers(match.params.id), formData)
                    .then((res) => {
                      actions.setSubmitting(false);
                      if (Object.entries(res.result.userInvoices).length > 0) {
                        setInvoiceInformation(res.result);
                        setInvoiceModal(true);
                      } else {
                        toast.success("تغییرات  با موفقیت ثبت شد.");
                        history.push(`/userDetail/${match.params.id}/profile`);
                      }
                      actions.resetForm();
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      actions.resetForm();
                    });
                } else {
                  actions.resetForm()
                }
              })


            }}
          >
            {(formik) => (
              <StyledForm
                onSubmit={formik.handleSubmit}
                onBlur={formik.handleBlur}
                onReset={formik.handleReset}
              >
                {userProfileData.isLegal && (
                  <div
                    className="alert alert-primary d-flex align-items-center"
                    role="alert"
                  >
                    <i className="fa fa-exclamation-circle ml-2" />
                    <span>
                      {" "}
                      نوع حساب کاربر حقوقی می باشد و برای تبدیل آن به حقیقی نیاز
                      به وارد کردن اطلاعات جدیدی نیست
                    </span>
                  </div>
                )}

                {!userProfileData.isLegal && (
                  <div>
                    <div className="row text-right">
                      <StyledInput
                        formGroupClass="col-lg-6 col-md-6 col-12"
                        lable="نام شرکت"
                        focus={true}
                        placeholder="لطفا نام شرکت خود را وارد کنید:"
                        name="companyName"
                        value={formik.values.companyName}
                        error={
                          formik.errors.companyName &&
                            formik.touched.companyName
                            ? formik.errors.companyName
                            : "false"
                        }
                        onChange={(e) =>
                          formik.setFieldValue("companyName", e.target.value)
                        }
                      />

                      <StyledInput
                        formGroupClass="col-lg-6 col-md-6 col-12"
                        lable="شماره ثبت"
                        focus={true}
                        placeholder="لطفا شماره ثبت خود را وارد کنید:"
                        name="registrationNumber"
                        value={formik.values.registrationNumber}
                        error={
                          formik.errors.registrationNumber &&
                            formik.touched.registrationNumber
                            ? formik.errors.registrationNumber
                            : "false"
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            "registrationNumber",
                            e.target.value
                          )
                        }
                      />
                    </div>

                    <div className="row text-right">
                      <StyledInput
                        formGroupClass="col-lg-6 col-md-6 col-12"
                        lable="شناسه ملی"
                        focus={true}
                        placeholder="لطفا شناسه ملی خود را وارد کنید:"
                        name="nationalIdCompany"
                        value={formik.values.nationalIdCompany}
                        error={
                          formik.errors.nationalIdCompany &&
                            formik.touched.nationalIdCompany
                            ? formik.errors.nationalIdCompany
                            : "false"
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            "nationalIdCompany",
                            e.target.value
                          )
                        }
                      />

                      <StyledSelect
                          formGroupClass="col-lg-6 col-md-6 col-12"
                          optionItems={[
                            { value: null, name: "انتخاب کنید" },
                            ...CompanyTypeList,
                          ]}
                          lable="نوع شرکت"
                          focus={true}
                          name="companyType"
                          value={formik.values.companyType}
                          error={
                            formik.errors.companyType &&
                            formik.touched.companyType
                                ? formik.errors.companyType
                                : "false"
                          }
                          onChange={(e) =>
                              formik.setFieldValue("companyType", e.target.value)
                          }
                      />
                    </div>
                    <div className="row text-right">
                      <div className="col-12 text-right">
                        <label>آپلود روزنامه رسمی</label>
                        <UploadConatiner>
                          <ImageBox>
                            {
                              uploadPreview === null ? (
                                <>
                                  <UploadIcon fill={theme.paleGrey} />
                                  <UploadTitle>
                                    فایل های پشتیبانی : jpeg, jpg, JPG, png
                                  </UploadTitle>
                                </>
                              ) : (
                                <FileNamePreview>
                                  {uploadFile.name && uploadFile.name}
                                </FileNamePreview>
                              )
                              // <ImagePreview src={uploadPreview} className="ImagePreview" />
                            }
                          </ImageBox>

                          <StyledInputUpload
                            hasurl={uploadPreview}
                            placeholder="آپلود فایل"
                            type="file"
                            name="gazetteUploadedFile"
                            accept="image/*"
                            error={
                              formik.errors.gazetteUploadedFile &&
                                formik.values.gazetteUploadedFile
                                ? formik.errors.gazetteUploadedFile
                                : "false"
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                "gazetteUploadedFile",
                                e.target.files[0]
                              );
                              handleOnBlur(e);
                            }}
                          />
                        </UploadConatiner>
                      </div>
                    </div>
                  </div>
                )}
                <div className="text-left">
                  <StyledButton btnStyle="greenBtn mt-3"
                    type="submit">
                    {userProfileData.isLegal ? 'تبدیل به حقیقی' : 'تبدیل به حقوقی'}
                  </StyledButton>
                </div>
              </StyledForm>
            )}
          </Formik>
        </div>
      </MainContent>
      <Modal
        onClose={() => closeModal()}
        onCancel={() => closeModal()}
        visible={invoiceModal}
        headerTitle="فاکتور های کاربر"
      >
        <EditUserInvoiceModal
          data={invoiceInformation}
          onCloseModal={() => closeModal()}
          confirmModal={(e) => invoiceHandler(e)}
        />
      </Modal>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => ({
  userProfileData: state.UserProfileDataReducer,
});
export default connect(mapStateToProps)(ConvertUserType);
