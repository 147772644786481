import React, {useEffect, useState} from 'react'
import Modal from "../../../../../Component/Kit/Modal";
import {Formik} from "formik";
import {FormGroup, FormLable, StyledCheckBox, StyledForm, StyledInput,} from "../../../../../Component/Kit/Form";
import {StyledButton} from "../../../../../Component/Kit/Button";
import {MyDatePicker} from "../../../../../Component/Shared/DatePicker";
import {NumberingInvoiceSchema} from "../../../../../Utility/Validation/Schema/NumberingInvoiceSchema";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";
import {toast} from "react-toastify";
import {ConvertQueryString} from "../../../../../Utility/Services/convert-query-string";
import {useLocation} from "react-router-dom";


export function InvoiceNumberingGroupModal(props){
    const {visible , closeModal , onCancel, selectedInvoice , submit , isOldPanel}  = props;
    const location = useLocation();
    return (
        <Modal
            visible={visible}
            onClose={() => closeModal()}
            onCancel={onCancel}
            headerTitle={"شماره دار کردن گروهی فاکتور ها"}
        >

            {selectedInvoice.length &&

                <Formik
                    initialValues={{
                        number: '',
                        date: '',
                        sendSms: false
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        const params  = {};
                        let urlData = ConvertQueryString(location.search);
                        if (urlData['onlyReturns'] == true) {
                            params.isReturn = true
                        }
                        if (isOldPanel && values.sendSms){
                            params.sendLinkViaSms = true
                        }

                        const formValues = selectedInvoice.map((a , i) => {
                            if (isOldPanel){
                                return {
                                    invoiceId: a.id,
                                    number: (Number(values.number) + i).toString(),
                                    date: Number(values.date),
                                }
                            }else{
                                return {
                                    id: a.id,
                                    number: (Number(values.number) + i).toString(),
                                    date: Number(values.date),
                                }
                            }

                        })
                        ApiClient.Put( Urls.GroupNumberedPanelInvoice(isOldPanel) , {
                            sendSms: values.sendSms,
                            invoices: formValues
                        }  , params).then(() => {
                            toast.success('فاکتور ها شماره دار و در سپیدار ثبت شدند .');
                            submit()
                        })
                    }}
                    validationSchema={NumberingInvoiceSchema}
                >
                    {(formik) => (
                        <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                            <div className="d-flex mb-3">
                                <span className="ml-2">شناسه فاکتور های :</span>
                                {selectedInvoice.map((a, i) => (
                                    <span className="ml-1">{a.id}
                                        {selectedInvoice.length !== i +  1 &&  ','}
                                       </span>
                                ))}
                            </div>
                                <StyledInput
                                    lable="شماره فاکتور"
                                    type="text"
                                    focus={true}
                                    name={`number`}
                                    value={formik.values.number}
                                    error={(formik.errors.number && formik.touched.number)
                                        ? formik.errors.number
                                        : 'false'
                                    }
                                    onChange={(e) => formik.setFieldValue(`number`, e.target.value)} />

                                <FormGroup>
                                    <FormLable show={true}>تاریخ فاکتور</FormLable>
                                    <MyDatePicker
                                        defaultValue={formik.values.date}
                                        value={(event) => {
                                            formik.setFieldValue(`date`, event.baseFormat);
                                        }}
                                        name={`date`}
                                        error={
                                            formik.errors.date !== undefined
                                                ? formik.errors.date
                                                : false
                                        }
                                    />
                                </FormGroup>

                                <StyledCheckBox
                                    formGroupClass="mt-3"
                                    lable="ارسال پیامک"
                                    focus={true}
                                    name="sendSms"
                                    id="sendSms"
                                    checked={formik.values.sendSms}
                                    onChange={(e) =>    formik.setFieldValue(`sendSms`, e.target.checked)}
                                />


                            <StyledButton
                                width="100%"
                                btnStyle="darkGreenBtn"
                                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting }
                                loading={formik.isSubmitting ? 1 : 0}
                                // loading={btnLoading ? 1 : 0}
                                type="submit"
                            >
                                تایید
                            </StyledButton>
                        </StyledForm>
                    )}
                </Formik>

            }
        </Modal>
    )
}
