import { CompanyActivityList, CompanyTypeList } from "../Model/company.model";
function CompanyActivity(value) {
  let result = CompanyActivityList.find((item) => item.value === value);
  return result.name;
}

function CompanyType(value) {
  let result = CompanyTypeList.find((item) => item.value === value);
  return result.name;
}

export { CompanyActivity, CompanyType };
