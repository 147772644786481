import React from 'react'
import {Image} from 'react-bootstrap';
import DateConvert from '../../../../Utility/Services/date-convert';
import UserLogo from "../../../../Assets/images/user.jpg";
import {Link} from "react-router-dom";
import {RoleCanActivate} from "../../../../Utility/Services/roleCanActivate";
import {connect} from "react-redux";

const CommentItem = (props) => {
    const {CItem, userId, deleteConfirm, adminProfile} = props;


    return (
        <div className={`mb-2 ${CItem.isAdmin ? 'admin' : 'user'}`}>

            <div className="ticket-detail__profile-pic">
                {!CItem.isAdmin
                    ?
                    <Link to={`/userDetail/${userId}/profile`} target="_blank">
                        {
                            CItem.authorImageUrl
                                ?
                                <Image src={CItem.authorImageUrl}/>
                                :
                                <img src={UserLogo} alt={CItem.authorName}/>
                        }
                    </Link>
                    :
                    CItem.authorImageUrl
                        ?
                        <Image src={CItem.authorImageUrl}/>
                        :
                        <img src={UserLogo} alt={CItem.authorName}/>
                }

            </div>

            <div className="ticket-detail__comment position-relative">

                <div className="ticket-text position-relative pt-3">
                    {
                        (Array.isArray(CItem.content)) &&
                        CItem.content.map((segment, index) => (
                            <div className={'content'} key={index}>
                                {

                                    (segment.type === "code" || segment.type === "link") ?
                                        <p className={'text-white'}
                                           dangerouslySetInnerHTML={{__html: segment.value}}></p>
                                        :
                                        <p className={'text-white'}>
                                            {segment.value}
                                        </p>
                                }

                            </div>
                        ))
                    }

                    {
                        CItem.attachedFileUrl &&
                        <div className="ticket-text__file px-0">
                            <span className="mx-2"><i className="fa fa-upload"/></span>
                            <a href={CItem.attachedFileUrl} target="_blank">فایل بارگذاری شده </a>
                        </div>
                    }
                    {
                        <div className="seen_at px-2 pb-1 text-left">
                            {
                                CItem.seenAt ?
                                    <>
                                        <span className="date-time">
                                            {DateConvert(CItem.seenAt)}
                                        </span>
                                        <i className="fal fa-check-double fa-sm mr-1"></i>
                                    </>
                                    :
                                    <>
                                        <i className="fal fa-check fa-sm mr-1"></i>
                                    </>
                            }

                        </div>
                    }
                </div>

                <div className="ticket-info mt-2">
                    {!CItem.isAdmin ?
                        <Link to={`/userDetail/${userId}/profile`} target="_blank">
                            <span>{CItem.authorName}</span>
                        </Link>
                        :
                        <span>{CItem.authorName}</span>
                    }
                    <span>
                        {
                            CItem.createdAt &&
                            <>
                                {
                                    (CItem.isAdmin && CItem.isDeletable && RoleCanActivate(['supervisor', 'manager'], adminProfile.roleId)) &&
                                    <span onClick={() => deleteConfirm(CItem.id)} className='ticket__delete mx-2'>
                                        <i className="far fa-trash-alt fa-1x"></i>
                                    </span>
                                }

                                {DateConvert(CItem.createdAt)}
                            </>
                        }
                    </span>
                </div>
            </div>

        </div>
    )
}

function mapStateToProps(state) {
    const {bootstrapper} = state;
    return {adminProfile: bootstrapper.data};
}
export default connect(mapStateToProps)(CommentItem);
