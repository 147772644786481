import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import Table from '../../../Component/Shared/Table/Table';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { ConvertQueryString } from "../../../Utility/Services/convert-query-string";
import DateConvert from '../../../Utility/Services/date-convert';
import { SeprateNumberWithComma } from '../../../Utility/Services/SeprateNumberWithComma';
import { LendInvoiceItemModal } from './LendInvoiceItemModal';


const LendInvoicesList = ({ BanksProvider }) => {
    const [lendInvoicesList, setLendInvoicesList] = useState({});
    const [lendItemModalVisible, setLendItemModalVisible] = useState(false);
    const [lendInvoiceItem, setLendInvoiceItem] = useState({});
    const [banksProviderList, setBanksProviderList] = useState({});
    const location = useLocation();
    const tableConfig = {
        header: {
            userName: "نام کاربری",
            description: "توضیحات",
            credit: "تعداد کردیت",
            finalPrice: "مبلغ کل پرداختی",
            createdAt: "تاریخ و زمان ایجاد",
            isPaidStatus: "وضعیت پرداخت",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
                condition: (data) => {
                    if (!data.isPaid) return true;
                    return false;
                },
            }
        },
        mode: ["filter", "search"],
        filters: ["datePickerFilter", "isPaid"]
    }

    const tableActions = (items) => {
        if (items.actionName === 'view') {
            if (!items.data.isPaid) {
                openLendItem(items.data);
            }
        }
    }

    const openLendItem = (data) => {
        setLendInvoiceItem(data);
        setLendItemModalVisible(true);
    }

    const modalDataChange = (item) => {
        if (item) getLendInvoceList()
    }

    const getLendInvoceList = useCallback(() => {
        ApiClient.Get(Urls.ModifyLendInvoices(), ConvertQueryString(location.search))
            .then(res => {
                let responseData = res.result;
                responseData.items.map((item, i) => {
                    item.finalPrice = `${SeprateNumberWithComma(item.finalPrice)} ریال`;
                    //  item.description = 'با سلام من مشکل مالی دارم. ده تا چک دارم. الان برام پرداخت کنین قول میدم که به موقع برگردونم.';
                    item.createdAt = DateConvert(item.createdAt);
                    item.isPaidStatus = item.isPaid;
                    item.isPaidStatus = (item.isPaidStatus)
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    return false;
                })
                setLendInvoicesList(responseData);
            })
    }, [location.search])

    useEffect(() => {
        getLendInvoceList();

        if (BanksProvider) {
            let banksList = [];
            BanksProvider.map(item => {
                banksList.push({ value: item.id, name: item.title })
            })
            setBanksProviderList(banksList);
        }
    }, [location.search, getLendInvoceList, BanksProvider]);

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <MainContent>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <PageTitleLayout>
                                <h5> لیست فاکتورهای پرداخت‌های قرضی</h5>
                                <span>در این بخش لیستی از فاکتور‌ اعتبارهای بدون پرداخت را مشاهده کنید.</span>
                            </PageTitleLayout>
                            <Table
                                data={lendInvoicesList.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: lendInvoicesList.pageNumber,
                                    pageSize: lendInvoicesList.pageSize,
                                    totalItemsCount: lendInvoicesList.totalItemsCount,
                                    totalPagesCount: lendInvoicesList.totalPagesCount,
                                }}
                            />
                        </div>
                    </div>
                </MainContent>
            </div>

            <LendInvoiceItemModal
                visible={lendItemModalVisible}
                setVisible={setLendItemModalVisible}
                onClose={() => setLendItemModalVisible(false)}
                onCancle={() => setLendItemModalVisible(false)}
                data={lendInvoiceItem}
                banksProviderList={banksProviderList}
                dataChange={modalDataChange}
            />

        </WrapperLayout>
    )
}

const mapStateToProps = (state) => ({
    BanksProvider: state.bootstrapper.data.banks
})

export default connect(mapStateToProps)(LendInvoicesList)

