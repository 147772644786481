let CompanyActivityList = [
  { value: 1, name: "بانک/موسسه مالی" },
  { value: 2, name: "خدماتی" },
  { value: 3, name: "تولیدی" },
  { value: 4, name: "تبلیغاتی" },
  { value: 5, name: "بیمه" },
  { value: 6, name: "خبرگزاری/رسانه" },
  { value: 7, name: "اطلاعات فناوری" },
  { value: 9, name: "فرهنگی/ورزشی" },
  { value: 8, name: "سایر" },
];
let CompanyTypeList = [
  { value: 1, name: "سهامی خاص" },
  { value: 2, name: "سهامی عام" },
  { value: 3, name: "مسئولیت محدود" },
  { value: 4, name: "تضامنی" },
  { value: 5, name: "دولتی" },
  { value: 6, name: "وزارتخانه" },
  { value: 7, name: "سفارتخانه" },
  { value: 8, name: "مسجد" },
  { value: 9, name: "مدرسه" },
  { value: 10, name: "NGO" },
  { value: 11, name: "سایر" },
  { value: 12, name: "موسسه" },
];
export { CompanyActivityList, CompanyTypeList };
