import styled from "styled-components";

import { StyledForm } from "../../../Component/Kit/Form";
import Col from "../../../Component/Kit/Column";
import { theme } from "../../../Utility/Theme";


export const LoginFirstStepForm = styled(StyledForm)`
    display : ${props => props.currentStep !== props.orderStep && 'none'};
    /* currentStep : ${props => props.currentStep};*/
    align-items: center;
    background: white;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
    padding: 2.1rem 2.6rem 1.6rem;
    border-radius: .75rem;
    flex-direction: column;
    width: 550px;
    input{
        direction:ltr;
    }
    @media only screen and (max-width: 575px){
        width: calc(100vw - 10vw);
        padding: 1.5rem 1.5rem 1.5rem;
    }
`;

export const LoginSecondStepForm = styled(StyledForm)`
    display : ${props => props.currentStep !== props.orderStep && 'none'};
    align-items: center;
    background: white;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
    padding: 2.1rem 2.6rem 1.6rem;
    border-radius: .75rem;
    flex-direction: column;
    width: 550px;
    .d-flex{
        flex-direction: row-reverse;
        justify-content: center;
        min-height: 50px;
        align-items: center;
        margin-bottom: 0.75rem;
    }
    input{
        direction:ltr;
    }
    @media only screen and (max-width: 575px){
        width: calc(100vw - 10vw);
        padding: 1.5rem 1.5rem 1.5rem;
    }
`;

export const ProfileContainer = styled(Col)`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    margin-bottom:30px;
    border: solid 2px ${theme.paleGrey};
    svg{
        width: 32px;
        height: 32px;     
    }
`;

export const StyleParagraph = styled.p`
    display: flex;
    font-family: IRANSans;
    font-size: 12px;
    font-weight: normal;
    color: #999999;
    text-align: right;
    direction: rtl;
    width: 100%;
    .fakeLink{
        margin-right: 10px;
        color: ${theme.darkGreen};
        text-decoration: underline;
        cursor: pointer;
    }
`;
