class LocalToken {
  #ACCESS_TOKEN;
  #REFRESH_TOKEN;

  constructor() {
    this.#ACCESS_TOKEN = 'access_token';
    this.#REFRESH_TOKEN = 'refresh_token';
  }

  getAccessToken = () => {
    return localStorage.getItem(this.#ACCESS_TOKEN);
  };

  geRefreshToken = () => {
    return localStorage.getItem(this.#REFRESH_TOKEN);
  };

  setToken = (res) => {
    localStorage.setItem(this.#ACCESS_TOKEN, res.result.accessToken);
    localStorage.setItem(this.#REFRESH_TOKEN, res.result.refreshToken);
  };

  setAccessToken = (res) => {
    localStorage.setItem(this.#ACCESS_TOKEN, res.result);
  };

  setRefreshToken = (res) => {
    localStorage.setItem(this.#REFRESH_TOKEN, res.result);
  };

  Remove = () => {
    localStorage.removeItem(this.#ACCESS_TOKEN);
    localStorage.removeItem(this.#REFRESH_TOKEN);
  };
}
export default new LocalToken();
