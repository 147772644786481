import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { StyledButton } from '../../../../Component/Kit/Button';
import {
  AutocompleteList,
  FormLable,
  StyledCheckBox,
  StyledForm,
  StyledSelect,
} from '../../../../Component/Kit/Form';
import Modal from '../../../../Component/Kit/Modal';
import TicketDepartmentList from '../../../../Utility/Model/TicketDepartmentModel';
import TicketStatus from '../../../../Utility/Services/TicketStatusService';
import { ModifyConfigTicketSchema } from '../../../../Utility/Validation/Schema/ModifyConfigTicketSchema';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import TicketPriorityList from '../../../../Utility/Model/TicketPriorityModel';
import { FormLabel } from 'react-bootstrap';

export const ModifyTicketSettingModal = (props) => {
  const {
    ticketReply,
    setTicketReply,
    ticketID,
    setVisible,
    configSetting,
    setConfigSetting,
  } = props;
  const history = useHistory();
  const [adminName, setAdminName] = useState('');
  const [adminList, setAdminList] = useState();
  const [customAdminList, setCustomAdminList] = useState();
  const [customAdminAssignList, setCustomAdminAssignList] = useState([]);
  let TicketStatusModidyList = [
    { name: 'در حال بررسی', value: 4 },
    { name: 'بسته', value: 5 },
  ];

  if (
    configSetting.status &&
    configSetting.status !== 4 &&
    configSetting.status !== 5
  ) {
    let currentStatus = TicketStatus(configSetting.status);
    TicketStatusModidyList = [
      { name: currentStatus, value: configSetting.status },
      { name: 'در حال بررسی', value: 4 },
      { name: 'بسته', value: 5 },
    ];
  }

  const addUpdate = (items) => {
    setTicketReply({
      ...ticketReply,
      department: items.department,
      status: items.status,
      priority: items.priority,
      isUploadable: items.isUploadable,
      adminId: items.adminId,
      admin: items.admin,
      assignedAdmin: items.assignedAdmin
    });
  };

  const changeInput = (e) => {
    const list = adminList.filter((f) => f.fullName.includes(e));
    setCustomAdminAssignList([
      {
        adminId: null,
        fullName: 'ندارد',
      },
      ...list,
    ]);
    setCustomAdminList([
      {
        adminId: null,
        fullName: 'همه مدیران',
      },
      ...list,
    ]);
  };
  const changeSelect = (e) => {
    setAdminName(e.fullName);
    setCustomAdminAssignList([
      {
        adminId: null,
        fullName: 'ندارد',
      },
      ...adminList,
    ]);
    setCustomAdminList([
      {
        adminId: null,
        fullName: 'همه مدیران',
      },
      ...adminList,
    ]);
  };
  useEffect(() => {
    ApiClient.Get(Urls.AdminSummary()).then((res) => {
      setAdminList(res.result);
      setCustomAdminAssignList([
        {
          adminId: null,
          fullName: 'ندارد',
        },
        ...res.result,
      ]);
      setCustomAdminList([
        {
          adminId: null,
          fullName: 'همه مدیران',
        },
        ...res.result,
      ]);
    });
  }, []);

  return (
    <>
        <Formik
          initialValues={{
            changeDepartment: configSetting.department,
            status: configSetting.status,
            priority: configSetting.priority,
            uploadable: configSetting.isUploadable,
            adminId: configSetting.adminId,
            assignedAdminId: configSetting.assignedAdminId
          }}
          validationSchema={ModifyConfigTicketSchema}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            let body = {
              department: values.changeDepartment
                ? Number(values.changeDepartment)
                : configSetting.department,
              status: values.status
                ? Number(values.status)
                : configSetting.status,
              priority: values.priority
                ? Number(values.priority)
                : configSetting.priority,
              isUploadable:
                values.uploadable !== undefined
                  ? values.uploadable
                  : configSetting.isUploadable,
              adminId:
                values.adminId !== undefined
                  ? values.adminId
                  : configSetting.adminId,
              assignedAdminId:
                  values.assignedAdminId !== undefined
                  ? values.assignedAdminId
                  : configSetting.assignedAdminId,
            };
            ApiClient.Put(Urls.GetTicketByAdmin(ticketID), body).then((res) => {
              toast.success('تغییرات تیکت با موفقیت ثبت شد.');
              setConfigSetting({ ...body, admin: customAdminAssignList?.find((a => a.adminId === values.adminId))?.fullName , assignedAdmin: customAdminAssignList?.find((a => a.adminId === values.assignedAdminId))?.fullName });
              actions.setSubmitting(false);
              actions.resetForm();
              addUpdate({ ...body, admin: customAdminAssignList?.find((a => a.adminId === values.adminId))?.fullName  , assignedAdmin: customAdminAssignList?.find((a => a.adminId === values.assignedAdminId))?.fullName});
              setVisible(false);
              if (body.status === 5) history.push('/ticket/list');
            });
          }}
          onReset={(values, formikBag) => {
            setVisible(false);
          }}
        >
          {(formik) => (
            <StyledForm
              onSubmit={formik.handleSubmit}
              onBlur={formik.handleBlur}
              onReset={formik.handleReset}
            >
              <StyledSelect
                lable='تغییر دپارتمان'
                focus={true}
                name='changeDepartment'
                value={
                  formik.values.changeDepartment !== undefined
                    ? formik.values.changeDepartment
                    : formik.initialValues.changeDepartment
                }
                optionItems={TicketDepartmentList}
                error={
                  formik.errors.changeDepartment &&
                  formik.touched.changeDepartment
                    ? formik.errors.changeDepartment
                    : 'false'
                }
                onChange={(e) =>
                  formik.setFieldValue('changeDepartment', e.target.value)
                }
              />
              <StyledSelect
                lable='تغییر وضعیت'
                focus={true}
                name='status'
                value={
                  formik.values.status !== undefined
                    ? formik.values.status
                    : formik.initialValues.status
                }
                optionItems={TicketStatusModidyList}
                error={
                  formik.errors.status && formik.touched.status
                    ? formik.errors.status
                    : 'false'
                }
                onChange={(e) => formik.setFieldValue('status', e.target.value)}
              />
              <StyledSelect
                lable='درجه اهمیت'
                focus={true}
                name='priority'
                value={
                  formik.values.priority !== undefined
                    ? formik.values.priority
                    : formik.initialValues.priority
                }
                optionItems={TicketPriorityList}
                error={
                  formik.errors.priority && formik.touched.priority
                    ? formik.errors.priority
                    : 'false'
                }
                onChange={(e) =>
                  formik.setFieldValue('priority', e.target.value)
                }
              />

              <div className='form-group'>
                <FormLable show={true}>انتخاب مدیر</FormLable>
                <AutocompleteList
                  options={customAdminList}
                  placeholder='انتخاب کنید'
                  keyName='adminId'
                  valueName='fullName'
                  value={
                    formik.values.adminId !== undefined
                      ? formik.values.adminId
                      : formik.initialValues.adminId
                  }
                  inputHandler={(e) => {
                    changeInput(e);
                  }}
                  selectItem={(e) => {
                    formik.setFieldValue('adminId', e.adminId);
                    changeSelect(e);
                  }}
                ></AutocompleteList>
              </div>

              <div className='form-group'>
                <FormLable show={true}> مسئول پیگیری </FormLable>
                <AutocompleteList
                    options={customAdminAssignList}
                    placeholder='انتخاب کنید'
                    keyName='adminId'
                    valueName='fullName'
                    value={
                      formik.values.assignedAdminId !== undefined
                          ? formik.values.assignedAdminId
                          : formik.initialValues.assignedAdminId
                    }
                    inputHandler={(e) => {
                      changeInput(e);
                    }}
                    selectItem={(e) => {
                      formik.setFieldValue('assignedAdminId', e.adminId);
                      changeSelect(e);
                    }}
                ></AutocompleteList>
              </div>

              <StyledCheckBox
                lable='قابلیت آپلود فایل'
                focus={true}
                name='uploadable'
                id='uploadable'
                checked={
                  formik.values.uploadable !== undefined
                    ? formik.values.uploadable
                    : formik.initialValues.uploadable
                }
                error={
                  formik.errors.uploadable && formik.touched.uploadable
                    ? formik.errors.uploadable
                    : 'false'
                }
                onChange={(e) =>
                  formik.setFieldValue('uploadable', e.target.checked)
                }
              />
              <div className='d-flex justify-content-between'>
                <StyledButton
                  width='38%'
                  disabled={!formik.isValid}
                  loading={formik.isSubmitting ? 1 : 0}
                  btnStyle="greenBtn" 
                  type='submit'
                >
                  ثبت تنظیمات
                </StyledButton>
                <StyledButton
                  width='35%'
                  btnStyle='grayBtn'
                  type='reset'
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  بستن
                </StyledButton>
              </div>
            </StyledForm>
          )}
        </Formik>
    </>
  );
};
