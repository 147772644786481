import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap';
import {toast} from 'react-toastify';


import {StyledButton} from '../../../../Component/Kit/Button';
import {FormLable, StyledForm, StyledSelect, StyledTextArea} from '../../../../Component/Kit/Form';
import {UploadFileWrqapperAllKindOfFile,} from '../../../../Component/Kit/UploadZone';
import ApiClient from '../../../../Requester/ApiClient';
import {ReplyTicketSchema} from "../../../../Utility/Validation/Schema/ReplyTicketSchema";
import Urls from '../../../../Requester/Urls';
import TicketDepartmentList from '../../../../Utility/Model/TicketDepartmentModel';
import TicketReportTypeList from '../../../../Utility/Model/TicketReportType';
import {MyDatePicker} from '../../../../Component/Shared/DatePicker';
import {Loader} from '../../../../Component/Shared/Loader';


export const ReplyToTicket = (props) => {
    const {userId, userTicketId, setReload, reload} = props;
    const [tab, setTab] = useState("1");
    const [AnswerTemplateList, setAnswerTemplateList] = useState([]);
    const [lines, setLines] = useState(null);
    const [selectedLine, setSelectedLine] = useState(null);
    const [filteredAnswerTemplate, setFilteredAnswerTemplate] = useState([]);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [inputRef, setInputRef] = useState(null);
    const [report, setReport] = useState({
        fileName: null,
        linkDownload: null,
        reportType: {
            value: null,
            text: null,
        },
        isLoading: false
    });


    useEffect(() => {
        if (userId) {
            getTicketTemplate();
            getUserLine(userId);
        }
    }, [userId])


    const formik = useFormik({
        initialValues: {
            ticketContent: "",
            ticketFile: undefined,
            ticketType: "",
            toDate: '',
            fromDate: '',
            templateFile: {
                url: null,
                id: null
            }
        },
        validationSchema: ReplyTicketSchema,

        onSubmit: (values, actions) => {
            let formData = new FormData();

            setTimeout(() => {
                setLoadingFlag(true)
            }, 40);

            (values.ticketContent === "")
                ?
                formData.append('content', " :فایل")
                :
                formData.append('content', values.ticketContent)

            if (values.ticketFile && !report.fileName) {
                formData.append("file", values.ticketFile, values.ticketFile.name);
            }

            if (report.fileName) {
                formData.append("UploadedFileId", report.fileName);
            }

            if (values.templateFile.id) {
                formData.append("UploadedFileId", values.templateFile.id);
            }

            removeFile(inputRef);

            ApiClient.Post(Urls.AddTicketReplyByAdmin(userTicketId), formData)
                .then(res => {
                    toast.success('تیکت با موفقیت ارسال شد.');
                    setTimeout(() => {
                        setLoadingFlag(false)
                        actions.setSubmitting(false);
                        actions.resetForm();
                        setReload(!reload)
                    }, 50);
                })
                .catch(e => {
                    setLoadingFlag(false)
                    actions.setSubmitting(false);

                })
        }
    });


    const selectDepartmentHandler = (e) => {
        const target = e.target.value;
        let myArray = AnswerTemplateList.filter((el) => {
            if (el.department === Number(target)) {
                return el
            }
            return false;
        })
        setFilteredAnswerTemplate(myArray)
    }


    const selectReportType = (e) => {
        const value = e.target.value;
        setReport({
            ...report,
            reportType: {
                value: value,
                text: TicketReportTypeList.find(e => e.value === value)?.name
            }
        })

    }


    const selectLine = (e) => {
        const value = e.target.value;
        setSelectedLine(value)
    }


    const getTicketTemplate = () => {
        const params = {
            pageNumber: 1,
            pageSize: 1000
        }
        ApiClient.Get(Urls.TicketAnswerTemplates(), params)
            .then(response => {
                const dataItem = response.result.items;

                Object.values(dataItem).map((item) => {
                    setAnswerTemplateList(oldArray => [...oldArray, {
                        name: item.title,
                        value: item.id,
                        department: item.department,
                        content: item.content,
                        attachedFileId: item.attachedFileId,
                        attachedFileUrl: item.attachedFileUrl
                    }])
                    return item;
                })
            })
    }


    const getUserLine = (userId) => {
        ApiClient.Get(Urls.userLines(userId))
            .then(response => {
                const lines = response.result.lines.map((item) => {
                    return {
                        value: item.number,
                        name: item.number
                    }
                })
                setLines(lines)

            }).catch(() => {
            setLines([])
        })
    }


    const changeFile = (e, isSelfUploaded = false, inputElement = null) => {
        formik.setFieldValue('ticketFile', e)
        if (isSelfUploaded) {
            removeReportFile();
            formik.setFieldValue("templateFile", {
                url: null,
                id: null
            });
        }
        if (inputElement) setInputRef(inputElement);
    }


    const removeFile = (inputRef) => {
        if (inputRef) inputRef.current.value = "";
        formik.setFieldValue("ticketFile", null);
        formik.setFieldValue("templateFile", {
            url: null,
            id: null
        });

        removeReportFile();
    }


    const removeReportFile = () => {
        setReport({
            ...report,
            reportType: {text: null, value: 'default'},
            fileName: null,
            linkDownload: null,
            isLoading: false,
        })
    }


    const selectTemplate = (e, data) => {
        e.persist()
        formik.setFieldValue("ticketContent", data.content)
        formik.setFieldValue("ticketFile", null);
        removeReportFile();
        if (inputRef) inputRef.current.value = "";
        if (data.attachedFileUrl) {
            formik.setFieldValue("templateFile", {
                url: data.attachedFileUrl,
                id: data.attachedFileId
            })
            setTab("2");
        }

    }


    const responseReport = (response) => {
        let data = response.result;
        if (data) {
            toast.success('گزارش در تب آپلود قرار گرفت');
            handleSelectTab("2");
            setReport({
                ...report,
                fileName: data.id,
                isLoading: false,
                linkDownload: data.url,
                reportType: {text: null, value: 'default'},
            })

            changeFile(null)

        } else {
            toast.warning('گزارشی یافت نشد');

            setReport({
                ...report,
                reportType: {text: null, value: 'default'},
                isLoading: false,
            })
        }
    }


    const getReportFile = (type) => {
        if (report.reportType.value === 'default') {
            toast.error('لطفا نوع گزارش را انتخاب کنید');
            return;
        }

        const params = {}

        if (formik.values.fromDate) {
            params["fromDate"] = formik.values.fromDate;
        }
        if (formik.values.toDate) {
            params["toDate"] = formik.values.toDate;
        }
        if (selectedLine) {
            params["lineNumber"] = selectedLine;
        }
        setSelectedLine(null);

        setReport(prevState => ({
            ...prevState,
            isLoading: true,
        }))

        // eslint-disable-next-line default-case
        switch (type) {
            case "1":
                ApiClient.Get(Urls.getUserReceiveReport(userId), params)
                    .then(response => {

                        responseReport(response);

                    }).catch(e => {
                    setReport({
                        ...report,
                        reportType: {text: null, value: 'default'},
                        isLoading: false,
                    })
                })
                break;


            case "2":
                ApiClient.Get(Urls.getUserSendReport(userId), params)
                    .then(response => {
                        responseReport(response);

                    }).catch(e => {
                    setReport({
                        ...report,
                        reportType: {text: null, value: 'default'},
                        isLoading: false,
                    })
                })

                break;
        }

    }


    const handleSelectTab = (key) => {
        setTab(key);
    }


    return (
        <div className="flex-row mt-4 ticket-reply-text">


            <StyledForm
                onSubmit={formik.handleSubmit}
                className="replyContainer"
            >
                <div className="row">
                    <div className="col-md-6 rp-box">
                        <StyledTextArea
                            placeholder="موارد مورد نظر خود را وارد نمایید"
                            lable="متن تیکت"
                            focus={true}
                            name="ticketContent"
                            height="100%"
                            error={(formik.errors.ticketContent && formik.touched.ticketContent)
                                ? formik.errors.ticketContent
                                : 'false'
                            }
                            value={formik.values.ticketContent}
                            onChange={(e) => {
                                formik.setFieldValue("ticketContent", e.target.value)
                            }}
                        />
                    </div>

                    <div className="col-md-6 rp-box">
                        <Tabs
                            activeKey={tab}
                            onSelect={handleSelectTab}
                        >

                            <Tab eventKey={"1"} title="قالب‌ها">
                                <div className="col-md-12 p-0">
                                    <StyledSelect
                                        lable="انتخاب بخش"
                                        focus={true}
                                        name="department"
                                        value={formik.values.department}
                                        optionItems={TicketDepartmentList}
                                        hasDefaultOption
                                        error="false"
                                        onChange={(e) => selectDepartmentHandler(e)}
                                    />
                                    <div>
                                        {
                                            filteredAnswerTemplate &&
                                            filteredAnswerTemplate.map((resultItem, index) => (
                                                (Object.entries(resultItem).length) > 0
                                                    ?
                                                    <span className="m-1 btn btn-info btn-sm" key={index}
                                                          onClick={(e) => {
                                                              selectTemplate(e, resultItem)


                                                          }}>
                                                        {resultItem.name}
                                                    </span>
                                                    :
                                                    <p>قالب تیکت‌ای موجود نیست</p>
                                            ))
                                        }

                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey={"2"} title="آپلود">
                                <div className="w-100">
                                    <UploadFileWrqapperAllKindOfFile
                                        text="xls, xlsx, jpeg, jpg, JPG, png, zip, rar, txt"
                                        type="file"
                                        name="ticketFile"
                                        callbackfunc={changeFile}
                                        error={(formik.errors.ticketFile && formik.values.ticketFile)
                                            ? formik.errors.ticketFile
                                            : false
                                        }

                                        file={formik.values.ticketFile?.name || report.fileName || formik.values.templateFile.id}
                                        deleteFile={removeFile}
                                        downloadUrl={report.fileName ? report.linkDownload : (
                                            formik.values.templateFile.url ? formik.values.templateFile.url : null
                                        )}
                                    />
                                </div>
                            </Tab>

                            <Tab eventKey={"3"} title="گزارش">
                                {!report.isLoading && lines !== null ?
                                    <>
                                        <div className="col-md-12 p-0">
                                            <StyledSelect
                                                lable="نوع گزارش"
                                                focus={true}
                                                name="report"
                                                optionItems={TicketReportTypeList}
                                                hasDefaultOption
                                                error="false"
                                                onChange={(e) => selectReportType(e)}
                                            />
                                        </div>

                                        <div className="col-md-12 p-0">
                                            <StyledSelect

                                                lable="خط"
                                                focus={true}
                                                name="report"
                                                optionItems={lines}
                                                hasDefaultOption
                                                error="false"
                                                onChange={(e) => selectLine(e)}
                                            />
                                        </div>

                                        <div className="row dotes">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <FormLable show={true}>
                                                    تاریخ شروع
                                                </FormLable>
                                                <MyDatePicker
                                                    defaultValue={formik.values.fromDate}
                                                    value={(event) => {
                                                        formik.setFieldValue("fromDate", event.baseFormat)
                                                    }}
                                                    name="fromDate"
                                                    id="fromDate"
                                                    error={
                                                        formik.errors.fromDate !== undefined
                                                            ? formik.errors.fromDate
                                                            : false
                                                    }
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <FormLable show={true}>
                                                    تاریخ پایان
                                                </FormLable>
                                                <MyDatePicker
                                                    defaultValue={formik.values.toDate}
                                                    value={(event) => {
                                                        formik.setFieldValue("toDate", event.baseFormat)
                                                    }}
                                                    name="toDate"
                                                    id="toDate"
                                                    error={
                                                        formik.errors.toDate !== undefined
                                                            ? formik.errors.toDate
                                                            : false
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className='w-100'>

                                            <StyledButton
                                                width="100%"
                                                btnStyle="darkGreenBtn mt-3"
                                                disabled={!report.reportType.value}
                                                loading={loadingFlag ? 1 : 0}
                                                type="submit"
                                                onClick={() => getReportFile(report.reportType.value)}
                                            >
                                                دریافت
                                            </StyledButton>

                                        </div>
                                    </> : <Loader/>
                                }

                            </Tab>

                        </Tabs>
                    </div>

                </div>
                <StyledButton
                    width="100%"
                    btnStyle="darkGreenBtn"
                    disabled={!formik.values.ticketContent || loadingFlag}
                    loading={loadingFlag ? 1 : 0}
                    type="submit"
                >
                    ارسال تیکت
                </StyledButton>
            </StyledForm>

        </div>
    )
}
