import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { PageTitleLayout } from "../../../Component/Kit/Layout";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { ConvertQueryString } from "../../../Utility/Services/convert-query-string";
import DateConvert from "../../../Utility/Services/date-convert";

function IncommingCalls() {
  const location = useLocation();
  const [guestData, setGuestData] = useState([]);

  let match = useRouteMatch();

  const tableConfig = {
    header: {
      date: "تاریخ تماس",
      time: "زمان تماس",
    },
    actions: {},
  };

  useEffect(() => {
    ApiClient.Get(
      Urls.getIcommingCalls("guest", match.params.id),
      ConvertQueryString(location.search)
    ).then((res) => {
      let data = res.result;
      data.items.map((m, i) => {
        m.date = DateConvert(m.createdAt).slice(11);
        m.time = DateConvert(m.createdAt).slice(0, 8);
        return m;
      });
      setGuestData(data);
    });
  }, [location.search]);

  return (
    <>
      <PageTitleLayout className="mt-4">
        <h5>تماس ها</h5>
        <span>در اینجا تاریخچه ای از تماس های کاربر مهمان را ببینید.</span>
      </PageTitleLayout>
      <Table
        data={guestData.items}
        config={tableConfig}
        pageConfig={{
          pageNumber: guestData.pageNumber,
          pageSize: guestData.pageSize,
          totalItemsCount: guestData.totalItemsCount,
          totalPagesCount: guestData.totalPagesCount,
        }}
      />
    </>
  );
}

export default IncommingCalls;
