const AdminActivityOperationEnum = {
    CreateNewUser: 1,
    SendSmsToUser: 2,
    Login: 3,
    LoginToUserPanel: 4,
    ModifyUserProfile: 5,
    ModifySendingBlackListKeywordForSpecificUser: 6,
    ModifyUserCooperationCreditProfitPercent: 7,
    ModifyUserCooperationLineProfitPercent: 8,
    ModifyBlockedIP: 9,
    ModifyCreditPackage: 10,
    ModifyFastSendTemplateStatus: 11,
    ModifyFinancialDepartmentProcess: 12,
    ModifyGuest: 13,
    ModifySystemLine: 14,
    ModifyLine: 15,
    MagfaInfo: 16,
    ModifyPublicBlackListKeyWord: 17,
    ModifyCustomNotification: 18,
    HandlePendingSendMessages: 19,
    ModifySetting: 20,
    SmsLimitationForbiddenWord: 21,
    ModifyTicket: 22,
    ModifyUserAdmin: 23,
    ValidateApiFrequestMessageText: 24,
    RemoveUserLine: 25,
    ModifyLegalUser: 26,
    ModifyLegalUserInvoices: 27,
    ModifyBlockedMobiles: 28,
    ModifySpecialAllowedMaxParameterFastSendSize: 29,
    ModifyCalledVerifyApiCount: 30,
    AddPlanToUser : 31,
    AssignDedicatedBasePriceToUser : 32,
    RemoveUserDedicatedBasePrice : 33,
    HandlePendingUserLine : 34,
    ExpireUserPlan : 35,
    ModifyPublicLinesConfiguration : 36,
    ModifyUserAllowedUsingPublicLines: 37,
    HandleSendSmsViaTower : 38,
    ModifyPendingManualPayment : 39,
    CancelUserInvoice : 40,
    RemoveInvoice : 41,
    ModifyDefaultPublicLine : 42,
    ModifyPublicLinePendingMessage : 43,
    ValidateUserCompletionRequirement : 44,
    ModifyUserServiceLineStatus : 45,
    ModifyBanks : 46,
    ModifyAdvertisingCampaigns : 47,
    ModifyOfficialPaymentStatus: 48,
    ModifyAllowedTotalSmsSentInADay : 49,
    ModifySendViaPublicLinesIsActivated : 50,
    ModifyOffKeywordSettingByProvider : 51,
    ModifySendSmsViaTowerOperatorConfigurations : 52,
    ModifySendSmsViaTowerLimitations : 53,
    ModifyPublicLineCostCoefficient : 54,
    ModifyPublicLineSendLimitations : 55
}

const AdminActivityOperationModel = [
    { value: AdminActivityOperationEnum.CreateNewUser, name: 'ایجاد کاربر جدید' },
    { value: AdminActivityOperationEnum.SendSmsToUser, name: 'ارسال پیامک به کاربر' },
    { value: AdminActivityOperationEnum.Login, name: 'ورود به سامانه' },
    { value: AdminActivityOperationEnum.LoginToUserPanel, name: 'ورود به پنل کاربر' },
    { value: AdminActivityOperationEnum.ModifyUserProfile, name: 'ویرایش پرفایل کاربر' },
    {
        value: AdminActivityOperationEnum.ModifySendingBlackListKeywordForSpecificUser,
        name: 'ویرایش کلیدواژهای  یک کاربر خاص در لیست سیاه'
    },
    {
        value: AdminActivityOperationEnum.ModifyUserCooperationCreditProfitPercent,
        name: 'ویرایش درصد سود خرید اعتبار هر کاربر در بخش همکاری در فروش '
    },
    {
        value: AdminActivityOperationEnum.ModifyUserCooperationLineProfitPercent,
        name: 'ویرایش درصد سود خرید خط هر کاربر در بخش همکاری در فروش '
    },
    { value: AdminActivityOperationEnum.ModifyBlockedIP, name: 'ویرایش آی پی های بلاک شده' },
    { value: AdminActivityOperationEnum.ModifyCreditPackage, name: 'ویرایش پکیج ها ' },
    { value: AdminActivityOperationEnum.ModifyFastSendTemplateStatus, name: 'ویرایش وضعیت قالب ارسال سریع' },
    { value: AdminActivityOperationEnum.ModifyFinancialDepartmentProcess, name: 'ویرایش اطلاعات مالی ' },
    { value: AdminActivityOperationEnum.ModifyGuest, name: 'ویرایش کاربر مهمان' },
    { value: AdminActivityOperationEnum.ModifySystemLine, name: 'تغیرات در خطوط سیستمی ' },
    { value: AdminActivityOperationEnum.ModifyLine, name: 'ویرایش خطوط ' },
    { value: AdminActivityOperationEnum.MagfaInfo, name: 'ویرایش اطلاعات مگفا ' },
    { value: AdminActivityOperationEnum.ModifyPublicBlackListKeyWord, name: 'ویرایش در کلیدواژهای لیست سیاه' },
    { value: AdminActivityOperationEnum.ModifyCustomNotification, name: 'ویرایش اعلانات  ' },
    { value: AdminActivityOperationEnum.HandlePendingSendMessages, name: 'بررسی کردن وضعیت ارسال پیام در صف' },
    { value: AdminActivityOperationEnum.ModifySetting, name: 'ویرایش تنظیمات ' },
    { value: AdminActivityOperationEnum.SmsLimitationForbiddenWord, name: 'تغیرات در کلمات ممنوعه' },
    { value: AdminActivityOperationEnum.ModifyTicket, name: 'ویرایش تیکت' },
    { value: AdminActivityOperationEnum.ModifyUserAdmin, name: 'تغییر اطلاعات مربوط به مدیران/کاربران' },
    { value: AdminActivityOperationEnum.ValidateApiFrequestMessageText, name: 'تایید ارسال های مکرر' },
    { value: AdminActivityOperationEnum.RemoveUserLine, name: 'حذف خط کاربر' },
    { value: AdminActivityOperationEnum.ModifyLegalUser, name: 'تغییر اطلاعات حقوقی کاربر' },
    { value: AdminActivityOperationEnum.ModifyLegalUserInvoices, name: 'تغییر اطلاعات حقوقی فاکتور' },
    { value: AdminActivityOperationEnum.ModifyBlockedMobiles, name: 'ویرایش شماره های بلاک شده' },
    { value: AdminActivityOperationEnum.ModifySpecialAllowedMaxParameterFastSendSize, name: 'ویرایش تعداد کارکتر قالب ارسال سریع' },
    { value: AdminActivityOperationEnum.ModifyCalledVerifyApiCount, name: 'تغییر محدودیت ارسال روزانه وب سرویس' },
    { value: AdminActivityOperationEnum.AssignDedicatedBasePriceToUser, name: 'اختصاص قیمت پایه اختصاصی به کاربر' },
    { value: AdminActivityOperationEnum.RemoveUserDedicatedBasePrice, name: 'حذف قیمت پایه اختصاصی کاربر' },
    { value: AdminActivityOperationEnum.AddPlanToUser, name: 'افزودن پلن به یک کاربر' },
    { value: AdminActivityOperationEnum.HandlePendingUserLine, name: 'تغییر محدودیت ارسال روزانه وب سرویس' },
    { value: AdminActivityOperationEnum.ExpireUserPlan, name: 'منقضی شدن پلن کاربر' },
    { value: AdminActivityOperationEnum.ModifyPublicLinesConfiguration, name: 'ویرایش خطوط ویژه' },
    { value: AdminActivityOperationEnum.ModifyUserAllowedUsingPublicLines, name: 'ویرایش استفاده از خطوط ویژه برای یک کاربر' },
    { value: AdminActivityOperationEnum.HandleSendSmsViaTower, name: 'بررسی درخواست ارسال از دکل' },
    { value: AdminActivityOperationEnum.ModifyPendingManualPayment, name: 'ویرایش فیش در انتظار تایید' },
    { value: AdminActivityOperationEnum.CancelUserInvoice, name: 'فاکتور ابطالی' },
    { value: AdminActivityOperationEnum.RemoveInvoice, name: 'حذف فاکتور' },
    { value: AdminActivityOperationEnum.ModifyDefaultPublicLine, name: 'انتخاب پیشفرض خطوط ویژه' },
    { value: AdminActivityOperationEnum.ModifyPublicLinePendingMessage, name: 'ویرایش ارسال خطوط ویژه' },
    { value: AdminActivityOperationEnum.ValidateUserCompletionRequirement, name: 'بررسی صحت اطلاعات تکمیلی' },
    { value: AdminActivityOperationEnum.ModifyUserServiceLineStatus, name: 'ویرایش وضعیت خدماتی سازی خط' },
    { value: AdminActivityOperationEnum.ModifyBanks, name: 'ویرایش تنظیمات بانک ها' },
    { value: AdminActivityOperationEnum.ModifyAdvertisingCampaigns, name: 'ویرایش کمپین تبلیغاتی' },
    { value: AdminActivityOperationEnum.ModifyOfficialPaymentStatus, name: 'ویرایش تنظیمات پرداخت رسمی/غیررسمی کاربر' },
    { value: AdminActivityOperationEnum.ModifyAllowedTotalSmsSentInADay, name: 'ویرایش محدودیت تعداد پیامک ارسالی روزانه سامانه' },
    { value: AdminActivityOperationEnum.ModifySendViaPublicLinesIsActivated, name: 'ویرایش وضعیت ارسال با خطوط ویژه سامانه' },
    { value: AdminActivityOperationEnum.ModifyOffKeywordSettingByProvider, name: 'ویرایش تنظیمات عبارت لغو11 سامانه' },
    { value: AdminActivityOperationEnum.ModifySendSmsViaTowerOperatorConfigurations, name: 'ویرایش تنظیمات ارسال از دکل سامانه' },
    { value: AdminActivityOperationEnum.ModifySendSmsViaTowerLimitations, name: 'ویرایش تنظیمات محدودیت زمانی ارسال از دکل سامانه' },
    { value: AdminActivityOperationEnum.ModifyPublicLineCostCoefficient, name: 'ویرایش ضریب هزینه ارسال با خطوط ویژه' },
    { value: AdminActivityOperationEnum.ModifyPublicLineSendLimitations, name: 'ویرایش محدودیت‌های ارسال با خطوط ویژه' },
]
export { AdminActivityOperationModel }


