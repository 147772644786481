import { Formik } from 'formik';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../Component/Kit/Button';
import { StyledForm, StyledTextArea } from '../../../../Component/Kit/Form';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import { AddGuestCommentSchema } from './../../../../Utility/Validation/Schema/AddGuestCommentSchema';

const AddGuestCommentModal = ({ dataChange, onCloseModal }) => {
    const match = useRouteMatch();
    const id = match.params.id;
    
    return (
        <>
            <div className="custom-modal-body">
                <Formik
                    initialValues={{
                        description: ""
                    }}
                    validationSchema={AddGuestCommentSchema}
                    isInitialValid={false}
                    onSubmit={(values, actions) => {
                        const body = JSON.stringify(values.description)
                        ApiClient.Post(Urls.GuestComment(id), body)
                            .then(res => {
                                toast.success('کامنت با موفقیت ثبت شد.');
                                actions.resetForm()
                                onCloseModal(false)
                                dataChange(true)
                            })
                    }}
                    onReset={(values, formikBag) => {
                        onCloseModal(false)
                    }}
                >
                    {(formik) => (
                        <StyledForm
                            onSubmit={formik.handleSubmit}
                            onBlur={formik.handleBlur}
                            onReset={formik.handleReset}
                        >
                            <StyledTextArea
                                lable="توضیحات"
                                focus={true}
                                name="description"
                                value={formik.values.description}
                                error={(formik.errors.description && formik.touched.description)
                                    ? formik.errors.description
                                    : 'false'
                                }
                                onChange={(e) => {
                                    const str = e.target.value.trim() !== '' ? e.target.value : '';
                                    formik.setFieldValue("description", str)
                                }}
                            />

                            <div className="d-flex justify-content-between w-100">
                                <StyledButton
                                    width="35%"
                                    btnStyle="grayBtn"
                                    type="reset"
                                >
                                    بستن
                                </StyledButton>

                                <StyledButton
                                    width="35%"
                                    disabled={!formik.isValid || !formik.dirty}
                                    btnStyle="greenBtn"
                                    type="submit"
                                >
                                    افزودن کامنت
                                </StyledButton>
                            </div>
                        </StyledForm>

                    )}
                </Formik>
            </div>
        </>
    )
}

export default AddGuestCommentModal