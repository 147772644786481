import { Formik } from "formik";
import React from 'react';
import { toast } from "react-toastify";
import { StyledButton } from '../../../../Component/Kit/Button';
import {StyledForm, StyledInput, StyledTextArea} from '../../../../Component/Kit/Form';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import { AddBlockedIPSchema } from '../../../../Utility/Validation/Schema/AddBlockedIPSchema';

const AddBlockIPModal = ({ onCloseModal, dataChange }) => {
    return (
        <>
            <Formik
                initialValues={{
                    ipAddress: '',
                    description: ''
                }}
                isInitialValid={false}
                enableReinitialize={true}
                validationSchema={AddBlockedIPSchema}
                onSubmit={(value, actions) => {
                    ApiClient.Post(Urls.BlockedIPLog(), {
                        ip: value.ipAddress,
                        description: value.description
                    })
                        .then(res => {
                            if (res.result) {
                                toast.success('IP با موفقیت افزوده شد.');
                                actions.resetForm();
                                dataChange(true);
                                onCloseModal(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                    >
                        <StyledInput
                            lable="IP آدرس "
                            focus={true}
                            type="text"
                            name="ipAddress"
                            placeholder="xxx.xxx.xxx.xxx"
                            id="ipAddress"
                            dir="ltr"
                            value={formik.values.ipAddress}
                            error={(formik.errors.ipAddress && formik.touched.ipAddress)
                                ? formik.errors.ipAddress
                                : 'false'
                            }
                            onChange={(e) => formik.setFieldValue("ipAddress", e.target.value)}
                        />

                        <StyledTextArea
                            lable="توضیحات"
                            focus={true}
                            type="text"
                            name="description"
                            id="description"
                            dir="rtl"
                            value={formik.values.description}
                            error={(formik.errors.description && formik.touched.description)
                                ? formik.errors.description
                                : 'false'
                            }
                            onChange={(e) => formik.setFieldValue("description", e.target.value)}
                        />

                        <div className="d-flex justify-content-between mt-3">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => onCloseModal(false)}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                اضافه کردن
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}

            </Formik>
        </>
    )
}

export default AddBlockIPModal