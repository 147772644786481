import React, {useCallback, useEffect, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import {toast} from 'react-toastify';
import Swal from "sweetalert2";
import {PageTitleLayout} from "../../../Component/Kit/Layout";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import AccountRechargeModal from "./AccountRechargeModal";
import CooperationProfitModal from "./CooperationProfitModal";
import {CreditTransferModal} from "./CreditTransferModal";
import DedicatedUserBasePriceModal from "./DedicatedUserBasePriceModal";
import Modal from "react-bootstrap/Modal";
import {PaymentConfirmationModal} from "../../Financial/OnlinePayment/PaymentConfirmationModal";
import OfficialPaymentStatusModal from "./OfficialPaymentStatusModal";

function UserFinancial() {
    let match = useRouteMatch();
    const [isOfficial, setisOfficial] = useState(false);
    const [accountRechargeModalVisible, setAccountRechargeModalVisible] = useState(false);
    const [creditTransferModalVisible, setCreditTransferModalVisible] = useState(false);
    const [dedicatedUserBasePriceModalVisible, setDedicatedUserBasePriceModalVisible] = useState(false);
    const [officialPaymentTypeModalVisible, setOfficialPaymentTypeModalVisible] = useState(false);
    const [dedicatedBasePriceId, setDedicatedBasePriceId] = useState(null);
    const [cooperationModal, setCooperationModal] = useState(false);
    const [basePrice, setBasePrice] = useState(0);
    const [cooperationProfit, setCooperationProfit] = useState({});
    const [haveCooperation, setHaveCooperation] = useState(false);
    const [currentCredit, setCurrentCredit] = useState(0);
    const [dedicatedBasePriceList, setDedicatedBasePriceList] = useState([]);
    const [dedicatedPrice, setDedicatedPrice] = useState(null);


    const convertToOfficial = () => {
        if (!isOfficial) {
            Swal.fire({
                title: `آیا مطمئن هستید؟`,
                text: "امیدوارم بدونی داری چیکار میکنی! راه بازگشتی وجود ندارد...",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "انصراف",
                cancelButtonColor: "#d33",
                confirmButtonText: "بله، مطمئن هستم",
            }).then(result => {
                if (result.isConfirmed) {
                    ApiClient.Patch(Urls.userOfficialInvoiceStatus(match.params.id)).then(res => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        setisOfficial(res.result);
                    })
                }
            })
        }
    };

    const accountRecharge = () => {
        setAccountRechargeModalVisible(true);
    }

    const openCreditTransferModal = (item) => {
        setCreditTransferModalVisible(true);
    };

    const openCooperationModal = () => {
        setCooperationModal(true)
    }

    const getDedicatedBasePriceList = useCallback(() => {
        ApiClient.Get(Urls.DedicatedBasePrice())
            .then(res => {
                setDedicatedBasePriceList(res.result.map(item => ({id: item.id, price: item.basePrice})))
            })
    }, []);

    const getUserFinancialSettings = useCallback(() => {
        ApiClient.Get(Urls.userFinancialSettings(match.params.id))
            .then(res => {
                    if (res.result) {
                        setisOfficial(res.result.couldHasOfficialInvoice);
                        setBasePrice(res.result.lastBasePrice);
                        setCooperationProfit({
                            cooperationCreditProfitPercent: res?.result?.cooperationCreditProfitPercent,
                            cooperationLineProfitPercent: res?.result?.cooperationLineProfitPercent
                        });
                        setDedicatedBasePriceId(res.result.dedicatedBasePriceId)
                        setHaveCooperation(true)
                    }
                }
            );

        ApiClient.Get(Urls.GetUserCreditCount(match.params.id)).then((res) => {
            setCurrentCredit(res.result);
        });

    }, [match.params.id]);
    const dedicatedBasePriceSubmit = () => {
        getDedicatedBasePriceList();
        getUserFinancialSettings();
    }
    useEffect(() => {
        getUserFinancialSettings();
        getDedicatedBasePriceList();
    }, [getUserFinancialSettings, getDedicatedBasePriceList]);

    useEffect(() => {
        if (dedicatedBasePriceList.length > 0 && dedicatedBasePriceId) {
            const price = dedicatedBasePriceList.find(f => f.id === dedicatedBasePriceId)?.price
            setDedicatedPrice(price ?? 0);

        }
        else if(dedicatedBasePriceList.length > 0 && !dedicatedBasePriceId){
            setDedicatedPrice(null);

        }
    }, [dedicatedBasePriceList, dedicatedBasePriceId]);


    return (
        <div className="position-relative">
            <PageTitleLayout>
                <h5>امورمالی</h5>
                <span>
                    در اینجا لیست امورمالی این کاربر مورد بررسی قرار می‌گیرد.
                </span>
            </PageTitleLayout>
            <div className="base-price-box">
                <div className="base-price ml-2">تعرفه اختصاصی:
                    <span>{dedicatedPrice ?? '----'}</span>
                </div>


                <div className="base-price ml-2">آخرین تعرفه خرید:
                    <span>{basePrice}</span>
                </div>

                <div className="base-price">اعتبار جاری حساب:
                    <span>{currentCredit}</span>
                </div>


            </div>
            <ul className="section-list">
                <li>
                    <Link
                        to={`/user/${match.params.id}/financial/invoice`}
                        className="section-list_item"
                    >
                        <i className="fas fa-file-invoice-dollar"></i>
                        فاکتور ها
                    </Link>
                </li>
                <li>
                    <Link to={`/user/${match.params.id}/financial/invoice/payments`} className="section-list_item">
                        <i className="far fa-credit-card"></i>
                        پرداخت کاربر
                    </Link>
                </li>
                <li>
                    <Link
                        to={`/user/${match.params.id}/financial/invoice/transactions`}
                        className="section-list_item"
                    >
                        <i className="far fa-money-check-alt"></i>
                        تراکنش های کاربر
                    </Link>
                </li>

                <li>
                    <span
                        onClick={() => openCreditTransferModal()}
                        className="section-list_item"
                    >
                        <i className="fas fa-exchange"></i>
                        افزایش / کاهش اعتبار
                    </span>
                </li>
                <li>
                    <span
                        onClick={() => openCooperationModal()}
                        className="section-list_item"
                    >
                        <i className="fas fa-handshake-alt"></i>
                        سود مشارکت
                    </span>
                </li>

                <li>
                    <span
                        onClick={() => setDedicatedUserBasePriceModalVisible(true)}
                        className="section-list_item"
                    >
                        <i className="fas fa-layer-group"></i>
                        تعرفه اختصاصی
                    </span>
                </li>

                <li>
                    <span
                        onClick={() => {setOfficialPaymentTypeModalVisible(true)}}
                        className="section-list_item"
                    >
                        <i className="fas fa-receipt"></i>
                        نوع پرداخت
                    </span>
                </li>
            </ul>
            {haveCooperation &&
                <CooperationProfitModal
                    profitPercent={cooperationProfit}
                    visible={cooperationModal}
                    setVisible={setCooperationModal}
                    onClose={() => setCooperationModal(false)}
                    onCancle={() => setCooperationModal(false)}
                    onsubmit={() => getUserFinancialSettings()}>
                </CooperationProfitModal>
            }

            <DedicatedUserBasePriceModal
                baseId={dedicatedBasePriceId}
                onsubmit={() => dedicatedBasePriceSubmit()}
                visible={dedicatedUserBasePriceModalVisible}
                setVisible={setDedicatedUserBasePriceModalVisible}
                onClose={() => setDedicatedUserBasePriceModalVisible(false)}
                onCancle={() => setDedicatedUserBasePriceModalVisible(false)}/>

            <AccountRechargeModal
                visible={accountRechargeModalVisible}
                setVisible={setAccountRechargeModalVisible}
                onClose={() => setAccountRechargeModalVisible(false)}
                onCancle={() => setAccountRechargeModalVisible(false)}
            >
            </AccountRechargeModal>


            {creditTransferModalVisible &&
                <CreditTransferModal
                    dedicatedPrice={dedicatedPrice}
                    visible={creditTransferModalVisible}
                    setVisible={setCreditTransferModalVisible}
                    onClose={() => setCreditTransferModalVisible(false)}
                    onCancle={() => setCreditTransferModalVisible(false)}
                    onsubmit={() => getUserFinancialSettings()}>
                </CreditTransferModal>
            }

            <Modal centered show={officialPaymentTypeModalVisible} onHide={() => {
                setOfficialPaymentTypeModalVisible(false)
            }}>
                <Modal.Header>
                    <Modal.Title>نوع پرداخت</Modal.Title>
                    <i onClick={() => {
                        setOfficialPaymentTypeModalVisible(false)
                    }} className="close-btn fa fa-xmark"/>
                </Modal.Header>
                <Modal.Body>
                    <OfficialPaymentStatusModal onClose={() => {setOfficialPaymentTypeModalVisible(false)}}
                                                onSubmit={() => {setOfficialPaymentTypeModalVisible(false)}}
                    />
                </Modal.Body>

            </Modal>

        </div>
    );
}

export default UserFinancial;
