import React, { useState, useEffect, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { PageTitleLayout } from "../../../Component/Kit/Layout";
import { StyledButton } from "../../../Component/Kit/Button";
import DateConvert from "../../../Utility/Services/date-convert";
import Modal from "../../../Component/Kit/Modal";
import CommentView from "./comment-view";
import AddCommentModal from "./add-comment-modal";

function UserComments() {
    let match = useRouteMatch();
    const [commentsList, setcommentsList] = useState([]);
    const [detailsModal, setdetailsModal] = useState(false);
    const [addCommnetModal, setaddCommnetModal] = useState(false);
    const [modalData, setmodalData] = useState({});
    const tableConfig = {
        header: {
            content: "متن",
            adminUserName: "نام ادمین",
            createdAt: "تاریخ ایجاد",
            forGuest: "ثبت در وضعیت"
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
        },
    };
    const tableActions = (item) => {
        switch (item.actionName) {
            case "view":
                setdetailsModal(true);
                // let rowData = commentsList.find((m) => m.id === item.userId);
                setmodalData(item.data);
                break;
            default:
                break;
        }
    };
    const commentsHandler = (event) => {
        if (event) {
            getComments()
            setaddCommnetModal(false);
        }
    };
    const getComments = useCallback(() => {
        ApiClient.Get(Urls.userComments(match.params.id))
            .then((res) => {
                let data = res.result;
                data.map((m, i) => {
                    m.id = i + 1;
                    m.forGuest = m.forGuest ? 'مهمان' : 'کاربر';
                    m.createdAt = DateConvert(m.createdAt);
                    return m;
                });

                setcommentsList(data);
            });
    }, [match.params.id])
    useEffect(() => {
        getComments();
    }, [match.params.id, getComments]);
    return (
        <>
            <PageTitleLayout>
                <h5>نظرات کاربر</h5>
                <span>
                    در اینجا لیست نظرات کاربر این کاربر مورد بررسی قرار می‌گیرد.
                </span>
            </PageTitleLayout>
            <div className="pb-3 text-left">
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setaddCommnetModal(true)}
                >
                    ایجاد نظر جدید
                </StyledButton>
            </div>
            <Table
                data={commentsList}
                config={tableConfig}
                actionDispatched={tableActions}
            />

            <Modal
                onClose={() => setdetailsModal(false)}
                onCancel={() => setdetailsModal(false)}
                visible={detailsModal}
                headerTitle="کامنت"
            >
                <CommentView data={modalData} onCloseModal={(e) => setdetailsModal(e)} />
            </Modal>

            <Modal
                onClose={() => setaddCommnetModal(false)}
                onCancel={() => setaddCommnetModal(false)}
                visible={addCommnetModal}
                headerTitle="اضافه کردن کامنت"
            >
                <AddCommentModal dataChange={commentsHandler} onCloseModal={(e) => setaddCommnetModal(e)} />
            </Modal>
        </>
    );
}

export default UserComments;
