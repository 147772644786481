import userIncompleteFieldsModel from "../Models/user-incomplete-fields";
function getUserIncompleteFields(value) {
    let result = [];
    userIncompleteFieldsModel.forEach((item) => {
        value.forEach((element) => {
            if (item.value === element) result.push(item.name);
        });
    });
    return result;
}

export default getUserIncompleteFields;