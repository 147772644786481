import {sendSMSModel, sendSMSStatusModel} from "../Model/send-sms.model";

export function sendSMSType(value) {
    let result = sendSMSModel.find((item) => item.value === value);
    return result.name;
}

export function sendSMSStatus(value) {
    let result = sendSMSStatusModel.find((item) => item.value === value);
    return result.name;
}

