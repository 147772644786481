import React from "react";
import {NavLink, Route, Switch} from "react-router-dom";
import {MainContent} from "../../Component/Kit/Layout";
import BlockedIPList from "./BlockedIP/BlockedIPList";
import BlockedNumbersList from "./BlockedNumbers/BlockedNumbersList";

const Suspended = ({match}) => {
    return(
        <MainContent>
            <ul className="user-tab-menu">
                <li>
                    <NavLink activeClassName="is-active" to={`${match.url}/blocked-ip`}>
                        IP های مسدود شده
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="is-active" to={`${match.url}/numbers`}>
                        شماره های مسدود شده
                    </NavLink>
                </li>
            </ul>
            <div className="p-3">
                <Switch>
                    <Route exact path={`${match.path}/blocked-ip`}>
                        <BlockedIPList/>
                    </Route>
                    <Route path={`${match.path}/numbers`}>
                        <BlockedNumbersList/>
                    </Route>
                </Switch>
            </div>
        </MainContent>
    )
}

export default Suspended;