import { firstName, lastName, fatherName, username, setPassword, department, roleId, email, mobile ,nationalId} from "../Rules";
import * as Yup from "yup";

export const AddUserAdminSchema = Yup.object().shape({
    department,
    firstName,
    fatherName,
    lastName,
    username,
    setPassword,
    roleId,
    email,
    mobile,
    nationalId
});
