import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { StyledButton } from '../../../../Component/Kit/Button';
import { StyledForm, StyledInput } from '../../../../Component/Kit/Form';
import { SeprateNumberWithComma } from '../../../../Utility/Services/SeprateNumberWithComma';
import { useParams } from 'react-router-dom';

const PlansBasePriceStep = (props) => {
    const { step, updateState, initalData, formData, tiers } = props;
    const [planBasePriceDisCount, setPlanBasePriceDisCount] = useState([]);
    const params = useParams();

    const discountFieldName = {
        fromPrice: 'از قیمت',
        toPrice: 'تا قیمت',
        basePrice: 'قیمت پایه ',
        planBasePriceBronze: 'تعرفه پلن برنزی',
        planBasePriceSilver: 'تعرفه پلن نقره‌ای',
        planBasePriceGold: 'تعرفه پلن طلایی',
    }

    useEffect(() => {
        let planBasePrice = {};

        if (Boolean(formData) && Object.entries(formData).length !== 0) {
            // form in Edit mode or create
            setPlanBasePriceDisCount(formData.planBasePriceDisCount);
        }
        else {
            if (Object.entries(initalData).length > 0 && params.id) {
                //  edit mode without changing 
                for (let positionId = 1; positionId <= 3; positionId++) {
                    const filterByPlanPosition = initalData.publicDiscountCodePlanCreditTiers.filter(item => item.planPosition === positionId);
                    const basePrices = Object.assign({}, ...filterByPlanPosition.map((x) => ({ [x.creditTierId]: x.discountedBasePrice })));
                    planBasePrice = { ...planBasePrice, [positionId]: basePrices }
                }
                setPlanBasePriceDisCount(planBasePrice);
            }
            else {
                // first time create 
                for (let positionId = 1; positionId <= 3; positionId++) {
                    planBasePrice[positionId] = Array(Object.entries(tiers).length).fill()
                    setPlanBasePriceDisCount(planBasePrice);
                }
            }
        }

    }, [tiers]);

    return (
        <div className='row'>
            {
                Object.entries(planBasePriceDisCount).length > 0 &&
                <Formik
                    initialValues={{
                        planBasePriceDisCount,
                    }}
                    onSubmit={(values, actions) => {
                        // console.log(values);
                        /*  let data = {}
                         for (let positionId = 1; positionId <= 3; positionId++) {
                             const x = { ...values.planBasePriceDisCount[positionId] }
                             data = { ...data, [positionId]: x }
                         }
                         updateState({ step: step + 1, data: data }) */
                        updateState({ step: step + 1, data: values })
                    }}
                >
                    {(formik) => (
                        <StyledForm
                            onSubmit={formik.handleSubmit}
                            onBlur={formik.handleBlur}
                            onReset={formik.handleReset}
                        >
                            <div className="table-responsive custom-table p-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" key={`headLine_#`}>#</th>
                                            {
                                                discountFieldName &&
                                                Object.entries(discountFieldName).map(([item, value]) => (
                                                    <th scope="col" key={`headLine_${item}`}>{value}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {Object.values(tiers).map((dataItem, index) => (
                                            <>
                                                <tr key={`planTierId-${dataItem.id}`}>
                                                    <th scope="row">{index + 1}</th>

                                                    <td> {SeprateNumberWithComma(dataItem.fromPrice)} ریال</td>
                                                    <td>{SeprateNumberWithComma(dataItem.toPrice)} ریال</td>

                                                    <td>{dataItem.basePrice}</td>
                                                    <td>
                                                        <StyledInput
                                                            formGroupClass="planTierInput"
                                                            type="text"
                                                            focus={false}
                                                            name={`planBasePriceDisCount[1][${dataItem.id}]`}
                                                            id={`planBasePriceDisCount[1].${dataItem.id}`}
                                                            key={`planBasePriceDisCount[1].${dataItem.id}`}
                                                            value={formik.values.planBasePriceDisCount[1][dataItem.id] !== undefined
                                                                ? formik.values.planBasePriceDisCount[1][dataItem.id]
                                                                : formik.initialValues.planBasePriceDisCount[1][dataItem.id]}
                                                            error={(formik.touched && formik.touched.planBasePriceDisCount && formik.touched.planBasePriceDisCount[1] && formik.touched.planBasePriceDisCount[1][dataItem.id] && !formik.values.planBasePriceDisCount[1][dataItem.id])
                                                                ? "این فیلد الزامی است."
                                                                : ((formik.errors && formik.errors.planBasePriceDisCount && formik.errors.planBasePriceDisCount[1] && formik.errors.planBasePriceDisCount[1][dataItem.id]) ? formik.errors.basePriceDisCount[dataItem.id] : 'false')
                                                            }
                                                            onChange={(e) => formik.setFieldValue(`planBasePriceDisCount[1].${dataItem.id}`, e.target.value)}
                                                        /></td>
                                                    <td>
                                                        <StyledInput
                                                            formGroupClass="planTierInput"
                                                            type="text"
                                                            focus={false}
                                                            name={`planBasePriceDisCount[2][${dataItem.id}]`}
                                                            id={`planBasePriceDisCount[2].${dataItem.id}`}
                                                            key={`planBasePriceDisCount[2].${dataItem.id}`}
                                                            value={formik.values.planBasePriceDisCount[2][dataItem.id] !== undefined
                                                                ? formik.values.planBasePriceDisCount[2][dataItem.id]
                                                                : formik.initialValues.planBasePriceDisCount[2][dataItem.id]}
                                                            error={(formik.touched && formik.touched.planBasePriceDisCount && formik.touched.planBasePriceDisCount[2] && formik.touched.planBasePriceDisCount[2][dataItem.id] && !formik.values.planBasePriceDisCount[2][dataItem.id])
                                                                ? "این فیلد الزامی است."
                                                                : ((formik.errors && formik.errors.planBasePriceDisCount && formik.errors.planBasePriceDisCount[2] && formik.errors.planBasePriceDisCount[2][dataItem.id]) ? formik.errors.basePriceDisCount[dataItem.id] : 'false')
                                                            }
                                                            onChange={(e) => formik.setFieldValue(`planBasePriceDisCount[2].${dataItem.id}`, e.target.value)}
                                                        /></td>
                                                    <td>
                                                        <StyledInput
                                                            formGroupClass="planTierInput"
                                                            type="text"
                                                            focus={false}
                                                            name={`planBasePriceDisCount[3][${dataItem.id}]`}
                                                            id={`planBasePriceDisCount[3].${dataItem.id}`}
                                                            key={`planBasePriceDisCount[3].${dataItem.id}`}
                                                            value={formik.values.planBasePriceDisCount[3][dataItem.id] !== undefined
                                                                ? formik.values.planBasePriceDisCount[3][dataItem.id]
                                                                : formik.initialValues.planBasePriceDisCount[3][dataItem.id]}
                                                            error={(formik.touched && formik.touched.planBasePriceDisCount && formik.touched.planBasePriceDisCount[3] && formik.touched.planBasePriceDisCount[3][dataItem.id] && !formik.values.planBasePriceDisCount[3][dataItem.id])
                                                                ? "این فیلد الزامی است."
                                                                : ((formik.errors && formik.errors.planBasePriceDisCount && formik.errors.planBasePriceDisCount[3] && formik.errors.planBasePriceDisCount[3][dataItem.id]) ? formik.errors.basePriceDisCount[dataItem.id] : 'false')
                                                            }
                                                            onChange={(e) => formik.setFieldValue(`planBasePriceDisCount[3].${dataItem.id}`, e.target.value)}
                                                        /></td>
                                                </tr>
                                                <tr className="spacer" key={`spacer${dataItem.id}`}>
                                                    <td colSpan="100"></td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="mt-4 d-flex justify-content-between">
                                <StyledButton
                                    onClick={() => updateState({ step: step - 1 })}
                                    btnStyle="grayBtn"
                                    type="button"
                                    id="prevStep"
                                >
                                    مرحله قبلی
                                </StyledButton>
                                <StyledButton
                                    disabled={!formik.isValid || (!formik.dirty && !initalData.publicDiscountCodePlanCreditTiers && (formData && !formData.planBasePriceDisCount))}
                                    btnStyle="greenBtn"
                                    type="submit"
                                    id="finalStep"
                                >
                                    تایید نهایی
                                </StyledButton>
                            </div>
                        </StyledForm>
                    )}
                </Formik>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    tiers: state.BasePriceTiersReducer,
});

export default connect(mapStateToProps)(PlansBasePriceStep);