import { Formik } from "formik";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { toast } from "react-toastify";
import {StyledForm, StyledInput, StyledSelect } from "../../../../Component/Kit/Form";
import { StyledButton } from "../../../../Component/Kit/Button";
import React from "react";
import { SystemLineProviderEnum } from "../../../../Utility/Model/provider-enum.model";
import {PublicLineSchema} from "../../../../Utility/Validation/Schema/PublicLineSchema";

function PublicLinesModal({ onCloseModal, onSubmit }) {
    return (
        <>
            <Formik
                initialValues={{
                    number: '',
                    providerId: ''
                }}
                validationSchema={PublicLineSchema}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                        ApiClient.Post(Urls.publicLines(), values)
                            .then((res) => {
                                toast.success('عملیات با موفقیت انجام شد');
                                actions.resetForm();
                                onCloseModal(false)
                                onSubmit()
                            }).catch().finally(() => { actions.setSubmitting(false) })
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}>
                        <StyledInput
                            lable="شماره خط"
                            focus={true}
                            type="text"
                            name="number"
                            value={formik.values.number}
                            error={(formik.errors.number && formik.touched.number)
                                ? formik.errors.number
                                : 'false'
                            }
                            onChange={(e) =>
                                formik.setFieldValue("number", e.target.value)
                            }
                        />

                        <StyledSelect
                            formGroupClass="w-100 my-3"
                            lable="ارائه دهنده خدمات"
                            focus={true}
                            name="providerId"
                            value={formik.values.providerId !== undefined
                                ? formik.values.providerId
                                : formik.initialValues.providerId}
                            optionItems={SystemLineProviderEnum}
                            hasDefaultOption
                            error={(formik.errors.providerId && formik.touched.providerId)
                                ? formik.errors.providerId
                                : 'false'
                            }
                            onChange={(e) => formik.setFieldValue("providerId", e.target.value)}
                        />


                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting ? 1 : 0}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                اضافه کردن
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    );
}
export default PublicLinesModal
