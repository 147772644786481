import React, { useState, useEffect } from 'react'
import WindowsDimensions from './WindowsDimensions';

export function HandleResizeWindow() {

  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

  const [screenStatus, setScreenStatus] = useState(WindowsDimensions.resize())
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateSize = debounce(function handleResize() {
      let x = WindowsDimensions.resize();
      setScreenStatus(x);
      setWidth(window.innerWidth);
      // console.log("object")
    }, 300)

    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  },

    [width, screenStatus]
  );
  return screenStatus
}