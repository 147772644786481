import React, {useCallback, useEffect, useState} from "react";
import {NavLink, Redirect, Route, Switch, useLocation} from "react-router-dom";
import BackToPage from "../../Component/Kit/back";
import {HeaderContent, MainContent} from "../../Component/Kit/Layout";
import PendingSMS from "./list";
import RepeatSMs from "./Repeat-sms";
import RepeatSmsHistory from "./Repeat-sms-history";
import RepeatVerifySMs from "./Repeat-verify-sms";
import PublicLine from "./Public-line";
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import {Badge} from 'react-bootstrap';

function PendingSmsPages({match}) {
    let location = useLocation();
    const [prev, setPrev] = useState('')
    const [smsCount, setSmsCount] = useState(null);

    const getBadgeData = useCallback(() => {
        ApiClient.Get(
            Urls.getCountSMS()
        ).then((res) => {
            let data = res.result;
            setSmsCount(data);
        });
    }, []);

    useEffect(() => {
        setPrev(location.state && location.state.from ? `/pending-sms/${location.state.from}` : '/pending-sms');
        getBadgeData()
    }, [location.state, getBadgeData])


    useEffect(() => {
        const interval = setInterval(() => {
            getBadgeData()
        }, 5000);
        return () => {
            clearInterval(interval);
        }
    }, [getBadgeData])


    return (
        <>
            <HeaderContent className="justify-content-end">
                <BackToPage toPage={prev}/>
            </HeaderContent>
            <MainContent>
                <ul className="user-tab-menu">
                    <li>

                        <NavLink activeClassName="is-active" to={`${match.url}/list`}>

                            لیست پیام ها

                            {

                                smsCount?.pendingSendMessagesCount > 0 ?

                                    <Badge pill className="font-weight-normal mr-1 d-flex align-items-center"
                                           variant="warning">

                                        {
                                            smsCount.pendingSendMessagesCount
                                        }

                                    </Badge>

                                    :

                                    smsCount?.pendingSendMessagesCount == 0 ?
                                        <>
                                            <i className="fa fa-check-circle text-success d-flex align-items-center mr-1"/>
                                        </>
                                        : <></>

                            }

                        </NavLink>
                    </li>
                    <li>

                        <NavLink activeClassName="is-active" to={`${match.url}/repeat-sms`}>
                            پیام های پر تکرار در انتظار

                            {

                                smsCount?.frequentMessageTextsCount > 0 ?

                                    <Badge pill className="font-weight-normal mr-1 d-flex align-items-center"
                                           variant="warning">

                                        {
                                            smsCount.frequentMessageTextsCount
                                        }

                                    </Badge>

                                    :

                                    smsCount?.frequentMessageTextsCount == 0 ?
                                        <>
                                            <i className="fa fa-check-circle text-success d-flex align-items-center mr-1"/>
                                        </>
                                        : <></>

                            }

                        </NavLink>
                    </li>

                    <li>

                        <NavLink activeClassName="is-active" to={`${match.url}/webservice-repeat`}>

                            محدودیت ارسال وب سرویس

                            {

                                smsCount?.highConsumptionWebServiceUsersCount > 0 ?

                                    <Badge pill className="font-weight-normal mr-1 d-flex align-items-center"
                                           variant="warning">

                                        {
                                            smsCount.highConsumptionWebServiceUsersCount
                                        }

                                    </Badge>

                                    :
                                    smsCount?.highConsumptionWebServiceUsersCount == 0 ?
                                        <>
                                            <i className="fa fa-check-circle text-success d-flex align-items-center mr-1"/>
                                        </>
                                        : <></>

                            }


                        </NavLink>
                    </li>
                    <li>

                        <NavLink activeClassName="is-active" to={`${match.url}/public-line`}>
                            خطوط ویژه


                            {

                                smsCount?.pendingPublicLineSendMessagesCount > 0 ?

                                    <Badge pill className="font-weight-normal mr-1 d-flex align-items-center"
                                           variant="warning">

                                        {
                                            smsCount.pendingPublicLineSendMessagesCount
                                        }

                                    </Badge>

                                    :
                                    smsCount?.pendingPublicLineSendMessagesCount == 0 ?
                                        <>
                                            <i className="fa fa-check-circle text-success d-flex align-items-center mr-1"/>
                                        </>
                                        : <></>

                            }


                        </NavLink>
                    </li>


                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/history-repeat?isValid=true`}>
                            تاریخچه پیام های پر تکرار
                        </NavLink>
                    </li>
                </ul>
                <Switch>
                    <Route exact path={`${match.path}/list`}>
                        <PendingSMS/>
                    </Route>
                    <Route path={`${match.path}/repeat-sms`}>
                        <RepeatSMs/>
                    </Route>
                    <Route path={`${match.path}/history-repeat`}>
                        <RepeatSmsHistory/>
                    </Route>
                    <Route path={`${match.path}/webservice-repeat`} exact>
                        <RepeatVerifySMs/>
                    </Route>
                    <Route path={`${match.path}/public-line`} exact>
                        <PublicLine/>
                    </Route>
                    <Redirect to={`${match.path}/list`}/>
                </Switch>
            </MainContent>
        </>
    );
}

export default PendingSmsPages;
