import React, {useCallback, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {PageTitleLayout} from '../../../Component/Kit/Layout'
import Modal from 'react-bootstrap/Modal';
import Table from '../../../Component/Shared/Table/Table'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import {ConvertQueryString} from "../../../Utility/Services/convert-query-string";
import {ActionNameModel, SendStatusModel} from "../../../Utility/Model/web-service.model";
import DateConvert from "../../../Utility/Services/date-convert";
import {Loader} from "../../../Component/Shared/Loader";
import GetProvider from "../../../Utility/Services/provider";

export const WebService = () => {
    const location = useLocation();
    const {id} = useParams();

    const [logs, setLogs] = useState();
    const [selectedLog, setSelectedLog] = useState(null);
    const [webServiceModal, setWebServiceModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const webServiceModalTitle = {
        statusCode: 'کد وضعیت',
        description: 'توضیحات',
        actionName: 'نام عملیات',
        createdAt: 'تاریخ ایجاد',
        count: 'تعداد رخداد',
        modifiedAt: 'تاریخ آخرین رخداد',
        username: 'نام کاربری',
        ip: 'آیپی',
        httpMethod: 'متد',
        requestUrl: 'آدرس URL',
        httpStatusCode: 'httpStatusCode',
        requestBody: 'body',
        exceptionMessage: 'پیغام exception'
    }

    const tableConfig = {
        actions: {
            view: {
                icon: "fa fa-eye",
            },
        },

        header: {
            statusCode: 'کد وضعیت',
            description: 'توضیحات',
            actionName: 'نام عملیات',
            createdAt: 'تاریخ ایجاد',
            count: 'تعداد رخداد',
            modifiedAt: 'تاریخ آخرین رخداد',
        },
        mode: ["filter"],
        filters: ["datePickerFilter" ],
    };
    const getLogs = useCallback(() => {
        const params = {
            ...ConvertQueryString(location.search),
            userId: id,
        }

        ApiClient.Get(Urls.webServiceLogs(), params)
            .then(res => {
                let data = res.result;

                data.items.map((item) => {
                    item.actionName = ActionNameModel.find(i => i.value === item.actionName)?.name
                    item.createdAt = DateConvert(item.createdAt);
                    item.modifiedAt = DateConvert(item.modifiedAt);
                    item.description = SendStatusModel.find(i => i.value === item.statusCode)?.name
                    return item;
                });

                setLogs(res.result);
                setLoading(false);
            })
    }, [location.search]);

    useEffect(() => {
        getLogs();
    }, [getLogs]);

    const tableActions = (item) => {
        switch (item.actionName) {
            case 'view':
                setSelectedLog(item.data);
                setWebServiceModal(true);
                break;
            default:
                break;
        }
    };

    const handleClose = (event) => {
        if (event) setWebServiceModal(false);
    }

    return (
        <>
            <div className="pb-3 d-flex justify-content-between align-item-center">
                <PageTitleLayout>
                    <h5>لیست آخرین خطاهای وب سرویس</h5>
                    <span>
                        در اینجا لیست آخرین خطاهای فراخوانی وب سرویس کاربر مورد بررسی قرار می‌گیرد.
                    </span>
                </PageTitleLayout>

            </div>

            {
                !loading ?
                    <>

                        <Table
                            data={logs.items}
                            config={tableConfig}
                            pageConfig={{
                                pageNumber: logs.pageNumber,
                                pageSize: logs.pageSize,
                                totalItemsCount: logs.totalItemsCount,
                                totalPagesCount: logs.totalPagesCount,
                            }}
                            actionDispatched={(e) => tableActions(e)}
                        />


                    </>

                    :
                    <> <Loader/> </>
            }


            <Modal centered show={webServiceModal} onHide={() => {
                handleClose(true)
            }}>
                <Modal.Header>
                    <Modal.Title>جزئیات فراخوانی سرویس</Modal.Title>
                    <i onClick={() => {
                        handleClose(true)
                    }} className="close-btn fa fa-xmark pointer"/>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-100">
                        <ul className="params">
                            {
                                (webServiceModalTitle && selectedLog) &&
                                Object.entries(webServiceModalTitle).map(([key, value]) => (

                                    <li className="params__list" key={`${key}_${selectedLog.id}`}>
                                        <div dir={'rtl'} className="params__list__label">
                                            <span>{value}: </span>
                                        </div>
                                        <div className="params__list__value">
                                            <span dir={'rtl'} className="params__list__label--white"
                                                  style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>{selectedLog[key]}</span>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default WebService;