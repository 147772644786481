import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Col from "./Column";
import { CheckBox } from "./Checkbox";
import { useOnClickOutside } from "../../Utility/method/useOnClickOutside";
import { StyledButton } from './Button';


export const FormLayout = styled.div`
  border: 1px dashed #a8d2ff;
  border-top: 1px solid #a8d2ff;
  border-radius: 5px;
  padding: 15px;
  position: relative;
  margin-bottom: 30px;

  .form-title {
    position: absolute;
    background: #fff;
    top: -10px;
    right: 0;
    padding: 0 17px;
    font-size: 0.9rem;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 15px;
  /* box-shadow: 0 0 11px #ccc; */
  border-radius: 0.4rem;
`;

export const FormGroup = styled(Col)`
  flex-direction: column;
  min-height: 33px;
  height: 100%;
  width: 100%;
  margin: 0 0 1rem 0;
  border-radius: 5px;
  /* flex-direction: row-reverse;
  flex-wrap: wrap; */
  outline: none;
  position: relative;

  :focus {
    outline: none;
  }

  &.uploadGrop {
    display: block;
    height: 100%;
    top: 0px;
    position: absolute;
    text-align: center;
    /* overflow: hidden; */
    width: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;

    label {
      text-align: center;
      line-height: 2.2rem;
      font-family: IRANSans;
      font-size: 12px;
      width: inherit;
      height: 100%;
    }
  }

  .uploadBox {
    position: relative;
    width: 100%;
    height: 170px;
    display: block;
    margin: 0 auto;

    .uploadBar {
      background-color: rgba(191, 189, 189, 0.6);
      -webkit-transition: display 500ms ease-out 1s;
      -moz-transition: display 500ms ease-out 1s;
      -o-transition: display 500ms ease-out 1s;
      transition: display 500ms ease-out 1s;
      cursor: pointer;
      width: 100%;
      height: 36px;
      display: block;
      color: ${(props) => props.theme.gray};
      position: absolute;
      line-height: 2.5rem;
      bottom: 0px;
      border-radius: 0 0 0.4rem 0.4rem;
    }
  }
`;

export const FormInput = styled.input`
  font-family: "IRANSans";
  border: ${(props) =>
    props.error === "false"
      ? "1px solid #ced4da"
      : `1px solid ${props.theme.red}`};
  color: ${(props) => (props.readOnly ? "#a8a8a8" : "#495057")};
  color: ${(props) => (props.disabled ? "#a8a8a8" : "#495057")};
  cursor: ${(props) => props.readOnly === true && "no-drop"};
  cursor: ${(props) => props.disabled === true && "no-drop"};
  background-color: ${(props) => (props.readOnly ? "#e8e8e8" : "white")};
  background-color: ${(props) => (props.disabled ? "#e8e8e8" : "white")};
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
  width: ${(props) => (props.otpCode === true ? "10%" : "100%")};
  margin: ${(props) => (props.otpCode === true ? "0 1%" : "0")};
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: normal;
  line-height: 1.5;
  font-size: 1rem;
  background-clip: padding-box;
  border-radius: 0.25rem;
  text-align: ${(props) => (props.otpCode === true ? "center" : "initial")};

  :focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #00b9bc;
    transition: 0.3s;
  }

  ::placeholder {
    text-align: right;
  }

  :disabled {
    background: #ddd;
  }

  :read-only {
    background: #e8e8e8;
  }

  :-moz-placeholder,
  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    text-align: right;
  }
`;

export const FormSelect = styled.select`
  border: ${(props) =>
    props.error === "false"
      ? "1px solid #ced4da"
      : `1px solid ${props.theme.red}`};
  color: ${(props) => props.readOnly === true && props.theme.gray};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #495057;
  height: ${(props) => (props.multiple ? "6rem !important" : "auto")};
`;

export const FormTextArea = styled.textarea`
  border: ${(props) =>
          props.error === "false"
                  ? "1px solid #ced4da"
                  : `1px solid ${props.theme.red}`};
  cursor: ${(props) => props.readOnly === true && "no-drop"};
    /* background-color: ${(props) => (props.readOnly ? "#f7f7f7" : "white")}; */
  color: ${(props) => (props.readOnly ? "#a8a8a8" : "#495057")};
  background-color: ${(props) => (props.readOnly ? "#e8e8e8" : "white")};

  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
  width: 100%;
  margin: 0;
  resize: none;
  height: ${(props) =>
          props.height ? props.height : "100%"};
  padding: 0.375rem 0.75rem;
  font-weight: normal;
  line-height: 1.5;
  font-size: 1rem;
  background-clip: padding-box;
  border-radius: 0.25rem;
  direction: rtl;
  text-align: ${(props) => (props.otpCode === true ? "center" : "right")};
`;

export const FormInputCode = styled.input`
  border: 1px solid #ced4da;
  border: ${(props) =>
    props.error === false
      ? "1px solid #ced4da"
      : `1px solid ${props.theme.red}`};

  color: ${(props) => props.readOnly === true && props.theme.gray};
  cursor: ${(props) => props.readOnly === true && "no-drop"};
  background-color: ${(props) => props.theme.white};
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
  width: 14.65%;
  margin: 0 1%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: normal;
  line-height: 1.5;
  font-size: 1rem;
  color: #495057;
  background-clip: padding-box;
  border-radius: 0.25rem;
  direction: ltr;
  text-align: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  /* Chrome, Safari, Edge, Opera */

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type="number"] {
    -moz-appearance: textfield;
  }

  :focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #00b9bc;
    transition: 0.3s;
  }

  ::placeholder {
    direction: rtl;
  }
`;

export const FormLable = styled.label`
  background-color: white;
  display: ${(props) => (props.show !== true ? "none" : "flex")};
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.label};
  padding: 5px;
  margin-bottom: 0.1rem;
  font-weight: 600;
  text-align: right;
  font-size: 12px;
  width: 100%;

  > button {
    border: 0;
    background-color: transparent;
    color: #6993ff;

    &:focus {
      outline: none;
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  /* animation: fade 300ms ease 10ms 1 forwards ; */
`;

export const FormError = styled(Col)`
  display: ${(props) => (props.show === "false" ? "none" : "block")};
  color: red;
  font-size: 11px;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 0px 5px 0px;
  height: auto;
  text-align: right;
`;

const copyCodeToClipboard = (e) => {
  e.persist();
  const el = e.currentTarget.parentNode.nextSibling;
  el.select();
  document.execCommand("copy");
};

export const StyledInput = (props) => {
  const {
    lable,
    focus,
    formGroupClass,
    isCopyCapability,
    disabled = false,
  } = props;
  return (
    <FormGroup className={formGroupClass}>
      <FormLable show={focus}>
        {lable}
        {isCopyCapability ? (
          <button type="button" onClick={(e) => copyCodeToClipboard(e)}>
            <i className="far fa-copy fa-lg" />
          </button>
        ) : null}
      </FormLable>

      <FormInput {...props} value={props.value || ""} disabled={disabled} />
      <FormError show={props.error}>{props.error} </FormError>
    </FormGroup>
  );
};

export const RangePicker = (props) => {
  return (
    <FormGroup className={props.formGroupClass}>
      <FormInput {...props} />
      <FormError show={props.error}>{props.error} </FormError>
    </FormGroup>
  );
};

export const StyledOtpCode = (props) => {
  return (
    <>
      <FormInputCode {...props} />
    </>
  );
};

export const StyledSelect = (props) => {
  const { lable, error, focus, optionItems, hasDefaultOption } = props;

  return (
    <FormGroup className={props.formGroupClass}>
      <FormLable show={focus}> {lable}</FormLable>
      <FormSelect {...props} className="form-control">
        {hasDefaultOption && (
          <option
            value="defalut"
            disabled={`${props.value !== undefined && props.value !== "" ? "disable" : ""
              }`}
          >
            انتخاب کنید
          </option>
        )}
        {optionItems &&
          optionItems.map((item, index) => (
            <option value={item.value} key={index}>
              {item.name}
            </option>
          ))}
      </FormSelect>
      <FormError show={error}>{error} </FormError>
    </FormGroup>
  );
};

export const StyledTextArea = (props) => {
    const textareaRef = useRef(null);
    const { lable, focus, formGroupClass, isCopyCapability = false } = props;



  return (
    <FormGroup className={formGroupClass}>
      <FormLable show={focus}>
        {" "}
        {lable}{" "}
        {isCopyCapability ? (
          <button type="button" onClick={(e) => copyCodeToClipboard(e)}>
            <i className="far fa-copy fa-lg" />
          </button>
        ) : null}
      </FormLable>

      <FormTextArea  {...props} className={props.textAreaClass} />
      <FormError className="error" show={props.error}>
        {props.error}{" "}
      </FormError>
    </FormGroup>
  );
};

export const UploadButton = styled.span`
  display: inline-block;
  width: 125px;
  font-family: IRANSans;

  :focus {
    outline: none;
    border: none;
  }
`;

export const FormUploadInput = styled.input`
  direction: rtl;
  min-height: 36px;
  height: 100%;
  width: 100%;
  border-radius: 18px;
  position: absolute;
  cursor: ${(props) => props.readOnly === true && "no-drop"};
  background-color: ${(props) => props.theme.lightGray};
  padding: 0px 10px 0px 10px;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
  zoom: 1;
  right: 0;
  top: 0;

  :focus {
    outline: none;
  }

  ::placeholder {
    direction: rtl;
  }
`;


export const StyledInputUpload = (props) => {
  const { hasurl } = props;

  return (
    <FormGroup className="uploadGrop">
      {hasurl === null ? (
        <>
          <label htmlFor="file-input">
            <UploadButton />
          </label>
          <FormUploadInput {...props} id="file-input" />
        </>
      ) : (
        <div className="uploadBox" {...props}>
          <label htmlFor="file-input">
            <span className="uploadBar">تغییر فایل</span>
          </label>
          <FormUploadInput {...props} id="file-input" />
        </div>
      )}

      <FormError show={props.error}>{props.error} </FormError>
    </FormGroup>
  );
};


export const CustomeStyledInputUpload = (props) => {
  const { hasurl, error, name, placeholder, type, changeFile, removeUploadedFile, downloadUrl } = props;

  const clickHandler = (e) => {
    switch (e.target.name) {
      case "delete-file":
        removeUploadedFile();
        break;
    }
  }
  
  function downloadFile(url) {
    
    const a = document.createElement('a')
    a.href = url;
    a.target = "_blank";
    
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <FormGroup className="uploadGrop">
      {hasurl === null ? (

        <>
          <div className="w-100 h-100">

            <FormUploadInput type={type} onChange={e => changeFile(e)} placeholder={placeholder} name={name} id="file-input" />

          </div>

        </>

      ) : (
        <>
          <div className="uploadBox d-flex align-items-end" >

            <div className="d-flex w-100 justify-content-center">
              {
                downloadUrl ?

                  <>

                    <StyledButton
                      href={downloadUrl} target="_blank"
                      width="50%"
                      btnStyle="redBt m-2"
                      type="button"
                      onClick={ e => {downloadFile(downloadUrl)}}
                    >
                      دانلود فایل
                    </StyledButton>
                  </>
                  :
                  <>
                    <StyledButton
                      width="50%"
                      btnStyle="redBt m-2"
                      type="button"
                    >
                      <FormUploadInput type={type} onChange={e => changeFile(e)} placeholder={placeholder} name={name} id="file-input" />

                      تغییر فایل
                    </StyledButton>
                  </>
              }

              <StyledButton
                width="50%"
                btnStyle="redBtn m-2"
                type="button"
                name="delete-file"
                onClick={clickHandler}
              >
                حذف فایل
              </StyledButton>
            </div>
          </div>
        </>
      )
      }

      <FormError show={error}>{error} </FormError>

    </FormGroup >
  );
};

export const FormCheckBoxGroup = styled.div`
  position: relative;
  display: block;
  margin-bottom: 3px;
`;

export const FormCheckBox = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: ${(props) =>
    props.error === "false"
      ? "1px solid #1bc5bd"
      : `1px solid ${props.theme.red}`};
  color: ${(props) => props.readOnly === true && props.theme.gray};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const StyledCheckBox = (props) => {
  const { lable, focus, id } = props;
  return (
    <FormCheckBoxGroup className={props.formGroupClass}>
      <CheckBox {...props}>
        <label htmlFor={id}>
          <FormCheckBox
            {...props}
            checked={props.checked || false}
            type="checkbox"
            id={id}
          />
          {lable ? lable : " "}
        </label>
      </CheckBox>

      <FormError show={props.error || "false"}>{props.error} </FormError>
    </FormCheckBoxGroup>
  );
};

export const FormSwitchBoxGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const FormSwitchBox = styled.input`
  font-size: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:checked {
    background: ${(props) => props.theme.greenBtn};
  }

  &:after {
    position: absolute;
    content: "";
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    left: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  :checked:after {
    left: calc(100% - 1.5em);
  }
`;

export const StyledSwitchBox = (props) => {
  return (
    <FormSwitchBoxGroup className={props.formGroupClass}>
      <FormSwitchBox
        {...props}
        type="checkbox"
        disabled={props.disabled}
        checked={props.checked || false}
      />
      {/* <label className="mr-2 mb-0" show={props.lable}>{props.checked ? 'فعال' : 'غیرفعال'}</label> */}
    </FormSwitchBoxGroup>
  );
};

export const StyledRadioBox = styled.div`
  margin-left: 1rem;

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    display: none;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-right: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: ${(props) => props.theme.greenBtn};
    position: absolute;
    top: 3px;
    right: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  label {
    width: auto;
    margin-bottom: 0;
  }
`;
export const StyledRadioBtn = (props) => {
  return (
    <StyledRadioBox>
      <input type="radio" {...props} checked={props.checked || false} />
      <label htmlFor={props.id}>{props.label}</label>
    </StyledRadioBox>
  );
};
const AutocompleteWrapper = styled.div`
  width: 100%;
  position: relative;

  > input {
    border-radius: 4px;
    border: 1px solid #cecece;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    text-indent: 10px;
    font-size: 13px;

    &:focus {
      outline: none;
    }
  }

  > ul {
    display: none;
    padding: 0;
    background: #fff;
    border: 1px solid #cecece;
    border-top: none;
    margin-top: -2px;
    text-align: right;
    direction: rtl;
    position: absolute;
    top: calc(1.5em + 0.75rem + 2px);
    right: 0;
    width: 100%;
    max-height: 200px;
    border-radius: 0 0 4px 4px;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;

    &.open {
      display: block;
    }

    li {
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: #e2edee;
      }

      &.empty-list {
        text-align: center;
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;

export const AutocompleteList = (props) => {
  const {
    placeholder = "",
    options = [],
    keyName = "key",
    valueName = "value",
    inputHandler,
    selectItem,
    value,
  } = props;
  const [selectOpen, setSelectOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const ref = useRef();
  useOnClickOutside(ref, () => setSelectOpen(false));

  const changeInputHandler = (e) => {
    setSelectOpen(true);
    setInputText(e.target.value);
    inputHandler(e.target.value);
  };

  const selectHandler = (index) => {
    setSelectOpen(false);
    selectItem(options[index]);
    setInputText(options[index][valueName]);
  };

  useEffect(() => {
    if (!selectOpen) {
      const defaultValue = options.find((f) => f[keyName] === value);
      if (defaultValue) {
        setInputText(defaultValue[valueName]);
      }
    }
    if (!value) {
      setInputText("");
    }
  }, [value, options]);
  return (
    <>
      <AutocompleteWrapper ref={ref}>
        <input
          type="text"
          placeholder={placeholder}
          value={inputText}
          onClick={() => setSelectOpen(true)}
          onChange={(e) => changeInputHandler(e)}
        />
        <ul className={selectOpen ? "open" : undefined}>
          {options && options.length ? (
            options.map((item, index) => (
              <li onClick={() => selectHandler(index)} key={index}>
                {item[valueName]}
              </li>
            ))
          ) : (
            <li className="empty-list">موردی یافت نشد.</li>
          )}
        </ul>
      </AutocompleteWrapper>
    </>
  );
};
