import React, { useEffect, useState } from 'react';
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import DateConvert from "../../../Utility/Services/date-convert";
import { PageTitleLayout } from "../../../Component/Kit/Layout";
import Table from "../../../Component/Shared/Table/Table";
import Modal from "../../../Component/Kit/Modal"
import FastSendTemplatePendingModal from "./pending-modal";
import { FastSendTemplateStatus } from '../../../Utility/Model/fast-send-template.model';

const FastSendTemplatesPending = () => {
    const [pendingData, setPendingData] = useState([])
    const [isModal, setIsModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [isReject, setIsReject] = useState(false)

    const tableActions = (item) => {
        if (item.actionName === 'view') {
            let data = item.data;
            setModalData(data)
            setIsModal(true)
        }
    };

    const tableConfig = {
        header: {
            userName: "نام کاربری",
            id: "شناسه قالب",
            title: "عنوان قالب",
            templateText: "متن قالب",
            createdAt: "تاریخ ایجاد",
        },
        tooltipTitle: ['templateText'],
        actions: {
            view: {
                icon: "fa fa-eye",
            },

        },
    };

    useEffect(() => {
        getFastSendTemplatePendingList();
    }, [])

    const modalDataChange = (item) => {
        if (item) getFastSendTemplatePendingList()
    }
    const getFastSendTemplatePendingList = () => {
        let params = {
            statuses: FastSendTemplateStatus.Pending
        }
        ApiClient.Get(Urls.getFastSendTemplates(), params)
            .then(res => {
                let data = res.result;
                data.items.map(m => {
                    m.createdAt = DateConvert(m.createdAt);
                    return m
                })
                setPendingData(data)
            })
    }

    return (
        <>
            <PageTitleLayout className="mt-4">
                <h5>قالب‌ ماژول ارسال سریع منتظر تایید</h5>
                <span></span>
            </PageTitleLayout>
            <Table
                data={pendingData.items}
                config={tableConfig}
                actionDispatched={tableActions}
            />
            <Modal
                headerTitle="تعیین وضعیت قالب ارسال سریع"
                onClose={() => {
                    setIsModal(false);
                    setIsReject(false)
                }}
                visible={isModal}
                onCancel={() => {
                    setIsModal(false);
                    setIsReject(false)
                }}
            >
                <FastSendTemplatePendingModal data={modalData}
                    dataChange={modalDataChange}
                    modalReject={isReject}
                    rejectHandler={(event) => setIsReject(event)}
                    onCloseModal={(e) => {
                        setIsModal(e);
                        setIsReject(e)
                    }} />
            </Modal>
        </>
    )
}

export default FastSendTemplatesPending;
