import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { StyledButton } from "../../Component/Kit/Button";
import { FormLable, StyledForm, StyledInput, StyledRadioBtn } from "../../Component/Kit/Form";
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import { AddBlacklistKeywordScema } from "../../Utility/Validation/Schema/AddBlacklistKeywordScema";

function AddBlacklistKeyword({ onCloseModal, dataChange }) {
    return (
        <>
            <Formik
                initialValues={{
                    keyWord: '',
                    isCancellation: false
                }}
                validationSchema={AddBlacklistKeywordScema}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    ApiClient.Post(Urls.createBlacklistKeyword(), values)
                        .then((res) => {
                            toast.success('تغییرات  با موفقیت ثبت شد.');
                            actions.resetForm();
                            dataChange(true)
                            onCloseModal(false)
                        })
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}>
                        <StyledInput
                            lable="کلیدواژه"
                            focus={true}
                            type="text"
                            name="keyWord"
                            value={formik.values.keyWord}
                            error={(formik.errors.keyWord && formik.touched.keyWord)
                                ? formik.errors.keyWord
                                : 'false'
                            }
                            onChange={(e) =>
                                formik.setFieldValue("keyWord", e.target.value)
                            }
                        />
                        <div className="col-12 d-flex justify-content-start align-items-center mb-3 p-3">
                            <span className="d-block">نوع </span>
                            <div className="d-flex col-6">
                                <StyledRadioBtn id='member-ship' name='type' value={false}
                                    label='عضویت'
                                    className="p-3"
                                    checked={!formik.values.isCancellation}
                                    onChange={(e) =>
                                        formik.setFieldValue("isCancellation", (e.target.value === 'true'))
                                    } />
                                <StyledRadioBtn id='cancel' name='type' value={true} label='لغو'
                                    checked={formik.values.isCancellation}
                                    className="p-3"
                                    onChange={(e) =>
                                        formik.setFieldValue("isCancellation", (e.target.value === 'true'))
                                    } />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                اضافه کردن
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    )
}

export default AddBlacklistKeyword
