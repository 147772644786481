import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { DiscountCodeDetailAction } from '../../../Actions/DiscountCodeDetail';
import { StyledButton } from '../../../Component/Kit/Button';
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import { Loader } from '../../../Component/Shared/Loader';
import Table from '../../../Component/Shared/Table/Table';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { ConvertQueryString } from '../../../Utility/Services/convert-query-string';
import DateConvert from '../../../Utility/Services/date-convert';

export const DiscountCodeList = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [discountCodeList, setDiscountCodeList] = useState({});
    const [loading, setLoading] = useState(true);

    const tableConfig = {
        header: {
            code: "کد تخفیف",
            usageLimitationPerUser: 'محدودیت استفاده برای هر کاربر',
            usedCount: 'تعداد استفاده',
            createdAt: "تاریخ ایجاد",
            fromDateString: "شروع اعتبار",
            toDateString: "پایان اعتبار",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
            edit: {
                icon: "fa fa-edit",
            },
            delete: {
                icon: "fa fa-trash",
            }
        },
    };

    function tableActions(items) {
        switch (items.actionName) {
            case 'edit':
                dispatch(DiscountCodeDetailAction(items.data))
                // TODO
                history.push(`discount-code/${items.data.id}`);
                break;
            case 'delete':
                deleteDiscountCode(items.data.id);
                break;
            case 'view':
                history.push(`discount-code/${items.data.id}/log`);
                break;
            default:
                break;
        }
    }

    const deleteDiscountCode = (codeID) => {
        Swal.fire({
            title: '',
            text: `آیا از حذف این کد تخفیف مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.PublicDiscountCode(codeID))
                    .then(() => {
                        toast.success('کد تخفیف با موفقیت حذف گردید.');
                        getDiscountCodeList();
                    })
            }
        })

    }

    const getDiscountCodeList = useCallback(() => {
        ApiClient.Get(Urls.PublicDiscountCode(), ConvertQueryString(location.search))
            .then((res) => {
                let dataItem = res.result;
                dataItem.items.map((m, i) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.fromDateString = DateConvert(m.fromDate);
                    m.toDateString = DateConvert(m.toDate);
                    return m;
                });
                setDiscountCodeList(dataItem);
                setLoading(false)
            })
    }, [location.search])

    useEffect(() => {
        getDiscountCodeList();
    }, [location.search, getDiscountCodeList]);

    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-end">
                <div className="d-flex align-items-center justify-content-start w-100">
                    <StyledButton
                        btnStyle="greenBtn mx-1"
                        onClick={() => history.push('/discount-code/add')}
                    >
                        ایجاد کدتخفیف
                    </StyledButton>
                </div>
            </HeaderContent>
            <MainContent>
                <div className="col-12 col-lg-12">
                    <PageTitleLayout>
                        <h5>لیست کدهای تخفیف </h5>
                        <span>در این بخش لیست همه کدهای تخفیف قابل مشاهده است.</span>
                    </PageTitleLayout>
                    <div>
                        {loading === true
                            ?
                            <Loader />
                            :
                            <Table
                                data={discountCodeList.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: discountCodeList.pageNumber,
                                    pageSize: discountCodeList.pageSize,
                                    totalItemsCount: discountCodeList.totalItemsCount,
                                    totalPagesCount: discountCodeList.totalPagesCount,
                                }}
                            />
                        }
                    </div>
                </div>
            </MainContent>
        </WrapperLayout >
    );
};

export default connect()(DiscountCodeList)