import TicketStatusList from "../Model/TicketStatusModel";

function TicketStatus(value) {
    let result = TicketStatusList.find((item) => item.value ===value);
    return result.name
  }
  export default TicketStatus;


  export function GetTicketStatusKey(name) {
    let result = TicketStatusList.find((item) => item.name === name);
    return result.value
  }