const AdminDetailReducer =  (state = {}, action) => {
    switch (action.type) {

    case 'ADMIN_DETAILS':
        return { ...state, ...action.data }

    default:
        return state
    }
}

export default AdminDetailReducer;