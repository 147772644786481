const SystemLineType = [
  { value: 1, name: "فعال سازی سامانه" },
  { value: 2, name: "اطلاع رسانی" },
  { value: 4, name: "تبلیغات" },
  { value: 8, name: "ماژول" },
  { value: 16, name: "همکاری در فروش" },
  { value: 32, name: "فرایند های داخلی" },
];

export default SystemLineType;
