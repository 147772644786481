import React, { useState, useEffect } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import Table from "./../../../../Component/Shared/Table/Table";
import ApiClient from "./../../../../Requester/ApiClient";
import Urls from "./../../../../Requester/Urls";
import CreditTransactionType from "../../../../Utility/Services/transaction-type";
import { HeaderContent, MainContent, PageTitleLayout } from "../../../../Component/Kit/Layout";
import DateConvert from "../../../../Utility/Services/date-convert";
import BackToPage from "../../../../Component/Kit/back";
import { ConvertQueryString } from "../../../../Utility/Services/convert-query-string";

function UserTransactions() {
    let match = useRouteMatch();
    let location = useLocation();
    const [transactionsData, settransactionsData] = useState({});
    const tableConfig = {
        header: {
            description: "توضیحات",
            type: "نوع استفاده",
            remainCredit: " مقدار باقیمانده",
            credit: "مقدار استفاده شده",
            transactedAt: "تاریخ و ساعت استفاده",
        },
        mode: ["filter", "search", "export"],
        filters: ["datePickerFilter", "creditTransactionType"],
    };
    useEffect(() => {
        ApiClient.Get(Urls.userTransactions(match.params.id), ConvertQueryString(location.search))
            .then((res) => {
                let data = res.result;
                data.items.map((m) => {
                    m.transactedAt = DateConvert(m.transactedAt);
                    m.type = CreditTransactionType(m.type);
                    return m
                });
                settransactionsData(data);
            });
    }, [match.params.id, location.search]);
    return (
        <>
            <HeaderContent className="justify-content-end">
                <BackToPage toPage={`/userDetail/${match.params.id}/financial`} />
            </HeaderContent>
            <MainContent>
                <PageTitleLayout>
                    <h5>تراکنش‌های کاربر</h5>
                    <span>
                        در اینجا لیست تراکنش‌های این کاربر مورد بررسی قرار می‌گیرد.
                    </span>
                </PageTitleLayout>

                <Table
                    data={transactionsData.items}
                    config={tableConfig}
                    pageConfig={{
                        pageNumber: transactionsData.pageNumber,
                        pageSize: transactionsData.pageSize,
                        totalItemsCount: transactionsData.totalItemsCount,
                        totalPagesCount: transactionsData.totalPagesCount,
                    }}
                />
            </MainContent>
        </>
    );
}

export default UserTransactions;
