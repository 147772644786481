import React from "react";
import { useEffect } from "react";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import { MainContent } from "../../../Component/Kit/Layout";
import PlansLineList from "./PlansLineList";
import UserPendingForPlanLine from "./UserPendingForPlanLine";

function PlansLines({ match }) {
  const history = useHistory();

  useEffect(() => {
    history.push("/line/plans/list");
  }, []);
  return (
    <MainContent>
      <ul className="user-tab-menu">
        <li>
          <NavLink activeClassName="is-active" to={`${match.url}/list`}>
            خطوط پلن ها
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            to={`${match.url}/pending?onlyHandled=false`}
          >
            کاربران در انتظار خط
          </NavLink>
        </li>
      </ul>
      <div className="p-3">
        <Switch>
          <Route exact path={`${match.path}/list`}>
            <PlansLineList />
          </Route>
          <Route path={`${match.path}/pending`}>
            <UserPendingForPlanLine />
          </Route>
        </Switch>
      </div>
    </MainContent>
  );
}

export default PlansLines;
