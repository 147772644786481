const ProviderEnum = [
  { name: "مگفا", value: 1 },
  { name: "رهیاب", value: 2 },
  { name: "آسانک", value: 3 },
  { name: "راهکار", value: 4 },
  { name: "آسیاتک", value: 5 },
  { name: "نوآوران", value: 6 },
  { name: "جیرینگ", value: 7 },
  { name: "شاتل", value: 8 },
];
export default ProviderEnum;

const SystemLineProviderEnum = [
  { name: "مگفا", value: 1 , prefix: "3000", Eng: "Magfa"},
  { name: "رهیاب", value: 2  , prefix: "1000", Eng: "Rahyab"},
  { name: "آسانک", value: 3  , prefix: "021", Eng: "Asanak"},
  { name: "راهکار", value: 4  , prefix: "5000", Eng: "Rahkar"},
  { name: "آسیاتک", value: 5  , prefix: "9000", Eng: "Asiatech"},
  { name: "نوآوران", value: 6  , prefix: "6600", Eng: "Noavaran"},
  { name: "جیرینگ", value: 7  , prefix: "7007", Eng: "Jiring"},
  { name: "شاتل", value: 8  , prefix: "998", Eng: "Shatel"},
];
export { SystemLineProviderEnum };
