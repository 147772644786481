const ProviderNotificationEnum = {
    EngineIsUnhealthy : 1,
    ClientHasDisconnected : 2,
    ProviderUnavailability : 3
}

const ProviderNotificationModel = [
    { value: ProviderNotificationEnum.EngineIsUnhealthy, name: 'موتور ارسال در حالت ناسالم قرار گرفته است' },
    { value: ProviderNotificationEnum.ClientHasDisconnected, name: 'ارتباط با اپلیکیشن قطع شده است' },
    { value: ProviderNotificationEnum.ProviderUnavailability, name: 'ارائه‌دهندگان از دسترس خارج شده اند' },
]

export { ProviderNotificationModel }
