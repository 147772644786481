import { Formik } from "formik";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { settingModal } from "../../../../Actions/setting-modal";
import { StyledButton } from "../../../../Component/Kit/Button";
import { StyledCheckBox, StyledForm } from "../../../../Component/Kit/Form";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { FormLayout } from './../../../../Component/Kit/Form';


function sumAllEnum(flags , array){
    let num = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i] === true){
            num += +flags[i];
        }
    }
    return num !== 0 ? num : ""
}
function EditSendsModal({ defaultData, onCloseModal }) {
    let dispatch = useDispatch();
    return (
        <>
            {defaultData &&
                <div className="custom-modal-body">
                    <Formik
                        initialValues={{
                            defaultData
                        }}
                        isInitialValid={false}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {

                            const data = Object.assign({}, ...values.defaultData.map((x , i) => ({ [x.value]: (sumAllEnum([1,2,4,8,16,32] , x.fields)) })));
                            Swal.fire({
                                title: `آیا مطمئن هستید؟`,
                                text: "",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonText: "انصراف",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "بله، مطمئن هستم",
                            }).then(result => {
                                if (result.isConfirmed) {
                                    ApiClient.Patch(Urls.updateSendsTypes() ,  JSON.stringify(data)).then(() => {
                                        toast.success('تغییرات  با موفقیت ثبت شد.');
                                        dispatch(settingModal(true));
                                    })
                                } else {
                                    actions.resetForm()
                                }
                            })
                        }}
                        onReset={(values, formikBag) => {
                            onCloseModal(false)
                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                style={{fontSize: '.85rem'}}
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                            >
                                {formik.values.defaultData.map((method, index) => (
                                    <FormLayout key={index}>
                                        <div className="form-title">
                                            <span>{method.name}</span>
                                        </div>
                                        <div key={index} className="d-flex align-items-center justify-content-start">
                                            <StyledCheckBox
                                                formGroupClass="mt-2 ml-3"
                                                lable='محدودیت لینک'
                                                focus={true}
                                                name={`defaultData.${index}.fields[0]`}
                                                checked={formik.values.defaultData[index].fields[0]
                                                    ? formik.values.defaultData[index].fields[0]
                                                    : false}
                                                id={`defaultData${index}.fields[0]`}
                                                onChange={(e) => formik.setFieldValue(`defaultData.${index}.fields[0]`, e.target.checked)}
                                            />
                                            <StyledCheckBox
                                                formGroupClass="mt-2 ml-3"
                                                lable='کلمات ممنوعه'
                                                focus={true}
                                                name={`defaultData.${index}.fields[1]`}
                                                checked={formik.values.defaultData[index].fields[1]
                                                    ? formik.values.defaultData[index].fields[1]
                                                    : false}
                                                id={`defaultData${index}.fields[1]`}
                                                onChange={(e) => formik.setFieldValue(`defaultData.${index}.fields[1]`, e.target.checked)}
                                            />
                                            <StyledCheckBox
                                                formGroupClass="mt-2 ml-3"
                                                lable='کاربر جدید'
                                                focus={true}
                                                name={`defaultData.${index}.fields[2]`}
                                                checked={formik.values.defaultData[index].fields[2]
                                                    ? formik.values.defaultData[index].fields[2]
                                                    : false}
                                                id={`defaultData${index}.fields[2]`}
                                                onChange={(e) => formik.setFieldValue(`defaultData.${index}.fields[2]`, e.target.checked)}
                                            />

                                            <StyledCheckBox
                                                formGroupClass="mt-2 ml-3"
                                                lable='محدودیت مخاطبین'
                                                focus={true}
                                                name={`defaultData.${index}.fields[3]`}
                                                checked={formik.values.defaultData[index].fields[3]
                                                    ? formik.values.defaultData[index].fields[3]
                                                    : false}
                                                id={`defaultData${index}.fields[3]`}
                                                onChange={(e) => formik.setFieldValue(`defaultData.${index}.fields[3]`, e.target.checked)}
                                            />
                                            {/*<StyledCheckBox*/}
                                            {/*    formGroupClass="mt-2 ml-3"*/}
                                            {/*    lable='ارسال پرتکرار'*/}
                                            {/*    focus={true}*/}
                                            {/*    name={`defaultData.${index}.fields[4]`}*/}
                                            {/*    checked={formik.values.defaultData[index].fields[4]*/}
                                            {/*        ? formik.values.defaultData[index].fields[4]*/}
                                            {/*        : false}*/}
                                            {/*    id={`defaultData${index}.fields[4]`}*/}
                                            {/*    onChange={(e) => formik.setFieldValue(`defaultData.${index}.fields[4]`, e.target.checked)}*/}
                                            {/*/>*/}
                                            {(method.value == 1 || method.value == 9) &&
                                                <StyledCheckBox
                                                    formGroupClass="mt-2 ml-3"
                                                    lable='محدودیت ارسال روزانه'
                                                    focus={true}
                                                    name={`defaultData.${index}.fields[5]`}
                                                    checked={formik.values.defaultData[index].fields[5]
                                                        ? formik.values.defaultData[index].fields[5]
                                                        : false}
                                                    id={`defaultData${index}.fields[5]`}
                                                    onChange={(e) => formik.setFieldValue(`defaultData.${index}.fields[5]`, e.target.checked)}
                                                />
                                            }
                                        </div>
                                    </FormLayout>
                                ))}
                                <div className="d-flex justify-content-between">
                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => {
                                            onCloseModal(false);
                                        }}
                                    >
                                        بستن
                                    </StyledButton>
                                    <StyledButton
                                        width="35%"
                                        btnStyle="greenBtn"
                                        type="submit"
                                    >
                                        تایید
                                    </StyledButton>
                                </div>
                            </StyledForm>
                        )}
                    </Formik>
                </div>
            }

        </>
    )
}

export default connect()(EditSendsModal);
