import React, {useEffect, useRef, useState} from 'react'
import CommentItem from './Comment'

export const CatList = (props) => {
    const {commentList, userId, deleteConfirm} = props;
    const scrollToBottomRef = useRef(null);
    const prevTargetElementRef = useRef();

    const [lenghtContent, setLenghtContent] = useState(0);
    const [targetElementDetail, setTargetElementDetail] = useState({
        scrollTop: null,
        clientHeight: null,
        scrollHeight: null,

    });


    useEffect(() => {
        if (commentList) {
            if (lenghtContent === 0) {
                setTimeout(() => {
                    scrollToTop({top: scrollToBottomRef.current.scrollHeight})
                }, 100)
            }
            setLenghtContent(Object.values(commentList)?.length);
        }
    }, [commentList, lenghtContent]);


    useEffect(() => {
            if (prevTargetElementRef.current) {
                const {clientHeight, scrollHeight} = prevTargetElementRef.current;
                if (scrollHeight) {
                    const isNearBottom = Math.abs(
                            targetElementDetail.scrollTop + clientHeight - scrollHeight)
                        < clientHeight;

                    if (isNearBottom) {
                        setTimeout(() => {
                            scrollToTop({top: scrollToBottomRef.current.scrollHeight, behavior: 'smooth'})
                        }, 50)
                    }
                }
            }
        },
        [targetElementDetail, targetElementDetail.scrollHeight]);


    useEffect(() => {

            const scrollTop = scrollToBottomRef.current.scrollTop;
            const clientHeight = scrollToBottomRef.current.clientHeight;
            const scrollHeight = scrollToBottomRef.current.scrollHeight;

            setTargetElementDetail({
                clientHeight: clientHeight,
                scrollHeight: scrollHeight,
                scrollTop: scrollTop,
            });



        },
        [lenghtContent]);

    useEffect(() => {
            prevTargetElementRef.current = {
                clientHeight: targetElementDetail.clientHeight,
                scrollHeight: targetElementDetail.scrollHeight,
                scrollTop: targetElementDetail.scrollTop,
            };
        },
        [targetElementDetail]);

    const scrollToTop = (data) => {
        scrollToBottomRef.current.scrollTo(data);
    }

    return (
        <div ref={scrollToBottomRef} className="ticket-detail scrollbar-style">
            {
                commentList &&
                Object.values(commentList).map((CItem, index) => (
                    <CommentItem deleteConfirm={deleteConfirm}  CItem={CItem} userId={userId} key={`item_${CItem.authorName}_${index}`}/>
                ))
            }

        </div>
    )
}
