import React, { useCallback, useEffect, useState } from 'react';
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import DateConvert from "../../Utility/Services/date-convert";
import { HeaderContent, MainContent, PageTitleLayout } from "../../Component/Kit/Layout";
import Table from "../../Component/Shared/Table/Table";
import { StyledButton } from "../../Component/Kit/Button";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "./../../Component/Kit/Modal";
import ViewNotificationModal from "./view-notification";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import { ConvertQueryString } from "../../Utility/Services/convert-query-string";

function NotificationsList() {
    let history = useHistory();
    const location = useLocation();
    const [notificationData, setNotificationData] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [modalId, setModalId] = useState(0)
    const tableConfig = {
        header: {
            title: "عنوان",
            readsCountTitle: "تعداد خوانده شده",
            startDateTime: "تاریخ شروع",
            endDateTime: "تاریخ پایان",
            showAtEntry: 'نمایش هنگام ورود',
            createdAt: "تاریخ ایجاد",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
            edit: {
                icon: "fa fa-edit",
            },
            delete: {
                icon: "fa fa-trash",
            },
        },
        mode: ["search"],
    };
    const tableActions = (item) => {
        switch (item.actionName) {
            case 'view':
                setModalId(item.data.id);
                setIsModal(true)
                break;
            case 'edit':
                    history.push(`/notifications/edit/${item.data.id}`)
                break;
            case 'delete':
                deleteCustomNotification(item.data.id);
                break;
            default:
                break;
        }
    };
    const deleteCustomNotification = (item) => {
        Swal.fire({
            title: `حذف اعلان`,
            text: "آیا از حذف این اعلان مطمئن هستید؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.globalNotificationSingle(item))
                    .then(res => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        getCustomNotifications();
                    })

            }
        })

    }
    const getCustomNotifications = useCallback(() => {
        ApiClient.Get(Urls.globalNotifications(), ConvertQueryString(location.search)).then(res => {
            let data = res.result;
            data.items.map(m => {
                  m.showAtEntry= m.showAtEntry
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                m.createdAt = DateConvert(m.createdAt);
                m.readsCountTitle = `${m.readsCount} بار`;
                m.startDateTime = m.startDateTime ? DateConvert(m.startDateTime) : '-----'
                m.endDateTime = m.endDateTime ? DateConvert(m.endDateTime) : '-----'
                return m;
            })
            setNotificationData(data);
        })
    }, [location.search])

    useEffect(() => {
        getCustomNotifications();
    }, [location.search, getCustomNotifications])

    return (
        <>
            <HeaderContent className="justify-content-end">
                <div className="d-flex align-items-center justify-content-start w-100">
                    <StyledButton
                        btnStyle="greenBtn mx-1"
                        onClick={() => history.push('/notifications/add')}
                    >
                        ایجاد اعلان جدید
                    </StyledButton>
                </div>
            </HeaderContent>
            <MainContent>
                <PageTitleLayout>
                    <h5>اعلان‌های ادمینی</h5>
                    <span>لیست تمام اعلان‌های ایجادشده از طریق ادمین قابل مشاهده است.</span>
                </PageTitleLayout>
                <Table
                    data={notificationData.items}
                    config={tableConfig}
                    pageConfig={{
                        pageNumber: notificationData.pageNumber,
                        pageSize: notificationData.pageSize,
                        totalItemsCount: notificationData.totalItemsCount,
                        totalPagesCount: notificationData.totalPagesCount,
                    }}
                    actionDispatched={tableActions}
                />
            </MainContent>
            <Modal
                onClose={() => setIsModal(false)}
                visible={isModal}
                onCancel={() => setIsModal(false)}
                headerTitle="جزئیات اعلان"
            >
                <ViewNotificationModal data={modalId} onCloseModal={(e) => setIsModal(e)} />
            </Modal>
        </>
    )
}

export default NotificationsList;
