import React, { useEffect } from 'react';
import { NavLink, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { MainContent } from "../../Component/Kit/Layout";
import UserList from "./user-list";
import UserVerify from "./user-verify";

const UserPage = ({ match }) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === `${match.url}`) {
            history.push(`${match.url}/list`)
        }
    }, [])

    return (
        <>
            <MainContent>
                <ul className="user-tab-menu">
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/list`}>
                            همه کاربران
                        </NavLink>
                    </li>

                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/verify-info`}>
                            کاربران در انتظار تایید اطلاعات
                        </NavLink>
                    </li>

                </ul>
                <div className="p-3">
                    <Switch>
                        <Route exact path={`${match.path}/verify-info`}>
                            <UserVerify/>
                        </Route>
                        <Route path={`${match.path}/list`}>
                            <UserList/>
                        </Route>

                    </Switch>
                </div>
            </MainContent>
        </>
    )
}

export default UserPage
