import styled from "styled-components";
import { StyledLayout } from "../../Component/Kit/Layout";

export const LoginLayout = styled(StyledLayout)`
    height : 100vh;
    svg{
        width: 200px;
        margin-bottom: 1rem;
    }
    h5{
        margin-bottom: 1rem;
    }
`;

export const AuthForm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-flex-grow: 1;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    flex-direction: column;
    img {
      width: 150px;
      margin-bottom: 1rem;
    }
`;
