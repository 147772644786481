import { Formik } from 'formik';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../Component/Kit/Button';
import { StyledForm, StyledInput, StyledSelect, StyledTextArea } from '../../../../Component/Kit/Form';
import Modal from '../../../../Component/Kit/Modal';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import { AccountRechargeSchema } from '../../../../Utility/Validation/Schema/AccountRechargeSchema';

const AccountRechargeModal = (props) => {
    const { visible, setVisible, onCancel, onClose } = props;
    let match = useRouteMatch();
    let userId = match.params.id;

    const [basePrice, setBasePrice] = useState([]);
    useEffect(() => {
        if (visible) {
            ApiClient.Get(Urls.GetCreditTier())
                .then(res => {
                    let temp = [];
                    res.result.map((item) => {
                        temp = [...temp, { name: item.basePrice, value: item.id }];
                        return false
                    })
                    setBasePrice(temp);
                })
        }
    }, [visible]);

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            closable={true}
            headerTitle="افزایش اعتبار حساب"
        >
            <div className="col-12 pt-3 pb-3">
                <h6>سیلی نقد به از حلوای نسیه </h6>

                <div className="row">
                    <Formik
                        initialValues={{
                            creditCount: '',
                            basePrice: '',
                            description: ''
                        }}
                        validationSchema={AccountRechargeSchema}
                        // isInitialValid={false}
                        onSubmit={(values, actions) => {
                            const basePriceValue = basePrice.find((item) => item.value === +values.basePrice).name;
                            const dataBody = {
                                "credit": parseFloat(values.creditCount),
                                "description": values.description,
                                "basePrice": basePriceValue,
                                "userId": parseFloat(userId)
                            }
                            ApiClient.Post(Urls.AddCreditLend(), dataBody)
                                .then((res) => {
                                    toast.success('افزایش اعتبار قرضی با موفقیت انجام شد!')
                                    setVisible(false);
                                    actions.resetForm();
                                })
                                .catch(err => actions.resetForm())
                        }}
                        onReset={(values, formikBag) => {
                            setVisible(false);
                        }}
                    >

                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                                onBlur={formik.handleBlur}
                            >
                                <StyledInput
                                    lable="تعداد کریدیت درخواستی"
                                    placeholder=""
                                    focus={true}
                                    name="creditCount"
                                    value={formik.values.creditCount}
                                    error={(formik.errors.creditCount && formik.touched.creditCount)
                                        ? formik.errors.creditCount
                                        : 'false'
                                    }
                                    onChange={(e) => formik.setFieldValue("creditCount", e.target.value)}
                                />

                                <StyledSelect
                                    lable="تعرفه"
                                    focus={true}
                                    name="basePrice"
                                    hasDefaultOption
                                    value={formik.values.basePrice !== undefined
                                        ? formik.values.basePrice
                                        : formik.initialValues.basePrice}
                                    optionItems={basePrice}
                                    error={(formik.errors.basePrice && formik.touched.basePrice)
                                        ? formik.errors.basePrice
                                        : 'false'
                                    }
                                    onChange={(e) => formik.setFieldValue("basePrice", e.target.value)}
                                />

                                <StyledTextArea
                                    lable="توضیحات"
                                    focus={true}
                                    name="description"
                                    value={formik.values.description}
                                    error={(formik.errors.description && formik.touched.description)
                                        ? formik.errors.description
                                        : 'false'
                                    }
                                    onChange={(e) => {
                                        const str = e.target.value.trim() !== '' ? e.target.value : '';
                                        formik.setFieldValue("description", str)
                                    }}
                                />

                                <div className="d-flex justify-content-between">
                                    <StyledButton
                                        btnStyle="greenBtn ml-3"
                                        width="38%"
                                        disabled={!formik.isValid || !formik.dirty}
                                        loading={formik.isSubmitting ? 1 : 0}
                                        type="submit"
                                    >
                                        افزایش شارژ حساب
                                    </StyledButton>

                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                    >
                                        انصراف
                                    </StyledButton>
                                </div>

                            </StyledForm>
                        )}
                    </Formik>
                </div>

            </div>
        </Modal>
    )
}

export default AccountRechargeModal;
