import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { StyledButton } from "../../../Component/Kit/Button";
import { StyledForm, StyledTextArea } from "../../../Component/Kit/Form";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { FastSendTemplateStatus } from "../../../Utility/Model/fast-send-template.model";
import { FastSendTemplateRejectSchema } from "../../../Utility/Validation/Schema/FastSendTemplateRejectSchema";

const fastSendTemlateModalTitle = {
    title: "عنوان قالب",
    userName: 'نام کاربری',
    createdAt: "تاریخ ایجاد",
    templateText: "متن قالب",
}

function FastSendTemplatePendingModal({ data, dataChange, modalReject, rejectHandler, onCloseModal }) {

    const templateHandler = (item) => {
        if (item === FastSendTemplateStatus.IsVerified) {
            // TODO remove Swall if its unneccesary
            Swal.fire({
                title: "آیا مطمئن هستید؟",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "انصراف",
                cancelButtonColor: "#d33",
                confirmButtonText: "بله، مطمئن هستم",
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiClient.Put(Urls.putFastSendTemplates(data.id), { status: item })
                        .then(res => {
                            dataChange(true)
                            toast.success("تغییرات  با موفقیت ثبت شد.");
                        })
                }
            })
        } else if (item === FastSendTemplateStatus.UnVerified) {
            rejectHandler(true)
        }
    }
    return (
        <>
            {modalReject ? (
                <Formik
                    initialValues={{
                        fastSendTemplateRejectDescription: "",
                    }}
                    validationSchema={FastSendTemplateRejectSchema}
                    isInitialValid={false}
                    onSubmit={(values, actions) => {
                        // TODO remove Swall if its unneccesary
                        Swal.fire({
                            title: "آیا مطمئن هستید؟",
                            text: "",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonText: "انصراف",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "بله، مطمئن هستم",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                ApiClient.Put(Urls.putFastSendTemplates(data.id), {
                                    // status: 3,
                                    status: FastSendTemplateStatus.UnVerified,
                                    description: values.fastSendTemplateRejectDescription
                                }).then(res => {
                                    if (res.result) {
                                        dataChange(true)
                                        toast.success("تغییرات  با موفقیت ثبت شد.");
                                    }
                                })
                            }
                        })
                    }}
                    onReset={(values, formikBag) => {
                        onCloseModal(false)
                    }}
                >
                    {(formik) => (
                        <StyledForm
                            onSubmit={formik.handleSubmit}
                            onBlur={formik.handleBlur}
                            onReset={formik.handleReset}>
                            <StyledTextArea
                                lable="توضیحات"
                                focus={true}
                                name="fastSendTemplateRejectDescription"
                                value={formik.values.fastSendTemplateRejectDescription}
                                error={(formik.errors.fastSendTemplateRejectDescription && formik.touched.fastSendTemplateRejectDescription)
                                    ? formik.errors.fastSendTemplateRejectDescription
                                    : 'false'
                                }
                                onChange={(e) => {
                                    const str = e.target.value.trim() !== '' ? e.target.value : '';
                                    formik.setFieldValue("fastSendTemplateRejectDescription", str)
                                }}
                            />
                            <div className="d-flex justify-content-between w-100">
                                <StyledButton
                                    width="35%"
                                    btnStyle="grayBtn"
                                    type="reset"
                                    onClick={() => onCloseModal(false)}
                                >
                                    بستن
                                </StyledButton>
                                <StyledButton
                                    width="35%"
                                    disabled={!formik.isValid || !formik.dirty}
                                    btnStyle='redBtn'
                                    type="submit"
                                >
                                    عدم تایید
                                </StyledButton>
                            </div>
                        </StyledForm>
                    )}
                </Formik>
            ) : (
                <>
                    <div className="w-100">
                        <ul className="params">
                            {
                                fastSendTemlateModalTitle && data &&
                                Object.entries(fastSendTemlateModalTitle).map(([key, value]) => (

                                    <li className="params__list" key={`${key}_${data.id}`}>
                                        <div className="params__list__label">
                                            <span>{value}: </span>
                                        </div>
                                        <div className="params__list__value">
                                            <span className="params__list__label--white" style={{ whiteSpace: 'pre-wrap' }}>{data[key]}</span>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="mt-2 d-flex justify-content-center w-100">
                        <StyledButton
                            btnStyle="grayBtn"
                            type="reset"
                            onClick={() => onCloseModal(false)}
                        >
                            بستن
                        </StyledButton>
                        <StyledButton btnStyle="redBtn mr-2" onClick={() => templateHandler(FastSendTemplateStatus.UnVerified)}>
                            <span>عدم تایید</span>
                        </StyledButton>
                        <StyledButton btnStyle="greenBtn mr-2" onClick={() => templateHandler(FastSendTemplateStatus.IsVerified)}>
                            <span>تایید</span>
                        </StyledButton>
                    </div>
                </>
            )}
        </>
    )
}

export default FastSendTemplatePendingModal;
