import React from 'react';
import Modal from '../../../../Component/Kit/Modal';
import { LinkList } from './styles';

function FilesListModal({ visible, onClose, files }) {
  return (
    <>
      <Modal visible={visible} headerTitle='تمام فایل‌ها ' onClose={onClose}>
        <LinkList>
          {files.map((m, index) => (
            <li key={`${m}${index}`}>
              <a href={m} target='_blank'>
                <i className='fa fa-download' />
                <span>{m}</span>
              </a>
            </li>
          ))}
        </LinkList>
      </Modal>
    </>
  );
}

export default FilesListModal;
