import {
    companyName,
    registrationNumber,
    companyType,
    gazetteUploadedFile,
    nationalIdCompany
} from "../Rules";
import * as Yup from "yup";

export const ConvertUserTypeSchema = Yup.object().shape({
    companyName,
    registrationNumber,
    nationalIdCompany,
    companyType,
    gazetteUploadedFile
});
