import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { StyledButton } from '../../../Component/Kit/Button'
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout'
import { Loader } from '../../../Component/Shared/Loader'
import Table from '../../../Component/Shared/Table/Table'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import ModifyDedicatedBasePriceModal from './ModifyDedicatedBasePriceModal'

const DedicatedBasePrice = () => {
    const [loading, setLoading] = useState(true);
    const [dedicatedBasePriceList, setDedicatedBasePriceList] = useState([]);
    const [modifyDedicatedModalHandler, setModifyDedicatedModalHandler] = useState(false);
    const [modifybasePriceData, setModifybasePriceData] = useState({});

    const tableConfig = {
        header: {
            basePrice: "قیمت پایه",
            usagesCount: "تعداد تخصیص داده‌شده",
        },
        actions: {
            edit: {
                icon: "fa fa-edit",
            },
            delete: {
                icon: "fa fa-trash",
                condition: (data) => {
                    return (!data.usagesCount)
                }
            }
        },
    };

    function tableActions(items) {
        switch (items.actionName) {
            case 'delete':
                DeleteDedicatedBasePrice(items.data.id);
                break;
            case 'edit':
                setModifybasePriceData(items.data)
                setModifyDedicatedModalHandler(true);
                break;
            default:
                break;
        }
    }

    const DeleteDedicatedBasePrice = (basePriceId) => {
        Swal.fire({
            title: '',
            text: `آیا از حذف این قیمت پایه اختصاصی مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.DedicatedBasePrice(basePriceId))
                    .then(() => {
                        toast.success('قیمت پایه اختصاصی موردنظر حذف شد.')
                        getDedicatedBasePrice();
                    })
            }
        })
    }

    const getDedicatedBasePrice = useCallback(() => {
        ApiClient.Get(Urls.DedicatedBasePrice())
            .then(res => {
                setDedicatedBasePriceList(res.result)
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        getDedicatedBasePrice()
    }, [getDedicatedBasePrice])


    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-end">
                <div className="d-flex align-items-center justify-content-start w-100">
                    <StyledButton
                        btnStyle="greenBtn mx-1"
                        onClick={() => {
                            setModifybasePriceData(null)
                            setModifyDedicatedModalHandler(true);
                        }}
                    >
                        ایجاد تعرفه اختصاصی
                    </StyledButton>
                </div>
            </HeaderContent>
            <MainContent>
                <div className="col-12 col-lg-12">
                    <PageTitleLayout>
                        <h5>لیست تعرفه‌های اختصاصی </h5>
                        <span>در این بخش لیست همه تعرفه‌های اختصاصی تنظیم شده، قابل مشاهده است.</span>
                    </PageTitleLayout>
                    <div>
                        {loading === true
                            ?
                            <Loader />
                            :
                            <Table
                                data={dedicatedBasePriceList}
                                config={tableConfig}
                                actionDispatched={tableActions}
                            />
                        }
                    </div>
                </div>
            </MainContent>

            <ModifyDedicatedBasePriceModal
                data={modifybasePriceData}
                visible={modifyDedicatedModalHandler}
                setVisible={setModifyDedicatedModalHandler}
                onClose={() => setModifyDedicatedModalHandler(false)}
                onCancel={() => setModifyDedicatedModalHandler(false)}
                onSubmit={() => getDedicatedBasePrice()}
            />


        </WrapperLayout>
    )
}

export default DedicatedBasePrice