import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { LoginSecondStepForm } from "../LoginFirstStepForm/styles";
import { FormLable, StyledInput } from "../../../Component/Kit/Form";
import { StyledButton } from "../../../Component/Kit/Button";
import Urls from "../../../Requester/Urls";
import LocalToken from "../../../Utility/LocalStorge/LocalToken";
import { AdminLoginOtpSchema } from "../../../Utility/Validation/Schema/AdminLoginOtpSchema";
import AppContext from "../../../Context/AppContext";
import ApiClient from "../../../Requester/ApiClient"
import { toast } from "react-toastify";
import CountDownTimer from "../../../Component/Shared/CountDownTimer";

const LoginSecondStepFormContainer = (props) => {
    const { orderStep, currentStep, setStep, account } = props;
    const setHasToken = useContext(AppContext).setHasToken;
    const [active, setActive] = useState(false);

    const resendOtpCode = () => {
        ApiClient.Post(Urls.AdminLogin(), account)
            .then(() => {
                setActive(true);
            })
            .catch(error => {
                toast.error(error.Message);
            })
    }

    useEffect(() => {
        if (currentStep === 1 && !active) {
            setActive(true);
            document.getElementsByName("otpCode")[0].focus();
        }
    }, [currentStep, active]);

    return (
        <>
            <Formik
                initialValues={{
                    otpCode: ''
                }}
                validationSchema={AdminLoginOtpSchema}
                isInitialValid={false}
                onSubmit={(values, actions) => {
                    let body = values.otpCode;
                    ApiClient.Patch(Urls.AdminLogin(), body)
                        .then(res => {
                            if (res.result) {
                                setStep(0);
                                LocalToken.setToken(res);
                                setHasToken(true);
                                // actions.setSubmitting(false);
                                // actions.resetForm();
                            } else {
                                actions.setSubmitting(false);
                            }
                        })
                        .catch(error => {
                            actions.setSubmitting(false);
                        })
                }}
            >
                {(formik) => (
                    <LoginSecondStepForm
                        className="auth-form"
                        onSubmit={formik.handleSubmit}
                        currentStep={currentStep}
                        orderStep={orderStep}
                    >
                        <h5>ورود به حساب کاربری</h5>
                        <p>به زودی کد برای شما ارسال می شود.</p>
                        <FormLable show={true}>کد خود را وارد کنید:</FormLable>
                        <StyledInput
                            value={formik.values.otpCode}
                            type="text"
                            name="otpCode"
                            id="otpCode"
                            minLength='6'
                            maxLength="6"
                            error={formik.errors.otpCode !== undefined ? formik.errors.otpCode : 'false'}
                            onChange={(e) => formik.setFieldValue("otpCode", e.target.value)}
                        />

                        {
                            active &&
                            <CountDownTimer timeLeft={120} handleResendCode={resendOtpCode} />
                        }
                        <StyledButton
                            width="100%"
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting ? 1 : 0}
                            type="submit">
                            ورود
                        </StyledButton>

                    </LoginSecondStepForm>

                )}
            </Formik>

        </>
    );
}

export default LoginSecondStepFormContainer;

