import React, {useEffect} from 'react';
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import CampaignList from "./List";
import CampaignMembers from "./Members";
import AddCampaign from "./Add";
import EditCampaign from "./Edit";

const CampaignsPage = ({match}) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === `${match.url}`) {
            history.push(`${match.url.replace(/\/$/, '')}`);
        }
    }, [history, location.pathname, match.url])

    return (
        <>
            <Switch>

                <Route exact path={`${match.path}`}>
                    <CampaignList/>
                </Route>

                <Route path={`${match.path}/members`}>
                    <CampaignMembers/>
                </Route>

                <Route path={`${match.path}/:id/members`}>
                    <CampaignMembers/>
                </Route>

                <Route path={`${match.path}/:id/edit`}>
                    <EditCampaign/>
                </Route>

                <Route path={`${match.path}/add`}>
                    <AddCampaign/>
                </Route>


            </Switch>
        </>
    )
}

export default CampaignsPage
