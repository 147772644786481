import { Formik } from 'formik';
import React, {useEffect, useState} from 'react'
import { useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../Component/Kit/Button';
import { StyledForm, StyledInput } from '../../../../Component/Kit/Form';
import Modal from '../../../../Component/Kit/Modal';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import {CooperationProfitPercentSchema} from "../../../../Utility/Validation/Schema/CooperationProfitPercentSchema";

const CooperationProfitModal = (props) => {

    const { visible, setVisible, onCancel, profitPercent ,  onClose ,onsubmit } = props;
    let match = useRouteMatch();
    let userId = match.params.id;
    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            closable={true}
            headerTitle="درصد سود مشارکت کاربر"
        >
            <div className="col-12 pt-3 pb-3">

                <div className="row">
                    <Formik
                        initialValues={{
                            lineProfitPercent: profitPercent.cooperationLineProfitPercent  ? profitPercent.cooperationLineProfitPercent: "0",
                            creditProfitPercent : profitPercent.cooperationCreditProfitPercent? profitPercent.cooperationCreditProfitPercent : "0"
                        }}
                        validationSchema={CooperationProfitPercentSchema}
                        onSubmit={(values, actions) => {
                            if (values.lineProfitPercent !=   profitPercent.cooperationLineProfitPercent){
                                ApiClient.Patch(Urls.changeCooperationLineProfitPercent(userId), JSON.stringify(values.lineProfitPercent))
                                    .then((res) => {
                                        toast.success('تغییر درصد سود خرید خط کاربر با موفقیت انجام شد!')
                                        setVisible(false);
                                        onsubmit()
                                        actions.setSubmitting(false)
                                    })
                                    .catch(err => actions.resetForm())
                            }
                            if (values.creditProfitPercent != profitPercent.cooperationCreditProfitPercent){
                                ApiClient.Patch(Urls.changeCooperationCreditProfitPercent(userId), JSON.stringify(values.creditProfitPercent))
                                    .then((res) => {
                                        toast.success('تغییر درصد سود  خرید اعتبار کاربر با موفقیت انجام شد!')
                                        setVisible(false);
                                        onsubmit();
                                        actions.setSubmitting(false)
                                    })
                                    .catch(err => actions.resetForm())
                            }
                        }}
                        onReset={(values, formikBag) => {
                            setVisible(false);
                        }}
                    >

                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                                onBlur={formik.handleBlur}
                            >
                                <StyledInput
                                    lable="درصد سود خرید خط"
                                    focus={true}
                                    name="lineProfitPercent"
                                    value={formik.values.lineProfitPercent}
                                    error={(formik.errors.lineProfitPercent && formik.touched.lineProfitPercent)
                                        ? formik.errors.lineProfitPercent
                                        : 'false'
                                    }
                                    onChange={(e) => formik.setFieldValue("lineProfitPercent", e.target.value)}
                                />

                                <StyledInput
                                    lable="درصد سود خرید اعتبار"
                                    focus={true}
                                    name="creditProfitPercent"
                                    value={formik.values.creditProfitPercent}
                                    error={(formik.errors.creditProfitPercent && formik.touched.creditProfitPercent)
                                        ? formik.errors.creditProfitPercent
                                        : 'false'
                                    }
                                    onChange={(e) => formik.setFieldValue("creditProfitPercent", e.target.value)}
                                />

                                <div className="d-flex justify-content-between">
                                    <StyledButton
                                        btnStyle="greenBtn ml-3"
                                        width="38%"
                                        disabled={!formik.isValid || !formik.dirty}
                                        loading={formik.isSubmitting ? 1 : 0}
                                        type="submit"
                                    >
                                        تایید
                                    </StyledButton>

                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                    >
                                        انصراف
                                    </StyledButton>
                                </div>

                            </StyledForm>
                        )}
                    </Formik>
                </div>

            </div>
        </Modal>
    )
}

export default CooperationProfitModal;
