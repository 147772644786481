import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from "../../../Component/Kit/Layout";
import Modal from 'react-bootstrap/Modal';
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {ConvertQueryString} from "../../../Utility/Services/convert-query-string";
import DateConvert from "../../../Utility/Services/date-convert";
import {GetBankProvider} from '../../../Utility/Services/get-bank-provider';
import {PaymentStatusService} from "../../../Utility/Services/PaymentStatusService";
import {SeprateNumberWithComma} from "../../../Utility/Services/SeprateNumberWithComma";
import {PaymentConfirmationModal} from './PaymentConfirmationModal';
import BackToPage from "../../../Component/Kit/back";

const OnlinePayment = () => {
    let match = useRouteMatch();
    const {id} = useParams();
    const location = useLocation();
    const history = useHistory();

    const [onlinePaymentLists, setOnlinePaymentLists] = useState({});
    const [modalData, setModalData] = useState({});
    const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);

    const tableConfig = {
        header: {
            userName: "نام کاربری",
            description: "توضیحات",
            isOnlineName: "روش پرداخت",
            bankName: "بانک دریافت کننده",
            statusName: "وضعیت",
            finalPrice: "قیمت نهایی",
            createdAt: "تاریخ و زمان ایجاد",
            isOfficialShow: "نوع",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            }, edit: {
                icon: "fa fa-edit", condition: (data) => {
                    return (data?.status === 1);
                },
            },
        },
        mode: ["filter", "search", "export-excel"],
        filters: ["datePickerFilter", 'PaymentStatusFilter', 'isOnline', 'bankId', 'isOfficial']
    }

    const openConfirmationPaymentModal = (item) => {
        setPaymentConfirmationModal(true);
        setModalData(item);
    };

    const handleClose = (event) => {
        if (event) setPaymentConfirmationModal(false);
    }


    const formatPaymentItem = (item) => {
        item.finalPrice = `${SeprateNumberWithComma(item.finalPrice)} ریال`;
        item.createdAt = DateConvert(item.createdAt);
        item.bankName = GetBankProvider(item.bankId);
        item.statusName = PaymentStatusService(item.status);
        item.isOfficialShow = item.isOfficial ? "رسمی" : (item.canBeChangeToOfficial ? `غیر رسمی <i class="fa fa-comments-dollar fa-lg"/>` : "غیر رسمی");
        item.isOnlineName = item.isOnline ? 'آنلاین' : 'آفلاین';
    };

    const getData = useCallback(() => {
        if (id) {
            ApiClient.Get(Urls.userPayments(id), ConvertQueryString(location.search))
                .then(res => {
                    let responseData = res.result;
                    responseData.items.forEach(formatPaymentItem);
                    setOnlinePaymentLists(responseData);
                });

        } else {
            ApiClient.Get(Urls.getOnlinePaymentList(), ConvertQueryString(location.search))
                .then(res => {
                    let responseData = res.result;
                    responseData.items.forEach(formatPaymentItem);
                    setOnlinePaymentLists(responseData);
                });
        }

    }, [location.search, id]);


    useEffect(() => {
        getData()
    }, [getData]);

    const tableActions = (items) => {
        switch (items.actionName) {
            case "view":
                history.push(`/financial/online-payment/${items.data.id}`);
                break;
            case "edit":
                openConfirmationPaymentModal(items.data);
                break;
            default:
                break;
        }
    }

    const exportActions = (item) => {
        let params = {
            ...ConvertQueryString(location.search)
        }
        ApiClient.Get(Urls.getExportOnlinePaymentList(item.export), params)
            .then((res) => {
                let resData = res.result;
                if (!Array.isArray(resData)) {
                    const url = `${Urls.base()}/${resData}`
                    window.open(url, '_blank')
                } else {
                    resData.map((item) => {
                        const url = `${Urls.base()}/${item}`
                        windowOpen(url);
                    })
                }
            })
    }

    const windowOpen = (item) => {
        window.open(item)
    }

    return (<WrapperLayout>
        <div className="c-wrapper">
            {id && <HeaderContent className='justify-content-end'>
                <BackToPage toPage={`/userDetail/${match.params.id}/financial`}/>
            </HeaderContent>

            }

            <MainContent>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout>
                            {
                                id
                                    ?
                                    <>
                                        <h5>پرداخت‌های کاربر</h5>
                                        <span>
                                           در اینجا لیست پرداخت‌های این کاربر مورد بررسی قرار می‌گیرد.
                                        </span>

                                    </>

                                    :

                                    <>
                                        <h5>لیست پرداخت‌ها</h5>
                                        <span>در این بخش لیست همه پرداخت‌ها قابل مشاهده است.</span>
                                    </>
                            }



                        </PageTitleLayout>


                        <Table
                            data={onlinePaymentLists.items}
                            config={tableConfig}
                            actionDispatched={tableActions}
                            pageConfig={{
                                pageNumber: onlinePaymentLists.pageNumber,
                                pageSize: onlinePaymentLists.pageSize,
                                totalItemsCount: onlinePaymentLists.totalItemsCount,
                                totalPagesCount: onlinePaymentLists.totalPagesCount,
                            }}
                            exportDispatched={exportActions}
                        >

                        </Table>
                    </div>
                </div>
            </MainContent>
        </div>

        <Modal centered show={paymentConfirmationModal} onHide={() => {
            handleClose(false)
        }}>
            <Modal.Header>
                <Modal.Title>تایید پرداخت</Modal.Title>
                <i onClick={() => {
                    handleClose(true)
                }} className="close-btn fa fa-xmark"/>
            </Modal.Header>
            <Modal.Body>
                <PaymentConfirmationModal
                    data={modalData}
                    visible={paymentConfirmationModal}
                    onCloseModal={(e) => setPaymentConfirmationModal(e)}
                    dataModalChanged={() => getData()}
                />
            </Modal.Body>

        </Modal>

    </WrapperLayout>)
}


export default OnlinePayment;