import {email, invoiceDate, invoiceNumber, OldPanelInvoiceEmail, withEmail, withMobile} from "../Rules";
import * as Yup from "yup";

export const AddInvoiceNumberSchema = Yup.object().shape({
    invoiceNumber,
    invoiceDate
});
export const AddInvoiceNumberInOldPanelSchema = Yup.object().shape({
    OldPanelInvoiceEmail,
    withEmail,
    withMobile,
    invoiceNumber,
    invoiceDate
});

export const RetryOldPanelInvoiceSchema = Yup.object().shape({
    OldPanelInvoiceEmail,
    withEmail,
    withMobile,
});