export const NumericalValidationMultilang = (strNum) => {
    let result;
    let isValid;

    isValid = preventAlphabet(strNum);
    if (isValid) {
        result = convertPersianToEnglish(strNum);
        if (strNum !== result) {
          return result;
        } else {
          return strNum;
        }
      } else {
        return strNum.substring(0, strNum.length - 1);
      }
}

const convertPersianToEnglish = (strNum) => {
    let result;
    const persianNumbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'];
    const arabicNumbers = ['١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠'];
    const englishNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    result = strNum.split('').map(c => englishNumbers[persianNumbers.indexOf(c)] || englishNumbers[arabicNumbers.indexOf(c)] || c).join('');
    return result;
  }

const preventAlphabet = (input) => {
    const numbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    let result = true;

    input.split('').map((char) => {
      // console.log(`include: ${char}`, numbers.includes(char));
      result = result && numbers.includes(char);
    });
    return result;
  }
