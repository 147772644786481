import {Formik} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {StyledButton} from "../../../../Component/Kit/Button";
import {StyledForm, StyledSelect, StyledSwitchBox} from "../../../../Component/Kit/Form";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import * as Yup from "yup";
import {Loader} from "../../../../Component/Shared/Loader";
import {toast} from "react-toastify";

const OfficialPaymentStatusModal = (props) => {
    const match = useRouteMatch();
    const userId = match.params.id;
    const {onClose, onSubmit} = props;
    const [data, setData] = useState(null);

    const options = [
        {
            name: "رسمی",
            value: 'true'
        },
        {
            name: "غیر رسمی",
            value: 'false'
        },
        {
            name: "رسمی - غیر رسمی",
            value: 'null'
        },
    ]
    const conversionMap = {
        "true": true,
        "false": false,
        "null": null
    };

    const getOfficialPayment = useCallback(() => {
        ApiClient.Get(Urls.officialPayment(userId))
            .then(res => {
                const data = {
                    officialPaymentStatus: String(res.result.officialPaymentStatus),
                    allowOfficialPaymentChoice: res.result.allowOfficialPaymentChoice,
                }
                setData(data);
            })
    }, [userId]);


    useEffect(() => {
        getOfficialPayment();
    }, [getOfficialPayment]);

    const onChangeSwitch = (formik) => {
        if(!formik.values.allowOfficialPaymentChoice === true) return;
        if(formik.values.officialPaymentStatus === 'null') {
            formik.setFieldValue("officialPaymentStatus", 'true')
        }
    }

    return (
        <div className="col-12">
            <div className="row">
                {data ?
                    <Formik
                        initialValues={{
                            allowOfficialPaymentChoice: data.allowOfficialPaymentChoice,
                            officialPaymentStatus: data.officialPaymentStatus,
                        }}
                        validationSchema={
                            Yup.object().shape({})
                        }
                        onSubmit={(values, actions) => {
                            const payload = {
                                ...values,
                                officialPaymentStatus: conversionMap[values.officialPaymentStatus]
                            }

                            ApiClient.Patch(Urls.officialPayment(userId), JSON.stringify(payload))
                                .then((res) => {
                                    if (res) {
                                        toast.success('تغییرات  با موفقیت ثبت شد.');
                                        onSubmit(false);
                                    }
                                })
                                .catch().finally(() => {
                                actions.setSubmitting(false)
                            })

                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                className="p-0"
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                                onBlur={formik.handleBlur}
                            >
                                <div className="d-flex mb-3">
                                    <p className="ml-3 mb-0">امکان تغییر توسط کاربر</p>
                                    <StyledSwitchBox
                                        focus={true}
                                        name="allowOfficialPaymentChoice"
                                        checked={formik.values.allowOfficialPaymentChoice}
                                        onChange={(e) => {
                                            formik.setFieldValue("allowOfficialPaymentChoice", !formik.values.allowOfficialPaymentChoice)
                                            onChangeSwitch(formik)
                                        }}
                                    />
                                </div>

                                <StyledSelect
                                    lable="وضعیت"
                                    hasDefaultOption={formik.values.officialPaymentStatus}
                                    focus={true}
                                    name="officialPaymentStatus"
                                    value={formik.values.officialPaymentStatus}
                                    error={(formik.errors.officialPaymentStatus && formik.touched.officialPaymentStatus)
                                        ? formik.errors.officialPaymentStatus
                                        : 'false'
                                    }
                                    optionItems={!formik.values.allowOfficialPaymentChoice ? options.filter(option => option.value !== 'null') : options}

                                    onChange={(e) => formik.setFieldValue("officialPaymentStatus", e.target.value)}
                                />

                                <div className="d-flex justify-content-between">
                                    <StyledButton
                                        btnStyle="greenBtn ml-3"
                                        width="38%"
                                        loading={formik.isSubmitting ? 1 : 0}
                                        type="submit"
                                    >
                                        تایید
                                    </StyledButton>

                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => onClose()}
                                    >
                                        انصراف
                                    </StyledButton>
                                </div>

                            </StyledForm>
                        )}
                    </Formik>
                    :
                    <>
                        <Loader/>
                    </>
                }

            </div>
        </div>
    )
}

export default OfficialPaymentStatusModal;