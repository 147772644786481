import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import BackToPage from '../../../Component/Kit/back'
import { StyledButton } from '../../../Component/Kit/Button'
import { StyledForm, StyledInput, StyledSelect, StyledSwitchBox } from '../../../Component/Kit/Form'
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import { PlanConfigurationsList, PlanItemLimitationModel } from '../../../Utility/Model/PlanItemLimitation.model'
import { PlanPositionModel } from '../../../Utility/Model/PlanPositionModel'
import { SystemLineProviderEnum } from '../../../Utility/Model/provider-enum.model'
import { SeprateNumberWithComma } from '../../../Utility/Services/SeprateNumberWithComma'
import { ModifyPlanItemDetailSchema } from '../../../Utility/Validation/Schema/ModifyPlanItemDetailSchema'
import { PlanSectionStyled } from '../style'

function Repeat(props) {
    let items = [];
    for (let i = 0; i < props.numTimes; i++) {
        items.push(props.children(i));
    }
    return <>{items}</>;
}

const PlanItemDetail = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const editMode = !!match.params.id;
    const [planTiers, setPlanTiers] = useState([]);
    const [planBasePriceTier, setPlanBasePriceTier] = useState({});
    // const [planBasePriceTier, setPlanBasePriceTier] = useState(Array(10).fill().map((f, i) => ({ creditTierId: i + 1, basePrice: '' })));
    const [planPerMonth, setPlanPerMonth] = useState([
        { month: 1, name: 'یک ماهه', active: false, totalAmount: 0, creditAmount: 0, lineAmount: 0 },
        { month: 3, name: 'سه ماهه', active: false, totalAmount: 0, creditAmount: 0, lineAmount: 0 },
        { month: 6, name: 'شش ماهه', active: false, totalAmount: 0, creditAmount: 0, lineAmount: 0 },
        { month: 12, name: 'یکساله', active: false, totalAmount: 0, creditAmount: 0, lineAmount: 0 }
    ]);
    const [planDetail, setPlanDetail] = useState({});
    const [hasLine, setHasLine] = useState(false);
    const [hasFreeCredit, setHasFreeCredit] = useState(false);
    const planBasePriceInTiersName = {
        fromPrice: 'از قیمت',
        toPrice: 'تا قیمت',
        basePrice: 'قیمت پایه',
        planBasePrice: 'تعرفه پلن',
    }
    const planLimitationItemName = {
        name: 'نام قابلیت',
        isActive: 'وضعیت',
        limitationCount: 'محدودیت‌ها',
    }

    useEffect(() => {
        if (match.params.id) {
            ApiClient.Get(Urls.Plan(match.params.id))
                .then((res) => {
                    let basePrices = Object.assign({}, ...res.result.creditTiers.map((x) => ({ [x.creditTierId]: x.basePrice })));
                    setPlanBasePriceTier(basePrices);

                    setPlanDetail(res.result);
                    const month = planPerMonth.map(item => ({
                        ...res.result.months.find(x => {
                            if (x.month === item.month) {
                                item.active = true;
                                item.creditAmount = x.creditAmount;
                                item.lineAmount = x.lineAmount;
                                item.totalAmount = x.totalAmount;
                                return x
                            }
                        })
                        , ...item
                    }))
                    setHasLine(res.result.line?.providerId)
                    setHasFreeCredit(res.result.credit?.basePrice)
                    setPlanPerMonth(month);
                })
        }
        ApiClient.Get(Urls.GetCreditTier())
            .then((res) => {
                setPlanTiers(res.result);
            })
    }, [match.params.id])

    const deletePlan = () => {
        Swal.fire({
            title: `آیا از حذف پلن مطمئن هستید؟`,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.Plan(match.params.id))
                    .then(() => {
                        toast.success('پلن با موفقیت حذف شد.');
                        history.goBack();
                    })
            }
        })
    }

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    {editMode && !planDetail.isDeleted &&
                        <StyledButton
                            btnStyle="redBtn mx-1"
                            onClick={() => deletePlan()}
                        >
                            حذف  پلن
                        </StyledButton>
                    }

                    <span className="fakeContainer"></span>
                    <BackToPage />
                </HeaderContent>
            </div>

            <MainContent>
                <div className="col-12 col-lg-12">
                    <div className="row">
                        <div className="col-12 col-lg-12 mt-3">
                            <PageTitleLayout>
                                <h5>جزئیات پلن {planDetail && planDetail.name}</h5>
                            </PageTitleLayout>
                            <hr className="mt-4 mx-1" />
                        </div>
                    </div>

                    {/* <div className="row">
                    <div className="col-12 col-lg-12"> */}
                    <Formik
                        initialValues={{
                            planBasePriceTier,
                            planIsActive: planDetail.isActive || false,
                            planName: planDetail.name || '',
                            planPosition: planDetail.position || '',
                            planCreditCount: planDetail.credit?.creditCount || '',
                            planCreditBasePrice: planDetail.credit?.basePrice || '',
                            planProviderId: planDetail.line?.providerId || '',
                            planNumberLength: planDetail.line?.numberLength || '',
                            planPerMonth,
                            planLimitationItem: planDetail.items || Array(Object.keys(PlanConfigurationsList).length).fill().map((f, i) => ({ type: i + 1, limitationCount: null, isActive: false }))
                        }}
                        validateOnMount={true}
                        enableReinitialize={true}
                        // validationSchema={ModifyPlanItemDetailSchema}
                        onSubmit={(values, actions) => {
                            let tierData = [];
                            Object.keys(values.planBasePriceTier).map((key) => {
                                tierData.push({ creditTierId: Number(key), basePrice: Number(values.planBasePriceTier[key]) })
                            });
                            const months = values.planPerMonth.filter(item => item.active)
                            const payload = {
                                isActive: values.planIsActive,
                                refrenceId: Number(match.params.id) || null,
                                name: values.planName,
                                position: Number(values.planPosition),
                                line: hasLine && values.planProviderId && values.planNumberLength
                                    ? {
                                        providerId: Number(values.planProviderId),
                                        numberLength: Number(values.planNumberLength),
                                    }
                                    : null,
                                credit: hasFreeCredit && values.planCreditCount && values.planCreditBasePrice
                                    ? {
                                        creditCount: Number(values.planCreditCount),
                                        basePrice: Number(values.planCreditBasePrice),
                                    }
                                    : null,
                                months: months,
                                items: values.planLimitationItem,
                                creditTiers: tierData
                            }
                            ApiClient.Post(Urls.Plan(), JSON.stringify(payload))
                                .then(() => {
                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                    history.goBack();
                                })
                        }}
                    >
                        {(formik) => (
                            <StyledForm className="px-0"
                                onSubmit={formik.handleSubmit}
                                onBlur={formik.handleBlur}
                            >
                                {/* <pre>{JSON.stringify(formik.errors, '', 2)}</pre> */}

                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="mb-3">مشخصات پلن: </h5>
                                        <PlanSectionStyled>
                                            <div className="row">
                                                <div className="col-12 col-md-4">
                                                    <div className="d-flex col-12 mb-3 align-items-center">
                                                        <strong className="ml-2 font-12">وضعیت پلن: </strong>
                                                        <StyledSwitchBox
                                                            name="planIsActive"
                                                            checked={formik.values.planIsActive}
                                                            onChange={(e) => formik.setFieldValue('planIsActive', e.target.checked)}
                                                        />
                                                    </div>
                                                    <StyledInput
                                                        formGroupClass="col-12"
                                                        lable="عنوان پلن"
                                                        focus={true}
                                                        placeholder="عنوان پلن"
                                                        name="planName"
                                                        value={formik.values.planName !== undefined
                                                            ? formik.values.planName
                                                            : formik.initialValues.planName}
                                                        error={(formik.errors.planName && formik.touched.planName)
                                                            ? formik.errors.planName
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planName", e.target.value)}
                                                    />
                                                    <StyledSelect
                                                        formGroupClass="col-12"
                                                        lable="جایگاه پلن"
                                                        focus={true}
                                                        name="planPosition"
                                                        value={formik.values.planPosition !== undefined
                                                            ? formik.values.planPosition
                                                            : formik.initialValues.planPosition}
                                                        optionItems={PlanPositionModel}
                                                        hasDefaultOption
                                                        error={(formik.errors.planPosition && formik.touched.planPosition)
                                                            ? formik.errors.planPosition
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planPosition", e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-4">
                                                    <div className="d-flex col-12 mb-3 align-items-center">
                                                        <strong className="ml-2 font-12">خط مجزا دارد: </strong>
                                                        <StyledSwitchBox
                                                            name="planHasLine"
                                                            checked={hasLine}
                                                            onChange={(e) => {
                                                                setHasLine(!hasLine)
                                                                if (hasLine) {
                                                                    formik.values.planPerMonth.map((item, index) => (item.active) ? formik.setFieldValue(`planPerMonth.${index}.lineAmount`, 0) : null);
                                                                }
                                                            }} />
                                                    </div>
                                                    <StyledInput
                                                        formGroupClass="col-12"
                                                        lable="تعداد ارقام خط"
                                                        focus={true}
                                                        type="number"
                                                        placeholder="تعداد ارقام خط"
                                                        name="planNumberLength"
                                                        value={formik.values.planNumberLength !== undefined
                                                            ? formik.values.planNumberLength
                                                            : formik.initialValues.planNumberLength}
                                                        error={(formik.errors.planNumberLength && formik.touched.planNumberLength)
                                                            ? formik.errors.planNumberLength
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planNumberLength", e.target.value)}
                                                        disabled={!hasLine}
                                                    />
                                                    <StyledSelect
                                                        formGroupClass="col-12"
                                                        lable="ارائه دهنده خدمات"
                                                        focus={true}
                                                        name="planProviderId"
                                                        value={formik.values.planProviderId !== undefined
                                                            ? formik.values.planProviderId
                                                            : formik.initialValues.planProviderId}
                                                        optionItems={SystemLineProviderEnum}
                                                        hasDefaultOption
                                                        error={(formik.errors.planProviderId && formik.touched.planProviderId)
                                                            ? formik.errors.planProviderId
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planProviderId", e.target.value)}
                                                        disabled={!hasLine}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-4">
                                                    <div className="d-flex col-12 mb-3 align-items-center">
                                                        <strong className="ml-2 font-12">اعتبار رایگان دارد: </strong>
                                                        <StyledSwitchBox
                                                            name="hasFreeCredit"
                                                            checked={hasFreeCredit}
                                                            onChange={(e) => {
                                                                setHasFreeCredit(!hasFreeCredit);
                                                                if (hasFreeCredit) {
                                                                    formik.values.planPerMonth.map((item, index) => (item.active) ? formik.setFieldValue(`planPerMonth.${index}.creditAmount`, 0) : null);
                                                                }
                                                            }} />
                                                    </div>
                                                    <StyledInput
                                                        formGroupClass="col-12"
                                                        lable="تعداد اعتبار رایگان"
                                                        focus={true}
                                                        placeholder="تعداد اعتبار رایگان"
                                                        name="planCreditCount"
                                                        value={formik.values.planCreditCount !== undefined
                                                            ? formik.values.planCreditCount
                                                            : formik.initialValues.planCreditCount}
                                                        error={(formik.errors.planCreditCount && formik.touched.planCreditCount)
                                                            ? formik.errors.planCreditCount
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planCreditCount", e.target.value)}
                                                        disabled={!hasFreeCredit}
                                                    />
                                                    <StyledInput
                                                        formGroupClass="col-12"
                                                        lable="تعرفه اعتبار رایگان"
                                                        focus={true}
                                                        name="planCreditBasePrice"
                                                        value={formik.values.planCreditBasePrice !== undefined
                                                            ? formik.values.planCreditBasePrice
                                                            : formik.initialValues.planCreditBasePrice}
                                                        error={(formik.errors.planCreditBasePrice && formik.touched.planCreditBasePrice)
                                                            ? formik.errors.planCreditBasePrice
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planCreditBasePrice", e.target.value)}
                                                        disabled={!hasFreeCredit}
                                                    />
                                                </div>
                                            </div>
                                        </PlanSectionStyled>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <h5 className="my-3">قیمت جزئیات پلن‌ها: </h5>
                                        <PlanSectionStyled>
                                            <div className="price-per-plan">
                                                <div className="head-row">
                                                    <div></div>
                                                    <div>وضعیت</div>
                                                    <div>اعتبار</div>
                                                    <div> خط</div>
                                                    <div>کل</div>
                                                </div>

                                                {/* {formik.values.planPerMonth.map((method, index) => ( */}
                                                <Repeat numTimes={4}>
                                                    {(index) =>
                                                        <div className="cell-row" key={`planPerMonth-${index}`}>
                                                            <div>{planPerMonth[index].name}</div>
                                                            <div>
                                                                <StyledSwitchBox
                                                                    name={`planPerMonth_${index}.active`}
                                                                    checked={formik.values.planPerMonth[index].active !== undefined
                                                                        ? formik.values.planPerMonth[index].active
                                                                        : formik.initialValues.planPerMonth[index].active}
                                                                    onChange={(e) => formik.setFieldValue(`planPerMonth.${index}.active`, e.target.checked)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <StyledInput
                                                                    formGroupClass="mb-0"
                                                                    name={`planPerMonth_${index}_creditAmount`}
                                                                    id={`planPerMonth_${index}-creditAmount`}
                                                                    placeholder=""
                                                                    value={formik.values.planPerMonth[index].creditAmount !== undefined
                                                                        ? formik.values.planPerMonth[index].creditAmount
                                                                        : formik.initialValues.planPerMonth[index].creditAmount}
                                                                    onChange={(e) => formik.setFieldValue(`planPerMonth.${index}.creditAmount`, e.target.value)}
                                                                    disabled={!formik.values.planPerMonth[index].active || !hasFreeCredit}
                                                                    error={((formik.touched[`planPerMonth_${index}_creditAmount`] && hasFreeCredit) && !formik.values.planPerMonth[index].creditAmount) ? 'این فیلد الزامی است.' : 'false'}
                                                                />
                                                            </div>
                                                            <div>
                                                                <StyledInput
                                                                    formGroupClass="mb-0"
                                                                    name={`planPerMonth_${index}_lineAmount`}
                                                                    id={`planPerMonth.${index}-lineAmount`}
                                                                    placeholder=""
                                                                    value={formik.values.planPerMonth[index].lineAmount !== undefined
                                                                        ? formik.values.planPerMonth[index].lineAmount
                                                                        : formik.initialValues.planPerMonth[index].lineAmount}
                                                                    onChange={(e) => formik.setFieldValue(`planPerMonth.${index}.lineAmount`, e.target.value)}
                                                                    error={((formik.touched[`planPerMonth_${index}_lineAmount`] && hasLine) && !formik.values.planPerMonth[index].lineAmount) ? 'این فیلد الزامی است.' : 'false'}
                                                                    disabled={!formik.values.planPerMonth[index].active || !hasLine}
                                                                />
                                                            </div>
                                                            <div>
                                                                <StyledInput
                                                                    formGroupClass="mb-0"
                                                                    name={`planPerMonth_${index}_totalAmount`}
                                                                    id={`planPerMonth.${index}-totalAmount`}
                                                                    placeholder=""
                                                                    value={formik.values.planPerMonth[index].totalAmount !== undefined
                                                                        ? formik.values.planPerMonth[index].totalAmount
                                                                        : formik.initialValues.planPerMonth[index].totalAmount}
                                                                    onChange={(e) => formik.setFieldValue(`planPerMonth.${index}.totalAmount`, e.target.value)}
                                                                    error={(formik.touched[`planPerMonth_${index}_totalAmount`] && !formik.values.planPerMonth[index].totalAmount) ? 'این فیلد الزامی است.' : 'false'}
                                                                    disabled={!formik.values.planPerMonth[index].active}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </Repeat>
                                                {/* ))} */}
                                            </div>
                                        </PlanSectionStyled>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="my-3">ایجاد محدودیت‌ها: </h5>

                                        <div className="table-responsive custom-table planLimitation-table">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" key={`headLine_#`}>#</th>
                                                        {
                                                            planLimitationItemName &&
                                                            Object.entries(planLimitationItemName).map(([item, value]) => (
                                                                <th scope="col" key={`headLine_${item}`}>{value}</th>
                                                            ))
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(planDetail.items || formik.initialValues.planLimitationItem) && PlanItemLimitationModel.map((dataItem, index) => (
                                                        <>
                                                            <tr key={`planLimitationItemـ${dataItem.value}`}>
                                                                <th scope="row">{dataItem.value}</th>
                                                                <td>
                                                                    {dataItem.name}
                                                                </td>
                                                                <td>
                                                                    {formik.values.planLimitationItem[index] &&
                                                                        <StyledSwitchBox
                                                                            name={`planLimitationItem.${index}.isActive`}
                                                                            checked={formik.values.planLimitationItem[index].isActive !== undefined
                                                                                ? formik.values.planLimitationItem[index].isActive
                                                                                : formik.initialValues.planLimitationItem[index].isActive}
                                                                            onChange={(e) => {
                                                                                formik.setFieldValue(`planLimitationItem.${index}.isActive`, e.target.checked)
                                                                                // if (e.target.checked === false && PlanConfigurationsList[dataItem.key]) {
                                                                                //     formik.setFieldValue(`planLimitationItem.${index}.limitationCount`, null);
                                                                                // }
                                                                            }}
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {PlanConfigurationsList[dataItem.key] ? (
                                                                        <StyledInput
                                                                            formGroupClass="planTierInput"
                                                                            type="number"
                                                                            focus={false}
                                                                            name={`planLimitationCount_${index}`}
                                                                            id={`planLimitationCount_${index}`}
                                                                            key={`planLimitationCount_${index}`}
                                                                            value={formik.values.planLimitationItem[index].limitationCount !== undefined
                                                                                ? formik.values.planLimitationItem[index].limitationCount
                                                                                : formik.initialValues.planLimitationItem[index].limitationCount}
                                                                            // error={(formik.touched[`planLimitationCount_${index}`] && !formik.values.planLimitationItem[index].limitationCount) && formik.values.planLimitationItem[index].isActive ? 'این فیلد الزامی است.' : 'false'}
                                                                            error="false"
                                                                            onChange={(e) => formik.setFieldValue(`planLimitationItem.${index}.limitationCount`, Number(e.target.value))}
                                                                            disabled={!formik.values.planLimitationItem[index].isActive}
                                                                        />
                                                                    ) : <span>&nbsp;&nbsp;&nbsp; --- </span>}
                                                                </td>
                                                            </tr>
                                                            <tr className="spacer" key={`spacer${dataItem.value}`}>
                                                                <td colSpan="100"></td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <h5 className="my-3">قیمت پایه پلن برای هر تیر:</h5>

                                        <div className="table-responsive custom-table">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" key={`headLine_#`}>#</th>
                                                        {
                                                            planBasePriceInTiersName &&
                                                            Object.entries(planBasePriceInTiersName).map(([item, value]) => (
                                                                <th scope="col" key={`headLine_${item}`}>{value}</th>
                                                            ))
                                                        }
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {planTiers.map((dataItem, index) => (
                                                        <>
                                                            <tr key={`planTierId-${dataItem.id}`}>
                                                                <th scope="row">{index + 1}</th>
                                                                {dataItem.fromPrice &&
                                                                    <>
                                                                        <td> {SeprateNumberWithComma(dataItem.fromPrice)} ریال</td>
                                                                        <td>{SeprateNumberWithComma(dataItem.toPrice)} ریال</td>
                                                                    </>
                                                                }
                                                                <td>{dataItem.basePrice}</td>
                                                                <td>
                                                                    <StyledInput
                                                                        formGroupClass="planTierInput"
                                                                        type="text"
                                                                        focus={false}
                                                                        name={`planBasePriceTier_${dataItem.id}`}
                                                                        id={`planBasePriceTier_${dataItem.id}`}
                                                                        key={`planBasePriceTier_${dataItem.id}`}
                                                                        value={formik.values.planBasePriceTier[dataItem.id] !== undefined
                                                                            ? formik.values.planBasePriceTier[dataItem.id]
                                                                            : formik.initialValues.planBasePriceTier[dataItem.id]}
                                                                        error={(formik.touched[`planBasePriceTier_${dataItem.id}`] && !formik.values.planBasePriceTier[dataItem.id]) ? 'این فیلد الزامی است.' : 'false'}
                                                                        onChange={(e) => formik.setFieldValue(`planBasePriceTier.${dataItem.id}`, e.target.value)}
                                                                    /></td>
                                                            </tr>
                                                            <tr className="spacer" key={`spacer${dataItem.id}`}>
                                                                <td colSpan="100"></td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}

                                {!planDetail.isDeleted && (
                                    <div className="mt-4 d-flex justify-content-between">
                                        <StyledButton
                                            width="100%"
                                            btnStyle="greenBtn"
                                            type="submit"
                                            disabled={!formik.isValid}
                                        >
                                            ثبت
                                        </StyledButton>
                                    </div>
                                )}
                            </StyledForm>
                        )}
                    </Formik>
                    {/* </div>
                </div> */}
                </div>
            </MainContent>
        </WrapperLayout>
    )
}

export default PlanItemDetail