import React, { useCallback, useEffect, useState } from "react";
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import Table from "./../../Component/Shared/Table/Table";
import { HeaderContent, MainContent, PageTitleLayout } from "../../Component/Kit/Layout";
import { StyledButton } from "../../Component/Kit/Button";
import Modal from "./../../Component/Kit/Modal";
import AddForbiddenWords from "./AddForbiddenWord";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ConvertQueryString } from "../../Utility/Services/convert-query-string";
import ValidateMessage from "./ValidateMessage";

function ForbiddenWordsList() {
    const location = useLocation()
    const [forbiddenData, setForbiddenData] = useState({});
    const [modifyItem, setModifyItem] = useState({});
    const [isModal, setIsModal] = useState(false);
    const [validateModalHandler, setValidateModalHandler] = useState(false);
    const tableConfig = {
        header: {
            word: "کلمه ممنوعه",
        },
        actions: {
            edit: {
                icon: "fa fa-edit",
            },
            delete: {
                icon: "fa fa-trash",
            },
        },
        mode: ["search"],
    };
    // const applyFilter = (filter) => {
    //     settableFilter(filter);
    // };
    const addForbiddenWordModal = () => {
        setIsModal(true)
        setModifyItem({ ...{} })
    }

    const tableActions = (item) => {
        switch (item.actionName) {
            case 'edit':
                const currentItem = item.data
                setModifyItem({ ...currentItem });
                setIsModal(true);
                break;
            case 'delete':
                deleteForbiddenWord(item.data.id);
                break;
            default:
                break;
        }
    };
    const deleteForbiddenWord = (item) => {
        Swal.fire({
            title: '',
            text: `آیا از حذف این کلمه ممنوعه مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.modifyAndDeleteForbiddenWords(item))
                    .then(res => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        getForbiddenWordsList()
                    })
            }
        })

    }
    const getForbiddenWordsList = useCallback(() => {
        ApiClient.Get(Urls.forbiddenWords(), ConvertQueryString(location.search))
            .then(res => {
                let data = res.result;
                setForbiddenData(data);
            })
    }, [location.search])

    const modalDataChange = (e) => {
        if (e) {
            getForbiddenWordsList()
        }
    }

    useEffect(() => {
        getForbiddenWordsList()
    }, [location.search, getForbiddenWordsList])

    return (
        <>
            <HeaderContent className="justify-content-end">
                <div className="d-flex align-items-center justify-content-start w-100">
                    <StyledButton
                        btnStyle="greenBtn mx-1"
                        onClick={() => addForbiddenWordModal()}
                    >
                        اضافه کردن کلمات ممنوعه
                    </StyledButton>
                    <StyledButton
                        btnStyle="greenBtn mx-1 mr-2"
                        onClick={() => setValidateModalHandler(true)}
                    >
                        اعتبارسنجی پیامک
                    </StyledButton>
                </div>
            </HeaderContent>
            <MainContent>
                <PageTitleLayout>
                    <h5>کلمات ممنوعه</h5>
                    <span>در اینجا می‌توانید کلمات ممنوعه را مشاهده نمایید.</span>
                </PageTitleLayout>
                <Table
                    data={forbiddenData.items}
                    config={tableConfig}
                    pageConfig={{
                        pageNumber: forbiddenData.pageNumber,
                        pageSize: forbiddenData.pageSize,
                        totalItemsCount: forbiddenData.totalItemsCount,
                        totalPagesCount: forbiddenData.totalPagesCount,
                    }}
                    actionDispatched={tableActions}
                />
            </MainContent>
            <Modal
                onClose={() => setIsModal(false)}
                visible={isModal}
                onCancel={() => setIsModal(false)}
                headerTitle="حذف کلمه ممنوعه"
            >
                <AddForbiddenWords data={modifyItem}
                    onCloseModal={(e) => setIsModal(e)}
                    dataChange={(e) => modalDataChange(e)} />
            </Modal>


            <ValidateMessage
                
                visible={validateModalHandler}
                onCloseModal={(e) => setValidateModalHandler(e)} />


        </>
    )
}

export default ForbiddenWordsList;
