import React, { useState } from 'react';
import { StyledButton } from "../../Component/Kit/Button";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { UserSuspendSchema } from "../../Utility/Validation/Schema/UserSuspendSchema";
import { StyledForm, StyledTextArea } from "../../Component/Kit/Form";

function PendingSMSModal({ data, onCloseModal }) {
    const [submitLoading, setsubmitLoading] = useState(false);
    let match = useRouteMatch();
    let history = useHistory();
    const queueConfirm = async (event) => {
        setsubmitLoading(true)
        try {
            let result = await Swal.fire({
                title: `آیا از لغو ارسال این پیامک مطمئن هستید؟`,
                text: "احتیاط کن !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "انصراف",
                cancelButtonColor: "#d33",
                confirmButtonText: "بله، مطمئن هستم",
            });
            if (result.isConfirmed) {
                let body = {
                    confirm: false,
                    description: event
                }
                await ApiClient.Patch(`${Urls.getSingleQueue(match.params.id)}?smsType=${data.smsType}&storageType=${data.smsStorage}`, body)
                toast.success('تغییرات  با موفقیت ثبت شد.');
                history.push('/pending-sms/list')
            }
        } catch (error) {
            toast.error(error);
        }
    }
    
    const userBlock = async (event) => {
        try {
            const result = await Swal.fire({
                title: `آیا از تعلیق این کاربر مطمئن هستید؟`,
                text: "اگه فکر می‌کنی درسته، پس حتماً انجامش بده!!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "انصراف",
                cancelButtonColor: "#d33",
                confirmButtonText: "بله، مطمئن هستم",
            })
            if (result.isConfirmed) {
                let body = {
                    confirm: false,
                    description: event
                }
                await ApiClient.Patch(Urls.userSuspend(data.userId), event);
                await ApiClient.Patch(`${Urls.getSingleQueue(match.params.id)}?smsType=${data.smsType}&storageType=${data.smsStorage}`, body)
                toast.success('تغییرات  با موفقیت ثبت شد.');
                history.push('/pending-sms/list')
            }
        } catch (e) {
            toast.error(e);
        }
    }
    return (
        <>
            <Formik
                initialValues={{
                    suspendDescription: "",
                }}
                validationSchema={UserSuspendSchema}
                enableReinitialize={false}
                onSubmit={(values, actions) => {
                    if (data.mode === "reject") {
                        queueConfirm(JSON.stringify(values.suspendDescription))
                    } else {
                        userBlock(JSON.stringify(values.suspendDescription))
                    }
                    actions.resetForm()
                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false)
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}>
                        <StyledTextArea
                            lable="توضیحات"
                            focus={true}
                            name="suspendDescription"
                            value={formik.values.suspendDescription}
                            error={(formik.errors.suspendDescription && formik.touched.suspendDescription)
                                ? formik.errors.suspendDescription
                                : 'false'
                            }
                            onChange={(e) => {
                                const str = e.target.value.trim() !== '' ? e.target.value : '';
                                formik.setFieldValue("suspendDescription", str)
                            }
                            }
                        />
                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid}
                                loading={submitLoading ? 1 : 0}
                                btnStyle="redBtn"
                                type="submit"
                            >
                                تایید
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    );
}

export default PendingSMSModal
