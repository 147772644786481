import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Formik } from "formik";
import {
  StyledForm,
  StyledInput,
  StyledSelect,
} from "../../../../../Component/Kit/Form";
import { EditUserLegalSchema } from "../../../../../Utility/Validation/Schema/EditUserLegalSchema";
import {
  CompanyActivityList,
  CompanyTypeList,
} from "../../../../../Utility/Model/company.model";
import { StyledButton } from "../../../../../Component/Kit/Button";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";
import { toast } from "react-toastify";
import EditUserInvoiceModal from "../../EditUserInvoiceModal";
import Modal from "../../../../../Component/Kit/Modal";

function EditUserLegalInformation({ userLegalData }) {
  const [legalInformation, setLegalInformation] = useState(null);
  const [invoiceInformation, setInvoiceInformation] = useState(null);
  let match = useRouteMatch();
  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);

  const invoiceHandler = (result) => {
    if (result) {
      ApiClient.Patch(Urls.modifyUserInvoice(match.params.id), result).then(
        (res) => {
          closeModal();
        }
      );
    } else {
      closeModal();
    }
  };
  const closeModal = () => {
    setInvoiceModal(false);
    history.push(`/userDetail/${match.params.id}/profile`);
    toast.success("تغییرات  با موفقیت ثبت شد.");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.entries(userLegalData).length && userLegalData.isLegal) {
      setLegalInformation(userLegalData);
    } else {
      history.push(`/userDetail/${match.params.id}/profile`);
    }
  }, [history, match.params.id, userLegalData]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12">
          <h5 className="my-4">ویرایش اطلاعات حقوقی</h5>
        </div>

        {legalInformation && (
          <div className="col-12 col-lg-12 px-0">
            <Formik
              initialValues={{
                companyName: legalInformation.companyName,
                registrationNumber: legalInformation.registrationNumber,
                nationalIdCompany: legalInformation.companyNationalId,
                companyType: legalInformation.companyType,
                companyActivityField: legalInformation.companyActivityField,
              }}
              validationSchema={EditUserLegalSchema}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                let body = {
                  companyName: values.companyName,
                  registrationNumber: values.registrationNumber,
                  nationalId: values.nationalIdCompany,
                  companyType: values.companyType,
                  companyActivityField: values.companyActivityField,
                };
                setSubmitLoading(true);

                ApiClient.Patch(Urls.modifyUserLegal(match.params.id), body)
                  .then((res) => {
                    setSubmitLoading(false);
                    if (Object.entries(res.result.userInvoices).length > 0) {
                      setInvoiceInformation(res.result);
                      setInvoiceModal(true);
                    } else {
                      toast.success("تغییرات  با موفقیت ثبت شد.");
                    }
                  })
                  .catch(() => {
                    setSubmitLoading(false);
                  });
              }}
            >
              {(formik) => (
                <StyledForm
                  className="mt-3"
                  onSubmit={formik.handleSubmit}
                  onBlur={formik.handleBlur}
                >
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                      <StyledInput
                        lable="نام شرکت"
                        focus={true}
                        type="text"
                        isCopyCapability={true}
                        name="companyName"
                        value={formik.values.companyName || ""}
                        error={
                          formik.errors.companyName &&
                            formik.touched.companyName
                            ? formik.errors.companyName
                            : "false"
                        }
                        onChange={(e) =>
                          formik.setFieldValue("companyName", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <StyledInput
                        lable="شماره ثبت"
                        focus={true}
                        placeholder="شماره ثبت"
                        type="text"
                        name="registrationNumber"
                        value={formik.values.registrationNumber}
                        error={
                          formik.errors.registrationNumber &&
                            formik.touched.registrationNumber
                            ? formik.errors.registrationNumber
                            : "false"
                        }
                        onChange={(e) =>
                          formik.setFieldValue("registrationNumber", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <StyledInput
                        lable="شناسه ملی"
                        focus={true}
                        placeholder="شناسه ملی"
                        type="text"
                        name="nationalIdCompany"
                        value={formik.values.nationalIdCompany}
                        error={
                          formik.errors.nationalIdCompany &&
                            formik.touched.nationalIdCompany
                            ? formik.errors.nationalIdCompany
                            : "false"
                        }
                        onChange={(e) =>
                          formik.setFieldValue("nationalIdCompany", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <StyledSelect
                          lable="نوع شرکت"
                          hasDefaultOption
                          focus={true}
                          name="companyType"
                          value={formik.values.companyType}
                          optionItems={CompanyTypeList}
                          error={
                            formik.errors.companyType && formik.values.companyType
                                ? formik.errors.companyType
                                : "false"
                          }
                          onChange={(e) =>
                              formik.setFieldValue("companyType", e.target.value)
                          }
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <StyledSelect
                          lable="نوع فعالیت"
                          hasDefaultOption
                          focus={true}
                          name="companyActivityField"
                          value={formik.values.companyActivityField}
                          optionItems={CompanyActivityList}
                          error={
                            formik.errors.companyActivityField &&
                            formik.values.companyActivityField
                                ? formik.errors.companyActivityField
                                : "false"
                          }
                          onChange={(e) =>
                              formik.setFieldValue("companyActivityField", e.target.value)
                          }
                      />
                    </div>
                  </div>
                  <div className="text-left">
                    <StyledButton
                      disabled={
                        (!formik.isValid || !formik.dirty) && !submitLoading
                      }
                      loading={submitLoading ? 1 : 0}
                      type="submit"
                      btnStyle="greenBtn"
                    >
                      ویرایش اطلاعات کاربر
                    </StyledButton>
                  </div>
                </StyledForm>
              )}
            </Formik>
          </div>
        )}
      </div>
      <Modal
        onClose={() => closeModal()}
        onCancel={() => closeModal()}
        visible={invoiceModal}
        headerTitle="فاکتور های کاربر"
      >
        <EditUserInvoiceModal
          data={invoiceInformation}
          onCloseModal={() => closeModal()}
          confirmModal={(e) => invoiceHandler(e)}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userLegalData: state.UserLegalDataReducer,
});

export default connect(mapStateToProps)(EditUserLegalInformation);
