// import React from 'react'
import IdentityRoleList from '../Model/IdentityRoleModel'

export function IdentityRoleService(value) {
    let result = IdentityRoleList.find((item) =>
        item.value === value
    )
    return result.name
}

export function IdentityRoleKey(name) {
    let result = IdentityRoleList.find((item) => item.name === name);
    return result.value
}

export function IdentityRoleItem(value) {
    let result = IdentityRoleList.find((item) => item.value === value
    )
    return result
}

export function IdentityRoleTitle(value) {
    let result = IdentityRoleList.find((item) => item.value === value
    )
    return result.title
}
