import React from 'react'
import { StepContainer } from './index.jsx'


function Step(props) {
    const { stepItem, index, currentStep, stepCount } = props;
    return <>
        <div className="step-root">
            <span className={`step-item ${currentStep >= stepItem.step ? 'mui-active' : 'mui-disabled'}`}>
                <span className="step-label-icon-container">
                    {currentStep > stepItem.step
                        ?
                        <svg width="24" height="24" viewBox="0 0 24 24">
                            <path fill="rgb(105, 108, 255)" d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"></path>
                        </svg>
                        :
                        <div className="box-root"></div>
                    }
                </span>
                <span className="step-label-container">
                    <span className="step-label">
                        <p className=" step-title">{stepItem.title}</p>
                        <p className=" step-subtitle">{stepItem.subTitle}</p>
                    </span>
                </span>
            </span>
        </div>

        {
            (index < stepCount - 1) &&
            <div className={`step-connector ${currentStep > stepItem.step ? 'mui-active' : 'mui-disabled'}`}>
                <span className="step-connector-line"></span>
            </div>
        }

    </>
}

function Stepper(props) {
    const { config, currentStep } = props;

    return (
        <StepContainer>
            {config.map((item, index) =>
                <Step key={`stepItem-${index}`}
                    stepItem={item}
                    index={index}
                    currentStep={currentStep}
                    stepCount={config.length}
                />
            )}
            {/* {config.map((item, index) => 
                <>
                    <div className="step-root" key={`stepItem-${index}`}>
                        <span className={`step-item ${currentStep >= item.step ? 'mui-active' : 'mui-disabled'}`}>
                            <span className="step-label-icon-container">
                                {currentStep > item.step
                                    ?
                                    <svg width="24" height="24" viewBox="0 0 24 24">
                                        <path fill="rgb(105, 108, 255)" d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"></path>
                                    </svg>
                                    :
                                    <div className="box-root"></div>
                                }
                            </span>
                            <span className="step-label-container">
                                <span className="step-label">
                                    <p className=" step-title">{item.title}</p>
                                    <p className=" step-subtitle">{item.subTitle}</p>
                                </span>
                            </span>
                        </span>
                    </div>

                    {
                        (index < config.length - 1) &&
                        <div className={`step-connector ${currentStep > item.step ? 'mui-active' : 'mui-disabled'}`}>
                            <span className="step-connector-line"></span>
                        </div>
                    }

                </>
            )} */}

        </StepContainer>
    )
}

export default Stepper;