import React from "react";

function PendingLineIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={72.444}
      height={56.681}
      {...props}
    >
      <g data-name="Group 152131">
        <path
          data-name="Icon awesome-ellipsis-h"
          d="M40.501 5.063A5.063 5.063 0 1 1 35.438.001a5.059 5.059 0 0 1 5.063 5.062ZM47.814.001a5.063 5.063 0 1 0 5.062 5.062A5.059 5.059 0 0 0 47.813.001Zm-24.75 0a5.063 5.063 0 1 0 5.062 5.062A5.059 5.059 0 0 0 23.063.001Z"
          fill="#a7abc3"
        />
        <path
          data-name="Icon awesome-phone-volume"
          d="M.76 42.47a50.149 50.149 0 0 1 70.924 0 2.6 2.6 0 0 1 .366 3.21l-6.114 9.782a2.594 2.594 0 0 1-3.162 1.033l-12.229-4.891a2.594 2.594 0 0 1-1.618-2.667l.847-8.423a40.091 40.091 0 0 0-27.093 0l.842 8.423a2.594 2.594 0 0 1-1.618 2.667L9.674 56.495a2.594 2.594 0 0 1-3.163-1.034L.395 45.68a2.594 2.594 0 0 1 .366-3.209Z"
          fill="#3f3d56"
        />
      </g>
    </svg>
  );
}

export default PendingLineIcon;
