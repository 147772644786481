import { Formik } from 'formik';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {RejectedManualPaymentSchema} from "../../../../Utility/Validation/Schema/RejectedManualPaymentSchema";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import {StyledForm, StyledTextArea} from "../../../../Component/Kit/Form";
import {StyledButton} from "../../../../Component/Kit/Button";
import Modal from "../../../../Component/Kit/Modal";

export const AcceptRequestTower = (props) => {
    const {id, visible, setVisible, onCancel, onClose} = props;
    const history = useHistory();

    const acceptHandler = () => {
        let dataBody = {
            "isAccepted": true,
            "rejectDescription": null
        };
        ApiClient.Patch(Urls.sendTowerChangeStatus(id), dataBody)
            .then((res) => {
                setVisible(false);
                toast.success("فرآیند با موفقیت انجام گرفت!");
                history.push('/send-tower')
            })
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            headerTitle=" تایید درخواست "
        >
            <div className="col-12 p-2">
                <h6>آیا از تایید این درخواست مطمئن هستید؟ </h6>
                <div className="row">

                    <div className="d-flex justify-content-between m-3 w-100">
                        <StyledButton
                            width="52%"
                            btnStyle="greenBtn ml-3"
                            onClick={acceptHandler}
                        >
                            تایید
                        </StyledButton>

                        <StyledButton
                            width="48%"
                            btnStyle="grayBtn"
                            type="reset"
                            onClick={() => {
                                setVisible(false)
                            }}
                        >
                            بستن
                        </StyledButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
