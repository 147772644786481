import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
import { Button } from "../../../../Component/Kit/Button";
import { AutocompleteList } from "../../../../Component/Kit/Form";
import { PageTitleLayout } from "../../../../Component/Kit/Layout";
import Table from "../../../../Component/Shared/Table/Table";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { ConvertQueryString } from "../../../../Utility/Services/convert-query-string";
import { toast } from "react-toastify";
import { useDebounce } from "../../../../Utility/method/UseDebounce";

function WhiteListOfForbiddenWords() {
    const { search } = useLocation();
    const match = useRouteMatch();
    const [forbiddenWordsFoundList, setForbiddenWordsFoundList] = useState([]);
    const [selectedForbiddenWord, setSelectedForbiddenWord] = useState({});
    const [forbiddenWordsList, setForbiddenWordsList] = useState({});

    const tableConfig = {
        header: {
            word: "کلمه ممنوعه",
        },
        actions: {
            delete: {
                icon: "fa fa-trash",
            },
        }
    };

    const [searchQuery, setSearchQuery] = useState('');
    const debouncedValue = useDebounce(searchQuery, 500);

    const changeInput = (e) => {
        setSearchQuery(e)
    };
    const changeSelect = (e) => {
        setSelectedForbiddenWord(e)
    };

    const submitSelectedForbidderWord = () => {
        ApiClient.Post(Urls.userForbiddenWords(match.params.id), Number(selectedForbiddenWord.id))
            .then(res => {
                if (res && res.result) {
                    getData();
                    setSelectedForbiddenWord('');
                    setForbiddenWordsFoundList([]);
                    setSearchQuery('');
                }
            })
    };

    const deleteForbiddenWord = (id) => {
        Swal.fire({
            title: "",
            text: `آیا از حذف این کلمه ممنوعه مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.removeUserForbiddenWords(id, match.params.id))
                    .then(res => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        getData()
                    })
            }
        });
    };

    const tableActions = (item) => {
        switch (item.actionName) {
            case "delete":
                deleteForbiddenWord(item.data.id);
                break;
            default:
                break;
        }
    };

    const getData = useCallback(() => {
        ApiClient.Get(Urls.userForbiddenWords(match.params.id), ConvertQueryString(search))
            .then(res => {
                if (res && res.result) {
                    setForbiddenWordsList(res.result)
                }
            })
    }, [match.params.id, search]);

    const getForbiddenWordList = () => {
        const params = {
            phrase: searchQuery,
            pageSize: 50,
            pageNumber: 1
        }
        ApiClient.Get(Urls.forbiddenWords(), params)
            .then(res => {
                setForbiddenWordsFoundList(res.result.items);
            })
    }

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        if (searchQuery.length > 0) {
            getForbiddenWordList();
        }
        else {
            setSelectedForbiddenWord('')
            setForbiddenWordsFoundList([])
        }
    }, [debouncedValue]);

    return (
        <div className="row my-3">
            <div className="col-12 wrapper-box">
                <PageTitleLayout>
                    <h5>لیست سفید کلمات ممنوعه</h5>
                    <span>
                        شما می توانید لیستی از کلمات ممنوعه که این کاربر مجاز به ارسال آن ها
                        هست را وارد کنید.
                    </span>
                </PageTitleLayout>
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <div className="d-flex align-items-cetner">
                            <div className="form-group w-100">
                                <AutocompleteList
                                    options={forbiddenWordsFoundList}
                                    placeholder="کلمه ممنوعه مورد نظر را تایپ کنید..."
                                    keyName="id"
                                    valueName="word"
                                    value={searchQuery}
                                    inputHandler={(e) => changeInput(e)}
                                    selectItem={(e) => changeSelect(e)}
                                ></AutocompleteList>
                            </div>
                            <Button
                                type={"button"}
                                onClick={() => submitSelectedForbidderWord()}
                                disabled={Object.keys(selectedForbiddenWord).length === 0}
                                className="greenBtn mr-3"
                            >
                                افزودن
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Table
                            data={forbiddenWordsList.items}
                            config={tableConfig}
                            pageConfig={{
                                pageNumber: forbiddenWordsList.pageNumber,
                                pageSize: forbiddenWordsList.pageSize,
                                totalItemsCount: forbiddenWordsList.totalItemsCount,
                                totalPagesCount: forbiddenWordsList.totalPagesCount,
                            }}
                            actionDispatched={tableActions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhiteListOfForbiddenWords;
