import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';

import BackToPage from '../../../Component/Kit/back';
import { StyledButton } from '../../../Component/Kit/Button';
import { StyledCheckBox, StyledForm, StyledSelect, FormLable } from '../../../Component/Kit/Form';
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';

import IdentityRoleList from '../../../Utility/Model/IdentityRoleModel';
import TicketDepartmentList from '../../../Utility/Model/TicketDepartmentModel';
import { ModifyAdminRoleSchema } from '../../../Utility/Validation/Schema/ModifyAdminRoleSchema';
import { IdentityRoleItem } from '../../../Utility/Services/IdentityRoleService';

export const AdminItemDetails = () => {
    const [selectedOption, setSelectedOption] = useState([]);
    const [adminProperties, setAdminProperties] = useState({})
    const [btnLoading, setBtnLoading] = useState(false);
    let adminDetails = useSelector(state => state.AdminDetailReducer);
    const history = useHistory();

    useEffect(() => {
        if (Object.entries(adminDetails).length) {
            setAdminProperties(adminDetails);
        } else {
            history.push("/admins");
        }

        if (Object.entries(adminProperties).length && adminProperties.roleIds !== undefined) {
            let temp = [];
            adminProperties.roleIds.map((item) => {
                temp = [...temp, IdentityRoleItem(Number(item))]
                return item;
            })
            setSelectedOption(temp)
        }

    }, [adminDetails, adminProperties, history]);

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <span className="fakeContainer"></span>
                    <BackToPage />
                </HeaderContent>
                {
                    adminProperties && (
                        <MainContent>
                            <div className="col-12 col-lg-12">
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <PageTitleLayout>
                                            <h5>ویرایش ادمین </h5>
                                            <span>می‌توانید اطلاعات {adminProperties.fullName} را تغییر دهید.</span>
                                        </PageTitleLayout>
                                        <hr className="mt-4 mx-2" />
                                    </div>

                                    <Formik
                                        initialValues={{
                                            suspended: adminDetails.isSuspended,
                                            roleItems: selectedOption,
                                            department: adminProperties.department,
                                        }}
                                        validationSchema={ModifyAdminRoleSchema}
                                        enableReinitialize={true}

                                        onSubmit={(values, actions) => {
                                            let roleItems = []
                                            roleItems = values.roleItems
                                                .slice
                                                .call(values.roleItems)
                                                .map(option => String(option.value))


                                            let body = {
                                                "isSuspended": values.suspended,
                                                "roleIds": roleItems,
                                                "department": parseInt(values.department),
                                            };
                                            setBtnLoading(true);
                                            ApiClient.Put(Urls.AdminsManagment(adminProperties.adminId), body)
                                                .then(() => {
                                                    toast.success('تنظیمات با موفقیت انجام شد.')
                                                    history.goBack();
                                                    setBtnLoading(false);
                                                })
                                        }}
                                    >
                                        {(formik) => (
                                            <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                                                <div className="row">

                                                    <div className="col-md-4 pb-5">
                                                        <FormLable show={true}> مربوط به بخش </FormLable>
                                                        <Select
                                                            name="roleItems"
                                                            value={formik.values.roleItems !== null
                                                                ? formik.values.roleItems
                                                                : formik.initialValues.roleItems}
                                                            error={(formik.errors.roleItems && formik.touched.roleItems)
                                                                ? formik.errors.roleItems
                                                                : 'false'
                                                            }
                                                            getOptionLabel={option => option['name']}
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            options={IdentityRoleList}
                                                            onChange={(e) => {
                                                                formik.setFieldValue("roleItems", e)
                                                            }}
                                                        />
                                                        {
                                                            <div
                                                                className="jseBfC d-block">{formik.errors.roleItems}</div>
                                                        }
                                                    </div>

                                                    <StyledSelect
                                                        formGroupClass="col-md-4 pb-5"
                                                        lable="مربوط به بخش"
                                                        focus={true}
                                                        name="department"
                                                        value={formik.values.department !== undefined
                                                            ? formik.values.department
                                                            : formik.initialValues.department}
                                                        optionItems={TicketDepartmentList}
                                                        error={(formik.errors.department && formik.touched.department)
                                                            ? formik.errors.department
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("department", e.target.value)}
                                                    />

                                                    <StyledCheckBox
                                                        formGroupClass="col-md-4 mt-4"
                                                        lable="بلاک شده"
                                                        focus={true}
                                                        name="suspended"
                                                        id="suspended"
                                                        checked={formik.values.suspended !== undefined
                                                            ? formik.values.suspended
                                                            : formik.initialValues.suspended}
                                                        error="false"
                                                        onChange={(e) => formik.setFieldValue("suspended", e.target.checked)}
                                                    />

                                                </div>

                                                <StyledButton
                                                    width="100%"
                                                    btnStyle="darkGreenBtn"
                                                    disabled={!formik.isValid || !formik.dirty}
                                                    loading={btnLoading ? 1 : 0}
                                                    type="submit"
                                                >
                                                    ویرایش اطلاعات
                                                </StyledButton>
                                            </StyledForm>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </MainContent>
                    )
                }
            </div>
        </WrapperLayout>
    )
}
export default AdminItemDetails

// const mapStateToProps = (state) => ({
//     adminDetails: state.AdminsList
// })
// export default connect(mapStateToProps)(AdminItemDetails)

// const mapDispatchToProps = {

// }
