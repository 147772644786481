import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { StyledButton } from '../../../Component/Kit/Button';
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import { Loader } from '../../../Component/Shared/Loader';
import Table from '../../../Component/Shared/Table/Table';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { ConvertQueryString } from '../../../Utility/Services/convert-query-string';
import DateConvert from '../../../Utility/Services/date-convert';
import {toast} from "react-toastify";

export const CampaignList = () => {
    const location = useLocation();
    const history = useHistory();
    const [CampaignList, setCampaignList] = useState({});
    const [loading, setLoading] = useState(true);

    const tableConfig = {
        header: {
            name: "نام کمپین",
            code: "کد",
            startAt: "تاریخ شروع",
            endAt: "تاریخ پایان",
            isActive: 'وضعیت',
            createdAt: "تاریخ ایجاد کمپین",
            modifiedAt: 'تاریخ ویرایش',
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
            edit: {
                icon: "fa fa-edit",
            },
            copy: {
                icon: "far fa-copy",
            },
        },
        mode: ['filter', 'search'],
        filters: ['datePickerFilter', 'isActiveCampaign'],
    };

    function tableActions(item) {
        switch (item.actionName) {
            case 'edit':
                history.push(`campaigns/${item.data.id}/edit`);
                break;
            case 'view':
                history.push(`campaigns/${item.data.id}/members`);
                break;
            case 'copy':
                toast.info(`${item.data.code}  کپی شد.`);
                navigator.clipboard.writeText(item.data.code)
                break;
            default:
                break;
        }
    }

    const getCampaignList = useCallback(() => {
        ApiClient.Get(Urls.advertisingCampaign(), ConvertQueryString(location.search))
            .then((res) => {
                let dataItem = res.result;
                dataItem.items.map((m, i) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.endAt = DateConvert(m.endAt);
                    m.startAt = DateConvert(m.startAt);
                    m.modifiedAt = DateConvert(m.modifiedAt);
                    m.isActive = m.isActive
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    return m;
                });
                setCampaignList(dataItem);
                setLoading(false);

            })
    }, [location.search])

    useEffect(() => {
        getCampaignList();
    }, [getCampaignList]);

    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-end">
                <div className="d-flex align-items-center justify-content-start w-100">

                    <StyledButton
                        btnStyle="greenBtn mx-1"
                        onClick={() => history.push('/campaigns/add')}
                    >
                        ایجاد کمپین
                    </StyledButton>

                </div>
            </HeaderContent>
            <MainContent>
                <div className="col-12 col-lg-12">
                    <PageTitleLayout>
                        <h5>لیست کمپین ها </h5>
                        <span>در این بخش لیست همه کمپین ها قابل مشاهده است.</span>
                    </PageTitleLayout>
                    <div>
                        {loading === true
                            ?
                            <Loader />
                            :
                            <Table
                                data={CampaignList.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: CampaignList.pageNumber,
                                    pageSize: CampaignList.pageSize,
                                    totalItemsCount: CampaignList.totalItemsCount,
                                    totalPagesCount: CampaignList.totalPagesCount,
                                }}
                            />
                        }
                    </div>
                </div>
            </MainContent>
        </WrapperLayout >
    );
};

export default CampaignList;