import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import BackToPage from '../../../../Component/Kit/back';
import {StyledButton} from '../../../../Component/Kit/Button';
import {
    FooterContent,
    HeaderContent,
    MainContent,
    PageTitleLayout,
    WrapperLayout
} from '../../../../Component/Kit/Layout';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import DateConvert from '../../../../Utility/Services/date-convert';
import {PaymentStatusService} from '../../../../Utility/Services/PaymentStatusService';
import {SeprateNumberWithComma} from '../../../../Utility/Services/SeprateNumberWithComma';
import {AcceptedPaymentModal} from './AcceptedPaymentModal';
import {RejectedPaymentModal} from './RejectedPaymentModal';
import Modal from "../../../../Component/Kit/Modal";
import {Formik} from "formik";
import {toast} from "react-toastify";
import {FormLable, StyledForm, StyledInput, StyledSelect} from "../../../../Component/Kit/Form";
import {MyDatePicker} from "../../../../Component/Shared/DatePicker";
import moment from "moment-jalaali";

const ManualPaymentItem = (props) => {
    const paymentId = props.match.params.id;
    let BanksProvider = props.BanksProvider;
    const [singleManualPayment, setSingleManualPayment] = useState({});
    const [acceptedPaymentModalVisible, setAcceptedPaymentModalVisible] = useState(false);
    const [rejectedPaymentModalVisible, setRejectedPaymentModalVisible] = useState(false);
    const [manualPaymentTitles, setManualPaymentTitles] = useState({
        payerFullName: "نام و نام‌خانوادگی",
        description: "عنوان محصول",
        bankIdTitle: "عنوان بانک",
        netPrice: "قیمت خام",
        discountPrice: "تخفیف",
        fifteenRemarkPrice: "لایحه 15",
        vatPrice: "مالیات بر ارزش افزوده (9 درصد)",
        finalPrice: "مبلغ کل",
        status: "وضعیت پرداختی",
        trackingNumber: "شماره پیگیری ",
        paidAtConvert: "تاریخ پرداخت",
        userDescription: "توضیحات",
    });
    const [showEditModal, setEditModal] = useState(false);


    const getData = useCallback(() => {
        ApiClient.Get(Urls.GetPendingManualPayments(paymentId))
            .then(res => {
                let responseData = res.result;
                Object.entries(responseData).map(([item, value]) => {
                    switch (item) {
                        case "bankId":
                            return responseData.bankIdTitle = BanksProvider.find(b => b.id === responseData.bankId)?.title || '-';
                        case "netPrice":
                            responseData.netPriceValue = responseData.netPrice;
                            return responseData.netPrice = `${SeprateNumberWithComma(value)} ریال`;
                        case "discountPrice":
                            return responseData.discountPrice = `${SeprateNumberWithComma(value)} ریال`;
                        case "fifteenRemarkPrice":
                            return responseData.fifteenRemarkPrice = `${SeprateNumberWithComma(value)} ریال`;
                        case "vatPrice":
                            return responseData.vatPrice = `${SeprateNumberWithComma(responseData.vatPrice)} ریال`;
                        case "vatPercent":
                            setManualPaymentTitles({
                                ...manualPaymentTitles,
                                vatPrice: `مالیات بر ارزش افزوده (${value} درصد)`
                            })
                            break;
                        case "finalPrice":
                            return responseData.finalPrice = `${SeprateNumberWithComma(value)} ریال`;
                        case "status":
                            return responseData.status = PaymentStatusService(value);
                        case "paidAt":
                            return responseData.paidAtConvert = DateConvert(value);
                        default:
                            return responseData[item] = value;
                    }
                })
                setSingleManualPayment(responseData);
            })
    }, [paymentId, BanksProvider])


    useEffect(() => {
        getData()
    }, [getData]);


    const rejectManualPayment = () => {
        setRejectedPaymentModalVisible(true)
    }

    const acceptManualPayment = () => {
        setAcceptedPaymentModalVisible(true)
    }

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <span className="fakeContainer"></span>
                    <BackToPage toPage="/financial/manual-payment"/>
                </HeaderContent>

                <MainContent>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <PageTitleLayout className='pt-2'>
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <h5> جزئیات پرداخت‌های دستی</h5>
                                    <StyledButton
                                        btnStyle='greenBtn'
                                        onClick={() => {
                                            setEditModal(true)
                                        }}
                                    >
                                        ویرایش
                                    </StyledButton>
                                </div>
                            </PageTitleLayout>

                            <hr className="mt-4 mx-2"/>
                        </div>

                        <div className="col-12 col-lg-12">
                            <ul className="params">
                                {
                                    manualPaymentTitles &&
                                    Object.entries(manualPaymentTitles).map(([item, value]) => (
                                        <li className="params__list" key={value}>
                                            <div className="params__list__label">
                                                <span>{value}</span>
                                            </div>
                                            <div className="params__list__value">
                                                <span className="params__list__label--white">
                                                    {singleManualPayment[item]}
                                                </span>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>

                    <FooterContent>
                        <StyledButton
                            btnStyle="redBtn"
                            onClick={() => rejectManualPayment()}
                        >
                            عدم تایید
                        </StyledButton>

                        <StyledButton
                            btnStyle="greenBtn ml-3"
                            onClick={() => acceptManualPayment()}
                        >
                            تایید
                        </StyledButton>

                        {
                            <RejectedPaymentModal
                                paymentId={paymentId}
                                setVisible={setRejectedPaymentModalVisible}
                                visible={rejectedPaymentModalVisible}
                                // onClose={false}
                                onClose={() => false}
                                onCancel={() => setRejectedPaymentModalVisible(false)}
                                // onClose={() => setRejectedPaymentModalVisible(false)}
                            />
                        }
                        {
                            <AcceptedPaymentModal
                                paymentId={paymentId}
                                setVisible={setAcceptedPaymentModalVisible}
                                visible={acceptedPaymentModalVisible}
                                onClose={() => false}
                                onCancel={() => setAcceptedPaymentModalVisible(false)}
                                // onClose={() => setAcceptedPaymentModalVisible(false)}
                            />
                        }

                    </FooterContent>

                </MainContent>

            </div>
            {singleManualPayment &&

                <Modal
                    onClose={() => setEditModal(false)}
                    onCancel={() => setEditModal(false)}
                    visible={showEditModal}
                    headerTitle="ویرایش پرداخت‌ دستی"
                >

                    <Formik
                        initialValues={{
                            bankId: singleManualPayment.bankId,
                            paidAt: singleManualPayment.paidAt,
                            trackingNumber: singleManualPayment.trackingNumber
                        }}
                        isInitialValid={false}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            ApiClient.Patch(Urls.EditPaymentManual(paymentId), values)
                                .then(res => {
                                    if (res.result) {
                                        getData()
                                        toast.success('ویرایش با موفقیت انجام شد .');
                                        actions.resetForm();
                                        setEditModal(false);
                                    }
                                })
                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onBlur={formik.handleBlur}
                                onReset={formik.handleReset}
                            >
                                <StyledSelect
                                    lable="بانک"
                                    name="bankId"
                                    value={
                                        formik.values.bankId !== undefined
                                            ? formik.values.bankId
                                            : formik.initialValues.bankId
                                    }
                                    optionItems={BanksProvider.map((a) => {
                                        return {
                                            value: a.id,
                                            name: a.title
                                        }
                                    })}
                                    error={(formik.errors.bankId && formik.touched.bankId)
                                        ? formik.errors.bankId
                                        : 'false'
                                    }
                                    onChange={formik.handleChange}
                                />

                                <StyledInput
                                    lable="شماره پیگیری"
                                    focus={true}
                                    type="text"
                                    name="trackingNumber"
                                    id="trackingNumber"
                                    value={formik.values.trackingNumber}
                                    error={(formik.errors.trackingNumber && formik.touched.trackingNumber)
                                        ? formik.errors.trackingNumber
                                        : 'false'
                                    }
                                    onChange={(e) => formik.setFieldValue("trackingNumber", e.target.value)}
                                />
                                <FormLable show={true}>
                                    تاریخ
                                </FormLable>
                                <MyDatePicker
                                    defaultValue={formik.values.paidAt}
                                    value={(event) => {
                                        formik.setFieldValue("paidAt", event.baseFormat);
                                    }}
                                    max={moment(formik.values.paidAt, 'jYYYYjMMjDDHHmmss').format()}
                                    haveTime
                                    name="paidAt"
                                    id="paidAt"
                                    error={
                                        formik.errors.paidAt !== undefined
                                            ? formik.errors.paidAt
                                            : false
                                    }
                                />


                                <div className="d-flex justify-content-between mt-3">
                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => setEditModal(false)}
                                    >
                                        بستن
                                    </StyledButton>
                                    <StyledButton
                                        width="35%"
                                        disabled={!formik.isValid}
                                        btnStyle="greenBtn"
                                        type="submit"
                                    >
                                        ویرایش
                                    </StyledButton>
                                </div>
                            </StyledForm>
                        )}

                    </Formik>


                </Modal>

            }
        </WrapperLayout>
    )
}

const mapStateToProps = (state) => ({
    BanksProvider: state.bootstrapper.data.banks
})

export default connect(mapStateToProps)(ManualPaymentItem)
