const LineEarnEnum = {
    Gift: 1,
    Buy: 2,
    Transfer: 3,
    Plan: 4
}

const lineTypesEnum = {
    Unknown: 0,
    Advertising: 1,
    Service: 2
}

const UserLinePrebuyStatusEnum = {
    Rejected: 1,
    Expired: 2,
    Accepted: 3,
    Paid: 4,
    ReservedByOthers: 5
}

const lineTypes = [
    {value: lineTypesEnum.Unknown, name: "نامشخص"},
    {value: lineTypesEnum.Advertising, name: "تبلیغاتی"},
    {value: lineTypesEnum.Service, name: "خدماتی"},
];
const UserLineEarnMethod = [
    {value: LineEarnEnum.Gift, name: "هدیه"},
    {value: LineEarnEnum.Buy, name: "خرید"},
    {value: LineEarnEnum.Transfer, name: "انتقالی"},
    {value: LineEarnEnum.Plan, name: "پلن"},
];
const UserLineStatus = [
    {name: "انتظار", value: 1},
    {name: "غیرفعال", value: 2},
    {name: "فعال", value: 3},
];


const UserLineStatusNotPending = [
    {name: "غیرفعال", value: 2},
    {name: "فعال", value: 3},
];

const UserLinePrebuyStatus = [
    {value: UserLinePrebuyStatusEnum.Rejected, name: "رد شده"},
    {value: UserLinePrebuyStatusEnum.Expired, name: "منقضی شده"},
    {value: UserLinePrebuyStatusEnum.Accepted, name: "تایید شده"},
    {value: UserLinePrebuyStatusEnum.Paid, name: "پرداخت شده"},
    {value: UserLinePrebuyStatusEnum.ReservedByOthers, name: "رزرو شده"},
];

const WhoisLineOperationEnum = {
    MagfaFirstStep: 1,
    MagfaSecondStep: 2,
    Rahyab: 3,
    MagfaDeleteLineInfo: 4
}

const WhoisLineOperationStatus = [
    {value: WhoisLineOperationEnum.MagfaFirstStep, name: 'ارسال کد تایید مگفا'},
    {value: WhoisLineOperationEnum.MagfaSecondStep, name: 'اعتبارسنجی کد تایید مگفا'},
    {value: WhoisLineOperationEnum.Rahyab, name: 'whois رهیاب'},
    {value: WhoisLineOperationEnum.MagfaDeleteLineInfo, name: 'حذف اطلاعات خط مگفا'},
]

export {
    lineTypesEnum,
    LineEarnEnum,
    UserLineEarnMethod,
    UserLineStatus,
    UserLineStatusNotPending,
    WhoisLineOperationStatus,
    lineTypes,
    UserLinePrebuyStatusEnum,
    UserLinePrebuyStatus,
};
