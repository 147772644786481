import { Formik } from "formik";
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import {StyledForm, StyledInput, StyledInputUpload, StyledSelect} from "../../../Component/Kit/Form";
import {FileNamePreview, ImageBox, UploadConatiner, UploadTitle} from "../../../Component/Kit/UploadZone";
import UploadIcon from "../../../Component/Icon/UploadIcon";
import {ConvertUserTypeSchema} from "../../../Utility/Validation/Schema/ConvertUserTypeSchema";
import Modal from "../../../Component/Kit/Modal";
import ApiClient from "../../../Requester/ApiClient";
import { theme } from '../../../Utility/Theme';
import Urls from "../../../Requester/Urls";
import {CompanyTypeList} from "../../../Utility/Model/company.model";

import {StyledButton} from "../../../Component/Kit/Button";

export const ConvertGuestType = (props) => {
    const { data, userId, visible, onClose, onCancel, setVisible, onSubmit } = props;
    const [uploadPreview, setUploadPreview] = useState(null);
    const [legal, setLegal] = useState(null);
    const [uploadFile, setUploadFile] = useState(null);

    useEffect(() => {
        setLegal(data.isLegal);
    }, [data])

    const handleOnBlur = (e) => {
        if (e.target.files[0] && e.target.length !== 0) {
            const file = e.target.files[0];
            setUploadPreview(URL.createObjectURL(file));
            setUploadFile(file);
        }
    };

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            onCancel={onCancel}
            onClose={onClose}
            headerTitle="تغییر نوع حساب کاربری"
        >
            <div className="col-12 py-3 text-center">
                <Formik
                    initialValues={{
                        isLegal: data?.isLegal ? data.isLegal : '',
                        companyName: data?.legalData?.companyName ? data?.legalData?.companyName : '',
                        registrationNumber: data?.legalData?.registrationNumber ? data?.legalData?.registrationNumber : '',
                        nationalIdCompany: data?.legalData?.nationalId ? data?.legalData?.nationalId : '',
                        companyType: data?.legalData?.companyType ? data?.legalData?.companyType : '',
                        gazetteUploadedFile: data?.gazetteUploadedURL ? data?.gazetteUploadedURL : '',
                    }}
                    validationSchema={!data.isLegal ? ConvertUserTypeSchema : null}
                    enableReinitialize={true}
                    onReset={(values, formikBag) => {
                        setVisible(false);
                    }}

                    onSubmit={(values, actions) => {
                        const formData = new FormData()
                        formData.append('IsLegal', !data.isLegal)
                        if (!data.isLegal) {
                            formData.append('CompanyName', values.companyName)
                            formData.append('RegistrationNumber', values.registrationNumber)
                            formData.append('NationalId', values.nationalIdCompany)
                            formData.append('CompanyType', values.companyType)
                            formData.append('GazetteUploadedFile', values.gazetteUploadedFile)
                        }

                        ApiClient.Put(Urls.transformGuest(userId), formData)
                            .then(saveRes => {
                                toast.success('تغییرات  با موفقیت ثبت شد.');
                                onSubmit()
                                actions.setSubmitting(false);
                                actions.resetForm();
                            })
                            .catch(err => {
                                actions.setSubmitting(false);
                                actions.resetForm();
                            })

                    }}
                >
                   
                    {(formik) => (
                        <StyledForm className="p-0"
                            onSubmit={formik.handleSubmit}
                            onBlur={formik.handleBlur}
                            onReset={formik.handleReset}>
                            <div className="mb-2">
                                {/* <span className="mb-3">نوع حساب کاربر ({legal ? "حقوقی" : "حقیقی"})</span> */}
                                {legal &&
                                    <p className="text-right"> نوع حساب کاربر حقوقی می‌باشد و برای تبدیل آن به حقیقی نیاز به وارد کردن اطلاعات جدیدی نیست </p>
                                }
                                {!legal &&
                                    <p className="text-right"> نوع حساب کاربر حقیقی می‌باشد و برای تبدیل آن به حقوقی نیاز به وارد کردن اطلاعات شرکت است </p>
                                }
                                <hr className="w-100" />
                            </div>
                            {!legal &&
                                <div>
                                    <div className="row text-right">
                                        <StyledInput
                                            formGroupClass="col-lg-6 col-md-6 col-12"
                                            lable="نام شرکت"
                                            focus={true}
                                            placeholder="لطفا نام شرکت خود را وارد کنید:"
                                            name="companyName"
                                            value={formik.values.companyName}
                                            error={(formik.errors.companyName && formik.touched.companyName)
                                                ? formik.errors.companyName
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("companyName", e.target.value)}
                                        />

                                        <StyledInput
                                            formGroupClass="col-lg-6 col-md-6 col-12"
                                            lable="شماره ثبت"
                                            focus={true}
                                            placeholder="لطفا شماره ثبت خود را وارد کنید:"
                                            name="registrationNumber"
                                            value={formik.values.registrationNumber}
                                            error={(formik.errors.registrationNumber && formik.touched.registrationNumber)
                                                ? formik.errors.registrationNumber
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("registrationNumber", e.target.value)}
                                        />
                                    </div>

                                    <div className="row text-right">
                                        <StyledInput
                                            formGroupClass="col-lg-6 col-md-6 col-12"
                                            lable="شناسه ملی"
                                            focus={true}
                                            placeholder="لطفا شناسه ملی خود را وارد کنید:"
                                            name="nationalIdCompany"
                                            value={formik.values.nationalIdCompany}
                                            error={(formik.errors.nationalIdCompany && formik.touched.nationalIdCompany)
                                                ? formik.errors.nationalIdCompany
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("nationalIdCompany", e.target.value)}
                                        />

                                        <StyledSelect
                                            formGroupClass="col-lg-6 col-md-6 col-12"
                                            optionItems={[{ value: null, name: 'انتخاب کنید' }, ...CompanyTypeList]}
                                            lable="نوع شرکت"
                                            focus={true}
                                            name="companyType"
                                            value={formik.values.companyType}
                                            error={(formik.errors.companyType && formik.touched.companyType)
                                                ? formik.errors.companyType
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("companyType", e.target.value)}
                                        />

                                    </div>
                                    <div className="row text-right">
                                        <div className="col-12 text-right">
                                            <label>آپلود روزنامه رسمی</label>
                                            <UploadConatiner>
                                                <ImageBox>
                                                    {
                                                        (uploadPreview === null)
                                                            ?
                                                            <>
                                                                <UploadIcon fill={theme.paleGrey} />
                                                                <UploadTitle>
                                                                    فایل های پشتیبانی :  jpeg, jpg, JPG, png
                                                                </UploadTitle>
                                                            </>
                                                            :
                                                            <FileNamePreview>
                                                                {
                                                                    uploadFile.name &&
                                                                    uploadFile.name
                                                                }
                                                            </FileNamePreview>
                                                        // <ImagePreview src={uploadPreview} className="ImagePreview" />
                                                    }
                                                </ImageBox>

                                                <StyledInputUpload
                                                    hasurl={uploadPreview}
                                                    placeholder="آپلود فایل"
                                                    type="file"
                                                    name="gazetteUploadedFile"
                                                    accept="image/*"
                                                    error={(formik.errors.gazetteUploadedFile && formik.values.gazetteUploadedFile)
                                                        ? formik.errors.gazetteUploadedFile
                                                        : 'false'
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("gazetteUploadedFile", e.target.files[0]);
                                                        handleOnBlur(e);
                                                    }}
                                                />
                                            </UploadConatiner>
                                        </div>
                                    </div>
                                </div>
                                
                            }
                            <div className="d-flex justify-content-between mt-5">
                                <StyledButton
                                    btnStyle="greenBtn ml-3"
                                    disabled={!formik.isValid}
                                    width="38%"
                                    type="submit"
                                >
                                    ویرایش
                                </StyledButton>

                                <StyledButton
                                    width="35%"
                                    btnStyle="grayBtn"
                                    type="reset"
                                >
                                    بستن
                                </StyledButton>
                            </div>
                        </StyledForm>
                    )}
                </Formik>

            </div>
        </Modal>
    )
}
