import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import ApiClient from "../../../../Requester/ApiClient";
import {ConvertQueryString} from "../../../../Utility/Services/convert-query-string";
import GetProvider from "../../../../Utility/Services/provider";
import Urls from "../../../../Requester/Urls";
import {UserServiceLineStatusEnum, UserServiceLineStatusModel} from "../../../../Utility/Model/user-service-line.model";
import DateConvert from "../../../../Utility/Services/date-convert";
import {PageTitleLayout} from "../../../../Component/Kit/Layout";
import Table from "../../../../Component/Shared/Table/Table";


function SharedServiceLineTab(props) {
    const {status} = props;
    const [lineData, setlineData] = useState([]);
    const location = useLocation();
    const history = useHistory();

    const tableConfig = {
        header: {
            number: "شماره خط",
            username: "کاربر",
            statusFa: "وضعیت درخواست",
            providerId: "ارائه دهنده",
            createdAt: "تاریخ ایجاد",
            modifiedAt: "تاریخ ویرایش",
        },

        actions: {
            view: {
                icon: "fa fa-eye",
            },
        },
        mode: ["search"],
    };

    const getServiceLine = useCallback(() => {
        const params ={
            ...ConvertQueryString(location.search),
            status,
        }
        ApiClient.Get(Urls.getAllUserServiceLine(), params).then((res) => {
            let data = res.result;
            data.items.map((m) => {
                m.providerId = GetProvider(m.providerId);
                m.statusFa = UserServiceLineStatusModel.find(u => u.value === m.status).name ?? m.status
                m.createdAt = DateConvert(m.createdAt) || '- - -';
                m.modifiedAt = DateConvert(m.modifiedAt) || '- - -';
                return m
            });
            setlineData(data);
        });
    }, [location.search, status]);


    useEffect(() => {
        getServiceLine()
    }, [getServiceLine]);

    const tableActions = (item) => {
        switch (item.actionName) {
            case "view":
                history.push(`/line/user-service-line-detail/${item.data.id}`);
                break;

            default:
                break;
        }
    };

    return (
        <>
            <PageTitleLayout>
                <h5>خطوط خدماتی</h5>
                <span>
                        برای تغییر اطلاعات لطفا با واحد فنی در ارتباط باشید.
                    </span>
            </PageTitleLayout>
            <Table data={lineData.items} config={tableConfig} pageConfig={{
                pageNumber: lineData.pageNumber,
                pageSize: lineData.pageSize,
                totalItemsCount: lineData.totalItemsCount,
                totalPagesCount: lineData.totalPagesCount,
            }} actionDispatched={tableActions}></Table>
        </>
    );
}

export default SharedServiceLineTab;
