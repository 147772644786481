let CreditTransactionModel  = [
    { name: "خرید اعتبار", value: 1 },
    { name: "ارسال پیامک", value: 2 },
    { name: "بازگشت اعتبار", value: 3 },
    { name: "انتقال از / به سامانه قدیم", value: 4 },
    { name: "اصلاح تعرفه", value: 5 },
    { name: "اصلاح اعتبار", value: 6 },
  ];
  
  export default CreditTransactionModel ;
