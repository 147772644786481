import {NavLink, Route, Switch, useHistory, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import {MainContent} from "../../../Component/Kit/Layout";
import SharedServiceLineTab from "./SharedServiceLineTab";

const UserServiceLine = ({ match }) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === `${match.url}`) {
            history.push(`${match.url}/pending`.replace(/\/+/g, '/'))
        }
    }, [history, location.pathname, match.url])
    return (
        <>
            <MainContent>
                <ul className="user-tab-menu">
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/pending`} >
                            درخواست های جدید
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/not-accepted`}>
                            رد شده ها
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/wait-by-provider`}>
                            در انتظار اپراتور
                        </NavLink>
                    </li>

                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/wait-for-document`}>
                            در انتظار مدارک اصلی
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/done`}>
                            خدماتی شده ها
                        </NavLink>
                    </li>
                </ul>
                <div className="p-3">
                    <Switch>
                        <Route path={`${match.path}/pending`}>
                            <SharedServiceLineTab status={1}/>
                        </Route>
                        <Route path={`${match.path}/not-accepted`} >
                            <SharedServiceLineTab status={2}/>
                        </Route>
                        <Route path={`${match.path}/wait-by-provider`} >
                            <SharedServiceLineTab status={3}/>
                        </Route>
                        <Route path={`${match.path}/wait-for-document`} >
                            <SharedServiceLineTab status={4}/>
                        </Route>
                        <Route path={`${match.path}/done`} >
                            <SharedServiceLineTab status={5}/>
                        </Route>
                    </Switch>
                </div>
            </MainContent>
        </>
    )
}

export default UserServiceLine;