import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import BackToPage from '../../../Component/Kit/back';
import { StyledButton } from '../../../Component/Kit/Button';
import { StyledForm, StyledInput, StyledSelect } from '../../../Component/Kit/Form';
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { NumericalValidationMultilang } from '../../../Utility/method/NumericalValidationMultilang';
import IdentityRoleList from '../../../Utility/Model/IdentityRoleModel';
import TicketDepartmentList from '../../../Utility/Model/TicketDepartmentModel';
import { AddUserAdminSchema } from '../../../Utility/Validation/Schema/AddUserAdminSchema';

export const AddUserAdmin = ({ history }) => {

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <span className="fakeContainer"></span>
                    <BackToPage />
                </HeaderContent>

                <MainContent>
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout>
                            <h5>افزودن کاربر ادمین</h5>
                            <span>در این بخش می‌توانید کاربر با دسترسی ادمین ایجاد نمایید.</span>
                        </PageTitleLayout>
                    </div>
                    <hr className="mt-4 mx-3" />

                    <Formik
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            username: "",
                            setPassword: "",
                            department: "",
                            roleId: "",
                            email: "",
                            mobile: "",
                            fatherName: "",
                            nationalId: "",
                        }}

                        validationSchema={AddUserAdminSchema}
                        enableReinitialize={false}

                        onSubmit={(values, actions) => {
                            let body = {
                                "firstName": values.firstName,
                                "lastName": values.lastName,
                                "userName": values.username,
                                "password": values.setPassword,
                                "department": parseInt(values.department),
                                "roleId": values.roleId,
                                "email": values.email,
                                "mobile": parseInt(values.mobile),
                                "fatherName": values.fatherName,
                                "nationalId": values.nationalId,
                            };
                            ApiClient.Post(Urls.AdminsManagment(), body)
                                .then(() => {
                                    toast.success('کاربر ادمین با موفقیت افزوده شد.')
                                    history.goBack();
                                })
                                .catch(err => console.error(err))
                        }}
                    >
                        {(formik) => (
                            <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                                <div className="row">
                                    <StyledInput
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="نام"
                                        focus={true}
                                        maxLength="32"
                                        name="firstName"
                                        value={formik.values.firstName}
                                        // error={formik.errors.firstName !== undefined ? formik.errors.firstName : 'false'}
                                        error={(formik.errors.firstName && formik.touched.firstName)
                                            ? formik.errors.firstName
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                                    />

                                    <StyledInput
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="نام خانوادگی"
                                        focus={true}
                                        maxLength="32"
                                        name="lastName"
                                        value={formik.values.lastName}
                                        error={(formik.errors.lastName && formik.touched.lastName)
                                            ? formik.errors.lastName
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                                    />

                                    <StyledInput
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="نام پدر "
                                        focus={true}
                                        placeholder=""
                                        name="fatherName"
                                        value={formik.values.fatherName}
                                        error={(formik.errors.fatherName && formik.touched.fatherName)
                                            ? formik.errors.fatherName
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("fatherName", e.target.value)}
                                    />
                                    <StyledInput
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="شماره موبایل "
                                        focus={true}
                                        placeholder=""
                                        autoComplete="off"
                                        inputMode="numeric"
                                        maxLength="11"
                                        name="mobile"
                                        value={formik.values.mobile}
                                        error={(formik.errors.mobile && formik.touched.mobile)
                                            ? formik.errors.mobile
                                            : 'false'
                                        }
                                        onChange={(e) => {
                                            let convertStr;
                                            convertStr = NumericalValidationMultilang(e.target.value);
                                            if (convertStr === '') {
                                                formik.setFieldValue("mobile", "");
                                                return;
                                            }
                                            if (convertStr) {
                                                formik.setFieldValue("mobile", convertStr)
                                            } else {
                                                return;
                                            }
                                        }}
                                    />

                                    <StyledInput
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="کد ملی"
                                        focus={true}
                                        maxLength="10"
                                        placeholder=""
                                        inputMode="numeric"
                                        name="nationalId"
                                        value={formik.values.nationalId}
                                        error={(formik.errors.nationalId && formik.touched.nationalId)
                                            ? formik.errors.nationalId
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("nationalId", e.target.value)}
                                    />
                                    <StyledInput
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="ایمیل "
                                        focus={true}
                                        maxLength="256"
                                        name="email"
                                        value={formik.values.email}
                                        error={(formik.errors.email && formik.touched.email)
                                            ? formik.errors.email
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("email", e.target.value)}
                                    />
                                    <StyledInput
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="نام کاربری"
                                        focus={true}
                                        name="username"
                                        value={formik.values.username}
                                        error={(formik.errors.username && formik.touched.username)
                                            ? formik.errors.username
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("username", e.target.value)}
                                    />
                                    <StyledInput
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="رمز عبور"
                                        focus={true}
                                        name="setPassword"
                                        type="password"
                                        value={formik.values.setPassword}
                                        error={(formik.errors.setPassword && formik.touched.setPassword)
                                            ? formik.errors.setPassword
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("setPassword", e.target.value)}
                                    />
                                    <StyledSelect
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="نقش‌ها"
                                        hasDefaultOption
                                        focus={true}
                                        name="roleId"
                                        value={formik.values.roleId}
                                        optionItems={IdentityRoleList}
                                        error={(formik.errors.roleId && formik.touched.roleId)
                                            ? formik.errors.roleId
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("roleId", e.target.value)}
                                    />
                                    <StyledSelect
                                        formGroupClass="col-lg-4 col-md-4 col-12"
                                        lable="مربوط به بخش"
                                        hasDefaultOption
                                        focus={true}
                                        name="department"
                                        value={formik.values.department}
                                        optionItems={TicketDepartmentList}
                                        error={(formik.errors.department && formik.touched.department)
                                            ? formik.errors.department
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("department", e.target.value)}
                                    />
                                </div>

                                <StyledButton
                                    width="100%"
                                    btnStyle="darkGreenBtn"
                                    disabled={!formik.isValid || !formik.dirty}
                                    loading={formik.isSubmitting ? 1 : 0}
                                    type="submit"
                                >
                                    افزودن کاربر
                                </StyledButton>
                            </StyledForm>

                        )}
                    </Formik>
                </MainContent>

            </div>
        </WrapperLayout>
    )
}

export default AddUserAdmin;
