
import { otpCode } from "../Rules";
import * as Yup from "yup";

export const AdminLoginOtpSchema = Yup.object().shape({
    //otpCode,
    // otpCode_field_0,
    // otpCode_field_1,
    // otpCode_field_2,
    // otpCode_field_3,
    // otpCode_field_4,
    // otpCode_field_5
    otpCode
});
