import React, { useState, useEffect, useCallback } from "react";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { PageTitleLayout, MainContent } from "../../../Component/Kit/Layout";
import DateConvert from "../../../Utility/Services/date-convert";
import Modal from "./../../../Component/Kit/Modal";
import GetProvider from "../../../Utility/Services/provider";
import {EarnMethod, LineStatus, LineTypes} from "../../../Utility/Services/lines";
import UserLineModal from "./../../../Component/Shared/Modal/user-line-modal";
import { ConvertQueryString } from "../../../Utility/Services/convert-query-string";
import { useLocation } from "react-router-dom";
import { LineEarnEnum } from "../../../Utility/Model/lines.model";
import { DeleteGiftLineModal } from './../../../Component/Shared/Modal/delete-gift-line-modal';

function UserLines() {
    const location = useLocation()
    const [lineData, setlineData] = useState([]);
    const [userLineModal, setUserLineModal] = useState(false);
    const [deleteGiftLineModal, setDeleteGiftLineModal] = useState(false);
    const [modalData, setmodalData] = useState({});
    const tableConfig = {
        header: {
            userName: "نام کاربری",
            number: "شماره",
            providerName: "ارائه دهنده خدمات",
            earnMethodName: "نوع دریافت",
            statusName: "وضعیت خط",
            lineTypeText: "نوع خط",
            createdAt: "تاریخ دریافت خط",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
                condition: (data) => {
                    if (data.number) return true;
                    return false;
                },
            },
            copy: {
                icon: "far fa-copy",
            },
            delete: {
                icon: "fa fa-trash",
            },
        },
        mode: ["filter", "search"],
        filters: ["datePickerFilter", "provider", "earnMethod", "lineStatus", "lineType"],
    };
    const tableActions = (items) => {
        switch (items.actionName) {
            case "view":
                openUserLineModal(items.data);
                break;
            case 'copy':
                const number = items.data.number;
                navigator.clipboard.writeText(number)
                break;
            case 'delete':
                openDeleteGiftLineModal(items.data);
                break;
            default:
                break;
        }
    };
    const openUserLineModal = (item) => {
        // let result = lineData.items.find((m) => m.id === item);
        setUserLineModal(true);
        setmodalData(item);
    };
    const openDeleteGiftLineModal = (item) => {
        setDeleteGiftLineModal(true);
        setmodalData(item);
    }

    const modalChangeHandler = (event) => {
        if (event) {
            getLines();
        }
    };
    const getLines = useCallback(() => {
        ApiClient.Get(Urls.getUserLines(), ConvertQueryString(location.search))
            .then((res) => {
                let data = res.result;
                data.items.map((m, i) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.providerName = GetProvider(m.providerId);
                    m.earnMethodName = EarnMethod(m.earnMethod);
                    m.statusName = LineStatus(m.status);
                    m.lineTypeText = LineTypes(m.lineType);
                    return m;
                });
                setlineData(data);
            })
    }, [location.search])

    useEffect(() => {
        getLines();
    }, [location.search, getLines]);

    return (
        <>
            <MainContent>
                <PageTitleLayout>
                    <h5>خطوط کاربران</h5>
                    <span>
                        لیست خطوط کاربران در این بخش قابل مشاهده است.
                    </span>
                </PageTitleLayout>
                <Table
                    data={lineData.items}
                    config={tableConfig}
                    pageConfig={{
                        pageNumber: lineData.pageNumber,
                        pageSize: lineData.pageSize,
                        totalItemsCount: lineData.totalItemsCount,
                        totalPagesCount: lineData.totalPagesCount,
                    }}
                    actionDispatched={tableActions}
                />
            </MainContent>

            <Modal
                onClose={() => setUserLineModal(false)}
                visible={userLineModal}
                onCancel={() => setUserLineModal(false)}
                headerTitle="وضعیت خط"
            >
                <UserLineModal data={modalData} dataChange={modalChangeHandler}
                    onCloseModal={(e) => setUserLineModal(e)} />
            </Modal>

            <Modal
                onClose={() => setDeleteGiftLineModal(false)}
                visible={deleteGiftLineModal}
                onCancel={() => setDeleteGiftLineModal(false)}
                headerTitle={(modalData.earnMethod === LineEarnEnum.Gift) ? 'حذف خط هدیه ' : 'حذف خط '}
            >
                <DeleteGiftLineModal data={modalData} dataChange={modalChangeHandler}
                    onCloseModal={(e) => setDeleteGiftLineModal(e)} />
            </Modal>
        </>
    );
}

export default UserLines;
