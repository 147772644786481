import { combineReducers } from "redux";
import pendingLines from './pending-lines'
import AdminDetailReducer from './AdminDetail'
import bootstrapper from "./bootstrapper";
import modalChange from "./setting-modal";
import DiscountCodeDetailReducer from "./DiscountCodeDetail";
import UserProfileDataReducer from "./UserProfileData";
import UserLegalDataReducer from "./UserLegalData";
import LocationDataReducer from "./locationData";
import CreditPackageItemReducer from "./CreditPackageItem";
import BasePriceTiersReducer from "./BasePriceTiers";
import guestData from "./guest-data";

const reducers = combineReducers({
    bootstrapper,
    pendingLines,
    AdminDetailReducer,
    modalChange,
    DiscountCodeDetailReducer,
    UserProfileDataReducer,
    LocationDataReducer,
    UserLegalDataReducer,
    CreditPackageItemReducer,
    BasePriceTiersReducer,
    guestData,
});

export default reducers;
