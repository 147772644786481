import React, {useState} from "react";
import {Badge, Modal} from 'react-bootstrap';

import Urls from "../../../../Requester/Urls";
import {StyledButton} from "../../../../Component/Kit/Button";
import NumberLimitationModal from "../../modals/limitation-modal";

function PublicLineCostCoefficient({data}) {
    const [isModal, setIsModal] = useState(false);
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">ضریب کسر اعتبار ارسال با خطوط ویژه : </span>
                    <Badge className="font-weight-normal" variant="primary">{data}</Badge>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>

            <Modal centered show={isModal} onHide={() => {
                setIsModal(false)
            }}>
                <Modal.Header>
                    <Modal.Title>ضریب کسر اعتبار ارسال با خطوط ویژه</Modal.Title>
                    <i onClick={() => {
                        setIsModal(false)
                    }} className="close-btn fa fa-xmark"/>
                </Modal.Header>
                <Modal.Body className={'p-0'}>
                    <NumberLimitationModal defaultData={data} onCloseModal={() => setIsModal(false)} url={Urls.publicLineCost()} />
                </Modal.Body>

            </Modal>

        </>
    )
}

export default PublicLineCostCoefficient;
