import React, {useEffect, useState} from 'react';
import { HeaderContent, MainContent, PageTitleLayout } from "../../Component/Kit/Layout";
import { StyledButton } from "../../Component/Kit/Button";
import BackToPage from "../../Component/Kit/back";
import {Field, Formik} from "formik";
import { StyledInput, StyledTextArea, StyledForm } from "../../Component/Kit/Form";
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import { MyRangePicker} from "../../Component/Shared/DatePicker";
import { DateObject } from "react-multi-date-picker";
import Swal from "sweetalert2";
import {useHistory, useLocation, useParams} from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment-jalaali';
import {CheckBox} from "../../Component/Kit/Checkbox";
import DateConvert from "../../Utility/Services/date-convert";
import loginFirstStepForm from "../Login/LoginFirstStepForm";

function AddGlobalNotification() {
    let history = useHistory();
    const params = useParams();
    const [notification , setNotification] = useState({})
    useEffect(() => {
        if (params.id){
            ApiClient.Get(Urls.globalNotificationSingle(params.id)).then(
                (res)=> {
                    setNotification(res.result)
                }
            );
        }
    } , [params.id])


    const dateConvert = (item) => {
        const data = item.toString()
        if (data){
            let value = {
                year: data.slice(0, 4),
                month: data.slice(4, 6),
                day: data.slice(6, 8),
                hour: data.slice(8, 10),
                minute: data.slice(10, 12),
                second: data.slice(12, 14),
            };

            return moment(`${value.year}/${value.month}/${value.day} ${value.hour}:${value.minute}:${value.second}`)
                .locale('en')
                .format('YYYY/MM/DD HH:mm:ss')

        }else{
            return ''
        }
    }
    const addGlobalNotification = (body) => {
        Swal.fire({
            title: `آیا مطمئن هستید؟`,
            text: "آیا از ارسال این اعلان مطمئن هستین؟؟ این اعلام برای تمام کاربران ارسال می‌شود.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                if (params.id){
                    ApiClient.Put(
                        Urls.globalNotificationSingle(params.id), body
                    ).then(() => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        history.push(`/notifications`)
                    })
                }else{
                    ApiClient.Post(
                        Urls.globalNotifications(), body
                    ).then(() => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        history.push(`/notifications`)
                    })
                }
            }
        })
    }
    return (
        <>
            <HeaderContent className="justify-content-end">
                <BackToPage toPage={`/notifications`} />
            </HeaderContent>
            <MainContent>
                <div className="col-12 col-lg-12">
                    <PageTitleLayout className='pt-2'>
                        <h5>{params.id ? 'ویرایش اعلان' : 'ایجاد اعلان جدید'}</h5>
                        <p>لطفا فیلدهای زیر را پرکنید.</p>
                    </PageTitleLayout>
                </div>
                <hr className="mt-4 mx-3" />
                <Formik
                    initialValues={{
                        notificationStartDate: notification?.startDateTime ? dateConvert(notification?.startDateTime):  '',
                        notificationEndDate:notification?.endDateTime ? dateConvert(notification?.endDateTime): '',
                        notificationTitle: notification?.title ? notification?.title: '',
                        notificationContent: notification?.content ? notification?.content : '',
                        showAtEntry: notification?.showAtEntry ? notification?.showAtEntry : false
                    }}
                    validate={values => {
                        const errors = {};
                        if (values.notificationStartDate !== null && values.notificationEndDate === null) {
                            errors.notificationEndDate = 'این فیلد الزامی است.'
                        }
                        if (values.notificationStartDate === null && values.notificationEndDate !== null) {
                            errors.notificationStartDate = 'این فیلد الزامی است.'
                        }
                        if (values.notificationTitle?.length < 1 || values.notificationTitle?.length > 32) {
                            errors.notificationTitle = 'حداقل 1 کاراکتر و حداکثر 32 کاراکتر وارد کنید'
                        }
                        if (values.notificationContent?.length < 1 || values.notificationContent?.length > 512) {
                            errors.notificationContent = 'حداقل 1 کاراکتر و حداکثر 512 کاراکتر وارد کنید'
                        }
                        return errors;
                    }}
                    isInitialValid={false}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        let body = {
                            startDateTime: values.notificationStartDate ? parseInt(values.notificationStartDate.baseFormat) : null,
                            endDateTime: values.notificationEndDate ? parseInt(values.notificationEndDate.baseFormat) : null,
                            title: values.notificationTitle,
                            content: values.notificationContent,
                            showAtEntry: values.showAtEntry
                        };
                        addGlobalNotification(body)
                    }}
                >
                    {(formik) => (
                        <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <StyledInput
                                        lable="لطفا نام اعلان را وارد کنید."
                                        focus={true}
                                        placeholder="نام اعلان"
                                        type="text"
                                        name="notificationTitle"
                                        value={formik.values.notificationTitle}
                                        error={(formik.errors.notificationTitle && formik.touched.notificationTitle)
                                            ? formik.errors.notificationTitle
                                            : 'false'
                                        }
                                        onChange={(e) =>
                                            formik.setFieldValue("notificationTitle", e.target.value)
                                        }
                                    />
                                </div>
                                    <div className="col-12 col-lg-8">
                                        <MyRangePicker
                                            fromDate={formik.values.notificationStartDate}
                                            toDate={formik.values.notificationEndDate}
                                            haveTime
                                            value={(event) => {
                                                formik.setFieldValue("notificationStartDate", event.fromDate)
                                                formik.setFieldValue("notificationEndDate", event.toDate)
                                            }}
                                            min={moment().subtract(1, 'days')}
                                            // fromError={!formik.values.notificationStartDate && formik.values.notificationEndDate ? formik['isValid'] = false : false}
                                            // toError={formik.values.notificationStartDate && !formik.values.notificationEndDate ?  formik['isValid'] = false  : false}
                                            fromError={formik.errors.notificationStartDate !== undefined
                                                ? formik.errors.notificationStartDate
                                                : false}
                                            toError={formik.errors.notificationEndDate !== undefined
                                                ? formik.errors.notificationEndDate
                                                : false}
                                        />
                                    </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <StyledTextArea
                                        lable="متن اعلان را وارد کنید"
                                        focus={true}
                                        name="notificationContent"
                                        textAreaClass="notification-text"
                                        value={formik.values.notificationContent}
                                        error={(formik.errors.notificationContent && formik.touched.notificationContent)
                                            ? formik.errors.notificationContent
                                            : 'false'
                                        }
                                        onChange={(e) => {
                                            const str = e.target.value.trim() !== '' ? e.target.value : ''
                                            formik.setFieldValue("notificationContent", str)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-3">
                                <CheckBox>
                                    <label>
                                        <Field type="checkbox" name="showAtEntry" />
                                        نمایش هنگام ورود
                                    </label>
                                </CheckBox>
                                </div>
                            </div>
                            <StyledButton
                                width="100%"
                                btnStyle="darkGreenBtn"
                                disabled={!formik.isValid}
                                type="submit"
                            >
                                {params.id ? 'ویرایش اعلان' : 'ذخیره اعلان'}
                            </StyledButton>
                        </StyledForm>
                    )}
                </Formik>
            </MainContent>
        </>
    )
}

export default AddGlobalNotification;