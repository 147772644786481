import { Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../../Component/Kit/Button';
import { StyledForm, StyledInput } from '../../../../../Component/Kit/Form';
import ApiClient from '../../../../../Requester/ApiClient';
import Urls from '../../../../../Requester/Urls';
import { EditUserMobileSchema } from '../../../../../Utility/Validation/Schema/EditUserMobileSchema';

const UserMobileVerification = (props) => {
    const { setEditMobileStep, userId, setUserMobile } = props;
    const [submitLoading, setSubmitLoading] = useState(false);

    return (
        <Formik
            initialValues={{
                mobile: '',
            }}
            validationSchema={EditUserMobileSchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                setSubmitLoading(true);
                ApiClient.Patch(Urls.modifyUserMobile(userId), JSON.stringify(values.mobile))
                    .then((res) => {
                        setSubmitLoading(false);
                        setEditMobileStep('code');
                        setUserMobile(values.mobile);
                        if (res.result) {
                            toast.warning('کد فعالسازی قبلا برای شما ارسال شده است. لطفا از کد قبلی استفاده نمایید.');
                        } else {
                            toast.success('کد برای کاربر ارسال شد.');
                        }
                    })
                    .catch(err => {
                        setSubmitLoading(false);
                        actions.resetForm();
                    })
            }}
        >
            {(formik) => (
                <StyledForm
                    className="mt-3"
                    onSubmit={formik.handleSubmit}
                    onBlur={formik.handleBlur}>
                    <div className="row">
                        <div className="col-12">
                            <StyledInput
                                lable="شماره تلفن همراه"
                                focus={true}
                                type="text"
                                name='mobile'
                                value={formik.values.mobile || ''}
                                error={(formik.errors.mobile && formik.touched.mobile)
                                    ? formik.errors.mobile
                                    : 'false'
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("mobile", e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="text-left">
                        <StyledButton
                            disabled={(!formik.isValid || !formik.dirty) && !submitLoading}
                            loading={submitLoading ? 1 : 0}
                            type="submit"
                            btnStyle="greenBtn"
                        >
                            ارسال شماره همراه
                        </StyledButton>
                    </div>
                </StyledForm>
            )}
        </Formik>
    )
}

export default UserMobileVerification