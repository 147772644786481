import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormLayout } from '../../../../../Component/Kit/Form';
import { UploadFileWrqapper } from '../../../../../Component/Kit/UploadZone';
import { toast } from 'react-toastify';
import ApiClient from '../../../../../Requester/ApiClient';
import Urls from '../../../../../Requester/Urls';

const EditUserdocuments = ({ userProfileData }) => {
  let match = useRouteMatch();
  const history = useHistory();
  const [nationalCardUrl, setNationalCardUrl] = useState('');
  const [gazetteURL, setGazetteURL] = useState('');

  useEffect(() => {
    if (Object.entries(userProfileData).length) {
      setNationalCardUrl(userProfileData.nationalCardUploadedURL);
      setGazetteURL(userProfileData.gazetteUploadedURL || null);
    } else {
      history.push(`/userDetail/${match.params.id}/profile`);
    }
  }, [history, match.params.id, userProfileData])

  const changeImageHandler = (e) => {
    const nationalCardImage = e.target.files[0];
    if (nationalCardImage.size > 3000000) {
      toast.warning("سایز عکس بیشتر از مقدار مجاز است");
      return
    }
    const formData = new FormData();
    formData.append('file', nationalCardImage, nationalCardImage.name);
    ApiClient.Patch(Urls.modifyUserNationalCardImage(match.params.id), formData)
      .then(res => {
        setNationalCardUrl(res.result);
        toast.success('آپلود کارت ملی با موفقیت انجام شد.');
      });
  };

  const changeGazetteImageHandler = (e) => {
    const gazetteImage = e.target.files[0];
    if (gazetteImage.size > 3000000) {
      toast.warning("سایز عکس بیشتر از مقدار مجاز است");
      return
    }
    const formData = new FormData();
    formData.append('file', gazetteImage, gazetteImage.name);
    ApiClient.Patch(Urls.modifyUserGazetteImage(match.params.id), formData)
      .then(res => {
        setGazetteURL(res.result);
        toast.success('آپلود روزنامه رسمی با موفقیت انجام شد.');
      });
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <h5 className="my-4 pb-4">ویرایش اسناد کاربر</h5>
      </div>

      <div className="col-12 col-lg-6">
        <FormLayout>
          <div className="form-title">
            <span>کارت ملی</span>
          </div>

          <UploadFileWrqapper
            fileUrl={nationalCardUrl}
            name="nationalCardUrl"
            id="nationalCardUrl"
            onChange={(e) => {
              changeImageHandler(e)
            }}
            format="image/jpg, image/jpeg, image/png"
          />
        </FormLayout>
      </div>

      {userProfileData.gazetteUploadedURL &&
        <div className="col-12 col-lg-6">
          <FormLayout>
            <div className="form-title">
              <span>روزنامه رسمی</span>
            </div>

            <UploadFileWrqapper
              fileUrl={gazetteURL}
              name="gazetteUploadedUrl"
              id="gazetteUploadedUrl"
              onChange={(e) => {
                changeGazetteImageHandler(e)
              }}
              format="image/jpg, image/jpeg, image/png"
            />
          </FormLayout>
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  userProfileData: state.UserProfileDataReducer,
});
export default connect(mapStateToProps)(EditUserdocuments);