import TicketPriorityList from "../Model/TicketPriorityModel";

function TicketPriority(value) {
    let result = TicketPriorityList.find((item) => item.value === value);
    return result.name
}

export default TicketPriority;

export function GetTicketPriorityKey(name) {
    let result = TicketPriorityList.find((item) => item.name === name);
    return result.value
}
