import React, {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';
import {Formik} from 'formik';
import {connect} from 'react-redux';
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import DateConvert from "../../../../Utility/Services/date-convert";
import {PageTitleLayout, WrapperLayout} from "../../../../Component/Kit/Layout";
import * as Yup from "yup";
import {StyledForm, StyledTextArea} from "../../../../Component/Kit/Form";
import {StyledButton} from "../../../../Component/Kit/Button";
import {Loader} from "../../../../Component/Shared/Loader";
import CommentItem from "./CommentItem";
import { toast } from 'react-toastify';

const ServiceLineCommentSchema = Yup.object().shape({
    content: Yup.string()
        .label("content")
        .required("این فیلد الزامی است."),
});

function ServiceLineComments({adminProfile, notes}) {
    const params = useParams();
    const [noteList, setNoteList] = useState();
    const [loading, setLoading] = useState(true);

    const getData = () => {
        ApiClient.Get(Urls.getDetailUserServiceLine(params.id)).then((res) => {
            res.result.adminNotes.forEach((f) => {
                f.creationDateTime = DateConvert(f.createdAt);
            });
            setLoading(false);
            setNoteList(res.result.adminNotes);
        });
    };
    const deleteHandler = (isDelete) => {
        if (isDelete) {
            getData();
        }
    };

    useEffect(() => {
        if (notes) {
            notes.forEach((f) => {
                f.creationDateTime = DateConvert(f.createdAt);
            })
            setLoading(false);
            setNoteList(notes);
        }
    }, [notes]);
    return (
        <>

                <div className="col-12 mt-5">
                    {loading === true ?
                        <Loader/>
                        :
                        <>
                        </>
                    }
                    <CommentItem
                        comments={noteList || []}
                        deleteConfirm={deleteHandler}/>
                </div>

                <>
                    <PageTitleLayout className="pt-2">
                        <h5>ایجاد یادداشت‌</h5>
                    </PageTitleLayout>

                    <Formik
                        initialValues={{
                            content: '',
                        }}
                        validationSchema={ServiceLineCommentSchema}
                        onSubmit={(values, actions) => {
                            ApiClient.Post(
                                Urls.AddServiceLineNote(params.id),
                                JSON.stringify({
                                    content: values.content
                                })
                            ).then((res) => {
                                getData();
                                toast.success('یادداشت  با موفقیت ثبت شد.');
                                actions.resetForm();
                            });
                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onBlur={formik.handleBlur}
                                className='replyContainer'
                            >
                                <div className='row'>
                                    <div className='col-12 rp-box'>
                                        <StyledTextArea
                                            placeholder='موارد مورد نظر خود را وارد نمایید'
                                            lable='متن یادداشت'
                                            focus={true}
                                            name='content'
                                            height='100%'
                                            error={
                                                formik.errors.content &&
                                                formik.touched.content
                                                    ? formik.errors.content
                                                    : 'false'
                                            }
                                            value={formik.values.content}
                                            onChange={(e) => {
                                                formik.setFieldValue('content', e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <StyledButton
                                    width='100%'
                                    btnStyle='darkGreenBtn'
                                    id='submit-button'
                                    disabled={!formik.isValid || !formik.dirty}
                                    type='submit'
                                >
                                    ارسال یادداشت
                                </StyledButton>
                            </StyledForm>
                        )}
                    </Formik>
                </>
        </>
    );
}

function mapStateToProps(state) {
    const {bootstrapper} = state;
    return {adminProfile: bootstrapper.data};
}

export default connect(mapStateToProps)(ServiceLineComments);
