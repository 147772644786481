import React, {useCallback, useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import {toast} from "react-toastify";


import {PageTitleLayout} from "../../../Component/Kit/Layout";
import {Button, StyledButton} from "../../../Component/Kit/Button";
import {CheckBox} from "../../../Component/Kit/Checkbox";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import EnumFlag from "../../../Utility/Services/enum-flag";
import {StyledSwitchBox} from "../../../Component/Kit/Form";
import WhiteListOfForbiddenWords from "./WhiteListOfForbiddenWords";
import DedicatedLimitForVerifyCodeParametersModal from "./DedicatedLimitForVerifyCodeParametersModal";
import CalledWebserviceLimitationModal from "./CalledWebserviceLimitationModal";

const UserRestriction = () => {
    const match = useRouteMatch();
    const [data, setData] = useState({
        count: false, link: false, forbiddenWord: false, newUser: false,
    });
    const [cancellationStatus, setCancellationStatus] = useState(false);
    const [verifyCodeParametersModal, setVerifyCodeParametersModal] = useState(false)
    const [calledWebServiceModal, setCalledWebServiceModal] = useState(false)

    const switchHandler = (status) => {
        const body = JSON.stringify(status);
        ApiClient.Patch(Urls.userCancellationPhraseStatus(match.params.id), body).then((res) => {
            if (res.result) {
                setCancellationStatus(status);
                toast.success("تغییرات  با موفقیت ثبت شد.");
            }
        });
    };

    const getData = useCallback(async () => {
        try {
            const userDeniedResponse = await ApiClient.Get(Urls.userDeniedLimitations(match.params.id));
            const cancellationPhraseResponse = await ApiClient.Get(Urls.userCancellationPhraseStatus(match.params.id));
            setCancellationStatus(cancellationPhraseResponse.result);

            const deniedRestriction = EnumFlag([1, 2, 4, 8], userDeniedResponse.result);
            const newData = {
                count: false, link: false, forbiddenWord: false, newUser: false,
            };
            deniedRestriction.forEach((item) => {
                switch (item) {
                    case 1:
                        newData.count = true;
                        break;
                    case 2:
                        newData.link = true;
                        break;
                    case 4:
                        newData.forbiddenWord = true;
                        break;
                    case 8:
                        newData.newUser = true;
                        break;
                    default:
                        console.log("type undefined");
                }
            });
            setData({...newData});
        } catch (err) {
        }
    }, [match.params.id]);

    useEffect(() => {
        getData();
    }, [getData, match.params.id]);
    return (<>
        <div className="row">
            <div className="col-12 d-flex justify-content-end p-0 mb-3">
                <StyledButton btnStyle="greenBtn" onClick={() => setVerifyCodeParametersModal(true)}>
                    تعداد کاراکتر وب سرویس verify
                </StyledButton>
                <StyledButton btnStyle="greenBtn mr-3" onClick={() => setCalledWebServiceModal(true)}>
                    محدودیت ارسال روزانه وب سرویس
                </StyledButton>
            </div>
            <div className="col-12 wrapper-box">
                <PageTitleLayout>
                    <h5>رفع محدودیت‌های ارسال</h5>
                    <span>
                        می‌توانید در صورت نیاز لغو محدودیت‌های ارسالی برای این کاربر را از
                        این صفحه کنترل کنید. با انجام این کار، کاربر به لیست سفید منتقل
                        شده و محدودیت مورد نظر اعمال نمی‌شود. می‌توانید در هر زمان لغو
                        محدودیت‌ها را برای این کاربر بردارید.
                    </span>
                </PageTitleLayout>
                <Formik
                    enableReinitialize={true}
                    initialValues={{...data}}
                    onSubmit={(values) => {
                        let result = 0;
                        for (const item in values) {
                            if (values[item]) {
                                if (item === "count") {
                                    result += 1;
                                }
                                if (item === "link") {
                                    result += 2;
                                }
                                if (item === "forbiddenWord") {
                                    result += 4;
                                }
                                if (item === "newUser") {
                                    result += 8;
                                }
                            }
                        }
                        if (result) {
                            ApiClient.Put(Urls.userDeniedLimitations(match.params.id), result).then((res) => {
                                toast.success("محدودیت ها با موفقیت ویرایش شد.");
                                setData({
                                    count: values.count,
                                    link: values.link,
                                    forbiddenWord: values.forbiddenWord,
                                    newUser: values.newUser,
                                });
                            });
                        } else {
                            ApiClient.Delete(Urls.userDeniedLimitations(match.params.id)).then((res) => {
                                if (res) {
                                    toast.success("همه محدودیت ها با موفقیت اعمال شد.");
                                    setData({
                                        count: values.count,
                                        link: values.link,
                                        forbiddenWord: values.forbiddenWord,
                                        newUser: values.newUser,
                                    });
                                }
                            });
                        }
                    }}
                >
                    {({dirty}) => (
                        <Form className="mt-4">
                            <CheckBox>
                                <label>
                                    <Field type="checkbox" name="count"/>
                                    لغو محدودیت ارسال
                                </label>
                            </CheckBox>
                            <CheckBox>
                                <label>
                                    <Field type="checkbox" name="link"/>
                                    لغو محدودیت لینک
                                </label>
                            </CheckBox>
                            <CheckBox>
                                <label>
                                    <Field type="checkbox" name="forbiddenWord"/>
                                    لغو کلمات ممنوعه
                                </label>
                            </CheckBox>
                            <CheckBox>
                                <label>
                                    <Field type="checkbox" name="newUser"/>
                                    لغو کاربر جدید
                                </label>
                            </CheckBox>
                            <div className="mt-4">
                                <Button
                                    type={"submit"}
                                    disabled={!dirty}
                                    className="greenBtn"
                                >
                                    ویرایش محدودیت ها
                                </Button>
                            </div>
                        </Form>)}
                </Formik>
            </div>
        </div>

        <div className="row my-3">
            <div className="col-12 wrapper-box">
                <PageTitleLayout>
                    <h5>ارسال عبارت لغو</h5>
                    <span>
                        از طریق فعال کردن این گزینهُ امکان عدم ارسال عبارت لغو برای کاربر
                        امکان‌پذیر خواهد شد. می‌توانید در هر زمان این قابلیت را برای کاربر
                        مجدداٌ فعال نمایید.
                    </span>
                </PageTitleLayout>

                <div className="row">
                    <div className="col-12">
                        <div className="d-flex align-items-center">
                            <span className="ml-2">عدم ارسال عبارت لغو:</span>
                            <StyledSwitchBox
                                checked={cancellationStatus === true}
                                onChange={(e) => switchHandler(e.target.checked)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <WhiteListOfForbiddenWords/>
        <DedicatedLimitForVerifyCodeParametersModal
            visible={verifyCodeParametersModal}
            setVisible={() => setVerifyCodeParametersModal(false)}
            onCancel={() => setVerifyCodeParametersModal(false)}
            onClose={() => setVerifyCodeParametersModal(false)}
        />

        <CalledWebserviceLimitationModal
            visible={calledWebServiceModal}
            setVisible={() => setCalledWebServiceModal(false)}
            onCancel={() => setCalledWebServiceModal(false)}
            onClose={() => setCalledWebServiceModal(false)}
        />

    </>);
};
export default UserRestriction;
