import React from "react";
import styled from "styled-components";
import Col from "./Column";

export const Button = styled.button`
  position: relative;
  transition: all 0.3s ease-in;
  height: ${(props) => props.height || "32px"};
  min-height: ${(props) => props.height || "32px"};
  width: ${(props) => props.width || "auto"};
  cursor: ${(props) => (props.disabled === true ? "no-drop" : "pointer")};
  background-color: ${(props) =>
    props.disabled === true ? props.theme.primaryHover : props.theme.primary};
  color: white;
  border-radius: 0.3rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border: none;
  outline: none;
  padding: ${props => props.iconPosition === 'right' ? '0px 24px 0px 0px' : ' .375rem .75rem'};

  :focus {
    outline: none;
    border: none;
  }

  &.greenBtn {
    background-color: ${(props) =>
    props.disabled === true
      ? props.theme.greenDisable
      : props.theme.greenBtn};
    padding-left: 2.25rem;
    padding-right: 2.25rem;

    &:hover {
      background-color: ${(props) =>
    props.disabled === true
      ? props.theme.greenDisable
      : props.theme.greenBtnHover};
    }
  }

  &.darkGreenBtn {
    background-color: ${(props) =>
    props.disabled === true
      ? props.theme.darkGreenDisable
      : props.theme.darkGreenBtn};
    padding-left: 2.25rem;
    padding-right: 2.25rem;

    &:hover {
      background-color: ${(props) =>
    props.disabled === true
      ? props.theme.darkGreenDisable
      : props.theme.darkGreenBtnHover};
    }
  }
  &.smallBtn {
    min-height: 27px;
    height: auto;
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
  }

  &.redBtn {
    background-color: ${(props) =>
    props.disabled === true ? props.theme.redDisable : props.theme.redBtn};
    padding-left: 2.25rem;
    padding-right: 2.25rem;

    &:hover {
      background-color: ${(props) =>
    props.disabled === true
      ? props.theme.redDisable
      : props.theme.redBtnHover};
    }
  }

  &.yellowBtn {
    background-color: ${(props) =>
    props.disabled === true
      ? props.theme.yellowDisable
      : props.theme.yellowBtn};
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    color: ${(props) => props.theme.grayDark};

    &:hover {
      background-color: ${(props) =>
    props.disabled === true
      ? props.theme.yellowDisable
      : props.theme.yellowBtnHover};
    }
  }

  &.grayBtn {
    background-color: ${(props) =>
    props.disabled === true
      ? props.theme.grayDisable
      : props.theme.grayBtn};
    padding-left: 2.20rem;
    padding-right: 2.20rem;
    color: ${(props) => props.theme.grayDark};

    &:hover {
      background-color: ${(props) =>
    props.disabled === true
      ? props.theme.grayDisable
      : props.theme.grayBtnHover};
      color: ${(props) => props.theme.white};
    }
  }

  @media only screen and (max-width: 575px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-size: 13px;
    width: auto;
  }

`;

const IconContainer = styled(Col)`
  width: ${(props) => props.height || "26px"};
  height: ${(props) => props.height || "26px"};
  background-color: ${(props) => props.theme.primary};
  position: absolute;
  display: ${(props) => (props.hasIcon === true ? "flex" : "none")};
  top: 0px;
  bottom: 0px;
  right: ${(props) => props.iconPosition === "right" && "0px"};
  left: ${(props) => props.iconPosition === "left" && "0px"};
  border-radius: ${(props) =>
    props.iconPosition === "right" ? "0px 50px 50px 0px" : "50px 0px 0px 50px"};

  svg {
    height: 12px;
    width: 12px;
  }
`;

export const StyledButton = (props) => {
  const { loading, hasIcon, iconPosition, btnStyle } = props;
  return (
    <Button {...props} className={btnStyle}>
      <IconContainer
        height={props.height}
        hasIcon={hasIcon || false}
        iconPosition={iconPosition || "right"}
      ></IconContainer>
      {loading ? "چند لحظه منتظر بمانید ..." : props.children}
    </Button>
  );
};
