import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PageTitleLayout } from "../../../../Component/Kit/Layout";
import ApiClient from "../../../../Requester/ApiClient";
import Table from "../../../../Component/Shared/Table/Table";
import Urls from "../../../../Requester/Urls";
import { ConvertQueryString } from "../../../../Utility/Services/convert-query-string";
import DateConvert from "../../../../Utility/Services/date-convert";
import { SeprateNumberWithComma } from "../../../../Utility/Services/SeprateNumberWithComma";

const OldPanelInvoiceApproved = (props) => {
  const location = useLocation();
  const { tableConfig, tableActions } = props;
  const [invoiceApprovedList, setInvoiceApprovedList] = useState({});
  const history = useHistory();
  useEffect(() => {
    let params = {
      ...ConvertQueryString(location.search),
      numbered: true,
    };
    ApiClient.Get(Urls.GetOldPanelOfficialInvoices(), params).then((res) => {
      let responseData = res.result;
      responseData.items.map((item, i) => {
        item.totalPrice = `${SeprateNumberWithComma(item.totalPrice)} ریال`;
        item.createdAt = DateConvert(item.createdAt);
        item.canModifyDiscountPrice = item.canModifyDiscountPrice
          ? `<span class="grass-green-text">دارد</span>`
          : `<span class="red-text">ندارد</span>`;
        item.isManualPayment = item.isManualPayment
          ? `<span class="grass-green-text">دارد</span>`
          : `<span class="red-text">ندارد</span>`;
      });
      setInvoiceApprovedList(responseData);
    });
  }, [location.search]);

  const oldPanelTableActions = (items) => {
    if (items.actionName === "view") {
      history.push(`/financial/invoice/old/${items.data.id}`);
    }
  };
  const exportActions = (item) => {
    let params = {
      ...ConvertQueryString(location.search),
    };
    ApiClient.Get(`${Urls.GetOldPanelOfficialInvoices()}/excel`, {
      numbered: true,
      ...params,
    })
      .then((res) => {
        let resData = res.result;
        const url = `${Urls.OldPanelBase()}/${resData}`;
        windowOpen(url);
      })
      .catch((err) => console.log(err));
  };

  const windowOpen = (item) => {
    window.open(item);
  };

  return (
    <div>
      <PageTitleLayout className="mt-4">
        <h5>لیست فاکتورهای با‌شماره (پنل قدیم)</h5>
        <span>
          در این بخش لیستی از فاکتور‌های تایید شده در پنل قدیم، قابل مشاهده است.
        </span>
      </PageTitleLayout>
      {invoiceApprovedList && (
        <Table
          data={invoiceApprovedList.items}
          config={tableConfig}
          pageConfig={{
            pageNumber: invoiceApprovedList.pageNumber,
            pageSize: invoiceApprovedList.pageSize,
            totalItemsCount: invoiceApprovedList.totalItemsCount,
            totalPagesCount: invoiceApprovedList.totalPagesCount,
          }}
          actionDispatched={(e) => oldPanelTableActions(e)}
          exportDispatched={exportActions}
        />
      )}
    </div>
  );
};

export default OldPanelInvoiceApproved;
