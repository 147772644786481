import React, { useState } from 'react';
import { StyledButton } from '../../../Component/Kit/Button';
import { StyledTextArea } from '../../../Component/Kit/Form';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { ValidateWrapper, ValidateWrapperText } from '../index.jsx';
import Modal from '../../../Component/Kit/Modal';
import {ArabicToFa} from "../../../Utility/method/farsi";


export const ValidateMessage = ({ onCloseModal, visible }) => {
    const [textMessage, setTextMessage] = useState('');
    const [validatedMessage, setValidatedMessage] = useState([]);
    const [invalidItems, setInvalidItems] = useState([]);

    const validationHandler = () => {
        setValidatedMessage(ArabicToFa(textMessage).split(' '));
        ApiClient.Get(Urls.ValidationMessage(), { messageText: textMessage })
            .then(res => {
                setInvalidItems(res.result);
            })
    }

    const modalCloseHandler = () => {
        onCloseModal(false);
        setTextMessage('');
        setValidatedMessage([]);
        setInvalidItems([]);
    }

    return (
        <Modal
            onClose={() => modalCloseHandler()}
            visible={visible}
            onCancel={() => modalCloseHandler()}
            headerTitle="اعتبارسنجی متن پیامک"
        >
            <div className="w-100 p-2">
                <div className="form">
                    <StyledTextArea
                        lable="لطفا متن مورد نظر خود را وارد نمایید."
                        focus={true}
                        placeholder="متن پیامک"
                        type="text"
                        name="decription"
                        value={textMessage}
                        error="false"
                        height="100px"
                        onChange={(e) => setTextMessage(e.target.value)}
                    />
                    <div>
                        <StyledButton
                            width="35%"
                            disabled={!textMessage}
                            btnStyle="greenBtn"
                            type="submit"
                            onClick={() => validationHandler()}
                        >
                            اعتبارسنجی متن
                        </StyledButton>
                    </div>
                </div>

                {
                    validatedMessage.length > 0 &&
                    <ValidateWrapper>
                        <ValidateWrapperText>متن اعتبارسنجی شده: </ValidateWrapperText>
                        <div className="p-3 wrapper-box">
                            {
                                validatedMessage.map((item, index) => (
                                    <span key={`item_${index}`}
                                        className={invalidItems.find(x => item.includes(x)) ? 'red-text' : ''}
                                    >{item} </span>
                                ))
                            }
                        </div>
                    </ValidateWrapper>
                }
            </div>
        </Modal>

    )
}

export default ValidateMessage;
