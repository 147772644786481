import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import BackToPage from '../../../Component/Kit/back'
import { StyledButton } from '../../../Component/Kit/Button'
import { StyledForm, StyledInput, StyledTextArea } from '../../../Component/Kit/Form'
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import { Loader } from '../../../Component/Shared/Loader';

export const AddReturnInvoice = (props) => {

    const [singleInvoice, setSingleInvoice] = useState({});
    const [creditCount, setCreditCount] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    let searchItems = props.history.location.search;
    searchItems = searchItems.replace("?", "");
    const params = searchItems.split('&');
    const returnObj = {
        invoiceId: params[0].replace("invoiceId=", ""),
        userId: params[1].replace("userId=", ""),
    };

    const baseUrl = Urls.GetOfficialInvoices(returnObj.invoiceId)

    useEffect(() => {
        const parameter = {
            "isReturn": false
        }
        ApiClient.Get(baseUrl, parameter).then((res) => {
            if (res?.result){
                setSingleInvoice(res.result.details[0]);
            }
            ApiClient.Get(Urls.GetUserCreditCount(returnObj.userId)).then((res) => {
                setCreditCount(res.result);
                setLoading(false);
            });
        });
    }, [setSingleInvoice, setLoading, setCreditCount , searchItems])

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <span className="fakeContainer"></span>
                    <BackToPage />
                </HeaderContent>

                <MainContent>
                    <div className="row">
                        <div className="col-12 col-lg-12 px-4">
                            <div className="col-12 col-lg-12">
                                <PageTitleLayout className='pt-2'>
                                    <h5>ثبت فاکتور برگشت از فروش</h5>
                                    <p>
                                        تعداد اعتبار خریداری شده
                                        <strong> {singleInvoice.count} </strong>
                                        و اعتبار فعلی
                                        <strong> {creditCount} </strong>
                                        می‌باشد.
                                    </p>
                                </PageTitleLayout>
                            </div>
                            <hr className="mt-4 mx-3" />
                        </div>
                            <div className="col-12 col-lg-12">
                                {
                                    loading === true && singleInvoice
                                        ? (<Loader/>)
                                        : (
                                            <Formik
                                                initialValues={{
                                                    basePrice: singleInvoice?.basePrice,
                                                    discountPrice: singleInvoice?.discountPrice,
                                                    vatPercent: singleInvoice?.vatPercent,
                                                    creditCount:  singleInvoice.item == 3 ? 1 : "",
                                                    description: singleInvoice?.description,
                                                    maxCount: singleInvoice?.count
                                                }}
                                                // validationSchema={AddReturnInvoiceSchema}
                                                isInitialValid={singleInvoice.item == 3}
                                                validate={(values) => {
                                                    const errors = {};
                                                    if (singleInvoice.item != 3) {
                                                        if (values.creditCount !== null && values.creditCount === null) {
                                                            errors.creditCount = 'این فیلد الزامی است.'
                                                        }
                                                        if (values.creditCount > singleInvoice.count) {
                                                            errors.creditCount = 'اعتبار برگشتی نمی‌تواند از اعتبار خریداری شده بیشتر باشد.'
                                                        }
                                                        if (values.creditCount < 100) {
                                                            errors.creditCount = 'حداقل کریدیت وارد شده 100 واحد می‌باشد.'
                                                        }
                                                    }
                                                    if (values.description === '') {
                                                        errors.description = 'این فیلد الزامی است.'
                                                    }
                                                    return errors;
                                                }}
                                                onSubmit={(values, actions) => {
                                                    let body = {
                                                        "count": values.creditCount ? parseFloat(values.creditCount) : 1,
                                                        "basePrice": singleInvoice.basePrice,
                                                        "discountPrice": singleInvoice.discountPrice,
                                                        "vatPercent": singleInvoice.vatPercent,
                                                        "description": values.description,
                                                        "item": singleInvoice.item
                                                    };

                                                    ApiClient.Post(Urls.AddReturnInvoice(returnObj.userId, returnObj.invoiceId), body)
                                                        .then((res) => {
                                                            toast.success('فاکتور برگشت از فروش با موفقیت ثبت شد.')
                                                            history.goBack();
                                                        })
                                                }}
                                            >

                                                {(formik) => (

                                                    <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                                                        <div className="row">
                                                            <StyledInput
                                                                formGroupClass="col-md-6"
                                                                lable="تعداد"
                                                                focus={true}
                                                                type="text"
                                                                name="creditCount"
                                                                disabled={singleInvoice.item == 3}
                                                                max={singleInvoice.count}
                                                                value={formik.values.creditCount}
                                                                error={(formik.errors.creditCount && formik.touched.creditCount)
                                                                    ? formik.errors.creditCount
                                                                    : 'false'
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue("creditCount", e.target.value)
                                                                }
                                                            />
                                                            <StyledInput
                                                                formGroupClass="col-md-6"
                                                                lable="مبلغ واحد"
                                                                focus={true}
                                                                error="false"
                                                                disabled={true}
                                                                type="text"
                                                                name="basePrice"
                                                                value={formik.values.basePrice}
                                                                onChange={(e) =>
                                                                    formik.setFieldValue("basePrice", e.target.value)
                                                                }
                                                            />
                                                            <StyledInput
                                                                formGroupClass="col-md-6"
                                                                lable="مبلغ تخفیف"
                                                                focus={true}
                                                                error="false"
                                                                disabled={true}
                                                                type="text"
                                                                name="discountPrice"
                                                                value={formik.values.discountPrice}
                                                                onChange={(e) =>
                                                                    formik.setFieldValue("discountPrice", e.target.value)
                                                                }
                                                            />
                                                            <StyledInput
                                                                formGroupClass="col-md-6"
                                                                lable="مالیات برارزش افزوده"
                                                                focus={true}
                                                                error="false"
                                                                disabled={true}
                                                                type="text"
                                                                name="vatPercent"
                                                                value={formik.values.vatPercent}
                                                                onChange={(e) =>
                                                                    formik.setFieldValue("vatPercent", e.target.value)
                                                                }
                                                            />
                                                            <StyledTextArea
                                                                formGroupClass="col-md-12"
                                                                lable="توضیحات"
                                                                focus={true}
                                                                name="description"
                                                                value={formik.values.description}
                                                                error={(formik.errors.description && formik.touched.description)
                                                                    ? formik.errors.description
                                                                    : 'false'
                                                                }
                                                                onChange={(e) => {
                                                                    const str = e.target.value.trim() !== '' ? e.target.value : '';
                                                                    formik.setFieldValue("description", str)
                                                                }}
                                                            />

                                                        </div>

                                                        <StyledButton
                                                            width="100%"
                                                            disabled={!formik.isValid}
                                                            loading={formik.isSubmitting ? 1 : 0}
                                                            type="submit"
                                                        >
                                                            ثبت فاکتور برگشتی
                                                        </StyledButton>
                                                    </StyledForm>
                                                )}
                                            </Formik>
                                        )
                                }
                            </div>
                    </div>

                </MainContent>
            </div>
        </WrapperLayout>

    )
}
