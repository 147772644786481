import {Formik, useFormik} from 'formik';
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import BackToPage from '../../../Component/Kit/back';
import {StyledButton} from '../../../Component/Kit/Button';
import {FormLable, StyledForm, StyledInput, StyledSelect, StyledTextArea} from '../../../Component/Kit/Form';
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from '../../../Component/Kit/Layout';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import TicketDepartmentList from '../../../Utility/Model/TicketDepartmentModel';
import {templateSchema} from '../../../Utility/Validation/Schema/TemplateSchema';
import {AddTicketSchema} from "../../../Utility/Validation/Schema/AddTicketSchema";
import {UploadFileWrqapperAllKindOfFile} from "../../../Component/Kit/UploadZone";


export const AddTemplate = () => {
    const history = useHistory();
    const [inputRef, setInputRef] = useState(null);

    const formik = useFormik({
        initialValues: {
            subject: "",
            department: "",
            longDescription: "",
            templateFile: null,
        },
        validationSchema: templateSchema,
        enableReinitialize: false,

        onSubmit: (values, actions) => {

            const formData = new FormData();
            formData.append('Title', values.subject);
            formData.append('Department', values.department);
            formData.append('Content', values.longDescription);

            if (values.templateFile) formData.append('File', values.templateFile);

            deleteFile(inputRef);
            ApiClient.Post(Urls.TicketAnswerTemplates(), formData)
                .then(saveRes => {

                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    actions.setSubmitting(false);
                    actions.resetForm();
                    history.push("/ticket/template");
                })
                .catch(err => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                })
        }
    });

    const changeFile = (e, isSelfUploaded = false, inputElement = null) => {
        formik.setFieldValue('templateFile', e)

        if(inputElement) setInputRef(inputElement);
    }
    function deleteFile(inputRef) {
        if(inputRef) inputRef.current.value = "";
        formik.setFieldValue('templateFile', null)
    }

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <span className="fakeContainer"></span>
                    <BackToPage toPage="/ticket/template"/>
                </HeaderContent>

                <MainContent>
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout className='pt-2'>
                            <h5>ایجاد قالب تیکت</h5>
                            <p>شما می‌توانید قالب تیکت دلخواه خود را ایجاد کنید.</p>
                        </PageTitleLayout>
                    </div>

                    <hr className="mt-4 mx-3"/>

                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}>
                        <div className="row">
                            <StyledInput
                                formGroupClass="col-lg-6 col-md-6 col-12"
                                lable="عنوان قالب"
                                focus={true}
                                placeholder="عنوان"
                                name="subject"
                                value={formik.values.subject}
                                error={(formik.errors.subject && formik.touched.subject)
                                    ? formik.errors.subject
                                    : 'false'
                                }
                                onChange={(e) => formik.setFieldValue("subject", e.target.value)}
                            />

                            <StyledSelect
                                formGroupClass="col-lg-6 col-md-6 col-12"
                                lable="مربوط به بخش"
                                focus={true}
                                name="department"
                                value={formik.values.department}
                                optionItems={TicketDepartmentList}
                                hasDefaultOption
                                error={(formik.errors.department && formik.touched.department)
                                    ? formik.errors.department
                                    : 'false'
                                }
                                onChange={(e) => formik.setFieldValue("department", e.target.value)}
                            />
                        </div>

                        <div className="row">
                            <StyledTextArea
                                formGroupClass="col-lg-12 col-md-12 col-12"
                                height="20vh"
                                lable="متن قالب"
                                focus={true}
                                name="longDescription"
                                value={formik.values.longDescription}
                                error={(formik.errors.longDescription && formik.touched.longDescription)
                                    ? formik.errors.longDescription
                                    : 'false'
                                }
                                onChange={(e) => {
                                    const str = e.target.value.trim() !== '' ? e.target.value : '';
                                    formik.setFieldValue("longDescription", str)
                                }}
                            />

                            <StyledButton
                                btnStyle="greenBtn topAbsolute"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting ? 1 : 0}
                                type="submit"
                            >
                                افزودن قالب
                            </StyledButton>

                        </div>

                        <div className='col-12 col-md-12 p-0'>

                            <FormLable show={true}>فایل</FormLable>

                            <UploadFileWrqapperAllKindOfFile
                                text="xls, xlsx, jpeg, jpg, JPG, png, zip, rar, txt"
                                type="file"
                                name="templateFile"
                                callbackfunc={changeFile}
                                error={(formik.errors.templateFile && formik.values.templateFile)
                                    ? formik.errors.templateFile
                                    : false
                                }
                                file={formik.values.templateFile?.name}
                                deleteFile={deleteFile}
                            />

                        </div>

                    </StyledForm>

                </MainContent>
            </div>
        </WrapperLayout>
    )
}
