import { Formik } from 'formik';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { bootstrapper } from '../../../Actions/bootstrapper';
import { StyledButton } from '../../../Component/Kit/Button';
import { StyledForm, StyledInput } from '../../../Component/Kit/Form';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { NumericalValidationMultilang } from '../../../Utility/method/NumericalValidationMultilang';
import { ModifyAdminProfileInfoSchema } from '../../../Utility/Validation/Schema/ModifyAdminProfileInfoSchema';

export const ProfileDetails = (props) => {
    const { personalProfile } = props;
    const [loadingFlag, setLoadingFlag] = useState(false);
    const dispatch = useDispatch();

    return (
        <div className="row mt-3 mx-0">
            <div className="col-12 col-lg-12">
                <div className="descripton form-group">
                    <p>در این بخش می‌توانید اطلاعات فردی خود را تغییر دهید. </p>
                </div>
            </div>
            {
                personalProfile && (
                    <div className="col-12 col-lg-12">
                        <Formik
                            initialValues={{
                                firstName: personalProfile.firstName,
                                lastName: personalProfile.lastName,
                                email: personalProfile.email,
                                mobile: personalProfile.mobile ? `0${personalProfile.mobile}` : personalProfile.mobile,
                            }}

                            validationSchema={ModifyAdminProfileInfoSchema}
                            enableReinitialize={true}

                            onSubmit={(values, actions) => {
                                setTimeout(() => { setLoadingFlag(true) }, 40);
                                let body = {
                                    "firstName": values.firstName,
                                    "lastName": values.lastName,
                                    "email": values.email,
                                    "mobile": parseInt(values.mobile),
                                };
                                ApiClient.Put(Urls.ModifyAdminProfileInfo(), body)
                                    .then((res) => {
                                        toast.success('ویرایش  با موفقیت افزوده شد.')
                                        setTimeout(() => { setLoadingFlag(false) }, 50);

                                        let data = {
                                            ...personalProfile,
                                            "firstName": values.firstName,
                                            "lastName": values.lastName,
                                            "email": values.email,
                                            "mobile": parseInt(values.mobile)
                                        }
                                        dispatch(bootstrapper(data));
                                    })
                                    .catch(setLoadingFlag(false))
                            }}
                        >
                            {(formik) => (
                                <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur} className="p-0">
                                    <div className="row">
                                        <StyledInput
                                            formGroupClass="col-lg-6 col-md-6 col-12"
                                            lable="نام"
                                            focus={true}
                                            maxLength="32"
                                            name="firstName"
                                            value={formik.values.firstName !== undefined
                                                ? formik.values.firstName
                                                : formik.initialValues.firstName}
                                            error={(formik.errors.firstName && formik.touched.firstName)
                                                ? formik.errors.firstName
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                                        />

                                        <StyledInput
                                            formGroupClass="col-lg-6 col-md-6 col-12"
                                            lable="نام خانوادگی"
                                            focus={true}
                                            maxLength="32"
                                            name="lastName"
                                            value={formik.values.lastName !== undefined
                                                ? formik.values.lastName
                                                : formik.initialValues.lastName}
                                            error={(formik.errors.lastName && formik.touched.lastName)
                                                ? formik.errors.lastName
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                                        />
                                        <StyledInput
                                            formGroupClass="col-lg-6 col-md-6 col-12"
                                            lable="شماره موبایل "
                                            focus={true}
                                            placeholder=""
                                            name="mobile"
                                            autoComplete="off"
                                            inputMode="numeric"
                                            maxLength="11"
                                            value={formik.values.mobile !== undefined
                                                ? formik.values.mobile
                                                : formik.initialValues.mobile}
                                            error={(formik.errors.mobile && formik.touched.mobile)
                                                ? formik.errors.mobile
                                                : 'false'
                                            }
                                            // onChange={(e) => formik.setFieldValue("mobile", e.target.value)}
                                            onChange={(e) => {
                                                let convertStr;
                                                convertStr = NumericalValidationMultilang(e.target.value);
                                                if (convertStr === '') {
                                                    formik.setFieldValue("mobile", "");
                                                    return;
                                                }

                                                if (convertStr) {
                                                    formik.setFieldValue("mobile", convertStr)
                                                } else {
                                                    return;
                                                }
                                            }}
                                        />
                                        <StyledInput
                                            formGroupClass="col-lg-6 col-md-6 col-12"
                                            lable="ایمیل "
                                            focus={true}
                                            name="email"
                                            value={formik.values.email !== undefined
                                                ? formik.values.email
                                                : formik.initialValues.email}
                                            error={(formik.errors.email && formik.touched.email)
                                                ? formik.errors.email
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("email", e.target.value)}
                                        />
                                    </div>

                                    <StyledButton
                                        width="100%"
                                        disabled={!formik.isValid || !formik.dirty}
                                        loading={loadingFlag}
                                        type="submit"
                                        btnStyle="darkGreenBtn"
                                    >
                                        ویرایش اطلاعات
                                    </StyledButton>
                                </StyledForm>

                            )}
                        </Formik>

                    </div>
                )
            }
        </div>
    )
}
