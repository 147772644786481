import { Formik } from "formik";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { toast } from "react-toastify";
import { FormLable, StyledForm, StyledInput, StyledSelect, StyledSwitchBox } from "../../../../Component/Kit/Form";
import { StyledButton } from "../../../../Component/Kit/Button";
import React, { useEffect, useState } from "react";
import SystemLineType from "../../Models/SystemLineType.model";
import Select from "react-select";
import { SystemLineProviderEnum } from "../../../../Utility/Model/provider-enum.model";
import { SystemLineSchema } from "../../../../Utility/Validation/Schema/SystemLineSchema";

const ProviderItems = [{ name: "انتخاب کنید", value: null }, ...SystemLineProviderEnum]

function SystemModal({ data, onCloseModal, onSubmit, fromLineOptions }) {
    const [formFields, setFormFields] = useState({})
    useEffect(() => {
        if (data) {
            setFormFields({
                fromLineNumber: '',
                isActive: data.isActive,
                lineNumber: data?.number ? data.number : '',
                type: data?.systemLines ? data.systemLines : '',
                providerId: data?.providerId ? data.providerId : '',
            })
        } else {
            setFormFields({
                lineNumber: '',
                type: '',
                providerId: '',
            })
        }
    }, [data])
    return (
        <>
            <Formik
                initialValues={formFields}
                validationSchema={data ? null : SystemLineSchema}
                enableReinitialize={true}
                onSubmit={(values, actions) => {

                    let number = 0
                    values.type.forEach(el => {
                        number += el.value
                    })

                    if (data) {
                        ApiClient.Patch(Urls.updateSystemLine(data.id), { ...values, type: number })
                            .then((res) => {
                                toast.success('عملیات با موفقیت انجام شد');
                                actions.resetForm();
                                onCloseModal(false)
                                onSubmit()
                            }).catch().finally(() => { actions.setSubmitting(false) })
                    } else {
                        ApiClient.Post(Urls.createSystemLine(), { ...values, type: number })
                            .then((res) => {
                                toast.success('عملیات با موفقیت انجام شد');
                                actions.resetForm();
                                onCloseModal(false)
                                onSubmit()
                            }).catch().finally(() => { actions.setSubmitting(false) })
                    }
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}>
                        {data &&
                            <StyledSelect
                                formGroupClass="w-100 my-3"
                                lable="انتخاب خط مبدا"
                                focus={true}
                                name="fromLineNumber"
                                value={formik.values.fromLineNumber !== undefined
                                    ? formik.values.fromLineNumber
                                    : formik.initialValues.fromLineNumber}
                                optionItems={fromLineOptions}
                                error={(formik.errors.fromLineNumber && formik.touched.fromLineNumber)
                                    ? formik.errors.fromLineNumber
                                    : 'false'
                                }
                                onChange={(e) => {
                                    formik.setFieldValue("fromLineNumber", e.target.value);
                                    formik.setFieldValue('type', fromLineOptions?.find(a => a.value == e.target.value)?.systemLines)
                                }
                                }
                            />
                        }
                        <StyledInput
                            lable="شماره خط"
                            focus={true}
                            type="text"
                            name="lineNumber"
                            value={formik.values.lineNumber}
                            error={(formik.errors.lineNumber && formik.touched.lineNumber)
                                ? formik.errors.lineNumber
                                : 'false'
                            }
                            onChange={(e) =>
                                formik.setFieldValue("lineNumber", e.target.value)
                            }
                        />
                        <div className="w-100 my-3">
                            <FormLable show={true}> مربوط به بخش </FormLable>
                            <Select
                                name="type"
                                value={formik.values.type !== null
                                    ? formik.values.type
                                    : formik.initialValues.type}
                                error={(formik.errors.type && formik.touched.type)
                                    ? formik.errors.type
                                    : 'false'
                                }
                                getOptionLabel={option => option['name']}
                                isMulti
                                options={SystemLineType}
                                onChange={(e) => {
                                    formik.setFieldValue("type", e)
                                }}
                            />
                            {
                                <div
                                    className="jseBfC d-block">{formik.errors.type}</div>
                            }
                        </div>

                        <StyledSelect
                            formGroupClass="w-100 my-3"
                            lable="ارائه دهنده خدمات"
                            focus={true}
                            name="providerId"
                            value={formik.values.providerId !== undefined
                                ? formik.values.providerId
                                : formik.initialValues.providerId}
                            optionItems={SystemLineProviderEnum}
                            hasDefaultOption
                            error={(formik.errors.providerId && formik.touched.providerId)
                                ? formik.errors.providerId
                                : 'false'
                            }
                            onChange={(e) => formik.setFieldValue("providerId", e.target.value)}
                        />

                        {data &&
                            <div className="d-flex align-items-center my-4">
                                <p className="font-weight-bold ml-3 mb-0">وضعیت خط</p>
                                <StyledSwitchBox
                                    focus={true}
                                    checked={formik.values.isActive}
                                    onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                                />
                            </div>
                        }



                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting ? 1 : 0}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                اضافه کردن
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    );
}
export default SystemModal
