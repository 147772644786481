import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import BackToPage from '../../../Component/Kit/back'
import { StyledButton } from '../../../Component/Kit/Button'
import { StyledForm, StyledInput, StyledSelect, StyledSwitchBox } from '../../../Component/Kit/Form'
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import {PlanConfigurationsList, PlanItemLimitationModel} from '../../../Utility/Model/PlanItemLimitation.model'
import { SystemLineProviderEnum } from '../../../Utility/Model/provider-enum.model'
import { PlanSectionStyled } from '../style'

const monthOption =  [
    { value: 1, name: 'یک ماهه'},
    { value: 3, name: 'سه ماهه'},
    { value: 6, name: 'شش ماهه'},
    { value: 12, name: 'یکساهه'}
]
function Repeat(props) {
    let items = [];
    for (let i = 0; i < props.numTimes; i++) {
        items.push(props.children(i));
    }
    return <>{items}</>;
}

const PlanFree = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const [planDetail, setPlanDetail] = useState({});
    const [planConfigurations, setPlanConfigurations] = useState({});
    const [hasLine, setHasLine] = useState(false);
    const [hasFreeCredit, setHasFreeCredit] = useState(false);
    const planLimitationItemName = {
        name: 'نام قابلیت',
        isActive: 'وضعیت',
        limitationCount: 'محدودیت‌ها',
    }

    useEffect(() => {
            ApiClient.Get(Urls.PlanFree())
                .then((res) => {
                    setPlanDetail(res.result);
                    setHasLine(res.result.line?.providerId)
                    setHasFreeCredit(res.result.credit?.basePrice)
                })
    }, [match.params.id])

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <span className="fakeContainer"></span>
                    <BackToPage />
                </HeaderContent>
            </div>

            <MainContent>
                <div className="col-12 col-lg-12">
                    <div className="row">
                        <div className="col-12 col-lg-12 mt-3">
                            <PageTitleLayout>
                                <h5>جزئیات پلن رایگان</h5>
                            </PageTitleLayout>
                            <hr className="mt-4 mx-1" />
                        </div>
                    </div>

                    {/* <div className="row">
                    <div className="col-12 col-lg-12"> */}
                    <Formik
                        initialValues={{
                            planIsActive: true,
                            planName: planDetail.name || '',
                            planPosition: planDetail.position || '',
                            planCreditCount: planDetail.credit?.creditCount || '',
                            planCreditBasePrice: planDetail.credit?.basePrice || '',
                            planProviderId: planDetail.line?.providerId || '',
                            planNumberLength: planDetail.line?.numberLength || '',
                            month : planDetail.month,
                            planLimitationItem: planDetail.items || Array(30).fill().map((f, i) => ({ type: i + 1, limitationCount: null, isActive: false }))
                            // Array.from({length: Math.floor(5)}, (_value, index) => ({type: index+1 , limitationCount: null, isActive: false}))
                        }}
                        validateOnMount={true}
                        enableReinitialize={true}
                        // validationSchema={ModifyPlanItemDetailSchema}
                        onSubmit={(values, actions) => {
                            const payload = {
                                name: values.planName,
                                line: hasLine && values.planProviderId && values.planNumberLength
                                    ? {
                                        providerId: Number(values.planProviderId),
                                        numberLength: Number(values.planNumberLength),
                                    }
                                    : null,
                                credit: hasFreeCredit && values.planCreditCount && values.planCreditBasePrice
                                    ? {
                                        creditCount: Number(values.planCreditCount),
                                        basePrice: Number(values.planCreditBasePrice),
                                    }
                                    : null,
                                month: values.month,
                                items: values.planLimitationItem,
                            }
                            ApiClient.Put(Urls.PlanFree(), JSON.stringify(payload))
                                .then(() => {
                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                    history.goBack();
                                })

                        }}
                    >
                        {(formik) => (
                            <StyledForm className="px-0"
                                onSubmit={formik.handleSubmit}
                                onBlur={formik.handleBlur}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <PlanSectionStyled>
                                            <div className="row">
                                                <div className="col-12 col-md-4">
                                                    <div className="d-flex col-12 mb-3 align-items-center">
                                                        <strong className="ml-2 font-12">وضعیت پلن: </strong>
                                                        <StyledSwitchBox
                                                            disabled={true}
                                                            checked={formik.values.planIsActive}
                                                            onChange={(e) => formik.setFieldValue("planIsActive", e.target.value)}
                                                        />
                                                    </div>
                                                    <StyledInput
                                                        formGroupClass="col-12"
                                                        lable="عنوان پلن"
                                                        focus={true}
                                                        placeholder="عنوان پلن"
                                                        name="planName"
                                                        value={formik.values.planName !== undefined
                                                            ? formik.values.planName
                                                            : formik.initialValues.planName}
                                                        error={(formik.errors.planName && formik.touched.planName)
                                                            ? formik.errors.planName
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planName", e.target.value)}
                                                    />
                                                    <StyledSelect
                                                        formGroupClass="col-12"
                                                        lable="ماه"
                                                        focus={true}
                                                        name="month"
                                                        value={formik.values.month !== undefined
                                                            ? formik.values.month
                                                            : formik.initialValues.month}
                                                        optionItems={monthOption}
                                                        hasDefaultOption
                                                        error={(formik.errors.month && formik.touched.month)
                                                            ? formik.errors.month
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("month", e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-4">
                                                    <div className="d-flex col-12 mb-3 align-items-center">
                                                        <strong className="ml-2 font-12">خط مجزا دارد: </strong>
                                                        <StyledSwitchBox
                                                            checked={hasLine}
                                                            onChange={(e) => setHasLine(!hasLine)} />
                                                    </div>
                                                    <StyledInput
                                                        formGroupClass="col-12"
                                                        lable="تعداد ارقام خط"
                                                        focus={true}
                                                        type="number"
                                                        placeholder="تعداد ارقام خط"
                                                        name="planNumberLength"
                                                        value={formik.values.planNumberLength !== undefined
                                                            ? formik.values.planNumberLength
                                                            : formik.initialValues.planNumberLength}
                                                        error={(formik.errors.planNumberLength && formik.touched.planNumberLength)
                                                            ? formik.errors.planNumberLength
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planNumberLength", e.target.value)}
                                                        disabled={!hasLine}
                                                    />
                                                    <StyledSelect
                                                        formGroupClass="col-12"
                                                        lable="ارائه دهنده خدمات"
                                                        focus={true}
                                                        name="planProviderId"
                                                        value={formik.values.planProviderId !== undefined
                                                            ? formik.values.planProviderId
                                                            : formik.initialValues.planProviderId}
                                                        optionItems={SystemLineProviderEnum}
                                                        hasDefaultOption
                                                        error={(formik.errors.planProviderId && formik.touched.planProviderId)
                                                            ? formik.errors.planProviderId
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planProviderId", e.target.value)}
                                                        disabled={!hasLine}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-4">
                                                    <div className="d-flex col-12 mb-3 align-items-center">
                                                        <strong className="ml-2 font-12">اعتبار رایگان دارد: </strong>
                                                        <StyledSwitchBox
                                                            checked={hasFreeCredit}
                                                            onChange={(e) => setHasFreeCredit(!hasFreeCredit)} />
                                                    </div>
                                                    <StyledInput
                                                        formGroupClass="col-12"
                                                        lable="تعداد اعتبار رایگان"
                                                        focus={true}
                                                        placeholder="تعداد اعتبار رایگان"
                                                        name="planCreditCount"
                                                        value={formik.values.planCreditCount !== undefined
                                                            ? formik.values.planCreditCount
                                                            : formik.initialValues.planCreditCount}
                                                        error={(formik.errors.planCreditCount && formik.touched.planCreditCount)
                                                            ? formik.errors.planCreditCount
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planCreditCount", e.target.value)}
                                                        disabled={!hasFreeCredit}
                                                    />
                                                    <StyledInput
                                                        formGroupClass="col-12"
                                                        lable="تعرفه اعتبار رایگان"
                                                        focus={true}
                                                        name="planCreditBasePrice"
                                                        value={formik.values.planCreditBasePrice !== undefined
                                                            ? formik.values.planCreditBasePrice
                                                            : formik.initialValues.planCreditBasePrice}
                                                        error={(formik.errors.planCreditBasePrice && formik.touched.planCreditBasePrice)
                                                            ? formik.errors.planCreditBasePrice
                                                            : 'false'
                                                        }
                                                        onChange={(e) => formik.setFieldValue("planCreditBasePrice", e.target.value)}
                                                        disabled={!hasFreeCredit}
                                                    />
                                                </div>
                                            </div>
                                        </PlanSectionStyled>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="my-3">ایجاد محدودیت‌ها: </h5>

                                        <div className="table-responsive custom-table planLimitation-table">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" key={`headLine_#`}>#</th>
                                                        {
                                                            planLimitationItemName &&
                                                            Object.entries(planLimitationItemName).map(([item, value]) => (
                                                                <th scope="col" key={`headLine_${item}`}>{value}</th>
                                                            ))
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* <pre>{JSON.stringify(formik.values.planLimitationItem)}</pre> */}
                                                    {/* {(formik.values.planLimitationItem || formik.initialValues.planLimitationItem ) && PlanItemLimitationModel.map((dataItem, index) => ( */}
                                                    {(planDetail.items || formik.initialValues.planLimitationItem) && PlanItemLimitationModel.map((dataItem, index) => (
                                                        <>
                                                            <tr key={`planLimitationItemـ${dataItem.value}`}>
                                                                <th scope="row">{dataItem.value}</th>
                                                                <td>
                                                                    {dataItem.name}
                                                                </td>
                                                                <td>
                                                                    {formik.values.planLimitationItem[index] &&
                                                                        <StyledSwitchBox
                                                                            checked={formik.values.planLimitationItem[index].isActive !== undefined
                                                                                ? formik.values.planLimitationItem[index].isActive
                                                                                : formik.initialValues.planLimitationItem[index].isActive}
                                                                            onChange={(e) => formik.setFieldValue(`planLimitationItem.${index}.isActive`, e.target.checked)}
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {PlanConfigurationsList[dataItem.key] ? (
                                                                        <StyledInput
                                                                            formGroupClass="planTierInput"
                                                                            type="number"
                                                                            focus={false}
                                                                            name={`planLimitationCount_${index}`}
                                                                            id={`planLimitationCount_${index}`}
                                                                            key={`planLimitationCount_${index}`}
                                                                            value={formik.values.planLimitationItem[index].limitationCount !== undefined
                                                                                ? formik.values.planLimitationItem[index].limitationCount
                                                                                : formik.initialValues.planLimitationItem[index].limitationCount}
                                                                            error={(formik.touched[`planLimitationCount_${index}`] && !formik.values.planLimitationItem[index].limitationCount) ? 'این فیلد الزامی است.' : 'false'}
                                                                            onChange={(e) => formik.setFieldValue(`planLimitationItem.${index}.limitationCount`, Number(e.target.value))}
                                                                            disabled={!formik.values.planLimitationItem[index].isActive}
                                                                        />
                                                                    ) : <span>&nbsp;&nbsp;&nbsp; --- </span>}
                                                                </td>
                                                            </tr>
                                                            <tr className="spacer" key={`spacer${dataItem.value}`}>
                                                                <td colSpan="100"></td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                                <div className="mt-4 d-flex justify-content-between">
                                    <StyledButton
                                        width="100%"
                                        btnStyle="greenBtn"
                                        type="submit"
                                    >
                                        تایید
                                    </StyledButton>
                                </div>
                            </StyledForm>
                        )}
                    </Formik>
                </div>
            </MainContent>
        </WrapperLayout>
    )
}

export default PlanFree