import * as Yup from "yup";
import {ipAddress, mobile, suspendDescription} from '../Rules';

export const AddBlockedIPSchema = Yup.object().shape({
    ipAddress
})

export const AddBlockedNumberSchema = Yup.object().shape({
    mobile,
    suspendDescription
})