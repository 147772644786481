import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PageTitleLayout } from "../../../../Component/Kit/Layout";
import Table from "../../../../Component/Shared/Table/Table";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { ConvertQueryString } from "../../../../Utility/Services/convert-query-string";
import DateConvert from "../../../../Utility/Services/date-convert";
import GetProvider from "../../../../Utility/Services/provider";
import AssignPlanLineModal from "./AssignPlanLineModal";

const UserPendingForPlanLine = () => {
  const location = useLocation();
  const [usersList, setUsersList] = useState();
  const [userId, setUserId] = useState();
  const [isAssignModal, setIsAssignModal] = useState(false);
  const tableConfig = {
    header: {
      userId: "شناسه کاربر",
      providerName: "نام اپراتور",
      numberLength: "طول خط",
      handledDateTime: "زمان تحویل خط",
      numberText: "شماره خط",
      createdAt: "تاریخ و زمان ایجاد",
    },
    actions: {
      edit: {
        icon: "fa fa-edit",
        condition: (data) => !data.number && !data.handleAt,
      },
    },
    mode: ["filter"],
    filters: ["isHandled"],
  };

  const onInit = useCallback(
    (onlyHandled = null) => {
      ApiClient.Get(
        Urls.plansLinesPendingUser(), ConvertQueryString(location.search)
      ).then((res) => {
        let data = res.result;
        data.items.map((m) => {
          m.providerName = GetProvider(m.providerId);
          m.handledDateTime = m.handledAt ? DateConvert(m.handledAt) : "----";
          m.numberText = m.number || "----";
          m.createdAt = DateConvert(m.createdAt);
          return m;
        });
        setUsersList(res.result);
      });
    },
    [location.search]
  );

  const tableActionsHandler = (item) => {
    if (item.actionName === "edit") {
      setUserId(item.data.id);
      setIsAssignModal(true);
    }
  };

  const confirmLines = () => {
    onInit();
  };

  useEffect(() => {
    onInit();
  }, [onInit]);

  return (
    <div>
      {usersList && (
        <>
          <PageTitleLayout>
            <h5>کاربران در انتظار خط</h5>
            <span>
              لیستی از کاربرانی که پلن خریداری کرده و خطی تحویل نگرفته اند در
              این جدول قابل بررسی است.
            </span>
          </PageTitleLayout>

          <Table
            data={usersList.items}
            config={tableConfig}
            pageConfig={{
              pageNumber: usersList.pageNumber,
              pageSize: usersList.pageSize,
              totalItemsCount: usersList.totalItemsCount,
              totalPagesCount: usersList.totalPagesCount,
            }}
            actionDispatched={(e) => tableActionsHandler(e)}
          />
        </>
      )}
      <AssignPlanLineModal
        visible={isAssignModal}
        closeHandler={() => setIsAssignModal(false)}
        confirmHandler={() => confirmLines()}
        userId={userId}
      />
    </div>
  );
};

export default UserPendingForPlanLine;
