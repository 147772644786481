import * as Yup from "yup";
import {ValidNationalId} from "persian-national-id";

const NumberRegExp = /^\d+$/;
const NumberBiggestZeroRegExp = /^[1-9]\d*$/;
const IPRegExp =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

const NotLatinRegex = /^[^a-zA-Z]*$/;
export const nationalId = Yup.string()
    .label("nationalCode")
    .required("این فیلد الزامی است.")
    .test("", "کد ملی معتبر نیست.", (value) => ValidNationalId(value));

export const nationalIdCompany = Yup.string()
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .min(11, "شناسه ملی نمی تواند کمتر از 11 کاراکتر باشد.")
    .max(11, "شناسه ملی نمی تواند بیشتر از 11 کاراکتر باشد..")
    .label("nationalIdCompany")
    .required("این فیلد الزامی است.");

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneNumber = Yup.string()
    .matches(phoneRegExp, "شماره تلفن معتبر نیست.")
    .min(7, "شماره تلفن نمی تواند کمتر از 7 کاراکتر باشد.")
    .max(11, "شماره تلفن نمی تواند بیشتر از 11 کاراکتر باشد.")
    .label("phoneNumber")
    .required("این فیلد الزامی است.");

export const userPhoneNumber = Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(7, "شماره موبایل نمی تواند کمتر از 7 کاراکتر باشد.")
    .max(11, "شماره موبایل نمی تواند بیشتر از 11 کاراکتر باشد.")
    .label("userPhoneNumber")
    .required("این فیلد الزامی است.");

// const mobileRegExp = /^(|[1-9]\d*)$/;
const mobileRegExp =
    /^09(0[0-9]|1[0-9]|2[0-9]|3[0-9]|41|9[0-9])-?[0-9]{3}-?[0-9]{4}/;
export const mobile = Yup.string()
    .matches(mobileRegExp, "شماره موبابل معتبر وارد نمایید.")
    .min(11, "شماره موبایل نمی تواند کمتر از 11 کاراکتر باشد.")
    .max(11, "شماره موبایل نمی تواند بیشتر از 11 کاراکتر باشد.")
    .label("mobile")
    .required("این فیلد الزامی است.");

export const otpCode = Yup.string()
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .min(6, "کد نمی‌تواند کمتر از 6 کاراکتر ‌باشد.")
    .max(6, "کد نمی‌تواند بیشتر از 6 کاراکتر ‌باشد.")
    .label("otpCode")
    .required("این فیلد الزامی است.");

export const username = Yup.string()
    .min(6, "حداقل کاراکتر مجاز 6 می باشد")
    .max(256, "نام‌کاربری نمی تواند بیشتر از 256 کاراکتر باشد.")
    .label("username")
    .required("این فیلد الزامی است.");

export const password = Yup.string()
    .min(8, "حداقل کاراکتر مجاز 8 می باشد")
    //.max(10, "رمزعبور نمی تواند بیشتر از 10 کاراکتر باشد.")
    .label("password")
    .required("این فیلد الزامی است.");

export const setPassword = Yup.string()
    .min(8, "حداقل کاراکتر مجاز 8 می باشد")
    .label("setPassword")
    .required("این فیلد الزامی است.");

export const oldPassword = Yup.string()
    .label("oldPassword")
    .min(8, "حداقل کاراکتر مجاز 8 می باشد")
    .required("این فیلد الزامی است.");

export const newPassword = Yup.string()
    .label("newPassword")
    .min(8, "حداقل کاراکتر مجاز 8 می باشد")
    .required("این فیلد الزامی است.");

export const repeatPassword = Yup.string()
    .label("repeatPassword")
    .oneOf([Yup.ref("newPassword"), null], "عدم تطابق با رمز عبور")
    .min(8, "حداقل کاراکتر مجاز 8 می باشد")
    .required("این فیلد الزامی است.");

export const subject = Yup.string()
    .max(32, "حداکثر کاراکتر مجاز 32 می باشد")
    .label("subject")
    .required("این فیلد الزامی است.");

export const description = Yup.string()
    .label("description")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .max(512, "توضیحات نمی تواند بیشتر از 512 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const longDescription = Yup.string()
    .label("longDescription")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .required("این فیلد الزامی است.");

export const suspendDescription = Yup.string()
    .label("description")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .max(512, "توضیحات نمی تواند بیشتر از 512 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const fastSendTemplateRejectDescription = Yup.string()
    .label("description")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .max(512, "توضیحات نمی تواند بیشتر از 512 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const commentDescription = Yup.string()
    .label("description")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .max(512, "توضیحات نمی تواند بیشتر از 512 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const keyWord = Yup.string()
    .label("keyWord")
    .required("این فیلد الزامی است.");

export const isCancellation = Yup.string()
    .label("isCancellation")
    .required("این فیلد الزامی است.");

export const forbiddenWords = Yup.string()
    .label("words")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .max(32, "کلمه ممنوعه نمیتواند بیش از 32 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const notificationTitle = Yup.string()
    .label("notificationTitle")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .max(32, "نام اعلان نمی تواند بیشتر از 32 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const notificationContent = Yup.string()
    .label("notificationContent")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .max(512, "توضیحات نمی تواند بیشتر از 512 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const department = Yup.string()
    .label("department")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const priority = Yup.string()
    .label("priority")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const outcome = Yup.string()
    .label("outcome")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const ticketContent = Yup.string()
    .label("ticketContent")
    .required("این فیلد الزامی است.");

export const firstName = Yup.string()
    .min(2, "نام نمی‌تواند کمتر از 2 کاراکتر باشد.")
    .max(32, "حداکثر کاراکتر مجاز 32 می باشد")
    .label("firstName")
    .required("این فیلد الزامی است.");

export const lastName = Yup.string()
    .min(2, "نام خانوادگی نمی‌تواند کمتر از 2 کاراکتر باشد.")
    .max(32, "حداکثر کاراکتر مجاز 32 می باشد")
    .label("lastName")
    .required("این فیلد الزامی است.");

export const fatherName = Yup.string()
    .min(2, "نام پدر نمی‌تواند کمتر از 2 کاراکتر باشد.")
    .max(32, "حداکثر کاراکتر مجاز 32 می باشد")
    .label("fatherName")
    .required("این فیلد الزامی است.");

export const certificateNo = Yup.string()
    .label("certificateNo")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .min(1, "حداقل تعداد مجاز کاراکتر 1 می‌باشد.")
    .max(10, "حداکثر تعداد مجاز کاراکتر 10 می‌باشد.")
    .required("این فیلد الزامی است.");

export const birthDate = Yup.string()
    .label("birthDate")
    .required("این فیلد الزامی است.");

export const address = Yup.string()
    .label("address")
    .required("این فیلد الزامی است.");

export const sendMessage = Yup.string()
    .label("sendMessage")
    .required("این فیلد الزامی است.");

export const userIsVip = Yup.boolean().label("userIsVip");

export const postalCode = Yup.string()
    .matches(phoneRegExp, "کدپستی معتبر نیست")
    .min(10, "کدپستی نمی تواند کمتر از 10 رقم باشد.")
    .max(10, "کدپستی نمی تواند بیشتر از 10 رقم باشد.")
    .label("postalCode")
    .required("این فیلد الزامی است.");

// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/JPG", "image/png", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/x-zip-compressed", ".rar"];
const SUPPORTED_FORMATS = [
    "jpg",
    "jpeg",
    "JPG",
    "png",
    "xlsx",
    "xls",
    "zip",
    "rar",
    "txt"
];
const maxFileSize = 5 * 1024 * 1024;
export const ticketFile = Yup.mixed()
    .label("ticketFile")
    .test("ticketFile", "حجم فایل باید کمتر از 5 مگ باشد", function (value) {
        if (!!value) {
            return value.size <= maxFileSize ? value.size <= maxFileSize : false;
        }
        return true;
    })
    .test("ticketFile", "فرمت فایل ارسالی پشتیبانی نمی‌شود.", function (value) {
        if (!!value) {
            var ext = value.name.split(".");
            return SUPPORTED_FORMATS.includes(ext[ext.length - 1]);
        }
        return true;
    });

/* Modify config setting Modal */
export const changeDepartment = Yup.string().label("changeDepartment");

export const status = Yup.string().label("status");
//.required("لطفاً یک گزینه را انتخاب نمایید.");

export const uploadable = Yup.string().label("uploadable");
//
export const proformaDueDate = Yup.string().required("این فیلد الزامی است.");

export const proformaDescription = Yup.string()
    .label("proformaDescription")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .max(512, "توضیحات نمی تواند بیشتر از 512 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const proformaFinalPrice = Yup.string()
    .label("proformaFinalPrice")
    .nullable()
    .max(10, "مبلغ نمی تواند بیشتر از 10 رقم باشد.")
    .required("این فیلد الزامی است.")
    .test(
        "",
        "عدد مورد نظر باید بین 1 و 2,100,000,000 ریال باشد.",
        (value) => value > 0 && value <= 2100000000
    );
export const proformaBasePrice = Yup.string()
    .label("proformaBasePrice")
    .nullable()
    .required("این فیلد الزامی است.")
    .test("", "عدد مورد نظر باید بزرگتر صفر باشد.", (value) => value > 0);

export const invoiceNumber = Yup.string()
    .min(1, "حداقل تعداد مجاز کاراکتر 1 می‌باشد.")
    .max(10, "حداکثر تعداد مجاز کاراکتر 10 می‌باشد.")
    .label("invoiceNumber")
    .required("این فیلد الزامی است.");

export const city = Yup.object().label("city").required("این فیلد الزامی است.");

export const invoiceDate = Yup.string()
    .label("invoiceDate")
    .required("این فیلد الزامی است.");

export const maxCount = Yup.string().label("maxCount");
export const count = Yup.string().label("count").required("این فیلد الزامی است.");
export const item = Yup.string().label("item").required("این فیلد الزامی است.");
export const discountPrice = Yup.string().label("discountPrice").required("این فیلد الزامی است.");

const floatNumberRegExp = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
export const creditCount = Yup.string()
    .matches(floatNumberRegExp, "تعداد وارد شده مجاز نیست.")
    .min(3, "حداقل کریدیت وارد شده 100 واحد می‌باشد.")
    .max(6, "این مقدار بیشتر از حد مجاز می‌باشد.")
    .label("creditCount")
    .required("این فیلد الزامی است.");
// .oneOf([Yup.ref('maxCount'), null], 'عدم تطابق با رمز عبور')
// .test('', 'lklk', (value) => console.log(Yup.object()) );

export const basePrice = Yup.string()
    .label("basePrice")
    .required("این فیلد الزامی است.");

export const lineProfitPercent = Yup.number()
    .label("lineProfitPercent")
    .required("این فیلد الزامی است.")
    .test(
        "",
        "درصد وارد شده نمی تواند از 100 بیشتر ‌باشد.",
        (value) => value <= 100
    )
    .test("", "درصد وارد شده نمی تواند از 0 کمتر ‌باشد.", (value) => value >= 0);

export const creditProfitPercent = Yup.number()
    .label("creditProfitPercent")
    .required("این فیلد الزامی است.")
    .test(
        "",
        "درصد وارد شده نمی تواند از 100 بیشتر ‌باشد.",
        (value) => value <= 100
    )
    .test("", "درصد وارد شده نمی تواند از 0 کمتر ‌باشد.", (value) => value >= 0);

export const roleItems = Yup.array()
    .label("roleItems")
    .required("این فیلد الزامی است.");

export const roleId = Yup.string()
    .label("roleId")
    .required("این فیلد الزامی است.");

export const suspended = Yup.string().label("suspended");

const emailRegExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const email = Yup.string()
    .matches(emailRegExp, "ایمیل معتبر وارد نمایید")
    .max(128, "حداکثر تعداد مجاز کاراکتر 128 می‌باشد.")
    .label("email")
    .required("این فیلد الزامی است.");

//setting
export const numberLimitation = Yup.string()
    .label("numberLimitation")
    .required("این فیلد الزامی است.");

export const smsContent = Yup.string()
    .label("smsContent")
    .required("این فیلد الزامی است.");

export const giftLineProvider = Yup.string()
    .label("giftLineProvider")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const lineNumber = Yup.string()
    .label("lineNumber")
    .required("این فیلد الزامی است.");

export const type = Yup.string()
    .label("type")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const providerId = Yup.string()
    .label("providerId")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const fromLineNumber = Yup.string()
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .label("fromLineNumber")
    .required("این فیلد الزامی است.");

export const toLineNumber = Yup.string()
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .label("toLineNumber");

// guest
export const guestFirstName = Yup.string()
    .label("guestFirstName")
    .min(1, "حداقل تعداد مجاز کاراکتر 1 می‌باشد.")
    .max(32, "حداکثر تعداد مجاز کاراکتر 32 می‌باشد.")
    .required("این فیلد الزامی است.");

export const guestLastName = Yup.string()
    .label("guestLastName")
    .min(1, "حداقل تعداد مجاز کاراکتر 1 می‌باشد.")
    .max(32, "حداکثر تعداد مجاز کاراکتر 32 می‌باشد.")
    .required("این فیلد الزامی است.");

export const guestFatherName = Yup.string()
    .label("guestFatherName")
    .min(1, "حداقل تعداد مجاز کاراکتر 1 می‌باشد.")
    .max(32, "حداکثر تعداد مجاز کاراکتر 32 می‌باشد.")
    .required("این فیلد الزامی است.");

export const addLineNumber = Yup.string()
    .label("addLineNumber")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .max(12, "حداکثر تعداد مجاز کاراکتر 12 می‌باشد.")
    .required("این فیلد الزامی است.");

export const guestCertificateNo = Yup.string()
    .label("guestCertificateNo")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .min(1, "حداقل تعداد مجاز کاراکتر 1 می‌باشد.")
    .max(10, "حداکثر تعداد مجاز کاراکتر 10 می‌باشد.")
    .required("این فیلد الزامی است.");

export const guestBirthDate = Yup.string()
    .label("guestBirthDate")
    .required("این فیلد الزامی است.");

export const guestPhoneNumber = Yup.string()
    .label("guestPhoneNumber")
    .min(7, "حداقل تعداد مجاز کاراکتر 7 می‌باشد.")
    .max(12, "حداکثر تعداد مجاز کاراکتر 12 می‌باشد.")
    .required("این فیلد الزامی است.");

export const guestPostalCode = Yup.string()
    .matches(phoneRegExp, "کدپستی معتبر نیست")
    .min(10, "کدپستی باید 10 رقم باشد.")
    .max(10, "کدپستی نمی تواند بیشتر از 10 رقم باشد.")
    .label("guestPostalCode")
    .required("این فیلد الزامی است.");

export const guestAddress = Yup.string()
    .label("guestAddress")
    .min(1, "حداقل تعداد مجاز کاراکتر 1 می‌باشد.")
    .required("این فیلد الزامی است.");

export const guestCompanyActivityField = Yup.string()
    .label("guestCompanyActivityField")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const companyActivityField = Yup.string()
    .label("companyActivityField")
    .required("لطفاً یک گزینه را انتخاب نمایید.");
export const guestCompanyName = Yup.string()
    .label("guestCompanyName")
    .min(1, "حداقل تعداد مجاز کاراکتر 1 می‌باشد.")
    .max(100, "حداکثر تعداد مجاز کاراکتر 100 می‌باشد.")
    .nullable()
    .required("این فیلد الزامی است.");

export const companyName = Yup.string()
    .label("guestCompanyName")
    .min(1, "حداقل تعداد مجاز کاراکتر 1 می‌باشد.")
    .max(100, "حداکثر تعداد مجاز کاراکتر 100 می‌باشد.")
    .nullable()
    .required("این فیلد الزامی است.");

export const registrationNumber = Yup.string()
    .label("guestRegistrationNumber")
    .matches(NotLatinRegex, "کاراکتر انگلیسی مجاز نمیباشد.")
    .min(2, "حداقل تعداد مجاز کاراکتر 2 می‌باشد.")
    .max(13, "حداکثر تعداد مجاز کاراکتر 13 می‌باشد.")
    .nullable()
    .required("این فیلد الزامی است.");

export const guestRegistrationNumber = Yup.string()
    .label("guestRegistrationNumber")
    .matches(NotLatinRegex, "کاراکتر انگلیسی مجاز نمیباشد.")
    .min(2, "حداقل تعداد مجاز کاراکتر 2 می‌باشد.")
    .max(13, "حداکثر تعداد مجاز کاراکتر 13 می‌باشد.")
    .nullable()
    .required("این فیلد الزامی است.");

export const guestLegalNationalId = Yup.string()
    .label("guestLegalNationalId")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .min(11, "حداقل تعداد مجاز کاراکتر 11 می‌باشد.")
    .max(11, "حداکثر تعداد مجاز کاراکتر 11 می‌باشد.")
    .nullable()
    .required("این فیلد الزامی است.");

export const economicCode = Yup.string()
    .label("economicCode")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .min(11, "حداقل تعداد مجاز کاراکتر 11 می‌باشد.")
    .max(12, "حداکثر تعداد مجاز کاراکتر 12 می‌باشد.")
    .nullable()
    .required("این فیلد الزامی است.");

export const guestEconomicCode = Yup.string()
    .label("guestEconomicCode")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .min(11, "حداقل تعداد مجاز کاراکتر 11 می‌باشد.")
    .max(12, "حداکثر تعداد مجاز کاراکتر 12 می‌باشد.")
    .nullable()
    .required("این فیلد الزامی است.");

export const companyType = Yup.string()
    .label("companyType")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const gazetteUploadedFile = Yup.string()
    .label("gazetteUploadedFile")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const guestCompanyType = Yup.string()
    .label("guestCompanyType")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const cityId = Yup.string()
    .label("cityId")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const provinceId = Yup.string()
    .label("provinceId")
    .required("لطفاً یک گزینه را انتخاب نمایید.");

export const bankProvider = Yup.string()
    .label("bankProvider")
    .required("این فیلد الزامی است.");

export const paymentDueDate = Yup.string()
    .label("paymentDueDate")
    .required("این فیلد الزامی است.");

export const paymentDueTime = Yup.string()
    .label("paymentDueTime")
    .required("این فیلد الزامی است.");

export const trackingNumber = Yup.string()
    .label("trackingNumber")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .required("این فیلد الزامی است.");

export const payerFullName = Yup.string()
    .label("payerFullName")
    .required("این فیلد الزامی است.");

export const paymentDescription = Yup.string()
    .label("paymentDescription")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .max(512, "توضیحات نمی تواند بیشتر از 512 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const invoiceDiscount = Yup.string()
    .matches(NumberBiggestZeroRegExp, "فقط کاراکتر عددی بالای صفر مجاز می‌باشد.")
    .label("invoiceDiscount")
    .required("این فیلد الزامی است.");

export const membershipKeyWord = Yup.string()
    .label("membershipKeyWord")
    .required("این فیلد الزامی است.");

export const cancellationKeyWord = Yup.string()
    .label("cancellationKeyWord")
    .required("این فیلد الزامی است.");

export const fromDate = Yup.string().required("این فیلد الزامی است.");

export const toDate = Yup.string().required("این فیلد الزامی است.");


export const number = Yup.string().required("این فیلد الزامی است.");

export const date = Yup.string().required("این فیلد الزامی است.");

export const usageLimitationPerUser = Yup.string()
    .label("usageLimitationPerUser")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .required("این فیلد الزامی است.");

export const discountCode = Yup.string()
    .label("discountCode")
    .required("این فیلد الزامی است.");

const allowedUserNameChar =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.~!@#$%^&*()_+|-=";
export const userUsername = Yup.string()
    .label("userUsername")
    .min(6, "حداقل کاراکتر مجاز 6 می باشد")
    .test("", "نام‌کاربری معتبر نمی‌باشد", (value) => {
        if (!!value) {
            const characterList = value.split("");
            return characterList.every((e) => allowedUserNameChar.includes(e));
        }
        return true;
    })
    .max(256, "نام‌کاربری نمی تواند بیشتر از 256 کاراکتر باشد.")
    .required("این فیلد الزامی است.");

export const ipAddress = Yup.string()
    .label("ipAddress")
    .matches(IPRegExp, "فرمت وارد شده صحیح نمی‌باشد.")
    .required("این فیلد الزامی است.");

export const providerThresholdCredit = Yup.string()
    .label("providerThresholdCredit")
    .required("این فیلد الزامی است.");

export const providerAmount = Yup.number()
    .label("providerAmount")
    .test("", "درصد وارد شده نمی تواند از 0 کمتر ‌باشد.", (value) => value >= 0)
    .required("این فیلد الزامی است.");

export const providerChargedAt = Yup.string()
    .label("providerChargedAt")
    .required("این فیلد الزامی است.");

export const withMobile = Yup.boolean().label("withMobile");
export const withEmail = Yup.boolean().label("withEmail");

export const OldPanelInvoiceEmail = Yup.string().when("withEmail", {
    is: true,
    then: Yup.string()
        .matches(emailRegExp, "ایمیل معتبر وارد نمایید")
        .max(128, "حداکثر تعداد مجاز کاراکتر 128 می‌باشد.")
        .label("OldPanelInvoiceEmail")
        .required("این فیلد الزامی است."),
});

export const invoiceOldPanelFullName = Yup.string()
    .label("invoiceOldPanelFullName")
    .required("این فیلد الزامی است.");

export const dedicatedBasePrice = Yup.string()
    .label("dedicatedBasePrice")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .max(4, "مبلغ نمی تواند بیشتر از 4 رقم باشد.")
    .required("این فیلد الزامی است.")
    .test(
        "",
        "عدد تعرفه اختصاصی باید بیشتر از صفر باشد.",
        (value) => Number(value) > 0
    );

export const discountedBasePrice = Yup.string()
    .label("discountedBasePrice")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .required("این فیلد الزامی است.");

export const netPrice = Yup.string()
    .label("netPrice")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .required("این فیلد الزامی است.");

// Plans
export const planIsActive = Yup.string().label("planIsActive");

export const planName = Yup.string()
    .label("planName")
    .required("این فیلد الزامی است.");

export const planPosition = Yup.string()
    .label("planPosition")
    .required("این فیلد الزامی است.");

export const planProviderId = Yup.string()
    .label("planProviderId")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.");
// .required('این فیلد الزامی است.');

export const planNumberLength = Yup.number()
    .label("planNumberLength")
    .test("", "درصد وارد شده نمی تواند از 0 کمتر ‌باشد.", (value) => value >= 0)
    // .matches(NumberRegExp, 'فقط کاراکتر عددی مجاز می‌باشد.')
    .required("این فیلد الزامی است.");

export const planCreditCount = Yup.string()
    .label("planCreditCount")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.");
// .required('این فیلد الزامی است.');

export const planCreditBasePrice = Yup.string()
    .label("planCreditBasePrice")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.");
// .required('این فیلد الزامی است.');

export const planBasePriceTier = Yup.array().of(
    // .label('planBasePriceTier')
    Yup.number().required("First name is required")
);

export const planPerMonth = Yup.array().of(
    Yup.object().shape({
        totalAmount: Yup.string().required("name required"),
        lineAmount: Yup.number()
            .required("age required")
            .typeError("age must be a number"),
        creditAmount: Yup.number()
            .required("age required")
            .typeError("age must be a number"),
    })
);
// .label('planPerMonth')

export const planLimitationItem = Yup.string().label("planLimitationItem");

export const dedicatedLimitForParameters = Yup.string()
    .label("dedicatedLimitForParameters")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .min(1, "حداقل کاراکتر مجاز 1 می باشد")
    .required("این فیلد الزامی است.")
    .test(
        "",
        "حداقل محدودیت وارد شده باید بیشتر از 25 باشد.",
        (value) => Number(value) > 25
    );

export const webServiceLimitation = Yup.string()
    .label("webServiceLimitation")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .required("این فیلد الزامی است.")
    .test(
        "",
        "حداقل محدودیت وارد شده باید بیشتر از 10 و کمتر از 50000 باشد.",
        (value) => Number(value) < 50000 && Number(value) > 10
    );

export const PurchaseRestrictionPaymentCount = Yup.string()
    .label("PurchaseRestrictionPaymentCount")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .required("این فیلد الزامی است.")
    .test(
        "",
        "حداکثر تعداد پرداخت 3 می باشد",
        (value) => Number(value) >= 0 && Number(value) <= 3
    );

export const PurchaseRestrictionTime = Yup.string()
    .label("PurchaseRestrictionTime")
    .required("این فیلد الزامی است.");

export const linesList = Yup.string()
    .label("linesList")
    .required("این فیلد الزامی است.");

export const selectedPublicLine = Yup.string()
    .label("selectedPublicLine")
    .required("این فیلد الزامی است.");

export const planLineNumber = Yup.string()
    .label("planLineNumber")
    .required("این فیلد الزامی است.");

export const basePriceDisCount = Yup.array()
    .of(
        Yup.number()
            .typeError("فقط کاراکتر عددی مجاز می‌باشد.")
            .required("این فیلد الزامی است."),
    );

export const planId = Yup.string()
    .label("planId")
    .required("این فیلد الزامی است.");

export const planDuration = Yup.string()
    .label("planDuration")
    .required("این فیلد الزامی است.");

export const trakingNumber = Yup.string()
    .label("trakingNumber")
    .matches(NumberRegExp, "فقط کاراکتر عددی مجاز می‌باشد.")
    .required("این فیلد الزامی است.");


