import React from "react";

function WhiteSmsIcon(props) {
  return (
    <svg    
    xmlns="http://www.w3.org/2000/svg"
      id="prefix__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 595.28 640"
      fill={props.fill || '#ffbe00'}
      {...props}
    >
     <path
        d="M157.9 335.21c23.78 11.14 48.26 17.23 74.3 14.22 10.12-1.16 20-4.17 29.94-6.56 16.27-3.9 32.27-9.16 48.67-11.83 35.75-5.81 66.31 5.06 90.17 32.54 8 9.16 14.69 19.41 22 29.19-.41.48-.82.89-1.23 1.37-1.71-.69-3.42-1.3-5.06-2.05-32.27-14.84-65.15-17.64-99.12-6.09-21.53 7.38-43.41 13.19-66.58 13.13-24.27-.07-44.91-8.21-62.89-24.13-12.64-11.21-22.34-24.75-30.2-39.79z"
      ></path>
      <path
        d="M393.73 346.22c-15.59-12.31-32.68-21.74-52.43-24.2-12-1.51-24.34-.41-36.5-.48-15.45-.07-31 1.23-46.28-.41-27.82-3-48.06-18.46-61.32-42.79-4.71-8.61-8.2-17.91-12.3-26.87l1.64-1c1.78 1.23 3.55 2.39 5.26 3.62 24.34 17.64 51.27 24.61 81.28 21.19a240.43 240.43 0 0142.59-.89c28 1.78 50 14.84 64.59 39 5.95 9.91 10.33 20.84 15.38 31.3a14 14 0 01-1.91 1.53z"
      ></path>
      <path
        d="M218.94 184.48c5.88 5.61 10.32 10.33 15.24 14.43 15.45 12.92 33.29 19.62 53.39 21.39a206.31 206.31 0 0134.66 5.81c34 9.3 54.62 36.71 55.57 72.6-.68-.27-1.5-.27-1.84-.68-18.66-21.67-42.38-32.68-70.82-34.18a145.54 145.54 0 01-23-3.42c-35.34-7.73-55.3-28.92-61.59-65-.55-3.15-.93-6.37-1.61-10.95z"
      ></path>
      <path
        d="M478.86 139.1a251.82 251.82 0 10-356.12 356.13A251.82 251.82 0 00478.86 139.1zM300.8 549.91c-128.55 0-232.75-104.2-232.75-232.74S172.25 84.42 300.8 84.42s232.75 104.2 232.75 232.75S429.34 549.91 300.8 549.91z"
      ></path>
    </svg>
  );
}

export default WhiteSmsIcon;
