import React from "react";
import styled from "styled-components";

export const ValidateWrapper = styled.div`
    border-top: 1px solid #e2e5ec;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
`;

export const ValidateWrapperText = styled.p`
    border-bottom: 0.5rem;
    font-weight: bold;
`;