import React, { useEffect, useState } from 'react';
import {
  MainContent,
  WrapperLayout,
  HeaderContent,
  PageTitleLayout,
} from '../../../Component/Kit/Layout';
import { StyledButton } from '../../../Component/Kit/Button';
import BackToPage from '../../../Component/Kit/back';
import { useHistory, useParams } from 'react-router-dom';
import TicketCommentsItem from './TicketCommentItem';
import { Formik } from 'formik';
import { TicketCommentSchema } from '../../../Utility/Validation/Schema/TicketCommentSchema';
import { StyledForm, StyledTextArea } from '../../../Component/Kit/Form';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import DateConvert from '../../../Utility/Services/date-convert';
import { connect } from 'react-redux';
import moment from 'moment-jalaali';

function TicketComments({ adminProfile }) {
  const history = useHistory();
  const params = useParams();
  const [noteList, setNoteList] = useState();

  const getData = () => {
    ApiClient.Get(Urls.ticketNote(params.id)).then((res) => {
      res.result.forEach((f) => {
        f.creationDateTime = DateConvert(f.createdAt);
      });
      setNoteList(res.result);
    });
  };
  const deleteHandler = (list) => {
    setNoteList(list);
  };

  useEffect(() => {
    getData();
  }, [params.id]);
  return (
    <WrapperLayout>
      <div className='c-wrapper'>
        <HeaderContent className='justify-content-between'>
          <div className='d-flex'>
            <StyledButton
              btnStyle='greenBtn'
              onClick={() => history.push(`/ticket/list/${params.id}`)}
            >
              تیکت
            </StyledButton>
          </div>

          <BackToPage toPage={'/ticket/list'} />
        </HeaderContent>

        <MainContent>
          <div className="col-12 col-lg-12">
            <PageTitleLayout className="pt-2">
              <h5>یادداشت‌های تیکت</h5>
              <span>در این بخش لیست تمام یادداشت‌های تیکت قابل مشاهده است.</span>
            </PageTitleLayout>
          </div>

          <hr className="mt-4 mx-3" />

          <TicketCommentsItem
            comments={noteList || []}
            deleteConfirm={deleteHandler} />
        </MainContent>

        <MainContent>
          <PageTitleLayout className="pt-2">
            <h5>ایجاد یادداشت‌ برای این تیکت</h5>
          </PageTitleLayout>

          <Formik
            initialValues={{
              ticketContent: '',
            }}
            validationSchema={TicketCommentSchema}
            onSubmit={(values, actions) => {
              ApiClient.Post(
                Urls.ticketNote(params.id),
                JSON.stringify(values.ticketContent)
              ).then((res) => {
                setNoteList((prevState) => {
                  return [
                    ...prevState,
                    {
                      id: res.result,
                      content: values.ticketContent,
                      isDeletable: true,
                      authorName: `${adminProfile.firstName} ${adminProfile.lastName}`,
                      createdAt: moment().format('jYYYYjMMjDDHHmmss'),
                      creationDateTime: DateConvert(
                        moment().format('jYYYYjMMjDDHHmmss')
                      ),
                    },
                  ];
                });
                actions.resetForm();
              });
            }}
          >
            {(formik) => (
              <StyledForm
                onSubmit={formik.handleSubmit}
                onBlur={formik.handleBlur}
                className='replyContainer'
              >
                <div className='row'>
                  <div className='col-12 rp-box'>
                    <StyledTextArea
                      placeholder='موارد مورد نظر خود را وارد نمایید'
                      lable='متن یادداشت'
                      focus={true}
                      name='ticketContent'
                      height='100%'
                      error={
                        formik.errors.ticketContent &&
                          formik.touched.ticketContent
                          ? formik.errors.ticketContent
                          : 'false'
                      }
                      value={formik.values.ticketContent}
                      onChange={(e) => {
                        formik.setFieldValue('ticketContent', e.target.value);
                      }}
                    />
                  </div>
                </div>
                <StyledButton
                  width='100%'
                  btnStyle='darkGreenBtn'
                  id='submit-button'
                  disabled={!formik.isValid || !formik.dirty}
                  type='submit'
                >
                  ارسال یادداشت
                </StyledButton>
              </StyledForm>
            )}
          </Formik>
        </MainContent>

      </div>
    </WrapperLayout>
  );
}

function mapStateToProps(state) {
  const { bootstrapper } = state;
  return { adminProfile: bootstrapper.data };
}

export default connect(mapStateToProps)(TicketComments);
