import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Badge} from "react-bootstrap";


import DateConvert from '../../../../Utility/Services/date-convert';
import TicketDepartment from '../../../../Utility/Services/TicketDepartmentService';
import TicketPriority from '../../../../Utility/Services/TicketPriorityService';
import TicketStatus from '../../../../Utility/Services/TicketStatusService';
import {StyledButton} from '../../../../Component/Kit/Button';
import {parseTicketContent} from "../../../../Utility/method/ParseTicketContent";


export const TickeInfo = (props) => {
    const {ticketDetailData, filesModal, fileLength} = props;
    const ticketParams = useParams();
    const [tItems, setTItems] = useState({});
    const history = useHistory();

    const ticketInfoItems = {
        title: 'عنوان تیکت',
        status: 'وضعیت',
        department: 'دپارتمان',
        priority: 'درجه اهمیت',
        createdAt: 'ایجاد شده',
        lastModifiedAt: 'آخرین به روز رسانی',
        admin: 'نام مدیر',
        assignedAdmin: 'مسئول پیگیری',
    };

    const convertContentTicket = (text) => {
        if(typeof text !== "string") return text;
        return parseTicketContent(text);
    }

    useEffect(() => {
        Object.entries(ticketDetailData).map(([item, value]) => {
            switch (item) {
                case 'adminId':
                    return (ticketDetailData.admin =
                        ticketDetailData.adminId === null
                            ? 'همه مدیران'
                            : ticketDetailData.admin);
                case 'status':
                    return (ticketDetailData.status = TicketStatus(value));
                case 'department':
                    return (ticketDetailData.department = TicketDepartment(value));

                case 'priority':
                    return typeof ticketDetailData.priority === 'string'
                        ? ticketDetailData.priority
                        : (ticketDetailData.priority = TicketPriority(value));
                case 'createdAt':
                    return typeof ticketDetailData.createdAt === 'string'
                        ? ticketDetailData.createdAt
                        : (ticketDetailData.createdAt = DateConvert(value));

                case 'lastModifiedAt':
                    return typeof ticketDetailData.lastModifiedAt === 'string'
                        ? ticketDetailData.lastModifiedAt
                        : ticketDetailData.lastModifiedAt
                            ? (ticketDetailData.lastModifiedAt = DateConvert(value))
                            : (ticketDetailData.lastModifiedAt = '- - -');

                case 'assignedAdmin':
                    return typeof ticketDetailData.assignedAdmin === 'string'
                        ? ticketDetailData.assignedAdmin
                        : ticketDetailData.assignedAdmin
                            ? ticketDetailData.assignedAdmin
                            : (ticketDetailData.assignedAdmin = '- - -');
                case 'contents':
                    let updatedContents = ticketDetailData.contents.map(data => {
                        return {
                            ...data,
                            content: convertContentTicket(data.content)
                        }
                    })
                    return ticketDetailData.contents = updatedContents
                default:
                    return (ticketDetailData[item] = value);
            }
        });
        setTItems({...ticketDetailData});
    }, [ticketDetailData]);

    return (
        <div className='border-box ticket-info'>
            <h5>
                <i className='fa fa-exclamation-circle font-weight-bold'></i>
                اطلاعات تیکت{' '}
            </h5>
            <ul className='params'>
                {Object.keys(ticketInfoItems).map((keyName, i) => (
                    <li className='params__list' key={i}>
                        <div className='params__list__label'>
                            <span>{ticketInfoItems[keyName]}</span>
                        </div>
                        <div className='params__list__value'>
              <span className='params__list__label--white'>
                {tItems[keyName]}
              </span>
                        </div>
                    </li>
                ))}
            </ul>
            {fileLength > 0 ? (
                <StyledButton
                    btnStyle='grayBtn mb-2 w-100'
                    onClick={() => filesModal()}
                >
                    تمام فایل‌ها
                </StyledButton>
            ) : null}
            <StyledButton
                btnStyle='grayBtn mb-2 w-100'
                onClick={() => history.push(`/ticket/comments/${ticketParams.id}`)}
            >
                یادداشت‌های تیکت
                {

                    ticketDetailData.notesCount > 0 ?

                        <Badge pill className="text-dark pt-2  mr-2"
                               variant="warning">


                            {ticketDetailData.notesCount}


                        </Badge>

                        : <></>

                }
            </StyledButton>
        </div>
    );
};

export default TickeInfo;
