import React from "react";
import { StyledButton } from "../../../Component/Kit/Button";

function CommentView({ data, onCloseModal }) {

  const titleField = {
    adminUserName: "نام ادمین",
    createdAt: "تاریخ ایجاد",
    forGuest: "ثبت در وضعیت",
    content: "متن",
  }

  return (
    <>
      <div className="w-100">
        <ul className="params" >
          {
            titleField && data &&
            Object.entries(titleField).map(([key, value]) => (
              <li className="params__list" key={value}>
                <div className="params__list__label">
                  <span>{value}: </span>
                </div>
                <div className="params__list__value">
                  <span className="params__list__label--white">{data[key]}</span>
                </div>
              </li>
            ))
          }
        </ul>
      </div>

      <div className="d-flex justify-content-center w-100">
        <StyledButton
          width="35%"
          btnStyle="grayBtn"
          type="reset"
          onClick={() => {
            onCloseModal(false)
          }}
        >
          بستن
        </StyledButton>
      </div>
    </>
  );
}

export default CommentView;
