import React, { useEffect, useState } from "react";
import { Badge } from 'react-bootstrap';
import { connect } from "react-redux";
import { StyledButton } from "../../../../Component/Kit/Button";
import Modal from "../../../../Component/Kit/Modal";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import SmsModal from "../../modals/sms-modal";

function CodeReaderDuplicateMessage({ modalChange , data }) {
    const [isModal, setIsModal] = useState(false);

    useEffect(() => {
    }, [modalChange])
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">پیام تکراری عمومی کدخوان : </span>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>
            <Modal
                onClose={() => setIsModal(false)}
                onCancel={() => setIsModal(false)}
                visible={isModal}
                headerTitle="پیام تکراری عمومی کدخوان"
            >
            <SmsModal url={Urls.setGlobalCodeReaderDuplicateMessage()} onCloseModal={() => setIsModal(false)} defaultData={data} />
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    modalChange: state.modalChange,
});
export default connect(mapStateToProps)(CodeReaderDuplicateMessage);
