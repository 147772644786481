import {
    lineNumber, providerId,  type
} from "../Rules";
import * as Yup from "yup";

export const SystemLineSchema = Yup.object().shape({
    lineNumber,
    type,
    providerId
});
