import React from "react";
import {Col} from "react-bootstrap";
import styled from "styled-components";
import {theme} from "../../Utility/Theme";
import UploadIcon from "../Icon/UploadIcon";
import {FormError} from "./Form";

export const UploadConatiner = styled.div`
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;

  svg {
    width: 95px;
    height: 95px;
    margin: auto;
    display: block;
    margin-bottom: 5px;
  }
`;

export const UploadFileLayout = styled.div`
  height: 170px;
  flex-direction: column;
  margin-top: 10px;
`;

export const UploadTitle = styled.div`
  width: 100%;
  font-family: IRANSans;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.5rem;
  text-align: center;
  color: #999999;
  display: block;
  margin-bottom: 5px;
`;

export const ImageBox = styled(Col)`
  width: 100%;
  height: 170px;
  overflow: hidden;
  display: block;
  /* margin-bottom: 2rem; */
  border: 1px dashed #ced4da;
  border-radius: 0.4rem;
`;

export const FileNamePreview = styled.div`
  width: 100%;
  height: inherit;
  display: block;
  text-align: left;
  margin-top: 15px;
`;

export const UploadImageWrapper = styled.div`
  border-radius: ${(props) => props.theme.radius};
  border: 1px solid ${(props) => props.theme.grey5};
  position: relative;
  /* width: fit-content;
  height: 300px; */
  /* width: 500px; */
  //width: fit-content;
  //height: auto;
  max-height: 350px;
  overflow: hidden;
  min-height: 150px;
  width: 100%;

  @media only screen and (max-width: 991px) {
    margin-top: 1rem;
  }

  @media only screen and (max-width: 767px) {
    height: 250px;
  }
`;

export const UploadImageBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .overlay-control {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    max-height: 350px;
    object-fit: contain;
    border-radius: ${(props) => props.theme.radius};
  }

  .SquareCover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .overlay-control {
    display: flex;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(179, 179, 179, 40%);
    align-items: center;
    border-radius: ${(props) => props.theme.radius};
    justify-content: center;
    opacity: 0;
    transition: all 500ms ease;
    max-height: 350px;

    label {
      width: 5rem;
      height: 5rem;
      border-radius: 100%;
      background: ${(props) => props.theme.grey5};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      color: ${(props) => props.theme.grey4};
      cursor: pointer;
    }
  }
`;

export const UploadImageImageForm = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  > i {
    position: absolute;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background: ${(props) => props.theme.grey5};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: ${(props) => props.theme.grey4};
    left: calc(50% - 2.5rem);
    top: calc(50% - 2.5rem);
  }

  input {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
  }
`;

export const DeleteAction = styled.div`
  width: 100%;
  margin-bottom: .5rem;

  span:hover {
    transition: .3s;
    color: ${(props) => props.theme.redBtnHover};
  }
`;

export const DownloadAction = styled.div`
  width: 100%;

  span:hover {
    transition: .3s;
    color: ${(props) => props.theme.darkGreenBtnHover};
  }
`;

export const UploadFileBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;

  svg {
    width: 50px;
    height: 50px;
  }
`;

export function UploadFileWrqapper(props) {
    const {id, fileUrl, onChange, format, className, imageCoverFit, name} = props;
    return (<UploadImageWrapper className={className || ""}>
        {fileUrl && (<UploadImageBox>
            <img
                src={fileUrl}
                alt={fileUrl}
                className={imageCoverFit ? "SquareCover" : ""}
            />
            <div className="overlay-control">
                <label htmlFor={id}>
                    <i className="fas fa-image"/>
                </label>
            </div>
        </UploadImageBox>)}
        <UploadImageImageForm>
            {!fileUrl && <i className="fas fa-image"/>}

            <input
                type="file"
                id={id}
                value=""
                name={name || "filename"}
                accept={format || "image/jpg, image/jpeg, image/png"}
                onChange={(e) => onChange(e)}
            />
        </UploadImageImageForm>
    </UploadImageWrapper>);
}


export function UploadFileWrqapperAllKindOfFile(props) {
    const inputElement = React.useRef()

    const {text, type, callbackfunc, error, name, file, deleteFile, downloadUrl} = props;

    function clickUploadBox() {
        inputElement.current.click()
    }

    function changeInput(e) {
        callbackfunc(e.target.files[0], true, inputElement)
    }

    function downloadFile(url) {

        const a = document.createElement('a')
        a.href = url;
        a.target = "_blank";

        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }


    return (<div className="w-100">
        <UploadFileBox onClick={e => clickUploadBox(e)}>

            <br/>

            <input onChange={changeInput} ref={inputElement} className="d-none" type={type} name={name}/>

            <UploadIcon fill={theme.paleGrey}/>

            <UploadTitle>

                فایل های پشتیبانی

            </UploadTitle>

            <UploadTitle>
                {text}
            </UploadTitle>

            <br/>

        </UploadFileBox>

        <FormError show={error}>{error} </FormError>

        {file && <div className="w-100 d-flex justify-content-start ">

            <div className='col-12 col-md-12 py-2 pr-2'>
                <DeleteAction>
                        <span className='pointer' onClick={e => {deleteFile(inputElement)}}>
                            <i className='fa fa-trash ml-2'/>
                            {file}
                        </span>
                </DeleteAction>

                {
                    downloadUrl &&

                    <DownloadAction>
                        <span className='pointer' onClick={e => {
                            downloadFile(downloadUrl)
                        }}>

                            <i className='fa fa-download ml-2'/>
                            دریافت فایل

                        </span>

                    </DownloadAction>
                }

            </div>

        </div>}

    </div>);
}
