import styled from 'styled-components';

export const TicketCommentsWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 0;

  @media only screen and (max-width: 1199px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;
export const TicketCommentsListItem = styled.li`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  padding: 1rem;
`;
export const TicketTitleRow = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }
  span {
    &.name {
      margin-bottom: 0.5rem;
      color: #006861;
    }
    &.date {
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }
  }

  .delete-button {
    width: 32px;
    height: 32px;
    border: 0;
    background: transparent;
    color: #c72622;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease;
    &:hover {
      color: #ffffff;
      background: #c72622;
    }
    &:focus {
      border: 0;
      outline: none;
    }
  }
`;
export const EmptyList = styled.span`
  display: block;
  text-align: center;
  padding: 2rem 0;
`;
