let OutcomeList = [
  { name: "اطلاعات هویتی اشتباه است.", value: 1 },
  { name: "عدم پاسخگویی به شماره ثابت.", value: 2 },
  { name: "تصاویر آپلود شده داری مشکل هستند.", value: 3 },
  { name: "تلفن ثابت اشتباه است.", value: 5 },
];
export default OutcomeList;

let OutcomeDescriptionList = [
  {
    name: 'سلام کاربر عزیز از ثبت نام و انتخاب شما متشکریم \n متاسفانه ثبت شما به دلیل عدم تطابق اطلاعات ثبت شده با کارت ملی شما عدم تایید گردید لطفا  اطلاعات مطابق کارت ملی ثبت شود .\n باتشکر از همراهی و توجه شما SMS.IR _0212853', value: 1
  },
  {
    name: 'سلام کاربر عزیز از ثبت نام و انتخاب شما متشکریم \n متاسفانه ثبت نام شما به دلیل عدم پاسخگویی به تلفن ثابت تایید نگردید لطفا شنبه الی چهارشنبه بین ساعات 8 الی 20:30 و پنج شنبه‌ها از ساعت 9 الی 13 جهت احراز هویت با شماره ثابت در دسترس خود با شماره‌ی 0212853 داخلی 1 تماس حاصل نمایید . \n باتشکر از همراهی و توجه شما SMS.IR', value: 2
  },
  {
    name: 'سلام کاربر عزیز از ثبت نام و انتخاب شما متشکریم \n لطفا جهت فعالسازی و تایید پنل پیامک خود عکس کارت ملی یا صفحه‌ی اول شناسنامه‌ی خود را بارگزاری بفرمایید . \n باتشکر از همراهی و توجه شما SMS.IR', value: 3
  },
  {
    name: 'سلام کاربر عزیز از ثبت نام و انتخاب شما متشکریم \n جهت فعالسازی و تایید پنل پیامک درج تلفن ثابت الزامی می‌باشد و جهت احراز هویت با شماره ثابت شما تماس گرفته خواهد شد ، لطفا شماره ثابت خود را در اطلاعات پنل خود ثبت نمایید . \n باتشکر از همراهی و توجه شما SMS.IR', value: 5
  },
];

export { OutcomeDescriptionList };


