const arabicToPersianMap = {
    'ا': 'ا',
    'ب': 'ب',
    'پ': 'پ',
    'ت': 'ت',
    'ث': 'ث',
    'ج': 'ج',
    'چ': 'چ',
    'ح': 'ح',
    'خ': 'خ',
    'د': 'د',
    'ذ': 'ذ',
    'ر': 'ر',
    'ز': 'ز',
    'ژ': 'ژ',
    'س': 'س',
    'ش': 'ش',
    'ص': 'ص',
    'ض': 'ض',
    'ط': 'ط',
    'ظ': 'ظ',
    'ع': 'ع',
    'غ': 'غ',
    'ف': 'ف',
    'ق': 'ق',
    'ک': 'ک',
    'ك': 'ک',
    'گ': 'گ',
    'ل': 'ل',
    'م': 'م',
    'ن': 'ن',
    'و': 'و',
    'ه': 'ه',
    'ی': 'ی',
    'ى': 'ی',
    'ئ': 'ی',
    'ء': 'ء',
    'أ': 'ا',
    'إ': 'ا',
    'ؤ': 'و',
    'آ': 'آ',
    'ة': 'ه',
    'ي': 'ی',
    'ٔ': '',
    'ٕ': '',
    'ٰ': '',
    'ٹ': 'ت',
    'ڈ': 'د',
    'ڑ': 'ر',
    'ں': 'ن',
    'ۃ': 'ه',
    'ے': 'ی'
};


export function ArabicToFa(arabicText) {
    let persianText = '';
    for (let char of arabicText) {
        persianText += arabicToPersianMap[char] || char;
    }
    return persianText;
}


