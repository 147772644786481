import styled from "styled-components";

export const StyledLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  flex-direction: column;
  /* background-color: #edf0f5; */
`;

export const WrapperLayout = styled.div`
    > i{
        margin-left:5px;
        margin-bottom:1rem;
    }
`;

export const ContentLayout = styled.div`
  /* margin-top: 1rem!important; */
`;

export const HeaderContent = styled.div`
  padding: 20px 20px 0;
  direction:ltr;
  display:flex;
  align-items : center;
  a{
    direction:rtl;
    color: #282a3c;
    font-weight: 500;
    font-size: 14px;
  }
  /* button{} */
`;

export const FooterContent = styled.div`
  padding: 20px 20px 0;
  direction:ltr;
  display:flex;
  align-items : center;
`;

export const MainContent = styled.div`
  position: relative;
  text-align:right;
  padding: 20px;
  margin: 20px;
  background-color: white;
  border-radius: .3rem;
  flex-grow: 1;
  box-shadow: -1px 1px 6px 4px #dadada54;
  min-height:300px;
  .descripton {
    h5{
      margin-bottom: 1.0rem;
    }
  }
  @media only screen and (max-width: 575px){
    margin : 15px; 
  }
`;

export const FooterLayout = styled.footer`
  margin-top: 1rem !important;
`;

export const PageTitleLayout = styled.div`
  direction: rtl;
  text-align: right;
  margin-bottom: 15px;
  h5{
    margin-bottom:0.75rem;
  }
  span{
    line-height:1.7;
  }
`;
