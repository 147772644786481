import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {StyledButton} from '../../../Component/Kit/Button'
import {PageTitleLayout} from '../../../Component/Kit/Layout'
import Modal from '../../../Component/Kit/Modal'
import Table from '../../../Component/Shared/Table/Table'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import PlanPositionService from '../../../Utility/Services/PlanPositionService'
import DateConvert from '../../../Utility/Services/date-convert'
import {AssignUserPlanModal} from './AssignUserPlanModal'

export const UserPlan = () => {
    let params = useParams();
    const [userPlanList, setUserPlanList] = useState([]);
    const [assignUserPlaneModalHandler, setAssignUserPlaneModalHandler] = useState(false);

    const tableConfig = {
        header: {
            name: "عنوان پلن",
            isManual: "روش دریافت",
            createdAt: "تاریخ ایجاد",
            expireAt: "تاریخ انقضا",
            renewalCount: "تعداد تمدید",
            isDeleted: "وضعیت حذف",
            isActive: "فعال",
        },
    };
    const getUserPlan = useCallback(() => {
        ApiClient.Get(Urls.UserPlan(params.id))
            .then(res => {
                let data = res.result;
                data.map((item) => {
                    item.createdAt = DateConvert(item.createdAt);
                    item.expireAt = DateConvert(item.expireAt);
                    item.isManual = item.isManual ? "اختصاص یافته" : "خریداری شده";
                    item.isDeleted = item.isDeleted ? "حذف شده" : "حذف نشده";
                    item.isActive = item.isActive
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    return item;
                })
                setUserPlanList(data);
            })
    }, [params.id]);

    useEffect(() => {
        getUserPlan();
    }, [getUserPlan]);

    return (
        <>
            <div className="pb-3 d-flex justify-content-between align-item-center">
                <PageTitleLayout>
                    <h5>پلن کاربر</h5>
                    <span>
                        در اینجا لیست پلن‌های خریداری شده کاربر مورد بررسی قرار می‌گیرد.
                    </span>
                </PageTitleLayout>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setAssignUserPlaneModalHandler(true)}
                >
                    تخصیص پلن به کاربر
                </StyledButton>
            </div>
            <Table
                data={userPlanList}
                config={tableConfig}
            />

            <Modal
                onClose={() => setAssignUserPlaneModalHandler(false)}
                onCancel={() => setAssignUserPlaneModalHandler(false)}
                visible={assignUserPlaneModalHandler}
                headerTitle="تخصیص یک پلن به کاربر"
            >
                <AssignUserPlanModal
                    dataChange={assignUserPlaneModalHandler}
                    onCloseModal={(e) => setAssignUserPlaneModalHandler(e)}
                    onSubmit={() => getUserPlan()}
                />
            </Modal>
        </>
    )
}

export default UserPlan;