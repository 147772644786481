import React, { useEffect } from 'react';
import { useState } from 'react';
import ApiClient from '../../Requester/ApiClient';
import Urls from '../../Requester/Urls';
import Table from '../../Component/Shared/Table/Table';
import {
  MainContent,
  PageTitleLayout,
  WrapperLayout,
} from '../../Component/Kit/Layout';
import DateConvert from '../../Utility/Services/date-convert';
import TicketStatus from '../../Utility/Services/TicketStatusService';
import TicketDepartment from '../../Utility/Services/TicketDepartmentService';
import { Loader } from '../../Component/Shared/Loader';
import TicketPriority from '../../Utility/Services/TicketPriorityService';
import { useLocation } from 'react-router-dom';
import { ConvertQueryString } from '../../Utility/Services/convert-query-string';

export const TicketList = ({ history }) => {
  const location = useLocation();
  const [ticketList, setTicketList] = useState({});
  const [ticketItems, setTicketItems] = useState([]);
  const [loading, setLoading] = useState(true);

  // let filterID;
  // let myFilter = {};

  // if (history.location.search) {
  //     if (history.location.search.includes('Status')) {
  //         myFilter = {Status: history.location.search.replace('?Status=', '')}
  //     } else {
  //         myFilter = {Department: history.location.search.replace('?Department=', '')}
  //     }
  // }

  const tableConfig = {
    header: {
      userName: 'نام کاربری',
      title: 'عنوان',
      status: 'وضعیت',
      department: 'دپارتمان',
      priority: 'اولویت',
      admin: 'نام ادمین',
      assignedAdmin: 'مسئول پیگیری',
      lastModifiedAt: 'آخرین بروزرسانی',
    },
    actions: {
      comment: {
        icon: 'fa fa-comment',
      },
      view: {
        icon: 'fa fa-eye',
      },
    },
    mode: ['filter', 'search'],
    filters: ['status', 'department', 'priority', 'adminId','assignedAdminId'],
  };

  function tableActions(items) {
    switch (items.actionName) {
      case 'view':
        history.push(`list/${items.data.id}`);
        break;
      case 'comment':
        history.push(`comments/${items.data.id}`);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    ApiClient.Get(Urls.GetTicketsList(), ConvertQueryString(location.search))
      .then((res) => {
        let dataItem = res.result;
        setTicketList(dataItem);
        dataItem.items.map((m, i) => {
          m.lastModifiedAt = DateConvert(m.lastModifiedAt);
          m.statusValue = m.status;
          m.status = TicketStatus(m.status);
          m.department = TicketDepartment(m.department);
          m.priority = TicketPriority(m.priority);
          return m;
        });
        setTicketItems(dataItem.items);
        setLoading(false);
      });
  }, [location.search]);

  return (
    <WrapperLayout>
      <div className='c-wrapper'>
        <MainContent>
          <div className='col-12 col-lg-12'>
            <PageTitleLayout>
              <h5>لیست تیکت ها</h5>
              <span>در این بخش لیست همه تیک‌ها قابل مشاهده است.</span>
            </PageTitleLayout>
            <div>
              {loading === true ? (
                <Loader />
              ) : (
                <Table
                  data={ticketItems}
                  config={tableConfig}
                  actionDispatched={tableActions}
                  pageConfig={{
                    pageNumber: ticketList.pageNumber,
                    pageSize: ticketList.pageSize,
                    totalItemsCount: ticketList.totalItemsCount,
                    totalPagesCount: ticketList.totalPagesCount,
                  }}
                />
              )}
            </div>
          </div>
        </MainContent>
      </div>
    </WrapperLayout>
  );
};

export default TicketList;
