let sendSMSLimitationModel = [
    {name: "محدودیت تعداد ارسال", value: 1},
    {name: "محدودیت لینک", value: 2},
    {name: "کلمات ممنوعه", value: 4},
    {name: "کاربر جدید", value: 8},
    {name: "پیام های پرتکرار", value: 16},
    {name: "محدودیت ارسال وب", value: 32},
    {name: "ارسال خطوط ویژه", value: 64},
];

export default sendSMSLimitationModel;
