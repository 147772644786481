import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    EmptyList,
    TicketCommentsListItem,
    TicketCommentsWrapper,
    TicketTitleRow
} from "../../../../Tickets/TicketComments/TicketCommentItem/styles";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";


function CommentItem({ comments, deleteConfirm }) {
    const params = useParams();

    const [notes, setNotes] = useState();

    const deleteServiceLineNote = (noteId, index) => {
        ApiClient.Delete(Urls.DeleteServiceLineNote(params.id, noteId)).then((res) => {
            if (res.result) {
                toast.success('یادداشت ثبت شده با موفقیت حذف شد.');
                deleteConfirm(true);
            }
        });
    };
    useEffect(() => {
        setNotes(comments);
    }, [comments]);
    return (
        <div>
            {notes && notes.length > 0 ? (
                <TicketCommentsWrapper>
                    {notes.map((item, index) => (
                        <TicketCommentsListItem key={index}>
                            <TicketTitleRow>
                                <div>
                                    <span className='name'>{item.authorName}</span>
                                    <span className='date'>{item.creationDateTime}</span>
                                </div>
                                {item.isDeletable && (
                                    <button
                                        onClick={() => deleteServiceLineNote(item.id, index)}
                                        type='button'
                                        className='delete-button'
                                    >
                                        <i className='fa fa-trash' />
                                    </button>
                                )}
                            </TicketTitleRow>
                            <p>{item.content}</p>
                        </TicketCommentsListItem>
                    ))}
                </TicketCommentsWrapper>
            ) : (
                <EmptyList>آیتمی جهت نمایش وجود ندارد.</EmptyList>
            )}
        </div>
    );
}

export default CommentItem;
