import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { StyledButton } from '../../Component/Kit/Button';
import { StyledForm } from '../../Component/Kit/Form';
import ApiClient from '../../Requester/ApiClient';
import Urls from '../../Requester/Urls';
import { StyledSelect } from './../../Component/Kit/Form';
import { SetDefaultBlackListKeywordSchema } from './../../Utility/Validation/Schema/SetDefaultBlackListKeywordSchema';

export const SetDefaultBlackListKeyword = ({ onCloseModal, dataChange, keywordList }) => {
    const [cancellationKeyWordList, setCancellationKeyWordList] = useState([]);
    const [membershipKeyWordList, setMembershipKeyWordList] = useState([]);

    useEffect(() => {
        if (Array.isArray(keywordList)) {
            let cancel = [];
            let memeber = [];
            keywordList.map(el => {
                el.isCancellation
                    ? cancel.push({ value: el.id, name: el.keyWord, isDefault: el.isDefault })
                    : memeber.push({ value: el.id, name: el.keyWord, isDefault: el.isDefault })
            })
            setCancellationKeyWordList(cancel);
            setMembershipKeyWordList(memeber)
        }
    }, [keywordList])

    return (
        <>
            <Formik
                initialValues={{
                    membershipKeyWord: membershipKeyWordList ? `${membershipKeyWordList.find(el => el.isDefault)?.value}` : 0,
                    cancellationKeyWord: cancellationKeyWordList ? `${cancellationKeyWordList.find(el => el.isDefault)?.value}` : 0,
                }}
                enableReinitialize={true}
                validationSchema={SetDefaultBlackListKeywordSchema}
                onSubmit={(values, actions) => {
                    const payload = {
                        membershipKeyWordId: Number(values.membershipKeyWord),
                        cancellationKeyWordId: Number(values.cancellationKeyWord)
                    }
                    ApiClient.Put(Urls.setDefaultBlacklistKeyword(), payload)
                        .then((res) => {
                            toast.success('تغییرات  با موفقیت ثبت شد.');
                            actions.resetForm();
                            onCloseModal(false)
                            dataChange(true)
                        })
                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false)
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                    >
                        <StyledSelect
                            lable="کلیدواژه پیش‌فرض عضویت"
                            hasDefaultOption
                            focus={true}
                            name="membershipKeyWord"
                            value={formik.values.membershipKeyWord}
                            optionItems={membershipKeyWordList}
                            error="false"
                            onChange={(e) => {
                                formik.setFieldValue("membershipKeyWord", e.target.value)
                            }}
                        />
                        <StyledSelect
                            lable="کلیدواژه پیش‌فرض لغو عضویت"
                            hasDefaultOption
                            focus={true}
                            name="cancellationKeyWord"
                            value={formik.values.cancellationKeyWord}
                            optionItems={cancellationKeyWordList}
                            error="false"
                            onChange={(e) => {
                                formik.setFieldValue("cancellationKeyWord", e.target.value)
                            }}
                        />

                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                ثبت تغییرات
                            </StyledButton>
                        </div>

                    </StyledForm>
                )}

            </Formik>
        </>
    )
}

export default SetDefaultBlackListKeyword