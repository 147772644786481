import React from 'react'

function PendingManualPaymentsIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={49.037}
            height={73.054}
            {...props}
        >
            <g data-name="Group 152130">
                <path
                    data-name="Icon awesome-wallet"
                    d="M44.171 39.342H7.662a1.532 1.532 0 1 1 0-3.065h36.777a1.532 1.532 0 0 0 1.532-1.532 4.6 4.6 0 0 0-4.6-4.6H6.13A6.129 6.129 0 0 0 0 36.278v30.647a6.129 6.129 0 0 0 6.13 6.13h38.041a4.742 4.742 0 0 0 4.865-4.6V43.94a4.742 4.742 0 0 0-4.865-4.598Zm-4.329 19.921a3.065 3.065 0 1 1 3.065-3.065 3.065 3.065 0 0 1-3.065 3.065Z"
                    fill="#3f3d56"
                />
                <path
                    data-name="Icon awesome-user-check"
                    d="m37.73 3.605-3.168-3.2a1.351 1.351 0 0 0-1.914-.011L20.705 12.243l-5.186-5.219a1.351 1.351 0 0 0-1.914-.011l-3.2 3.179a1.351 1.351 0 0 0-.011 1.914l9.31 9.378a1.351 1.351 0 0 0 1.914.011L37.719 5.52a1.362 1.362 0 0 0 .011-1.914Z"
                    fill="#a7abc3"
                />
            </g>
        </svg>
    )
}

export default PendingManualPaymentsIcon;