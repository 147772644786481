import React, { useState } from 'react'
import { useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux'
import { MainContent, WrapperLayout } from '../../Component/Kit/Layout'
import { ChangePassword } from './ChangePassword'
import { ProfileDetails } from './ProfileDetails'
import { UploadAvatarContainer } from './UploadAvatar'

function AdminProfile({ bootstrapper }) {
    const [profileInfo, setProfileInfo] = useState({});

    useEffect(() => {
        setProfileInfo(bootstrapper.data)
    }, [bootstrapper])

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <MainContent>
                    {
                        profileInfo && (
                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <h5 className="mb-5">اطلاعات پروفایل</h5>

                                    <Tabs defaultActiveKey="profileDetails" id="profileDetails">
                                        <Tab eventKey="profileDetails" title=" مشخصات فردی ">
                                            <ProfileDetails personalProfile={profileInfo} />
                                        </Tab>
                                        <Tab eventKey="uploadAvatar" title="آپلود عکس">
                                            <UploadAvatarContainer personalProfile={profileInfo} />
                                        </Tab>
                                        <Tab eventKey="changePassword" title="تغییر پسورد">
                                            <ChangePassword />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        )
                    }
                </MainContent>
            </div>
        </WrapperLayout>
    )
}

const mapStateToProps = (state) => ({
    bootstrapper: state.bootstrapper,
});
export default connect(mapStateToProps)(AdminProfile);

