import { Formik } from 'formik';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../../Component/Kit/Button';
import { StyledForm, StyledTextArea } from '../../../../../Component/Kit/Form';
import Modal from '../../../../../Component/Kit/Modal';
import ApiClient from '../../../../../Requester/ApiClient';
import Urls from '../../../../../Requester/Urls';
import { RejectedManualPaymentSchema } from '../../../../../Utility/Validation/Schema/RejectedManualPaymentSchema';

export const RejectedPaymentModal = (props) => {
    const { paymentId, visible, setVisible, onCancel, onClose } = props;
    const history = useHistory();

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            headerTitle="عدم تایید پرداخت"
        >
            <div className="col-12 pt-5 pb-3">
                <h6>آیا از عدم تایید این پرداخت مطمئن هستید؟ </h6>
                <div className="row">
                    <Formik
                        initialValues={{
                            description: ""
                        }}
                        validationSchema={RejectedManualPaymentSchema}
                        enableReinitialize={false}

                        onSubmit={(values, actions) => {
                            let dataBody = {
                                "isAccepted": false,
                                "rejectReason": values.description,
                            };
                            ApiClient.Patch(Urls.GetPendingManualPayments(paymentId), dataBody)
                                .then((res) => {
                                    toast.success('فرآیند با موفقیت انجام گرفت!')
                                    setVisible(false);
                                    history.push(`/financial/manual-payment`);
                                })
                        }}
                        onReset={(values, formikBag) => {
                            setVisible(false)
                        }}
                    >

                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onBlur={formik.handleBlur}
                                onReset={formik.handleReset}
                            >
                                <StyledTextArea
                                    className="col-12"
                                    lable="دلیل  خود را وارد کنید"
                                    focus={true}
                                    name="description"
                                    value={formik.values.description}
                                    error={(formik.errors.description && formik.touched.description)
                                        ? formik.errors.description
                                        : 'false'
                                    }
                                    onChange={(e) => {
                                        const str = e.target.value.trim() !== '' ? e.target.value : ''
                                        formik.setFieldValue("description", str)
                                    }}

                                />

                                <div className="d-flex justify-content-between">
                                    <StyledButton
                                        btnStyle="redBtn ml-3"
                                        width="38%"
                                        disabled={!formik.isValid || !formik.dirty}
                                        loading={formik.isSubmitting ? 1 : 0}
                                        type="submit"
                                    >
                                        عدم تایید
                                    </StyledButton>

                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => {
                                            setVisible(false)
                                        }}
                                    >
                                        بستن
                                    </StyledButton>
                                </div>


                            </StyledForm>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}
