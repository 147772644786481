import React, {useEffect, useState} from "react";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import GetSystemLine from "../Services/system-line-type";
import EnumFlag from "../../../Utility/Services/enum-flag";
import GetProvider from "../../../Utility/Services/provider";
import {HeaderContent, MainContent, PageTitleLayout} from "../../../Component/Kit/Layout";
import {StyledButton} from "../../../Component/Kit/Button";
import Table from "../../../Component/Shared/Table/Table";
import Modal from "../../../Component/Kit/Modal";
import PublicLinesModal from "./PublicModal";
import {StyledSwitchBox} from "../../../Component/Kit/Form";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import PublicActiveModal from "./PublicActiveModal";
import EditDefaultLineModal from "./EditDefaultLineModal";

function PublicLines() {
    const [lineData, setLineData] = useState([]);
    const [modal, showModal] = useState(false);
    const [selectedLine, setSelectedLine] = useState({});
    const [activeModal, showActiveModal] = useState(false);
    const [showEditDefaultLineModal, setShowEditDefaultLineModal] = useState(false);
    const [ActivePublicLines, setActivePublicLines] = useState(false);

    const tableConfig = {
        header: {
            number: "شماره خط",
            providerText: "ارائه دهنده خدمات",
            isActiveText: "وضعیت خط",
            isDefaultIcon: "پیشفرض",
        },
        actions: {
            edit: {
                icon: "fa fa-edit",
                condition: (data) => !data.isDefault
            },
            delete: {
                icon: "fa fa-trash",
                condition: (data) => !data.isDefault
            }
        }
    };
    useEffect(() => {
        getSystemLine()
    }, []);

    const getSystemLine = () => {
        ApiClient.Get(Urls.publicLines()).then((res) => {

            setActivePublicLines(res.result.sendViaPublicLinesIsActivated)
            let data = res.result.publicLines;
            data.map((m) => {
                m.systemLines = GetSystemLine(EnumFlag([1, 2, 4, 8, 16], m.type));
                m.providerText = GetProvider(m.providerId);
                m.isActiveText = m.isActive ? "فعال" : "غیر فعال";
                m.isDefaultIcon = m.isDefault
                    ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                    : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                return m
            });
            setLineData(data);
        });

    }

    const createNewLine = () => {
        showModal(true)
    }

    function tableActions(item) {
        switch (item.actionName) {
            case 'edit':
                setSelectedLine(item.data)
                showActiveModal(true)
                break;
            case 'delete':
                deletePublicLine(item.data);
                break;

            default:
                break;
        }
    }


    const confirmActivition = () => {

        Swal.fire({
            text: `آیا از ${ActivePublicLines ? 'غیر فعال سازی' : ' فعال سازی '} خطوط ویژه اطمینان دارید ؟`,
            title: "احتیاط کن !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Patch(Urls.publicLines(), JSON.stringify(!ActivePublicLines)).then(
                    () => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        setActivePublicLines(!ActivePublicLines)
                    }
                )
            }
        });
    }

    const deletePublicLine = (data) => {
        if (data.isDefault) return

        Swal.fire({
            text: `آیا از حذف این خط ویژه مطمئن هستید ؟`,
            title: "احتیاط کن !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.DeletePublicLine(data.id)).then(
                    () => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        getSystemLine();
                    }
                )
            }
        });
    }

    return (
        <>
            <HeaderContent>
                <StyledButton btnStyle="greenBtn" onClick={() => createNewLine()}>
                    ایجاد خط جدید
                </StyledButton>

                <StyledButton btnStyle="greenBtn ml-3" onClick={() => (setShowEditDefaultLineModal(true))}>
                    انتخاب خط پیشفرض
                </StyledButton>

                <div className="d-flex ml-3">
                    <StyledSwitchBox
                        focus={true}
                        checked={ActivePublicLines}
                        onChange={(e) => confirmActivition()}
                    />
                    <p className="ml-3 mb-0 font-weight-bold " style={{lineHeight: 'normal'}}>فعال سازی خطوط</p>
                </div>
            </HeaderContent>
            <MainContent>
                <PageTitleLayout>
                    <h5>خطوط ویژه</h5>
                    <span>
            خطوط ویژه سامانه در این بخش مدیریت می شود.
                      </span>
                </PageTitleLayout>
                <Table data={lineData} config={tableConfig} actionDispatched={tableActions}></Table>
            </MainContent>

            <Modal
                onClose={() => showModal(false)}
                visible={modal}
                onCancel={() => showModal(false)}
                headerTitle={'ایجاد خط جدید'}
            >
                <PublicLinesModal onCloseModal={(e) => showModal(e)} onSubmit={() => {
                    getSystemLine()
                }}/>
            </Modal>

            {
                activeModal &&
                <Modal
                    onClose={() => showActiveModal(false)}
                    visible={activeModal}
                    onCancel={() => showActiveModal(false)}
                    headerTitle={'تغییر وضعیت خط'}
                >
                    <PublicActiveModal selectedItem={selectedLine} onCloseModal={(e) => {showActiveModal(e)}}
                       onSubmit={() => {
                       getSystemLine()
                       showActiveModal(false)}}
                    />
                </Modal>
            }
            {
                showEditDefaultLineModal &&
                <Modal
                    onClose={() => setShowEditDefaultLineModal(false)}
                    visible={showEditDefaultLineModal}
                    onCancel={() => setShowEditDefaultLineModal(false)}
                    headerTitle={'انتخاب خط پیشفرض'}
                >
                    <EditDefaultLineModal selectedItem={selectedLine} onCloseModal={(e) => setShowEditDefaultLineModal(e)}
                       onSubmit={() => {
                           getSystemLine()
                           setShowEditDefaultLineModal(false)
                       }}
                    />

                </Modal>
            }


        </>
    );
}

export default PublicLines;
