
import React from 'react'
import { StyledButton } from '../../../../Component/Kit/Button'

const GuestCommentDetailModal = ({ commentData, onCloseModal }) => {
    const headTitle = {
        adminUserName: "نام‌کاربری ادمین",
        content: "توضیحات",
        createdAt: "تاریخ ایجاد",
    }
    return (
        <>
            <div className="custom-modal-body">
                <ul className="params text-right w-100">
                    {
                        commentData &&
                        Object.entries(headTitle).map(([item, value]) => (
                            <li className="params__list" key={item}>
                                <div className="params__list__label">
                                    <span>{value}</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{commentData[item]}</span>
                                </div>
                            </li>
                        ))
                    }
                </ul>

                <div className="d-flex justify-content-center w-100">
                    <StyledButton
                        width="35%"
                        btnStyle="grayBtn"
                        type="reset"
                        onClick={() => onCloseModal(false)}
                    >
                        بستن
                    </StyledButton>
                </div>
            </div>
        </>
    )
}

export default GuestCommentDetailModal