
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { StyledForm, StyledInput } from "../../../../Component/Kit/Form";
import { StyledButton } from "../../../../Component/Kit/Button";
import { ConfirmationPaymentSchema } from "../../../../Utility/Validation/Schema/ConfirmationPaymentSchema";
import Swal from "sweetalert2";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { toast } from "react-toastify";

export const PaymentConfirmationModal = ({ data, onCloseModal, dataModalChanged }) => {
    const [paymentId, setPaymentId] = useState(null);

    useEffect(() => {
        if (Object.entries(data).length > 0)
            setPaymentId(data.id)
    }, [data])

    return (
        <div className="w-100 text-right">

            <h6> کد پیگیری خود را برای تایید پرداخت، وارد نمایید.</h6>
            <Formik
                initialValues={{
                    trakingNumber: ""
                }}
                validationSchema={ConfirmationPaymentSchema}
                enableReinitialize={false}

                onSubmit={(values, actions) => {
                    Swal.fire({
                        title: '',
                        text: `آیا از تایید پرداخت  مطمئن هستید؟`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonText: "انصراف",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "بله، مطمئن هستم",
                    }).then(result => {
                        if (result.isConfirmed) {
                            ApiClient.Patch(Urls.paymentConfirmation(paymentId), JSON.stringify(values.trakingNumber))
                                .then((res) => {
                                    toast.success('فرآیند با موفقیت انجام گرفت!')
                                    onCloseModal(false);
                                    actions.resetForm();
                                    dataModalChanged();
                                })
                                .catch(() => actions.resetForm())
                        }
                    })

                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false);
                }}
            >

                {(formik) => (
                    <StyledForm
                        className="p-0"
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                    >
                        <StyledInput
                            lable="کدپیگیری"
                            focus={true}
                            placeholder="کدپیگیری"
                            name="trakingNumber"
                            value={formik.values.trakingNumber !== undefined
                                ? formik.values.trakingNumber
                                : formik.initialValues.trakingNumber}
                            error={(formik.errors.trakingNumber && formik.touched.trakingNumber)
                                ? formik.errors.trakingNumber
                                : 'false'
                            }
                            onChange={(e) => formik.setFieldValue("trakingNumber", e.target.value)}
                        />

                        <div className="d-flex justify-content-between">
                            <StyledButton
                                btnStyle="greenBtn ml-3"
                                width="38%"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting ? 1 : 0}
                                type="submit"
                            >
                                تایید
                            </StyledButton>

                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                        </div>


                    </StyledForm>
                )}
            </Formik>

        </div>
    )
}