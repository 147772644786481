import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout'
import Table from '../../../Component/Shared/Table/Table'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import { ConvertQueryString } from '../../../Utility/Services/convert-query-string'
import DateConvert from '../../../Utility/Services/date-convert'
import { SeprateNumberWithComma } from '../../../Utility/Services/SeprateNumberWithComma'

export const ManualPaymentsList = () => {
    const [manualPaymentLists, setManualPaymentLists] = useState({});
    const history = useHistory();
    const location = useLocation();

    const tableConfig = {
        header: {
            userName: "نام کاربری",
            finalPrice: "مبلغ کل پرداختی",
            description: "توضیحات",
            createdAt: "تاریخ و زمان ایجاد",
            paidAt: "تاریخ  پرداخت",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            }
        }
    }
    
    useEffect(() => {
        ApiClient.Get(Urls.GetPendingManualPayments(), ConvertQueryString(location.search))
            .then(res => {
                let responseData = res.result;
                responseData.items.map((item, i) => {
                    item.finalPrice = `${SeprateNumberWithComma(item.finalPrice)} ریال`;
                    item.createdAt = DateConvert(item.createdAt);
                    item.paidAt = DateConvert(item.paidAt);
                });
                setManualPaymentLists(responseData);
            })
    }, [location.search]);


    const tableActions = (items) => {
        history.push(`manual-payment/${items.data.id}`);
    }

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <MainContent>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <PageTitleLayout>
                                <h5>لیست پرداخت‌های دستی</h5>
                                <span>در این بخش لیست همه پرداخت‌های دستی قابل مشاهده است.</span>
                            </PageTitleLayout>
                            <Table
                                data={manualPaymentLists.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: manualPaymentLists.pageNumber,
                                    pageSize: manualPaymentLists.pageSize,
                                    totalItemsCount: manualPaymentLists.totalItemsCount,
                                    totalPagesCount: manualPaymentLists.totalPagesCount,
                                }}
                            ></Table>
                        </div>
                    </div>
                </MainContent>
            </div>
        </WrapperLayout>
    )
}
