import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { StyledButton } from "../../../../Component/Kit/Button";
import Modal from "../../../../Component/Kit/Modal";
import {sendSMSModel} from '../../../../Utility/Model/send-sms.model';
import EditBlacklistPermissionModal from "./edit-blacklist-permission-modal";

function ManageBlacklist({ data }) {
    const [isModal, setIsModal] = useState(false);
    const [blacklistData, setBlacklistData] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);

    useEffect(() => {
        if (data && typeof data == 'string') {
            refactorBlacklistData(data);
        }
    }, [data])

    const refactorBlacklistData = (result) => {
        if (result) {
            const data = Object.entries((JSON.parse(result)))
                .map(([key, value]) => {
                    return {
                        value: key,
                        name: sendSMSModel?.find(a => a.value == key)?.name,
                        fields: JSON.parse(value)
                    }
                })
            let selectedNames = [];
            data.map(item => {
                if (item.fields.ShouldFilterMobiles || item.fields.ShouldSendCancellationText) {
                    const nameItem = item.fields.ShouldFilterMobiles || item.fields.ShouldSendCancellationText ? 'فیلتر شماره، متن لغو'
                        : (item.fields.ShouldSendCancellationText ? 'متن لغو' : 'فیلتر شماره');
                    selectedNames.push(`${item.name} (${nameItem})`)
                }
            })
            setSelectedItem(selectedNames)
            setBlacklistData(data)
        }
    }

    return (
        <>
            {
                data &&
                <>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <span className="ml-1">مدیریت لیست سیاه برای ارسال ها : </span>
                            {/*{selectedItem && blacklistData*/}
                            {/*    ? <Badge className="font-weight-normal ml-3" style={{ whiteSpace: 'pre-wrap' }} variant="primary">{selectedItem.join(' , ')}</Badge>*/}
                            {/*    : <Badge className="font-weight-normal" variant="primary">گزینه ای انتخاب نشده</Badge>*/}
                            {/*}*/}

                        </div>
                        <StyledButton
                            btnStyle="greenBtn"
                            onClick={() => setIsModal(true)}
                        >
                            ویرایش
                        </StyledButton>
                    </div>
                    <Modal
                        onClose={() => setIsModal(false)}
                        onCancel={() => setIsModal(false)}
                        visible={isModal}
                        headerTitle="مدیریت لیست سیاه برای ارسال ها"
                    >
                        <EditBlacklistPermissionModal defaultData={blacklistData}
                            onCloseModal={() => setIsModal(false)} />
                    </Modal>
                </>
            }
        </>
    )
}

export default ManageBlacklist
