import { TemplateVerifyStatus, TemplateType } from "../Model/fast-send-template.model";
function VerifyStatusService(value) {
  let result = TemplateVerifyStatus.find((item) => item.value === value);
  return result.name;
}

function TemplateTypeService(value) {
  let result = TemplateType.find((item) => item.value === value);
  return result.name;
}

export { VerifyStatusService, TemplateTypeService };