import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ConvertQueryString} from '../../Utility/Services/convert-query-string';
import DateConvert from '../../Utility/Services/date-convert';
import {PageTitleLayout} from './../../Component/Kit/Layout';
import Table from './../../Component/Shared/Table/Table';
import ApiClient from './../../Requester/ApiClient';
import Urls from './../../Requester/Urls';

function UserList() {
    const [userListData, setuserListData] = useState([]);
    const location = useLocation();
    const history = useHistory();

    const tableConfig = {
        header: {
            fullName: 'نام و نام خانوادگی',
            userName: 'نام کاربری',
            companyName: 'نام شرکت',
            mobile: 'تلفن همراه',
            isActiveIcon: 'وضعیت کاربر',
            isSuspendedIcon: 'بلاک',
            createdAt: 'تاریخ ایجاد حساب',
        },
        actions: {
            view: {
                icon: 'fa fa-eye',
            },
            copy: {
                icon: 'far fa-copy',
            },
        },
        rowColor: {
            color: '#BAE3E3',
            condition: (data) => {
                return data.isVIP;
            },
        },
        mode: ['filter', 'search'],
        filters: ['datePickerFilter', 'isActive', 'isExpired', 'isSuspended'],
    };

    const tableActions = (item) => {
        switch (item.actionName) {
            case 'view':
                history.push(`/userDetail/${item.data.id}/profile`, {
                    from: location.search,
                    someOtherProp: '',
                });
                break;
            case 'copy':
                const phoneNumber = item.data.mobile;
                navigator.clipboard.writeText(phoneNumber);
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        ApiClient.Get(Urls.getUsers(), ConvertQueryString(location.search)).then(
            (res) => {
                let data = res.result;
                data.items.map((m) => {
                    m.companyName = m.companyName ? m.companyName : '- - -'
                    m.createdAt = DateConvert(m.createdAt);
                    m.isActiveIcon = m.isActive
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    m.isExpiredIcon = m.isExpired
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    m.isSuspendedIcon = m.isSuspended
                        ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                        : `<i class="fa fa-times-circle text-danger fa-lg"/>`;
                    m.mobile = `0${m.mobile}`;
                    return m;
                });
                setuserListData(data);
            }
        );
    }, [location.search]);
    return (
        <>
            <PageTitleLayout>
                <h5>مدیریت کاربران</h5>
                <span>لیستی از کاربران تایید شده در این جدول قابل بررسی است.</span>
            </PageTitleLayout>

            <Table
                data={userListData.items}
                config={tableConfig}
                pageConfig={{
                    pageNumber: userListData.pageNumber,
                    pageSize: userListData.pageSize,
                    totalItemsCount: userListData.totalItemsCount,
                    totalPagesCount: userListData.totalPagesCount,
                }}
                actionDispatched={tableActions}
                // exportDispatched={exportActions}
            />
        </>
    );
}

export default UserList;
