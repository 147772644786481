import React, { useEffect, useState } from "react";
import { StyledTextArea } from "../../Kit/Form";
import { Wrapper } from "./styles";

const NumberList = ({
  defaultValue,
  numberListHandler,
  onChange,
  error,
  name,
  id,
  lable,
}) => {
  const [value, setValue] = useState("");
  const [validArray, setValidArray] = useState("");

  const commaSeparator = (e) => {
    const str = e.target.value;
    const numberList = str.split(/[,\n ]+/);
    const last = numberList.slice(-1);
    if (last[0] === "") {
      numberList.pop();
    }
    const cleanNumbersList = JSON.parse(JSON.stringify(numberList));
    numberList.forEach((item, index) => {
      if (!item.length) {
        numberList.splice(index, 1);
        cleanNumbersList.splice(index, 1);
      } else {
        numberList[index] = item + ",\n";
      }
    });
    if (e.keyCode === 13) {
      e.target.value = numberList.join("");
    }
    const validArrayList = cleanNumbersList.filter((i) => !isNaN(i));
    const invalidArray = cleanNumbersList.filter((i) => isNaN(i));
    setValidArray(validArrayList);
    onChange(e.target.value);
    numberListHandler({
      validArray: validArrayList,
      invalidArray,
    });
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Wrapper>
      <StyledTextArea
        label={lable}
        placeholder="3000*********,5000*********"
        value={value}
        onKeyUp={(e) => commaSeparator(e)}
        onChange={(e) => setValue(e.target.value)}
        name={name}
        id={id}
        cols="100"
        rows="10"
        error={error}
      />
    </Wrapper>
  );
};

export default NumberList;
