
import { planBasePriceTier, planIsActive, planName, planPosition, planCreditCount, planCreditBasePrice, planProviderId, planNumberLength, planPerMonth, planLimitationItem } from "../Rules";
import * as Yup from "yup";

export const ModifyPlanItemDetailSchema = Yup.object().shape({
    planIsActive,
    planName,
    planPosition,
    planCreditCount,
    planCreditBasePrice,
    planProviderId,
    planNumberLength,
    planBasePriceTier,
    planPerMonth,
    planLimitationItem,
});
