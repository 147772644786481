import React, { useEffect } from 'react';
import { NavLink, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { MainContent } from "../../Component/Kit/Layout";
import FinishedList from "./GuestsList/finished-list";
import GuestList from './GuestsList/guest-list';

const GuestPage = ({ match }) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === `${match.url}`) {
            history.push(`${match.url}/finished`)
        }
    }, [])

    return (
        <>
            <MainContent>
                <ul className="user-tab-menu">
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/finished`}>
                            منتظر تایید
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/list`}>
                            کاربران مهمان
                        </NavLink>
                    </li>
                </ul>
                <div className="p-3">
                    <Switch>
                        <Route exact path={`${match.path}/finished`}>
                            <FinishedList />
                        </Route>
                        <Route path={`${match.path}/list`}>
                            <GuestList />
                        </Route>
                    </Switch>
                </div>
            </MainContent>
        </>
    )
}

export default GuestPage
