import React, {useState} from "react";
import {StyledForm, StyledSwitchBox} from "../../../../Component/Kit/Form";
import {StyledButton} from "../../../../Component/Kit/Button";
import {useFormik} from "formik";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import {toast} from "react-toastify";

function PublicActiveModal({onCloseModal, onSubmit, selectedItem}) {

    const formik = useFormik({
        initialValues: {
            active: selectedItem['isActive']
        },

        onSubmit: (values, actions) => {
            ApiClient.Post(Urls.publicLineActivitionById(selectedItem['id']), JSON.stringify(values.active))
                .then((res) => {
                    toast.success('عملیات با موفقیت انجام شد');
                    onSubmit()
                }).catch().finally(() => {
            })
        }
    });


    return (

        <StyledForm
            onSubmit={formik.handleSubmit}
        >
            <div className="d-flex mb-4 mx-2 mt-3">
                <span className="ml-3 mb-0 font-weight-bold " style={{lineHeight: 'normal'}}>فعال سازی خط</span>
                <StyledSwitchBox
                    focus={true}
                    checked={formik.values.active}
                    onChange={(e) => formik.setFieldValue("active", e.target.checked)}
                />
            </div>


            <div className="d-flex justify-content-between">
                <StyledButton
                    width="35%"
                    btnStyle="grayBtn"
                    type="reset"
                    onClick={() => {
                        onCloseModal(false)
                    }}
                >
                    بستن
                </StyledButton>
                <StyledButton
                    width="35%"
                    loading={formik.isSubmitting ? 1 : 0}
                    btnStyle="greenBtn"
                    type="submit"
                >
                    تایید
                </StyledButton>
            </div>
        </StyledForm>
    );
}

export default PublicActiveModal
