import React, {useEffect, useState} from "react";
import Modal from "../../../../../Component/Kit/Modal";
import {Formik} from "formik";
import {
    AddInvoiceNumberInOldPanelSchema,
    AddInvoiceNumberSchema, RetryOldPanelInvoiceSchema
} from "../../../../../Utility/Validation/Schema/AddInvoiceNumberSchema";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";
import {toast} from "react-toastify";
import {FormGroup, FormLable, StyledCheckBox, StyledForm, StyledInput} from "../../../../../Component/Kit/Form";
import {MyDatePicker} from "../../../../../Component/Shared/DatePicker";
import {StyledButton} from "../../../../../Component/Kit/Button";

const RetryInvoiceSendModal = ({officialInvoiceId, data, email, visible, setVisible, onCancel, onClose}) => {
    const [userEmail, setUserEmail] = useState("");
    useEffect(() => {
        setUserEmail(email);
    }, [email]);
    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            headerTitle="ارسال مجدد فاکتور"
        >
            <div className="col-12 pt-3 pb-3">
                <div className="row">
                    <Formik
                        initialValues={{
                            OldPanelInvoiceEmail: userEmail || null,
                            withEmail: false,
                            withMobile: true,
                        }}
                        validationSchema={RetryOldPanelInvoiceSchema}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            const dataBody = {
                                sendLinkViaSms: values.withMobile,
                                email: values.withEmail && values.OldPanelInvoiceEmail
                                    ? values.OldPanelInvoiceEmail
                                    : ""
                            };

                            ApiClient.Post(
                                Urls.RetryOldPanelInvoice(officialInvoiceId),
                                dataBody
                            ).then((res) => {
                                toast.success("فرآیند با موفقیت انجام گرفت!");
                                setVisible(false);
                            });
                        }}
                        onReset={(values, formikBag) => {
                            setVisible(false);
                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                                onBlur={formik.handleBlur}
                            >
                                <FormLable className="mb-2" show={true}>
                                    نحوه ارسال
                                </FormLable>
                                <div className="d-flex align-items-center">
                                    <StyledCheckBox
                                        lable="تلفن همراه"
                                        focus={true}
                                        name="withMobile"
                                        id="withMobile"
                                        checked={
                                            formik.values.withMobile !== undefined
                                                ? formik.values.withMobile
                                                : formik.initialValues.withMobile
                                        }
                                        error="false"
                                        onChange={(e) =>
                                            formik.setFieldValue("withMobile", e.target.checked)
                                        }
                                    />
                                    <StyledCheckBox
                                        formGroupClass="mr-3"
                                        lable="ایمیل"
                                        focus={true}
                                        name="withEmail"
                                        id="withEmail"
                                        checked={
                                            formik.values.withEmail !== undefined
                                                ? formik.values.withEmail
                                                : formik.initialValues.withEmail
                                        }
                                        error="false"
                                        onChange={(e) =>
                                            formik.setFieldValue("withEmail", e.target.checked)
                                        }
                                    />
                                </div>
                                <StyledInput
                                    lable="ایمیل"
                                    placeholder="name@info.com"
                                    focus={true}
                                    disabled={!formik.values.withEmail}
                                    name="email"
                                    value={formik.values.OldPanelInvoiceEmail}
                                    error={
                                        formik.errors.OldPanelInvoiceEmail &&
                                        formik.touched.OldPanelInvoiceEmail
                                            ? formik.errors.OldPanelInvoiceEmail
                                            : "false"
                                    }
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "OldPanelInvoiceEmail",
                                            e.target.value
                                        )
                                    }
                                />

                                <div className="d-flex justify-content-between mt-3">
                                    <StyledButton
                                        btnStyle="greenBtn ml-3"
                                        width="38%"
                                        disabled={!formik.isValid}
                                        type="submit"
                                    >
                                        ارسال
                                    </StyledButton>

                                    <StyledButton width="35%" btnStyle="grayBtn" type="reset">
                                        بستن
                                    </StyledButton>
                                </div>
                            </StyledForm>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

export default RetryInvoiceSendModal;