const FastSendTemplateStatus = {
  Pending: 1,
  IsVerified: 2,
  UnVerified: 3
};

const TemplateVerifyStatus = [
  { value: FastSendTemplateStatus.Pending, name: "منتظر تایید" },
  { value: FastSendTemplateStatus.IsVerified, name: "تایید" },
  { value: FastSendTemplateStatus.UnVerified, name: "عدم تایید" },
];

const TemplateType = [
  { value: 1, name: "پیش فرض" },
  { value: 2, name: "سفارشی" },
];

export { TemplateVerifyStatus, TemplateType, FastSendTemplateStatus };
