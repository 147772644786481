import styled from 'styled-components';

export const LinkList = styled.ul`
  width: 100%;
  direction: ltr;

  li {
    margin-bottom: 1rem;
    a {
      display: flex;
      align-items: center;
      i {
        margin-right: 0.5rem;
      }
    }
  }
`;
