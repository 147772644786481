import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import CloseIcon from "../Icon/CloseIcon";
import Col from "./Column";

const StyledModalContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;

  padding: 0px;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;

  opacity: 0;
  z-index: 1050;
  overflow: hidden;
  pointer-events: none;
  transition: all 0.2s ease-out;

  &.open {
    opacity: 1;
    pointer-events: all;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const StyledModal = styled.div`
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: auto;
  height: auto;
  margin: auto;
  /* padding : 15px; */
    /* box-shadow : ${(props) => props.theme.shadow}; */
  /* margin-top: 154px; */
  @media screen and (max-width: 768px) {
    width: 96%;
  }
`;
const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
`
const Wrapper = styled.div`
  width: ${props => props.width === "large" ? "800px" : " 588px"};
  min-height: ${(props) => (`${props.size} === "small" ` ? "auto" : "336px")};
  box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.03);
  background-color: white !important;
  border-radius: 5px;
  position: relative;
  padding: 8px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 98%;
  }
`;

const Header = styled(Col)`
  height: 30px;
  background-color: #f8f9fc;
  margin-bottom: 4px ;
    /* border-bottom : ${(props) => props.theme.border}; */
`;

const HeaderTitle = styled(Col)`
  height: 100%;
  justify-content: flex-start;
  font-family: IRANSans;
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  /* color: #999999; */
`;

const HeaderAction = styled(Col)`
  height: 100%;
  justify-content: flex-end;

  svg {
    width: 11px;
    height: 11px;
    cursor: pointer;
  }
`;
const Container = styled(Col)`
  text-align: right;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    /* height: calc(100vh - 130px); */
  }
`;

const Modal = (props) => {
    const theme = useContext(ThemeContext);
    const [show, setShow] = useState(props.visible);
    const {
        headerTitle,
        onCancel,
        onClose,
    } = props;
    /*
    onConfirm,
        onConfirm,
        confirmText,
        loading,
        disable
    */
    const ModalRef = useRef();

    const callBack = () => {
        if (props.onCancel) {
            setShow(false);
            if (onCancel) {
                return onCancel();
            } else if (onClose) {
                return onClose();
            } else {
                return null;
            }
        }
    };

    const OutSideClick = (e, ref, callback) => {
        if (ref && ref.current && callback) {
            if (!ref.current.contains(e.target)) {
                callback();
            }
        }
    };

    const ClickFunc = (e) => {
        OutSideClick(e, ModalRef, callBack);
    };

    useEffect(() => {
        setShow(props.visible);
        props.visible === true &&
            window.addEventListener("mousedown", ClickFunc, false);

        return () => {
            props.visible === true &&
                window.removeEventListener("mousedown", ClickFunc, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    useMemo(() => {
        if (props.visible === true) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
    }, [props.visible]);

    return (
        <StyledModalContainer
            className={show ? "open" : "close"}
            show={show}
            width={props.width}
            {...props}
        >
            <StyledModal theme={theme} ref={ModalRef} show={show}  {...props}>
                <Overlay onClick={props.onClose}></Overlay>
                <Wrapper width={props.width}>
                    <Header xl={11.82} lg={11.82} md={12} sm={12} xs={12}>
                        <HeaderTitle xl={10.82} lg={10.82} md={8} sm={8} xs={8}>
                            {headerTitle}
                        </HeaderTitle>

                        <HeaderAction xl={1} lg={1} md={4} sm={4} xs={4}
                            onClick={props.closable !== false && props.onClose}
                        >
                            {
                                props.closable !== false &&
                                <CloseIcon />
                            }

                        </HeaderAction>
                    </Header>

                    <Container xl={11.82} lg={11.82} md={11.82} sm={11.82} xs={11.82}>
                        {props.children}
                    </Container>
                </Wrapper>
            </StyledModal>
        </StyledModalContainer>
    );

};

export default Modal;
