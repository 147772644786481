import * as hljs from 'highlight.js';


const CODE_BLOCK_REGEX = /(```[\s\S]*?```)/g;
const LINK_REGEX = /(https?:\/\/[^\s]+)/g;


/**
 * Parse ticket content into segments.
 * @param {string} content - The content to parse.
 * @returns {Array<Object>} An array of parsed segments.
 */
export function parseTicketContent(content) {
    const segments = splitContentIntoSegments(content);
    return segments
        .map(parseSegment)
        .filter(isNonEmptySegment);
}

/**
 * Split content into segments based on code blocks and links.
 * @param {string} content - The content to split.
 * @returns {Array<string>} An array of content segments.
 */
function splitContentIntoSegments(content) {
    return content.split(CODE_BLOCK_REGEX).flatMap(segment => {
        return CODE_BLOCK_REGEX.test(segment) ? segment : segment.split(LINK_REGEX);
    });
}


/**
 * Parse a content segment and categorize it.
 * @param {string} segment - The segment to parse.
 * @returns {Object} An object representing the parsed segment.
 */
function parseSegment(segment) {
    if (isCodeBlock(segment)) {
        return { type: "code", value: highlightCode(segment) };
    } else if (isLink(segment)) {
        return { type: "link", value: convertToLink(segment) };
    } else {
        return { type: "text", value: segment };
    }
}


/**
 * Check if a segment is a code block.
 * @param {string} segment - The segment to check.
 * @returns {boolean} True if the segment is a code block, otherwise false.
 */
function isCodeBlock(segment) {
    return segment.startsWith("```") && segment.endsWith("```");
}

/**
 * Check if a segment is a link.
 * @param {string} segment - The segment to check.
 * @returns {boolean} True if the segment is a link, otherwise false.
 */
function isLink(segment) {
    return LINK_REGEX.test(segment);
}

/**
 * Highlight code within a code block.
 * @param {string} segment - The code block segment.
 * @returns {string} The highlighted code.
 */
function highlightCode(segment) {
    const hljsFunc = (code) => {
        return hljs.default.highlightAuto(code).value};

    return  segment.match(CODE_BLOCK_REGEX)
        .map(i => i.replace(/```/g, ""))
        .map(code => `
            <pre class="text-left mb-0 w-100">
                <code class="hljs radius-10 w-100">${hljsFunc(code.trim())}</code>
            </pre>
    `).join()
}

/**
 * Convert a link segment into a clickable link.
 * @param {string} segment - The link segment.
 * @returns {string} The formatted link.
 */
function convertToLink(segment) {
    return segment.replace(LINK_REGEX, (url) => `<a class="text-white" href="${url}" target="_blank">${url}</a>`);
}

/**
 * Check if a segment is non-empty.
 * @param {Object} segment - The segment to check.
 * @returns {boolean} True if the segment is non-empty, otherwise false.
 */
function isNonEmptySegment(segment) {
    return !/^\s*$/.test(segment.value);
}

