import React from "react";
import { Formik } from "formik";
import { StyledForm, StyledInput } from "../../../Component/Kit/Form";
import { StyledButton } from "../../../Component/Kit/Button";
import { AddForbiddenWordsSchema } from "../../../Utility/Validation/Schema/AddForbiddenWordsScema";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function AddForbiddenWords({ onCloseModal, dataChange, data }) {
    return (
        <>
            <Formik
                initialValues={{
                    forbiddenWords: data.hasOwnProperty('id') ? data.word : '',
                }}
                validationSchema={AddForbiddenWordsSchema}
                // isInitialValid={false}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    Swal.fire({
                        title: data.hasOwnProperty('id') ? 'آیا از ویرایش این کلمه ممنوعه مطمئن هستید؟' : 'آیا از اضافه کردن این کلمه ممنوعه مطمئن هستید؟',
                        text: "",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonText: "انصراف",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "بله، مطمئن هستم",
                    }).then(result => {
                        if (result.isConfirmed) {
                            if (data.hasOwnProperty('id')) {
                                ApiClient.Put(Urls.modifyAndDeleteForbiddenWords(data.id), JSON.stringify(values.forbiddenWords))
                                    .then((res) => {
                                        toast.success('تغییرات  با موفقیت ثبت شد.');
                                        // values.forbiddenWords = ''
                                        actions.resetForm();
                                        dataChange(true);
                                    })
                            } else {
                                ApiClient.Post(Urls.forbiddenWords(), JSON.stringify(values.forbiddenWords))
                                    .then((res) => {
                                        toast.success('تغییرات  با موفقیت ثبت شد.');
                                        // values.forbiddenWords = ''
                                        actions.resetForm();
                                        dataChange(true);
                                    })
                            }
                        }
                    })

                }}
            // onReset={(values, formikBag) => {
            //     onCloseModal(false)
            // }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}>
                        <StyledInput
                            lable="لطفا کلمه ممنوعه خود را مشخص کنید."
                            focus={true}
                            placeholder="کلمه ممنوعه"
                            type="text"
                            name="forbiddenWords"
                            value={formik.values.forbiddenWords}
                            error={(formik.errors.forbiddenWords && formik.touched.forbiddenWords)
                                ? formik.errors.forbiddenWords
                                : 'false'
                            }
                            onChange={(e) =>
                                formik.setFieldValue("forbiddenWords", e.target.value)
                            }
                        />

                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                {data.hasOwnProperty('id') ? 'ویرایش کردن' : 'اضافه کردن'}
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    )
}

export default AddForbiddenWords
