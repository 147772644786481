import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { PageTitleLayout } from "../../../Component/Kit/Layout";
import { StyledButton } from "../../../Component/Kit/Button";
import DateConvert from "../../../Utility/Services/date-convert";
import TicketStatus from "../../../Utility/Services/TicketStatusService";
import { ConvertQueryString } from "../../../Utility/Services/convert-query-string";

function UserTicket() {
  const tableConfig = {
    header: {
      title: "عنوان",
      lastModifiedAt: "آخرین بروزرسانی",
      statusName: "وضعیت تیکت",
    },
    actions: {
      view: {
        icon: "fa fa-eye",
      },
    },
    mode: ["filter", "search"],
    filters: ["datePickerFilter", "status"],
  };

  let match = useRouteMatch();
  let history = useHistory();
  let location = useLocation();
  const [userTicketData, setuserTicketData] = useState({});

  const tableActions = (item) => {
    history.push(`/ticket/list/${item.data.id}`);
  };
  const addTicket = () => {
    history.push(`/ticket/add/${match.params.id}`);
  };
  useEffect(() => {
    ApiClient.Get(Urls.userTicket(match.params.id), ConvertQueryString(location.search))
      .then((res) => {
        let data = res.result;
        data.items.map((m) => {
          m.statusName = TicketStatus(m.status);
          m.lastModifiedAt = DateConvert(m.lastModifiedAt);
          return m
        });
        setuserTicketData(data);
      });
  }, [match.params.id, location.search]);
  return (
    <>

      <PageTitleLayout>
        <h5>تیکت</h5>
        <span>
          در اینجا لیست تیکت‌های این کاربر مورد بررسی قرار می‌گیرد.
        </span>
      </PageTitleLayout>

      <div className="pb-3 text-left">
        <StyledButton btnStyle="greenBtn" onClick={() => addTicket()}>
          ایجاد تیکت جدید
        </StyledButton>
      </div>

      <Table
        data={userTicketData.items}
        config={tableConfig}
        pageConfig={{
          pageNumber: userTicketData.pageNumber,
          pageSize: userTicketData.pageSize,
          totalItemsCount: userTicketData.totalItemsCount,
          totalPagesCount: userTicketData.totalPagesCount,
        }}
        actionDispatched={tableActions}
      />
    </>
  );
}

export default UserTicket;
