import React, { useState } from "react";
import { StyledButton } from "../../../../Component/Kit/Button";
import {
  StyledCheckBox,
  StyledRadioBtn,
  FormLayout,
} from "../../../../Component/Kit/Form";

function EditUserInvoiceModal({ data, onCloseModal, confirmModal }) {
  const [invoiceList, setInvoiceList] = useState([]);
  const [isChange, setIsChange] = useState(false);

  const invoiceHandler = (id, checked, confirmModal) => {
    const invoices = invoiceList;
    const invoiceID = Number(id);
    if (checked && !invoices.includes(invoiceID)) {
      invoices.push(invoiceID);
    }
    if (!checked) {
      const invoiceIndex = invoices.findIndex((f) => f === invoiceID);
      if (invoiceIndex >= 0) {
        invoices.splice(invoiceIndex, 1);
      }
    }
    setInvoiceList([...invoices]);
  };

  const switchHandler = (value) => {
    setIsChange(value);
    setInvoiceList([]);
  };

  const submitModal = () => {
    if (isChange) {
      const body = [...invoiceList]
      confirmModal(body);
    } else {
      confirmModal(null);
    }
  };

  return (
    data && (
      <>
        <div className="row w-100 mt-3">
          <div className="col-5">
            <StyledRadioBtn
              id="credit-increase"
              name="type"
              value={false}
              label="بدون تغییر"
              checked={!isChange}
              onChange={(e) => switchHandler(e.target.value === "true")}
            />
          </div>
          <div className="col-5">
            <StyledRadioBtn
              id="credit-decrease"
              name="type"
              value={true}
              label="لیست فاکتورها"
              checked={isChange}
              onChange={(e) => switchHandler(e.target.value === "true")}
            />
          </div>
        </div>
        {!isChange && (
          <div className="w-100 mt-4">
            <div
              className="alert alert-primary d-flex align-items-center"
              role="alert"
            >
              <i className="fas fa-exclamation-circle" />
              <span>اطلاعات فاکتورهای قبلی کاربر تغییری نمی کند.</span>
            </div>
          </div>
        )}
        {isChange && (
          <>
            {data.userInvoices && (
              <>
                <div className="w-100 mt-4">
                  <FormLayout>
                    <div className="form-title">
                      <span>لیست فاکتورها</span>
                    </div>
                    <div className="row w-100">
                      {Object.entries(data.userInvoices).map(
                        (invoice, index) => (
                          <div className="col-12 col-md-6 col-lg-4" key={index}>
                            <>
                              <StyledCheckBox
                                formGroupClass="mt-2"
                                lable={invoice[1] || 'بدون شماره'}
                                focus={true}
                                name="userIsVip"
                                id={`invoice-${index}`}
                                checked={invoiceList.includes(
                                  Number(invoice[0])
                                )}
                                onChange={(e) =>
                                  invoiceHandler(invoice[0], e.target.checked)
                                }
                              />
                            </>
                          </div>
                        )
                      )}
                    </div>
                  </FormLayout>
                </div>
              </>
            )}
          </>
        )}
        <div className="w-100 d-flex justify-content-between mt-3">
          <StyledButton
            btnStyle="greenBtn ml-3"
            width="38%"
            disabled={
              isChange &&
              invoiceList.length === 0
            }
            onClick={() => submitModal()}
            type="button"
          >
            تایید
          </StyledButton>

          <StyledButton
            width="35%"
            btnStyle="grayBtn"
            type="button"
            onClick={() => {
              onCloseModal(false);
            }}
          >
            انصراف
          </StyledButton>
        </div>
      </>
    )
  );
}

export default EditUserInvoiceModal;
