import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {HeaderContent, MainContent, WrapperLayout,} from "../../../../Component/Kit/Layout";

import {StyledButton} from "../../../../Component/Kit/Button";
import BackToPage from "../../../../Component/Kit/back";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import DateConvert from "../../../../Utility/Services/date-convert";
import WhiteSmsIcon from "../../../../Component/Icon/WhiteSmsIcon";
import {SellerDetails} from "../../TransactionDetails/SellerDetails";
import {BuyerDetails} from "../../TransactionDetails/BuyerDetails";
import TransactionTable from "../../TransactionDetails/TransactionTable";
import {AddNumberToInvoiceModal} from "../OfficialInvoiceItem/AddNumberToInvoiceModal";
import {SepidarModal} from "../OfficialInvoiceItem/SepidarModal";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {EditInvoiceDiscountModal} from "../OfficialInvoiceItem/EditInvoiceDiscountModal";
import RetryInvoiceSendModal from "./RetryInvoiceSendModal";

function OldPanelOfficialInvoiceItem(props) {
    const officialInvoiceId = props.match.params.id;
    const history = useHistory();
    const [singleOfficialInvoice, setSingleOfficialInvoice] = useState({});
    useState(false);
    const [addNumberToInvoiceModalVisible, setAddNumberToInvoiceModalVisible] =
        useState(false);
    const [retryInvoiceSendModalVisible, setRetryInvoiceSendModalVisible] =
        useState(false);
    const [editableInvoiceData, setEditableInvoiceData] = useState(null);
    const [invoiceTableDetails, setInvoiceTableDetails] = useState({});
    const [sepidarModal, showSepidarModal] = useState(false);
    const [editInvoiceDiscountModalVisible, setEditInvoiceDiscountModalVisible] = useState(false);
    const [sepidarInvoice, setSepidarInvoice] = useState(0);
    const [vatPercent, setVatPercent] = useState();


    const officialInvoiceName = {
        number: "شماره فاکتور",
        createdAt: "تاریخ فاکتور",
        type: "نوع فاکتور",
    };

    const saveHandler = (isSave) => {
        if (isSave) {
            getData();
        }
    };

    const onAddNumberToInvoice = () => {
        getData();
    };

    const getData = useCallback(() => {
        ApiClient.Get(Urls.GetOldPanelOfficialInvoices(officialInvoiceId)).then(
            (res) => {
                let response = res.result;
                if (response.number) {
                    setEditableInvoiceData({
                        number: response.number,
                        date: response.createdAt,
                    });
                }
                response.address = [response.provinceName, response.cityName, response.address].join("  ")
                response.createdAt = DateConvert(response.createdAt);
                setSingleOfficialInvoice(JSON.parse(JSON.stringify(response)));
                setInvoiceTableDetails(JSON.parse(JSON.stringify(response)));
                setVatPercent(response.details[0].vatPercent);
                setSepidarInvoice(response?.number)


            }
        );
    }, [officialInvoiceId]);

    const editDiscountModalHandler = () => {
        setEditInvoiceDiscountModalVisible(true)
    }

    const editUserDetailsModalHandler = () => {
        Swal.fire({
            title: `آیا از اصلاح اطلاعات کاربر مطمئن هستید؟`,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Put(Urls.EditOldPanelUserDetails(invoiceTableDetails.id)).then(
                    (res) => {
                        toast.success("فرآیند با موفقیت انجام گرفت!");
                        getData();
                    }
                );
            }
        })
    };

    const cancelInvoiceHandler = () => {
        Swal.fire({
            title: '',
            text: `آیا از ابطال این فاکتور مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Post(Urls.cancelOldPanelInvoice(invoiceTableDetails.userId, officialInvoiceId))
                    .then(res => {
                        toast.success('فاکتور ابطال شد.');
                        getData()
                    })
            }
        })

    }


    const removeInvoiceHandler = () => {
        Swal.fire({
            title: '',
            text: `آیا از حذف این فاکتور مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Post(Urls.removeOldPanelInvoice(officialInvoiceId))
                    .then(res => {
                        toast.success('فاکتور حذف شد.');
                        history.goBack();
                    })
            }
        })

    }

    const addNumberToInvoice = () => {
        setAddNumberToInvoiceModalVisible(true);
    };

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <div className="d-flex">
                        {
                            singleOfficialInvoice.canModifyDiscountPrice
                            &&
                            <StyledButton
                                btnStyle="greenBtn mr-3"
                                onClick={() => editDiscountModalHandler()}>
                                ویرایش تخفیف
                            </StyledButton>
                        }
                        <StyledButton
                            btnStyle="greenBtn mr-3"
                            onClick={() => editUserDetailsModalHandler()}
                        >
                            دریافت مجدد اطلاعات کاربر
                        </StyledButton>
                    </div>
                    <BackToPage/>
                </HeaderContent>
                <MainContent>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="invoice-title">
                                    <h4>فاکتور رسمی فروش کالا و خدمات</h4>
                                </div>
                            </div>
                            {invoiceTableDetails && (
                                <>
                                    <div className="d-flex justify-content-between align-items-center px-3">
                                        <WhiteSmsIcon className="whiteSmsIcon"/>

                                        <div className="invoice-details d-flex flex-column">
                                            <span className="text-center mb-2">
                                                {officialInvoiceName.number} :{" "}
                                                {invoiceTableDetails.number}
                                            </span>
                                            <span className="text-center mb-2">
                                                {officialInvoiceName.createdAt} :
                                                {invoiceTableDetails.createdAt}
                                            </span>
                                        </div>
                                    </div>
                                    <SellerDetails/>

                                    <BuyerDetails singleInvoiceDetails={invoiceTableDetails}/>

                                    <TransactionTable
                                        singleInvoiceDetails={invoiceTableDetails}
                                        vatPercent={vatPercent}
                                    />

                                    <div className="d-flex align-items-center justify-content-end ">
                                        {Object.keys(invoiceTableDetails).length > 0 && (
                                            <>
                                                {
                                                    invoiceTableDetails.number && (
                                                        <>


                                                            {invoiceTableDetails.type !== 3 &&
                                                                <StyledButton
                                                                    btnStyle="redBtn mr-3"
                                                                    onClick={() => cancelInvoiceHandler()}
                                                                >
                                                                    ابطال
                                                                </StyledButton>
                                                            }

                                                            <StyledButton
                                                                btnStyle="greenBtn mr-3"
                                                                onClick={() => setRetryInvoiceSendModalVisible(true)}
                                                            >
                                                                ارسال مجدد فاکتور
                                                            </StyledButton>

                                                            <StyledButton
                                                                btnStyle="greenBtn mr-3"
                                                                disabled={!singleOfficialInvoice.hasOwnProperty('isLegal')}
                                                                onClick={() => {
                                                                    showSepidarModal(true)
                                                                }}
                                                            >
                                                                ثبت در سپیدار
                                                            </StyledButton>

                                                        </>
                                                    )
                                                }

                                                <StyledButton
                                                    btnStyle="redBtn mr-3"
                                                    onClick={() => removeInvoiceHandler()}
                                                >
                                                    حذف
                                                </StyledButton>

                                                <StyledButton
                                                    btnStyle="greenBtn mr-3"
                                                    onClick={() => addNumberToInvoice()}
                                                >
                                                    {!invoiceTableDetails.number ? "تایید" : "ویرایش"}
                                                </StyledButton>
                                            </>
                                        )}

                                        {
                                            <EditInvoiceDiscountModal
                                                officialInvoiceId={officialInvoiceId}
                                                data={singleOfficialInvoice}
                                                isSave={saveHandler}
                                                hasOldPanel={true}
                                                setVisible={setEditInvoiceDiscountModalVisible}
                                                visible={editInvoiceDiscountModalVisible}
                                                onCancel={() => setEditInvoiceDiscountModalVisible(false)}
                                                onClose={() => setEditInvoiceDiscountModalVisible(false)}
                                            />
                                        }
                                        {
                                            <RetryInvoiceSendModal
                                                officialInvoiceId={officialInvoiceId}
                                                data={singleOfficialInvoice}
                                                email={invoiceTableDetails.email}
                                                setVisible={setRetryInvoiceSendModalVisible}
                                                visible={retryInvoiceSendModalVisible}
                                                onCancel={() => setRetryInvoiceSendModalVisible(false)}
                                                onClose={() => setRetryInvoiceSendModalVisible(false)}
                                            />
                                        }
                                        {
                                            <AddNumberToInvoiceModal
                                                officialInvoiceId={officialInvoiceId}
                                                isReturn={false}
                                                data={editableInvoiceData}
                                                hasOldPanel={true}
                                                email={invoiceTableDetails.email}
                                                setVisible={setAddNumberToInvoiceModalVisible}
                                                visible={addNumberToInvoiceModalVisible}
                                                onSubmit={onAddNumberToInvoice}
                                                setSepidarModal={showSepidarModal}
                                                onCancel={() =>
                                                    setAddNumberToInvoiceModalVisible(false)
                                                }
                                                onClose={() => setAddNumberToInvoiceModalVisible(false)}
                                            />
                                        }
                                        {
                                            <SepidarModal
                                                singleOfficialInvoice={singleOfficialInvoice}
                                                officialInvoiceId={officialInvoiceId}
                                                data={sepidarInvoice}
                                                setVisible={showSepidarModal}
                                                visible={sepidarModal}
                                                hasOldPanel={true}
                                                onCancel={() => showSepidarModal(false)}
                                                onClose={() => showSepidarModal(false)}
                                            />
                                        }
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </MainContent>
            </div>
        </WrapperLayout>
    );
}

export default OldPanelOfficialInvoiceItem;
