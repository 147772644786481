class Urls {
    base() {
        return process?.env?.REACT_APP_API_HOST;
    }

    adminApi() {
        return `${this.base()}/api/admin`;
    }

    OldPanelBase() {
        return process?.env?.REACT_APP_OLDPANEL_URL;
    }

    MonitoringBase() {
        return process?.env?.REACT_APP_MONITORING_URL;
    }

    OldPanelBaseUrl() {
        return `${this.OldPanelBase()}/api`;
    }

    GetMonitoringData() {
        return `${this.MonitoringBase()}/api/report`
    }

    GetMonitoringProviderStatus() {
        return `${this.MonitoringBase()}/api/report/provider-status`
    }

    sendSms() {
        return `${this.MonitoringBase()}/api/check-message/send`
    }

    ProviderNotificationSetting() {
        return `${this.MonitoringBase()}/api/notification-setting`
    }

    GetAdminActivityLog() {
        return `${this.adminApi()}/log`;
    }

    refreshToken() {
        return `${this.adminApi()}/auth/token`;
    }

    adminBootstrapper() {
        return `${this.adminApi()}/bootstrapper`;
    }

    AdminLogin() {
        return `${this.adminApi()}/auth/login`;
    }

    GetTicketsList() {
        return `${this.adminApi()}/ticket`;
    }

    GetTicketByAdmin(ticketId) {
        return `${this.adminApi()}/ticket/${ticketId}`;
    }

    DeleteTicketByAdmin(ticketId, replyId) {
        return `${this.adminApi()}/ticket/${ticketId}/reply/${replyId}`;
    }

    AddTicketReplyByAdmin(ticketId) {
        return `${this.adminApi()}/ticket/${ticketId}/reply`;
    }

    ticketNote(ticketId) {
        return `${this.adminApi()}/ticket/${ticketId}/note`;
    }

    deleteTicketNote(ticketId, noteId) {
        return `${this.adminApi()}/ticket/${ticketId}/note/${noteId}`;
    }


    assignmentTicket(id) {
        if (id) {
            return `${this.adminApi()}/ticket/assignment-period/${id}`;
        } else {
            return `${this.adminApi()}/ticket/assignment-period`;
        }
    }


    // guest
    getAllGuest() {
        return `${this.adminApi()}/guest`;
    }

    getIcommingCalls(status, id) {
        return `${this.adminApi()}/${status}/${id}/incomming-call`;
    }

    guest(id) {
        return `${this.adminApi()}/guest/${id}`;
    }

    modifyGuestNationalCard(id) {
        return `${this.adminApi()}/guest/${id}/national-card`;
    }

    modifyGuestGazette(id) {
        return `${this.adminApi()}/guest/${id}/gazette`;
    }


    getProvince() {
        return `${this.adminApi()}/setting/province`;
    }

    getCity(id) {
        return `${this.adminApi()}/setting/${id}/city`;
    }

    guestState(id) {
        return `${this.adminApi()}/guest/${id}/state`;
    }

    GuestComment(guestId) {
        return `${this.adminApi()}/guest/${guestId}/comment`;
    }

    transformGuest(id) {
        return `${this.adminApi()}/guest/${id}/transform`;
    }

    // line
    getAllSystemLine() {
        return `${this.adminApi()}/line/system`;
    }

    getAllUserServiceLine() {
        return `${this.adminApi()}/line/service`;
    }

    AddServiceLineNote(serviceLineId) {
        return `${this.adminApi()}/line/service/${serviceLineId}/note`;
    }

    DeleteServiceLineNote(serviceLineId, noteId) {
        return `${this.adminApi()}/line/service/${serviceLineId}/note/${noteId}`;
    }

    getDetailUserServiceLine(userServiceLineId) {
        return `${this.adminApi()}/line/service/${userServiceLineId}`;
    }

    acceptFileByAdmin(userServiceLineId, userServiceLineFileId) {
        return `${this.adminApi()}/line/service/${userServiceLineId}/file/${userServiceLineFileId}/accept`;
    }

    rejectFileByAdmin(userServiceLineId, userServiceLineFileId) {
        return `${this.adminApi()}/line/service/${userServiceLineId}/file/${userServiceLineFileId}/reject`;
    }

    receiveDocumentsByAdmin(userServiceLineId) {
        return `${this.adminApi()}/line/service/${userServiceLineId}/file/original/received`;
    }

    publicLines() {
        return `${this.adminApi()}/line/public`;
    }

    DeletePublicLine(id) {
        return `${this.adminApi()}/line/public/${id}`;
    }

    userPublicLinesActivition(id) {
        return `${this.adminApi()}/user/${id}/line/public-line/access`;
    }

    publicSetDefaultLineById(id) {
        return `${this.adminApi()}/line/public/${id}/default`;
    }

    publicLineActivitionById(id) {
        return `${this.adminApi()}/line/public/${id}/active`;
    }

    usersPublicLineAccess(userId = null) {
        return userId ? `${this.adminApi()}/line/${userId}/public/user-access` : `${this.adminApi()}/line/public/user-access`;
    }


    userLine() {
        return `${this.adminApi()}/line/user`;
    }

    userLinePrebuy(id) {
        return `${this.adminApi()}/line/user/${id}/prebuy`;
    }

    deleteUserLinePrebuy(id, prebuyId) {
        return `${this.adminApi()}/line/user/${id}/prebuy/${prebuyId}`;
    }

    getUserLines() {
        return `${this.adminApi()}/line/user/not-pending`;
    }

    deleteUserGiftLines(id) {
        return `${this.adminApi()}/line/user/not-pending/${id}`;
    }

    statusUserLines(id) {
        return `${this.adminApi()}/line/user/not-pending/${id}/status`;
    }

    removeSystemLine(id) {
        return `${this.adminApi()}/line/system/${id}`;
    }

    createSystemLine() {
        return `${this.adminApi()}/line/system`;
    }

    updateSystemLine(id) {
        return `${this.adminApi()}/line/system/${id}`;
    }

    getPendingLines() {
        return `${this.adminApi()}/line/user/pending`;
    }

    removePendingLines(id) {
        return `${this.adminApi()}/line/user/pending/${id}`;
    }

    activePendingLine(id) {
        return `${this.adminApi()}/line/user/pending/${id}/number`;
    }

    magfaWhoisLog(id) {
        return `${this.adminApi()}/line/${id}/whois-log`;
    }

    magfaWhoisApply(id) {
        return `${this.adminApi()}/line/${id}/magfa-whois`;
    }

    // ticket
    getUserSendReport(userId) {
        return `${this.adminApi()}/user/${userId}/send/excel`;
    }

    getUserReceiveReport(userId) {
        return `${this.adminApi()}/user/${userId}/receive/excel`;
    }

    TicketAnswerTemplates() {
        return `${this.adminApi()}/ticket-answer-template`;
    }

    ModifyTicketAnswerTemplateById(templateId) {
        return `${this.adminApi()}/ticket-answer-template/${templateId}`;
    }

    addTicket(id) {
        return `${this.adminApi()}/ticket/${id}`;
    }

    // users
    getUsers() {
        return `${this.adminApi()}/user`;
    }

    userProfile(id) {
        return `${this.adminApi()}/user/${id}/profile`;
    }

    userApproveInformation(id) {
        return `${this.adminApi()}/user/${id}/pending-requirement`;
    }

    modifyUserLegal(id) {
        return `${this.adminApi()}/user/${id}/legal-profile`;
    }

    modifyUserInvoice(id) {
        return `${this.adminApi()}/user/${id}/invoices`;
    }

    modifyUserMobile(id) {
        return `${this.adminApi()}/user/${id}/verficaion-code`;
    }

    modifyUserVerificationCode(id) {
        return `${this.adminApi()}/user/${id}/mobile`;
    }

    modifyUserNationalCardImage(id) {
        return `${this.adminApi()}/user/${id}/national-card`;
    }

    modifyUserGazetteImage(id) {
        return `${this.adminApi()}/user/${id}/gazette`;
    }

    transformUsers(id) {
        return `${this.adminApi()}/user/${id}/transform`;
    }

    loginAsUser(id) {
        return `${this.adminApi()}/user/${id}/login`;
    }

    userTicket(id) {
        return `${this.adminApi()}/user/${id}/ticket`;
    }

    userLines(id) {
        return `${this.adminApi()}/user/${id}/line`;
    }

    userSuspentionLog(id) {
        return `${this.adminApi()}/user/${id}/suspension/log`;
    }

    userSuspend(id) {
        return `${this.adminApi()}/user/${id}/suspend`;
    }

    userUnsuspend(id) {
        return `${this.adminApi()}/user/${id}/unsuspend`;
    }

    userComments(id) {
        return `${this.adminApi()}/user/${id}/comment`;
    }

    userCustomNotification(id) {
        return `${this.adminApi()}/user/${id}/notification/custom`;
    }

    customNotification(id, notificationId) {
        return `${this.adminApi()}/user/${id}/notification/custom/${notificationId}`;
    }

    userFastSendTemplate(id) {
        return `${this.adminApi()}/user/${id}/fast-send-template`;
    }

    userTransactions(id) {
        return `${this.adminApi()}/user/${id}/credit`;
    }

    userPayments(id) {
        return `${this.adminApi()}/user/${id}/payment`;
    }

    userSendSms(id) {
        return `${this.adminApi()}/user/${id}/sms`;
    }

    userPaymentSingle(id, paymentId) {
        return `${this.adminApi()}/user/${id}/payment/${paymentId}`;
    }

    userOfficialInvoice(id) {
        return `${this.adminApi()}/user/${id}/invoice`;
    }

    userFinancialSettings(id) {
        return `${this.adminApi()}/user/${id}/financial-settings`;
    }

    dedicatedUserBasePrice(id) {
        return `${this.adminApi()}/user/${id}/financial-settings/dedicated-base-price`;
    }

    dedicatedLimitationVerifyParamSize(userId) {
        return `${this.adminApi()}/user/${userId}/special-allowed-max-param-size`;
    }

    //financial
    getOnlinePaymentList() {
        return `${this.adminApi()}/payment`;
    }

    onlinePaymentById(id) {
        return `${this.adminApi()}/payment/${id}`;
    }

    getExportOnlinePaymentList(type) {
        return `${this.adminApi()}/payment/${type}`;
    }

    GetPendingManualPayments(id) {
        let request = "";
        id === undefined
            ? (request = `${this.adminApi()}/payment/manual/pending`)
            : (request = `${this.adminApi()}/payment/manual/pending/${id}`);
        return request;
    }


  ErrorLogSepidar(){
    return `${this.adminApi()}/registration/error-log/sepidar`;
  }

  EditPaymentManual(paymentId){
    return `${this.adminApi()}/payment/manual/${paymentId}`;
  }

    GetOfficialInvoices(id) {
        let request = "";
        id === undefined
            ? (request = `${this.adminApi()}/invoice`)
            : (request = `${this.adminApi()}/invoice/${id}`);
        return request;
    }

    setNumberForInvoice(id, params) {
        return `${this.adminApi()}/invoice/${id}/number?isReturn=${params.isReturn}`;
    }

    changeInvoiceDetails(id) {
        return `${this.adminApi()}/invoice/${id}/detail/change-preview`;
    }


    EditInvoiceDetail(id) {
        return `${this.adminApi()}/invoice/${id}/detail/`;
    }


    GiveOfficialAccess(id) {
        return `${this.adminApi()}/payment/online/${id}/official/access`;
    }


    GetSepidarLocation() {
        return `${this.adminApi()}/location/sepidar`;
    }

    GetSepidarInfo(id) {
        return `${this.adminApi()}/customer-existence/${id}/sepidar`;
    }


    registerSepidar(id) {
        return `${this.adminApi()}/invoice/${id}/sepidar`;
    }

    registerSepidarReturnInvoice(id) {
        return `${this.adminApi()}/invoice/${id}/return/sepidar`;
    }

    GetExportFromOfficialNumberedInvoices(type) {
        return `${this.adminApi()}/invoice/numbered/${type}`;
    }

    GetUserCreditCount(userId) {
        return `${this.adminApi()}/user/${userId}/credit/count`;
    }

    AddReturnInvoice(userId, invoiceId) {
        return `${this.adminApi()}/user/${userId}/invoice/${invoiceId}/return`;
    }

    //pending SMS

    getCountSMS() {
        return `${this.adminApi()}/send/count`;
    }

    getPendingSMS() {
        return `${this.adminApi()}/send/pending`;
    }

    getPendingSMSPublicLine() {
        return `${this.adminApi()}/send/pending/public-line`;
    }

    getFrequentMessage() {
        return `${this.adminApi()}/send/frequent-messages`;
    }

    setFrequentMessageValidation(id) {
        return `${this.adminApi()}/send/frequent-messages/${id}`;
    }

    highConsumptionVerify() {
        return `${this.adminApi()}/send/high-consumption-web-service-user`;
    }

    highConsumptionVerifyPerUser(userId) {
        return `${this.adminApi()}/send/high-consumption-web-service-user/${userId}`;
    }

    highConsumptionVerifyUserMessages(userId) {
        return `${this.adminApi()}/send/high-consumption-web-service-user/${userId}/messages`;
    }

    getSingleQueue(id) {
        return `${this.adminApi()}/send/pending/${id}`;
    }

    editPublicLineById(id) {
        return `${this.adminApi()}/send/pending/public-line/${id}/line`;
    }

    smartParametricList(id) {
        return `${this.adminApi()}/send/pending/smart/${id}/detail`;
    }

    // notifications
    globalNotifications() {
        return `${this.adminApi()}/notification/custom/global`;
    }

    globalNotificationSingle(id) {
        return `${this.adminApi()}/notification/custom/global/${id}`;
    }

    // fast-send template
    getFastSendTemplates() {
        return `${this.adminApi()}/fast-send-template`;
    }

    putFastSendTemplates(id) {
        return `${this.adminApi()}/fast-send-template/${id}`;
    }

    //Admin Managment
    AdminsManagment(id) {
        let request = "";
        id === undefined
            ? (request = `${this.adminApi()}/`)
            : (request = `${this.adminApi()}/${id}`);
        return request;
    }

    AdminSummary() {
        return `${this.adminApi()}/summary`;
    }

    // forbidden words
    forbiddenWords() {
        return `${this.adminApi()}/forbidden-Word`;
    }

    modifyAndDeleteForbiddenWords(id) {
        return `${this.adminApi()}/forbidden-Word/${id}`;
    }

    ValidationMessage() {
        return `${this.adminApi()}/forbidden-word/validate`;
    }

    // blacklist keyword
    getBlacklistKeyword() {
        return `${this.adminApi()}/modules/black-list/key-word`;
    }

    createBlacklistKeyword() {
        return `${this.adminApi()}/modules/black-list/key-word`;
    }

    deleteBlacklistKeyword(id) {
        return `${this.adminApi()}/modules/black-list/key-word/${id}`;
    }

    setDefaultBlacklistKeyword() {
        return `${this.adminApi()}/modules/black-list/key-word/default`;
    }

    // settings
    getSettingList() {
        return `${this.adminApi()}/setting`;
    }

    getbankSetting() {
        return `${this.adminApi()}/bank`;
    }

    contactGroupLimitationCount() {
        return `${this.adminApi()}/setting/contact-group-limitation`;
    }

    abTestMinimunImportedContacts() {
        return `${this.adminApi()}/setting/abtest-contact-limitation`;
    }

    calledWebServiceCount() {
        return `${this.adminApi()}/setting/called-web-service-count`;
    }

    totalSmsInDay() {
        return `${this.adminApi()}/setting/total-sms-in-day`;
    }

    publicLineCost() {
        return `${this.adminApi()}/setting/public-line-cost`;
    }

    vatPercent() {
        return `${this.adminApi()}/setting/vat-percent`;
    }

    towerLimitationConfiguration() {
        return `${this.adminApi()}/setting/tower-limitation-configuration`;
    }

    publicLineSendLimitations() {
        return `${this.adminApi()}/setting/public-line-send-limitations`;
    }

    offKeyword() {
        return `${this.adminApi()}/setting/off-keyword`;
    }

    smsTypeActivation() {
        return `${this.adminApi()}/setting/smstype-activation`;
    }

    towerOperatorConfiguration() {
        return `${this.adminApi()}/setting/tower-operator-configuration`;
    }


    userEventLimitationCount() {
        return `${this.adminApi()}/setting/user-event-limitation`;
    }

    userTemplateLimitationCount() {
        return `${this.adminApi()}/setting/user-template-limitation`;
    }

    fastSendTemplateAddLimitation() {
        return `${this.adminApi()}/setting/fastsend-template-limitation`;
    }

    recipientLimitationCountInPerSendSms() {
        return `${this.adminApi()}/setting/recipient-sms-limitation`;
    }

    fifteenRemarkCoefficient() {
        return `${this.adminApi()}/setting/fifteenremark-coefficient`;
    }

    reactionToUserTicket() {
        return `${this.adminApi()}/setting/ticket-reaction`;
    }

    giftLineActivation() {
        return `${this.adminApi()}/setting/giftline-activation`;
    }

    linkUrlLimitationInSmsContent() {
        return `${this.adminApi()}/setting/sms-linkcheck`;
    }

    //campaign
    advertisingCampaign() {
        return `${this.adminApi()}/advertising-campaign`;
    }

    advertisingCampaignById(campaignId) {
        return `${this.adminApi()}/advertising-campaign/${campaignId}`;
    }

    advertisingCampaignMembers() {
        return `${this.adminApi()}/advertising-campaign/members`;
    }

    advertisingCampaignMembersById(campaignId) {
        return `${this.adminApi()}/advertising-campaign/${campaignId}/members`;
    }

    saleCooperationActivation() {
        return `${this.adminApi()}/setting/cooperation-activation`;
    }

    registerSepidarActivation() {
        return `${this.adminApi()}/setting/sepidar-register-receipt-activation`;
    }

    publicLineActivation() {
        return `${this.adminApi()}/setting/public-line-activation`;
    }


    updateBlackListTypes() {
        return `${this.adminApi()}/setting/send-types-necessity`;
    }

    updateSendsTypes() {
        return `${this.adminApi()}/setting/send-types-limitations`;
    }


    lackOfCreditWarningThreshold() {
        return `${this.adminApi()}/setting/lack-of-credit-warning-threshold`;
    }

    recipientLimitationCountInPerSendApi() {
        return `${this.adminApi()}/setting/recipient-send-in-api-limitation`;
    }

    minimumCooperationWithdrawal() {
        return `${this.adminApi()}/setting/minimum-cooperation-withdrawal-amount`;
    }

    cooperationLineProfit() {
        return `${this.adminApi()}/setting/cooperation-line-profit-percent`;
    }

  cooperationCreditProfit() {
    return `${this.adminApi()}/setting/cooperation-credit-profit-percent`;
  }


    setGlobalCodeReaderDuplicateMessage() {
        return `${this.adminApi()}/setting/global-code-reader-duplicate-parameter-message`;
    }

  setGlobalCodeReaderWrongMessage() {
    return `${this.adminApi()}/setting/global-code-reader-wrong-parameter-message`;
  }

  userTagLimitation() {
    return `${this.adminApi()}/setting/usertag-limitation`;
  }

    //dont use in this version
    /*     welcomeMessageText() {
              return `${this.adminApi()}/setting/welcomeMessageText`;
          }
          mobileVerificationText() {
              return `${this.adminApi()}/setting/mobileVerificationText`;
          }
          deactivationUserMessageText() {
              return `${this.adminApi()}/setting/deactivationUserMessageText`;
          }
          newTicketIssuedSMSTemplateForAdmin() {
              return `${this.adminApi()}/setting/newTicketIssuedSMSTemplateForAdmin`;
          }
          userSuspensionMessageText() {
              return `${this.adminApi()}/setting/userSuspensionMessageText`;
          }
          fastSendDefaultTemplate() {
              return `${this.adminApi()}/setting/fastSendDefaultTemplate`;
          }
          refuseGuestMessageText() {
              return `${this.adminApi()}/setting/refuseGuestMessageText`;
          } */

    // giftLine
    getGiftLinesCount() {
        return `${this.adminApi()}/line/gift/count`;
    }

    giftLines() {
        return `${this.adminApi()}/line/gift`;
    }

    // Profile
    ModifyAdminProfileInfo() {
        return `${this.adminApi()}/profile`;
    }

    ModifyAdminProfileAvatar() {
        return `${this.adminApi()}/profile/image`;
    }

    ModifyAdminPassword() {
        return `${this.adminApi()}/profile/password`;
    }

    // Dashboard
    GetDashboard() {
        return `${this.adminApi()}/dashboard`;
    }


    AddCreditLend() {
        return `${this.adminApi()}/lend`;
    }

    changeCooperationCreditProfitPercent(id) {
        return `${this.adminApi()}/user/${id}/cooperation-credit-profit-percent`;
    }

    changeCooperationLineProfitPercent(id) {
        return `${this.adminApi()}/user/${id}/cooperation-line-profit-percent`;
    }

    creditTransfer(id) {
        return `${this.adminApi()}/user/${id}/credit`;
    }

    ModifyLendInvoices(id) {
        let request = "";
        id === undefined
            ? (request = `${this.adminApi()}/lend`)
            : (request = `${this.adminApi()}/lend/${id}`);
        return request;
    }

    GetCreditTier() {
        return `${this.adminApi()}/credit/tier`;
    }

    getPendingCampaignByItem(id) {
        return `${this.adminApi()}/send/pending/${id}/items`;
    }

    getWhiteUsersList() {
        return `${this.adminApi()}/denied-limitation`;
    }

    userDeniedLimitations(id) {
        return `${this.adminApi()}/user/${id}/denied-limitations`;
    }

    userCalledWebServiceCount(id) {
        return `${this.adminApi()}/user/${id}/allowed-calling-web-service-count`;
    }

    userForbiddenWords(id) {
        return `${this.adminApi()}/user/${id}/white-list-forbidden-word`;
    }

    removeUserForbiddenWords(id, userId) {
        return `${this.adminApi()}/user/${userId}/white-list-forbidden-word/${id}`;
    }

    userCancellationPhraseStatus(id) {
        return `${this.adminApi()}/user/${id}/avoid-black-list-keyword`;
    }

    getOfficialDiscount(invoiceId, invoiceDetailId) {
        return `${this.adminApi()}/invoice/${invoiceId}/details/${invoiceDetailId}/discount`;
    }

    // getOldPanelOfficialDiscount(invoiceId, invoiceDetailId) {
    //   return `${this.adminApi()}/invoice/old-panel/${invoiceId}/details/${invoiceDetailId}/discount`;
    // }

    cancelInvoice(userId, invoiceId) {
        return `${this.adminApi()}/user/${userId}/invoice/${invoiceId}/cancel`;
    }

    deleteInvoice(invoiceId) {
        return `${this.adminApi()}/invoice/${invoiceId}`;
    }

    removeOldPanelInvoice(invoiceId) {
        return `${this.OldPanelBaseUrl()}/api/invoice/${invoiceId}`;
    }

    cancelOldPanelInvoice(userId, invoiceId) {
        return `${this.OldPanelBaseUrl()}/user/${userId}/invoice/${invoiceId}/cancel`;
    }

    DiscountCodeLogs(id) {
        return `${this.adminApi()}/public-discount-code/${id}/log`;
    }

    PublicDiscountCode(id) {
        let request = "";
        id === undefined
            ? (request = `${this.adminApi()}/public-discount-code`)
            : (request = `${this.adminApi()}/public-discount-code/${id}`);
        return request;
    }

    // send-tower

    getSendTowerPending() {
        return `${this.adminApi()}/send-via-tower?isPendingForHandle=true`;
    }

    getSingleSendTower(id) {
        return `${this.adminApi()}/send-via-tower/${id}`;
    }

    getSendTowerNotPending() {
        return `${this.adminApi()}/send-via-tower?isPendingForHandle=false`;
    }

    sendTowerChangeStatus(id) {
        return `${this.adminApi()}/send-via-tower/${id}/pending`;
    }

    sendTowerReady(id) {
        return `${this.adminApi()}/send-via-tower/${id}/ready`;
    }

    sendTowerPaid(id) {
        return `${this.adminApi()}/send-via-tower/${id}/paid`;
    }


    // sale's cooperation
    getSaleCooperationTransaction() {
        return `${this.adminApi()}/cooperation/transactions`;
    }

    editSaleCooperationTransaction(id) {
        return `${this.adminApi()}/cooperation/settlement/${id}`;
    }

    getSaleCooperationSettlement() {
        return `${this.adminApi()}/cooperation/settlement`;
    }

    // Providers Credit
    GetProvidersCredit() {
        return `${this.adminApi()}/provider/balance`;
    }

    ModifyProviderThreshold(providerId) {
        return `${this.adminApi()}/setting/provider/${providerId}`;
    }

    GetProviderAmount(providerId) {
        return `${this.adminApi()}/provider/balance/charge/${providerId}`;
    }

    IncreaseProviderAmount() {
        return `${this.adminApi()}/provider/balance/charge`;
    }

    // blocked IP
    BlockedIPLog(id) {
        let request = "";
        id === undefined
            ? (request = `${this.adminApi()}/blocked-IP/log`)
            : (request = `${this.adminApi()}/blocked-IP/log/${id}`);
        return request;
    }

    BlockedNumber(id = undefined) {
        return id === undefined
            ? `${this.adminApi()}/blocked-mobile/`
            : `${this.adminApi()}/blocked-mobile/${id}`;
    }

    // Credit Package
    GetCreditPackage() {
        return `${this.adminApi()}/credit/package`;
    }

    ModifyCreditPackage(packageId) {
        const request =
            packageId === undefined
                ? `${this.adminApi()}/credit/package`
                : `${this.adminApi()}/credit/package/${packageId}`;
        return request;
    }

    // Feedback
    GetFeedback() {
        return `${this.adminApi()}/feedback`;
    }

    modifyPaymentLimitations() {
        return `${this.adminApi()}/setting/payment-limitations`;
    }


    officialPayment(userId) {
        return `${this.adminApi()}/user/${userId}/official-payment`;
    }

    // baseprice
    DedicatedBasePrice(id) {
        let request = "";
        id === undefined
            ? (request = `${this.adminApi()}/credit/dedicated-base-price`)
            : (request = `${this.adminApi()}/credit/dedicated-base-price/${id}`);
        return request;
    }

    //old-panel

    GetOldPanelOfficialInvoices(id) {
        if (id) {
            return `${this.OldPanelBaseUrl()}/invoice/${id}`;
        }
        return `${this.OldPanelBaseUrl()}/invoice`;
    }

    GroupNumberedPanelInvoice(isOldPanel) {
        return `${isOldPanel ? this.OldPanelBaseUrl() : this.adminApi()}/invoice/number`;
    }

    NumberedOldPanelInvoice(id) {
        return `${this.OldPanelBaseUrl()}/invoice/${id}/number`;
    }

    RetryOldPanelInvoice(id) {
        return `${this.OldPanelBaseUrl()}/invoice/${id}/pdf`;
    }

    EditOldPanelUserDetails(id) {
        return `${this.OldPanelBaseUrl()}/invoice/${id}/user-info`;
    }

    getOldPanelOfficialDiscount(invoiceId, invoiceDetailId) {
        return `${this.OldPanelBaseUrl()}/invoice/${invoiceId}/details/${invoiceDetailId}/discount`;
    }


    registerOldPanelSepidar(id) {
        return `${this.OldPanelBaseUrl()}/invoice/${id}/sepidar`;
    }

    // Plan
    Plan(id) {
        let request = '';
        id === undefined
            ? (request = `${this.adminApi()}/plan`)
            : (request = `${this.adminApi()}/plan/${id}`);
        return request;
    }

    PlanFree() {
        return `${this.adminApi()}/plan/free`;
    }



    // plans lines
    plansLines(id) {
        let request = '';
        id === undefined
            ? (request = `${this.adminApi()}/plan/line`)
            : (request = `${this.adminApi()}/plan/line/${id}`);
        return request;
    }

    plansLinesCount() {
        return `${this.adminApi()}/plan/line/count`;
    }

    plansLinesPendingUser(id) {
        let request = '';
        id === undefined
            ? (request = `${this.adminApi()}/plan/line/pending`)
            : (request = `${this.adminApi()}/plan/line/pending/${id}/handle`);
        return request;
    }

    UserPlan(userId) {
        return `${this.adminApi()}/user/${userId}/plan`;
    }

    UsersPerPlan(planId) {
        return `${this.adminApi()}/plan/${planId}/user`;
    }

    paymentConfirmation(paymentId) {
        return `${this.adminApi()}/payment/online/${paymentId}`;
    }

    webServiceLogs() {
        return `${this.adminApi()}/api/log`;
    }


}

export default new Urls();
