import { useFormik } from 'formik';
import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from 'react-toastify';
import BackToPage from "../../../Component/Kit/back";
import { StyledButton } from "../../../Component/Kit/Button";
import {
    FormLable,
    StyledForm,
    StyledInput,
    StyledSelect,
    StyledTextArea
} from "../../../Component/Kit/Form";
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from "../../../Component/Kit/Layout";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import TicketDepartmentList from "../../../Utility/Model/TicketDepartmentModel";
import TicketPriorityList from "../../../Utility/Model/TicketPriorityModel";
import { AddTicketSchema } from "../../../Utility/Validation/Schema/AddTicketSchema";
import { UploadFileWrqapperAllKindOfFile } from "../../../Component/Kit/UploadZone";

const AddTicket = () => {
    let match = useRouteMatch();
    let history = useHistory();
    const [submitLoading, setsubmitLoading] = useState(false);

    const addTicketHandler = (body) => {
        setsubmitLoading(true);
        ApiClient.Post(Urls.addTicket(match.params.id), body)
            .then((res) => {
                setsubmitLoading(false);
                toast.success('تغییرات  با موفقیت ثبت شد.');
                history.push(`/userDetail/${match.params.id}/ticket`);
            })
    };

    function onChangeFile(event) {
        formik.setFieldValue('ticketFile', event)
    }


    function deleteFile(inputRef) {
        if(inputRef.current.value) inputRef.current.value = "";
        formik.setFieldValue('ticketFile', null)
    }

    const formik = useFormik({

        initialValues: {
            subject: "",
            department: "",
            priority: "",
            longDescription: "",
            ticketFile: null,
        },
        enableReinitialize: false,

        validationSchema: AddTicketSchema,

        onSubmit: (values, actions) => {
            const formData = new FormData()
            formData.append('title', values.subject)
            formData.append('department', parseInt(values.department))
            formData.append('priority', parseInt(values.priority))
            formData.append('content', values.longDescription)
            formData.append('File', values.ticketFile)

            addTicketHandler(formData);
        },

        onBlur: () => {

        },

    });


    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <span className="fakeContainer"></span>
                    <BackToPage />
                </HeaderContent>

                <MainContent>
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout className='pt-2'>
                            <h5> تیکت جدید</h5>
                            <p>لطفا تیکت خود را بنویسید.</p>
                        </PageTitleLayout>
                    </div>
                    <hr className="mt-4 mx-3" />

                    <StyledForm
                        onSubmit={formik.handleSubmit}
                    >

                        <StyledInput
                            lable="لطفا عنوان تیکت خود را مشخص کنید."
                            focus={true}
                            placeholder="عنوان"
                            type="text"
                            name="subject"
                            value={formik.values.subject}
                            error={(formik.errors.subject && formik.touched.subject)
                                ? formik.errors.subject
                                : 'false'
                            }
                            onChange={(e) =>
                                formik.setFieldValue("subject", e.target.value)
                            }
                        />
                        <div className="row">
                            <StyledSelect
                                formGroupClass="col-md-6"
                                lable="مربوط به بخش"
                                hasDefaultOption
                                focus={true}
                                name="department"
                                value={formik.values.department}
                                optionItems={TicketDepartmentList}
                                error={(formik.errors.department && formik.touched.department)
                                    ? formik.errors.department
                                    : 'false'
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("department", e.target.value)
                                }
                            />
                            <StyledSelect
                                formGroupClass="col-md-6"
                                lable="اولویت"
                                hasDefaultOption
                                focus={true}
                                name="priority"
                                value={formik.values.priority}
                                optionItems={TicketPriorityList}
                                error={(formik.errors.priority && formik.touched.priority)
                                    ? formik.errors.priority
                                    : 'false'
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("priority", e.target.value)
                                }
                            />
                        </div>


                        <div className="row p-3">

                            <div className="col-12 col-md-6 pr-0 pl-0 pl-md-2">
                                <StyledTextArea
                                    lable="متن تیکت خود را وارد کنید"
                                    focus={true}
                                    name="longDescription"
                                    value={formik.values.longDescription}
                                    error={(formik.errors.longDescription && formik.touched.longDescription)
                                        ? formik.errors.longDescription
                                        : 'false'
                                    }
                                    onChange={(e) => {
                                        const str = e.target.value.trim() !== '' ? e.target.value : '';
                                        formik.setFieldValue("longDescription", str)
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 pl-0 pr-0 pr-md-3">

                                <div className='col-12 col-md-12 p-0'>

                                    <FormLable show={true}>فایل</FormLable>

                                    <UploadFileWrqapperAllKindOfFile
                                        text="xls, xlsx, jpeg, jpg, JPG, png, zip, rar, txt"
                                        type="file"
                                        name="ticketFile"
                                        callbackfunc={onChangeFile}
                                        error={(formik.errors.ticketFile && formik.values.ticketFile)
                                            ? formik.errors.ticketFile
                                            : 'false'
                                        }
                                        file={formik.values.ticketFile?.name}
                                        deleteFile={deleteFile}
                                    />

                                </div>


                            </div>

                        </div>



                        <div className="w-100">
                            <StyledButton
                                width="100%"
                                btnStyle="darkGreenBtn"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={submitLoading ? 1 : 0}
                                type="submit"
                            >
                                ارسال تیکت
                            </StyledButton>
                        </div>
                    </StyledForm>
                </MainContent>
            </div>
        </WrapperLayout>
    );
};

export default AddTicket;
