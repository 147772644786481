import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sendSMSLimitationModel from "../../../Page/PendingSMS/Model/send-sms-limitation.model";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { AdminActivityOperationModel } from "../../../Utility/Model/AdminActivityOperationModel";
import { BankProviderList } from "../../../Utility/Model/bank-provider.model";
import { TemplateVerifyStatus } from "../../../Utility/Model/fast-send-template.model";
import {
  lineTypes,
  UserLineEarnMethod,
  UserLineStatusNotPending,
} from "../../../Utility/Model/lines.model";
import TicketDepartmentList from "../../../Utility/Model/TicketDepartmentModel";
import TicketPriorityList from "../../../Utility/Model/TicketPriorityModel";
import { AutocompleteList } from "../../Kit/Form";
import { MyRangePicker } from "../DatePicker";
import StepList from "./../../../Utility/Model/guest-step.model";
import invoiceTypeModel from "./../../../Utility/Model/invoice.model";
import PaymentStatusModel from "./../../../Utility/Model/PaymentStatusModel";
import ProviderEnum from "./../../../Utility/Model/provider-enum.model";
import TicketStatusList from "./../../../Utility/Model/TicketStatusModel";
import CreditTransactionModel from "./../../../Utility/Model/transaction-type.model";
import transactionTypeModel from "./../../../Utility/Model/transaction-type.model";

const LabelContainer = styled.div`
  label {
    background-color: white;
    display: "block";
    padding: 5px;
    margin-bottom: 0.1rem;
    justify-content: flex-start;
    font-weight: 600;
    text-align: right;
    font-size: 12px;
    width: 100%;
  }
`;
const { forwardRef, useImperativeHandle } = React;
export let getFilter = {};
export const TableFilters = forwardRef((props, ref) => {
  const [adminSummaryList, setAdminSummaryList] = useState();
  const [adminSummaryCustomList, setAdminSummaryCustomList] = useState();
  const [adminAssignCustomList, setAdminAssignCustomList] = useState();
  const [filters, SetFilters] = useState({
    fromDate: {
      defaultValue: undefined,
      title: "از تاریخ",
      filterName: "fromDate",
    },
    needCount: {
      defaultValue: undefined,
      title: "",
      filterName: "needCount",
    },
    toDate: {
      defaultValue: undefined,
      title: "تا تاریخ",
      filterName: "toDate",
    },
    step: {
      defaultValue: undefined,
      title: "وضعیت ثبت نام",
      filterName: "step",
    },
    status: {
      defaultValue: undefined,
      title: "وضعیت",
      filterName: "status",
    },
    providerId: {
      defaultValue: undefined,
      title: "ارائه دهنده خدمات",
      filterName: "providerId",
    },
    earnMethod: {
      defaultValue: undefined,
      title: "نوع دریافت",
      filterName: "earnMethod",
    },
    lineType: {
      defaultValue: undefined,
      title: "نوع خط",
      filterName: "lineType",
    },
    department: {
      defaultValue: undefined,
      title: "نوع دپارتمان",
      filterName: "department",
    },
    assignedAdminId: {
      defaultValue: undefined,
      title: "مسئول پیگیری",
      filterName: "assignedAdminId",
    },
    isExpired: {
      defaultValue: undefined,
      title: "وضعیت حساب",
      filterName: "isExpired",
    },
    isExpiredLine: {
      defaultValue: undefined,
      title: "وضعیت",
      filterName: "isExpiredLine",
    },
    isSuspended: {
      defaultValue: undefined,
      title: "وضعیت مسدود بودن",
      filterName: "isSuspended",
    },
    isActive: {
      defaultValue: undefined,
      title: "وضعیت کاربر",
      filterName: "isActive",
    },

    isActiveCampaign: {
      defaultValue: undefined,
      title: "وضعیت کمپین",
      filterName: "isActiveCampaign",
    },

    isDeleted: {
      defaultValue: undefined,
      title: "منقضی شده",
      filterName: "isDeleted",
    },
    isRead: {
      defaultValue: undefined,
      title: "وضعیت",
      filterName: "isRead",
    },
    creditTransactionType: {
      defaultValue: undefined,
      title: "نوع استفاده",
      filterName: "creditTransactionType",
    },
    type: {
      defaultValue: undefined,
      title: "نوع فاکتور",
      filterName: "type",
    },
    isOnline: {
      defaultValue: undefined,
      title: "روش پرداخت",
      filterName: "isOnline",
    },
    isOfficial: {
      defaultValue: undefined,
      title: "نوع",
      filterName: "isOfficial",
    },
    onlyReturns: {
      defaultValue: undefined,
      title: "نوع فاکتور",
      filterName: "onlyReturns",
    },
    isVerified: {
      defaultValue: undefined,
      title: "وضعیت فاکتور",
      filterName: "isVerified",
    },
    fromNumber: {
      defaultValue: undefined,
      title: "از شماره",
      filterName: "fromNumber",
    },
    toNumber: {
      defaultValue: undefined,
      title: "تا شماره",
      filterName: "toNumber",
    },
    isPaid: {
      defaultValue: undefined,
      title: "وضعیت پرداخت",
      filterName: "isPaid",
    },
    priority: {
      defaultValue: undefined,
      title: "اولویت",
      filterName: "priority",
    },
    limitationType: {
      defaultValue: undefined,
      title: "محدودیت لغو شده",
      filterName: "type",
    },
    operation: {
      defaultValue: undefined,
      title: "عملیات",
      filterName: "operation",
    },
    onlyFree: {
      defaultValue: undefined,
      title: "آزادبودن خط",
      filterName: "onlyFree",
    },
    onlyPendings: {
      defaultValue: true,
      title: "درحال انتظار",
      filterName: "onlyPendings",
    },
    adminId: {
      defaultValue: undefined,
      title: "انتخاب مدیر",
      filterName: "adminId",
    },
    isValid: {
      defaultValue: undefined,
      title: "وضعیت",
      filterName: "isValid",
    },
    bankId: {
      defaultValue: undefined,
      title: "بانک دریافت کننده",
      filterName: "bankId",
    },
    onlyHandled: {
      defaultValue: undefined,
      title: 'وضعیت',
      filterName: 'onlyHandled',
    },

    ReviewState: {
      defaultValue: undefined,
      title: 'وضعیت',
      filterName: 'ReviewState',
    },
  });
  // functions
  const DatePickerFilter = () => {
    const dateHandler = (event) => {
      if (event.fromDate) {
        changeHandler(event.fromDate.baseFormat, "fromDate");
      } else {
        changeHandler("", "fromDate");
      }
      if (event.toDate) {
        changeHandler(event.toDate.baseFormat, "toDate");
      } else {
        changeHandler("", "toDate");
      }
    };
    return (
      <>
        <MyRangePicker
          toValue={filters.toDate.defaultValue}
          fromValue={filters.fromDate.defaultValue}
          value={dateHandler}
          column={true}
        />
      </>
    );
  };
  const Step = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.step["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "step")}
            value={filters.step.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {StepList.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };
  const Status = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.status["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "status")}
            value={filters.status.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {TicketStatusList.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };
  const Provider = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.providerId["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "providerId")}
            value={filters.providerId.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {ProviderEnum.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };


  const EarnMethod = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.earnMethod["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "earnMethod")}
            value={filters.earnMethod.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {UserLineEarnMethod.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  const LineType = function () {
    return (
        <>
          <div className="form-group">
            <LabelContainer>
              <label>{filters.lineType["title"]}</label>
            </LabelContainer>
            <select
                className="form-control"
                onChange={(e) => changeHandler(e.target.value, "lineType")}
                value={filters.lineType.defaultValue}
            >
              <option value="">انتخاب کنید</option>
              {lineTypes.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.name}
                  </option>
              ))}
            </select>
          </div>
        </>
    );
  };
  const LineStatus = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.status["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "status")}
            value={filters.status.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {UserLineStatusNotPending.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };
  const Department = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.department["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "department")}
            value={filters.department.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {TicketDepartmentList.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };
  const IsExpired = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.isExpired["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "isExpired")}
            value={filters.isExpired.defaultValue}
          >
            <option value={""}>انتخاب کنید</option>
            <option value={true}>منقضی شده</option>
            <option value={false}>معتبر</option>
          </select>
        </div>
      </>
    );
  };
  const IsExpiredLine = function () {
    return (
        <>
          <div className="form-group">
            <LabelContainer>
              <label>{filters.isExpiredLine["title"]}</label>
            </LabelContainer>
            <select
                className="form-control"
                onChange={(e) => changeHandler(e.target.value, "isExpiredLine")}
                value={filters.isExpiredLine.defaultValue}
            >
              <option value={""}>انتخاب کنید</option>
              <option value={false}>فعال</option>
              <option value={true}>غیر فعال</option>
            </select>
          </div>
        </>
    );
  };
  const IsSuspended = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.isSuspended["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "isSuspended")}
            value={filters.isSuspended.defaultValue}
          >
            <option value={""}>انتخاب کنید</option>
            <option value={true}>Block</option>
            <option value={false}>Unblock</option>
          </select>
        </div>
      </>
    );
  };

  const IsActiveCampaign = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.isActiveCampaign["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "isActiveCampaign")}
            value={filters.isActiveCampaign.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            <option value={true}>فعال</option>
            <option value={false}>غیرفعال</option>
          </select>
        </div>
      </>
    );
  };


  const IsActive = function () {
    return (
        <>
          <div className="form-group">
            <LabelContainer>
              <label>{filters.isActive["title"]}</label>
            </LabelContainer>
            <select
                className="form-control"
                onChange={(e) => changeHandler(e.target.value, "isActive")}
                value={filters.isActive.defaultValue}
            >
              <option value="">انتخاب کنید</option>
              <option value={true}>فعال</option>
              <option value={false}>غیرفعال</option>
            </select>
          </div>
        </>
    );
  };

  const IsDeleted = function () {
    return (
        <>
          <div className="form-group">
            <LabelContainer>
              <label>{filters.isDeleted["title"]}</label>
            </LabelContainer>
            <select
                className="form-control"
                onChange={(e) => changeHandler(e.target.value, "isDeleted")}
                value={filters.isDeleted.defaultValue}
            >
              <option value="">انتخاب کنید</option>
              <option value={true}>بله</option>
              <option value={false}>خیر</option>
            </select>
          </div>
        </>
    );
  };

  const IsValid = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.isValid["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "isValid")}
            value={filters.isValid.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            <option value={true}>فعال</option>
            <option value={false}>غیرفعال</option>
          </select>
        </div>
      </>
    );
  };
  const OnlyPending = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.onlyPendings["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "onlyPendings")}
            value={filters.onlyPendings.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            <option value={true}>منتظر</option>
            <option value={false}>تایید شده</option>
          </select>
        </div>
      </>
    );
  };
  const IsRead = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.isRead["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "isRead")}
            value={filters.isRead.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            <option value={true}>خوانده شده</option>
            <option value={false}>خوانده نشده</option>
          </select>
        </div>
      </>
    );
  };
  const TemplateStatus = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.status["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "status")}
            value={filters.status.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {TemplateVerifyStatus.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };
  const NotPendingTemplateStatus = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.status["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "status")}
            value={filters.status.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {TemplateVerifyStatus.map((item, index) =>
              index > 0 ? (
                <option value={item.value} key={index}>
                  {item.name}
                </option>
              ) : null
            )}
          </select>
        </div>
      </>
    );
  };
  const CreditTransactionType = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.creditTransactionType["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) =>
              changeHandler(e.target.value, "creditTransactionType")
            }
            value={filters.creditTransactionType.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {CreditTransactionModel.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };
  const InvoiceTypeFilter = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.type["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "type")}
            value={filters.type.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {invoiceTypeModel.map((item, index) =>
              index > 0 ? (
                <option value={item.value} key={index}>
                  {item.name}
                </option>
              ) : null
            )}
          </select>
        </div>
      </>
    );
  };




    const PaymentStatusFilter = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.status["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "status")}
            value={filters.status.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {PaymentStatusModel.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };
  const IsOnline = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.isOnline["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "isOnline")}
            value={filters.isOnline.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            <option value={true}>آنلاین</option>
            <option value={false}>آفلاین</option>
          </select>
        </div>
      </>
    );
  };

  const IsOfficial = function () {
    return (
        <>
          <div className="form-group">
            <LabelContainer>
              <label>{filters.isOfficial["title"]}</label>
            </LabelContainer>
            <select
                className="form-control"
                onChange={(e) => changeHandler(e.target.value, "isOfficial")}
                value={filters.isOfficial.defaultValue}
            >
              <option value="">انتخاب کنید</option>
              <option value={true}>رسمی</option>
              <option value={false}>غیر رسمی</option>
            </select>
          </div>
        </>
    );
  };

  const OnlyReturns = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.type["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "type")}
            value={filters.type.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {invoiceTypeModel.map((item, index) =>
                    <option value={item.value} key={index}>
                      {item.name}
                    </option>
            )}
          </select>
        </div>
      </>
    );
  };
  const IsVerifiedFilter = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.isVerified["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "isVerified")}
            value={filters.isVerified.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            <option value={false}>تایید نشده</option>
            <option value={true}>تایید شده</option>
          </select>
        </div>
      </>
    );
  };
  const FromNumber = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.fromNumber["title"]}</label>
          </LabelContainer>
          <input
            type="text"
            placeholder="of-xxxxxx"
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "fromNumber")}
            value={filters.fromNumber.defaultValue}
          />
        </div>
      </>
    );
  };
  const ToNumber = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.toNumber["title"]}</label>
          </LabelContainer>
          <input
            type="text"
            placeholder="of-xxxxxx"
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "toNumber")}
            value={filters.toNumber.defaultValue}
          />
        </div>
      </>
    );
  };
  const IsPaid = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.isPaid["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "isPaid")}
            value={filters.isPaid.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            <option value={true}>پرداخت شده</option>
            <option value={false}>پرداخت نشده</option>
          </select>
        </div>
      </>
    );
  };
  const TicketPriorityFilter = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.priority["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "priority")}
            value={filters.priority.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {TicketPriorityList.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };
  const canceledRestriction = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.limitationType["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "limitationType")}
            value={filters.limitationType.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {sendSMSLimitationModel.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };
  const adminOperation = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.operation["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "operation")}
            value={filters.operation.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {AdminActivityOperationModel.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  const PlanLineFilter = function () {
    return (
        <>
          <div className="form-group">
            <LabelContainer>
              <label>{filters.onlyFree["title"]}</label>
            </LabelContainer>
            <select
                className="form-control"
                onChange={(e) => changeHandler(e.target.value, "onlyFree")}
                value={filters.onlyFree.defaultValue}
            >
              <option value="">انتخاب کنید</option>
              <option value={true}>فعال</option>
              <option value={false}>غیرفعال</option>
            </select>
          </div>
        </>
    );
  };
  const BankIDFilter = function () {
    return (
      <>
        <div className="form-group">
          <LabelContainer>
            <label>{filters.bankId["title"]}</label>
          </LabelContainer>
          <select
            className="form-control"
            onChange={(e) => changeHandler(e.target.value, "bankId")}
            value={filters.bankId.defaultValue}
          >
            <option value="">انتخاب کنید</option>
            {BankProviderList.map(
              (item) =>
                item.value > 0 && (
                  <option value={item.value} key={item.value}>
                    {item.name}
                  </option>
                )
            )}
          </select>
        </div>
      </>
    );
  };

  const adminAssignListFilter = function () {
    const changeInput = (e) => {
      const list = adminAssignCustomList.filter((f) => f.fullName.includes(e));
      if (!e) {
        changeHandler(undefined, "assignedAdminId");
      }
      setAdminAssignCustomList([
        {
          adminId: "",
          fullName: "ندارد",
        },
        ...list,
      ]);
    };

    const changeSelect = (e) => {
      changeHandler(e.adminId, "assignedAdminId");
      setAdminAssignCustomList([
        {
          adminId: "",
          fullName: "ندارد",
        },
        ...adminSummaryList,
      ]);
    };
    return (
        <div className="form-group">
          <LabelContainer>
            <label>{filters.assignedAdminId["title"]}</label>
          </LabelContainer>
          <AutocompleteList
              options={adminAssignCustomList}
              placeholder="انتخاب کنید"
              keyName="adminId"
              valueName="fullName"
              value={
                  filters.assignedAdminId.defaultValue && Number(filters.assignedAdminId.defaultValue)
              }
              inputHandler={(e) => changeInput(e)}
              selectItem={(e) => changeSelect(e)}
          ></AutocompleteList>
        </div>
    );
  }


  const adminSummaryListFilter = function () {
    const changeInput = (e) => {
      const list = adminSummaryList.filter((f) => f.fullName.includes(e));
      if (!e) {
        changeHandler(undefined, "adminId");
      }
      setAdminSummaryCustomList([
        {
          adminId: "",
          fullName: "همه مدیران",
        },
        ...list,
      ]);
    };

    const changeSelect = (e) => {
      changeHandler(e.adminId, "adminId");
      setAdminSummaryCustomList([
        {
          adminId: "",
          fullName: "همه مدیران",
        },
        ...adminSummaryList,
      ]);
    };

    return (
      <div className="form-group">
        <LabelContainer>
          <label>{filters.adminId["title"]}</label>
        </LabelContainer>
        <AutocompleteList
          options={adminSummaryCustomList}
          placeholder="انتخاب کنید"
          keyName="adminId"
          valueName="fullName"
          value={
            filters.adminId.defaultValue && Number(filters.adminId.defaultValue)
          }
          inputHandler={(e) => changeInput(e)}
          selectItem={(e) => changeSelect(e)}
        ></AutocompleteList>
      </div>
    );
  };

  const IsHandled = function () {
    return (
      <>
        <div className='form-group'>
          <LabelContainer>
            <label>{filters.onlyHandled['title']}</label>
          </LabelContainer>
          <select
            className='form-control'
            onChange={(e) => changeHandler(e.target.value, 'onlyHandled')}
            value={filters.onlyHandled.defaultValue}
          >
            <option value={''}>همه</option>
            <option value={true}>تخصیص داده شده</option>
            <option value={false}>بدون خط</option>
          </select>
        </div>
      </>
    );
  };

  const ReviewState = function () {
    return (
        <>
          <div className='form-group'>
            <LabelContainer>
              <label>{filters.ReviewState['title']}</label>
            </LabelContainer>
            <select
                className='form-control'
                onChange={(e) => changeHandler(e.target.value, 'ReviewState')}
                value={filters.ReviewState.defaultValue}
            >
              <option value="">انتخاب کنید</option>
              <option value={1}>در انتظار</option>
              <option value={2}>تایید شده</option>
              <option value={3}>رد شده</option>
            </select>
          </div>
        </>
    );
  };

  // List of functions
  const componentList = {
    datePickerFilter: DatePickerFilter,
    step: Step,
    status: Status,
    provider: Provider,
    earnMethod: EarnMethod,
    lineType: LineType,
    lineStatus: LineStatus,
    department: Department,
    isExpired: IsExpired,
    isExpiredLine: IsExpiredLine,
    isSuspended: IsSuspended,
    isActive: IsActive,
    isActiveCampaign: IsActiveCampaign,
    isDeleted: IsDeleted,
    isRead: IsRead,
    templateStatus: TemplateStatus,
    notPendingTemplateStatus: NotPendingTemplateStatus,
    creditTransactionType: CreditTransactionType,
    InvoiceTypeFilter: InvoiceTypeFilter,
    PaymentStatusFilter: PaymentStatusFilter,
    isOnline: IsOnline,
    isOfficial: IsOfficial,
    onlyReturns: OnlyReturns,
    isVerifiedFilter: IsVerifiedFilter,
    fromNumber: FromNumber,
    toNumber: ToNumber,
    isPaid: IsPaid,
    priority: TicketPriorityFilter,
    limitationType: canceledRestriction,
    operation: adminOperation,
    onlyFree: PlanLineFilter,
    adminId: adminSummaryListFilter,
    isValid: IsValid,
    onlyPendings: OnlyPending,
    isHandled: IsHandled,
    bankId: BankIDFilter,
    assignedAdminId: adminAssignListFilter,
    ReviewState: ReviewState,
  };
  // Do not change these functions
  const changeHandler = (value, mode) => {
    const val = value;
    SetFilters((prevState) => {
      return {
        ...prevState,
        [mode]: {
          ...filters[mode],
          defaultValue: val,
        },
      };
    });
    if (val) {
      getFilter[mode] = {
        defaultValue: val,
        title: filters[mode].title,
        filterName: filters[mode].filterName,
      };
    } else {
      delete getFilter[mode];
    }
  };
  const filterFn = (filterName) => {
    if (componentList.hasOwnProperty(filterName)) {
      return componentList[filterName]();
    }
    return "";
  };
  useImperativeHandle(ref, () => {
    return {
      resetFilters() {
        for (const item of Object.keys(getFilter)) {
          SetFilters((prevState) => {
            return {
              ...prevState,
              [item]: {
                ...filters[item],
                defaultValue: "",
              },
            };
          });
        }
        getFilter = {};
      },
      removeFilter(value, mode) {
        changeHandler(value, mode);
      },
      addFilters(items) {
        for (const itemsKey in items) {
          changeHandler(items[itemsKey], itemsKey);
        }
      },
    };
  });

  useEffect(() => {
    if (!adminSummaryList || adminSummaryList.length === 0) {
      ApiClient.Get(Urls.AdminSummary()).then((res) => {
        setAdminSummaryList(res.result);
        setAdminSummaryCustomList([
          {
            adminId: "",
            fullName: "همه مدیران",
          },
          ...res.result,
        ]);
        setAdminAssignCustomList([
          {
            adminId: "",
            fullName: "ندارد",
          },
          ...res.result,
        ])
      });
    }
  }, []);
  return (
    <>
      <ul>
        {props.filtersList.map((item, index) => (
          <li className="filter-row" key={index}>
            {filterFn(item)}
          </li>
        ))}
      </ul>
    </>
  );
});
