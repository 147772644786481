const BasePriceTiersReducer = (state = {}, action) => {
    switch (action.type) {

        case 'BASE_PRICE_TIERS':
            return { ...state, ...action.data }

        default:
            return state
    }
}

export default BasePriceTiersReducer;