const bootstrapper = (state = [], action) => {
    switch (action.type) {
        case "BOOTSTRAPPER":
            return {
                data: action.data,
            };

        default:
            return state;
    }
};


export default bootstrapper