import { axiosInstance } from "./AxiosInstance";
import queryString from "query-string";

class ApiClient {
  Get = (url, params = null) => {
    return axiosInstance({
      method: "get",
      url,
      params,
      paramsSerializer: (paramList) => {
        return queryString.stringify(paramList);
      },
    });
  };

  Post = (url, data) => {
    return axiosInstance({
      method: "post",
      url,
      data,
    });
  };

  Put = (url, data, params = null) => {
    return axiosInstance({
      method: "put",
      url,
      data,
      params,
    });
  };

  Patch = (url, data, params = null) => {
    return axiosInstance({
      method: "patch",
      url,
      data,
      params,
    });
  };

  Delete = (url, data = null) => {
    return axiosInstance({
      method: "delete",
      url,
      data,
    });
  };
}

export default new ApiClient();
