import axios from "axios";
import { toast } from "react-toastify";
import LocalToken from "../Utility/LocalStorge/LocalToken";
import { errorTranslateService } from "../Utility/Services/error-translate";
import Urls from "./Urls";

let baseurl = Urls.base();
let refreshing_token = null;

export const axiosInstance = axios.create({
    baseurl,
});

axiosInstance
    .interceptors
    .request
    .use(
        function (config) {
            config.headers = {
                "content-type": "application/json",

            };
            if (config.url !== Urls.AdminLogin()) {
                if (LocalToken.getAccessToken()) {
                    config.headers = {
                        Authorization: `Bearer ${LocalToken.getAccessToken()}`,
                        "content-type": "application/json",
                    };
                } else {
                    window.location.replace("/");
                }
            }

            return config;
        },
        (error) => Promise.reject(error)
    );

axiosInstance
    .interceptors
    .response
    .use(
        (response) => {
            if (response.status === 200 && response.data.success === false) {
                    toast.error(errorTranslateService(response.data.errorCode))
                // throw new axios.Cancel('throw Error')
                new Error(response)
                return Promise.reject(response)
            } else {
                return response.data;
            }
        },
        async (error) => {
            const { config, response } = error;
            const originalConfig = config;

            if (response && response.status === 400) {
                toast.error(errorTranslateService(400))
                return Promise.reject(error);
            }
            if (response && response.status === 403) {
                localStorage.clear();
                toast.error(errorTranslateService(response.data.errorCode))
                window.location.replace('/');
            }
            if (response && response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    refreshing_token = refreshing_token ? refreshing_token : refresh_token();
                    let res = await refreshing_token;
                    refreshing_token = null;
                    if (res.status !== 401) {
                        LocalToken.setAccessToken(res.data);
                        return axiosInstance(originalConfig);
                    }
                    else {
                        return Promise.reject(error);
                    }
                } catch (_error) {
                    // return Promise.reject(_error);
                    toast.error("مجددا لاگین نمایید.");
                    localStorage.clear();
                    window.location.replace("/");
                }

            } else {
                return Promise.reject(error);
            }
        }
    );

function refresh_token() {
    // console.log('Refreshing access token')
    const headers = {
        'Content-Type': 'application/json'
    }
    return axios.put(Urls.refreshToken(), JSON.stringify(LocalToken.geRefreshToken()), { headers });
}
