import React, { useState } from 'react'
import Modal from '../../../../Component/Kit/Modal'
import UserCodeVerification from './UserCodeVerification';
import UserMobileVerification from './UserMobileVerification';

const ChangeUserMobile = (props) => {
    const { userId, visible, onClose, onCancel, setVisible, onSubmit } = props;
    const [editMobileStep, setEditMobileStep] = useState('mobile');
    const [userMobile, setUserMobile] = useState('');
    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            onCancel={onCancel}
            onClose={onClose}
            headerTitle="تغییر شماره همراه کاربر"
        >
            <div className="col-12 p-0">
                {
                    editMobileStep === "mobile" &&
                    <UserMobileVerification
                        userId={userId}
                        setEditMobileStep={setEditMobileStep}
                        setUserMobile={setUserMobile}
                    />
                }
                {
                    editMobileStep === "code" &&
                    <UserCodeVerification
                        userId={userId}
                        onSubmit={onSubmit}
                        userMobile={userMobile}
                        onClose={() => setVisible(false)}
                        setEditMobileStep={setEditMobileStep}
                    />
                }
            </div>
        </Modal>
    )
}

export default ChangeUserMobile