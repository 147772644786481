import React, {useState} from "react";
import {StyledButton} from "../../../../Component/Kit/Button";
import {Badge} from 'react-bootstrap';
import Modal from "../../../../Component/Kit/Modal";
import NumberLimitationModal from "../../modals/limitation-modal";
import Urls from "../../../../Requester/Urls";

function FastSendTemplateAddLimitation({data}) {
    const [isModal, setIsModal] = useState(false);
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">بیشینه تعداد مجاز قالب ارسال سریع : </span>
                    <Badge className="font-weight-normal" variant="primary">{data}</Badge>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>
            <Modal
                onClose={() => setIsModal(false)}
                onCancel={() => setIsModal(false)}
                visible={isModal}
                headerTitle="بیشینه تعداد مجاز قالب ارسال سریع"
            >
                <NumberLimitationModal defaultData={data} onCloseModal={() => setIsModal(false)} url={Urls.fastSendTemplateAddLimitation()} />
            </Modal>
        </>
    )
}

export default FastSendTemplateAddLimitation
