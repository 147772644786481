import React from 'react'
import { StyledButton } from '../../../Component/Kit/Button'
import { useEffect } from 'react'
import { useState } from 'react'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import { Loader } from '../../../Component/Shared/Loader'
import DateConvert from '../../../Utility/Services/date-convert'
import { SeprateNumberWithComma } from '../../../Utility/Services/SeprateNumberWithComma'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { FormLable, StyledForm, StyledInput } from '../../../Component/Kit/Form'
import { MyDatePicker } from '../../../Component/Shared/DatePicker'
import moment from 'moment-jalaali'
import { IncreaseProviderAmountSchema } from '../../../Utility/Validation/Schema/IncreaseProviderAmountSchema'
import { TimePickerStyled } from '../../../Component/Shared/TimePicker'

const ProviderAmountModal = ({ selectedProviderAmountData, onSubmit, onCloseModal }) => {
    const [providerData, setProviderData] = useState();
    const [loading, setLoading] = useState(false);

    const whoisLogName = {
        chargedAt: "تاریخ شارژ",
        amount: "مقدار",
    };

    const getProviderAmount = (providerId) => {
        ApiClient.Get(Urls.GetProviderAmount(providerId))
            .then(res => {
                res.result.map((m) => {
                    m.chargedAt = DateConvert(m.chargedAt);
                    m.amount = SeprateNumberWithComma(m.amount);
                })
                setProviderData(res.result);
                setLoading(false);
            })
    }

    useEffect(() => {
        setLoading(true);
        if (selectedProviderAmountData.providerId) {
            getProviderAmount(selectedProviderAmountData.providerId);
        }
    }, [selectedProviderAmountData])

    return (
        <>
            <div className="w-100 custom-modal-body">
                <div className="w-100 mt-3">
                    <p className="m-0 font-weight-bold">ثبت افزایش اعتبار: </p>

                    <Formik
                        initialValues={{
                            providerAmount: '',
                            providerChargedAt: '',
                            providerChargeTime: ''
                        }}
                        isInitialValid={false}
                        enableReinitialize={true}
                        validationSchema={IncreaseProviderAmountSchema}
                        onSubmit={(values, actions) => {
                            Swal.fire({
                                title: `آیا مطمئن هستید؟`,
                                text: "",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonText: "انصراف",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "بله، مطمئن هستم",
                            }).then(result => {
                                onCloseModal(true)
                                if (result.isConfirmed) {
                                    const payload = {
                                        providerId: selectedProviderAmountData.providerId,
                                        chargedAt: Number('' + values.providerChargedAt + values.providerChargeTime),
                                        amount: Number(values.providerAmount)
                                    };

                                    ApiClient.Post(Urls.IncreaseProviderAmount(), payload)
                                        .then((res) => {
                                            if (res) {
                                                toast.success('تغییرات  با موفقیت ثبت شد.');
                                                getProviderAmount(selectedProviderAmountData.providerId);
                                                values.providerChargedAt = ''
                                                values.providerChargeTime = ''
                                                actions.resetForm()
                                                onSubmit();
                                            } else {
                                                toast.error(res.result.message)
                                            }
                                        })
                                        .catch().finally(() => {
                                            actions.setSubmitting(false)
                                        })
                                } else {
                                    actions.resetForm();
                                }
                            })
                        }}

                    >
                        {(formik) => (
                            <StyledForm
                                className='px-0'
                                onSubmit={formik.handleSubmit}
                                onBlur={formik.handleBlur}
                                onReset={formik.handleReset}
                            >
                                <div className="row px-sm-3">
                                    <div className="col-12 col-sm-4 px-sm-2">
                                        <StyledInput
                                            lable="مقدار اعتبار"
                                            focus={true}
                                            name="providerAmount"
                                            type="number"
                                            value={formik.values.providerAmount}
                                            error={(formik.errors.providerAmount && formik.touched.providerAmount)
                                                ? formik.errors.providerAmount
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue('providerAmount', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 px-sm-2">
                                        <FormLable show={true}>
                                            تاریخ افزایش اعتبار
                                        </FormLable>
                                        <MyDatePicker
                                            defaultValue={formik.values.providerChargedAt}
                                            value={(event) => formik.setFieldValue("providerChargedAt", event.baseFormat)}
                                            name="providerChargedAt"
                                            max={moment().subtract(0, 'days')}
                                            error={(formik.errors.providerChargedAt && formik.touched.providerChargedAt)
                                                ? formik.errors.providerChargedAt
                                                : false
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4  px-sm-2">
                                        <FormLable show={true}>
                                            ساعت افزایش اعتبار
                                        </FormLable>
                                        <TimePickerStyled
                                            value={(event) => {
                                                if (event)
                                                    formik.setFieldValue("providerChargeTime", event.baseFormat)
                                            }}
                                            defaultValue={formik.values.providerChargeTime}
                                            name="providerChargeTime"
                                            error={(formik.errors.providerChargeTime && formik.touched.providerChargeTime)
                                                ? formik.errors.providerChargeTime
                                                : 'false'
                                            }
                                        />
                                    </div>
                                    <div className="col-12 px-sm-2 d-flex align-items-start">
                                        <StyledButton
                                            disabled={!formik.isValid || !formik.dirty}
                                            btnStyle="greenBtn px-3 w-100"
                                            type="submit"
                                        >
                                            ثبت اعتبار
                                        </StyledButton>
                                    </div>
                                </div>
                            </StyledForm>
                        )}
                    </Formik>
                </div>
                <hr />

                <div className="w-100 mt-3">
                    <p className="ml-1 font-weight-bold">تاریخچه ثبت اعتبار: </p>
                    {
                        loading
                            ?
                            <Loader />
                            :
                            <div className="table-responsive">
                                <table className="factor-table">
                                    <thead>
                                        {whoisLogName && (
                                            <tr className="gray-bg fo-13">
                                                <th>#</th>
                                                <th>{whoisLogName.chargedAt}</th>
                                                <th>{whoisLogName.amount}</th>
                                            </tr>
                                        )}
                                    </thead>
                                    <tbody>
                                        {providerData &&
                                            providerData.map((item, key) => (
                                                <tr className="fo-13" key={`item${key}`}>
                                                    <td>{key + 1}</td>
                                                    <td>{item.chargedAt}</td>
                                                    <td>{item.amount}</td>
                                                </tr>
                                            ))
                                        }
                                        {
                                            providerData && providerData.length == 0 &&
                                            <tr>
                                                <td colSpan={5}>موردی وجود ندارد.</td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                    }

                </div>

            </div>
        </>
    )
}

export default ProviderAmountModal