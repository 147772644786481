import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import NoMatch from './Component/Shared/404';
import PrivateRoute from './Layouts/Container/PrivateRoute';
import { PublicRoute } from './Layouts/Container/PublicRoute';
import { AddUserAdmin } from './Page/AdminManagement/AddUserAdmin';
import { AdminItemDetails } from './Page/AdminManagement/AdminDetails';
import { AdminsList } from './Page/AdminManagement/AdminsList';
import Dashboard from './Page/Dashboard';
import FastSendTemplateList from './Page/FastSendTemplate';
import SendTower from './Page/SendTower';
import { AddReturnInvoice } from './Page/Financial/AddReturnInvoice';
import LendInvoicesList from './Page/Financial/LendInvoicesList';
import { ManualPaymentsList } from './Page/Financial/ManualPaymentsList';
import ManualPaymentItem from './Page/Financial/ManualPaymentsList/ManualPaymentItem';
import { OfficialInvoicesList } from './Page/Financial/OfficialInvoicesList';
import { OfficialInvoiceItem } from './Page/Financial/OfficialInvoicesList/OfficialInvoiceItem';
import OnlinePayment from './Page/Financial/OnlinePayment';
import ForbiddenWordsList from './Page/ForbiddenWord';
import GuestPage from './Page/Guest/';
import EditGuest from './Page/Guest/GuestInformation/EditGuest';
import GuestInformation from './Page/Guest/GuestInformation/index';
import PendingLines from './Page/Lines/PendingLines';
import PendingLineDetails from './Page/Lines/PendingLines/pending-line-details';
import SystemLines from './Page/Lines/System';
import UserLines from './Page/Lines/UserLines';
import Login from './Page/Login';
import NotificationsList from './Page/Notifications';
import AddGlobalNotification from './Page/Notifications/add-notification';
import PendingSMSDetails from './Page/PendingSMS/pending-sms-details';
import AdminProfile from './Page/Profile';
import SettingList from './Page/Settings';
import TicketAnswerTemplates from './Page/TicketAnswerTemplates';
import { AddTemplate } from './Page/TicketAnswerTemplates/AddTemplate';
import { EditTicketAnswerTemplate } from './Page/TicketAnswerTemplates/EditTemplate';
import Tickets from './Page/Tickets';
import { TicketDetail } from './Page/Tickets/TicketDetail';
import TicketComments from './Page/Tickets/TicketComments';
import UserPage from './Page/Users/index';
import UserInfo from './Page/Users/user-info';
import UserTransactions from './Page/Users/UserFinancial/Transactions';
import AddTicket from './Page/Users/UserTicket/add-ticket';
import WhiteUsers from './Page/WhiteUsers';
import { BlackList } from './Page/Blacklist';
import AdminActivityLogs from './Page/AdminManagement/AdminActivityLogs/index';
import { DiscountCodeList } from "./Page/DiscountCode/DiscountCodeList";
import EditUserProfile from "./Page/Users/UserProfile/EditUserProfile";
import SaleCooperationTransaction from "./Page/SaleCooperation/Transaction";
import SaleCooperationSettlement from "./Page/SaleCooperation/Settlement";
import { DiscountCodeLog } from "./Page/DiscountCode/DiscountCodeLog";
import PendingSmsPages from "./Page/PendingSMS/pending-sms-pages";
import UserInvoice from "./Page/Users/UserFinancial/Invoice/Official";
import ConvertUserType from './Page/Users/UserProfile/ConvertUserType';
import ProvidersCreditList from './Page/ProvidersCredit/ProvidersCreditList';
import OldPanelOfficialInvoiceItem from "./Page/Financial/OfficialInvoicesList/OldPanelOfficialInvoiceItem";
import CreditPackageList from './Page/CreditPackage/CreditPackageList';
import ModifyCreditPackageItem from './Page/CreditPackage/ModifyCreditPackageItem';
import Suspended from "./Page/Suspended";
import UserFeedback from './Page/UserFeedback';
import { PlansList } from './Page/Plans/PlansList';
import PlanItemDetail from './Page/Plans/PlanItemDetail';
import PlanFree from "./Page/Plans/PlanFree";
import SingleRepeatVerifySMs from "./Page/PendingSMS/Single-Repeat-verify";
import DedicatedBasePrice from './Page/BasePrice/DedicatedBasePrice';
import PlansLines from './Page/Lines/PlansLines';
import ModifyDiscountCode from './Page/DiscountCode/ModifyDiscountCode';
import ProvidersState from "./Page/ProvidersCredit/providerState";
import { UsersPlan } from './Page/Plans/UsersPlan';
import PublicLines from "./Page/Lines/Public";
import ProviderNotification from "./Page/ProvidersCredit/providerNotification";
import {SendTowerSingle} from "./Page/SendTower/single";
import AutoAssignmentTicket from "./Page/Users/UserTicket/auto-assignment-ticket";
import UserServiceLine from "./Page/Lines/UserServiceLine";
import UserServiceLineDetails from "./Page/Lines/UserServiceLine/Details";
import {OnlinePaymentDetail} from "./Page/Financial/OnlinePayment/Detail";
import CampaignsPage from "./Page/campaigns";
import AccessPublic from "./Page/Lines/AccessPublic";
import {SepidarLogs} from "./Page/Financial/SepidarLogs";

const AppRoute = () => {
  return (
    <div className="AppContainer">
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted={true} component={Login} path="/" exact />
          <PrivateRoute component={Dashboard} path="/dashboard" exact />

          <PrivateRoute component={GuestPage} roles={['supporter', 'sales', 'supervisor']} path="/guests" />
          <PrivateRoute component={GuestInformation} roles={['supporter', 'sales', 'supervisor']} path="/guest/item/:id" />
          <PrivateRoute component={EditGuest} roles={['supporter', 'sales', 'supervisor']} path="/guest/:id/edit" />

          <PrivateRoute component={Tickets} path="/ticket/list" exact />
          <PrivateRoute component={TicketDetail} path="/ticket/list/:id" />
          <PrivateRoute component={TicketComments} path='/ticket/comments/:id' />
          <PrivateRoute component={AddTicket} path="/ticket/add/:id" />
          <PrivateRoute component={AutoAssignmentTicket} path="/ticket/auto-assignment" exact />

          <PrivateRoute component={SystemLines} roles={['supporter', 'supervisor']} path="/line/system" exact />
          <PrivateRoute component={UserServiceLine} roles={['supporter', 'supervisor']} path="/line/user-service-line" />
          <PrivateRoute component={UserServiceLineDetails} roles={['supporter', 'supervisor']} exact path="/line/user-service-line-detail/:id" />
          <PrivateRoute component={PublicLines} roles={['supporter', 'supervisor']} path="/line/public" exact />
          <PrivateRoute component={AccessPublic} roles={['supporter', 'supervisor']} path="/line/access-public" exact />
          <PrivateRoute component={UserLines} roles={['supporter', 'supervisor']} path="/line/user-lines" exact />
          <PrivateRoute component={PendingLines} roles={['supporter', 'supervisor']} path="/line/user-lines/pending" exact />
          <PrivateRoute component={PendingLineDetails} roles={['supporter', 'supervisor']}
            path="/line/user-lines/pending/:id" exact />
          <PrivateRoute component={PlansLines} roles={['supporter', 'supervisor']} path="/line/plans" />

          <PrivateRoute component={TicketAnswerTemplates} roles={['supporter', 'sales', 'supervisor']}
            path="/ticket/template" exact />
          <PrivateRoute component={EditTicketAnswerTemplate} roles={['supporter', 'sales', 'supervisor']}
            path="/ticket/template/id=:id" />
          <PrivateRoute component={AddTemplate} path="/ticket/template/add" roles={['supporter', 'supervisor']} />

          <PrivateRoute component={CampaignsPage} path="/campaigns" />

          <PrivateRoute component={UserPage} path="/users" />
          <PrivateRoute component={UserInfo} path="/userDetail/:id" />
          <PrivateRoute component={EditUserProfile} path="/user/:id/profile/edit" />
          <PrivateRoute component={ConvertUserType} path="/user/:id/profile/type" />

          <PrivateRoute component={UserInvoice} path="/user/:id/financial/invoice" exact />
          <PrivateRoute component={UserTransactions} path="/user/:id/financial/invoice/transactions" />
          <PrivateRoute component={OnlinePayment} path="/user/:id/financial/invoice/payments" />

          <PrivateRoute component={ManualPaymentsList} roles={['supporter', 'supervisor']} path="/financial/manual-payment"
            exact />
          <PrivateRoute component={ManualPaymentItem} path="/financial/manual-payment/:id" />
          <PrivateRoute component={OnlinePayment} path="/financial/online-payment" exact />
          <PrivateRoute component={OnlinePaymentDetail} path="/financial/online-payment/:id" exact />
          <PrivateRoute component={SepidarLogs} roles={['accountant']} path="/financial/sepidar-logs" exact />
          <PrivateRoute component={OfficialInvoicesList} path="/financial/invoices" />
          <PrivateRoute component={OldPanelOfficialInvoiceItem} path="/financial/invoice/old/:id" exact />
          <PrivateRoute component={OfficialInvoiceItem} path="/financial/invoice/:id/:isReturn" />
          <PrivateRoute component={AddReturnInvoice} path="/financial/invoice/returnInvoice" exact />
          <PrivateRoute component={LendInvoicesList} path="/financial/invoice/lend" exact />

          <PrivateRoute component={AdminsList} roles={['supervisor']} path="/admins" exact />
          <PrivateRoute component={AdminItemDetails} roles={[]} path="/admins/view/:id" />
          <PrivateRoute component={AddUserAdmin} roles={[]} path="/admins/addUserAdmin" exact />
          <PrivateRoute component={AdminActivityLogs} roles={['supervisor']} path="/admins/logs" exact />
          <PrivateRoute component={AdminProfile} path="/profile" exact />

          <PrivateRoute component={PendingSmsPages} roles={['supporter', 'supervisor']} path="/pending-sms" />
          <PrivateRoute component={PendingSMSDetails} roles={['supporter', 'supervisor']} path="/sms/:id/:type" />

          <PrivateRoute component={SingleRepeatVerifySMs} roles={['supporter', 'supervisor']} path="/high-consumption-webservice/:id" />
          <PrivateRoute component={NotificationsList} roles={['supporter', 'sales', 'supervisor']} path="/notifications"
            exact />
          <PrivateRoute component={AddGlobalNotification} roles={['supporter', 'sales', 'supervisor']} path="/notifications/edit/:id"/>
          <PrivateRoute component={AddGlobalNotification} path="/notifications/add" roles={['supporter', 'sales', 'supervisor']} />

          <PrivateRoute component={FastSendTemplateList} roles={['supporter', 'supervisor']} path="/fast-send" />

          <PrivateRoute component={WhiteUsers} path="/white-users" roles={['supervisor']} />

          <PrivateRoute component={BlackList} path="/blacklist" roles={['supporter', 'supervisor']} />

          {/* <PrivateRoute component={DiscountCodeList} path="/discount-code/list" roles={[]} exact /> */}
          {/* <PrivateRoute component={AddDiscount} path="/discount-code/add" roles={[]} />
          <PrivateRoute component={DiscountItemDetail} path="/discount-code/:id" roles={[]} exact />
          <PrivateRoute component={DiscountCodeLog} path="/discount-code/:id/log" roles={[]} exact /> */}
          {/* TODO Change this */}

          <PrivateRoute component={DiscountCodeList} path="/discount-code" roles={['supervisor']} exact />
          <PrivateRoute component={ModifyDiscountCode} path="/discount-code/add" roles={['supervisor']} />
          <PrivateRoute component={ModifyDiscountCode} path="/discount-code/:id" roles={['supervisor']} exact />
          <PrivateRoute component={DiscountCodeLog} path="/discount-code/:id/log" roles={['supervisor']} exact />

          <PrivateRoute component={ForbiddenWordsList} path="/forbidden-words" roles={['supervisor']} />

          <PrivateRoute component={SettingList} path="/settings" roles={['supporter', 'supervisor']} />

          <PrivateRoute component={SaleCooperationTransaction} path="/sale-cooperation/transaction" />
          <PrivateRoute component={SaleCooperationSettlement} path="/sale-cooperation/settlement" />

          {/*<PrivateRoute component={BlockedNumbersList} roles={['supporter', 'supervisor']} path="/blocked-ip" exact />*/}
          <PrivateRoute component={Suspended} roles={['supporter', 'supervisor']} path="/suspended" />

          <PrivateRoute component={ProvidersCreditList} path="/providers/credit" exact />
          <PrivateRoute component={ProvidersState} path="/providers/state" exact />
          <PrivateRoute component={ProviderNotification} path="/providers/notification" exact />

          <PrivateRoute component={CreditPackageList} path="/credit-package" exact />
          <PrivateRoute component={ModifyCreditPackageItem} path="/credit-package/add" exact />
          <PrivateRoute component={ModifyCreditPackageItem} path="/credit-package/:id" exact />

          <PrivateRoute component={UserFeedback} path="/feedback" exact />

          <PrivateRoute component={DedicatedBasePrice} path="/base-price/dedicated" roles={['supervisor']} />

          <PrivateRoute component={PlansList} roles={['supervisor']} path="/plan" exact />
          <PrivateRoute component={PlanItemDetail} roles={['supervisor']} path="/plan/:id/detail" exact />
          <PrivateRoute component={PlanItemDetail} roles={['supervisor']} path="/plan/add" exact />
          <PrivateRoute component={UsersPlan} roles={['supervisor']} path="/plan/:id/users" exact />
          <PrivateRoute component={PlanFree} roles={['supervisor']} path="/plan/free" exact />
          <PrivateRoute component={SendTowerSingle}  path="/send-tower/single/:id" exact/>

          <PrivateRoute component={SendTower}  path="/send-tower" />

          <PrivateRoute component={NoMatch} path="*" />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default AppRoute;
