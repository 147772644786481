export const theme = {
  white:           "#FFFFFF",
  black:           "#000000",
  primary:         "#6993ff",
  primaryHover:    "rgba(105, 147, 255 ,0.5)",
  secondary:       "#13dad7",

  secondaryGreen:  "#5aba47",

  greenBtn:        "#1bc5bd",
  greenDisable:    "rgba(27, 197, 189, 0.5)",
  greenBtnHover:   "#0bb7af",

  darkGreenBtn:   "#0bb7af",
  darkGreenDisable:"rgba(11, 183, 175, 0.5)",
  darkGreenBtnHover:"#0aa7a0",

  redBtn:          "#f64e60",
  redDisable:      "rgba(246, 78, 96, 0.5)",
  redBtnHover:     "#ee2d41",

  yellowBtn:      "#FDD835",
  yellowDisable:  "rgba(253, 216, 53 , 0.5)",
  yellowBtnHover: "#FBC02D",

  grayBtn:        "#e5eaee",
  grayDisable:    "rgba(229, 234, 238, 0.5)",
  grayBtnHover:   "#d6d6e0",
  $paramsListBg:  "#f3f3f3",
  gray:           "#495057",
  grayDark:       "#616161",
  grey4:          '#f2f4f7',
  grey5:          '#cacaca',
  yellow:         "#fcc51e",
  lightGray:      "#f8f9fc",
  darkGreen:      "#007a39",
  lightGreen:     "#edfaf3",
  borderGreen:    "#008840",
  greenBlue:      "#becae0",
  paleGrey:       "#e8ecf4",
  red:            "#f25a5a",
  headerHeight:   "65px",

  blue:           "#6993ff",
  $hoverBlue:     "#4a7dff",
  darkBlue:       "#1d5cff",
  lightBlue:      "#e1e9ff",
  label:         "#5b6980",

  radius: '8px',
};
