import React from "react";
import {Badge} from 'react-bootstrap';
import {StyledSwitchBox} from "../../../../Component/Kit/Form";
import ApiClient from "../../../../Requester/ApiClient";
import {connect,useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {settingModal} from "../../../../Actions/setting-modal";


function SettingSwitchRow({data ,url,title}) {
    let dispatch = useDispatch();
    const switchHandler = (body) => {
        body = JSON.stringify(body)
        ApiClient.Patch(url,body).then(res => {
            toast.success('تغییرات  با موفقیت ثبت شد.');
            dispatch(settingModal(true));
        })
    }
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">{title} </span>
                    <Badge className="font-weight-normal" variant="primary">
                        {
                            (data === 'true')?'فعال':'غیرفعال'
                        }
                    </Badge>
                </div>
                <StyledSwitchBox checked={(data === 'true')} onChange={(e)=>switchHandler(e.target.checked)} />
            </div>
        </>
    )
}
export default connect()(SettingSwitchRow);
