import React, { useState } from 'react'
import { StyledButton } from '../../../../Component/Kit/Button'
import Modal from '../../../../Component/Kit/Modal';
import Urls from '../../../../Requester/Urls'
import NumberLimitationModal from "../../modals/limitation-modal";
import { Badge } from 'react-bootstrap';

const RecipientLimitationCountInPerWebService = ({data}) => {
    const [isModal, setIsModal] = useState(false);
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">محدودیت تعداد مخاطبین در هر ارسال (وب سرویس) :</span>
                    <Badge className="font-weight-normal" variant="primary">{data}</Badge>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>
            <Modal
                onClose={() => setIsModal(false)}
                onCancel={() => setIsModal(false)}
                visible={isModal}
                headerTitle="محدودیت تعداد مخاطبین در هر ارسال (وب سرویس)"
            >
                <NumberLimitationModal defaultData={data} onCloseModal={() => setIsModal(false)} url={Urls.recipientLimitationCountInPerSendApi()} />
            </Modal>
        </>
    )
}

export default RecipientLimitationCountInPerWebService