import { Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../../Component/Kit/Button';
import { StyledForm, StyledInput } from '../../../../../Component/Kit/Form';
import ApiClient from '../../../../../Requester/ApiClient';
import Urls from '../../../../../Requester/Urls';
import { EditUserCodeVerificationSchema } from '../../../../../Utility/Validation/Schema/EditUserCodeVerificationSchema';

const UserCodeVerification = (props) => {
  const { setEditMobileStep, userMobile, userId, onSubmit, onClose } = props;
  const [submitLoading, setSubmitLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        otpCode: '',
      }}
      validationSchema={EditUserCodeVerificationSchema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        setSubmitLoading(true);
        let body = {
          mobile: Number(userMobile),
          code: Number(values.otpCode),
          usage: 5,
        };
        ApiClient.Patch(Urls.modifyUserVerificationCode(userId), body)
          .then((res) => {
            setSubmitLoading(false);
            toast.success('تغییر شماره با موفقیت اعمال شد.');
            actions.resetForm();
            setEditMobileStep('mobile');
            onSubmit();
            onClose();
          })
          .catch(err => {
            setSubmitLoading(false);
            actions.resetForm();
          })
      }}
    >
      {(formik) => (
        <StyledForm
          className="mt-3"
          onSubmit={formik.handleSubmit}
          onBlur={formik.handleBlur}>
          <div className="row">
            <div className="col-12">
              <StyledInput
                lable="کد فعالسازی"
                focus={true}
                type="text"
                name='otpCode'
                value={formik.values.otpCode || ''}
                error={(formik.errors.otpCode && formik.touched.otpCode)
                  ? formik.errors.otpCode
                  : 'false'
                }
                onChange={(e) =>
                  formik.setFieldValue("otpCode", e.target.value)
                }
              />
            </div>
          </div>
          <div className="text-left">
            <StyledButton
              disabled={(!formik.isValid || !formik.dirty) && !submitLoading}
              loading={submitLoading ? 1 : 0}
              type="submit"
              btnStyle="greenBtn" 
            >
              ثبت کدفعالسازی
            </StyledButton>
          </div>
        </StyledForm>
      )}
    </Formik>
  )
}

export default UserCodeVerification;