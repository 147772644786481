import React, { useState } from "react";
import { Badge } from 'react-bootstrap';
import { StyledButton } from "../../../../Component/Kit/Button";
import Modal from "../../../../Component/Kit/Modal";
import Urls from "../../../../Requester/Urls";
import EnumFlag from "../../../../Utility/Services/enum-flag";
import GetReactionToUserTicket from "../../Services/reaction-to-user-ticket";
import ReactionToUserTicketModal from "./reaction-to-user-ticket-modal";

function ReactionToUserTicket({ data }) {
    const [isModal, setIsModal] = useState(false);
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">واکنش به تیکت ایجاد شده : </span>
                    <Badge className="zfont-weight-normal" variant="primary">

                        {GetReactionToUserTicket(EnumFlag([1, 2, 4], data)).map((m, x) =>
                            GetReactionToUserTicket(EnumFlag([1, 2, 4], data)).length - 1 === x ? m : m + " ، "
                        )}

                    </Badge>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>
            <Modal
                onClose={() => setIsModal(false)}
                onCancel={() => setIsModal(false)}
                visible={isModal}
                headerTitle="واکنش به تیکت ایجاد شده"
            >
                <ReactionToUserTicketModal defaultData={EnumFlag([1, 2, 4], data)}
                    onCloseModal={() => setIsModal(false)}
                    url={Urls.fifteenRemarkCoefficient()} />
            </Modal>
        </>
    )
}

export default ReactionToUserTicket
