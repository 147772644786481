import React, {useState, useEffect} from 'react';

// const CountDownTimer = ({ props, timeLeft , handleResendCode}) => {
const CountDownTimer = (props) => {
    const {timeLeft, handleResendCode} = props;
    const secondLeft = timeLeft;

    const [seconds, setSeconds] = useState(secondLeft);
    const [showTime, setShowTime] = useState(null);

    const SecondsToms = (seconds) => {
        if (!seconds) return '';
        let duration = seconds;
        let min = parseInt(duration / 60);
        duration = duration % (60);

        let sec = parseInt(duration);

        if (sec < 10) {
            sec = `${sec}`;
        }
        if (min < 10) {
            min = `${min}`;
        }
        if (min == 0) {
            return `0:${sec}`
        } else {
            return `${min}:${sec}`
        }
    }
    const resendCode = () => {
        handleResendCode();
        setSeconds(timeLeft)
    }
    useEffect(() => {
        let interval = null;
        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds - 1);
                setShowTime(SecondsToms(seconds))
            }, 1000);

        } else {
            clearInterval(interval);
            setSeconds(0);
        }
        return () => {
            clearInterval(interval);
        }
    }, [seconds, showTime]);

    return (
        <>
            <div className={`timeoutCounter ${seconds > 0 ? 'show' : 'hide'}`}>
                <p>
                    <span>{showTime}</span>
                    ارسال خواهد شد.
                </p>
            </div>
            <div className={`resend ${seconds <= 0 ? 'show' : 'hide'}`} onClick={() => resendCode()}>
                دریافت مجدد کد
            </div>
        </>
    );
};

export default CountDownTimer;
