import { Formik } from 'formik';
import moment from "moment-jalaali";
import React from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { StyledButton } from '../../../../Component/Kit/Button';
import { FormLable, StyledForm, StyledInput, StyledSelect, StyledTextArea } from '../../../../Component/Kit/Form';
import Modal from '../../../../Component/Kit/Modal';
import { MyDatePicker } from '../../../../Component/Shared/DatePicker';
import { TimePickerStyled } from '../../../../Component/Shared/TimePicker';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import { ModifyLendInvoiceItemSchema } from '../../../../Utility/Validation/Schema/ModifyLendInvoiceItemSchema';

export const LendInvoiceItemModal = (props) => {
    const { visible, setVisible, onCancel, onClose, data, dataChange, banksProviderList } = props;
    // const history = useHistory();

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            headerTitle="فیش افزایش شارژ بدون پرداخت"
        >

            <div className="col-12 px-0 pt-3 pb-3">
                {
                    data && data.credit && (
                        <Formik
                            initialValues={{
                                creditCount: data.credit,
                                finalPrice: data.finalPrice,
                                lendDescription: data.description,
                                trackingNumber: '',
                                payerFullName: '',
                                paymentDueDate: '',
                                paymentDueTime: '',
                                paymentDescription: '',
                            }}
                            validationSchema={ModifyLendInvoiceItemSchema}
                            isInitialValid={false}

                            onSubmit={(values, actions) => {

                                const dataBody = {
                                    "bankId": parseInt(values.bankProvider),
                                    "paidAt": parseInt(values.paymentDueDate + values.paymentDueTime),
                                    "trackingNumber": values.trackingNumber,
                                    "payerFullName": values.payerFullName,
                                    "userDescription": values.paymentDescription
                                }

                                ApiClient.Patch(Urls.ModifyLendInvoices(data.id), dataBody)
                                    .then(res => {
                                        toast.success('پرداخت با موفقیت انجام شد.');
                                        setVisible(false);
                                        dataChange(true)
                                        actions.resetForm();
                                        // window.location.reload();
                                    })
                                    .catch(err => actions.resetForm())
                            }}

                            onReset={(values, formikBag) => {
                                setVisible(false);
                            }}
                        >
                            {(formik) => (
                                <StyledForm
                                    onSubmit={formik.handleSubmit}
                                    onReset={formik.handleReset}
                                    onBlur={formik.handleBlur}
                                >
                                    <div className="row">
                                        <StyledInput
                                            formGroupClass="col-md-6 col-12"
                                            lable="تعداد کریدیت"
                                            focus={true}
                                            error="false"
                                            readOnly
                                            name="creditCount"
                                            value={formik.values.creditCount}
                                        />
                                        <StyledInput
                                            formGroupClass="col-md-6 col-12"
                                            lable="مبلغ قابل پرداخت"
                                            focus={true}
                                            error="false"
                                            readOnly
                                            name="finalPrice"
                                            value={formik.values.finalPrice}
                                        />
                                        <StyledTextArea
                                            formGroupClass="col-md-12 col-12"
                                            lable="توضیحات خرید"
                                            focus={true}
                                            error="false"
                                            readOnly
                                            name="lendDescription"
                                            value={formik.values.lendDescription}
                                        />
                                        <StyledSelect
                                            formGroupClass="col-md-12 col-12"
                                            lable="بانک پرداخت کرده "
                                            focus={true}
                                            name="bankProvider"
                                            hasDefaultOption
                                            value={formik.values.bankProvider !== undefined
                                                ? formik.values.bankProvider
                                                : formik.initialValues.bankProvider}
                                            optionItems={banksProviderList}
                                            error={(formik.errors.bankProvider && formik.touched.bankProvider)
                                                ? formik.errors.bankProvider
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("bankProvider", e.target.value)}
                                        />
                                        <StyledInput
                                            formGroupClass="col-md-6 col-12"
                                            lable="شماره پیگیری"
                                            placeholder=""
                                            focus={true}
                                            name="trackingNumber"
                                            value={formik.values.trackingNumber}
                                            error={(formik.errors.trackingNumber && formik.touched.trackingNumber)
                                                ? formik.errors.trackingNumber
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("trackingNumber", e.target.value)}
                                        />
                                        <StyledInput
                                            formGroupClass="col-md-6 col-12"
                                            lable="نام و نام خانوادگی پرداخت کننده"
                                            placeholder=""
                                            focus={true}
                                            name="payerFullName"
                                            value={formik.values.payerFullName}
                                            error={(formik.errors.payerFullName && formik.touched.payerFullName)
                                                ? formik.errors.payerFullName
                                                : 'false'
                                            }
                                            onChange={(e) => formik.setFieldValue("payerFullName", e.target.value)}
                                        />

                                        <div className="col-12 col-md-6">
                                            <FormLable show={true}>
                                                تاریخ ثبت
                                            </FormLable>
                                            <MyDatePicker
                                                value={(event) => {
                                                    if (event)
                                                        formik.setFieldValue("paymentDueDate", event.baseFormat)
                                                }}
                                                name="paymentDueDate"
                                                max={moment().subtract(0, 'days')}
                                                error={(formik.errors.paymentDueDate && formik.touched.paymentDueDate)
                                                    ? formik.errors.paymentDueDate
                                                    : false
                                                }
                                            />
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <FormLable show={true}>
                                                ساعت پرداخت
                                            </FormLable>
                                            <TimePickerStyled
                                                focus={true}
                                                value={(event) => {
                                                    if (event)
                                                        formik.setFieldValue("paymentDueTime", event.baseFormat)
                                                }}
                                                name="paymentDueTime"
                                                error={(formik.errors.paymentDueTime && formik.touched.paymentDueTime)
                                                    ? formik.errors.paymentDueTime
                                                    : 'false'
                                                }
                                            />
                                        </div>

                                        <StyledTextArea
                                            formGroupClass="col-md-12 col-12"
                                            lable="توضیحات پرداخت"
                                            focus={true}
                                            name="paymentDescription"
                                            value={formik.values.paymentDescription}
                                            error={(formik.errors.paymentDescription && formik.touched.paymentDescription)
                                                ? formik.errors.paymentDescription
                                                : 'false'
                                            }
                                            onChange={(e) => {
                                                const str = e.target.value.trim() !== '' ? e.target.value : '';
                                                formik.setFieldValue("paymentDescription", str)
                                            }}
                                        />
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <StyledButton
                                            btnStyle="greenBtn ml-3"
                                            disabled={!formik.isValid || !formik.dirty}
                                            width="38%"
                                            type="submit"
                                        >
                                            پرداخت شده
                                        </StyledButton>

                                        <StyledButton
                                            width="35%"
                                            btnStyle="grayBtn"
                                            type="reset"
                                        >
                                            بستن
                                        </StyledButton>
                                    </div>

                                </StyledForm>
                            )}

                        </Formik>
                    )
                }

            </div>
        </Modal>
    )
}
