import React from "react";
import styled from "styled-components";
import copounIcon from "../../Assets/images/copoun_icon.png";

export const DiscountCodeWrapper = styled.div`
  width: 100%;
  border: 2px dashed ${(props) => props.theme.grayBtnHover};
  border-radius: 6px;
  padding: 2rem;
  .discount-row {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .code-detail {
    width: calc(100% - 250px - 2.5rem);
    padding-left: 2rem;
    margin-left: 2.5rem;
    border-left: 2px dashed ${(props) => props.theme.grayBtnHover};
    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    li {
      flex: calc(50% - 8px);
      flex-grow: inherit;
      display: flex;
      flex-wrap: wrap;
      margin: 8px 4px;
      width: 50%;
    }
    @media only screen and (min-width: 1300px) {
      .params__list__label {
        border-left: 4px solid #fff;
        border-right: none;
      }

      .params__list__value {
        border-left: 4px solid #fff;
        border-right: none;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    padding: 1rem;
    .code-detail {
      width: calc(100% - 225px - 1.5rem);
      padding-left: 1rem;
      margin-left: 1.5rem;
    }
  }
  @media only screen and (max-width: 1024px) {
    padding: 1.5rem;
    .discount-row {
      flex-direction: column;
    }
    .code-detail {
      border-left: none;
      border-top: 2px dashed #d6d6e0;
      padding-left: 0;
      padding-top: 1rem;
      margin-left: 0;
      margin-top: 1.5rem;
      width: 100%;
      li {
        flex: 100%;
      }
    }
  }
`;

export const DisCountDateWrapper = styled.div`
  .dotes {
    border: 2px dashed ${(props) => props.theme.grayBtnHover};
    border-radius: 6px;
    padding: 2rem 1rem 3rem 1rem;
    margin: 0 0 1.5rem;
  }
`;

export const DiscountCopoun = styled.div`
  width: 254px;
  height: 150px;
  display: block;
  position: relative;
  img {
    filter: opacity(0.5);
    display: inline-block;
    width: 100%;
    height: auto;
  }
  .d-code {
    color: ${(props) => props.theme.black};
    width: calc(100% - 30px);
    text-align: center;
    font-weight: bold;
    font-size: 19px;
    position: absolute;
    left: 15px;
    top: 49%;
  }
  @media only screen and (max-width: 1200px) {
    width: 225px;
    height: 133px;
  }
  @media only screen and (max-width: 1024px) {
    width: 254px;
    height: 150px;
  }
`;

export const DiscountCopounIcon = (props) => {
  const { code } = props;
  return (
    <DiscountCopoun>
      <span className="d-code">{code}</span>
      <img src={copounIcon} alt="کد تخفیف" />
    </DiscountCopoun>
  );
};

export const StepWizardWrapper = styled.div`
  padding-top: 1rem;
`;
