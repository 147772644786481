import {
    guestFirstName,
    guestLastName,
    guestFatherName,
    nationalId,
    guestCertificateNo,
    guestBirthDate,
    guestPhoneNumber,
    guestPostalCode,
    guestAddress,
    guestCompanyActivityField,
    guestCompanyName,
    guestRegistrationNumber,
    guestLegalNationalId,
    guestCompanyType,
    cityId,
    provinceId,
} from "../Rules";
import * as Yup from "yup";

export const EditGuestSchema = Yup.object().shape({
    guestFirstName,
    guestLastName,
    guestFatherName,
    nationalId,
    guestCertificateNo,
    guestBirthDate,
    guestPhoneNumber,
    guestPostalCode,
    guestAddress,
    cityId,
    provinceId,
    guestCompanyActivityField,
});
export const EditGuestLegalSchema = Yup.object().shape({
    guestFirstName,
    guestLastName,
    guestFatherName,
    nationalId,
    guestCertificateNo,
    guestBirthDate,
    guestPhoneNumber,
    guestPostalCode,
    guestAddress,
    guestCompanyActivityField,
    guestCompanyName,
    guestRegistrationNumber,
    guestLegalNationalId,
    cityId,
    provinceId,
    guestCompanyType,
});
