import React, {useCallback, useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {StyledButton} from "../../../../Component/Kit/Button";
import DateConvert from "../../../../Utility/Services/date-convert";
import {GetBankProvider} from "../../../../Utility/Services/get-bank-provider";
import {SeprateNumberWithComma} from "../../../../Utility/Services/SeprateNumberWithComma";
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from "../../../../Component/Kit/Layout";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import {useParams} from "react-router-dom";
import {PaymentStatusService} from "../../../../Utility/Services/PaymentStatusService";
import {Loader} from "../../../../Component/Shared/Loader";
import {toast} from "react-toastify";
import {Formik} from "formik";
import {FormLable, StyledForm} from "../../../../Component/Kit/Form";
import {MyDatePicker} from "../../../../Component/Shared/DatePicker";
import moment from "moment-jalaali";
import * as Yup from "yup";
import BackToPage from "../../../../Component/Kit/back";
import {UserInformationItem, UserInformationWrapper} from "../../../Users/UserProfile/UserInformation/style";
import {PaymentConfirmationModal} from "../PaymentConfirmationModal";


export const OnlinePaymentDetail = () => {
    const {id} = useParams();
    const [convertToOfficialModal, setConvertToOfficialModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [onlinePaymentDetail, setOnlinePaymentDetail] = useState({});
    const [onlinePaymentTitles, setOnlinePaymentTitles] = useState({
        userName: "نام کاربری",
        payerFullName: "نام پرداخت کننده",
        description: "توضیحات",
        statusName: "وضعیت پرداختی",
        isOnlineName: 'روش پرداخت',
        bankName: "نام بانک",
        paidAt: "تاریخ پرداخت",
        count: "تعداد",
        basePriceString: "قیمت پایه",
        netPriceString: "قیمت خام",
        discountPriceString: "تخفیف",
        fifteenRemarkPriceString: "لایحه 15",
        vatPrice: "مالیات بر ارزش افزوده (9 درصد)",
        finalPrice: "مبلغ کل",
        createdAt: "تاریخ ثبت",
        trackingNumber: "شناسه پرداخت",
        isOfficial: "نوع پرداخت",
    });



    const formatPrice = (value) => `${SeprateNumberWithComma(value)} ریال`;

    const formatDateString = (date) => DateConvert(date);

    const formatStatus = (status) => PaymentStatusService(status);

    const formatIsOnline = (isOnline) => (isOnline ? 'آنلاین' : 'آفلاین');

    const formatIsOfficial = (isOfficial, canBeChangeToOfficial) => {
        if (isOfficial) {
            return 'رسمی';
        } else {
            return 'غیر رسمی'
        }
    };

    const formatBankName = (bankId) => GetBankProvider(bankId);

    const formatPriceString = (value) => (value ? formatPrice(value) : '-');

    const formatVatPrice = (vatPrice) => `${SeprateNumberWithComma(vatPrice)} ریال`;

    const formatData = (data) => {
        const formattedData = { ...data };
        Object.entries(formattedData).forEach(([item, value]) => {
            switch (item) {
                case 'finalPrice':
                    formattedData.finalPrice = formatPrice(data.finalPrice);
                    break;
                case 'createdAt':
                    formattedData.createdAt = formatDateString(data.createdAt);
                    break;
                case 'status':
                    formattedData.statusName = formatStatus(data.status);
                    break;
                case 'paidAt':
                    formattedData.paidAt = formatDateString(data.paidAt);
                    break;
                case 'isOnline':
                    formattedData.isOnlineName = formatIsOnline(data.isOnline);
                    break;
                case 'isOfficial':
                    formattedData.isOfficial = formatIsOfficial(data.isOfficial, data.canBeChangeToOfficial);
                    break;
                case 'bankId':
                    formattedData.bankName = formatBankName(data.bankId);
                    break;
                case 'netPrice':
                    formattedData.netPriceString = formatPrice(value);
                    break;
                case 'discountPrice':
                    formattedData.discountPriceString = formatPrice(value);
                    break;
                case 'fifteenRemarkPrice':
                    formattedData.fifteenRemarkPriceString = formatPriceString(data.fifteenRemarkPrice);
                    break;
                case 'basePrice':
                    formattedData.basePriceString = formatPrice(value);
                    break;
                case 'vatPrice':
                    formattedData.vatPrice = formatVatPrice(data.vatPrice);
                    break;
                case 'vatPercent':
                    setOnlinePaymentTitles({
                        ...onlinePaymentTitles,
                        vatPrice: `مالیات بر ارزش افزوده (${formattedData.vatPercent} درصد)`
                    })
                    break;
                default:
                    formattedData[item] = value;
                    break;
            }
        });

        return formattedData;
    };
    

    const getData = useCallback(() => {
        ApiClient.Get(Urls.onlinePaymentById(id))
            .then(res => {
                const data = formatData(res.result);
                setOnlinePaymentDetail(data);
                setLoading(false);
            })
    }, [id]);

    useEffect(() => {
        getData()
    }, [getData]);


    const openConvertToOfficialModal = () => {
        if (!onlinePaymentDetail.canGiveOfficialAccess) {
            ApiClient.Delete(Urls.GiveOfficialAccess(onlinePaymentDetail.id)).then(
                () => {
                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    setTimeout(() => {
                        getData();
                    }, 100)
                }
            )
        } else {
            setConvertToOfficialModal(true);
        }

    }

    const PaymentAccessButton = ({ onClick, canGiveOfficialAccess, canBeChangeToOfficial }) => {
        if (!canGiveOfficialAccess && !canBeChangeToOfficial) {
            return null;
        }

        const buttonText = canGiveOfficialAccess ? 'دسترسی پرداخت ارزش افزوده' : 'اخذ دسترسی پرداخت ارزش افزوده';

        return (
            <StyledButton
                width="260px"
                btnStyle="greenBtn"
                type="reset"
                onClick={onClick}
            >
                {buttonText}
            </StyledButton>
        );
    };

    const handleClose = (event) => {
        if (event) setConvertToOfficialModal(false);
    }

    return (<>
        <WrapperLayout>
            <HeaderContent className="justify-content-between">
                <div>
                    {!loading ? (
                        <PaymentAccessButton
                            onClick={() => openConvertToOfficialModal()}
                            canGiveOfficialAccess={onlinePaymentDetail.canGiveOfficialAccess}
                            canBeChangeToOfficial={onlinePaymentDetail.canBeChangeToOfficial}
                        />
                    ) : null}
                </div>

                <BackToPage/>
            </HeaderContent>
            <MainContent>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout>
                            <h5>جزئیات پرداخت</h5>
                            <span>در این بخش جزئیات پرداخت کاربر قابل مشاهده است.</span>
                        </PageTitleLayout>

                        {
                            loading ?
                                <Loader/>
                                :
                                <>
                                    <div className="col-12 wrapper-box">
                                        <UserInformationWrapper>
                                            {Object.entries(onlinePaymentTitles).map(([key, value]) => (
                                                <UserInformationItem label={value} dataItem={onlinePaymentDetail[key]}
                                                                     key={key}/>
                                            ))}
                                        </UserInformationWrapper>
                                    </div>
                                </>
                        }

                    </div>

                </div>

            </MainContent>
        </WrapperLayout>

        {convertToOfficialModal &&

            <Modal centered show={convertToOfficialModal} onHide={() => {
                handleClose(true)
            }}>
                <Modal.Header>
                    <Modal.Title>فعال سازی پرداخت ارزش افزوده</Modal.Title>
                    <i onClick={() => {
                        handleClose(true)
                    }} className="close-btn fa fa-xmark"/>
                </Modal.Header>
                <Modal.Body>

                    <Formik
                        initialValues={{
                            publicLineDate: '',
                        }}
                        validationSchema={Yup.object().shape({
                            publicLineDate: Yup.string()
                                .required("این فیلد الزامی است."),
                        })}

                        onSubmit={(values, formikHelpers) => {
                            let data = {
                                expireAt: values.publicLineDate
                            }

                            if (onlinePaymentDetail.canGiveOfficialAccess) {
                                ApiClient.Post(Urls.GiveOfficialAccess(onlinePaymentDetail.id), JSON.stringify(data)).then(
                                    () => {
                                        toast.success('تغییرات  با موفقیت ثبت شد.');
                                        setConvertToOfficialModal(!convertToOfficialModal)
                                        formikHelpers.resetForm();
                                        formikHelpers.setSubmitting(false);
                                        getData()
                                    }
                                )
                            } else {

                            }


                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                            >
                                <FormLable show={true}>فعال تا تاریخ</FormLable>
                                <MyDatePicker
                                    haveTime
                                    defaultValue={formik.values.publicLineDate}
                                    value={(event) => {
                                        formik.setFieldValue("publicLineDate", event.baseFormat);
                                    }}
                                    min={moment().subtract(0, 'days')}
                                    // max={moment().subtract(18, 'years')}
                                    name="publicLineDate"
                                    error={
                                        formik.errors.publicLineDate !== undefined
                                            ? formik.errors.publicLineDate
                                            : false
                                    }
                                />

                                <div className="d-flex justify-content-between mt-3">
                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => setConvertToOfficialModal(false)}

                                    >
                                        بستن
                                    </StyledButton>
                                    <StyledButton
                                        width="35%"
                                        disabled={!formik.isValid || formik.isSubmitting}
                                        btnStyle="greenBtn"
                                        type="submit"
                                        loading={formik.isSubmitting ? 1 : 0}
                                    >
                                        ثبت
                                    </StyledButton>
                                </div>

                            </StyledForm>
                        )}
                    </Formik>

                </Modal.Body>

            </Modal>

        }
    </>)
}
