
import styled from "styled-components";
import Col from "./../../Component/Kit/Column";
import { StyledForm } from "../../Component/Kit/Form";

export const DashboardLayuot = styled.div`
  .bulet {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background: #3F3D56;
    margin-left: 5px;
    display: inline-block;
  }

  .providerCard {
    background: #fff;
    min-height: 120px;
    cursor: pointer;
    padding: 12px;
    border-radius: .2rem;
    box-shadow: 0 3px 16px #d9d9d9;
    margin-bottom: 12px;

    .inner-container {
      border-radius: .4rem;
      height: 50px;
      background: #eee;
      width: 100%;
      padding: 10px;

      &.healthy {
        background: #d2f3c7;
        color: #34741e;
      }

      &.unhealthy {
        background: #f3c7c7;
        color: #741e1e;
      }

      &.unknown {
        background: #f3e0c7;
        color: #743f1e;
      }

      img {
        height: 40px;
        margin-bottom: 5px;
      }
    }
  }
`;

export const StyledNationalCode = styled(Col)`
`;

export const StyledButtonAdd = styled.div`
  color : white;
  width: 137px;
  height: 36px;
  border-radius: 18px;
  background-color: #008840;
  font-family: IRANSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

export const ChangePasswordForm = styled(StyledForm)`
  box-shadow:none;
  height : 250px;
  padding : 0px;
  margin-top : 25px;
`;
