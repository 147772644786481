import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { StyledButton } from "../../../../Component/Kit/Button";
import { StyledForm, StyledSelect, StyledSwitchBox } from "../../../../Component/Kit/Form";
import Modal from "../../../../Component/Kit/Modal";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { DedicatedBasePriceSchema } from "../../../../Utility/Validation/Schema/DedicatedBasePriceSchema";

const DedicatedUserBasePriceModal = (props) => {
    const { visible, setVisible, onCancel, baseId, onClose, onsubmit } = props;
    const [dedicatedBasePriceList, setDedicatedBasePriceList] = useState([]);
    const [dedicatedBasePriceDefault, setDedicatedBasePriceDefault] = useState(false);
    const match = useRouteMatch();
    const userId = match.params.id;

    const getDedicatedBasePriceList = useCallback(() => {
        ApiClient.Get(Urls.DedicatedBasePrice())
            .then(res => {
                setDedicatedBasePriceList(res.result.map(item => ({ value: item.id, name: item.basePrice })))
            })
    }, []);

    useEffect(() => {
        if (visible) {
            setDedicatedBasePriceDefault(!Boolean(baseId));
            getDedicatedBasePriceList();
        }
    }, [baseId, getDedicatedBasePriceList, visible]);

    const removeBasePrice = () => {
        ApiClient.Delete(Urls.dedicatedUserBasePrice(userId))
            .then((res) => {
                if (res) {
                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    setVisible(false);
                    onClose(false);
                    onsubmit();
                }
            })
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            closable={true}
            headerTitle="تعرفه اختصاصی"
        >
            <div className="col-12 pt-3 pb-3">
                <div className="row">
                    <Formik
                        initialValues={{
                            dedicatedBasePrice: baseId || '',
                        }}
                        enableReinitialize
                        validationSchema={dedicatedBasePriceDefault ? null : DedicatedBasePriceSchema}
                        onSubmit={(values, actions) => {
                            if (dedicatedBasePriceDefault) {
                                removeBasePrice();
                            } else {
                                ApiClient.Post(Urls.dedicatedUserBasePrice(userId), Number(values.dedicatedBasePrice))
                                    .then((res) => {
                                        if (res) {
                                            toast.success('تغییرات  با موفقیت ثبت شد.');
                                            setVisible(false);
                                            actions.resetForm();
                                            onsubmit();
                                        }
                                    })
                                    .catch().finally(() => {
                                        actions.setSubmitting(false)
                                    })
                            }

                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                                onBlur={formik.handleBlur}
                            >
                                <div className="d-flex mb-3">
                                    <p className="ml-3">مبلغ تعرفه اختصاصی بصورت پیشفرض</p>
                                    <StyledSwitchBox
                                        focus={true}
                                        name="dedicatedBasePriceDefault"
                                        checked={dedicatedBasePriceDefault}
                                        onChange={(e) => setDedicatedBasePriceDefault(e.target.checked)}
                                    />
                                </div>
                                {
                                    !dedicatedBasePriceDefault &&
                                    <StyledSelect
                                        lable="مبلغ تعرفه اختصاصی"
                                        hasDefaultOption={!baseId}
                                        focus={true}
                                        name="dedicatedBasePrice"
                                        value={formik.values.dedicatedBasePrice}
                                        error={(formik.errors.dedicatedBasePrice && formik.touched.dedicatedBasePrice)
                                            ? formik.errors.dedicatedBasePrice
                                            : 'false'
                                        }
                                        optionItems={dedicatedBasePriceList}
                                        onChange={(e) => formik.setFieldValue("dedicatedBasePrice", e.target.value)}
                                    />
                                }

                                <div className="d-flex justify-content-between">
                                    <StyledButton
                                        btnStyle="greenBtn ml-3"
                                        width="38%"
                                        disabled={(!formik.isValid || !formik.dirty) && !dedicatedBasePriceDefault}
                                        loading={formik.isSubmitting ? 1 : 0}
                                        type="submit"
                                    >
                                        تایید
                                    </StyledButton>

                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => setVisible(false)}
                                    >
                                        انصراف
                                    </StyledButton>
                                </div>

                            </StyledForm>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

export default DedicatedUserBasePriceModal;