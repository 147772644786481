import React, {useState, useEffect, useCallback} from "react";
import {useRouteMatch} from "react-router-dom";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {PageTitleLayout} from "../../../Component/Kit/Layout";
import {StyledButton} from "../../../Component/Kit/Button";
import DateConvert from "../../../Utility/Services/date-convert";
import Modal from "../../../Component/Kit/Modal";
import BlockedUserInformation from "./blocked-user-information";
import BlockActionModal from "./block-action-modal";

function UserBlock() {
    let match = useRouteMatch();
    const [blockList, setblockList] = useState([]);
    const [isBlock, setisBlock] = useState(false);
    const [detailsModal, setdetailsModal] = useState(false);
    const [suspendAction, setsuspendAction] = useState(false);
    const [modalData, setmodalData] = useState({});
    const tableConfig = {
        header: {
            description: "توضیحات",
            adminUserName: "نام ادمین",
            isSuspensionTitle: "نوع عملیات",
            createdAt: "تاریخ ایجاد",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
        },
    };
    const tableActions = (item) => {
        switch (item.actionName) {
            case "view":
                setdetailsModal(true);
                // let rowData = blockList.find((m) => m.id === item.userId);
                setmodalData(item.data);
                break;
            default:
                break;
        }
    };
    const suspendHandler = (event) => {
        if (event) {
            getSuspend()
            setsuspendAction(false);
        }
    };
    const getSuspend = useCallback(() => {
        ApiClient.Get(Urls.userSuspentionLog(match.params.id)).then((res) => {
            let data = res.result;
            data.map((m, i) => {
                m.id = i + 1;
                m.createdAt = DateConvert(m.createdAt);
                m.isSuspensionTitle = m.isSuspension ? "Block" : "Unblock";
                return m;
            });
            if (data.length) {
                setisBlock(data[data.length - 1].isSuspension);
            } else {
                setisBlock(false);
            }
            setblockList(data);
        });
    }, [match.params.id])

    useEffect(() => {
        getSuspend();
    }, [match.params.id, getSuspend]);

    return (
        <>
            <PageTitleLayout>
                <h5>مسدود سازی کاربر</h5>
                <span>
          در اینجا می‌توانید کاربر مورد نظر را بلاک یا آنبلاک نمایید.
        </span>
            </PageTitleLayout>
            <div className="pb-3 text-left">
                <StyledButton
                    btnStyle={isBlock ? "yellowBtn" : "greenBtn"}
                    onClick={() => setsuspendAction(true)}
                >
                    {isBlock ? "عدم تعلیق کاربر" : "تعلیق کاربر"}
                </StyledButton>
            </div>
            <Table
                data={blockList}
                config={tableConfig}
                actionDispatched={tableActions}
            />

            <Modal
                onClose={() => setdetailsModal(false)}
                onCancel={() => setdetailsModal(false)}
                visible={detailsModal}
                headerTitle="توضیحات"
            >
                <BlockedUserInformation data={modalData} onCloseModal={(e) => setdetailsModal(e)}/>
            </Modal>

            <Modal
                onClose={() => setsuspendAction(false)}
                onCancel={() => setsuspendAction(false)}
                visible={suspendAction}
                headerTitle= {isBlock ? "عدم تعلیق کاربر" : "تعلیق کاربر"}
            >
                <BlockActionModal data={isBlock} dataChange={suspendHandler} onCloseModal={(e) => setsuspendAction(e)}/>
            </Modal>
        </>
    );
}

export default UserBlock;
