import React from 'react'
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from '../../../Component/Kit/Layout';
import BackToPage from "../../../Component/Kit/back";
import {StyledForm, StyledInput, StyledSelect} from "../../../Component/Kit/Form";
import {StyledButton} from "../../../Component/Kit/Button";
import {Formik} from "formik";
import * as Yup from "yup";
import moment from "moment-jalaali";
import {MyRangePicker} from "../../../Component/Shared/DatePicker";
import {Alert} from "react-bootstrap";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

export const AddCampaign = () => {
    const history = useHistory();
    const options = [
        {
            name: "رسمی",
            value: 'true'
        },
        {
            name: "غیر رسمی",
            value: 'false'
        },
        {
            name: "رسمی - غیر رسمی",
            value: 'null'
        },
    ]
    const conversionMap = {
        "true": true,
        "false": false,
        "null": null
    };

    const AddCampaign = (values) => {
        if ("officialPaymentStatus" in values) {
            values.officialPaymentStatus = conversionMap[values.officialPaymentStatus]
        }

        ApiClient.Post(Urls.advertisingCampaign(), JSON.stringify(values))
            .then((res) => {
                toast.success("تغییرات  با موفقیت ثبت شد.");
                history.push(`/campaigns`);
            })
    }


    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-end">
                <BackToPage toPage={'/campaigns'}/>
            </HeaderContent>
            <MainContent>
                <PageTitleLayout className="mb-0">
                    <h5 className="mb-4">ایجاد کمپین تبلیغاتی </h5>
                    <Alert variant="warning" className="mb-0">
                        <p>
                            - در صورت خالی بودن کد کمپین، کد به صورت تصادفی انتخاب می‌شود.
                        </p>
                        <p className="m-0">
                            - تاریخ شروع و پایان کمپین می‌توانند خالی باشد.
                        </p>
                    </Alert>
                </PageTitleLayout>
                <>
                    <div>
                            <>

                                <Formik
                                    initialValues={{
                                        name: "",
                                        code: null,
                                        officialPaymentStatus: "true"
                                    }}
                                    validationSchema={Yup.object().shape({
                                        name: Yup.string()
                                            .label("name")
                                            .required("این فیلد الزامی است."),
                                    })}
                                    isInitialValid={false}
                                    onSubmit={(values, actions) => {
                                        AddCampaign(values);
                                    }}
                                >
                                    {(formik) => (
                                        <StyledForm
                                            onSubmit={formik.handleSubmit}
                                            onBlur={formik.handleBlur}
                                            onReset={formik.handleReset}>

                                            <div className="d-flex justify-content-between flex-wrap">

                                                <StyledInput
                                                    formGroupClass="col-lg-6 col-md-6 col-12 px-0 pr-md-0 pl-md-3"
                                                    lable="نام کمپین"
                                                    focus={true}
                                                    name="name"
                                                    value={formik.values.name}
                                                    error={(formik.errors.name && formik.touched.name)
                                                        ? formik.errors.name
                                                        : 'false'
                                                    }
                                                    onChange={(e) => formik.setFieldValue("name", e.target.value)}
                                                />

                                                <StyledInput
                                                    formGroupClass="col-lg-6 col-md-6 col-12 px-0 pl-md-0 pr-md-3"
                                                    lable="کد کمپین"
                                                    focus={true}
                                                    name="code"
                                                    value={formik.values.code}
                                                    error={(formik.errors.code && formik.touched.code)
                                                        ? formik.errors.code
                                                        : 'false'
                                                    }
                                                    onChange={(e) => formik.setFieldValue("code", e.target.value)}
                                                />

                                            </div>

                                            <MyRangePicker
                                                fromDate={formik.values.startAt}
                                                toDate={formik.values.endAt}
                                                value={(event) => {
                                                    formik.setFieldValue("startAt", event.fromDate?.baseFormat)
                                                    formik.setFieldValue("endAt", event.toDate?.baseFormat)
                                                }}
                                                min={moment()}
                                                fromError={formik.errors.startAt !== undefined
                                                    ? formik.errors.startAt
                                                    : false}
                                                toError={formik.errors.endAt !== undefined
                                                    ? formik.errors.endAt
                                                    : false}
                                            />

                                            <StyledSelect
                                                formGroupClass="col-lg-6 col-md-6 col-12 px-0 pr-md-0 pl-md-3 mb-0 mt-3"
                                                optionItems={options}
                                                lable="نوع پرداخت"
                                                focus={true}
                                                name="officialPaymentStatus"
                                                value={formik.values.officialPaymentStatus}
                                                error={(formik.errors.officialPaymentStatus && formik.touched.officialPaymentStatus)
                                                    ? formik.errors.officialPaymentStatus
                                                    : 'false'
                                                }
                                                onChange={(e) => formik.setFieldValue("officialPaymentStatus", e.target.value)}
                                            />


                                            <div className="d-flex justify-content-end w-100 mt-5">

                                                <StyledButton
                                                    width="10%"
                                                    disabled={!formik.isValid || !formik.dirty}
                                                    btnStyle='greenBtn'
                                                    type="submit"
                                                >
                                                    ثبت
                                                </StyledButton>
                                            </div>
                                        </StyledForm>
                                    )}
                                </Formik>

                            </>
                    </div>
                </>

            </MainContent>
        </WrapperLayout>
    );
};

export default AddCampaign;