import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import BackToPage from '../../../Component/Kit/back';
import { Loader } from '../../../Component/Shared/Loader';
import Table from '../../../Component/Shared/Table/Table';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { ConvertQueryString } from '../../../Utility/Services/convert-query-string';
import DateConvert from '../../../Utility/Services/date-convert';

export const UsersPlan = () => {
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const [userList, setUserList] = useState([]);
    // const [planName, setPlanName] = useState('');
    const [loading, setLoading] = useState(true);

    const tableConfig = {
        header: {
            username: 'نام کاربری ',
            createdAt: "تاریخ ایجاد",
            expireAt: "تاریخ انقضا",
        },
        actions: {
            view: {
                icon: 'fa fa-eye',
            },
        },
        mode: ['search'],
        filters: [],
    };

    function tableActions(items) {
        switch (items.actionName) {
            case 'view':
                history.push(`/userDetail/${items.data.userId}/profile/`);
                break;
            default:
                break;
        }
    }
    const getUsersPerPlan = () => {
        ApiClient.Get(Urls.UsersPerPlan(params.id), ConvertQueryString(location.search))
            .then(res => {
                let dataItems = res.result;
                dataItems.items.forEach(item => {
                    item.createdAt = DateConvert(item.createdAt);
                    item.expireAt = DateConvert(item.expireAt);
                });
                setUserList(dataItems)
                setLoading(false);
            });
    }

    useEffect(() => {
        getUsersPerPlan();
        // setPlanName(new URLSearchParams(history.location.search).get('name'));
    }, [params.id, location.search]);

    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-between">
                <span className="fakeContainer"></span>
                <BackToPage />
            </HeaderContent>
            <div className='c-wrapper'>
                <MainContent>
                    <PageTitleLayout>
                        {/* <h5> لیست کاربران پلن‌ ({planName})</h5> */}
                        <h5> لیست کاربران پلن‌</h5>
                        <span>در این بخش لیست همه کاربران این پلن‌ را مشاهده کنید.</span>
                    </PageTitleLayout>
                    <div>
                        {loading === true ? (
                            <Loader />
                        ) : (
                            <Table
                                data={userList.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: userList.pageNumber,
                                    pageSize: userList.pageSize,
                                    totalItemsCount: userList.totalItemsCount,
                                    totalPagesCount: userList.totalPagesCount,
                                }}
                            />
                        )}
                    </div>
                </MainContent>
            </div>
        </WrapperLayout>
    )
}
