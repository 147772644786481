import * as Yup from "yup";
import {
  companyName,
  registrationNumber,
  nationalIdCompany,
  economicCode,
  companyType,
  companyActivityField,
} from "../Rules";
export const EditUserLegalSchema = Yup.object().shape({
  companyName,
  registrationNumber,
  nationalIdCompany,
  companyType,
  companyActivityField,
});
