import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useRouteMatch} from "react-router-dom";
import {StyledButton} from "../../../Component/Kit/Button";
import {PageTitleLayout} from "../../../Component/Kit/Layout";
import UserLineModal from "../../../Component/Shared/Modal/user-line-modal";
import {ConvertQueryString} from "../../../Utility/Services/convert-query-string";
import DateConvert from "../../../Utility/Services/date-convert";
import {EarnMethod, LineStatus, LineTypes} from "../../../Utility/Services/lines";
import GetProvider from "../../../Utility/Services/provider";
import Modal from "./../../../Component/Kit/Modal";
import Table from "./../../../Component/Shared/Table/Table";
import ApiClient from "./../../../Requester/ApiClient";
import Urls from "./../../../Requester/Urls";
import AddLineToUser from "./add-line";
import {WhoisModal} from "./whois-modal";
import {FormLable, StyledForm, StyledSwitchBox} from "../../../Component/Kit/Form";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {MyDatePicker} from "../../../Component/Shared/DatePicker";
import {Formik} from "formik";
import * as Yup from 'yup';
import moment from "moment-jalaali";


const PublicLineSchema = Yup.object().shape({
    publicLineDate: Yup.string()
        .required("این فیلد الزامی است."),
});

function UserLines() {
    let match = useRouteMatch();
    let location = useLocation();
    const [lineData, setlineData] = useState({});
    const [ActivePublicLines, setActivePublicLines] = useState(false);
    const [publicLineExpireDateTime, setPublicLineExpireDateTime] = useState();


    const [ActivePublicLinesModal, setActivePublicLinesModal] = useState(false);
    const [lineModal, setlineModal] = useState(false);
    const [addLineModal, setAddLineModal] = useState(false);
    const [modalData, setmodalData] = useState({});
    const [whoisModal, setWhoisModal] = useState(false);
    const tableConfig = {
        header: {
            number: "شماره خط",
            earnMethodName: "نوع دریافت",
            statusName: "وضعیت خط",
            lineTypeText: "نوع خط",
            providerName: "ارائه دهنده خدمات",
            createdAt: "تاریخ ایجاد",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
            edit: {
                icon: 'fa fa-edit',
                condition: (data) => {
                    return data.providerId === 1;
                },
            }
        },
        mode: ["filter", "search"],
        filters: ["datePickerFilter", "earnMethod", "lineStatus", "lineType"],
    };
    const tableActions = (items) => {
        switch (items.actionName) {
            case "view":
                openUserLineModal(items.data);
                break;
            case "edit":
                openUserWhoisModal(items.data);
            default:
                break;
        }
    };
    const openUserLineModal = (item) => {
        // let result = lineData.find((m) => m.id === item);
        setlineModal(true);
        setmodalData(item);
    };

    const openUserWhoisModal = (item) => {
        setWhoisModal(true);
        setmodalData(item);
    }

    const modalChangeHandler = (event) => {
        if (event) {
            getLines();
        }
    };

    const getLines = useCallback(() => {
        ApiClient.Get(Urls.userLines(match.params.id), ConvertQueryString(location.search))
            .then((res) => {
                let data = res.result;
                let {allowedUsingPublicLine, publicLineExpireDateTime} = data.userPublicLineExpiryInfo;

                data.lines.map((m) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.earnMethodName = EarnMethod(m.earnMethod);
                    m.statusName = LineStatus(m.status);
                    m.providerName = GetProvider(m.providerId);
                    m.lineTypeText = LineTypes(m.lineType);
                    return m;
                });
                setlineData(data.lines);
                setActivePublicLines(allowedUsingPublicLine)
                setPublicLineExpireDateTime(DateConvert(publicLineExpireDateTime))
            });
    }, [match.params.id, location.search])


    useEffect(() => {
        getLines();
    }, [match.params.id, location.search, getLines]);

    const confirmActivition = (value) => {
        if (!value) {
            setActivePublicLinesModal(true);
        } else {
            Swal.fire({
                text: `آیا از غیر فعال سازی خطوط ویژه اطمینان دارید ؟`,
                title: "احتیاط کن !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "انصراف",
                cancelButtonColor: "#d33",
                confirmButtonText: "بله، مطمئن هستم",
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiClient.Delete(Urls.userPublicLinesActivition(match.params.id)).then(
                        () => {
                            toast.success('تغییرات  با موفقیت ثبت شد.');
                            setActivePublicLines(!ActivePublicLines)
                        }
                    ).catch(err => getLines())
                }
            });
        }


    }


    return (
        <>
            <PageTitleLayout>
                <h5>خطوط</h5>
                <span>
                    در اینجا لیست خطوط این کاربر مورد بررسی قرار می‌گیرد.
                </span>
                <div className="pb-3 justify-content-end d-flex">
                    <div className="d-flex ml-3 align-items-center">
                        <p className="ml-3 mb-0 font-weight-bold " style={{lineHeight: 'normal'}}>

                            فعال سازی خطوط
                            ویژه

                            {
                                ActivePublicLines &&
                            <small className="mr-1">
                                    (
                                    {publicLineExpireDateTime}
                                    )
                            </small>
                            }
                        </p>
                        <StyledSwitchBox
                            focus={true}
                            checked={ActivePublicLines}
                            onChange={(e) => confirmActivition(ActivePublicLines)}
                        />
                    </div>
                    <StyledButton btnStyle="greenBtn" onClick={() => setAddLineModal(true)}>
                        افزودن خط
                    </StyledButton>
                </div>
            </PageTitleLayout>
            <Table
                data={lineData}
                config={tableConfig}
                actionDispatched={tableActions}
            />
            <Modal
                onClose={() => setlineModal(false)}
                visible={lineModal}
                onCancel={() => setlineModal(false)}
                headerTitle="وضعیت خط"
            >
                <UserLineModal data={modalData} dataChange={modalChangeHandler} onCloseModal={(e) => setlineModal(e)}/>
            </Modal>
            <Modal
                onClose={() => setAddLineModal(false)}
                visible={addLineModal}
                onCancel={() => setAddLineModal(false)}
                headerTitle="اضافه کردن خط"
            >
                <AddLineToUser dataChange={modalChangeHandler} onCloseModal={(e) => setAddLineModal(e)}/>

            </Modal>

            <Modal
                width="large"
                onClose={() => setWhoisModal(false)}
                onCancel={() => setWhoisModal(false)}
                visible={whoisModal}
                headerTitle="whois خط کاربر"
            >
                <WhoisModal data={modalData} onClose={e => setWhoisModal(e)}/>
            </Modal>

            {ActivePublicLinesModal &&
                <Modal
                    onClose={() => setActivePublicLinesModal(false)}
                    visible={ActivePublicLinesModal}
                    onCancel={() => setActivePublicLinesModal(false)}
                    headerTitle="فعال سازی خطوط ویژه"

                >
                    <Formik
                        initialValues={{
                            publicLineDate: '',
                        }}
                        validationSchema={PublicLineSchema}

                        onSubmit={(values, formikHelpers) => {
                            let data = {
                                expireAt: values.publicLineDate
                            }
                            ApiClient.Post(Urls.userPublicLinesActivition(match.params.id), JSON.stringify(data)).then(
                                () => {
                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                    setActivePublicLines(!ActivePublicLines)
                                    formikHelpers.resetForm();
                                    formikHelpers.setSubmitting(false);
                                    setActivePublicLinesModal(false);
                                    getLines();
                                }
                            )


                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                            >
                                <FormLable show={true}>فعال تا تاریخ</FormLable>
                                <MyDatePicker
                                    haveTime
                                    defaultValue={formik.values.publicLineDate}
                                    value={(event) => {
                                        formik.setFieldValue("publicLineDate", event.baseFormat);
                                    }}
                                    min={moment().subtract(0, 'days')}
                                    max={moment().add(1, 'jYear')}
                                    name="publicLineDate"
                                    error={
                                        formik.errors.publicLineDate !== undefined
                                            ? formik.errors.publicLineDate
                                            : false
                                    }
                                />

                                <div className="d-flex justify-content-between mt-3">
                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => setActivePublicLinesModal(false)}

                                    >
                                        بستن
                                    </StyledButton>
                                    <StyledButton
                                        width="35%"
                                        disabled={!formik.isValid || formik.isSubmitting}
                                        btnStyle="greenBtn"
                                        type="submit"
                                        loading={formik.isSubmitting ? 1 : 0}
                                    >
                                        ثبت
                                    </StyledButton>
                                </div>

                            </StyledForm>
                        )}
                    </Formik>

                </Modal>
            }

        </>
    );
}

export default UserLines;
