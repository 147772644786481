import * as Yup from 'yup';
import {
  address, birthDate, certificateNo, cityId, email, fatherName, firstName, lastName, nationalId, postalCode, provinceId, userIsVip, userPhoneNumber, userUsername
} from '../Rules';

export const EditUserProfileSchema = Yup.object().shape({
  email,
  userUsername,
  userIsVip,
  firstName,
  lastName,
  fatherName,
  nationalId,
  certificateNo,
  birthDate,
  userPhoneNumber,
  provinceId,
  cityId,
  postalCode,
  address,
});

