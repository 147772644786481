import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import BackToPage from '../../../Component/Kit/back';
import {StyledButton} from '../../../Component/Kit/Button';
import {FormLable, StyledForm, StyledInput, StyledSelect, StyledTextArea} from '../../../Component/Kit/Form';
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from '../../../Component/Kit/Layout';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import TicketDepartmentList from '../../../Utility/Model/TicketDepartmentModel';
import {templateSchema} from '../../../Utility/Validation/Schema/TemplateSchema';
import {UploadFileWrqapperAllKindOfFile} from "../../../Component/Kit/UploadZone";

export const EditTicketAnswerTemplate = (props) => {
    const templateId = props.match.params.id;
    const [templateDetail, setTemplateDetail] = useState({});
    const [inputRef, setInputRef] = useState(null);
    const history = useHistory();

    useEffect(() => {
        ApiClient.Get(Urls.ModifyTicketAnswerTemplateById(templateId))
            .then(res => {
                setTemplateDetail(res.result)
            })
    }, [templateId])

    const formik = useFormik({
        initialValues: {
            subject: templateDetail.title,
            department: templateDetail.department,
            longDescription: templateDetail.content,
            templateFile: null,
            attachedFile: {
                url: templateDetail.attachedFileUrl,
                id: templateDetail.attachedFileId
            },

        },
        validationSchema: templateSchema,
        enableReinitialize: true,

        onSubmit: (values, actions) => {

            const formData = new FormData();

            formData.append('Title', values.subject ? values.subject : templateDetail.title);
            formData.append('Department', values.department ? Number(values.department) : templateDetail.department);
            formData.append('Content', values.longDescription ? values.longDescription : templateDetail.content);

            if (values.templateFile) formData.append('File', values.templateFile);

            if (values.attachedFile.id) formData.append('FileId', values.attachedFile.id);

            deleteFile(inputRef);

            ApiClient.Put(Urls.ModifyTicketAnswerTemplateById(templateId), formData)
                .then(() => {

                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    actions.setSubmitting(false);
                    actions.resetForm();
                    history.push("/ticket/template");
                })
                .catch(() => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                })
        }
    });

    const changeFile = (e, isSelfUploaded = false, inputElement = null) => {
        formik.setFieldValue('templateFile', e)
        if (isSelfUploaded) {
            formik.setFieldValue('attachedFile', {
                url: null,
                id: null
            })
        }
        if (inputElement) setInputRef(inputElement);
    }

    function deleteFile(inputRef) {
        if (inputRef) inputRef.current.value = "";
        formik.setFieldValue('templateFile', null)
        formik.setFieldValue('attachedFile', {
            url: null,
            id: null
        })
    }

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <span className="fakeContainer"></span>
                    <BackToPage/>
                </HeaderContent>

                <MainContent>
                    <div className="col-12 col-lg-12">
                        <PageTitleLayout className=" pt-2">
                            <h5>ویرایش کردن قالب</h5>
                            <p>شما می‌توانید قالب تیکت خود را ویرایش کنید.</p>
                        </PageTitleLayout>
                    </div>
                    <hr className="mt-4 mx-3"/>

                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                    >
                        <div className="row">
                            <StyledInput
                                formGroupClass="col-lg-6 col-md-6 col-12"
                                lable="عنوان قالب"
                                focus={true}
                                placeholder="عنوان"
                                name="subject"
                                value={formik.values.subject !== undefined
                                    ? formik.values.subject
                                    : formik.initialValues.subject}
                                error={(formik.errors.subject && formik.touched.subject)
                                    ? formik.errors.subject
                                    : 'false'
                                }
                                onChange={(e) => formik.setFieldValue("subject", e.target.value)}
                            />

                            <StyledSelect
                                formGroupClass="col-lg-6 col-md-6 col-12"
                                lable="مربوط به بخش"
                                focus={true}
                                name="department"
                                value={formik.values.department}
                                optionItems={TicketDepartmentList}
                                error={(formik.errors.department && formik.touched.department)
                                    ? formik.errors.department
                                    : 'false'
                                }
                                onChange={(e) => formik.setFieldValue("department", e.target.value)}
                            />
                        </div>

                        <div className="row">
                            <StyledTextArea
                                formGroupClass="col-12"
                                height="20vh"
                                lable="توضیحات قالب"
                                focus={true}
                                name="longDescription"
                                value={formik.values.longDescription !== undefined
                                    ? formik.values.longDescription
                                    : formik.initialValues.longDescription}
                                error={(formik.errors.longDescription && formik.touched.longDescription)
                                    ? formik.errors.longDescription
                                    : 'false'
                                }
                                onChange={(e) => {
                                    const str = e.target.value.trim() !== '' ? e.target.value : '';
                                    formik.setFieldValue("longDescription", str)

                                }}
                            />

                            <StyledButton
                                btnStyle="greenBtn topAbsolute"
                                disabled={!formik.isValid}
                                loading={formik.isSubmitting ? 1 : 0}
                                type="submit"
                            >
                                ذخیره قالب
                            </StyledButton>

                        </div>

                        <div className='col-12 col-md-12 p-0'>

                            <FormLable show={true}>فایل</FormLable>

                            <UploadFileWrqapperAllKindOfFile
                                text="xls, xlsx, jpeg, jpg, JPG, png, zip, rar, txt"
                                type="file"
                                name="templateFile"
                                callbackfunc={changeFile}
                                error={(formik.errors.templateFile && formik.values.templateFile)
                                    ? formik.errors.templateFile
                                    : false
                                }
                                file={formik.values.templateFile?.name || formik.values.attachedFile.id}
                                deleteFile={deleteFile}
                                downloadUrl={formik.values.attachedFile.url}
                            />

                        </div>


                    </StyledForm>

                </MainContent>
            </div>
        </WrapperLayout>
    )
}
