import React, {useState} from "react";
import {StyledButton} from "../../../Component/Kit/Button";
import Modal from "../../../Component/Kit/Modal";
import {FormError, FormLable, StyledForm} from "../../../Component/Kit/Form";
import Select from "react-select";
import {Formik} from "formik";
import {TimePicker, TimePickerStyled} from "../../../Component/Shared/TimePicker";
import {MyDatePicker} from "../../../Component/Shared/DatePicker";
import {AddAssignmentTicket} from "../../../Utility/Validation/Schema/AddAssignmentTicket";
import ApiClient from "../../../Requester/ApiClient";
import urls from "../../../Requester/Urls";
import {toast} from "react-toastify";
import moment from "moment-jalaali";

const AssignmentPeriod = (props) => {
    const {visible, onClose, onCancel, adminsList, onSubmit} = props
    const [minDate, setMinDate] = useState()
    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            headerTitle="ساخت زمان  پاسخگویی جدید"
        >
            <Formik
                initialValues={{
                    adminIds: [],
                    startDate: '',
                    endDate: '',
                    startTime: '',
                    endTime: ''
                }}
                validationSchema={AddAssignmentTicket}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    console.log(values)
                    console.log(values.adminIds.map(a => a.adminId))
                    ApiClient.Post(urls.assignmentTicket(), {
                        ...values,
                        startTime: `${values.startTime.slice(0, 2)}:${values.startTime.slice(2, 4)}:00`,
                        endTime: `${values.endTime.slice(0, 2)}:${values.endTime.slice(2, 4)}:00`,
                        adminIds: values.adminIds.map(a => a.adminId)
                    }).then(
                        (res) => {
                            toast.success("فرآیند با موفقیت انجام گرفت!");
                            actions.resetForm()
                            onSubmit()

                        }
                    )

                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}>
                        <div className="row my-3">
                            <div className="col-12">
                                <FormLable show={true}> ادمین ها </FormLable>
                                <Select
                                    name="admins"
                                    value={formik.values.adminIds !== null
                                        ? formik.values.adminIds
                                        : formik.initialValues.adminIds}
                                    error={(formik.errors.adminIds && formik.touched.adminIds)
                                        ? formik.errors.adminIds
                                        : 'false'
                                    }
                                    placeholder="ادمین های مورد نظر را انتخاب کنید"
                                    getOptionLabel={option => option['fullName']}
                                    getOptionValue={option => option['adminId']}
                                    isMulti
                                    options={adminsList}
                                    onChange={(e) => {
                                        formik.setFieldValue("adminIds", e)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <FormLable show={true}>تاریخ شروع</FormLable>
                                <MyDatePicker
                                    defaultValue={formik.values.startDate}
                                    value={(event) => {
                                        setMinDate(moment(formik.values.startDate , 'jYYYYjMMjDD').format())
                                        formik.setFieldValue(`startDate`, event.baseFormat);
                                    }}
                                    name={`startDate`}
                                />
                            </div>
                            <div className="col-6">
                                <FormLable show={true}>تاریخ پایان</FormLable>
                                <MyDatePicker
                                    defaultValue={formik.values.endDate}
                                    value={(event) => {
                                        formik.setFieldValue(`endDate`, event.baseFormat);
                                    }}
                                    min={minDate}
                                    name={`endDate`}
                                />
                            </div>

                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <FormLable show={true}>ساعت شروع</FormLable>
                                <TimePickerStyled
                                    withoutSecond={true}
                                    defaultValue={formik.values.startTime}
                                    value={(event) => {
                                        if (event)
                                            formik.setFieldValue("startTime", event.baseFormat)
                                    }}
                                    name="startTime"
                                />
                            </div>
                            <div className="col-6">
                                <FormLable show={true}>ساعت پایان</FormLable>
                                <TimePickerStyled
                                    withoutSecond={true}
                                    defaultValue={formik.values.endTime}
                                    value={(event) => {
                                        if (event)
                                            formik.setFieldValue("endTime", event.baseFormat)
                                    }}
                                    name="endTime"
                                />
                            </div>
                        </div>
                        {
                            formik.values.startTime && formik.values.endTime &&
                            formik.values.startTime === formik.values.endTime &&
                            <FormError show={true}>ساعت شروع و پایان نمی تواند همزمان باشد</FormError>

                        }
                        <div className="d-flex justify-content-between mt-4">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onClose(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting || (formik.values.startTime === formik.values.endTime)}
                                loading={formik.isSubmitting ? 1 : 0}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                اضافه کردن
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </Modal>
    );
};

export default AssignmentPeriod


