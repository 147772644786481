function DateConvert(item) {
    item = item?.toString() || '';
  if (item?.length === 14) {
    let value = {
      year: item.slice(0, 4),
      month: item.slice(4, 6),
      day: item.slice(6, 8),
      hour: item.slice(8, 10),
      minute: item.slice(10, 12),
      second: item.slice(12, 14),
    };
    return `${value.hour}:${value.minute}:${value.second} | ${value.year}/${value.month}/${value.day}`;
  } else if (item?.length === 8) {
    let value = {
      year: item.slice(0, 4),
      month: item.slice(4, 6),
      day: item.slice(6, 8),
    };
    return `${value.year}/${value.month}/${value.day}`;
  }
  return "";
}

export default DateConvert;
