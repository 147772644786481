import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom';
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from '../../../Component/Kit/Layout';
import {Loader} from '../../../Component/Shared/Loader';
import BackToPage from "../../../Component/Kit/back";
import {FormLable, StyledForm, StyledInput, StyledRadioBtn, StyledSelect} from "../../../Component/Kit/Form";
import {StyledButton} from "../../../Component/Kit/Button";
import {Formik} from "formik";
import * as Yup from "yup";
import {MyDatePicker} from "../../../Component/Shared/DatePicker";
import {Alert} from "react-bootstrap";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {toast} from "react-toastify";
import campaigns from "../index";

export const EditCampaign = () => {
    const {id} = useParams();
    const history = useHistory();
    const [Campaign, setCampaign] = useState({});
    const [loading, setLoading] = useState(true);
    const conversionMap = {
        "true": true,
        "false": false,
        "null": null
    };
    const options = [
        {
            name: "رسمی",
            value: 'true'
        },
        {
            name: "غیر رسمی",
            value: 'false'
        },
        {
            name: "رسمی - غیر رسمی",
            value: 'null'
        },
    ]


    const EditCampaign = (values) => {
        setLoading(true);
        if ("isActive" in values) {
            values.isActive = values.isActive === "active";
        }
        if ("officialPaymentStatus" in values) {
            values.officialPaymentStatus = conversionMap[values.officialPaymentStatus]
        }


        ApiClient.Put(Urls.advertisingCampaignById(id), JSON.stringify(values))
            .then((res) => {
                toast.success("تغییرات  با موفقیت ثبت شد.");
                history.push(`/campaigns`);
            }).catch(() => {
            setLoading(false);
        })
    }


    const getCampaign = useCallback(() => {
        ApiClient.Get(Urls.advertisingCampaignById(id))
            .then((res) => {
                const data = res.result;
                data['officialPaymentStatus'] = String(data['officialPaymentStatus'])
                setCampaign(data);
                setLoading(false);
            })
    }, [id])

    useEffect(() => {
        getCampaign();
    }, [getCampaign]);


    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-end">
                <BackToPage toPage={'/campaigns'}/>
            </HeaderContent>
            <MainContent>
                <PageTitleLayout className="mb-0">
                    <h5 className="mb-4">ویرایش کمپین تبلیغاتی </h5>
                    <Alert variant="warning" className="mb-0">
                        <p>
                            - در صورت خالی بودن کد کمپین، کد به صورت تصادفی انتخاب می‌شود.
                        </p>
                        <p className="m-0">
                            - تاریخ شروع و پایان کمپین می‌توانند خالی باشد.
                        </p>
                    </Alert>
                </PageTitleLayout>
                <div>
                    {loading === true && campaigns !== {}
                        ?
                        <Loader/>
                        :
                        <>

                            <Formik
                                initialValues={{
                                    name: Campaign.name,
                                    code: Campaign.code,
                                    isActive: Campaign.isActive ? "active" : "inactive",
                                    endAt: Campaign.endAt,
                                    startAt: Campaign.startAt,
                                    officialPaymentStatus: Campaign.officialPaymentStatus
                                }}
                                validationSchema={Yup.object().shape({
                                    name: Yup.string()
                                        .label("name")
                                        .required("این فیلد الزامی است."),
                                })}
                                isInitialValid={false}
                                onSubmit={(values, actions) => {
                                    EditCampaign(values);
                                }}
                            >
                                {(formik) => (
                                    <StyledForm
                                        onSubmit={formik.handleSubmit}
                                        onBlur={formik.handleBlur}
                                        onReset={formik.handleReset}>

                                        <div className="d-flex justify-content-between flex-wrap">

                                            <StyledInput
                                                formGroupClass="col-lg-6 col-md-6 col-12 px-0 pr-md-0 pl-md-3"
                                                lable="نام کمپین"
                                                focus={true}
                                                isCopyCapability={true}
                                                name="name"
                                                value={formik.values.name}
                                                error={(formik.errors.name && formik.touched.name)
                                                    ? formik.errors.name
                                                    : 'false'
                                                }
                                                onChange={(e) => formik.setFieldValue("name", e.target.value)}
                                            />

                                            <StyledInput
                                                formGroupClass="col-lg-6 col-md-6 col-12 px-0 pl-md-0 pr-md-3"
                                                lable="کد کمپین"
                                                isCopyCapability={true}
                                                focus={true}
                                                name="code"
                                                value={formik.values.code}
                                                error={(formik.errors.code && formik.touched.code)
                                                    ? formik.errors.code
                                                    : 'false'
                                                }
                                                onChange={(e) => formik.setFieldValue("code", e.target.value)}
                                            />

                                            <div className="col-lg-6 col-md-6 col-12 px-0 pr-md-0 pl-md-3">
                                                <FormLable show={true}>
                                                    از تاریخ
                                                </FormLable>
                                                <MyDatePicker
                                                    defaultValue={formik.values.startAt}
                                                    value={(event) => {
                                                        formik.setFieldValue(
                                                            "startAt",
                                                            event.baseFormat
                                                        )
                                                    }}
                                                    name="startAt"
                                                    error={
                                                        formik.errors.startAt !== undefined
                                                            ? formik.errors.startAt
                                                            : false
                                                    }
                                                />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-12 px-0 pl-md-0 pr-md-3">
                                                <FormLable show={true}>
                                                    تا تاریخ
                                                </FormLable>
                                                <MyDatePicker
                                                    defaultValue={formik.values.endAt}
                                                    value={(event) => {
                                                        formik.setFieldValue(
                                                            "endAt",
                                                            event.baseFormat
                                                        )
                                                    }}
                                                    name="endAt"
                                                    error={
                                                        formik.errors.endAt !== undefined
                                                            ? formik.errors.endAt
                                                            : false
                                                    }
                                                />
                                            </div>

                                            <StyledSelect
                                                formGroupClass="col-lg-6 col-md-6 col-12 px-0 pr-md-0 pl-md-3 mb-0 mt-3"
                                                optionItems={options}
                                                lable="نوع پرداخت"
                                                focus={true}
                                                name="officialPaymentStatus"
                                                value={formik.values.officialPaymentStatus}
                                                error={(formik.errors.officialPaymentStatus && formik.touched.officialPaymentStatus)
                                                    ? formik.errors.officialPaymentStatus
                                                    : 'false'
                                                }
                                                onChange={(e) => formik.setFieldValue("officialPaymentStatus", e.target.value)}
                                            />

                                        </div>

                                        <div className="d-flex mt-3">
                                            <label className="mb-0 mx-2"> وضعیت کمپین : </label>
                                            <StyledRadioBtn id='active' name='type' value={'active'}
                                                            label='فعال'
                                                            className="p-3"
                                                            checked={formik.values.isActive === "active"}
                                                            onChange={(e) =>
                                                                formik.setFieldValue("isActive", "active")
                                                            }/>
                                            <StyledRadioBtn id='inactive' name='type' value={'inactive'}
                                                            label='غیر فعال'
                                                            checked={formik.values.isActive === "inactive"}
                                                            className="p-3"
                                                            onChange={(e) =>
                                                                formik.setFieldValue("isActive", 'inactive')
                                                            }/>

                                        </div>


                                        <div className="d-flex justify-content-end w-100 mt-5">

                                            <StyledButton
                                                width="10%"
                                                disabled={Object.keys(formik.errors).length > 0}
                                                btnStyle='greenBtn'
                                                type="submit"
                                            >
                                                ثبت
                                            </StyledButton>
                                        </div>
                                    </StyledForm>
                                )}
                            </Formik>

                        </>
                    }
                </div>

            </MainContent>
        </WrapperLayout>
    );
};

export default EditCampaign;