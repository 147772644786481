import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StyledButton } from "../../../../../Component/Kit/Button";
import { CheckBox } from "../../../../../Component/Kit/Checkbox";
import { StyledForm, StyledInput } from "../../../../../Component/Kit/Form";
import Modal from "../../../../../Component/Kit/Modal";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";
import { SeprateNumberWithComma } from "../../../../../Utility/Services/SeprateNumberWithComma";
import { EditDiscountSchema } from "../../../../../Utility/Validation/Schema/EditDiscountSchema";

export const EditInvoiceDiscountModal = (props) => {
  const {
    officialInvoiceId,
    data,
    hasOldPanel,
    isSave,
    visible,
    setVisible,
    onCancel,
    onClose,
  } = props;
  const [isCustom, setIsCustom] = useState(false);
  const [discountKey, setDiscountKey] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [invoiceNewDetails, setInvoiceNewDetails] = useState({
    discountPrice: 0,
    vatPrice: 0,
    finalPrice: 0,
  });

  // const [oldPriceDetails, setOldPriceDetails] = useState({})
  const isCustomHandler = (payload) => {
    setIsCustom(payload);
    // TODO: refactor
  };
  const saveKey = () => {
    if (hasOldPanel) {
      ApiClient.Patch(
        Urls.getOldPanelOfficialDiscount(
          officialInvoiceId,
          invoiceDetails.detailDiscountId
        ),
        JSON.stringify(discountKey)
      ).then((res) => {
        setVisible(false);
        setDiscountKey(null);
        setIsCustom(false);
        toast.success("فرآیند با موفقیت انجام گرفت!");
        isSave(true);
      });
    } else {
      ApiClient.Put(
        Urls.getOfficialDiscount(
          officialInvoiceId,
          invoiceDetails.detailDiscountId
        ),
        JSON.stringify(discountKey)
      ).then((res) => {
        setVisible(false);
        setDiscountKey(null);
        setIsCustom(false);
        toast.success("فرآیند با موفقیت انجام گرفت!");
        isSave(true);
      });
    }
  };
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      let payload = JSON.parse(JSON.stringify(data));
      let discountItem = payload.details.find((a) => a.isDiscountable === true);
      if (discountItem) {
        payload.totalPrice = SeprateNumberWithComma(payload.totalPrice);
        payload.vatPrice = SeprateNumberWithComma(discountItem.vatPrice);
        payload.discountPrice = SeprateNumberWithComma(
          discountItem.discountPrice
        );
        payload.finalPrice = SeprateNumberWithComma(discountItem.finalPrice);
        payload.detailDiscountId = discountItem.invoiceDetailId;
        setInvoiceDetails(payload);
      }
    }
  }, [data]);
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      onCancel={onCancel}
      headerTitle="ویرایش تخفیف"
    >
      <div className="col-12 pt-3 pb-3">
        <h6>آیا تمایل به تغییر مبلغ تخفیف دارید؟ </h6>
        <div className="row">
          <Formik
            initialValues={{
              invoiceDiscount: data !== null ? data.number : "",
            }}
            validationSchema={isCustom ? EditDiscountSchema : null}
            isInitialValid={false}
            onSubmit={(values, actions) => {
              const params = {
                suggestedDiscountPrice:
                  isCustom || values.invoiceDiscount === 0
                    ? values.invoiceDiscount
                    : 0,
              };
              if (hasOldPanel) {
                ApiClient.Post(
                  Urls.getOldPanelOfficialDiscount(
                    officialInvoiceId,
                    invoiceDetails.detailDiscountId
                  ),
                  Number(params.suggestedDiscountPrice)
                ).then((res) => {
                  const newDetails = {
                    discountPrice: SeprateNumberWithComma(
                      res.result.discountPrice
                    ),
                    vatPrice: SeprateNumberWithComma(res.result.vatPrice),
                    finalPrice: SeprateNumberWithComma(res.result.finalPrice),
                    totalPrice: SeprateNumberWithComma(res.result.totalPrice),
                  };
                  setInvoiceNewDetails(newDetails);
                  setDiscountKey(res.result.key);
                });
              } else {
                ApiClient.Get(
                  Urls.getOfficialDiscount(
                    officialInvoiceId,
                    invoiceDetails.detailDiscountId
                  ),
                  params
                ).then((res) => {
                  const newDetails = {
                    discountPrice: SeprateNumberWithComma(
                      res.result.discountPrice
                    ),
                    vatPrice: SeprateNumberWithComma(res.result.vatPrice),
                    finalPrice: SeprateNumberWithComma(res.result.finalPrice),
                    totalPrice: SeprateNumberWithComma(res.result.totalPrice),
                  };
                  setInvoiceNewDetails(newDetails);
                  setDiscountKey(res.result.key);
                });
              }
            }}
            onReset={(values, formikBag) => {
              setVisible(false);
            }}
          >
            {(formik) => (
              <StyledForm
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
              >
                <CheckBox>
                  <label>
                    <Field
                      checked={isCustom}
                      onChange={(e) => isCustomHandler(e.target.checked)}
                      type="checkbox"
                      name="isCustom"
                    />
                    مبلغ تخفیف پیشنهادی
                  </label>
                </CheckBox>
                <StyledInput
                  lable="مبلغ تخفیف (ریال)"
                  placeholder="100000"
                  focus={true}
                  readOnly={!isCustom}
                  disabled={!isCustom}
                  name="invoiceDiscount"
                  value={formik.values.invoiceDiscount}
                  error={
                    formik.errors.invoiceDiscount && isCustom
                      ? formik.errors.invoiceDiscount
                      : "false"
                  }
                  onChange={(e) =>
                    formik.setFieldValue("invoiceDiscount", e.target.value)
                  }
                />
                <table className="factor-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>مبلغ قبلی</th>
                      <th>مبلغ جدید</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>مبلغ تخفیف</td>
                      <td>
                        {invoiceDetails.discountPrice} <small>ريال</small>
                      </td>
                      <td>
                        {invoiceNewDetails.discountPrice} <small>ريال</small>
                      </td>
                    </tr>
                    <tr>
                      <td>مالیات</td>
                      <td>
                        {invoiceDetails.vatPrice} <small>ريال</small>
                      </td>
                      <td>
                        {invoiceNewDetails.vatPrice} <small>ريال</small>
                      </td>
                    </tr>
                    <tr>
                      <td>مبلغ نهایی پیامک</td>
                      <td>
                        {invoiceDetails.finalPrice} <small>ريال</small>
                      </td>
                      <td>
                        {invoiceNewDetails.finalPrice} <small>ريال</small>
                      </td>
                    </tr>
                    <tr>
                      <td>جمع کل</td>
                      <td>
                        {invoiceDetails.totalPrice} <small>ريال</small>
                      </td>
                      <td>
                        {invoiceNewDetails.totalPrice} <small>ريال</small>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="d-flex justify-content-between mt-3">
                  <StyledButton
                    btnStyle="greenBtn ml-3"
                    width="38%"
                    disabled={isCustom && !formik.isValid}
                    type="submit"
                  >
                    محاسبه
                  </StyledButton>

                  <StyledButton
                    width="35%"
                    btnStyle="redBtn"
                    type="reset"
                    disabled={discountKey === null}
                    onClick={() => saveKey()}
                  >
                    ذخیره
                  </StyledButton>
                </div>
              </StyledForm>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
