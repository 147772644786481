import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
const BackWrapper = styled.div`
  a,
  button {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    direction: rtl;
    i {
      margin-left: 5px;
    }
  }
`;

function BackToPage({ toPage }) {
  let history = useHistory();
  return (
    <>
      <BackWrapper>
        {toPage ? (
          <Link to={toPage}>
            <i className="fa fa-arrow-right"/> <span>بازگشت</span>
          </Link>
        ) : (
          <button onClick={() => history.goBack()}>
            <i className="fa fa-arrow-right"/> <span>بازگشت</span>
          </button>
        )}
      </BackWrapper>
    </>
  );
}
export default BackToPage;
