export  const TowerStatusList = [
    {name: 'در حال بررسی', value: 1},
    {name: 'تایید ارسال', value: 3},
    {name: 'عدم تایید', value: 2},
    {name: 'پرداخت شده', value: 5},
    {name: 'آماده ارسال', value: 6},
    {name: 'در حال بررسی فیش', value: 4},
    {name: 'ارسال شده', value:7},
    {name: 'منقضی شده', value:8},
];

export const TowerOperatorList = [
    {name: 'همراه اول', value: 1},
    {name: 'ایرانسل', value: 2},
    {name: 'رایتل', value: 3},
    {name: 'تالیا', value: 4},
];
