import React, {useState} from "react";
import {StyledButton} from "../../../../Component/Kit/Button";
import {Badge} from 'react-bootstrap';
import Modal from "../../../../Component/Kit/Modal";
import NumberLimitationModal from "../../modals/limitation-modal";
import Urls from "../../../../Requester/Urls";

function FifteenRemarkCoefficient({data}) {
    const [isModal, setIsModal] = useState(false);
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">لایحه تبصره 6 : </span>
                    <Badge className="font-weight-normal" variant="primary">{data}</Badge>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>
            <Modal
                onClose={() => setIsModal(false)}
                onCancel={() => setIsModal(false)}
                visible={isModal}
                headerTitle="لایحه تبصره 6"
            >
                <NumberLimitationModal defaultData={data} onCloseModal={() => setIsModal(false)} url={Urls.fifteenRemarkCoefficient()} />
            </Modal>
        </>
    )
}

export default FifteenRemarkCoefficient
