import React, { useCallback, useEffect, useState } from 'react';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import Table from '../../../Component/Shared/Table/Table';
import {
  HeaderContent,
  PageTitleLayout,
  WrapperLayout,
} from '../../../Component/Kit/Layout';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledButton } from '../../../Component/Kit/Button';
import moment from 'moment-jalaali';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import {ConvertQueryString} from "../../../Utility/Services/convert-query-string";
import DateConvert from "../../../Utility/Services/date-convert";

let interval;

function RepeatSMsHistory() {
  let history = useHistory();
  const location = useLocation();
  const [pendingData, setpendingData] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [refreshDate, setRefreshDate] = useState(moment().format('HH:mm:ss'));
  const intervalTime = 90000;

  const tableConfig = {
    header: {
      userName: 'نام کاربری',
      messageText: 'متن پیامک',
      status: 'وضعیت',
      adminUserName: 'نام ادمین',
      handledAt : 'تاریخ تعیین وضعیت'
    },
    tooltipTitle: ['messageText'],
    actions: {
      view: {
        icon: 'fa fa-eye',
      },
      edit: {
        icon: 'fa fa-check',
        condition: (data) => {
          if (!data.isValid) return true;
          return false;
        },
      },
      delete: {
        icon: 'fa fa-ban',
        condition: (data) => {
          if (data.isValid) return true;
          return false;
        },
      },
    },

    mode: ["filter"],
    filters: ["isValid"]
  };
  const tableActions = (item) => {
    if (item.actionName === 'view') {
      history.push(`/userDetail/${item.data.userId}/profile`);
    }else if (item.actionName === 'edit') {
      queueSmsHandler(true ,item.data.id).then()
    } else if (item.actionName === 'delete'){
      queueSmsHandler(false, item.data.id).then()
    }
  };

  const getSMSQueue = useCallback(() => {

    ApiClient.Get(Urls.getFrequentMessage(),  ConvertQueryString(location.search)).then((res) => {
      if (!location.search.includes('isValid')){
        history.push('?isValid=true')
      }
      setRefreshDate(moment().format('HH:mm:ss'))
      let data = res.result;
      data.items.map((m) => {
        m.handledAt = DateConvert(m.handledAt)
        m.status =   m.isValid ? 'تایید شده' : 'عدم تایید'
        return m;
      });
      setpendingData(data);
    });
  }, [location.search]);

  const updateHandler = () => {
    getSMSQueue();
    setLoadingFlag(true);
    setTimeout(() => {
      setLoadingFlag(false);
    }, 30000);
  };

  const queueSmsHandler = async (item, userId) => {
    try {
      let result = await Swal.fire({
        title: '',
        text: `آیا از ${
            item ? 'تایید' : 'عدم تایید'
        } این متن اطمینان دارید؟`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonText: 'انصراف',
        cancelButtonColor: '#d33',
        confirmButtonText: 'بله، مطمئن هستم',
      });
      if (result.isConfirmed) {
        await ApiClient.Patch(Urls.setFrequentMessageValidation(userId), JSON.stringify(item));
        toast.success('تغییرات  با موفقیت ثبت شد.');
        getSMSQueue();
        autoRefresh();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const autoRefresh = useCallback(() => {
    interval = setInterval(() => {
      getSMSQueue();
    }, intervalTime);
  }, [getSMSQueue]);

  useEffect(() => {
    getSMSQueue();
    autoRefresh();
    return () => clearInterval(interval);
  }, [autoRefresh, getSMSQueue]);

  return (
      <WrapperLayout>
        <div className='c-wrapper'>
          <HeaderContent className='d-flex flex-row-reverse justify-content-between align-items-center'>
            <div className='d-flex flex-row-reverse align-items-center'>
              <StyledButton
                  btnStyle='greenBtn'
                  onClick={() => updateHandler()}
                  disabled={loadingFlag}
              >
                بروزرسانی
              </StyledButton>
              <div className='d-flex mr-3 rtl'>
                <span className='fo-12 mr-2'>آخرین بروزرسانی: </span>
                {/* <span>{new Date().toLocaleTimeString()}</span> */}
                <span>{refreshDate}</span>
              </div>
            </div>
          </HeaderContent>
          <div className="p-4">
            <PageTitleLayout>
              <h5> تاریخچه پیام های پر تکرار</h5>
              <span>
              دراین بخش می‌توانید تاریخچه پیامک‌هایی به صورت مکرر فرستاده شده را بررسی کنید.
            </span>
            </PageTitleLayout>
            <Table
                data={pendingData.items}
                config={tableConfig}
                pageConfig={{
                  pageNumber: pendingData.pageNumber,
                  pageSize: pendingData.pageSize,
                  totalItemsCount: pendingData.totalItemsCount,
                  totalPagesCount: pendingData.totalPagesCount,
                }}
                actionDispatched={tableActions}
            />
          </div>
        </div>
      </WrapperLayout>
  );
}

export default RepeatSMsHistory;
