import { Formik } from "formik";
import React from 'react';
import { toast } from "react-toastify";
import { StyledButton } from '../../../../Component/Kit/Button';
import {StyledForm, StyledInput, StyledTextArea} from '../../../../Component/Kit/Form';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import {AddBlockedIPSchema, AddBlockedNumberSchema} from '../../../../Utility/Validation/Schema/AddBlockedIPSchema';
import {mobile, suspendDescription} from "../../../../Utility/Validation/Rules";

const AddBlockedNumbersModal = ({ onCloseModal, dataChange }) => {
    return (
        <>
            <Formik
                initialValues={{
                    mobile: '',
                    suspendDescription: ''
                }}
                isInitialValid={false}
                enableReinitialize={true}
                validationSchema={AddBlockedNumberSchema}
                onSubmit={(value, actions) => {
                    ApiClient.Post(Urls.BlockedNumber(), {
                        mobile: value.mobile,
                        description: value.suspendDescription
                    })
                        .then(res => {
                            if (res.result) {
                                toast.success('شماره با موفقیت افزوده شد.');
                                actions.resetForm();
                                dataChange(true);
                                onCloseModal(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                    >
                        <StyledInput
                            lable="تلفن همراه"
                            focus={true}
                            type="text"
                            name="mobile"
                            placeholder="09000000000"
                            id="mobile"
                            dir="ltr"
                            value={formik.values.mobile}
                            error={(formik.errors.mobile && formik.touched.mobile)
                                ? formik.errors.mobile
                                : 'false'
                            }
                            onChange={(e) => formik.setFieldValue("mobile", e.target.value)}
                        />

                        <StyledTextArea
                            lable="توضیحات"
                            focus={true}
                            type="text"
                            name="suspendDescription"
                            id="suspendDescription"
                            dir="rtl"
                            value={formik.values.suspendDescription}
                            error={(formik.errors.suspendDescription && formik.touched.suspendDescription)
                                ? formik.errors.suspendDescription
                                : 'false'
                            }
                            onChange={(e) => formik.setFieldValue("suspendDescription", e.target.value)}
                        />

                        <div className="d-flex justify-content-between mt-3">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => onCloseModal(false)}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                اضافه کردن
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}

            </Formik>
        </>
    )
}

export default AddBlockedNumbersModal