import React, { useState } from "react";
import { Formik } from "formik";
import { StyledForm, StyledTextArea } from "../../../Component/Kit/Form";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { UserSuspendSchema } from "../../../Utility/Validation/Schema/UserSuspendSchema";
import { StyledButton } from "../../../Component/Kit/Button";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import { useRouteMatch } from "react-router-dom";

function BlockActionModal({ data, dataChange, onCloseModal }) {
    const [submitLoading, setsubmitLoading] = useState(false);
    let match = useRouteMatch();
    const blockHandler = (body) => {
        setsubmitLoading(true);
        Swal.fire({
            title: `آیا از ${data ? "عدم تعلیق کاربر" : " تعلیق کاربر"} کاربر مطمئن هستید؟`,
            text: "اگه فکر می‌کنی درسته، پس حتماً انجامش بده!!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Patch(data ? Urls.userUnsuspend(match.params.id) : Urls.userSuspend(match.params.id), body).then(() => {
                    setsubmitLoading(false);
                    toast.success('تغییرات  با موفقیت ثبت شد.');
                    dataChange(true);
                });
            }
        })

    };
    return (
        <>
            <Formik
                initialValues={{
                    suspendDescription: "",
                }}
                validationSchema={UserSuspendSchema}
                isInitialValid={false}
                onSubmit={(values, actions) => {
                    blockHandler(JSON.stringify(values.suspendDescription));
                    values.suspendDescription = ''
                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false)
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                    >
                        <StyledTextArea
                            lable="توضیحات"
                            focus={true}
                            name="suspendDescription"
                            value={formik.values.suspendDescription}
                            error={(formik.errors.suspendDescription && formik.touched.suspendDescription)
                                ? formik.errors.suspendDescription
                                : 'false'
                            }
                            onChange={(e) => {
                                const str = e.target.value.trim() !== '' ? e.target.value : ''
                                formik.setFieldValue("suspendDescription", str)
                            }}
                        />
                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => {
                                    onCloseModal(false)
                                }}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid}
                                loading={submitLoading ? 1 : 0}
                                btnStyle={data ? "yellowBtn" : "greenBtn"}
                                type="submit"
                            >
                                {data ? "عدم تعلیق کاربر" : " تعلیق کاربر"}
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    );
}

export default BlockActionModal;
