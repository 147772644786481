import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { StyledButton } from '../../../../Component/Kit/Button';
import { FormLable, StyledForm, StyledInput } from '../../../../Component/Kit/Form';
import { MyDatePicker } from '../../../../Component/Shared/DatePicker';
import { AddBasicInformationStepSchema, ModifyBasicInformationStepSchema } from '../../../../Utility/Validation/Schema/ModifyDiscountCodeSchema';
import { DisCountDateWrapper } from '../../style';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const BasicInformationStep = (props) => {
    const { step, updateState, initalData, formData } = props;
    const [firstStepData, setFirstStepData] = useState({});
    const params = useParams();

    useEffect(() => {
        if (Boolean(formData) && Object.entries(formData).length !== 0) {
            // Dirty form in Edit mode or create
            setFirstStepData({ ...formData, createdAt: params.id ? initalData.createdAt : null });
        }
        else {
            if (Object.entries(initalData).length > 0 && params.id) {
                //  edit mode without changing 
                setFirstStepData({ ...initalData, discountCode: initalData.code, createdAt: initalData.createdAt });
            }
            else {
                setFirstStepData({ ...firstStepData, createdAt: null });
            }
        }

        // console.log(initalData);
    }, [formData, initalData, params.id])

    return (
        <div className='row'>
            <Formik
                initialValues={{
                    discountCode: firstStepData.discountCode || '',
                    usageLimitationPerUser: firstStepData.usageLimitationPerUser || '',
                    fromDate: firstStepData.fromDate || '',
                    toDate: firstStepData.toDate || ''
                }}
                enableReinitialize={true}
                validationSchema={firstStepData.discountCode ? ModifyBasicInformationStepSchema : AddBasicInformationStepSchema}
                onSubmit={(values, actions) => {
                    updateState({ step: step + 1, data: { ...values, createdAt: initalData.createdAt } })
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                    >
                        <DisCountDateWrapper>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <StyledInput
                                        lable="کد تخفیف:"
                                        focus={true}
                                        type="text"
                                        min="0"
                                        name="discountCode"
                                        value={formik.values.discountCode !== undefined
                                            ? formik.values.discountCode
                                            : formik.initialValues.discountCode}
                                        error={(formik.errors.discountCode && formik.touched.discountCode)
                                            ? formik.errors.discountCode
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("discountCode", e.target.value)}
                                        readOnly={firstStepData.createdAt}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <FormLable show={true}>تاریخ شروع</FormLable>
                                    <MyDatePicker
                                        defaultValue={formik.values.fromDate}
                                        value={(event) => { formik.setFieldValue("fromDate", event.baseFormat); }}
                                        name="fromDate"
                                        id="fromDate"
                                        error={formik.errors.fromDate !== undefined
                                            ? formik.errors.fromDate
                                            : false
                                        }
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <StyledInput
                                        lable="محدودیت استفاده برای هر کاربر:"
                                        focus={true}
                                        type="number"
                                        min="0"
                                        name="usageLimitationPerUser"
                                        value={formik.values.usageLimitationPerUser}
                                        error={formik.errors.usageLimitationPerUser && formik.touched.usageLimitationPerUser
                                            ? formik.errors.usageLimitationPerUser
                                            : "false"}
                                        onChange={(e) => formik.setFieldValue("usageLimitationPerUser", e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <FormLable show={true}>تاریخ انقضا</FormLable>
                                    <MyDatePicker
                                        defaultValue={formik.values.toDate}
                                        value={(event) => { formik.setFieldValue("toDate", event.baseFormat); }}
                                        name="toDate"
                                        id="toDate"
                                        error={formik.errors.toDate !== undefined
                                            ? formik.errors.toDate
                                            : false}
                                    />
                                </div>
                                {
                                    firstStepData.createdAt &&
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <FormLable show={true}>تاریخ ایجاد</FormLable>
                                        <StyledInput
                                            value={firstStepData.createdAt}
                                            name="createdAt"
                                            error='false'
                                            readOnly={firstStepData.createdAt}
                                        />
                                    </div>
                                }
                            </div>
                        </DisCountDateWrapper>

                        <div className="mt-4 d-flex justify-content-end">
                            <StyledButton
                                disabled={!formik.isValid || (!formik.dirty && !firstStepData.discountCode)}
                                btnStyle="greenBtn"
                                type="submit"
                                id="firstStep"
                            >
                                مرحله بعدی
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </div>
    )
}


export default BasicInformationStep;