import React from "react";
import styled from "styled-components";

export const UserInformationWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ItemRow = styled.p`
  margin-bottom: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  flex: 0 0 33.3%;
  max-width: calc(33.3% - 1rem);
  background-color: #f3f3f3;
  border-radius: 4px;
  span {
    padding: 0.5rem 0.5rem;
  }
  .custom-label {
    min-width: 130px;
    display: inline-block;
    font-weight: bold;
    border-left: 3px solid #fff;
  }
  &.col-100{
    max-width: calc(100% - 1rem) !important;
    flex: 0 0 100% !important;
  }

  @media only screen and (min-width: 992px) and (max-width: 1330px){
    flex: 0 0 50%;
    max-width: calc(50% - 1rem);
  }
  @media only screen and (max-width: 991px){
    flex: 0 0 100%;
    max-width: calc(100% - 1rem);
  }
`;

export const UserInformationItem = (props) => {
    const { label, dataItem, className, onCopy } = props;
    const handleClick = () => {
        if(onCopy) onCopy();
    };
    return (
        <ItemRow onClick={handleClick} className={className}>
            <span className="custom-label">{label}: </span>
            <span>{dataItem ?? '--------'}</span>
        </ItemRow>
    );
};
