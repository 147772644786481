import { Formik } from 'formik';
import moment from 'moment-jalaali';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../../Component/Kit/Button';
import { FormLable, FormLayout, StyledCheckBox, StyledForm, StyledInput, StyledSelect, StyledTextArea } from '../../../../../Component/Kit/Form';
import Modal from '../../../../../Component/Kit/Modal';
import { MyDatePicker } from '../../../../../Component/Shared/DatePicker';
import ApiClient from '../../../../../Requester/ApiClient';
import Urls from '../../../../../Requester/Urls';
import GenderModel from '../../../../../Utility/Model/gender.model';
import { EditUserProfileSchema } from '../../../../../Utility/Validation/Schema/EditUserProfileSchema';
import EditUserInvoiceModal from '../../EditUserInvoiceModal';

const EditUserInformation = ({ userProfileData, locationData }) => {
  let match = useRouteMatch();
  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [provinceItem, setProvinceItem] = useState([]);
  const [cityItem, setCityItem] = useState([]);

  const [invoiceInformation, setInvoiceInformation] = useState(null);
  const [invoiceModal, setInvoiceModal] = useState(false);

  const closeModal = () => {
    setInvoiceModal(false);
    history.push(`/userDetail/${match.params.id}/profile`);
    toast.success("تغییرات  با موفقیت ثبت شد.");
  };

  const invoiceHandler = (result) => {
    if (result) {
      ApiClient.Patch(Urls.modifyUserInvoice(match.params.id), result).then(
        (res) => {
          closeModal();
        }
      );
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.entries(userProfileData).length) {
      setProvinceItem(locationData.provinceList);
      setCityItem(locationData.cityList);
    } else {
      history.push(`/userDetail/${match.params.id}/profile`);
    }
  }, [history, match.params.id, userProfileData])

  return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <h5 className="my-4">ویرایش اطلاعات کاربر</h5>
      </div>

      <div className="col-12 col-lg-12 px-0">
        <Formik
          initialValues={{
            email: userProfileData.email,
            userUsername: userProfileData.userName,
            mobile: userProfileData.mobile,
            userIsVip: userProfileData.isVIP,
            firstName: userProfileData.firstName,
            lastName: userProfileData.lastName,
            fatherName: userProfileData.fatherName,
            nationalId: userProfileData.nationalId,
            certificateNo: userProfileData.certificateNo,
            birthDate: userProfileData.birthDate,
            isMale: userProfileData.isMale,
            userPhoneNumber: userProfileData.phoneNumber,
            additionalMobile: userProfileData.additionalMobile,
            provinceId: userProfileData.provinceId,
            cityId: userProfileData.cityId,
            postalCode: userProfileData.postalCode,
            address: userProfileData.address,
            avenue: userProfileData?.avenue,
            alley: userProfileData?.alley,
            plaque: userProfileData?.plaque,
          }}
          validationSchema={EditUserProfileSchema}
          // isInitialValid={false}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            let body = {
              email: values.email,
              userName: values.userUsername,
              isVIP: values.userIsVip,
              firstName: values.firstName,
              lastName: values.lastName,
              fatherName: values.fatherName,
              nationalId: values.nationalId,
              certificateNo: values.certificateNo,
              birthDate: parseInt(values.birthDate),
              isMale: JSON.parse(values.isMale),
              phoneNumber: values.userPhoneNumber,
              provinceId: values.provinceId,
              cityId: values.cityId,
              postalCode: values.postalCode,
              address: values.address,
              nationalCardUrl: values.nationalCardUrl,
              avenue: values?.avenue,
              alley: values?.alley,
              plaque: values?.plaque,
            };
            setSubmitLoading(true);
            ApiClient.Patch(Urls.userProfile(match.params.id), body)
              .then((res) => {
                setSubmitLoading(false);
                if (res.result.userInvoices && Object.entries(res.result.userInvoices).length > 0) {
                  setInvoiceInformation(res.result);
                  setInvoiceModal(true);
                } else {
                  toast.success("تغییرات  با موفقیت ثبت شد.");
                }
              }).catch(() => {
                setSubmitLoading(false);
              })
          }}
        >
          {(formik) => (
            <StyledForm
              className="mt-3"
              onSubmit={formik.handleSubmit}
              onBlur={formik.handleBlur}>
              <FormLayout>
                <div className="form-title">
                  <span> اطلاعات اولیه کاربر</span>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-4">
                    <StyledInput
                      lable="ایمیل"
                      focus={true}
                      type="text"
                      isCopyCapability={true}
                      name='email'
                      value={formik.values.email || ''}
                      error={(formik.errors.email && formik.touched.email)
                        ? formik.errors.email
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("email", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-4">
                    <StyledInput
                      lable="نام کاربری"
                      focus={true}
                      dir="ltr"
                      type="text"
                      isCopyCapability={true}
                      name="userUsername"
                      value={formik.values.userUsername || ''}
                      error={(formik.errors.userUsername && formik.touched.userUsername)
                        ? formik.errors.userUsername
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("userUsername", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-4">
                    <StyledInput
                      lable="شماره همراه"
                      isCopyCapability={true}
                      focus={true}
                      error="false"
                      readOnly
                      type="text"
                      value={formik.values.mobile}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <StyledCheckBox
                      formGroupClass="mt-2"
                      lable="کاربر ویژه"
                      focus={true}
                      name="userIsVip"
                      id="userIsVip"
                      checked={formik.values.userIsVip !== undefined
                        ? formik.values.userIsVip
                        : formik.initialValues.userIsVip}
                      error="false"
                      onChange={(e) => formik.setFieldValue("userIsVip", e.target.checked)}
                    />
                  </div>
                </div>
              </FormLayout>
              <FormLayout>
                <div className="form-title">
                  <span>اطلاعات هویتی</span>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <StyledInput
                      lable="نام"
                      isCopyCapability={true}
                      focus={true}
                      className="text-right"
                      type="text"
                      name="firstName"
                      value={formik.values.firstName}
                      error={(formik.errors.firstName && formik.touched.firstName)
                        ? formik.errors.firstName
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("firstName", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-4">
                    <StyledInput
                      lable="نام خانوادگی"
                      focus={true}
                      isCopyCapability={true}
                      className="text-right"
                      type="text"
                      name="lastName"
                      value={formik.values.lastName}
                      error={(formik.errors.lastName && formik.touched.lastName)
                        ? formik.errors.lastName
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("lastName", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-4">
                    <StyledInput
                      lable="نام پدر"
                      focus={true}
                      className="text-right"
                      type="text"
                      isCopyCapability={true}
                      name='fatherName'
                      value={formik.values.fatherName}
                      error={(formik.errors.fatherName && formik.touched.fatherName)
                        ? formik.errors.fatherName
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("fatherName", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <StyledInput
                      lable="کدملی"
                      focus={true}
                      maxLength="10"
                      inputMode="numeric"
                      isCopyCapability={true}
                      name='nationalId'
                      value={formik.values.nationalId}
                      error={(formik.errors.nationalId && formik.touched.nationalId)
                        ? formik.errors.nationalId
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("nationalId", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-4">
                    <StyledInput
                      lable="شماره شناسنامه"
                      focus={true}
                      type="text"
                      isCopyCapability={true}
                      name='certificateNo'
                      value={formik.values.certificateNo}
                      error={(formik.errors.certificateNo && formik.touched.certificateNo)
                        ? formik.errors.certificateNo
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("certificateNo", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-4">
                    <FormLable show={true}>
                      تاریخ تولد
                    </FormLable>
                    <MyDatePicker
                      defaultValue={formik.values.birthDate}
                      value={(event) => {
                        formik.setFieldValue(
                          "birthDate",
                          event.baseFormat
                        )
                      }}
                      name="birthDate"
                      max={moment().subtract(18, 'years')}

                      error={
                        formik.errors.birthDate !== undefined
                          ? formik.errors.birthDate
                          : false
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <StyledSelect
                      lable="جنسیت"
                      hasDefaultOption
                      focus={true}
                      name="isMale"
                      value={formik.values.isMale}
                      optionItems={GenderModel}
                      error="false"
                      onChange={(e) => {
                        formik.setFieldValue("isMale", e.target.value)
                      }

                      }
                    />

                  </div>
                </div>
              </FormLayout>
              <FormLayout>
                <div className="form-title">
                  <span> اطلاعات نشانی</span>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <StyledInput
                      lable="تلفن"
                      focus={true}
                      isCopyCapability={true}
                      type="text"
                      name="userPhoneNumber"
                      value={formik.values.userPhoneNumber}
                      error={(formik.errors.userPhoneNumber && formik.touched.userPhoneNumber)
                        ? formik.errors.userPhoneNumber
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("userPhoneNumber", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-4 col-md-6">
                    <StyledSelect
                      lable="استان"
                      hasDefaultOption
                      focus={true}
                      name="provinceId"
                      value={formik.values.provinceId}
                      optionItems={provinceItem}
                      error={(formik.errors.provinceId && formik.values.provinceId)
                        ? formik.errors.provinceId
                        : 'false'
                      }
                      onChange={(e) => {
                        formik.setFieldValue("provinceId", e.target.value)
                        ApiClient.Get(Urls.getCity(e.target.value)).then(
                          (res2) => {
                            setCityItem(res2.result)
                            formik.setFieldValue("cityId", res2.result[0].value)
                          }
                        )
                      }
                      }
                    />
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <StyledSelect
                      lable="شهر"
                      hasDefaultOption
                      focus={true}
                      name="cityId"
                      value={formik.values.cityId}
                      optionItems={cityItem}
                      error={(formik.errors.cityId && formik.values.cityId)
                        ? formik.errors.cityId
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("cityId", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <StyledInput
                      lable="کدپستی"
                      isCopyCapability={true}
                      focus={true}
                      type="text"
                      name="postalCode"
                      value={formik.values.postalCode}
                      error={(formik.errors.postalCode && formik.touched.postalCode)
                        ? formik.errors.postalCode
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("postalCode", e.target.value)
                      }
                    />
                  </div>
                </div>
                  <div className="row">
                      <div className="col-12 col-lg-5">
                          <StyledInput
                              lable="خیابان"
                              focus={true}
                              name="avenue"
                              value={formik.values.avenue}
                              error={(formik.errors.address && formik.touched.avenue)
                                  ? formik.errors.avenue
                                  : 'false'
                              }
                              onChange={(e) =>
                                  formik.setFieldValue("avenue", e.target.value)
                              }
                          />
                      </div>
                      <div className="col-12 col-lg-5">
                          <StyledInput
                              lable="کوچه"
                              focus={true}
                              name="alley"
                              value={formik.values.alley}
                              error={(formik.errors.alley && formik.touched.alley)
                                  ? formik.errors.alley
                                  : 'false'
                              }
                              onChange={(e) =>
                                  formik.setFieldValue("alley", e.target.value)
                              }
                          />
                      </div>
                      <div className="col-12 col-lg-2">
                          <StyledInput
                              lable="پلاک"
                              focus={true}
                              name="plaque"
                              value={formik.values.plaque}
                              error={(formik.errors.plaque && formik.touched.plaque)
                                  ? formik.errors.plaque
                                  : 'false'
                              }
                              onChange={(e) =>
                                  formik.setFieldValue("plaque", e.target.value)
                              }
                          />
                      </div>
                  </div>
                <div className="row">
                  <div className="col-12">
                    <StyledTextArea
                      lable="نشانی"
                      isCopyCapability={true}
                      focus={true}
                      name="address"
                      value={formik.values.address}
                      error={(formik.errors.address && formik.touched.address)
                        ? formik.errors.address
                        : 'false'
                      }
                      onChange={(e) =>
                        formik.setFieldValue("address", e.target.value)
                      }
                    />
                  </div>
                </div>

              </FormLayout>
              <div className="text-left">
                <StyledButton
                  disabled={(!formik.isValid || !formik.dirty) && !submitLoading}
                  loading={submitLoading ? 1 : 0}
                  type="submit"
                  btnStyle="greenBtn"
                >
                  ویرایش اطلاعات کاربر
                </StyledButton>
              </div>
            </StyledForm>
          )}
        </Formik>
      </div>
      <Modal
        onClose={() => closeModal()}
        onCancel={() => closeModal()}
        visible={invoiceModal}
        headerTitle="فاکتور های کاربر"
      >
        <EditUserInvoiceModal
          data={invoiceInformation}
          onCloseModal={() => closeModal()}
          confirmModal={(e) => invoiceHandler(e)}
        />
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userProfileData: state.UserProfileDataReducer,
  locationData: state.LocationDataReducer,
});
export default connect(mapStateToProps)(EditUserInformation);