
import * as React from "react"

function FinancialTicketsIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={69.885}
            height={70.974}
            {...props}
        >
            <g data-name="Group 152134">
                <path
                    data-name="Icon awesome-envelope-open-text"
                    d="M18.906 39.174h17.188a1.718 1.718 0 0 0 1.719-1.719V35.74a1.718 1.718 0 0 0-1.719-1.719H18.906a1.718 1.718 0 0 0-1.719 1.719v1.719a1.718 1.718 0 0 0 1.719 1.715Zm-1.719 8.6a1.718 1.718 0 0 0 1.719 1.719h17.188a1.718 1.718 0 0 0 1.718-1.719v-1.722a1.718 1.718 0 0 0-1.719-1.719H18.906a1.718 1.718 0 0 0-1.719 1.719ZM27.5 60.783a8.581 8.581 0 0 1-5.034-1.632L0 42.922v22.9a5.156 5.156 0 0 0 5.156 5.152h44.688A5.156 5.156 0 0 0 55 65.818v-22.9L32.534 59.151a8.589 8.589 0 0 1-5.034 1.632Zm25.524-27.309a197.27 197.27 0 0 0-3.181-2.45v-4.737a5.156 5.156 0 0 0-5.156-5.156h-8.33l-.971-.7c-1.806-1.323-5.394-4.494-7.886-4.457-2.492-.038-6.079 3.133-7.886 4.452l-.971.7h-8.331a5.156 5.156 0 0 0-5.156 5.156v4.742c-1.329 1-2.23 1.7-3.181 2.45A5.156 5.156 0 0 0 0 37.536v1.144l10.312 7.45V26.287h34.375V46.13L55 38.68v-1.144a5.156 5.156 0 0 0-1.976-4.062Z"
                    fill="#3f3d56"
                />
                <path
                    data-name="Icon awesome-dollar-sign"
                    d="m65.266 13.687-6.333-1.853a1.73 1.73 0 0 1 .487-3.389h3.888a3.579 3.579 0 0 1 2.006.616.914.914 0 0 0 1.143-.117l2.041-1.995a.951.951 0 0 0-.106-1.437 8.292 8.292 0 0 0-5.072-1.759V.938A.941.941 0 0 0 62.381 0h-1.877a.941.941 0 0 0-.938.938v2.815h-.146a6.425 6.425 0 0 0-6.392 7.013 6.713 6.713 0 0 0 4.914 5.665l6.011 1.759a1.73 1.73 0 0 1-.487 3.389h-3.888a3.579 3.579 0 0 1-2.005-.616.914.914 0 0 0-1.143.117l-2.042 1.995a.951.951 0 0 0 .11 1.437 8.292 8.292 0 0 0 5.072 1.759v2.815a.941.941 0 0 0 .938.938h1.873a.941.941 0 0 0 .938-.938v-2.827A6.624 6.624 0 0 0 69.517 22a6.439 6.439 0 0 0-4.251-8.309Z"
                    fill="#a7abc3"
                />
            </g>
        </svg>
    )
}

export default FinancialTicketsIcon;