import React from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import ApiClient from "../../../Requester/ApiClient";
import { toast } from "react-toastify";
import { StyledForm, StyledInput } from "../../../Component/Kit/Form";
import { StyledButton } from "../../../Component/Kit/Button";
import { NumberLimitationSchema } from "../../../Utility/Validation/Schema/NumberLimitationSchema";
import { settingModal } from "../../../Actions/setting-modal";
import { connect, useDispatch } from "react-redux";

function NumberLimitationModal({ defaultData, onCloseModal, url }) {
    let dispatch = useDispatch();
    return (
        <>
            <Formik
                initialValues={{
                    numberLimitation: defaultData,
                }}
                validationSchema={NumberLimitationSchema}
                isInitialValid={false}
                enableReinitialize={true}
                onSubmit={(values) => {
                    Swal.fire({
                        title: `آیا مطمئن هستید؟`,
                        text: "",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonText: "انصراف",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "بله، مطمئن هستم",
                    }).then(result => {
                        if (result.isConfirmed) {
                            let value = parseInt(values.numberLimitation);
                            ApiClient.Patch(url, value)
                                .then((res) => {
                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                    values.numberLimitation = defaultData;
                                    dispatch(settingModal(true));
                                })
                        }
                        values.numberLimitation = defaultData;
                    })

                }}
                onReset={(values, formikBag) => {
                    onCloseModal(false)
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}>
                        <StyledInput
                            lable="لطفا محدودیت خود را مشخص کنید"
                            focus={true}
                            placeholder="50"
                            type="number"
                            name="numberLimitation"
                            value={formik.values.numberLimitation}
                            error={(formik.errors.numberLimitation && formik.touched.numberLimitation)
                                ? formik.errors.numberLimitation
                                : 'false'
                            }
                            onChange={(e) =>
                                formik.setFieldValue("numberLimitation", e.target.value)
                            }
                        />

                        <div className="d-flex justify-content-between">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                اعمال محدودیت
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    )
}

export default connect()(NumberLimitationModal);
