const PlanItemLimitationEnum = {
    BulkSend: 1,
    MobileBankSend: 2,
    ParametricSend: 3,
    MultipleTextSend: 4,
    ScheduledCampaign: 5,
    ABTestCampaign: 6,
    EventCampaign: 7,
    ReceivedMessagesReport: 8,
    UserTemplate: 9,
    BlackListInquiryCount: 10,
    SaleCooperation: 11,
    PersonalBlackListKeyword: 12,
    FastSend: 13,
    ApiKey: 14,
    IPLimitationOnApiKeys: 15,
    SmsTransmissionApiParameterless: 16,
    SmsTransmissionApiWithParameter: 17,
    SmsTransmissionEmailParameterless: 18,
    SmsTransmissionEmailWithParameter: 19,
    SmsTransmissionMobileParameterless: 20,
    SmsTransmissionMobileWithParameter: 21,
    SmsSecretaryParameterless: 22,
    SmsSecretaryWithParameter: 23,
    CodeReader: 24,
    CodeReaderParameters: 25,
    Contacts: 26,
    ContactGroups: 27,
    ContactEvents: 28,
    ConcurrentOpenTicketsCount: 29,
    WebServices: 30
}

const PlanItemLimitationModel = [
    { key: 'BulkSend', value: PlanItemLimitationEnum.BulkSend, name: "ارسال ساده" },
    { key: 'MobileBankSend', value: PlanItemLimitationEnum.MobileBankSend, name: "بانک شماره" },
    { key: 'ParametricSend', value: PlanItemLimitationEnum.ParametricSend, name: "هوشمند پارامتریک" },
    { key: 'MultipleTextSend', value: PlanItemLimitationEnum.MultipleTextSend, name: "هوشمند متناظر" },
    { key: 'ScheduledCampaign', value: PlanItemLimitationEnum.ScheduledCampaign, name: "کمپین دوره‌ای" },
    { key: 'ABTestCampaign', value: PlanItemLimitationEnum.ABTestCampaign, name: "کمپین A-B Test" },
    { key: 'EventCampaign', value: PlanItemLimitationEnum.EventCampaign, name: "کمپین رویدادی" },
    { key: 'ReceivedMessagesReport', value: PlanItemLimitationEnum.ReceivedMessagesReport, name: "گزارش‌های دریافتی" },
    { key: 'UserTemplate', value: PlanItemLimitationEnum.UserTemplate, name: "قالب کاربر" },
    { key: 'BlackListInquiryCount', value: PlanItemLimitationEnum.BlackListInquiryCount, name: " تعداد مجاز استعلام خطوط بلک‌ لیست (روزانه)" },
    { key: 'SaleCooperation', value: PlanItemLimitationEnum.SaleCooperation, name: "همکاری در فروش" },
    { key: 'PersonalBlackListKeyword', value: PlanItemLimitationEnum.PersonalBlackListKeyword, name: "کلیدواژهای‌های شخصی لیست سیاه" },
    { key: 'FastSend', value: PlanItemLimitationEnum.FastSend, name: "ماژول ارسال سریع" },
    { key: 'ApiKey', value: PlanItemLimitationEnum.ApiKey, name: "تعداد Api Key" },
    { key: 'IPLimitationOnApiKeys', value: PlanItemLimitationEnum.IPLimitationOnApiKeys, name: "محدودیت ثبت ip برای api key" },
    { key: 'SmsTransmissionApiParameterless', value: PlanItemLimitationEnum.SmsTransmissionApiParameterless, name: "ماژول انتقال پیامک api بدون پارامتر" },
    { key: 'SmsTransmissionApiWithParameter', value: PlanItemLimitationEnum.SmsTransmissionApiWithParameter, name: "ماژول انتقال پیامک api با پارامتر" },
    { key: 'SmsTransmissionEmailParameterless', value: PlanItemLimitationEnum.SmsTransmissionEmailParameterless, name: "ماژول انتقال پیامک ایمیل بدون پارامتر" },
    { key: 'SmsTransmissionEmailWithParameter', value: PlanItemLimitationEnum.SmsTransmissionEmailWithParameter, name: " ماژول انتقال پیامک ایمیل با پارامتر" },
    { key: 'SmsTransmissionMobileParameterless', value: PlanItemLimitationEnum.SmsTransmissionMobileParameterless, name: "ماژول انتقال موبایل بدون پارامتر" },
    { key: 'SmsTransmissionMobileWithParameter', value: PlanItemLimitationEnum.SmsTransmissionMobileWithParameter, name: "ماژول انتقال موبایل با پارامتر" },
    { key: 'SmsSecretaryParameterless', value: PlanItemLimitationEnum.SmsSecretaryParameterless, name: "منشی پیامکی بدون پارامتر" },
    { key: 'SmsSecretaryWithParameter', value: PlanItemLimitationEnum.SmsSecretaryWithParameter, name: "منشی پیامکی با پارامتر" },
    { key: 'CodeReader', value: PlanItemLimitationEnum.CodeReader, name: "کدخوان" },
    { key: 'CodeReaderParameters', value: PlanItemLimitationEnum.CodeReaderParameters, name: "کلیدواژه‌های کدخوان" },
    { key: 'Contacts', value: PlanItemLimitationEnum.Contacts, name: "تعداد مخاطبین" },
    { key: 'ContactGroups', value: PlanItemLimitationEnum.ContactGroups, name: "تعداد گروه مخاطبین" },
    { key: 'ContactEvents', value: PlanItemLimitationEnum.ContactEvents, name: "تعداد رویداد‌های مخاطبین" },
    { key: 'ConcurrentOpenTicketsCount', value: PlanItemLimitationEnum.ConcurrentOpenTicketsCount, name: "تعداد تیکت های باز جاری" },
    { key: 'WebServices', value: PlanItemLimitationEnum.WebServices, name: "وب سرویس" },
]

const PlanConfigurationsList = {
    BulkSend: false,
    MobileBankSend: false,
    ParametricSend: false,
    MultipleTextSend: false,
    ScheduledCampaign: false,
    ABTestCampaign: false,
    EventCampaign: false,
    ReceivedMessagesReport: false,
    UserTemplate: true,
    BlackListInquiryCount: true,
    SaleCooperation: false,
    PersonalBlackListKeyword: false,
    FastSend: true,
    ApiKey: true,
    IPLimitationOnApiKeys: false,
    SmsTransmissionApiParameterless: false,
    SmsTransmissionApiWithParameter: true,
    SmsTransmissionEmailParameterless: false,
    SmsTransmissionEmailWithParameter: true,
    SmsTransmissionMobileParameterless: false,
    SmsTransmissionMobileWithParameter: true,
    SmsSecretaryParameterless: false,
    SmsSecretaryWithParameter: true,
    CodeReader: false,
    CodeReaderParameters: true,
    Contacts: true,
    ContactGroups: true,
    ContactEvents: true,
    ConcurrentOpenTicketsCount: true,
    WebServices: false,
};

export { PlanItemLimitationModel, PlanConfigurationsList }