const SendStatusEnum = {
    InternalServerError: 0,
    Success: 1,
    ApiKeyIsWrong: 10,
    ApiKeyIsInactive: 11,
    ApiKeyIsLimitedToIp: 12,
    UserAccountIsDeactivated: 13,
    UserAccountIsSuspended: 14,
    PlanItemAccessDenied: 15,
    InvalidRequestModel: 16,
    RequestRateLimitExceeded: 20,
    LineNumberIsWrong: 101,
    CreditNotEnough: 102,
    NullOrEmptyMessageText: 103,
    MobileIsWrong: 104,
    TooManyMobileListCount: 105,
    TooManyMessageTextListCount: 106,
    EmptyMobileList: 107,
    EmptyMessageTextsList: 108,
    InvalidSendDateTime: 109,
    MobilesAndMessageTextsNotCompatible: 110,
    IdIsWrong: 111,
    ScheduledMessagesNotFound: 112,
    TemplateNotFound: 113,
    LengthOfParameterValueIsLongerThanAllowed: 114,
    AllMobilesAreInBlackList: 115,
    ParameterNameIsNullOrEmpty: 116,
    InvalidFrequentMessageText: 117,
    MessageCountOutOfRange: 118,
    CustomFastSendTemplatePlanLimitation: 119
}
const ActionNameEnum = {
    VerifySend: 1,
    BulkSend: 2,
    PeerToPeerSend: 3,
    UrlSend: 4,
    RemoveScheduledMessages: 5,
    GetSentMessage: 6,
    GetPack: 7,
    GetLivePacks: 8,
    GetSendLiveReport: 9,
    GetSendArchiveLiveReport: 10,
    GetReceiveLatestMessages: 11,
    GetReceiveLiveReport: 12,
    GetReceiveArchiveReport: 13,
    GetUserLines: 14,
    GetUserCredit: 15
}


const ActionNameModel = [
    {name: "ارسال وریفای", value: ActionNameEnum.VerifySend},
    {name: "ارسال گروهی", value: ActionNameEnum.BulkSend},
    {name: "ارسال نظیر به نظیر", value: ActionNameEnum.PeerToPeerSend},
    {name: "ارسال از طریق URL", value: ActionNameEnum.UrlSend},
    {name: "حذف ارسال زمانبندی شده", value: ActionNameEnum.RemoveScheduledMessages},
    {name: "گزارش پیامک (دریافت وضعیت)", value: ActionNameEnum.GetSentMessage},
    {name: "گزارش مجموعه ارسال", value: ActionNameEnum.GetPack},
    {name: "گزارش مجموعه ارسال‌های روز", value: ActionNameEnum.GetLivePacks},
    {name: "گزارش ارسال‌های روز", value: ActionNameEnum.GetSendLiveReport},
    {name: "گزارش ارسال‌های آرشیو شده", value: ActionNameEnum.GetSendArchiveLiveReport},
    {name: "گزارش تازه‌ترین پیامک‌های دریافتی", value: ActionNameEnum.GetReceiveLatestMessages},
    {name: "گزارش پیامک‌های دریافتی روز", value: ActionNameEnum.GetReceiveLiveReport},
    {name: "گزارش پیامک‌های دریافتی آرشیو شده", value: ActionNameEnum.GetReceiveArchiveReport},
    {name: "دریافت لیست خطوط", value: ActionNameEnum.GetUserLines},
    {name: "دریافت مقدار اعتبار فعلی", value: ActionNameEnum.GetUserCredit},
];


const SendStatusModel = [
    {name: 'درخواست شما با خطا مواجه شده‌است.', value: SendStatusEnum.InternalServerError},
    {name: 'عملیات با موفقیت انجام شد', value: SendStatusEnum.Success},
    {name: 'کلید وب سرویس نامعتبر است', value: SendStatusEnum.ApiKeyIsWrong},
    {name: 'کلید وب سرویس غیرفعال است', value: SendStatusEnum.ApiKeyIsInactive},
    {name: "کلید وب سرویس محدود به آی‌پی‌های تعریف شده می‌باشد.", value: SendStatusEnum.ApiKeyIsLimitedToIp},
    {name: "حساب کاربری غیرفعال است", value: SendStatusEnum.UserAccountIsDeactivated},
    {name: "حساب کاربری در حالت تعلیق قرار دارد", value: SendStatusEnum.UserAccountIsSuspended},
    {name: "به منظور استفاده از وب سرویس پلن خود را ارتقا دهید", value: SendStatusEnum.PlanItemAccessDenied},
    {name: "مقدار ارسالی پارامتر نادرست می‌باشد", value: SendStatusEnum.InvalidRequestModel},
    {name: "تعداد درخواست بیشتر از حد مجاز است", value: SendStatusEnum.RequestRateLimitExceeded},
    {name: "شماره خط نامعتبر میباشد", value: SendStatusEnum.LineNumberIsWrong},
    {name: "اعتبار کافی نمیباشد", value: SendStatusEnum.CreditNotEnough},
    {name: "درخواست شما دارای متن (های) خالی است", value: SendStatusEnum.NullOrEmptyMessageText},
    {name: "درخواست شما دارای موبایل (های) نادرست است", value: SendStatusEnum.MobileIsWrong},
    {name: "تعداد موبایل ها بیشتر از حد مجاز (100 عدد) میباشد", value: SendStatusEnum.TooManyMobileListCount},
    {name: "تعداد متن ها بیشتر از حد مجاز (100 عدد) میباشد", value: SendStatusEnum.TooManyMessageTextListCount},
    {name: "لیست موبایل ها خالی میباشد", value: SendStatusEnum.EmptyMobileList},
    {name: "لیست متن ها خالی میباشد", value: SendStatusEnum.EmptyMessageTextsList},
    {name: "زمان ارسال نامعتبر میباشد", value: SendStatusEnum.InvalidSendDateTime},
    {
        name: "تعداد شماره موبایل ها و تعداد متن ها برابر نیستند",
        value: SendStatusEnum.MobilesAndMessageTextsNotCompatible
    },
    {name: "با این شناسه ارسالی ثبت نشده است", value: SendStatusEnum.IdIsWrong},
    {name: "رکوردی برای حذف یافت نشد", value: SendStatusEnum.ScheduledMessagesNotFound},
    {name: "قالب یافت نشد", value: SendStatusEnum.TemplateNotFound},
    {
        name: "طول رشته مقدار پارامتر، بیش از حد مجاز (25 کاراکتر) میباشد",
        value: SendStatusEnum.LengthOfParameterValueIsLongerThanAllowed
    },
    {name: "شماره موبایل(ها) در لیست سیاه سامانه می‌باشند", value: SendStatusEnum.AllMobilesAreInBlackList},
    {
        name: "نام یک یا چند پارامتر مقداردهی نشده‌است. لطفا به بخش مستندات ارسال وریفای مراجعه فرمایید",
        value: SendStatusEnum.ParameterNameIsNullOrEmpty
    },
    {name: "متن ارسال شده مورد تایید نمی‌باشد", value: SendStatusEnum.InvalidFrequentMessageText},
    {name: "تعداد پیام ها بیشتر از حد مجاز میباشد", value: SendStatusEnum.MessageCountOutOfRange},
    {
        name: "به منظور استفاده از قالب‌ شخصی سازی شده پلن خود را ارتقا دهید",
        value: SendStatusEnum.CustomFastSendTemplatePlanLimitation
    },
];


export {SendStatusModel, ActionNameModel, ActionNameEnum, SendStatusEnum};

