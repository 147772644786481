import React, { useEffect, useState } from "react";
import { StyledButton } from "../../../../Component/Kit/Button";
import Modal from "../../../../Component/Kit/Modal";
import {sendSMSModel} from '../../../../Utility/Model/send-sms.model';
import EditSendsModal from "./edit-sends-modal";
import EnumFlag from "../../../../Utility/Services/enum-flag";
import SendSmsLimitation from "../../Services/SendSmsLimitation";

function ManageSends({ data }) {
    const [isModal, setIsModal] = useState(false);
    const [sendData, setSendData] = useState(false);

    useEffect(() => {
        if (data && typeof data == 'string') {
            refactorBlacklistData(data);
        }
    }, [data])

    const refactorBlacklistData = (result) => {
        if (result) {
            const data = Object.entries((JSON.parse(result)))
                .map(([key, value]) => {
                    return {
                        value: key,
                        name: sendSMSModel?.find(a => a.value == key)?.name,
                        fields: SendSmsLimitation(EnumFlag([1, 2, 4, 8, 16,32],value) , 'name')
                    }
                })
            setSendData(data)
        }
    }

    return (
        <>
            {
                data &&
                <>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <span className="ml-1">مدیریت ارسال‌ها : </span>
                            {/*{selectedItem && blacklistData*/}
                            {/*    ? <Badge className="font-weight-normal ml-3" style={{ whiteSpace: 'pre-wrap' }} variant="primary">{selectedItem.join(' , ')}</Badge>*/}
                            {/*    : <Badge className="font-weight-normal" variant="primary">گزینه ای انتخاب نشده</Badge>*/}
                            {/*}*/}

                        </div>
                        <StyledButton
                            btnStyle="greenBtn"
                            onClick={() => setIsModal(true)}
                        >
                            ویرایش
                        </StyledButton>
                    </div>
                    <Modal
                        width="large"
                        onClose={() => setIsModal(false)}
                        onCancel={() => setIsModal(false)}
                        visible={isModal}
                        headerTitle="مدیریت ارسال ها"
                    >
                        <EditSendsModal defaultData={sendData}
                            onCloseModal={() => setIsModal(false)} />
                    </Modal>
                </>
            }
        </>
    )
}

export default ManageSends
