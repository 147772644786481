import React from "react";

function PendingTemplatesIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={56.484}
      height={57.9}
      {...props}
    >
      <g data-name="Group 152137">
        <path
          data-name="Icon awesome-clipboard-list"
          d="M5.428 7.238h9.047a7.238 7.238 0 1 1 14.475 0H38a5.43 5.43 0 0 1 5.428 5.428v39.806A5.43 5.43 0 0 1 38 57.9H5.428A5.43 5.43 0 0 1 0 52.472V12.666a5.43 5.43 0 0 1 5.428-5.428Zm27.141 40.711a2.714 2.714 0 1 0-2.714-2.714 2.708 2.708 0 0 0 2.714 2.714Zm0-10.856a2.714 2.714 0 1 0-2.714-2.714 2.708 2.708 0 0 0 2.714 2.713Zm0-10.856a2.714 2.714 0 1 0-2.714-2.714 2.708 2.708 0 0 0 2.714 2.713ZM21.713 4.523a2.714 2.714 0 1 0 2.714 2.714 2.708 2.708 0 0 0-2.714-2.714ZM7.238 46.139a.907.907 0 0 0 .9.9h16.289a.907.907 0 0 0 .9-.9V44.33a.907.907 0 0 0-.9-.9H8.142a.907.907 0 0 0-.9.9Zm0-10.856a.907.907 0 0 0 .9.9h16.289a.907.907 0 0 0 .9-.9v-1.809a.907.907 0 0 0-.9-.9H8.142a.907.907 0 0 0-.9.9Zm0-10.856a.907.907 0 0 0 .9.9h16.289a.907.907 0 0 0 .9-.9v-1.81a.907.907 0 0 0-.9-.9H8.142a.907.907 0 0 0-.9.9Z"
          fill="#3f3d56"
        />
        <path
          data-name="Icon awesome-user-check"
          d="m56.096 15.034-3.168-3.2a1.351 1.351 0 0 0-1.914-.011L39.071 23.672l-5.186-5.219a1.351 1.351 0 0 0-1.914-.011l-3.2 3.179a1.351 1.351 0 0 0-.011 1.914l9.31 9.378a1.351 1.351 0 0 0 1.914.011l16.101-15.975a1.362 1.362 0 0 0 .011-1.914Z"
          fill="#a7abc3"
        />
      </g>
    </svg>
  );
}

export default PendingTemplatesIcon;

