import React from "react"
import Col from "../../Kit/Column"
import { LoaderContainer , LoaderWrapper} from "./styles"

export const Loader = () => {
    return (
        <LoaderWrapper>
        <LoaderContainer>
            <Col className='bar1'></Col>
            <Col className='bar2'></Col>
            <Col className='bar3'></Col>
            <Col className='bar4'></Col>
            <Col className='bar5'></Col>
            <Col className='bar6'></Col>
        </LoaderContainer>
        </LoaderWrapper>
    )
}