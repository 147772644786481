import React from "react";
import { Accordion, Button, Card, CardColumns } from "react-bootstrap";
import DateConvert from "../../../../Utility/Services/date-convert";
import GuestOutcome from '../../Services/guest-outcome';

function GuestLogs({ data }) {
  return (
    <>
      <div className="accordian-wrapper ">
        <Accordion defaultActiveKey="0">
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <span>بررسی مجدد</span>
            <i className="far fa-angle-down"></i>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <CardColumns className="mt-3">
              {data.map((item, index) => (
                <Card key={index}>
                  <Card.Body>
                    <div className="guest-log__body p-3">
                      <div className="d-flex flex-column mb-3">
                        <h6>{GuestOutcome(item.outcome)}</h6>
                        <span className="small">
                          {DateConvert(item.createdAt)} | {item.adminFullName}
                        </span>
                      </div>
                      <p>
                        {item.description}
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </CardColumns>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </>
  );
}
export default GuestLogs;
