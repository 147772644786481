import { PageTitleLayout } from "../../../Component/Kit/Layout";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { StyledButton } from "../../../Component/Kit/Button";
import { useRouteMatch } from "react-router-dom";
import { StyledForm, StyledTextArea } from "../../../Component/Kit/Form";
import Swal from "sweetalert2";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { toast } from "react-toastify";
import { SendMessageSchema } from "../../../Utility/Validation/Schema/SendMessageSchema";

const UserSendMessage = () => {
    const match = useRouteMatch();
    const [submitLoading, setSubmitLoading] = useState(false);
    useEffect(() => {
    }, [match.params.id])

    const sendMessageHandler = (body, actions) => {
        setSubmitLoading(true);
        Swal.fire({
            title: "ارسال پیامک",
            text: "آیا از ارسال این پیامک مطمئن هستید؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Post(Urls.userSendSms(match.params.id), JSON.stringify(body.sendMessage))
                    .then(() => {
                        setSubmitLoading(false);
                        toast.success('پیامک با موفقیت ارسال شد.');
                        actions.resetForm()
                    })
            } else {
                setSubmitLoading(false);
            }
        });
    };
    return (
        <>
            <div className="row">
                <div className="col-12 wrapper-box">
                    <PageTitleLayout>
                        <h5>ارسال پیامک</h5>
                        <span>
                            می‌توانید ‌در صورت نیاز به کاربر  مورد نظر پیامک ارسال کنید
                        </span>
                    </PageTitleLayout>

                    <Formik
                        initialValues={{
                            sendMessage: "",
                        }}
                        validationSchema={SendMessageSchema}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            let body = {
                                sendMessage: values.sendMessage,
                            };
                            sendMessageHandler(body, actions);
                        }}
                    >

                        {(formik) => (
                            <div className="row">
                                <StyledForm
                                    onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                                    <StyledTextArea
                                        lable="متن پیامک خود را وارد کنید"
                                        focus={true}
                                        name="sendMessage"
                                        type="text"
                                        textAreaClass="message-textarea"
                                        value={formik.values.sendMessage}
                                        error={(formik.errors.sendMessage && formik.touched.sendMessage)
                                            ? formik.errors.sendMessage
                                            : 'false'
                                        }
                                        onChange={(e) => {
                                            const str = e.target.value.trim() !== '' ? e.target.value : '';
                                            formik.setFieldValue("sendMessage", str)
                                        }}
                                    />
                                    <StyledButton
                                        width="180px"
                                        btnStyle="greenBtn"
                                        disabled={!formik.isValid || !formik.dirty}
                                        loading={submitLoading ? 1 : 0}
                                        type="submit"
                                    >
                                        ارسال پیامک
                                    </StyledButton>
                                </StyledForm>
                            </div>
                        )}

                    </Formik>
                </div>
            </div>
        </>
    );
}
export default UserSendMessage
