import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { StyledButton } from "../../../../Component/Kit/Button";
import { StyledForm, StyledInput, StyledSwitchBox } from "../../../../Component/Kit/Form";
import Modal from "../../../../Component/Kit/Modal";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { DedicatedLimitForVerifyCodeParametersSchema } from "../../../../Utility/Validation/Schema/DedicatedLimitForVerifyCodeParametersSchema";

const DedicatedLimitForVerifyCodeParametersModal = ({ visible, setVisible, onCancel, onClose }) => {
    let match = useRouteMatch();
    let userId = match.params.id;
    const [submitLoading, setSubmitLoading] = useState(false);
    const [limitForParameters, setLimitForParameters] = useState(null);
    const [limitForParametersActive, setlimitForParametersActive] = useState(false);

    useEffect(() => {
        if (visible) {
            ApiClient.Get(Urls.dedicatedLimitationVerifyParamSize(userId))
                .then((res) => {
                    setLimitForParameters(res.result)
                    setlimitForParametersActive(!Boolean(res.result))
                })
        }
    }, [userId, visible])
    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            headerTitle="تعداد کاراکتر وب سرویس verify"
        >
            <>
                <Formik
                    initialValues={{
                        dedicatedLimitForParameters: limitForParameters ? limitForParameters : null,
                    }}
                    enableReinitialize
                    validationSchema={limitForParametersActive ? null : DedicatedLimitForVerifyCodeParametersSchema}
                    onSubmit={(values, actions) => {
                        setSubmitLoading(true);
                        const payload = limitForParametersActive ? null : values.dedicatedLimitForParameters;
                        ApiClient.Patch(Urls.dedicatedLimitationVerifyParamSize(userId), payload)
                            .then(res => {
                                setSubmitLoading(false);
                                toast.success('محدودیت با موفقیت اعمال شد');
                                setVisible(false);
                                onClose(false);
                            })
                            .catch(() => actions.resetForm())
                            .finally(() => setSubmitLoading(false))

                    }}
                >
                    {(formik) => (
                        <StyledForm
                            onSubmit={formik.handleSubmit}
                            onBlur={formik.handleBlur}
                            onReset={formik.handleReset}>
                            <div className="d-flex mb-3">
                                <p className="ml-3">محدودیت بصورت پیشفرض</p>
                                <StyledSwitchBox
                                    focus={true}
                                    checked={limitForParametersActive}
                                    onChange={(e) => setlimitForParametersActive(e.target.checked)}
                                />
                            </div>
                            {
                                !limitForParametersActive &&
                                <StyledInput
                                    lable="لطفا تعداد کاراکتر خود را مشخص کنید."
                                    focus={true}
                                    placeholder="تعداد کاراکتر"
                                    type="text"
                                    name="dedicatedLimitForParameters"
                                    value={formik.values.dedicatedLimitForParameters}
                                    error={(formik.errors.dedicatedLimitForParameters && formik.touched.dedicatedLimitForParameters)
                                        ? formik.errors.dedicatedLimitForParameters
                                        : 'false'
                                    }
                                    onChange={(e) =>
                                        formik.setFieldValue("dedicatedLimitForParameters", e.target.value)
                                    }
                                />
                            }

                            <div className="d-flex justify-content-between">
                                <StyledButton
                                    width="35%"
                                    btnStyle="grayBtn"
                                    type="reset"
                                    onClick={() => onClose(false)}
                                >
                                    بستن
                                </StyledButton>
                                <StyledButton
                                    width="35%"
                                    btnStyle="greenBtn"
                                    disabled={(!formik.isValid || !formik.dirty) && !limitForParametersActive}
                                    loading={submitLoading ? 1 : 0}
                                    type="submit"
                                >
                                    ویرایش
                                </StyledButton>
                            </div>
                        </StyledForm>
                    )}
                </Formik>

            </>
        </Modal>
    )
}

export default DedicatedLimitForVerifyCodeParametersModal;