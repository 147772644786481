import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
// import {DatePicker} from "sg-react-datepicker-persian";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import moment from "moment-jalaali";
import Col from "../../Kit/Column";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const DatePickerContainer = styled.div`
  border: 1px solid #cecece;
  border-radius: 5px;
  background: #fff;
  text-align: right;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding-right: 8px;
  width: 100%;

  > div {
    width: 100%;
  }

  input {
    border: 0;
    background: transparent;
    width: 100%;

    &:focus {
      outline: none;
      border: 0;
      box-shadow: none;
      outline: none !important;
    }
  }

  .clear-date-picker {
    border: 0;
    background: transparent;
    width: 32px;
    height: 31px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: ${(props) => props.theme.red};

    &:focus {
      border: none;
      outline: none;
    }
  }
`;
const LabelContainer = styled.label`
  background-color: white;
  padding: 5px;
  margin-bottom: 0.1rem;
  justify-content: flex-start;
  font-weight: 600;
  text-align: right;
  font-size: 12px;
  width: 100%;
`
const FormError = styled(Col)`
  display: ${(props) => (!props.show ? "none" : "block")};
  color: red;
  font-size: 11px;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 0px 5px 0px;
  height: auto;
  text-align: right;
`;

let dateObject = {
    fromDate: null,
    toDate: null
}

function MyDatePicker(props) {
    const [datePickerValue, setDatePickerValue] = useState(null);
    const [min, setMin] = useState(null);
    const [max, setMax] = useState(null);
    const removeHandler = () => {
        setDatePickerValue(undefined)
        props.value('');
    }
    const changeHandler = (event) => {
        if (event) {
            const month = event.month.number < 10 ? `0${event.month.number}` : `${event.month.number}`
            const day = event.day < 10 ? `0${event.day}` : `${event.day}`
            const hour = event.hour < 10 ?  `0${event.hour}` : `${event.hour}`
            const min = event.minute < 10 ?  `0${event.minute}` : `${event.minute}`
            const dateValue  = {
                baseFormat: Number(props?.haveTime ?`${event.year}${month}${day}${hour}${min}00` : `${event.year}${month}${day}`),
                date:props?.haveTime ? `${event.year}/${month}/${day} ${hour}:${min}` : `${event.year}/${month}/${day}`
            }
            setDatePickerValue(dateValue.date);
            props.value(dateValue);
        }

        }

    useEffect(() => {
        if (props.defaultValue) {
            const defaultValue = props.defaultValue.toString();
            let result =props?.haveTime ? `${defaultValue.slice(0, 4)}/${defaultValue.slice(4, 6)}/${defaultValue.slice(6, 8)} ${defaultValue.slice(8, 10)}:${defaultValue.slice(10, 12)}` : `${defaultValue.slice(0, 4)}/${defaultValue.slice(4, 6)}/${defaultValue.slice(6, 8)}`
            setDatePickerValue(result)
        }
        else{
            setDatePickerValue(null);
        }
        if (props.min) {
            let result = moment(props.min).format('jYYYY/jMM/jDD');
            setMin(result)
        }
        if (props.max) {
            let result = moment(props.max).format('jYYYY/jMM/jDD');
            setMax(result)
        }
    }, [props.defaultValue, props.min, props.max])
    return (
        <>
            <DatePickerContainer>
                <DatePicker
                    calendar={persian}
                    locale={persian_fa}
                    format={props?.haveTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'}
                    plugins={   props?.haveTime ?
                        [<TimePicker hideSeconds={true} position="bottom" hStep={2} mStep={3} sStep={4}/>] : []}
                    value={datePickerValue}
                    minDate={min}
                    maxDate={max}
                    name={props.name}
                    onChange={changeHandler}/>
                <button
                    className="clear-date-picker"
                    type="button"
                    onClick={() => removeHandler()}
                >
                    <i className="fal fa-times"/>
                </button>
            </DatePickerContainer>
            <FormError show={props.error}>{props.error} </FormError>
        </>
    );
}

function MyRangePicker(props) {
    const [minVal, setMinVal] = useState(undefined);
    const [maxVal, setMaxVal] = useState(undefined);

    const [FromDate, setFromDate] = useState(null);
    const [ToDate, setToDate] = useState(null);

    const [min, setMin] = useState(null);
    const [max, setMax] = useState(null);

    const removeHandler = (mode) => {
        if (mode === 'fromDate') {
            setFromDate(undefined)
            dateObject.fromDate = null;
            props.value(dateObject);
        } else if (mode === 'toDate') {
            setToDate(undefined)
            dateObject.toDate = null;
            props.value(dateObject);
        }
    }

    const fromDateHandler = useCallback((event) => {
        let fromDateNumber = {
            baseFormat: null,
            date: null
        }
        if (event) {
            const month = event.month.number < 10 ? `0${event.month.number}` : `${event.month.number}`
            const day = event.day < 10 ? `0${event.day}` : `${event.day}`
            const hour = event.hour < 10 ?  `0${event.hour}` : `${event.hour}`
            const min = event.minute < 10 ?  `0${event.minute}` : `${event.minute}`
            fromDateNumber = {
                baseFormat: Number(props?.haveTime ?`${event.year}${month}${day}${hour}${min}00` : `${event.year}${month}${day}`),
                date:props?.haveTime ? `${event.year}/${month}/${day} ${hour}:${min}` : `${event.year}/${month}/${day}`
            }
        }
        setMinVal(fromDateNumber.date);
        setFromDate(fromDateNumber.date);
        dateObject.fromDate = {...fromDateNumber};
        props.value(dateObject);
    }, [props])
    const toDateHandler = useCallback((event) => {
        let toDateNumber = {
            baseFormat: null,
            date: null
        }
        if (event) {
            const month = event.month.number < 10 ? `0${event.month.number}` : `${event.month.number}`
            const day = event.day < 10 ? `0${event.day}` : `${event.day}`
            const hour = event.hour < 10 ?  `0${event.hour}` : `${event.hour}`
            const min = event.minute < 10 ?  `0${event.minute}` : `${event.minute}`
            toDateNumber = {
                baseFormat: Number(props?.haveTime ?`${event.year}${month}${day}${hour}${min}00` : `${event.year}${month}${day}`),
                date:props?.haveTime ? `${event.year}/${month}/${day} ${hour}:${min}` : `${event.year}/${month}/${day}`
            }
        }
        setMaxVal(toDateNumber.date);
        setToDate(toDateNumber.date);
        dateObject.toDate = {...toDateNumber};
        props.value(dateObject);
    }, [props])

    useEffect(() => {
        if (!props.fromValue) {
            fromDateHandler(props.fromValue);
        } else {
            const defaultValue = props.fromValue.toString();
            let result =props?.haveTime ? `${defaultValue.slice(0, 4)}/${defaultValue.slice(4, 6)}/${defaultValue.slice(6, 8)} ${defaultValue.slice(8, 10)}:${defaultValue.slice(10, 12)}` : `${defaultValue.slice(0, 4)}/${defaultValue.slice(4, 6)}/${defaultValue.slice(6, 8)}`
            setFromDate(result)
        }
        if (!props.toValue) {
            toDateHandler(props.toValue)
        } else {
            const defaultValue = props.toValue.toString();
            let result = props?.haveTime ? `${defaultValue.slice(0, 4)}/${defaultValue.slice(4, 6)}/${defaultValue.slice(6, 8)} ${defaultValue.slice(8, 10)}:${defaultValue.slice(10, 12)}` : `${defaultValue.slice(0, 4)}/${defaultValue.slice(4, 6)}/${defaultValue.slice(6, 8)}`
            setToDate(result)
        }
        if (props.min) {
            let result = moment(props.min).format('jYYYY/jMM/jDD');
            setMin(result)
        }
        if (props.max) {
            let result = moment(props.max).format('jYYYY/jMM/jDD');
            setMax(result)
        }

    }, [props.fromValue, props.toValue])
    return (
        <>
            <div className="row">
                <div className={props.column ? 'col-12' : 'col-12 col-lg-6'}>
                    <LabelContainer className="mb-1">از تاریخ</LabelContainer>
                    <DatePickerContainer>
                        <DatePicker
                            format={props?.haveTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'}
                            plugins={   props?.haveTime ?
                                [<TimePicker hideSeconds={true} position="bottom" hstep={2} mstep={3} sstep={4}/>] : []}
                            calendar={persian}
                            locale={persian_fa}
                            value={FromDate? FromDate : props.fromDate}
                            minDate={min}
                            maxDate={maxVal ? maxVal : max}
                            onChange={fromDateHandler}/>
                        <button
                            className="clear-date-picker"
                            type="button"
                            onClick={() => removeHandler('fromDate')}
                        >
                            <i className="fal fa-times"/>
                        </button>
                    </DatePickerContainer>
                    <FormError show={props.fromError}>{props.fromError}</FormError>
                </div>
                <div className={props.column ? 'col-12' : 'col-12 col-lg-6'}>
                    <LabelContainer className="mb-1">تا تاریخ</LabelContainer>
                    <DatePickerContainer>
                        <DatePicker
                            calendar={persian}
                            locale={persian_fa}
                            format={props?.haveTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'}
                            plugins={   props?.haveTime ?
                                [<TimePicker hideSeconds={true} position="bottom" hstep={2} mstep={3} sstep={4}/>] : []}
                            value={ToDate? ToDate : props.toDate}
                            minDate={minVal ? minVal : min}
                            maxDate={max}
                            onChange={toDateHandler}/>
                        <button
                            className="clear-date-picker"
                            type="button"
                            onClick={() => removeHandler('toDate')}
                        >
                            <i className="fal fa-times"/>
                        </button>
                    </DatePickerContainer>
                    <FormError show={props.toError}>{props.toError}</FormError>
                </div>
            </div>
        </>
    );
}

export {MyDatePicker, MyRangePicker, DatePickerContainer};
