import React, { useEffect, useState } from 'react';
import { StyledButton } from '../../../Component/Kit/Button';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { WhoisLineOperationService } from '../../../Utility/Services/lines';
import { Loader } from './../../../Component/Shared/Loader/index';
import DateConvert from './../../../Utility/Services/date-convert';
import { toast } from 'react-toastify';

export const WhoisModal = (props) => {
    const { data } = props;
    const [whoidLog, setWhoidLog] = useState();
    const [loading, setLoading] = useState(false);

    const applyWhoIsHandler = () => {
        ApiClient.Post(Urls.magfaWhoisApply(data.id))
            .then(res => {
                if (res.result.isSuccess) {
                    toast.success('Whois مورد نظر اعمال شد.')
                    getMagfaWhoisLogs()
                } else {
                    toast.error(res.result.message)
                }
            })
    }
    const deleteWhoIsHandler = () => {
        ApiClient.Delete(Urls.magfaWhoisApply(data.id))
            .then(res => {
                if (res.result.isSuccess) {
                    toast.success('Whois مورد نظر حذف شد.')
                    getMagfaWhoisLogs()
                } else {
                    toast.error(res.result.message)
                }
            })
    }

    const getMagfaWhoisLogs = () => {
        ApiClient.Get(Urls.magfaWhoisLog(data.id))
            .then(res => {
                res.result.map((m) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.isSuccess = m.isSuccess ? 'موفق' : 'ناموفق';
                    m.operation = WhoisLineOperationService(m.operation);
                    return m;
                });
                setWhoidLog(res.result);
                setLoading(false)
            })
            .catch(er => setLoading(false))
    }

    const whoisLogName = {
        createdAt: "تاریخ ایجاد",
        isSuccess: "وضعیت",
        operation: "عملیات",
        message: "توضیحات",
    };


    useEffect(() => {
        setLoading(true)
        if (data.id) {
            getMagfaWhoisLogs();
        }
    }, [data])

    return (
        <>
            <div className="w-100 custom-modal-body">
                <div className="d-flex align-items-center justify-content-between w-100 mt-3">
                    <p className="m-0 font-weight-bold">اعمال Whois برای کاربر: </p>
                    <StyledButton
                        btnStyle="greenBtn"
                        onClick={() => applyWhoIsHandler()}
                    >
                        Whois
                    </StyledButton>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between w-100 mt-3">
                    <p className="m-0 font-weight-bold">حذف Whois : </p>
                    <StyledButton
                        btnStyle="redBtn"
                        onClick={() => deleteWhoIsHandler()}
                    >
                        حــذف
                    </StyledButton>
                </div>
                <hr />
                <div className="w-100 mt-3">
                    <p className="ml-1 font-weight-bold">Whois Log: </p>
                    {
                        loading
                            ?
                            <Loader />
                            :
                            <div className="table-responsive">
                                <table className="factor-table">
                                    <thead>
                                        {whoisLogName && (
                                            <tr className="gray-bg fo-13">
                                                <th>#</th>
                                                <th>{whoisLogName.isSuccess}</th>
                                                <th>{whoisLogName.operation}</th>
                                                <th>{whoisLogName.createdAt}</th>
                                                <th>{whoisLogName.message}</th>
                                            </tr>
                                        )}
                                    </thead>
                                    <tbody>
                                        {whoidLog &&
                                            whoidLog.map((item, key) => (
                                                <tr className="fo-13" key={`item${key}`}>
                                                    <td>{key + 1}</td>
                                                    <td>{item.isSuccess}</td>
                                                    <td>{item.operation}</td>
                                                    <td>{item.createdAt}</td>
                                                    <td>{item.message}</td>
                                                </tr>
                                            ))
                                        }
                                        {
                                            whoidLog && whoidLog.length == 0 &&
                                            <tr>
                                                <td colSpan={5}>موردی وجود ندارد.</td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                    }

                </div>
            </div>
        </>
    )
}
