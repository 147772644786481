import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../../Component/Kit/Button';
import Modal from '../../../../../Component/Kit/Modal'
import ApiClient from '../../../../../Requester/ApiClient';
import Urls from '../../../../../Requester/Urls';

export const AcceptedPaymentModal = (props) => {
    const { paymentId, visible, setVisible, onCancel, onClose } = props;
    const [loadingFlag, setLoadingFlag] = useState(false);
    const history = useHistory();

    const acceptedPaymentHandler = () => {
        let dataBody = {
            "isAccepted": true
        }
        setLoadingFlag(true);

        ApiClient.Patch(Urls.GetPendingManualPayments(paymentId), dataBody)
            .then((res) => {
                toast.success('پرداخت با موفقیت تایید شد!')
                setVisible(false);
                history.push(`/financial/manual-payment`);
            })
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            headerTitle="تایید پرداخت"
        >
            <div className="col-12 py-5 text-center">
                <h6>آیا از تایید این پرداخت مطمئن هستید؟ </h6>

                <div className="mt-5">
                    <StyledButton
                        disabled={loadingFlag}
                        btnStyle="greenBtn ml-3"
                        onClick={() => acceptedPaymentHandler()}
                    >
                        مطمئنم
                    </StyledButton>

                    <StyledButton
                        btnStyle="grayBtn"
                        onClick={() => { setVisible(false) }}
                    >
                        بستن
                    </StyledButton>

                </div>

            </div>
        </Modal>
    )
}
