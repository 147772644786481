import * as Yup from "yup";
import {basePrice, count, description, item , discountPrice} from "../Rules";

export const EditInvoiceDetailSchema = Yup.object().shape({
    details: Yup.array().of(
        Yup.object().shape({
            basePrice,
            description,
            count,
            item,
            discountPrice
        })
    )
});
