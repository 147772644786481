import React, {useEffect} from "react";
import {
    NavLink,
    Route,
    Switch,
    useHistory,
    useLocation,
} from "react-router-dom";
import {MainContent, WrapperLayout} from "../../../Component/Kit/Layout";
import {InvoiceApproved} from "./InvoiceApproved";
import {InvoiceAwaitingApproval} from "./InvoiceAwaitingApproval";
import OldPanelInvoiceApproved from "./OldPanelInvoiceApproved";
import OldPanelInvoiceAwaitingApproval from "./OldPanelInvoiceAwaitingApproval";

export const OfficialInvoicesList = ({match}) => {

    const tableConfig = {
        header: {
            userName: "نام کاربری",
            userId: "شناسه کاربر",
            onlyReturns: "نوع فاکتور",
            totalPrice: "مبلغ کل پرداختی",
            canModifyDiscountPrice: "امکان اصلاح تخفیف",
            createdAt: "تاریخ و زمان ایجاد",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
        },
        mode: ["filter", "search"],
        filters: ["datePickerFilter", "onlyReturns"],
    };

    const oldPanelTableConfig = {
        header: {
            userName: "نام کاربری",
            userId: "شناسه کاربر",
            totalPrice: "مبلغ کل پرداختی",
            canModifyDiscountPrice: "امکان اصلاح تخفیف",
            createdAt: "تاریخ و زمان ایجاد",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
        },
        mode: ["filter", "search", "export-excel"],
        filters: ["datePickerFilter"],
    };

    const tableActions = (items) => {
        if (items.actionName === "view") {
            history.push(
                `/financial/invoice/${items.data.id}/isReturn=${items.data.isReturnInvoice}`
            );
        }
    };


    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === `${match.url}`) {
            history.push(`${match.url}/pending`);
        }
    }, []);
    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <MainContent>
                    <ul className="user-tab-menu">
                        <li>
                            <NavLink activeClassName="is-active" to={`${match.url}/pending`}>
                                فاکتور‌های منتظر تایید
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName="is-active"
                                to={`${match.url}/not-pending`}
                            >
                                فاکتور‌های تاییدشده
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName="is-active"
                                to={`${match.url}/old-panel-pending`}
                            >
                                فاکتور‌های منتظر تایید (پنل قدیم)
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName="is-active"
                                to={`${match.url}/old-panel-not-pending`}
                            >
                                فاکتور‌های تاییدشده (پنل قدیم)
                            </NavLink>
                        </li>
                    </ul>
                    <div className="p-3">
                        <Switch>
                            <Route exact path={`${match.path}/pending`}>
                                <InvoiceAwaitingApproval
                                    tableConfig={tableConfig}
                                    tableActions={tableActions}
                                />
                            </Route>
                            <Route path={`${match.path}/not-pending`}>
                                <InvoiceApproved
                                    tableConfig={tableConfig}
                                    tableActions={tableActions}
                                />
                            </Route>
                            <Route path={`${match.path}/old-panel-pending`}>
                                <OldPanelInvoiceAwaitingApproval
                                    tableConfig={oldPanelTableConfig}
                                />
                            </Route>
                            <Route path={`${match.path}/old-panel-not-pending`}>
                                <OldPanelInvoiceApproved
                                    tableConfig={oldPanelTableConfig}
                                />
                            </Route>
                        </Switch>
                    </div>
                </MainContent>
            </div>
        </WrapperLayout>
    );
};
