import {connect, useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {StyledButton} from "../../../../Component/Kit/Button";
import Modal from "react-bootstrap/Modal";
import TowerOperatorConfiguration from "./tower-operator-configuration";
import Swal from "sweetalert2";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import {toast} from "react-toastify";
import {settingModal} from "../../../../Actions/setting-modal";


function SendSmsViaTowerOperatorConfigurations({data}) {
    let dispatch = useDispatch();
    const [isModal, setIsModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [requestData, setRequestData] = useState([]);


    const updateOperator = (event) => {
        setRequestData(requestData.map(obj => (obj.OperatorId === event.OperatorId ? event : obj)));
    }

    const onSubmit = () => {
        Swal.fire({
            title: `آیا مطمئن هستید؟`,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                const body = JSON.stringify(requestData);
                ApiClient.Patch(Urls.towerOperatorConfiguration(), body)
                    .then((res) => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        dispatch(settingModal(true));
                    })
            }

        })
    }

    useEffect(() => {
        if (data) {
            setModalData(JSON.parse(data))
            setRequestData(JSON.parse(data))
        }
    }, [data]);


    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">تنظیمات اپراتورهای ارسال از دکل</span>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>

            <Modal centered show={isModal} onHide={() => {
                setIsModal(false)
            }}>
                <Modal.Header>
                    <Modal.Title>تنظیمات اپراتورهای ارسال از دکل</Modal.Title>
                    <i onClick={() => {
                        setIsModal(false)
                    }} className="close-btn fa fa-xmark"/>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalData &&
                        modalData.map((operator, index) => (
                            <TowerOperatorConfiguration key={`operator-${index}`} operatorData={operator}
                                                        updateOperator={updateOperator}/>
                        ))
                    }

                    <div className="mt-2 d-flex justify-content-between w-100">
                        <StyledButton
                            btnStyle="grayBtn"
                            type="reset"
                            onClick={() => setIsModal(false)}
                        >
                            بستن
                        </StyledButton>

                        <StyledButton btnStyle="greenBtn mr-2" onClick={() => onSubmit()}>
                            اعمال تغییرات
                        </StyledButton>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default connect()(SendSmsViaTowerOperatorConfigurations);