import React, { useEffect, useState } from 'react'
import { StyledButton } from '../../Component/Kit/Button';
import ApiClient from '../../Requester/ApiClient';
import Urls from '../../Requester/Urls';
import { convertPrefixAbtestService } from './Services/convert-prefix-abtest-service';

export const ABTestShowLinkModal = ({ itemId, onCloseModal }) => {

    const [campaignItemsData, setCampaignItemsData] = useState([]);

    useEffect(() => {
        if (itemId) {
            ApiClient.Get(Urls.getPendingCampaignByItem(itemId))
                .then(res => {
                    let data = res.result
                    setCampaignItemsData(data);
                })
        }
    }, [itemId])

    const openHyperLink = (url) => {
        // window.location.href = url;
        let direction = url.replace(/^\/\/|^.*?:(\/\/)?/, '');
        direction = '//' + direction;
        window.open(direction, '_blank');
    }

    return (
        <>
            {
                campaignItemsData &&  campaignItemsData.length
                    ? (
                        <div className="w-100">
                            <ul className="ShowLinkABTestCampaign">
                                {campaignItemsData.map((item) => (
                                    <li>
                                        <span>تست {convertPrefixAbtestService(item.alias)}: </span><span>{item.title}</span>
                                        {
                                            item.basicLink
                                                ?
                                                (
                                                    <p className="linkView" onClick={() => openHyperLink(item.basicLink)}>
                                                        {item.basicLink}
                                                    </p>
                                                )
                                                :
                                                (
                                                    <p className="linkView text-right">
                                                        لینک ندارد.
                                                    </p>
                                                )
                                        }

                                    </li>
                                )
                                )}
                            </ul>
                        </div>
                    )
                    : (
                        <div className='w-100 py-4 px-2'>
                            <p>لینکی برای نمایش وجود ندارد.</p>
                        </div>
                    )
            }

            <div className="d-flex justify-content-center mb-2 w-100">
                <StyledButton
                    width="35%"
                    btnStyle="grayBtn"
                    type="reset"
                    onClick={() => {
                        onCloseModal(false)
                    }}
                >
                    بستن
                </StyledButton>
            </div>
        </>
    )
}
