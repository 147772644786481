import React from "react";
import Modal from "../../../../Component/Kit/Modal";
import {FormLable, StyledForm, StyledInput} from "../../../../Component/Kit/Form";
import {StyledButton} from "../../../../Component/Kit/Button";
import {Formik} from "formik";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import {TimePickerStyled} from "../../../../Component/Shared/TimePicker";
import {PurchaseRestrictionsSchema} from "../../../../Utility/Validation/Schema/PurchaseRestrictionsSchema";
import Swal from "sweetalert2";
import ApiClient from "../../../../Requester/ApiClient";
import {toast} from "react-toastify";
import {settingModal} from "../../../../Actions/setting-modal";
import Urls from "../../../../Requester/Urls";
import {connect, useDispatch} from "react-redux";

function PurchaseRestrictionsModal({data, visible, setVisible, onClose, onCancel}) {
    let dispatch = useDispatch();
    return (
        <Modal
            onClose={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            visible={visible}
            headerTitle="محدودیت در زمان و مبلغ پرداخت"
        >
            {/*validationSchema={}*/}
            <Formik
                initialValues={{
                    paymentCount: data.AllowedPaymentCount,
                    fromTime: data.FromTime,
                    toTime: data.ToTime
                }}
                validationSchema={PurchaseRestrictionsSchema}
                isInitialValid={false}
                enableReinitialize={true}
                onSubmit={(values) => {
                    Swal.fire({
                        title: `آیا مطمئن هستید؟`,
                        text: "",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonText: "انصراف",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "بله، مطمئن هستم",
                    }).then(result => {
                        if (result.isConfirmed) {
                            const body = {
                                allowedPaymentCount: Number(values.paymentCount),
                                fromTime: `${values.fromTime.slice(0, 2)}:${values.fromTime.slice(2, 4)}:00`,
                                toTime: `${values.toTime.slice(0, 2)}:${values.toTime.slice(2, 4)}:00`,
                            }
                            ApiClient.Patch(Urls.modifyPaymentLimitations(), body)
                                .then((res) => {
                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                    // values.numberLimitation = defaultData;
                                    dispatch(settingModal(true));
                                })
                        }
                    })

                }}
                onReset={(values, formikBag) => {
                    onClose(false)
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                        onReset={formik.handleReset}
                    >
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <FormLable show={true}>
                                    از ساعت
                                </FormLable>
                                <TimePickerStyled
                                    withoutSecond={true}
                                    defaultValue={formik.values.fromTime}
                                    value={(event) => {
                                        if (event)
                                            formik.setFieldValue("fromTime", event.baseFormat)
                                    }}
                                    error={
                                        formik.errors.fromTime &&
                                        formik.touched.fromTime
                                            ? formik.errors.fromTime
                                            : "false"
                                    }
                                    name="fromTime"/>
                            </div>
                            <div className="col-12 col-sm-6">
                                <FormLable show={true}>
                                    تا ساعت
                                </FormLable>
                                <TimePickerStyled
                                    withoutSecond={true}
                                    defaultValue={formik.values.toTime}
                                    value={(event) => {
                                        if (event)
                                            formik.setFieldValue("toTime", event.baseFormat)
                                    }}
                                    error={
                                        formik.errors.toTime &&
                                        formik.touched.toTime
                                            ? formik.errors.toTime
                                            : "false"
                                    }
                                    name="toTime"/>
                            </div>
                            <StyledInput
                                formGroupClass="col-lg-6 col-md-6 col-12"
                                lable="تعداد پرداخت"
                                focus={true}
                                placeholder="لطفا حداکثر تعداد پرداخت را وارد کنید"
                                name="paymentCount"
                                value={formik.values.paymentCount}
                                error={
                                    formik.errors.paymentCount &&
                                    formik.touched.paymentCount
                                        ? formik.errors.paymentCount
                                        : "false"
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("paymentCount", e.target.value)
                                }
                            />
                        </div>

                        <div className="text-left">
                            <StyledButton btnStyle="greenBtn mt-3"
                                          disabled={!formik.isValid || !formik.dirty}
                                          type="submit">
                                ویرایش
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </Modal>
    )
}

export default connect()(PurchaseRestrictionsModal)

