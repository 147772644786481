import {NavLink, Route, Switch, useHistory, useLocation} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {MainContent} from "../../Component/Kit/Layout";
import {SendTowerPending} from "./Pending";
import {SendTowerNotPending} from "./NotPending";

const SendTower = ({ match }) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === `${match.url}`) {
            history.push(`${match.url}/pending`)
        }
    }, [])
    return (
        <>
            <MainContent>
                <ul className="user-tab-menu">
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/pending`}>
                            منتظر تایید
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/not-pending`}>
                            تعیین وضعیت شده
                        </NavLink>
                    </li>
                </ul>
                <div className="p-3">
                    <Switch>
                        <Route exact path={`${match.path}/pending`}>
                            <SendTowerPending />
                        </Route>
                        <Route path={`${match.path}/not-pending`}>
                            <SendTowerNotPending />
                        </Route>
                    </Switch>
                </div>
            </MainContent>
        </>
    )
}

export default SendTower
