const SendSmsTypeEnum = {
    FastSend: 1,
    FastSendTest: 2,
    BulkSimpleSend: 3,
    ParametricSmartSend: 4,
    MultipleTextSmartSend: 5,
    SimpleCampaign: 6,
    ScheduledCampaign: 7,
    EventCampaign: 8,
    ApiSend: 9,
    SmsSecretary: 10,
    SmsTransmission: 11,
    ABTestCampaign: 12,
    MobileBank: 13,
    CodeReader: 14,
    TowerSend: 15,
}

const SendSmsStatusEnum = {
    Ready: 1,
    Sending: 2,
    CreditReturn: 3,
    Finished: 4,
    Failed: 5,
    Pending: 6,
    Rejected: 7,
}
export const sendSMSModel = [
    {value: SendSmsTypeEnum.FastSend, name: "ماژول ارسال سریع", key: "FastSend"},
    {value: SendSmsTypeEnum.FastSendTest, name: "تست ماژول ارسال سریع", key: "FastSendTest"},
    {value: SendSmsTypeEnum.BulkSimpleSend, name: "ارسال ساده", key: "BulkSimpleSend"},
    {value: SendSmsTypeEnum.ParametricSmartSend, name: "هوشمند پارامتریک", key: "ParametricSmartSend"},
    {value: SendSmsTypeEnum.MultipleTextSmartSend, name: "هوشمند متناظر", key: "MultipleTextSmartSend"},
    {value: SendSmsTypeEnum.SimpleCampaign, name: "کمپین ساده", key: "SimpleCampaign"},
    {value: SendSmsTypeEnum.ScheduledCampaign, name: "کمپین دوره ای", key: "ScheduledCampaign"},
    {value: SendSmsTypeEnum.EventCampaign, name: "کمپین رویدادی", key: "EventCampaign"},
    {value: SendSmsTypeEnum.ApiSend, name: "وب سرویس", key: "ApiSend"},
    {value: SendSmsTypeEnum.SmsSecretary, name: "ماژول منشی پیامکی", key: "SmsSecretary"},
    {value: SendSmsTypeEnum.SmsTransmission, name: "ماژول انتقال پیامک", key: "SmsTransmission"},
    {value: SendSmsTypeEnum.ABTestCampaign, name: "کمپین A-B Test", key: "ABTestCampaign"},
    {value: SendSmsTypeEnum.MobileBank, name: "بانک شماره", key: "MobileBank"},
    {value: SendSmsTypeEnum.CodeReader, name: "کدخوان", key: "CodeReader"},
    {value: SendSmsTypeEnum.TowerSend, name: "ارسال از دکل", key: "TowerSend"},
];

export const sendSMSStatusModel = [
    {value: SendSmsStatusEnum.Ready, name: "آماده"},
    {value: SendSmsStatusEnum.Sending, name: "در حال ارسال"},
    {value: SendSmsStatusEnum.CreditReturn, name: "بررسی صحت ارسال"},
    {value: SendSmsStatusEnum.Finished, name: "پایان یافته"},
    {value: SendSmsStatusEnum.Failed, name: "ناموفق"},
    {value: SendSmsStatusEnum.Pending, name: "انتظار"},
    {value: SendSmsStatusEnum.Rejected, name: "رد شده"},
];

