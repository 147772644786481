import React, {useEffect, useState} from "react";
import {toast} from 'react-toastify';
import Swal from "sweetalert2";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {StyledButton} from "../../Kit/Button";
import {StyledRadioBtn, StyledSwitchBox} from "../../Kit/Form";
import {lineTypesEnum} from "../../../Utility/Model/lines.model";

function UserLineModal({data, dataChange, onCloseModal}) {
    const [status, setStatus] = useState();
    const [whoIsApplied, setWhoIsApplied] = useState();
    const [lineType, setLineType] = useState();
    const deactiveUserLines = (item) => {
        const payload = {
            whoisApplied: whoIsApplied,
            status,
            lineType
        }
        Swal.fire({
            title: "آیا مطمئن هستید؟",
            text: " ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Patch(Urls.statusUserLines(item.id), payload)
                    .then(() => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        dataChange(true)
                    })
            }
        });
    };

    useEffect(() => {
        setStatus(data.status);
        setWhoIsApplied(data.whoisApplied)
        setLineType(data.lineType)
    }, [data])
    return (
        <>
            {data &&
                <>
                    <ul className="params text-right w-100">
                        {data.userName ? (
                            <li className="params__list">
                                <div className="params__list__label">
                                    <span>نام کاربری</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{data.userName}</span>
                                </div>
                            </li>
                        ) : null}
                        <li className="params__list">
                            <div className="params__list__label">
                                <span>شماره</span>
                            </div>
                            <div className="params__list__value">
                                <span className="params__list__label--white">{data.number}</span>
                            </div>
                        </li>
                        <li className="params__list">
                            <div className="params__list__label">
                                <span>ارائه دهنده خدمات</span>
                            </div>
                            <div className="params__list__value">
                                <span className="params__list__label--white">{data.providerName}</span>
                            </div>
                        </li>
                        <li className="params__list">
                            <div className="params__list__label">
                                <span>نوع دریافت</span>
                            </div>
                            <div className="params__list__value">
                                <span className="params__list__label--white">
                                    {data.earnMethodName}
                                </span>
                            </div>
                        </li>
                        <li className="params__list">
                            <div className="params__list__label">
                                <span>تاریخ دریافت خط</span>
                            </div>
                            <div className="params__list__value">
                                <span className="params__list__label--white">{data.createdAt}</span>
                            </div>
                        </li>
                        <li className="params__list">
                            <div className="params__list__label">
                                <span>وضعیت خط</span>
                            </div>
                            <div className="params__list__value">
                                <span className="params__list__label--white">{data.statusName}</span>
                            </div>
                        </li>
                    </ul>

                    <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                        <span>وضعیت ({status === 3 ? "فعال" : "غیر فعال"})</span>
                        <StyledSwitchBox checked={(status === 3)}
                                         onChange={(e) => setStatus(e.target.checked ? 3 : 2)}/>
                    </div>

                    <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                        <span className="direction-ltr">Who is?</span>
                        <StyledSwitchBox checked={(whoIsApplied)} onChange={(e) => setWhoIsApplied(e.target.checked)}/>
                    </div>


                    <div className="d-flex align-items-center justify-content-between w-100 mb-3">

                        <span>نوع خط</span>


                        <div className="d-flex">
                            <StyledRadioBtn id='Service' name='type'
                                            label='خدماتی'
                                            className="p-3"
                                            checked={lineType === lineTypesEnum.Service}
                                            onChange={(e) =>
                                                setLineType(lineTypesEnum.Service)
                                            }/>
                            <StyledRadioBtn id='Advertising' name='type' label='تبلیغاتی'
                                            checked={lineType === lineTypesEnum.Advertising}
                                            className="p-3"
                                            onChange={(e) =>
                                                setLineType(lineTypesEnum.Advertising)
                                            }/>

                        </div>
                    </div>


                    <div className="d-flex justify-content-between w-100">
                        <StyledButton
                            width="35%"
                            btnStyle="grayBtn"
                            type="reset"
                            onClick={() => {
                                onCloseModal(false);
                                setStatus(data.status);
                                setWhoIsApplied(data.whoisApplied);
                            }}
                        >
                            بستن
                        </StyledButton>
                        <StyledButton
                            btnStyle="greenBtn"
                            width="35%"
                            type="button"
                            onClick={() => deactiveUserLines(data)}
                        >
                            ذخیره
                        </StyledButton>
                    </div>
                </>
            }
        </>
    );
}

export default UserLineModal;
