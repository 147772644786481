import React, { useEffect, useState } from "react";
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import BackToPage from "../../../../Component/Kit/back";
import {
  HeaderContent,
  MainContent,
  WrapperLayout,
} from "../../../../Component/Kit/Layout";
import EditUserdocuments from "./EditUserdocuments";
import EditUserInformation from "./EditUserInformation";
import EditUserLegalInformation from "./EditUserLegalInformation";
import { connect } from "react-redux";

const EditUserProfile = ({ userProfileData }) => {
  let match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [prev, setPrev] = useState("");
  const [isLegal, setIsLegal] = useState(false);
  useEffect(() => {
    if (Object.entries(userProfileData).length) {
      setIsLegal(userProfileData.isLegal);
    } else {
      history.push(`/userDetail/${match.params.id}/profile`);
    }

    if (!location.state) {
      setPrev(`/userDetail/${match.params.id}/profile`);
    }
  }, [location.state, userProfileData]);

  return (
    <WrapperLayout>
      <div className="c-wrapper">
        <HeaderContent className="justify-content-between">
          <span className="fakeContainer"></span>
          <BackToPage toPage={prev} />
        </HeaderContent>
      </div>

      <MainContent>
        <ul className="user-tab-menu">
          <li>
            <NavLink activeClassName="is-active" to={`info`}>
              ویرایش اطلاعات کاربر
            </NavLink>
          </li>
          {isLegal &&
              <li>
                <NavLink activeClassName="is-active" to={`legal`}>
                  ویرایش اطلاعات حقوقی
                </NavLink>
              </li>
          }
          <li>
            <NavLink activeClassName="is-active" to={`document`}>
              ویرایش مستندات
            </NavLink>
          </li>
        </ul>

        <div className="px-3">
          <Switch>
            <Route exact path={`${match.path}/info`}>
              <EditUserInformation />
            </Route>
            <Route path={`${match.path}/legal`}>
              <EditUserLegalInformation />
            </Route>
            <Route path={`${match.path}/document`}>
              <EditUserdocuments />
            </Route>
          </Switch>
        </div>
      </MainContent>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => ({
  userProfileData: state.UserProfileDataReducer,
});

export default connect(mapStateToProps)(EditUserProfile);
