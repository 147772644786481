import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../Component/Kit/Button';
import { StyledForm, StyledInput, StyledSwitchBox } from '../../../Component/Kit/Form';
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout';
import BackToPage from '../../../Component/Kit/back';
import { ModifyCreditPackageSchema } from '../../../Utility/Validation/Schema/ModifyCreditPackageSchema';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';

const ModifyCreditPackageItem = ({ creditPackageData }) => {
    const history = useHistory();
    const params = useParams();
    const editMode = params.id;
    const [creditPackageItem, setCreditPackageItem] = useState({})

    useEffect(() => {
        if (editMode && Object.entries(creditPackageData).length) {
            setCreditPackageItem(creditPackageData);
        }
        if (editMode && !Object.entries(creditPackageData).length) {
            history.push("/credit-package");
        }

    }, [creditPackageData])

    return (
        <WrapperLayout>
            <div className="c-wrapper">
                <HeaderContent className="justify-content-between">
                    <span className="fakeContainer"></span>
                    <BackToPage />
                </HeaderContent>

                <MainContent>
                    <div className="col-12 col-lg-12">
                        <div className="row">
                            <div className="col-12 col-lg-12">
                                <PageTitleLayout>
                                    <h5>{editMode ? 'ویرایش' : 'ایجاد'} بسته شارژ شگفت انگیز </h5>
                                    <span> می‌توانید مشخصات بسته شارژ شگفت انگیز خود را {editMode ? 'تغییر دهید.' : 'وارد نمایید.'}</span>
                                </PageTitleLayout>
                                <hr className="mt-4 mx-2" />
                            </div>

                            <Formik
                                initialValues={{
                                    description: creditPackageItem.description || '',
                                    discountedBasePrice: creditPackageItem.discountedBasePrice || '',
                                    netPrice: creditPackageItem.netPrice || '',
                                    isActive: creditPackageItem.isActive || '',
                                }}
                                validationSchema={ModifyCreditPackageSchema}
                                validateOnMount={false}
                                enableReinitialize={true}
                                onSubmit={(values, actions) => {
                                    let payload = {
                                        description: values.description,
                                        discountedBasePrice: Number(values.discountedBasePrice),
                                        netPrice: Number(values.netPrice)
                                    };
                                    if (editMode) {
                                        payload = { ...payload, isActive: values.isActive };
                                        ApiClient.Put(Urls.ModifyCreditPackage(params.id), payload)
                                            .then(res => {
                                                toast.success('بسته شارژ با موفقیت ویرایش شد.')
                                                history.goBack();
                                            })
                                    } else {
                                        ApiClient.Post(Urls.ModifyCreditPackage(), payload)
                                            .then(res => {
                                                toast.success('بسته شارژ با موفقیت ایجاد شد.')
                                                history.goBack();
                                            })
                                    }
                                }}
                            >
                                {(formik) => (
                                    <StyledForm
                                        onSubmit={formik.handleSubmit}
                                        onReset={formik.handleReset}
                                        onBlur={formik.handleBlur}
                                    >
                                        <div className="row">
                                            <StyledInput
                                                formGroupClass="col-12 col-md-4"
                                                lable="عنوان بسته"
                                                focus={true}
                                                type="text"
                                                name='description'
                                                value={formik.values.description || ''}
                                                error={(formik.errors.description && formik.touched.description)
                                                    ? formik.errors.description
                                                    : 'false'
                                                }
                                                onChange={(e) =>
                                                    formik.setFieldValue("description", e.target.value)
                                                }
                                            />
                                            <StyledInput
                                                formGroupClass="col-12 col-md-4"
                                                lable="تخفیف قیمت پایه هر پیامک"
                                                focus={true}
                                                type="text"
                                                name='discountedBasePrice'
                                                value={formik.values.discountedBasePrice || ''}
                                                error={(formik.errors.discountedBasePrice && formik.touched.discountedBasePrice)
                                                    ? formik.errors.discountedBasePrice
                                                    : 'false'
                                                }
                                                onChange={(e) =>
                                                    formik.setFieldValue("discountedBasePrice", e.target.value)
                                                }
                                            />

                                            <StyledInput
                                                formGroupClass="col-12 col-md-4"
                                                lable="قیمت نهایی"
                                                focus={true}
                                                type="text"
                                                name='netPrice'
                                                value={formik.values.netPrice || ''}
                                                error={(formik.errors.netPrice && formik.touched.netPrice)
                                                    ? formik.errors.netPrice
                                                    : 'false'
                                                }
                                                onChange={(e) =>
                                                    formik.setFieldValue("netPrice", e.target.value)
                                                }
                                            />
                                            {editMode &&
                                                <div className="col-12 md-6 mt-3">
                                                    <div className="d-flex">
                                                        <FormLabel className='ml-3'>وضعیت پکیج</FormLabel>
                                                        <StyledSwitchBox
                                                            focus={true}
                                                            checked={formik.values.isActive !== undefined
                                                                ? formik.values.isActive
                                                                : formik.initialValues.isActive}
                                                            onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="mt-4 d-flex justify-content-end">
                                            <StyledButton
                                                btnStyle="greenBtn"
                                                type="submit"
                                                disabled={(!formik.isValid || !formik.dirty)}
                                            >
                                                {editMode ? 'ویرایش' : 'ایجاد'} بسته
                                            </StyledButton>
                                        </div>

                                    </StyledForm>
                                )}

                            </Formik>

                        </div>
                    </div>
                </MainContent>

            </div>
        </WrapperLayout>
    )
}

const mapStateToProps = (state) => ({
    creditPackageData: state.CreditPackageItemReducer
});
export default connect(mapStateToProps)(ModifyCreditPackageItem)