import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import * as Yup from "yup";
import moment from "moment-jalaali";
import {Formik} from "formik";


import Urls from "../../../Requester/Urls";
import ApiClient from "../../../Requester/ApiClient";
import {ConvertQueryString} from "../../../Utility/Services/convert-query-string";
import {MainContent, PageTitleLayout} from "../../../Component/Kit/Layout";
import Table from "../../../Component/Shared/Table/Table";
import {StyledButton} from "../../../Component/Kit/Button";
import DateConvert from "../../../Utility/Services/date-convert";
import {FormLable, StyledForm} from "../../../Component/Kit/Form";
import {MyDatePicker} from "../../../Component/Shared/DatePicker";

function AccessPublic() {
    const location = useLocation();
    const [isModal, setIsModal] = useState(false);
    const [userId, setUserId] = useState();
    const [usersPublicLines, setUsersPublicLines] = useState([]);

    const tableConfig = {
        header: {
            userName: "نام کاربری",
            isExpiredIcon: "وضعیت",
            createdAt: "تاریخ ایجاد",
            expireAt: "تاریخ انقضا",
        },
        actions: {
            edit: {
                icon: "fa fa-edit",
                condition: (data) => {
                    return data.isExpired === false
                }
            },
            delete: {
                icon: "fa fa-trash",
                condition: (data) => {
                    return data.isExpired === false
                }
            }
        },
        mode: ["filter", "search"],
        filters: ["isExpiredLine"],
    };
    useEffect(() => {
        getUsersPublicLineAccess()
    }, [location.search]);

    const getUsersPublicLineAccess = () => {
        let params = ConvertQueryString(location.search);
        if ('isExpiredLine' in params) {
            params['isExpired'] = params.isExpiredLine
            delete params.isExpiredLine
        }

        ApiClient.Get(Urls.usersPublicLineAccess(), params).then((res) => {
            let data = res.result;
            data.items.map((m) => {
                m.createdAt = DateConvert(m.createdAt);
                m.expireAt = DateConvert(m.expireAt);
                m.isExpiredIcon = !m.isExpired
                    ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                    : `<i class="fa fa-times-circle text-danger fa-lg"/>`;

                return m
            });
            setUsersPublicLines(data);
        });

    }


    function tableActions(item) {
        switch (item.actionName) {
            case 'edit':
                setIsModal(true);
                setUserId(item.data.userId);
                break;
            case 'delete':
                deletePublicLine(item.data.userId);
                break;

            default:
                break;
        }
    }


    const deletePublicLine = (userId) => {
        Swal.fire({
            text: `آیا از حذف دسترسی مطمئن هستید ؟`,
            title: "احتیاط کن !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiClient.Delete(Urls.userPublicLinesActivition(userId)).then(
                    () => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        getUsersPublicLineAccess()
                    }
                )
            }
        });
    }


    return (
        <>
            <MainContent>
                <PageTitleLayout>
                    <h5>تخصیص خطوط ویژه</h5>
                    <span>
                        تخصیص خطوط ویژه سامانه در این بخش مدیریت می شود.
                    </span>
                </PageTitleLayout>
                <Table data={usersPublicLines.items} config={tableConfig} actionDispatched={tableActions}
                       pageConfig={{
                           pageNumber: usersPublicLines.pageNumber,
                           pageSize: usersPublicLines.pageSize,
                           totalItemsCount: usersPublicLines.totalItemsCount,
                           totalPagesCount: usersPublicLines.totalPagesCount,
                       }}
                ></Table>
            </MainContent>


            <Modal centered show={isModal} onHide={() => {
                setIsModal(false)
            }}>
                <Modal.Header>
                    <Modal.Title>ویرایش دسترسی خطوط ویژه</Modal.Title>
                    <i onClick={() => {
                        setIsModal(false)
                    }} className="close-btn fa fa-xmark"/>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            publicLineDate: '',
                        }}
                        validationSchema={Yup.object().shape({
                            publicLineDate: Yup.string()
                                .label("publicLineDate")
                                .required("این فیلد الزامی است."),
                        })}

                        onSubmit={(values, formikHelpers) => {

                            ApiClient.Patch(Urls.usersPublicLineAccess(userId), values.publicLineDate).then(
                                () => {
                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                    formikHelpers.resetForm();
                                    setIsModal(false)
                                    getUsersPublicLineAccess()
                                }
                            ).finally(() => {
                                formikHelpers.setSubmitting(false);
                            })

                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                            >
                                <FormLable show={true}>فعال تا تاریخ</FormLable>
                                <MyDatePicker
                                    haveTime
                                    defaultValue={formik.values.publicLineDate}
                                    value={(event) => {
                                        formik.setFieldValue("publicLineDate", event.baseFormat);
                                    }}
                                    min={moment().subtract(0, 'days')}
                                    max={moment().add(1, 'jYear')}
                                    name="publicLineDate"
                                    error={
                                        formik.errors.publicLineDate !== undefined
                                            ? formik.errors.publicLineDate
                                            : false
                                    }
                                />

                                <div className="d-flex justify-content-between mt-3">
                                    <StyledButton
                                        width="35%"
                                        btnStyle="grayBtn"
                                        type="reset"
                                        onClick={() => setIsModal(false)}

                                    >
                                        بستن
                                    </StyledButton>
                                    <StyledButton
                                        width="35%"
                                        disabled={!formik.isValid || formik.isSubmitting}
                                        btnStyle="greenBtn"
                                        type="submit"
                                        loading={formik.isSubmitting ? 1 : 0}
                                    >
                                        ثبت
                                    </StyledButton>


                                </div>


                            </StyledForm>
                        )}
                    </Formik>


                </Modal.Body>
            </Modal>

        </>
    );
}

export default AccessPublic;
