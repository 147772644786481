import { Formik } from "formik";
import React from "react";
import { StyledForm, StyledInput } from "../../../../../Component/Kit/Form";
import Modal from "../../../../../Component/Kit/Modal";
import { StyledButton } from "../../../../../Component/Kit/Button";
import { AssignPlanLineToUserSchema } from "../../../../../Utility/Validation/Schema/AssignPlanLineToUserSchema";
import Swal from "sweetalert2";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";
import { toast } from "react-toastify";

const AssignPlanLineModal = ({
  visible,
  closeHandler,
  confirmHandler,
  userId,
}) => {
  return (
    <Modal
      onClose={closeHandler}
      visible={visible}
      onCancel={closeHandler}
      headerTitle="تخصیص خط پلن ها به کاربر"
    >
      <Formik
        initialValues={{
          planLineNumber: "",
        }}
        validationSchema={AssignPlanLineToUserSchema}
        enableReinitialize={false}
        onSubmit={(values, actions) => {
          Swal.fire({
            title: "",
            text: `آیا مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
          }).then((result) => {
            if (result.isConfirmed) {
              ApiClient.Patch(Urls.plansLinesPendingUser(userId), Number(values.planLineNumber)).then(() => {
                toast.success("خط به کاربر با موفقیت تخصیص داده شد.");
                actions.resetForm();
                closeHandler();
                confirmHandler();
              });
            }
          });
        }}
      >
        {(formik) => (
          <StyledForm onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
            <StyledInput
              lable="شماره خط"
              focus={true}
              name="planLineNumber"
              value={formik.values.planLineNumber}
              error={
                formik.errors.planLineNumber && formik.touched.planLineNumber
                  ? formik.errors.planLineNumber
                  : "false"
              }
              onChange={(e) =>
                formik.setFieldValue("planLineNumber", e.target.value)
              }
            />
            <div className="d-flex justify-content-between w-100">
              <StyledButton
                width="35%"
                btnStyle="grayBtn"
                type="reset"
                onClick={() => {
                  closeHandler(false);
                }}
              >
                بستن
              </StyledButton>
              <StyledButton
                width="35%"
                btnStyle="greenBtn"
                disabled={!formik.isValid}
                type="submit"
              >
                تایید
              </StyledButton>
            </div>
          </StyledForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AssignPlanLineModal;
