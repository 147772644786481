import React, {useEffect, useRef} from "react";
import {Badge} from "react-bootstrap";
import {useFormik} from "formik";

import {
    FormLayout,
    StyledForm,
    StyledInput,
    StyledSwitchBox,
} from "../../../../Component/Kit/Form";
import GetOperator from "../../../../Utility/Services/operator";

function TowerOperatorConfiguration({operatorData, updateOperator}) {
    const isMounted = useRef(false);
    const formik = useFormik({
        initialValues: {
            ...operatorData,
        },
    });


    useEffect(() => {
        if (isMounted.current) {
            updateOperator(formik.values)
        } else {
            isMounted.current = true;
        }
    }, [formik.values]);

    return (
        <>
            <div className="px-0">
                <StyledForm
                    className="px-0"
                    style={{fontSize: '.85rem'}}
                >
                    <FormLayout >
                        <div className="form-title ">
                            <span>{GetOperator(operatorData.OperatorId)}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-start flex-wrap">

                            <StyledInput
                                lable="هزینه هر پیامک"
                                focus={true}
                                placeholder="50"
                                type="number"
                                name="BasePrice"
                                value={formik.values.BasePrice}
                                error={(formik.errors.BasePrice && formik.touched.BasePrice)
                                    ? formik.errors.BasePrice
                                    : 'false'
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("BasePrice", parseInt(e.target.value))
                                }
                            />



                            <StyledInput
                                lable="کمترین تعداد مخاطب مجاز"
                                focus={true}
                                placeholder="50"
                                type="number"
                                name="MinRecipientsCount"
                                value={formik.values.MinRecipientsCount}
                                error={(formik.errors.MinRecipientsCount && formik.touched.MinRecipientsCount)
                                    ? formik.errors.MinRecipientsCount
                                    : 'false'
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("MinRecipientsCount", parseInt(e.target.value))
                                }
                            />

                            <StyledInput
                                lable="بیشترین تعداد مخاطب مجاز"
                                focus={true}
                                placeholder="50"
                                type="number"
                                name="MaxRecipientsCount"
                                value={formik.values.MaxRecipientsCount}
                                error={(formik.errors.MaxRecipientsCount && formik.touched.MaxRecipientsCount)
                                    ? formik.errors.MaxRecipientsCount
                                    : 'false'
                                }
                                onChange={(e) =>
                                    formik.setFieldValue("MaxRecipientsCount", parseInt(e.target.value))
                                }
                            />



                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex align-items-center">
                                    <span className="ml-1">وضعیت : </span>
                                    <Badge className="font-weight-normal" variant="primary">
                                        {
                                            (formik.values.IsActive)?'فعال':'غیرفعال'
                                        }
                                    </Badge>
                                </div>
                                <StyledSwitchBox checked={(formik.values.IsActive)} onChange={(e)=>formik.setFieldValue("IsActive", !formik.values.IsActive)} />
                            </div>

                        </div>

                    </FormLayout>

                </StyledForm>

            </div>

        </>
    )

}
export default TowerOperatorConfiguration;
