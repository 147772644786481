import React, {useCallback, useEffect, useState} from 'react';
import {StyledButton} from "../../Component/Kit/Button";
import Swal from "sweetalert2";
import Modal from "./../../Component/Kit/Modal";
import {toast} from 'react-toastify';
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import {HeaderContent, MainContent, PageTitleLayout} from "../../Component/Kit/Layout";
import BackToPage from "../../Component/Kit/back";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import Table from "../../Component/Shared/Table/Table";
import limitationType from "./Services/send-sms-limitation-type";
import EnumFlag from "../../Utility/Services/enum-flag";
import DateConvert from "../../Utility/Services/date-convert";
import {sendSMSType} from "../../Utility/Services/send-sms";
import PendingSMSModal from "./pending-sms-modal";
import {ABTestShowLinkModal} from "./abTest-show-link-modal";
import {ConvertQueryString} from '../../Utility/Services/convert-query-string';
import PublicLineEditModal from "./public-line-edit-modal";

function PendingSMSDetails() {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    const [pendingData, setPendingData] = useState({});
    const [pendingDetail, setPendingDetail] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [abTestModal, setAbTestModal] = useState(false);
    const [modalEvent, setModalEvent] = useState({});
    const [isPeerToPeer, setIsPeerToPeer] = useState(false);
    const [isPublicLine, setIsPublicLine] = useState(false);
    const [modalEditPublicLine, setModalEditPublicLine] = useState(false);
    const tableConfig = {
        header: {
            limitationTypeTitle: "علت محدودیت",
            messageText: "متن پیامک",
            forbiddenWords: "کلمات ممنوعه",

        },
        tooltipTitle: ['messageText']
    };
    const queueConfirm = async () => {
        try {
            let result = await Swal.fire({
                title: ``,
                text: "آیا از تایید ارسال این پیامک مطمئن هستید؟",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "انصراف",
                cancelButtonColor: "#d33",
                confirmButtonText: "بله، مطمئن هستم",
            });
            if (result.isConfirmed) {
                let body = {
                    confirm: true
                }
                await ApiClient.Patch(`${Urls.getSingleQueue(pendingData.id)}?smsType=${pendingData.smsType}&storageType=${pendingData.smsStorage}`, body)
                toast.success('تغییرات  با موفقیت ثبت شد.');
                history.push('/pending-sms/list')
            }
        } catch (error) {
            toast.error(error);
        }
    }
    const queueHandler = (event) => {
        setIsModal(true)
        setModalEvent({
            mode: event,
            smsType: pendingData.smsType,
            smsStorage: pendingData.smsStorage,
            userId: pendingData.userId
        })
    }

    const EditPublicLine = (e) => {
        setModalEditPublicLine(e);
        getData();
    }

    const getData = () => {
        ApiClient.Get(Urls.getSingleQueue(match.params.id), {storageType: match.params.type}).then(res => {
            let data = res.result
            data.sentAt = DateConvert(data.sentAt);
            data.forbiddenWords = data.forbiddenWords && data.forbiddenWords.length > 0  ? data.forbiddenWords.join(" ") : ""
            data.smsTypeTitle = sendSMSType(data.smsType);
            data.limitationTypeTitle = limitationType(EnumFlag([1, 2, 4, 8, 64], data.limitationType));
            setIsPublicLine(EnumFlag([1, 2, 4, 8, 64], data.limitationType).includes(64));
            if (data.smsStorage === 2) {
                setIsPeerToPeer(true);
                // getSmartParametricList();
                /* ApiClient.Get(Urls.smartParametricList(match.params.id), ConvertQueryString(location.search))
                    .then(parametricResponse => {
                        let parametricData = parametricResponse.result;
                        parametricData.items.map(m => {
                            m.limitationTypeTitle = m.limitationType !== null ? limitationType(EnumFlag([1, 2, 4], m.limitationType)) : '----';
                            return m;
                        })
                        setPendingDetail(parametricData);
                    }) */
            }
            setPendingData(data)
        })
    }

    useEffect(() => {
        getData();
    }, [match.params.id, match.params.type])


    useEffect(() => {
        if (isPeerToPeer) {
            ApiClient.Get(Urls.smartParametricList(match.params.id), ConvertQueryString(location.search))
                .then(parametricResponse => {
                    let parametricData = parametricResponse.result;
                    parametricData.items.map(m => {
                        m.limitationTypeTitle = m.limitationType !== null ? limitationType(EnumFlag([1, 2, 4], m.limitationType)) : '----';
                        return m;
                    })
                    setPendingDetail(parametricData);
                })
        }
    }, [location.search, isPeerToPeer])

    return (
        <>
            <HeaderContent className="justify-content-end pending-sms">
                <div className="pending-sms-row d-flex align-items-center justify-content-start w-100">
                    <StyledButton
                        btnStyle="greenBtn mx-1"
                        onClick={() => queueConfirm()}
                    >
                        تایید
                    </StyledButton>
                    <StyledButton
                        btnStyle="redBtn mx-1"
                        onClick={() => queueHandler('reject')}
                    >
                        عدم تایید
                    </StyledButton>
                    {/*<StyledButton*/}
                    {/*    btnStyle="redBtn mx-1"*/}
                    {/*    onClick={() => queueHandler('block')}*/}
                    {/*>*/}
                    {/*    بلاک کردن کاربر*/}
                    {/*</StyledButton>*/}
                    {
                        isPublicLine &&
                        <StyledButton
                            btnStyle="darkGreenBtn mx-1"
                            onClick={() => setModalEditPublicLine(true)}
                        >
                            ویرایش خط ارسالی
                        </StyledButton>
                    }
                    {
                        pendingData.smsType && pendingData.smsType === 12
                            ?
                            (<StyledButton
                                btnStyle="greenBtn mx-1"
                                onClick={() => setAbTestModal(true)}
                            >
                                مشاهده لینک‌ها
                            </StyledButton>)
                            :
                            null
                    }
                </div>
                <BackToPage toPage="/pending-sms/list"/>
            </HeaderContent>
            <MainContent>
                <PageTitleLayout>
                    <h5>جزئیات پیامک</h5>
                </PageTitleLayout>
                <div className="row">
                    <div
                        className={pendingData.smsStorage === 2 ? "col-12 col-lg-4" : "col-12"}>
                        <ul className="params text-right w-100">
                            {pendingData.smsType === 5 ? null : (
                                <li className="params__list">
                                    <div className="params__list__label">
                                        <span>متن پیامک</span>
                                    </div>
                                    <div className="params__list__value">
                                        <span className="params__list__label--white">{pendingData.messageText}</span>
                                    </div>
                                </li>
                            )}
                            <li className="params__list">
                                <div className="params__list__label">
                                    <span>شماره خط</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{pendingData.lineNumber}</span>
                                </div>
                            </li>
                            <li className="params__list">
                                <div className="params__list__label">
                                    <span>تعداد مخاطبین</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{pendingData.recipientCount}</span>
                                </div>
                            </li>
                            {pendingData.forbiddenWords ? (
                                <li className="params__list">
                                    <div className="params__list__label">
                                        <span>کلمه فیلتر</span>
                                    </div>
                                    <div className="params__list__value">
                                        <span className="params__list__label--white">{pendingData.forbiddenWords}</span>
                                    </div>
                                </li>
                            ) : null}
                            <li className="params__list">
                                <div className="params__list__label">
                                    <span>نوع ارسال</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{pendingData.smsTypeTitle}</span>
                                </div>
                            </li>
                            <li className="params__list">
                                <div className="params__list__label">
                                    <span>دلایل محدودیت</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">
                                        {Array.isArray(pendingData.limitationTypeTitle) ? (
                                            pendingData.limitationTypeTitle.map((m, x) =>
                                                pendingData.limitationTypeTitle.length - 1 === x ? m : m + " ، "
                                            )
                                        ) : (
                                            <span
                                                dangerouslySetInnerHTML={{__html: pendingData.limitationTypeTitle}}
                                            />
                                        )}
                                    </span>
                                </div>
                            </li>
                            <li className="params__list">
                                <div className="params__list__label">
                                    <span>نام کاربری</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{pendingData.userName}</span>
                                </div>
                            </li>
                            <li className="params__list">
                                <div className="params__list__label">
                                    <span>زمان ارسال</span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white">{pendingData.sentAt}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {pendingData.smsStorage === 2 ? (
                        <div className="col-12 col-lg-8">
                            <Table
                                data={pendingDetail.items}
                                config={tableConfig}
                                pageConfig={{
                                    pageNumber: pendingDetail.pageNumber,
                                    pageSize: pendingDetail.pageSize,
                                    totalItemsCount: pendingDetail.totalItemsCount,
                                    totalPagesCount: pendingDetail.totalPagesCount,
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            </MainContent>
            <Modal
                headerTitle={modalEvent.mode === 'block' ? 'بلاک کردن کاربر' : 'عدم تایید کاربر'}
                onClose={() => setIsModal(false)}
                visible={isModal}
                onCancel={() => setIsModal(false)}
            >
                <PendingSMSModal data={modalEvent} onCloseModal={(e) => setIsModal(e)}/>
            </Modal>

            {
                pendingData.smsType === 12 &&
                <Modal
                    onClose={() => setAbTestModal(false)}
                    visible={abTestModal}
                    onCancel={() => setAbTestModal(false)}
                    headerTitle="چک کردن لینک‌های ارسالی A/B تست"
                >
                    <ABTestShowLinkModal itemId={pendingData.id} onCloseModal={(e) => setAbTestModal(e)}/>
                </Modal>
            }

            {

                <Modal
                    onClose={() => setModalEditPublicLine(false)}
                    visible={modalEditPublicLine}
                    headerTitle="ویرایش خط ارسالی"
                >
                    <PublicLineEditModal data={{
                        default: pendingData.lineNumber,
                        id: pendingData.id,
                        smsType: pendingData.smsType,
                        smsStorage: pendingData.smsStorage,

                    }} onCloseModal={(e) => EditPublicLine(e)}/>
                </Modal>
            }

        </>
    )
}

export default PendingSMSDetails;
