import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom';
import AppContext from "../../Context/AppContext";


export const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const hasToken = useContext(AppContext).hasToken;
    
    return (
        <>
            <Route {...rest} render={props => (
                restricted && hasToken
                    ?
                    <Redirect to="/dashboard" />
                    :
                    <Component {...props} />


            )} />
        </>
    )
}
