import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { pendingDetails } from "../../../Actions/pending-line";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import { PageTitleLayout, MainContent } from "../../../Component/Kit/Layout";
import DateConvert from "../../../Utility/Services/date-convert";
import GetProvider from "../../../Utility/Services/provider";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function PendingLines({ history, dispatch }) {
  const [lineData, setlineData] = useState([]);
  const tableConfig = {
    header: {
      userName: "نام کاربری",
      number: "شماره خریداری شده",
      type: "نوع خط",
      lineLengthName: "طول خط",
      providerName: "ارائه دهنده خدمات",
      createdAt: "تاریخ دریافت خط",
    },
    actions: {
      view: {
        icon: "fa fa-eye",
      },
      delete: {
        icon: "fa fa-trash",
      },
    },
  };
  const tableActions = (items) => {
    switch (items.actionName) {
      case "view":
        dataHandler(items.data);
        break;
      case "delete":
        lineDelete(items.data);
        break;
      default:
        break;
    }
  };
  const dataHandler = (data) => {
    // let lineDetail = lineData.find((item) => item.id === id);
    dispatch(pendingDetails(data));
    history.push(`/line/user-lines/pending/${data.id}`);
  };

  const lineDelete = (data) => {
    Swal.fire({
      title: "آیا مطمئن هستید؟",
      text: " ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonText: "انصراف",
      cancelButtonColor: "#d33",
      confirmButtonText: "بله، مطمئن هستم",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiClient.Delete(Urls.removePendingLines(data.id)).then((res) => {
          getData();
          toast.success("تغییرات  با موفقیت ثبت شد.");
        });
      }
    });
  };

  const getData = () => {
    ApiClient.Get(Urls.getPendingLines()).then((res) => {
      let data = res.result;
      data.map((m, i) => {
        m.createdAt = DateConvert(m.createdAt);
        m.providerName = GetProvider(m.providerId);
        m.type = m.number ? "سفارشی" : "غیرسفارشی";
        m.lineLengthName = `${m.lineLength} رقمی`;
        return m;
      });
      setlineData(data);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <MainContent>
        <PageTitleLayout>
          <h5>خطوط منتظر تایید</h5>
          <span>لیست خطوط منتظر تایید کاربران در این بخش قابل مشاهده است.</span>
        </PageTitleLayout>
        <Table
          data={lineData}
          config={tableConfig}
          actionDispatched={tableActions}
        />
      </MainContent>
    </>
  );
}

export default connect()(PendingLines);
