import React, { useState, useEffect } from "react";
import Table from "../../../Component/Shared/Table/Table";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {PageTitleLayout, MainContent, HeaderContent} from "../../../Component/Kit/Layout";
import EnumFlag from "../../../Utility/Services/enum-flag";
import GetSystemLine from "../Services/system-line-type";
import GetProvider from "../../../Utility/Services/provider";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {StyledButton} from "../../../Component/Kit/Button";
import Modal from "../../../Component/Kit/Modal";
import * as PropTypes from "prop-types";
import SystemModal from "./SystemModal";

SystemModal.propTypes = {
  dataChange: PropTypes.func,
  onCloseModal: PropTypes.func
};

function SystemLines() {
  const [lineData, setlineData] = useState([]);
  const [modal, showModal] = useState(false);
  const [modalData, SetModalData] = useState({});
  const [lineOption, setLineOption] = useState([]);

  const tableConfig = {
    header: {
      number: "شماره خط",
      type: "بخش مورد استفاده خط",
      providerText: "ارائه دهنده خدمات",
      isActiveText: "وضعیت خط",
    },

    actions: {
      delete: {
        icon: "fa fa-trash",
        condition: (data) => !data.isActive
      },
      edit: {
        icon: "fa fa-edit",
      }
    }
  };
  useEffect(() => {
    getSystemLine()
  }, []);

  const getSystemLine = () => {
    ApiClient.Get(Urls.getAllSystemLine()).then((res) => {
      let data = res.result;
      data.map((m) => {
        m.systemLines = GetSystemLine(EnumFlag([1, 2, 4, 8, 16, 32], m.type));
        m.type = GetSystemLine(EnumFlag([1, 2, 4, 8, 16, 32], m.type) , 'name');
        m.providerText = GetProvider(m.providerId);
        m.isActiveText = m.isActive ? "فعال" : "غیر فعال";
        return m
      });
      let options = [{name: 'انتخاب کنید', value : null}]

      data.forEach((el)=> {
        if(el?.systemLines?.length !== 0) {
          options.push({name: el.number, value : el.number , systemLines: el.systemLines})
        }
      })

      setLineOption(options);
      setlineData(data);
    });

  }

  const createNewLine = () => {
      SetModalData(null)
      showModal(true)
  }

  const tableActions = (item) => {
    if (item.actionName === 'edit') {
      let data = item.data;
      SetModalData(data);
      showModal(true)
    }else if (item.actionName === 'delete'){
      Swal.fire({
        title: "آیا مطمئن هستید؟",
        text: " ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonText: "انصراف",
        cancelButtonColor: "#d33",
        confirmButtonText: "بله، مطمئن هستم",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiClient.Delete(Urls.removeSystemLine(item.data.id))
              .then(() => {
                getSystemLine()
                toast.success('تغییرات  با موفقیت ثبت شد.');
              })
        }
      });
    }
  };
  return (
    <>
      <HeaderContent>
        <StyledButton btnStyle="greenBtn" onClick={() => createNewLine()}>
          ایجاد خط جدید
        </StyledButton>
      </HeaderContent>
      <MainContent>
        <PageTitleLayout>
          <h5>خطوط سیستمی</h5>
          <span>
            برای تغییر اطلاعات لطفا با واحد فنی در ارتباط باشید.
          </span>
        </PageTitleLayout>
        <Table data={lineData} config={tableConfig}  actionDispatched={tableActions}></Table>
      </MainContent>

      <Modal
          onClose={() => showModal(false)}
          visible={modal}
          onCancel={() => showModal(false)}
          headerTitle={modalData ? 'ویرایش خط' : 'ایجاد خط جدید'}
      >
        <SystemModal data={modalData} fromLineOptions={lineOption}  onCloseModal={(e) => showModal(e)} onSubmit={() => {getSystemLine()}} />
      </Modal>
    </>
  );
}
export default SystemLines;
