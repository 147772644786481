import React, { useEffect, useState } from "react";
import { Switch, Route, NavLink, useLocation } from "react-router-dom";
import UserTicket from "./UserTicket";
import UserProfile from "./UserProfile";
import UserLines from "./UserLines";
import UserBlock from "./UserBlock";
import UserComments from "./UserComments";
import UserFastSendTemplates from "./UserFastSendTemplates";
import UserCustomNotifications from "./UserCustomNotifications";
import UserFinancial from "./UserFinancial";
import { HeaderContent, MainContent } from "../../Component/Kit/Layout";
import BackToPage from "../../Component/Kit/back";
import UserRestriction from "./UserRestriction";
import UserSendMessage from "./UserSendMessage";
import UserIncommingCalls from "./UserIncommingCalls";
import { UserPlan } from "./UserPlan";
import WebService from "./WebService";

function UserInfo({ match }) {
    let location = useLocation();
    const [prev, setPrev] = useState('')
    useEffect(() => {
        setPrev(location.state && location.state.from ? `/users${location.state.from}` : '/users');
        // history.push(`${match.url}/profile`)
    }, [location.state])
    return (
        <>
            <HeaderContent className="justify-content-end">
                <BackToPage/>
            </HeaderContent>
            <MainContent>
                <ul className="user-tab-menu">
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/profile`}>
                            اطلاعات کاربر
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/ticket`}>
                            تیکت
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/lines`}>
                            خطوط
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/financial`}>
                            امورمالی
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/block`}>
                            مسدود کردن
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/comments`}>
                            نظرات
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="is-active"
                            to={`${match.url}/notifications`}
                        >
                            اعلان‌ها
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="is-active"
                            to={`${match.url}/fast-send-template`}
                        >
                            قالب‌های ارسال سریع
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="is-active"
                            to={`${match.url}/user-send-message`}
                        >
                            ارسال پیامک
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="is-active"
                            to={`${match.url}/user-restriction`}
                        >
                            محدودیت ارسال
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="is-active"
                            to={`${match.url}/incomming-calls`}
                        >
                            تماس‌ها
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="is-active"
                            to={`${match.url}/plan`}
                        >
                            پلن‌ها
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="is-active"
                            to={`${match.url}/web-service`}
                        >
                            خطای های وب سرویس
                        </NavLink>
                    </li>
                </ul>
                <div className="p-3">
                    <Switch>
                        <Route exact path={`${match.path}/profile`}>
                            <UserProfile />
                        </Route>
                        <Route path={`${match.path}/ticket`}>
                            <UserTicket />
                        </Route>
                        <Route path={`${match.path}/lines`}>
                            <UserLines />
                        </Route>
                        <Route exact path={`${match.path}/financial`}>
                            <UserFinancial />
                        </Route>
                        <Route path={`${match.path}/block`}>
                            <UserBlock />
                        </Route>
                        <Route path={`${match.path}/comments`}>
                            <UserComments />
                        </Route>
                        <Route path={`${match.path}/notifications`}>
                            <UserCustomNotifications />
                        </Route>
                        <Route path={`${match.path}/fast-send-template`}>
                            <UserFastSendTemplates />
                        </Route>
                        <Route exact path={`${match.path}/user-send-message`}>
                            <UserSendMessage />
                        </Route>
                        <Route exact path={`${match.path}/user-restriction`}>
                            <UserRestriction />
                        </Route>
                        <Route exact path={`${match.path}/incomming-calls`}>
                            <UserIncommingCalls />
                        </Route>
                        <Route exact path={`${match.path}/plan`}>
                            <UserPlan />
                        </Route>
                        <Route exact path={`${match.path}/web-service`}>
                            <WebService/>
                        </Route>
                    </Switch>
                </div>
            </MainContent>
        </>
    );
}

export default UserInfo;
