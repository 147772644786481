import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MainContent, PageTitleLayout, WrapperLayout } from '../../Component/Kit/Layout';
import Modal from '../../Component/Kit/Modal';
import { Loader } from '../../Component/Shared/Loader';
import Table from '../../Component/Shared/Table/Table';
import ApiClient from '../../Requester/ApiClient';
import Urls from '../../Requester/Urls';
import { ConvertQueryString } from '../../Utility/Services/convert-query-string';
import DateConvert from '../../Utility/Services/date-convert';
import UseFeedbackDetailModal from './UseFeedbackDetailModal';

function UserFeedback() {
    const [showUserFeedBackModal, setShowUserFeedBackModal] = useState(false);
    const [userFeedBackList, setUserFeedBackList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalData, setModalData] = useState({});
    const location = useLocation();

    const tableConfig = {
        header: {
            userName: "نام کاربری",
            userId: "شناسه کاربری",
            shortContent: 'متن بازخورد',
            createdAt: "تاریخ بازخورد",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
        },
        mode: ["search"],
    };

    function tableActions(items) {
        switch (items.actionName) {
            case 'view':
                setModalData(items.data);
                setShowUserFeedBackModal(true);
                break;
            default:
                break;
        }
    }

    const getUserFeedbackList = useCallback(() => {
        ApiClient.Get(Urls.GetFeedback(), ConvertQueryString(location.search))
            .then(res => {
                const dataList = res.result;
                dataList.items.map(item => {
                    item.shortContent = item.content.length > 90
                        ? `${item.content.substring(0, 90)} ...`
                        : item.content;
                    item.createdAt = DateConvert(item.createdAt);
                    return item
                })
                setUserFeedBackList(res.result);
                setLoading(false);
            })
    }, [location.search])

    useEffect(() => {
        getUserFeedbackList()
    }, [getUserFeedbackList])

    return (
        <WrapperLayout>
            <MainContent>
                <div className="col-12 col-lg-12">
                    <PageTitleLayout>
                        <h5>لیست بازخورد کاربران </h5>
                        <span>در این بخش لیست بازخوردهای ثبت شده کاربران در پنل کاربری قابل مشاهده است.</span>
                    </PageTitleLayout>
                    <div>
                        {loading === true
                            ?
                            <Loader />
                            :
                            <Table
                                data={userFeedBackList.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: userFeedBackList.pageNumber,
                                    pageSize: userFeedBackList.pageSize,
                                    totalItemsCount: userFeedBackList.totalItemsCount,
                                    totalPagesCount: userFeedBackList.totalPagesCount,
                                }}
                            />
                        }
                    </div>
                </div>
            </MainContent>
            <Modal
                headerTitle="مشاهده جزئیات بازخورد"
                visible={showUserFeedBackModal}
                onClose={() => setShowUserFeedBackModal(false)}
                onCancel={() => setShowUserFeedBackModal(false)}
            >
                <UseFeedbackDetailModal
                    data={modalData}
                    onCloseModal={(e) => setShowUserFeedBackModal(e)} />
            </Modal>
        </WrapperLayout>
    )
}

export default UserFeedback