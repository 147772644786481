import SystemLineType from "../Models/SystemLineType.model";
function GetSystemLine(value , key) {
  let result = [];
  SystemLineType.forEach((item) => {
    value.forEach((element) => {
      if (item.value === element) result.push(key ? item[key] : item);
    });
  });
  return result;
}

export default GetSystemLine;
