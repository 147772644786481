import React, { useEffect, useState } from 'react'
import { StyledUploadAvatar, UploadAvatarConatiner } from '../../../Component/Kit/AvatarUploder';
import { toast } from 'react-toastify';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { bootstrapper } from '../../../Actions/bootstrapper';
import { useDispatch } from 'react-redux';

export const UploadAvatarContainer = (props) => {
    const { personalProfile } = props;
    const [imgPreview, setImgPreview] = useState(null);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const fileToUpload = event.target.files[0];

        if (fileToUpload.size > 1000000) {
            toast.warning("سایز عکس بیشتر از مقدار مجاز است");
            return
        }

        let formData = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);

        ApiClient.Patch(Urls.ModifyAdminProfileAvatar(), formData)
            .then(res => {
                setImgPreview(res.result);
                let data = {
                    ...personalProfile,
                    "profileImageURL": res.result
                }
                dispatch(bootstrapper(data));
                toast.success('آپلود با موفقیت ارسال شد.');
            })
    };

    useEffect(() => {
        if (personalProfile && personalProfile.profileImageURL !== "") {
            setImgPreview(personalProfile.profileImageURL)
        }
    }, [personalProfile])

    return (
        <div className="row mt-3 mx-0">
            <div className="col-12 col-lg-12">
                <div className="descripton form-group">
                    <p>در این بخش می‌توانید آواتار خود را تغییر دهید. </p>
                </div>
            </div>

            {
                personalProfile && (
                    <div className="col-12 col-lg-12">

                        <div className="row">
                            <div className="col-md-9">
                                <div className="profile-picture_warning">
                                    <div className="triangle">
                                        <i className="fa fa-exclamation-triangle"></i>
                                    </div>
                                    <div className="message">
                                        <p>حداکثر حجم مجاز تصویر 1 مگابایت میباشد.</p>
                                        <p>فرمت های قابل پشتیبانی : JPG, PNG, JPEG</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 text-left">
                                <>
                                    <UploadAvatarConatiner>
                                        <StyledUploadAvatar
                                            HasUrl={imgPreview}
                                            // deleteHandler={deleteHandler}
                                            onChange={(event) => handleChange(event)}
                                        />
                                    </UploadAvatarConatiner>

                                </>
                            </div>
                        </div>

                    </div>
                )
            }
        </div>
    )
}
