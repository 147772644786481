import React from "react";

function LogoMinimize(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 200 200"
            {...props}
        >
            <rect
                width={200}
                height={200}
                rx={50}
                ry={50}
                style={{
                    fill: "#ffbe00",
                }}
            />
            <path d="M175 158.84a78.178 78.178 0 0 0-20.19-7.26 79.539 79.539 0 0 0-29.24-.65c-4.97.82-9.78 2.1-14.39 3.8-1.06.39-2.12.81-3.16 1.25-5.59 2.35-11.41 4.16-17.42 4.95a67.45 67.45 0 0 1-21.39-.6 64.06 64.06 0 0 1-8.1-2.07c-.86-.27-1.71-.57-2.56-.89a63.83 63.83 0 0 1-4.38-1.79c-.98-.53-1.95-1.09-2.9-1.68a66.33 66.33 0 0 1-4.58-3.06 66.393 66.393 0 0 1-10.64-9.66 66.578 66.578 0 0 1-11.04-17.01c10.31 5.6 22.13 8.77 34.69 8.77 2.62 0 5.2-.14 7.74-.41a70.171 70.171 0 0 0 8.7-1.44l.24-.06.12-.03c.65-.15 1.31-.32 1.96-.49h.05l9.86-2.5 16.05-4.05s.01 0 .02-.01h.02l.05-.02 1.32-.33a69.178 69.178 0 0 1 16.45-1.97c2.54 0 5.05.14 7.51.41 4 .7 7.87 1.75 11.59 3.13.75.27 1.5.56 2.24.87.76.39 1.51.79 2.25 1.22.09.05.18.09.27.14 12.61 7.18 22.75 18.18 28.84 31.45Z" />
            <path d="M159.34 131.63a66.747 66.747 0 0 0-15.43-9.69 67.27 67.27 0 0 0-13.25-4.37c-3.64-.8-7.28-1.28-10.89-1.46-4.27-.22-8.5-.03-12.63.54-1.12.16-2.24.34-3.35.56-1.8.35-3.6.72-5.43.87-5.51.45-11.17.11-16.85-1.13-3.56-.78-6.98-1.88-10.21-3.26-2.2-.93-4.32-2-6.35-3.18-.67-.38-1.32-.78-1.96-1.2-1.13-.72-2.23-1.48-3.31-2.28-.72-.62-1.42-1.26-2.1-1.92-1.13-1.07-2.21-2.2-3.24-3.37a57.33 57.33 0 0 1-7.08-9.94c-2.81-5-4.88-10.43-6.08-16.12 7.54 6.51 16.77 11.29 27.19 13.56 2.17.47 4.34.83 6.5 1.07.81.09 1.62.17 2.43.22 1.69.12 3.38.17 5.05.15h.31c.57 0 1.14-.03 1.71-.05h.05l8.64-.27 14.05-.45h.09l1.16-.03c4.61-.12 9.31.32 14.01 1.35 2.11.46 4.16 1.03 6.16 1.7 3.19 1.3 6.21 2.88 9.05 4.7.58.36 1.14.74 1.7 1.13.56.46 1.11.93 1.64 1.42.07.06.13.11.19.17a59.224 59.224 0 0 1 18.23 31.32Z" />
            <path d="M149.71 104.26a56.855 56.855 0 0 0-11.34-10.58A57.043 57.043 0 0 0 128 87.9a57.32 57.32 0 0 0-19.52-4.55c-.96-.05-1.93-.07-2.89-.07-1.56 0-3.13.02-4.68-.15-4.68-.51-9.36-1.71-13.9-3.66A48.56 48.56 0 0 1 79 75.11a45.699 45.699 0 0 1-4.79-3.68c-.5-.43-.98-.87-1.45-1.32-.83-.78-1.63-1.6-2.4-2.44-.5-.63-.99-1.28-1.45-1.94-.77-1.08-1.49-2.19-2.17-3.34a48.565 48.565 0 0 1-4.32-9.45c-1.55-4.63-2.4-9.5-2.49-14.45A52.476 52.476 0 0 0 80.48 54.2c1.74.75 3.5 1.39 5.26 1.94.66.21 1.33.4 1.99.58 1.39.37 2.8.68 4.2.94l.18.03h.09c.48.1.96.18 1.44.24h.04l7.26 1.17 11.82 1.88h.08l.97.16c3.87.64 7.73 1.76 11.49 3.38 1.69.72 3.31 1.53 4.88 2.41 2.46 1.6 4.73 3.4 6.81 5.38.42.4.84.8 1.24 1.21.39.47.77.96 1.15 1.45l.14.17c6.33 8.36 9.94 18.56 10.2 29.11Z" />
        </svg>
    );
}

export default LogoMinimize;
