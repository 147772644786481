export const CreditPackageItemReducer = (state = {}, action) => {
    switch (action.type) {

        case 'CREDIT_PACKAGE_ITEM':
            return { ...state, ...action.data }

        default:
            return state
    }
}

export default CreditPackageItemReducer;