import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { StyledButton } from '../../../Component/Kit/Button'
import { HeaderContent, MainContent, PageTitleLayout, WrapperLayout } from '../../../Component/Kit/Layout'
import { Loader } from '../../../Component/Shared/Loader'
import Table from '../../../Component/Shared/Table/Table'
import ApiClient from '../../../Requester/ApiClient'
import Urls from '../../../Requester/Urls'
import { ConvertQueryString } from '../../../Utility/Services/convert-query-string'
import PlanPositionService from '../../../Utility/Services/PlanPositionService'
import { SeprateNumberWithComma } from '../../../Utility/Services/SeprateNumberWithComma'

export const PlansList = ({ history }) => {
    const location = useLocation();
    const [planList, setPlanList] = useState({});
    const [loading, setLoading] = useState(true);

    const tableConfig = {
        header: {
            name: 'نام پلن',
            positionTitle: 'جایگاه',
            isActiveStatus: 'وضعیت',
            ActivePlanUser: 'تعداد کاربر فعال',
            buyerCount: 'تعداد خریداری شده',
            isDeletedStatus: 'حذف',
        },
        actions: {
            view: {
                icon: 'fa fa-eye',
                condition: (data) => {
                    return (!!Number(data.ActivePlanUser));
                },
            },
            edit: {
                icon: "fa fa-edit",
            },
            // delete: {
            //     icon: 'fa fa-trash',
            //     condition: (data) => {
            //         return !!data.isDeletable;
            //     },
            // },
        },
        mode: ['search'],
        filters: [],
    };

    function tableActions(items) {
        switch (items.actionName) {
            case 'view':
                history.push(`plan/${items.data.id}/users?name=${items.data.name}`);
                break;
            case 'edit':
                history.push(`plan/${items.data.id}/detail`);
                break;
            default:
                break;
        }
    }

    function getPlans() {
        ApiClient.Get(Urls.Plan(), ConvertQueryString(loading.search))
            .then(res => {
                let dataItems = res.result;
                dataItems.items.map((item) => {
                    item.isDeletedStatus = item.isDeleted ? 'آرشیو شده' : 'موجود';
                    item.isActiveStatus = (!item.isDeleted && item.isActive) ? 'فعال' : 'غیرفعال';
                    item.positionTitle = PlanPositionService(item.position);
                    item.ActivePlanUser = SeprateNumberWithComma(item.buyerCount + item.manualAssignmentCount);
                    item.buyerCount = SeprateNumberWithComma(item.buyerCount);
                    return item
                })
                setPlanList(dataItems);
                setLoading(false);
            })
    }
    useEffect(() => {
        getPlans()
    }, [location.search]);

    return (
        <WrapperLayout>
            <div className='c-wrapper'>
                <HeaderContent className="justify-content-end">
                    <div className="d-flex align-items-center justify-content-start w-100">
                        <StyledButton
                            btnStyle="greenBtn mx-1"
                            onClick={() => history.push('/plan/add')}
                        >
                            ایجاد پلن
                        </StyledButton>
                        <StyledButton
                            btnStyle="greenBtn mx-1"
                            onClick={() => history.push('/plan/free')}
                        >
                            ویرایش پلن رایگان
                        </StyledButton>
                    </div>
                </HeaderContent>

                <MainContent>
                    <PageTitleLayout>
                        <h5>لیست پلن‌ ها</h5>
                        <span>در این بخش لیست همه پلن‌ها قابل مشاهده است.</span>
                    </PageTitleLayout>
                    <div>
                        {loading === true ? (
                            <Loader />
                        ) : (
                            <Table
                                data={planList.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: planList.pageNumber,
                                    pageSize: planList.pageSize,
                                    totalItemsCount: planList.totalItemsCount,
                                    totalPagesCount: planList.totalPagesCount,
                                }}
                            />
                        )}
                    </div>
                </MainContent>
            </div>
        </WrapperLayout>
    )
}
