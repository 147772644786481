
import React from "react";

function Connected(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#000"
            version="1.1"
            viewBox="0 0 494.45 494.45"
            xmlSpace="preserve"
        >
            <path fill={props.color} d="M395.225 277.325c-6.8 0-13.5-2.6-18.7-7.8-71.4-71.3-187.4-71.3-258.8 0-10.3 10.3-27.1 10.3-37.4 0s-10.3-27.1 0-37.4c92-92 241.6-92 333.6 0 10.3 10.3 10.3 27.1 0 37.4-5.2 5.2-12 7.8-18.7 7.8z"></path>
            <path  fill={props.color} d="M323.625 348.825c-6.8 0-13.5-2.6-18.7-7.8-15.4-15.4-36-23.9-57.8-23.9s-42.4 8.5-57.8 23.9c-10.3 10.3-27.1 10.3-37.4 0-10.3-10.3-10.3-27.1 0-37.4 25.4-25.4 59.2-39.4 95.2-39.4s69.8 14 95.2 39.5c10.3 10.3 10.3 27.1 0 37.4-5.1 5.1-11.9 7.7-18.7 7.7z"></path>
            <circle  fill={props.color} cx="247.125" cy="398.925" r="35.3"></circle>
            <path  fill={props.color} d="M467.925 204.625c-6.8 0-13.5-2.6-18.7-7.8-111.5-111.4-292.7-111.4-404.1 0-10.3 10.3-27.1 10.3-37.4 0s-10.3-27.1 0-37.4c64-64 149-99.2 239.5-99.2s175.5 35.2 239.5 99.2c10.3 10.3 10.3 27.1 0 37.4-5.3 5.2-12.1 7.8-18.8 7.8z"></path>
        </svg>
    );
}

export default Connected;
