import React, { useState } from "react";

import { LoginLayout, AuthForm } from "./styles";

import LoginFirstStepFormContainer from "./LoginFirstStepForm/index";
import LoginSecondStepFormContainer from "./LoginSecondStepForm/index";
import Logo from "../../Component/Icon/Logo";

const Login = () => {

    const [step, setStep] = useState(0);
    const [account, setAccount] = useState({});

    return (
        <LoginLayout className="auth">
            <AuthForm>
                <Logo />
                <LoginFirstStepFormContainer orderStep={0} currentStep={step} setStep={setStep} setAccount={setAccount} />
                <LoginSecondStepFormContainer orderStep={1} currentStep={step} setStep={setStep} account={account} />
            </AuthForm>
        </LoginLayout>
    );
};

export default Login;
