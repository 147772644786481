import React, {useCallback, useEffect, useState} from 'react'
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from "../../../Component/Kit/Layout";
import {ProviderStateLayout} from "./styles";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {SystemLineProviderEnum} from "../../../Utility/Model/provider-enum.model";
import {toast} from "react-toastify";
import moment from "moment-jalaali";
import {StyledButton} from "../../../Component/Kit/Button";
import {Line} from 'react-chartjs-2';
import {CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement, Title} from 'chart.js';
import Disconnected from "../../../Component/Icon/_Disconnected";
import Connected from "../../../Component/Icon/_connected";
import {groupBy} from "../../../Utility/method/groupBy";

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);


export const ProvidersState = () => {
    const [monitoringData, setMonitoringData] = useState(null)
    const [loadingFlag, setLoadingFlag] = useState(false);
    const options = {
        responsive: true,
        maintainAspectRatio: true,

        plugins: {
            legend: {
                position: 'top',
                display: false
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                },
            },
            y: {
                title: {
                    display: false,
                },
                suggestedMin: -1,
                suggestedMax: 1,
                ticks: {
                    // forces step size to be 1 units
                    stepSize: 1
                }
            }
        }
    };


    const checkProvideState = (state) => {
        switch (state) {
            case 1 :
                return "healthy"
            case 2 :
                return 'unknown'
            case 3 :
                return 'unhealthy'
            default:
                return "unhealthy"
        }
    }
    const lastMessageStates = (state) => {
        switch (state) {
            case 1 :
                return "healthy fa-check"
            case 2 :
                return 'unknown fa-clock'
            case 3 :
                return 'unhealthy fa-xmark'
            default:
                return "unhealthy fa-xmark"
        }
    }
    const sendSms = (providerId, operatorId) => {
        ApiClient.Post(Urls.sendSms(), {providerId, operatorId}).then((response) => {
            callApi();
            toast.success('ارسال پیامک با موفقیت انجام شد')
        })
    }

    const updateHandler = () => {
        callApi();
        setLoadingFlag(true);
        setTimeout(() => {
            setLoadingFlag(false);
        }, 15000);
    };

    const callApi = useCallback(() => {
        ApiClient.Get(Urls.GetMonitoringData(), {}).then((response) => {
            response.result.providerServiceStatus.map((a, index) => {
                a.name = SystemLineProviderEnum.find(d => d.value === a.providerId).name;
                a.prefix = SystemLineProviderEnum.find(d => d.value === a.providerId).prefix;
                a.stateHistory =
                    {
                        labels: response.result.providerStateHistories
                            .filter(f => f.providerId === a.providerId && f.operatorId === a.operatorId).length > 0 ?
                            response.result.providerStateHistories
                                .filter(f => f.providerId === a.providerId && f.operatorId === a.operatorId).map(a => `${a.createdAt.toString()?.slice(8, 10)}:${a.createdAt.toString()?.slice(10, 12)}`)
                            : [`${new Date().getHours() + 1}:${new Date().getMinutes()}`, `${new Date().getHours()}:${new Date().getMinutes()}`],
                        datasets: [
                            {
                                label: a.operatorId === 1 ? 'همراه اول' : 'ایرانسل',
                                data: response.result.providerStateHistories
                                    .filter(f => f.providerId === a.providerId && f.operatorId === a.operatorId).length > 0 ? response.result.providerStateHistories
                                        .filter(f => f.providerId === a.providerId && f.operatorId === a.operatorId).map(a => a.providerState === 2 ? 0 : a.providerState === 3 ? -1 : 1) :
                                    response.result.messagesState.find(e => e.providerId === a.providerId && e.operatorId === a.operatorId).states.some(a => a === 1) ?
                                        [1, 1] : [-1, -1],
                                borderColor: (context) => {
                                    const chart = context.chart;
                                    const {ctx, chartArea} = chart;

                                    if (!chartArea) {
                                        // This case happens on initial chart load
                                        return;
                                    }
                                    return getGradient(ctx, chartArea);
                                },
                                backgroundColor: a.operatorId === 1 ? ['rgba(53, 162, 235, 0.5)'] : ['rgba(255,193,99,0.5)'],
                                stepped: true,
                            },
                        ],

                    }
                a.lastMessages = response.result.messagesState.find(e => e.providerId === a.providerId && e.operatorId === a.operatorId).states
            })

            const data = Array.from(groupBy(response.result.providerServiceStatus, item => item.providerId).values())
            setMonitoringData(data)
        })
    }, [])


    let width, height, gradient;

    function getGradient(ctx, chartArea) {
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!gradient || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, '#F44336');
            gradient.addColorStop(0.3, '#efb0ae');
            gradient.addColorStop(0.5, '#9E9E9E');
            // gradient.addColorStop(0.7, '#3ecabb');
            gradient.addColorStop(0.7, '#76cabe');
            gradient.addColorStop(1, '#009688');
        }

        return gradient;
    }

    useEffect(
        () => {
            callApi();
            const interval = setInterval(() => {
                callApi();
            }, 30000);
            return () => clearInterval(interval);
        }, [callApi]);

    return (
        <WrapperLayout>

            <HeaderContent className='d-flex flex-row-reverse justify-content-between'>
                <div className='d-flex align-items-center'>
                    <div className='mr-3 rtl'>
                        <span className='fo-12 mr-2'>آخرین بروزرسانی: </span>
                        <span>{moment().format('HH:mm:ss')}</span>
                    </div>

                    <StyledButton
                        btnStyle='greenBtn'
                        onClick={() => updateHandler()}
                        disabled={loadingFlag}
                    >
                        بروزرسانی
                    </StyledButton>
                </div>
            </HeaderContent>

            <MainContent>
                <PageTitleLayout>
                    <h5>وضعیت ارائه‌دهندگان</h5>
                    <span>در این بخش می‌توانید وضعیت ارائه‌دهندگان مشاهده نمایید.</span>
                </PageTitleLayout>
                <ProviderStateLayout>
                    <div className="row">
                        {monitoringData && monitoringData.map((provider, index) =>
                            (
                                <div className="col-lg-3 col-md-6 col-12 mb-3" key={provider[0].id}>
                                    <div className="main-box">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h3>{provider[0].name}</h3>
                                            <h3>{provider[0].prefix}</h3>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="inner-container">
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mb-2"
                                                        style={{minHeight: 35}}>
                                                        <span className="" style={{fontSize: '0.9rem'}}>همراه اول</span>
                                                        <span className=""
                                                              style={{fontSize: '0.9rem'}}>{provider[0].isAvailable ?
                                                            <Connected color="#707070"/> :
                                                            <Disconnected color="#707070"/>}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                    <span
                                                        className={checkProvideState(provider[0].isAvailable ? provider[0].state : 3) + " custom-budge w-100 font-weight-bold d-flex align-items-center justify-content-between"}>
                                                       <span className="d-flex">
                                                           <span
                                                               className="font-weight-bold ml-1">{provider[0].delay}</span>
                                                           <span>ثانیه تاخیر در ارسال</span>
                                                       </span>
                                                        <button onClick={() => sendSms(provider[0].providerId, 1)}
                                                                className={checkProvideState(provider[0].isAvailable ? provider[0].state : 3) + " resend-btn"}>ارسال مجدد</button>
                                                    </span>
                                                    </div>
                                                    <div
                                                        className="mt-4 mb-2 d-flex justify-content-around last-delivered align-items-center">
                                                        {provider[0].lastMessages.map((a, i) => (
                                                            <i key={i}
                                                               className={lastMessageStates(a) + " fa mr-2"}></i>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <Line data={provider[0].stateHistory} options={options}/>
                                            </div>
                                            <div className="col-12">
                                                <div className="separator-dashed my-4"
                                                     style={{borderBottom: '3px dashed #777'}}></div>
                                            </div>
                                            <div className="col-12">
                                                <div className="inner-container">
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mb-2"
                                                        style={{minHeight: 35}}>
                                                        <span className="" style={{fontSize: '0.9rem'}}>ایرانسل</span>
                                                        <span className=""
                                                              style={{fontSize: '0.9rem'}}>{provider[1].isAvailable ?
                                                            <Connected color="#707070"/> :
                                                            <Disconnected color="#707070"/>}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                    <span
                                                        className={checkProvideState(provider[1].isAvailable ? provider[1].state : 3) + " custom-budge w-100 font-weight-bold d-flex align-items-center justify-content-between"}>
                                                           <span className="d-flex">
                                                           <span
                                                               className="font-weight-bold ml-1">{provider[1].delay}</span>
                                                           <span>ثانیه تاخیر در ارسال</span>
                                                       </span>
                                                        <button onClick={() => sendSms(provider[1].providerId, 2)}
                                                                className={checkProvideState(provider[1].isAvailable ? provider[1].state : 3) + " resend-btn"}>ارسال مجدد</button>
                                                    </span>
                                                    </div>
                                                    <div
                                                        className="mt-4 mb-2 d-flex justify-content-around last-delivered align-items-center">
                                                        {provider[1].lastMessages.map((a, i) => (
                                                            <i key={i}
                                                               className={lastMessageStates(a) + " fa mr-2"}></i>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <Line data={provider[1].stateHistory} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}


                    </div>
                </ProviderStateLayout>
            </MainContent>
        </WrapperLayout>
    )
}

export default ProvidersState;
