import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ApiClient from "../../../../Requester/ApiClient";
import {ConvertQueryString} from "../../../../Utility/Services/convert-query-string";
import Urls from "../../../../Requester/Urls";
import {Loader} from "../../../../Component/Shared/Loader";
import {UserInformationItem, UserInformationWrapper} from "../../../Users/UserProfile/UserInformation/style";
import {HeaderContent, MainContent} from "../../../../Component/Kit/Layout";
import {
    ServiceLineFileTypeModel,
    UserServiceLineFileStatusModel, UserServiceLineStatusEnum,
    UserServiceLineStatusModel
} from "../../../../Utility/Model/user-service-line.model";
import GetProvider from "../../../../Utility/Services/provider";
import DateConvert from "../../../../Utility/Services/date-convert";
import BackToPage from "../../../../Component/Kit/back";
import Table from "../../../../Component/Shared/Table/Table";
import {Formik} from "formik";
import {toast} from "react-toastify";
import {StyledForm, StyledInput, StyledRadioBtn} from "../../../../Component/Kit/Form";
import {StyledButton} from "../../../../Component/Kit/Button";
import * as Yup from "yup";
import Swal from "sweetalert2";
import ServiceLineComments from "../Comments";
import {ModifyTicketSettingModal} from "../../../Tickets/TicketDetail/ModifyTicketSettingModal";


function UserServiceLineDetails() {
    const [lineData, setlineData] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [isModal, setIsModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalType, setModalType] = useState(null);
    const {id} = useParams();
    const userServiceLineModalSchema = Yup.object().shape({
        rejectReason: Yup.string()
            .label("rejectReason"),
        rejectByWho: Yup.string()
            .label("rejectByWho")
            .required("این فیلد الزامی است.")
    });

    const userInformationTitle = {
        username: "نام کاربری",
        number: "شماره",
        statusFa: "وضعیت",
        providerId: "ارائه دهنده",
        createdAt: "تاریخ ایجاد",
    }

    const tableConfig = {
        header: {
            typeFa: "نوع فایل",
            statusFa: "وضعیت",
            rejectDescription: "علت عدم تایید",
            modifiedAt: "تاریخ ویرایش کاربر",
        },

        actions: {

            accept: {
                icon: 'fa fa-check',
            },
            decline: {
                icon: 'fa fa-ban',
            },
            download: {
                icon: 'fa fa-download',
                condition: (data) => {
                    return !!data.fileUrl;
                },
            },
        },
    };

    const getServiceLine = useCallback(() => {
        ApiClient.Get(Urls.getDetailUserServiceLine(id), ConvertQueryString(location.search)).then((res) => {
            let data = res.result;
            data.providerId = GetProvider(data.providerId);
            data.statusFa = UserServiceLineStatusModel.find(u => u.value === data.status).name ?? data.status
            data.createdAt = DateConvert(data.createdAt) || '- - -';
            data.modifiedAt = DateConvert(data.modifiedAt) || '- - -';
            data.files.map((m) => {
                m.statusFa = UserServiceLineFileStatusModel.find(u => u.value === m.status).name ?? m.status
                m.modifiedAt = DateConvert(m.modifiedAt) || '- - -';
                m.typeFa = ServiceLineFileTypeModel.find(u => u.value === m.type).name ?? m.type
                return m
            });
            setlineData(data);
            setLoading(false);
        });
    }, [id, location.search]);

    useEffect(() => {
        getServiceLine();
    }, [getServiceLine]);


    const tableActions = (item) => {
        switch (item.actionName) {
            case "download":
                if (item.data.fileUrl) downloadFile(item.data.fileUrl)
                break;
            case "accept":
                setModalType("accept");
                setIsModal(true);
                setModalData(item.data)
                break;
            case "decline":
                setModalType("decline");
                setIsModal(true);
                setModalData(item.data)
                break;
            default:
                break;
        }
    };

    const downloadFile = (url) => {

        const a = document.createElement('a')
        a.href = url;
        a.target = "_blank";

        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const receiveDocuments = () => {
        Swal.fire({
            title: `آیا مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {
                ApiClient.Post(Urls.receiveDocumentsByAdmin(id))
                    .then(() => {
                        getServiceLine();
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                    })
            }
        })
    }

    return (
        <>
            <HeaderContent className="justify-content-end">
                <BackToPage/>
            </HeaderContent>
            <MainContent>

                {loading === true ?
                    <Loader/>
                    :
                    <>
                        {
                            UserServiceLineStatusEnum.AcceptedByProvider === lineData.status &&
                            <div className="col-12 px-0 mb-3 text-left">
                                <StyledButton
                                    width="20%"
                                    btnStyle="greenBtn"
                                    onClick={() => {
                                        receiveDocuments()
                                    }}
                                >
                                    مدارک دریافت شد
                                </StyledButton>
                            </div>
                        }

                        <div className="col-12 wrapper-box">
                            <UserInformationWrapper>
                                {Object.entries(userInformationTitle).map(([key, value]) => (
                                    <UserInformationItem label={value} dataItem={lineData[key]} key={key}/>
                                ))}
                            </UserInformationWrapper>
                        </div>

                        <div className="col-12">
                            <Table data={lineData.files} config={tableConfig} actionDispatched={tableActions}></Table>
                        </div>
                    </>
                }

                <Modal centered show={isModal} onHide={() => {
                    setIsModal(false)
                }}>
                    <Modal.Header>
                        <Modal.Title>{`${modalType === 'accept' ? 'تایید فایل' : 'عدم تایید فایل'}`}</Modal.Title>
                        <i onClick={() => {
                            setIsModal(false)
                        }} className="close-btn fa fa-xmark"/>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <Formik
                                initialValues={{
                                    rejectReason: '',
                                    rejectByWho: null
                                }}
                                validationSchema={userServiceLineModalSchema}
                                onSubmit={(values, actions) => {
                                    switch (modalType) {
                                        case 'accept':
                                            ApiClient.Post(Urls.acceptFileByAdmin(id, modalData.id), JSON.stringify({
                                                IsFromOperator: (values.rejectByWho === 'operator')
                                            }))
                                                .then((res) => {
                                                    getServiceLine();
                                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                                    actions.resetForm();
                                                    setIsModal(false)
                                                })
                                            break;
                                        case 'decline':
                                            ApiClient.Post(Urls.rejectFileByAdmin(id, modalData.id), JSON.stringify({
                                                IsFromOperator: (values.rejectByWho === 'operator'),
                                                rejectDescription: values.rejectReason,
                                            }))
                                                .then((res) => {
                                                    getServiceLine();
                                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                                    actions.resetForm();
                                                    setIsModal(false)
                                                })
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            >
                                {(formik) => (
                                    <StyledForm
                                        className={'p-0'}
                                        onSubmit={formik.handleSubmit}
                                        onBlur={formik.handleBlur}>
                                        {
                                            modalType !== 'accept' &&
                                            <StyledInput
                                                lable="توضیحات"
                                                focus={true}
                                                type="text"
                                                name="rejectReason"
                                                value={formik.values.rejectReason}
                                                error={(formik.errors.rejectReason && formik.touched.rejectReason)
                                                    ? formik.errors.rejectReason
                                                    : 'false'
                                                }
                                                onChange={(e) =>
                                                    formik.setFieldValue("rejectReason", e.target.value)
                                                }
                                            />
                                        }

                                        <div
                                            className="col-12 d-flex justify-content-start align-items-center mb-3 pt-3 px-0">
                                            <div className="d-flex col-12 px-0">
                                                <span
                                                    className="ml-3">{modalType === 'accept' ? 'تایید فایل توسط : ' : 'عدم تایید فایل توسط :'}</span>
                                                <StyledRadioBtn id='admin' name='type'
                                                                label='ادمین'
                                                                className="p-3"
                                                                checked={formik.values.rejectByWho === 'admin'}
                                                                onChange={(e) =>
                                                                    formik.setFieldValue("rejectByWho", 'admin')
                                                                }/>
                                                <StyledRadioBtn id='operator' name='type' label='اپراتور'
                                                                checked={formik.values.rejectByWho === 'operator'}
                                                                className="p-3"
                                                                onChange={(e) =>
                                                                    formik.setFieldValue("rejectByWho", 'operator')
                                                                }/>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3">
                                            <StyledButton
                                                width="35%"
                                                disabled={!formik.isValid || !formik.dirty}
                                                btnStyle="greenBtn"
                                                type="submit"
                                            >
                                                ثبت
                                            </StyledButton>

                                            <StyledButton
                                                width="35%"
                                                btnStyle="grayBtn"
                                                type="reset"
                                                onClick={() => {
                                                    setIsModal(false)
                                                }}
                                            >
                                                بستن
                                            </StyledButton>

                                        </div>
                                    </StyledForm>
                                )}
                            </Formik>
                        </>
                    </Modal.Body>

                </Modal>


                <ServiceLineComments notes={lineData.adminNotes}/>

            </MainContent>
        </>
    );
}

export default UserServiceLineDetails;
