import {
  lineTypes,
  UserLineEarnMethod,
  UserLinePrebuyStatus,
  UserLineStatus,
  WhoisLineOperationStatus
} from "./../Model/lines.model";
function EarnMethod(value) {
  let result = UserLineEarnMethod.find((item) => item.value === value);
  return result.name;
}

function LineStatus(value) {
  let result = UserLineStatus.find((item) => item.value === value);
  return result.name;
}

function LineTypes(value) {
  let result = lineTypes.find((item) => item.value === value);
  return result.name;
}

function LinePrebuyStatus(value) {
  let result = UserLinePrebuyStatus.find((item) => item.value === value);
  return result.name;
}

function WhoisLineOperationService(value) {
  return WhoisLineOperationStatus.find(item => item.value === value).name
}



export { EarnMethod, LineStatus, WhoisLineOperationService, LineTypes, LinePrebuyStatus};
