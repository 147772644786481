import { createStore, compose } from "redux";
import reducers from "./Reducers";

const store = createStore(
    reducers,
    compose(
       // applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
    )
 );

export default store;
