import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {HeaderContent, MainContent, PageTitleLayout, WrapperLayout} from '../../../Component/Kit/Layout';
import {Loader} from '../../../Component/Shared/Loader';
import Table from '../../../Component/Shared/Table/Table';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import {ConvertQueryString} from '../../../Utility/Services/convert-query-string';
import DateConvert from '../../../Utility/Services/date-convert';
import BackToPage from "../../../Component/Kit/back";
import {UserInformationItem, UserInformationWrapper} from "../../Users/UserProfile/UserInformation/style";
import {toast} from "react-toastify";

export const CampaignMembers = () => {
    const {id} = useParams();

    const location = useLocation();
    const history = useHistory();
    const [Campaign, setCampaign] = useState({});
    const [membersList, setMembersList] = useState(null);
    const [loading, setLoading] = useState(true);

    const tableConfig = {
        header: {
            mobile: "موبایل",
            username: "نام کاربری",
            campaignName: "نام کمپین",
            accountType: 'نوع حساب',
            createdAt: "تاریخ ایجاد",
            modifiedAt: 'تاریخ ویرایش',
        },

        mode: ['filter', 'search'],
        filters: ['datePickerFilter'],
    };

    const campaignTitles = {
        name: "نام کمپین",
        code: "کد کمپین",
        startAt: "تاریخ شروع",
        endAt: "تاریخ پایان",
        isActive: "وضعیت کمپین",
        officialPaymentStatus: "نوع پرداخت"
    }

    const convertOfficial = (value) => {
        const officialPaymentType = [
            {
                name: "رسمی",
                value: true
            },
            {
                name: "غیر رسمی",
                value: false
            },
            {
                name: "رسمی - غیر رسمی",
                value: null
            },
        ]

        return officialPaymentType.find(i => i.value === value)?.name
    }

    function tableActions(item) {
        switch (item.actionName) {
            case 'edit':
                history.push(`campaigns/${item.data.id}`);
                break;
            case 'view':
                history.push(`campaigns/${item.data.id}/members`);
                break;
            default:
                break;
        }
    }

    const copyItem = (key, value) => {
        if (["name", "code"].includes(key)) {
            toast.info(` ${value}  کپی شد.`);
            navigator.clipboard.writeText(value)
        }
    }

    const AccountTypeConvert = (guestId, userId) => {
        switch (true) {
            case userId !== null:
                return "کاربر";

            case userId === null && guestId !== null:
                return "مهمان";

            default:
                return "----";
        }
    }


    const getCampaign = useCallback(() => {
        if (id) {
            ApiClient.Get(Urls.advertisingCampaignById(id))
                .then((res) => {
                    const data = res.result;
                    data.isActive = data.isActive ? "فعال" : "غیر فعال"
                    data.startAt = DateConvert(data.startAt);
                    data.endAt = DateConvert(data.endAt);
                    data.officialPaymentStatus = convertOfficial(data.officialPaymentStatus)
                    setCampaign(data);
                })
        }
    }, [id])


    const getMembersList = useCallback(() => {
        if (id) {
            ApiClient.Get(Urls.advertisingCampaignMembersById(id), ConvertQueryString(location.search))
                .then((res) => {
                    let dataItem = res.result;
                    dataItem.items.map((m, i) => {
                        m.createdAt = DateConvert(m.createdAt);
                        m.modifiedAt = DateConvert(m.modifiedAt);
                        m.accountType = AccountTypeConvert(m.guestId, m.userId);
                        return m;
                    });
                    setMembersList(dataItem);
                    setLoading(false);

                })
        } else {
            ApiClient.Get(Urls.advertisingCampaignMembers(), ConvertQueryString(location.search))
                .then((res) => {
                    let dataItem = res.result;
                    dataItem.items.map((m, i) => {
                        m.createdAt = DateConvert(m.createdAt);
                        m.modifiedAt = DateConvert(m.modifiedAt);
                        m.accountType = AccountTypeConvert(m.guestId, m.userId);
                        return m;
                    });
                    setMembersList(dataItem);
                    setLoading(false);

                })
        }

    }, [id, location.search])

    useEffect(() => {
        getCampaign();
        getMembersList();
    }, [getMembersList, getCampaign]);


    return (
        <WrapperLayout>
            <HeaderContent className="justify-content-end">
                <BackToPage toPage={'/campaigns'}/>
            </HeaderContent>
            <MainContent>
                {
                    id &&
                    <div className="col-12 wrapper-box">
                        <UserInformationWrapper>
                            {Object.entries(campaignTitles).map(([key, value]) => (
                                <UserInformationItem onCopy={() => copyItem(key, Campaign[key])} label={value}
                                                     dataItem={Campaign[key]} key={key}/>

                            ))}
                        </UserInformationWrapper>
                    </div>
                }
                <div className="col-12 col-lg-12">
                    <PageTitleLayout>
                        <h5>
                            {
                                id ?
                                    'لیست اعضای کمپین'
                                    :
                                    'لیست اعضای کمپین ها'
                            }
                        </h5>
                        <span>در این بخش لیست اعضای کمپین قابل مشاهده است.</span>
                    </PageTitleLayout>
                    <div>
                        {loading === true
                            ?
                            <Loader/>
                            :
                            <Table
                                data={membersList.items}
                                config={tableConfig}
                                actionDispatched={tableActions}
                                pageConfig={{
                                    pageNumber: membersList.pageNumber,
                                    pageSize: membersList.pageSize,
                                    totalItemsCount: membersList.totalItemsCount,
                                    totalPagesCount: membersList.totalPagesCount,
                                }}
                            />
                        }
                    </div>
                </div>
            </MainContent>
        </WrapperLayout>
    );
};

export default CampaignMembers;