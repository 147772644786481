import ReactionToUserTicketModel from "../Models/reaction-to-user-ticket.model";

function GetReactionToUserTicket(value) {
    let result = [];
    ReactionToUserTicketModel.forEach((item) => {
        value.forEach((element) => {
            if (item.value === element) result.push(item.name);
        });
    });
    return result;
}

export default GetReactionToUserTicket;
