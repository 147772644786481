const pendingLines = (state = [], action) => {
  switch (action.type) {
    case "PENDING_DETAILS":
      return {
        data: action.data,
      };

    default:
      return state;
  }
};


export default pendingLines