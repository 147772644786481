import React, { useEffect, useState } from 'react';
import { StyledButton } from "../../Component/Kit/Button";
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import DateConvert from "../../Utility/Services/date-convert";

const notificationTitle = {
    title: "عنوان اعلان",
    createdAt: "تاریخ ایجاد",
    startDateTime: "تاریخ شروع نمایش",
    endDateTime: "تاریخ پایان نمایش",
    readsCountTitle: "تعداد مشاهده شدن",
    content: "توضیحات",
}

function ViewNotificationModal({ data, onCloseModal }) {
    const [notificationData, setNotificationData] = useState([]);
    useEffect(() => {
        if (data) {
            ApiClient.Get(Urls.globalNotificationSingle(data)).then(res => {
                let result = res.result;
                result.createdAt = DateConvert(result.createdAt)
                result.endDateTime = result.endDateTime ? DateConvert(result.endDateTime) : '-----'
                result.startDateTime = result.startDateTime ? DateConvert(result.startDateTime) : '-----'
                result.readsCountTitle = `${result.readsCount} بار خوانده شده است.`
                setNotificationData(result)
            })
        }
    }, [data])
    return (
        <>
            <div className="w-100">
                <ul className="params">
                    {
                        notificationTitle && notificationData &&
                        Object.entries(notificationTitle).map(([key, value]) => (

                            <li className="params__list" key={`${key}_${notificationData.id}`}>
                                <div className="params__list__label">
                                    <span>{value}: </span>
                                </div>
                                <div className="params__list__value">
                                    <span className="params__list__label--white" style={{ whiteSpace: 'pre-wrap' }}>{notificationData[key]}</span>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>

            {/* <div className="w-100">
                <h4>{notificationData.title}</h4>
                <div>
                    <span
                        className='small'>{notificationData.createdAt} | {notificationData.readsCountTitle}</span>
                </div>
                <div className='small d-flex align-items-center justify-content-between mt-3'>
                    <span>تاریخ شروع: {notificationData.startDate}</span>
                    <span>تاریخ پایان: {notificationData.endDate}</span>
                </div>
                <hr />
                <p>{notificationData.content}</p>
            </div> */}

            <div className="d-flex justify-content-center w-100">
                <StyledButton
                    width="35%"
                    btnStyle="grayBtn"
                    type="reset"
                    onClick={() => {
                        onCloseModal(false)
                    }}
                >
                    بستن
                </StyledButton>
            </div>
        </>
    )
}

export default ViewNotificationModal;
