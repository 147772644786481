const PlanPositionEnum = {
    First: 1,
    Second: 2,
    Third: 3,
}

const PlanPositionModel = [
    { value: PlanPositionEnum.First, name: "برنزی" },
    { value: PlanPositionEnum.Second,  name: "نقره‌ای" },
    { value: PlanPositionEnum.Third, name: "طلایی" }
]

export { PlanPositionModel }