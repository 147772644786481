import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import './App.scss';
import AppRoute from './AppRoute';
import AppContext from './Context/AppContext';
import LocalToken from './Utility/LocalStorge/LocalToken';
import { theme } from './Utility/Theme';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Logo from './Assets/images/logo.png';

const App = () => {

  const permission = useRef('');
  const [connection, setConnection] = useState(null);
  const [hasToken, setHasToken] = useState(
    LocalToken.getAccessToken() ? true : false
  );

  const notificationList = {
    NewGuestConfirmationNotification: {
      name: 'NewGuestConfirmationNotification',
      title: 'کاربر مهمان',
      body: 'یک کاربر منتظر تایید است.',
      url: '/guests/finished',
    },
    NewLineConfirmationNotification: {
      name: 'NewLineConfirmationNotification',
      title: 'خط جدید',
      body: 'یک خط جدید منتظر تایید است.',
      url: '/line/user-lines/pending',
    },
    NewTicketAdded: {
      name: 'NewTicketAdded',
      title: 'تیکت جدید',
      body: 'یک تیکت جدید، منتظر پاسخ است.',
      url: '/ticket/list',
    },
    NewFastSendTemplateNotification: {
      name: 'NewFastSendTemplateNotification',
      title: 'قالب جدید',
      body: 'یک قالب جدید، منتظر تایید است.',
      url: '/fast-send/pending',
    },
  };

  const notificationPermission = async () => {
    permission.current = await Notification.requestPermission();

    if (permission.current === 'granted' && LocalToken.getAccessToken()) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(
          `${
            process?.env?.REACT_APP_API_HOST
          }/smsHub?token=${LocalToken.getAccessToken()}&isAdmin=true`
        )
        .build();
      setConnection(newConnection);
    }
  };

  const notificationHandler = (item) => {
    connection.on(item.name, (value) => {
      const notification = new Notification(item.title, {
        body: item.body,
        icon: Logo,
      });
      notification.onclick = () => {
        window.open(item.url, '_self');
      };
    });
  };

  useEffect(() => {
    notificationPermission();
    return () => {
      if (connection) {
        stopConnection();
      }
    };
  }, [hasToken]);

  const stopConnection = () => {
    connection.stop().then(() => {
      console.log('disconnect!');
    });
  };

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          console.log('Connected!');

          newGuestNotification();
          newLineNotification();
          newTicketNotification();
          newFastSendTemplateNotification();
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  const newGuestNotification = () => {
    notificationHandler(notificationList.NewGuestConfirmationNotification);
  };

  const newLineNotification = () => {
    notificationHandler(notificationList.NewLineConfirmationNotification);
  };

  const newTicketNotification = () => {
    notificationHandler(notificationList.NewTicketAdded);
  };

  const newFastSendTemplateNotification = () => {
    notificationHandler(notificationList.NewFastSendTemplateNotification);
  };

  return (
    <AppContext.Provider
      value={{
        hasToken,
        setHasToken,
      }}
    >
      <ToastContainer rtl={true} />
      <ThemeProvider theme={theme}>
        <AppRoute />
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
