import { Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { StyledButton } from '../../../../Component/Kit/Button'
import { StyledForm, StyledSelect, StyledSwitchBox } from '../../../../Component/Kit/Form'
import ApiClient from '../../../../Requester/ApiClient'
import Urls from '../../../../Requester/Urls'
import { AssignUserToPlanSchema } from '../../../../Utility/Validation/Schema/AssignUserToPlanSchema'

export const AssignUserPlanModal = ({ onCloseModal, onSubmit }) => {
    const params = useParams();
    const [activePlanList, setActivePlanList] = useState([]);
    const [months, setMonths] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});
    const planMonthList = [
        { value: 1, name: 'یک ماهه' },
        { value: 3, name: 'سه ماهه' },
        { value: 6, name: 'شش ماهه' },
        { value: 12, name: 'یکساله' }
    ];

    const getPlanList = useCallback(() => {
        ApiClient.Get(Urls.Plan())
            .then(res => {
                const planItem = [];
                res.result.items
                    .forEach(item => {
                        if (!item.isDeleted && item.isActive) {
                            planItem.push({ name: item.name, value: item.id, ...item })
                        }
                    })
                setActivePlanList(planItem);
            })
    }, []);

    useEffect(() => {
        getPlanList();
    }, [getPlanList, params.id]);

    const getMonthsBaseSelectedPlan = (planId) => {
        const planItem = activePlanList.find(x => x.value == planId);
        setSelectedPlan(planItem);
        let planMonth = [];
        planItem.month.forEach(x => planMonth.push(planMonthList.find(i => i.value == x)));
        setMonths(planMonth);
    }

    return (
        <>
            <Formik
                initialValues={{
                    planId: '',
                    planDuration: '',
                    includeLine: false,
                    includeCredit: false
                }}
                validationSchema={AssignUserToPlanSchema}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    const payload = {
                        userId: Number(params.id),
                        planId: Number(values.planId),
                        month: Number(values.planDuration),
                        includeLine: values.includeLine,
                        includeCredit: values.includeCredit,
                    }
                    ApiClient.Post(Urls.UserPlan(params.id), payload)
                        .then(res => {
                            if (res) {
                                toast.success('تخصیص پلن با موفقیت انجام شد.');
                                onCloseModal(true);
                                onSubmit();
                            }
                        })
                }}
            >
                {(formik) => (
                    <StyledForm
                        onSubmit={formik.handleSubmit}
                        onBlur={formik.handleBlur}
                    >
                        <StyledSelect
                            formGroupClass="mb-3"
                            lable="جایگاه پلن"
                            focus={true}
                            name="planId"
                            value={formik.values.planId}
                            optionItems={activePlanList}
                            hasDefaultOption
                            error={(formik.errors.planId && formik.touched.planId)
                                ? formik.errors.planId
                                : 'false'
                            }
                            onChange={(e) => {
                                formik.setFieldValue("planId", e.target.value);
                                getMonthsBaseSelectedPlan(e.target.value);
                            }}
                        />
                        <StyledSelect
                            formGroupClass="mb-4"
                            lable="مدت پلن"
                            focus={true}
                            name="planDuration"
                            value={formik.values.planDuration}
                            optionItems={months}
                            hasDefaultOption
                            error={(formik.errors.planDuration && formik.touched.planDuration)
                                ? formik.errors.planDuration
                                : 'false'
                            }
                            onChange={(e) => formik.setFieldValue("planDuration", e.target.value)}
                        />

                        <div className="d-flex mb-3">
                            <span className="ml-4">اعطای خـط مجزا به کاربر</span>
                            <StyledSwitchBox
                                name="includeLine"
                                checked={formik.values.includeLine}
                                disabled={!selectedPlan.hasLine}
                                onChange={(e) => formik.setFieldValue("includeLine", e.target.checked)}
                            />
                        </div>
                        <div className="d-flex mb-4">
                            <span className="ml-3">اعطای اعتبار رایگان به کاربر</span>
                            <StyledSwitchBox
                                name="includeCredit"
                                disabled={!selectedPlan.hasCredit}
                                checked={formik.values.includeCredit}
                                onChange={(e) => formik.setFieldValue("includeCredit", e.target.checked)}
                            />
                        </div>

                        <div className="d-flex justify-content-between mt-2">
                            <StyledButton
                                width="35%"
                                btnStyle="grayBtn"
                                type="reset"
                                onClick={() => onCloseModal(false)}
                            >
                                بستن
                            </StyledButton>
                            <StyledButton
                                width="35%"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting ? 1 : 0}
                                btnStyle="greenBtn"
                                type="submit"
                            >
                                تخصیص دادن
                            </StyledButton>
                        </div>
                    </StyledForm>
                )}
            </Formik>
        </>
    )
}
