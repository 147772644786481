import {Formik} from "formik";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {StyledButton} from "../../../../../Component/Kit/Button";
import {FormGroup, FormLable, StyledCheckBox, StyledForm, StyledInput,} from "../../../../../Component/Kit/Form";
import Modal from "../../../../../Component/Kit/Modal";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";
import {
    AddInvoiceNumberInOldPanelSchema,
    AddInvoiceNumberSchema,
} from "../../../../../Utility/Validation/Schema/AddInvoiceNumberSchema";
import {MyDatePicker} from "../../../../../Component/Shared/DatePicker";


export const AddNumberToInvoiceModal = (props) => {
    const [isOldPanel, setIsOldPanel] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const {
        officialInvoiceId,
        isReturn,
        data,
        visible,
        setVisible,
        onSubmit,
        onCancel,
        onClose,
        hasOldPanel,
        email,
    } = props;


    const formSubmit = function (values, actions) {

        const dataBody = {
            number: values.invoiceNumber,
            date: values.invoiceDate,
            sendSms: !data ? values.sendSms : false,
            sepidarRegisterOptions: {
                intendedFirstName: values.intendedFirstName,
                intendedLastName: values.intendedLastName,
                isNewNameBeRegistered: values.isNewNameBeRegistered,
                ignoreCheckToRegistrationNewCustomer: values.ignoreCheckToRegistrationNewCustomer
            }
        };

        const params = {
            isReturn: isReturn,
        };
        if (isOldPanel) {
            dataBody["sendLinkViaSms"] = values.withMobile;
            dataBody["email"] =
                values.withEmail && values.OldPanelInvoiceEmail
                    ? values.OldPanelInvoiceEmail
                    : "";

            if (!data) {
                ApiClient.Post(
                    Urls.NumberedOldPanelInvoice(officialInvoiceId),
                    dataBody
                ).then((res) => {
                    toast.success("فاکتور شماره دار و در سپیدار ثبت شد!");
                    setVisible(false);
                    onSubmit()
                });
            } else {
                delete dataBody['sepidarRegisterOptions'];
                ApiClient.Put(
                    Urls.NumberedOldPanelInvoice(officialInvoiceId),
                    dataBody
                ).then((res) => {
                    toast.success(" شماره فاکتور با موفقیت ویرایش شد!");
                    setVisible(false);
                    onSubmit()
                });
            }

        } else {

            if (!data) {
                ApiClient.Post(
                    Urls.setNumberForInvoice(officialInvoiceId, params),
                    dataBody
                ).then((res) => {
                    toast.success("فاکتور شماره دار و در سپیدار ثبت شد!");
                    setVisible(false);
                    onSubmit()
                });
            } else {
                delete dataBody['sepidarRegisterOptions'];
                ApiClient.Put(
                    Urls.setNumberForInvoice(officialInvoiceId, params),
                    dataBody
                ).then((res) => {
                    toast.success(" شماره فاکتور با موفقیت ویرایش شد!");
                    setVisible(false);
                    onSubmit()
                });
            }


        }
    }


    useEffect(() => {
        setIsOldPanel(hasOldPanel || false);
        setUserEmail(email);
        setVisible(visible);
    }, [hasOldPanel, email, visible]);


    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCancel={onCancel}
            headerTitle={data !== null ? "ویرایش فاکتور" : "افزودن شماره فاکتور"}
        >
            <div className="col-12 pt-3 pb-3">
                {data === null && (
                    <h6>آیا مایلید فاکتور مورد نظر را شماره دار نمایید؟ </h6>
                )}
                <div className="row">
                    <Formik
                        initialValues={{
                            invoiceNumber: data !== null ? data.number : "",
                            invoiceDate: data !== null ? data.date : "",
                            OldPanelInvoiceEmail: userEmail || null,
                            withEmail: false,
                            withMobile: true,
                            sendSms: false,
                            intendedFirstName: "",
                            intendedLastName: "",
                            isNewNameBeRegistered: false,
                            ignoreCheckToRegistrationNewCustomer: false
                        }}
                        validationSchema={
                            isOldPanel
                                ? AddInvoiceNumberInOldPanelSchema
                                : AddInvoiceNumberSchema
                        }
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            formSubmit(values, actions);
                        }}
                        onReset={(values, formikBag) => {
                            setVisible(false);
                        }}
                    >
                        {(formik) => (
                            <StyledForm
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                                onBlur={formik.handleBlur}
                            >
                                {(isOldPanel && !data) && (
                                    <>
                                        <FormLable className="mb-2" show={true}>
                                            نحوه ارسال
                                        </FormLable>
                                        <div className="d-flex align-items-center">
                                            <StyledCheckBox
                                                lable="تلفن همراه"
                                                focus={true}
                                                name="withMobile"
                                                id="withMobile"
                                                checked={
                                                    formik.values.withMobile !== undefined
                                                        ? formik.values.withMobile
                                                        : formik.initialValues.withMobile
                                                }
                                                error="false"
                                                onChange={(e) =>
                                                    formik.setFieldValue("withMobile", e.target.checked)
                                                }
                                            />
                                            <StyledCheckBox
                                                formGroupClass="mr-3"
                                                lable="ایمیل"
                                                focus={true}
                                                name="withEmail"
                                                id="withEmail"
                                                checked={
                                                    formik.values.withEmail !== undefined
                                                        ? formik.values.withEmail
                                                        : formik.initialValues.withEmail
                                                }
                                                error="false"
                                                onChange={(e) =>
                                                    formik.setFieldValue("withEmail", e.target.checked)
                                                }
                                            />

                                        </div>
                                        <StyledInput
                                            lable="ایمیل"
                                            placeholder="name@info.com"
                                            focus={true}
                                            disabled={!formik.values.withEmail}
                                            name="email"
                                            value={formik.values.OldPanelInvoiceEmail}
                                            error={
                                                formik.errors.OldPanelInvoiceEmail &&
                                                formik.touched.OldPanelInvoiceEmail
                                                    ? formik.errors.OldPanelInvoiceEmail
                                                    : "false"
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "OldPanelInvoiceEmail",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </>
                                )}
                                <StyledInput
                                    lable="شماره فاکتور"
                                    placeholder="of-xxxxxxx"
                                    focus={true}
                                    name="invoiceNumber"
                                    value={formik.values.invoiceNumber}
                                    error={
                                        formik.errors.invoiceNumber && formik.touched.invoiceNumber
                                            ? formik.errors.invoiceNumber
                                            : "false"
                                    }
                                    onChange={(e) =>
                                        formik.setFieldValue("invoiceNumber", e.target.value)
                                    }
                                />

                                <FormGroup>
                                    <FormLable show={true}>تاریخ فاکتور</FormLable>
                                    <MyDatePicker
                                        defaultValue={formik.values.invoiceDate}
                                        value={(event) => {
                                            formik.setFieldValue("invoiceDate", event.baseFormat);
                                        }}
                                        name="invoiceDate"
                                        error={
                                            formik.errors.invoiceDate !== undefined
                                                ? formik.errors.invoiceDate
                                                : false
                                        }
                                    />
                                </FormGroup>

                                {!data &&

                                    <>
                                        <div className="row justify-content-between justify-content-md-around">

                                            <StyledCheckBox
                                                lable="ارسال پیامک"
                                                focus={true}
                                                name="sendSms"
                                                id="sendSms"
                                                checked={
                                                    formik.values.sendSms !== undefined
                                                        ? formik.values.sendSms
                                                        : formik.initialValues.sendSms
                                                }
                                                error="false"
                                                onChange={(e) =>
                                                    formik.setFieldValue("sendSms", e.target.checked)
                                                }
                                            />

                                            <StyledCheckBox
                                                formGroupClass=""
                                                lable="ثبت با نام جدید"
                                                focus={true}
                                                name="isNewNameBeRegistered"
                                                id="isNewNameBeRegistered"
                                                checked={formik.values.isNewNameBeRegistered !== undefined
                                                    ? formik.values.isNewNameBeRegistered
                                                    : formik.initialValues.isNewNameBeRegistered}
                                                error="false"
                                                onChange={(e) => formik.setFieldValue("isNewNameBeRegistered", e.target.checked)}
                                            />

                                            <StyledCheckBox
                                                formGroupClass=""
                                                lable="نادیده گرفتن بررسی مشتری"
                                                focus={true}
                                                name="ignoreCheckToRegistrationNewCustomer"
                                                id="ignoreCheckToRegistrationNewCustomer"
                                                checked={formik.values.ignoreCheckToRegistrationNewCustomer !== undefined
                                                    ? formik.values.ignoreCheckToRegistrationNewCustomer
                                                    : formik.initialValues.ignoreCheckToRegistrationNewCustomer}
                                                error="false"
                                                onChange={(e) => formik.setFieldValue("ignoreCheckToRegistrationNewCustomer", e.target.checked)}

                                            />

                                        </div>

                                        <div className="row">
                                            {
                                                formik.values.isNewNameBeRegistered &&
                                                <>
                                                    <div className="col-12 col-md-6">
                                                        <StyledInput
                                                            lable="نام"
                                                            focus={true}
                                                            type="text"
                                                            name="intendedFirstName"
                                                            value={formik.values.intendedFirstName || ""}
                                                            error="false"
                                                            onChange={(e) =>
                                                                formik.setFieldValue("intendedFirstName", e.target.value)
                                                            }
                                                        />
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <StyledInput
                                                            lable="نام خانوادگی"
                                                            focus={true}
                                                            type="text"
                                                            name="intendedLastName"
                                                            value={formik.values.intendedLastName || ""}
                                                            error="false"
                                                            onChange={(e) =>
                                                                formik.setFieldValue("intendedLastName", e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>

                                }

                                <div className="d-flex justify-content-between mt-3">
                                    <StyledButton
                                        btnStyle="greenBtn ml-3"
                                        width="38%"
                                        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                        loading={formik.isSubmitting ? 1 : 0}
                                        type="submit"
                                    >
                                        {data === null ? "تایید" : "ویرایش"}
                                    </StyledButton>

                                    <StyledButton width="35%" btnStyle="grayBtn" type="reset">
                                        بستن
                                    </StyledButton>
                                </div>
                            </StyledForm>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};
