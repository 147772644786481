
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../../../../../Component/Kit/Modal";
import {Field, FieldArray, Form, Formik} from "formik";
import {StyledForm, StyledInput, StyledSelect} from "../../../../../Component/Kit/Form";
import {StyledButton} from "../../../../../Component/Kit/Button";
import invoiceDetailItem from "../../../../../Utility/Model/InvoiceDetailsItem";
import {EditInvoiceDetailSchema} from "../../../../../Utility/Validation/Schema/EditInvoiceDetailSchema";
import ApiClient from "../../../../../Requester/ApiClient";
import Urls from "../../../../../Requester/Urls";
import {toast} from "react-toastify";
import {NumericalValidationMultilang} from "../../../../../Utility/method/NumericalValidationMultilang";

export const EditDetailInvoiceModal = (props) => {
    const {
        data,
        visible,
        setVisible,
        onSubmit,
        onCancel,
        onClose,
    } = props;

    const history = useHistory();


    const closeModal = () => {
        onClose();
    };



    function toEnglishDigits(str) {
        const persianNumbers = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"]
        const arabicNumbers = ["١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩", "٠"]
        const englishNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]

        return str.toString().split("").map(c => englishNumbers[persianNumbers.indexOf(c)] ||
            englishNumbers[arabicNumbers.indexOf(c)] || c).join("")
    }


    return (
        <Modal
            visible={visible}
            onClose={() => closeModal()}
            onCancel={onCancel}
            width="large"
            headerTitle={"ویرایش مشخصات فاکتور"}
        >
            {data?.details?.length > 0 &&
            <Formik
                initialValues={{ details: data?.details }}
                validationSchema={EditInvoiceDetailSchema}
                enableReinitialize={true}
                onSubmit={values =>{

                    const formValues = values.details.map(
                        (a) => {
                            return {
                                id: a.invoiceDetailId ? a.invoiceDetailId : null,
                                description: a.description,
                                count: toEnglishDigits(a.count),
                                basePrice: toEnglishDigits(a.basePrice),
                                discountPrice: toEnglishDigits(a.discountPrice),
                                item: a.item
                            }
                        }
                    )
                    ApiClient.Post(Urls.changeInvoiceDetails(data.id), formValues).then((res) => {
                        toast.success("تایید اولیه تغییرات فاکتور با موفقیت انجام شد .");
                        onSubmit(res)
                        onClose();
                    });
                }}
                render={({ values,handleSubmit,handleReset,handleBlur,isValid , dirty, setFieldValue ,errors,touched}) => (
                    <StyledForm
                        onSubmit={handleSubmit}
                        onBlur={handleBlur}
                        onReset={handleReset}
                    >
                        <FieldArray
                            name="details"

                            render={arrayHelpers => (

                                <div>
                                    <div className="mb-2 d-flex justify-content-end">
                                        <StyledButton
                                            style={{
                                                marginTop: 14,
                                                height: 30,
                                                background: '#7fb961',
                                                color: '#fff',
                                                border: 'none',
                                                minWidth: 150,
                                                borderRadius: 4,
                                                marginRight: 4,
                                            }}
                                            type="button"
                                            onClick={() => arrayHelpers.insert( values.details.length + 1, '')} // insert an empty string at a position
                                        >
                                            افزودن کالا
                                        </StyledButton>
                                    </div>
                                    {values.details && values.details.length > 0 &&

                                        values.details.map((friend, index) => (

                                            <div key={index} className="d-flex align-items-center">
                                                <StyledSelect
                                                    lable="نوع کالا"
                                                    focus={true}
                                                    name={`details.${index}.item`}
                                                    value={values.details[index].item}
                                                    optionItems={
                                                         [{ name: "انتخاب کنید...", value: 0 },
                                                            ...invoiceDetailItem ]
                                                 }
                                                    error={(touched && touched.item && touched.item[index] && values.item[index] == 0)
                                                        ? "این فیلد الزامی است."
                                                        : ((errors && errors.item && errors.item[index]) ? errors.item[index] : 'false')
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue(`details.${index}.item`, e.target.value)
                                                    }
                                                />
                                                <StyledInput
                                                    name={`details.${index}.description`}
                                                    lable="شرح کالا"
                                                    focus={true}
                                                    placeholder="شرح کالا"
                                                    type="text"
                                                    value={values.details[index].description}
                                                    onChange={(e) =>
                                                        setFieldValue(`details.${index}.description`, e.target.value)
                                                    }
                                                    error={(touched && touched.description && touched.description[index] && values.description[index] == 0)
                                                        ? "این فیلد الزامی است."
                                                        : ((errors && errors.description && errors.description[index]) ? errors.description[index] : 'false')
                                                    }
                                                />
                                                <StyledInput
                                                    name={`details.${index}.count`}
                                                    lable="تعداد/مقدار"
                                                    focus={true}
                                                    placeholder="تعداد/مقدار"
                                                    type="text"
                                                    value={values.details[index].count}
                                                    onChange={(e) =>
                                                        setFieldValue(`details.${index}.count`, e.target.value)
                                                    }
                                                    error={(touched && touched.count && touched.count[index] && values.count[index] == 0)
                                                        ? "این فیلد الزامی است."
                                                        : ((errors && errors.count && errors.count[index]) ? errors.count[index] : 'false')
                                                    }
                                                />
                                                <StyledInput
                                                    name={`details.${index}.basePrice`}
                                                    lable="مبلغ واحد"
                                                    focus={true}
                                                    placeholder="مبلغ واحد"
                                                    type="text"
                                                    value={values.details[index].basePrice}
                                                    onChange={(e) =>
                                                        setFieldValue(`details.${index}.basePrice`, e.target.value)
                                                    }
                                                    error={(touched && touched.basePrice && touched.basePrice[index] && values.basePrice[index] == 0)
                                                        ? "این فیلد الزامی است."
                                                        : ((errors && errors.basePrice && errors.basePrice[index]) ? errors.basePrice[index] : 'false')
                                                    }
                                                />
                                                <StyledInput
                                                    name={`details.${index}.discountPrice`}
                                                    lable="مبلغ تخفیف"
                                                    focus={true}
                                                    placeholder="مبلغ تخفیف"
                                                    type="text"
                                                    value={values.details[index].discountPrice?.toString()}
                                                    onChange={(e) =>
                                                        setFieldValue(`details.${index}.discountPrice`, e.target.value)
                                                    }
                                                    error={(touched && touched.discountPrice && touched.discountPrice[index] && values.discountPrice[index] == 0)
                                                        ? "این فیلد الزامی است."
                                                        : ((errors && errors.discountPrice && errors.discountPrice[index]) ? errors.discountPrice[index] : 'false')
                                                    }
                                                />

                                                <button
                                                    style={{
                                                        marginTop: 14,
                                                        height: 30,
                                                        background: '#be5656',
                                                        color: '#fff',
                                                        border: 'none',
                                                        minWidth: 30,
                                                        borderRadius: 4,
                                                        marginRight: 4,
                                                    }}
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    x
                                                </button>

                                            </div>


                                        ))

                                    }

                                    <div className="d-flex justify-content-end">
                                        <StyledButton
                                            btnStyle="greenBtn ml-3 flex-1"
                                            width="100%"
                                            type="submit"
                                            disabled={!isValid || !dirty}
                                        >
                                            تایید اولیه
                                        </StyledButton>

                                        <StyledButton width="80%" btnStyle="grayBtn" type="reset">
                                            بستن
                                        </StyledButton>
                                    </div>

                                </div>

                            )}

                        />
                    </StyledForm>
                )}
            />
            }
        </Modal>
    );
};
