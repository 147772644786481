import React, { useEffect, useState } from "react";
import {PageTitleLayout} from "../../../Component/Kit/Layout";
import Table from "../../../Component/Shared/Table/Table";
import {useHistory, useLocation} from "react-router-dom";
import ApiClient from "../../../Requester/ApiClient";
import Urls from "../../../Requester/Urls";
import {ConvertQueryString} from "../../../Utility/Services/convert-query-string";
import DateConvert from "../../../Utility/Services/date-convert";
import {TowerOperatorList, TowerStatusList} from "../../../Utility/Model/send-tower.model";

export function SendTowerPending(){
    const [requests, setRequests] = useState({});
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        ApiClient.Get(Urls.getSendTowerPending(), ConvertQueryString(location.search))
            .then(res => {
                res.result.items.map((item) => {
                    item.createdAt = DateConvert(item.createdAt);
                    item.firstSendAt = DateConvert(item.firstSendAt);
                    item.totalPrice = item.totalPrice?.toLocaleString('fa-IR')
                    item.operatorName = TowerOperatorList.find(a => a.value == item.operatorId)?.name
                    item.stateName = TowerStatusList.find(a => a.value == item.state)?.name
                })
                setRequests(res.result)
            })
    }, [location.search])
    const tableConfig = {
        header: {
            userName: "نام کاربری",
            recipientsCount: 'تعداد مخاطبین',
            totalPrice: "مبلغ کل",
            operatorName: "نام اپراتور",
            createdAt: "تاریخ ایجاد",
            firstSendAt: "زمان اولین ارسال",
            stateName: "وضعیت",
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            }
        },
    };
    const tableActions = (item) => {
        if (item.actionName === 'view') {
            history.push(`single/${item.data.id}`)
        }
    };

    return (
        <>
                <PageTitleLayout>
                    <h5> ارسال از دکل</h5>
                    <span>در اینجا می‌توانید درخواست های در حال انتظار را مشاهد نمایید.</span>
                </PageTitleLayout>
                <Table
                    data={requests.items}
                    config={tableConfig}
                    pageConfig={{
                        pageNumber: requests.pageNumber,
                        pageSize: requests.pageSize,
                        totalItemsCount: requests.totalItemsCount,
                        totalPagesCount: requests.totalPagesCount,
                    }}
                    actionDispatched={tableActions}
                />
        </>
    )
}