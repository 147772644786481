// import React from 'react'
import PaymentStatusModel from '../Model/PaymentStatusModel'

export const PaymentStatusService = (value) => {
    let result = PaymentStatusModel.find((item) => 
        item.value === value
    )
    return result.name
}

