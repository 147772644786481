import React, {useEffect, useState} from "react";
import {StyledButton} from "../../../../Component/Kit/Button";
import Modal from "react-bootstrap/Modal";
import {Badge} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import ApiClient from "../../../../Requester/ApiClient";
import {toast} from "react-toastify";
import {StyledForm, StyledInput} from "../../../../Component/Kit/Form";
import {connect, useDispatch} from "react-redux";
import Urls from "../../../../Requester/Urls";
import {settingModal} from "../../../../Actions/setting-modal";

function PublicLineSendLimitations({data}) {
    let dispatch = useDispatch();
    const [isModal, setIsModal] = useState(false);
    const [modalData, setModalData] = useState();

    useEffect(() => {
        if (data) {
            setModalData(JSON.parse(data))
        }
    }, [data]);

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column ">


                     <span className="">
                        حداقل تعداد مخاطب مجاز در ارسال با خطوط ویژه:
                        <Badge className="font-weight-normal mr-1" variant="primary">
                            {modalData &&
                                modalData.CreationLimitCount
                            }

                        </Badge>
                    </span>


                    <span className="mt-3">
                        حداقل تعداد مخاطب در اطلاع رسانی ارسال با خطوط ویژه:
                        <Badge className="font-weight-normal mr-1" variant="primary">
                        {modalData &&
                            modalData.NotificationLimitCount
                        }
                        </Badge>
                    </span>


                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>


            <Modal centered show={isModal} onHide={() => {
                setIsModal(false)
            }}>
                <Modal.Header>
                    <Modal.Title>محدودیت ‌های ارسال با خطوط ویژه</Modal.Title>
                    <i onClick={() => {
                        setIsModal(false)
                    }} className="close-btn fa fa-xmark"/>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalData &&
                        <>
                            <Formik
                                initialValues={{
                                    creationLimitCount: modalData.CreationLimitCount,
                                    notificationLimitCount: modalData.NotificationLimitCount,
                                }}
                                validationSchema={Yup.object().shape({
                                    creationLimitCount: Yup.string()
                                        .label("creationLimitCount")
                                        .required("این فیلد الزامی است."),

                                    notificationLimitCount: Yup.string()
                                        .label("notificationLimitCount")
                                        .required("این فیلد الزامی است."),

                                })}
                                isInitialValid={false}
                                enableReinitialize={true}
                                onSubmit={(values) => {
                                    Swal.fire({
                                        title: `آیا مطمئن هستید؟`,
                                        text: "",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonText: "انصراف",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "بله، مطمئن هستم",
                                    }).then(result => {
                                        if (result.isConfirmed) {
                                            let body = {
                                                creationLimitCount: parseInt(values.creationLimitCount),
                                                notificationLimitCount: parseInt(values.notificationLimitCount)
                                            }

                                            ApiClient.Patch(Urls.publicLineSendLimitations(), JSON.stringify(body))
                                                .then((res) => {
                                                    toast.success('تغییرات  با موفقیت ثبت شد.');
                                                    dispatch(settingModal(true));
                                                })
                                        }

                                    })

                                }}
                                onReset={(values, formikBag) => {
                                    setIsModal(false)
                                }}
                            >
                                {(formik) => (
                                    <StyledForm
                                        className={'p-0'}
                                        onSubmit={formik.handleSubmit}
                                        onBlur={formik.handleBlur}
                                        onReset={formik.handleReset}>

                                        <StyledInput
                                            lable="حداقل تعداد مخاطب مجاز در ارسال با خطوط ویژه"
                                            focus={true}
                                            placeholder="50"
                                            type="number"
                                            name="creationLimitCount"
                                            value={formik.values.creationLimitCount}
                                            error={(formik.errors.creationLimitCount && formik.touched.creationLimitCount)
                                                ? formik.errors.creationLimitCount
                                                : 'false'
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue("creationLimitCount", e.target.value)
                                            }
                                        />
                                        

                                        <StyledInput
                                            lable="حداقل تعداد مخاطب در اطلاع رسانی ارسال با خطوط ویژه"
                                            focus={true}
                                            placeholder="50"
                                            type="number"
                                            name="notificationLimitCount"
                                            value={formik.values.notificationLimitCount}
                                            error={(formik.errors.notificationLimitCount && formik.touched.notificationLimitCount)
                                                ? formik.errors.notificationLimitCount
                                                : 'false'
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue("notificationLimitCount", e.target.value)
                                            }
                                        />



                                        <div className="d-flex justify-content-between">
                                            <StyledButton
                                                width="35%"
                                                btnStyle="grayBtn"
                                                type="reset"
                                            >
                                                بستن
                                            </StyledButton>
                                            <StyledButton
                                                width="35%"
                                                disabled={!formik.isValid || !formik.dirty}
                                                btnStyle="greenBtn"
                                                type="submit"
                                            >
                                                اعمال محدودیت
                                            </StyledButton>
                                        </div>
                                    </StyledForm>
                                )}
                            </Formik>
                        </>
                    }

                </Modal.Body>

            </Modal>

        </>
    )
}

export default connect()(PublicLineSendLimitations)
