import React, { useState } from 'react'
import { StyledButton } from '../../../../Component/Kit/Button'
import Modal from '../../../../Component/Kit/Modal';
import Urls from '../../../../Requester/Urls'
import NumberLimitationModal from "../../modals/limitation-modal";
import {Badge} from 'react-bootstrap';

export const WarningLakeCredit = ({ data }) => {
    const [isModal, setIsModal] = useState(false)
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="ml-1">کمینه پیش ‌فرض آگاه‌سازی از کمبود اعتبار :</span>
                    <Badge className="font-weight-normal" variant="primary">{data}</Badge>
                </div>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setIsModal(true)}
                >
                    ویرایش
                </StyledButton>
            </div>
            <Modal
                onClose={() => setIsModal(false)}
                onCancel={() => setIsModal(false)}
                visible={isModal}
                headerTitle="کمینه پیش ‌فرض آگاه‌سازی از کمبود اعتبار"
            >
                <NumberLimitationModal defaultData={data} onCloseModal={() => setIsModal(false)} url={Urls.lackOfCreditWarningThreshold()} />
            </Modal>
        </>
    )
}
