import React, {useCallback, useEffect, useState} from 'react'
import { PageTitleLayout } from '../../../../Component/Kit/Layout';
import Table from '../../../../Component/Shared/Table/Table';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import DateConvert from '../../../../Utility/Services/date-convert';
import { SeprateNumberWithComma } from '../../../../Utility/Services/SeprateNumberWithComma';
import { useLocation } from "react-router-dom";
import { ConvertQueryString } from "../../../../Utility/Services/convert-query-string";
import {StyledButton} from "../../../../Component/Kit/Button";
import {InvoiceNumberingGroupModal} from "./InvoiceNumberingGroupModal";

export const InvoiceAwaitingApproval = (props) => {
    const location = useLocation()
    const { tableConfig, tableActions } = props;
    const [officialinvoiceAwaitingList, setOfficialinvoiceAwaitingList] = useState({});
    const [selectedInvoice , setSelectedInvoice] = useState([])
    const [showNumberingGroupModal , setShowNumberingGroupModal] = useState(false)

    let localTableConfig = {
        ...tableConfig,
        hasSelectCol: true,
    }


    const getInvoices  = useCallback(
        ()=> {
            let params = {
                ...ConvertQueryString(location.search),
                numbered: false
            }
            ApiClient.Get(Urls.GetOfficialInvoices(), params)
                .then(res => {
                    let responseData = res.result;
                    responseData.items.map((item, i) => {
                        item.totalPrice = `${SeprateNumberWithComma(item.totalPrice)} ریال`;
                        item.createdAt = DateConvert(item.createdAt);
                        item.canModifyDiscountPrice = item.canModifyDiscountPrice
                            ? `<span class="grass-green-text">دارد</span>`
                            : `<span class="red-text">ندارد</span>`;
                        item.onlyReturns = (item.isReturnInvoice)
                            ? "فاکتور برگشتی"
                            : "فاکتور";
                    });
                    setOfficialinvoiceAwaitingList(responseData);
                })
        } , [location.search]
    )

    useEffect(() => {
        getInvoices()
    }, [location.search]);

    const tableSelectedList = (item) => {
        const formArray = []
        item.forEach(
            (el) => {
                formArray.push( {
                    id: el.id,
                    number: '',
                    date: '0'
                })
            }
        )
        setSelectedInvoice(formArray);
    };

    return (
        <div>
            <PageTitleLayout className="mt-4">
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <h5>  لیست فاکتورهای بدون شماره</h5>
                        <span>در این بخش لیستی از فاکتور‌هایی که نیاز به شماره دارند، قابل مشاهده است.</span>
                    </div>
                    <StyledButton
                        btnStyle='greenBtn'
                        onClick={() => {
                            setShowNumberingGroupModal(true)
                        }}
                        disabled={!selectedInvoice.length}
                    >
                        شماره دار کردن گروهی فاکتور ها
                    </StyledButton>
                </div>
            </PageTitleLayout>
            {
                officialinvoiceAwaitingList && (
                    <Table
                        data={officialinvoiceAwaitingList.items}
                        config={localTableConfig}
                        actionDispatched={tableActions}
                        selectedDispatch={tableSelectedList}
                        pageConfig={{
                            pageNumber: officialinvoiceAwaitingList.pageNumber,
                            pageSize: officialinvoiceAwaitingList.pageSize,
                            totalItemsCount: officialinvoiceAwaitingList.totalItemsCount,
                            totalPagesCount: officialinvoiceAwaitingList.totalPagesCount,
                        }}
                    />

                )}


            <InvoiceNumberingGroupModal
                visible={showNumberingGroupModal}
                onCancel={() => setShowNumberingGroupModal(false)}
                closeModal={() => setShowNumberingGroupModal(false)}
                submit={() => {
                setShowNumberingGroupModal(false)
                getInvoices()
            }}
                isOldPanel={false}
                selectedInvoice={selectedInvoice}
            />
        </div>
    )
}
