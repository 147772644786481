import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { BasePriceTiersAction } from '../../../Actions/BasePriceTiers';
import { HeaderContent, MainContent, WrapperLayout } from '../../../Component/Kit/Layout';
import BackToPage, { BackWrapper } from '../../../Component/Kit/back';
import Stepper from '../../../Component/Shared/Stepper';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import { StepWizardWrapper } from '../style';
import BasePriceDiscountCodeStep from './BasePriceStep';
import BasicInformationStep from './BasicInformationStep';
import DedicatedBasePriceStep from './DedicatedBasePriceStep';
import PlansBasePriceStep from './PlansBasePriceStep';
import { DiscountCodeDetailAction } from '../../../Actions/DiscountCodeDetail';

const ModifyDiscountCode = ({ discountItemInitalData, tiers }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const [activeStep, setActiveStep] = useState(1);
  const [finalData, setFinalData] = useState({});
  const stepConfig = [
    {
      step: 1,
      title: 'مشخصات اولیه',
      subTitle: 'مشخصات کد تخفیف',
    },
    {
      step: 2,
      title: 'قیمت پایه',
      subTitle: 'تخفیف  تعرفه پایه ',
    },
    {
      step: 3,
      title: 'تعرفه اختصاصی',
      subTitle: 'تخفیف تعرفه اختصاصی',
    },
    {
      step: 4,
      title: 'تعرفه  پلن‌ها',
      subTitle: 'تخفیف تعرفه در پلن‌ها',
    },
  ]

  useEffect(() => {
    if (!Object.entries(discountItemInitalData).length && params.id) {
      history.push("/discount-code");
    }

    ApiClient.Get(Urls.GetCreditTier())
      .then((res) => {
        dispatch(BasePriceTiersAction(res.result));
      });

    return () => {
      dispatch(DiscountCodeDetailAction(null));
      setFinalData({});
    }

  }, [dispatch, discountItemInitalData, history, params.id]);



  const submitDiscountCode = (lastStepData) => {
    let discountedBasePricesData = {};
    Object.values(tiers).map((item, index) => {
      discountedBasePricesData[item.id] = Number(finalData[2].basePriceDisCount[index])
    })

    // const dedicatedBasePriceDisCountData = Object.assign({}, ...Object.values(finalData[3].dedicatedBasePriceDisCount).map((val, index) => ({ [index + 1]: val })));

    let planBasePriceDisCountData = {}
    for (let positionId = 1; positionId <= 3; positionId++) {
      const x = { ...lastStepData.planBasePriceDisCount[positionId] }
      planBasePriceDisCountData = { ...planBasePriceDisCountData, [positionId]: x }
    }

    let payload = {
      fromDate: finalData[1].fromDate,
      toDate: finalData[1].toDate,
      usageLimitationPerUser: Number(finalData[1].usageLimitationPerUser),
      tiersAndDiscountedBasePrices: discountedBasePricesData,
      dedicatedBasePriceAndDiscountedBasePrices: finalData[3].dedicatedBasePriceDisCount,
      planTiersAndDiscountedBasePrices: planBasePriceDisCountData,
    };

    if (Object.entries(discountItemInitalData).length && params.id) {
      // Modify Dicount Code
      ApiClient.Patch(Urls.PublicDiscountCode(params.id), JSON.stringify(payload))
        .then(() => {
          toast.success('تغییرات  با موفقیت ثبت شد.');
          history.goBack();
        })
    } else {
      // Add Dicount Code
      payload = { ...payload, code: finalData[1].discountCode }
      ApiClient.Post(Urls.PublicDiscountCode(), JSON.stringify(payload))
        .then(() => {
          toast.success("تغییرات  با موفقیت ثبت شد.");
          history.goBack();
        });
    }

  }

  const updateState = (state) => {
    if (state.data) {
      setFinalData({ ...finalData, [(state.step) - 1]: state.data })
    }
    if (state.step <= 4) {
      setActiveStep(state.step);
    }
    if (state.step == 5) {
      // Finall step
      const lastStepData = state.data;
      submitDiscountCode(lastStepData);
    }
  }

  return (
    <>
      <WrapperLayout>
        <div className="c-wrapper">
          <HeaderContent className="justify-content-between">
            <span className="fakeContainer"></span>
            <BackToPage />
          </HeaderContent>
        </div>

        <MainContent>
          <div className='col-12'>

            <Stepper config={stepConfig} currentStep={activeStep} />
            <hr />

            <StepWizardWrapper>
              {
                activeStep === 1 &&
                <BasicInformationStep step={1} formData={finalData[1]} initalData={discountItemInitalData} updateState={(e) => updateState(e)} />
              }
              {
                activeStep === 2 &&
                <BasePriceDiscountCodeStep step={2} formData={finalData[2]} initalData={discountItemInitalData} updateState={(e) => updateState(e)} />
              }
              {
                activeStep === 3 &&
                <DedicatedBasePriceStep step={3} formData={finalData[3]} initalData={discountItemInitalData} updateState={(e) => updateState(e)} />
              }
              {
                activeStep === 4 &&
                <PlansBasePriceStep step={4} formData={finalData[4]} initalData={discountItemInitalData} updateState={(e) => updateState(e)} />
              }
            </StepWizardWrapper>
          </div>
        </MainContent>

      </WrapperLayout >

    </>
  )
}
const mapStateToProps = (state) => ({
  discountItemInitalData: state.DiscountCodeDetailReducer,
  tiers: state.BasePriceTiersReducer,
});


export default connect(mapStateToProps)(ModifyDiscountCode);