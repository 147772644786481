import React, {useEffect, useState} from 'react';
import {StyledButton} from "../../Component/Kit/Button";
import Swal from "sweetalert2";
import {toast} from 'react-toastify';
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import {Formik} from "formik";
import {StyledForm, StyledSelect} from "../../Component/Kit/Form";
import * as Yup from "yup";
import {Loader} from "../../Component/Shared/Loader";


const PublicLineEditModalSchema = Yup.object().shape({
    selectedPublicLine: Yup.string()
        .required('این فیلد اجباری است.'),
});

function PublicLineEditModal({data, onCloseModal}) {
    const [loading, setLoading] = useState(true);
    const [optionList, setOptionList] = useState([]);
    const [submitLoading, setsubmitLoading] = useState(false);


    useEffect(() => {
        ApiClient.Get(Urls.publicLines()).then(res => {
            let data = res.result;
            setLoading(false);
            setOptionList(data.publicLines.map(item => {
                return {value: item['id'], name: item['number']}
            }))

        }).catch(e => {
            setLoading(false);
        })
    }, []);

    const changePublicLineConfirm = async (id) => {
        setsubmitLoading(true)
        try {
            let result = await Swal.fire({
                text: `آیا از تغییر شماره این پیامک مطمئن هستید؟`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "انصراف",
                cancelButtonColor: "#d33",
                confirmButtonText: "بله، مطمئن هستم",
            });
            if (result.isConfirmed) {
                let body = {
                    publicLineId: Number(id)
                }
                await ApiClient.Put
                (`${Urls.editPublicLineById(data.id)}?smsType=${data.smsType}&storageType=${data.smsStorage}`, body).then(
                    res => {
                        onCloseModal()
                    }
                )

                setsubmitLoading(false);
            }
        } catch (error) {
            setsubmitLoading(false);
            toast.error(error);
        }
    }

    return (
        <>
            {
                !loading ?
                    <>
                        <Formik
                            initialValues={{
                                selectedPublicLine: data.default,
                            }}
                            validationSchema={PublicLineEditModalSchema}
                            enableReinitialize={false}
                            onSubmit={(values, actions) => {
                                changePublicLineConfirm(values.selectedPublicLine);
                                actions.resetForm();
                            }}
                            onReset={(values, formikBag) => {
                                onCloseModal(false)
                            }}
                        >
                            {(formik) => (
                                <StyledForm
                                    onSubmit={formik.handleSubmit}
                                    onReset={formik.handleReset}>
                                    <StyledSelect
                                        formGroupClass=" col-12 px-0"
                                        lable="لیست خطوط ویژه"
                                        focus={true}
                                        name="selectedPublicLine"
                                        value={formik.values.selectedPublicLine}
                                        optionItems={optionList}
                                        hasDefaultOption
                                        error={(formik.errors.selectedPublicLine && formik.touched.selectedPublicLine)
                                            ? formik.errors.selectedPublicLine
                                            : 'false'
                                        }
                                        onChange={(e) => formik.setFieldValue("selectedPublicLine", e.target.value)}
                                    />
                                    <div className="d-flex justify-content-between">
                                        <StyledButton
                                            width="35%"
                                            btnStyle="grayBtn"
                                            type="reset"
                                            onClick={() => {
                                                onCloseModal(false)
                                            }}
                                        >
                                            بستن
                                        </StyledButton>
                                        <StyledButton
                                            width="35%"
                                            disabled={!formik.isValid}
                                            loading={submitLoading}
                                            btnStyle="greenBtn"
                                            type="submit"
                                        >
                                            تایید
                                        </StyledButton>
                                    </div>
                                </StyledForm>
                            )}
                        </Formik>
                    </>
                    :
                    <Loader/>
            }
        </>
    );
}

export default PublicLineEditModal
