import styled from "styled-components";
import {theme} from "../../Utility/Theme";

export const CheckBox = styled.div`
  margin-bottom: 0.5rem;

  label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  label input {
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: solid 1px ${theme.greenBtn};
    border-radius: 5px;
    appearance: none;
    margin-left: 8px;
  }

  label input:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: ${theme.greenBtn};
    position: relative;
    border-radius: 5px;
  }

  label input:disabled:checked {
    border: solid 1px #9ca8a7;
    background-color:${(props) => props.disabled === true && '#9ca8a7'};
  }

  label input:checked::after {
    content: "\\2714";
    position: absolute;
    color: white;
    top: 62%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 2px;
    font-size: 12px;
  }

  label input.null-checkBox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: ${theme.greenBtn};
    position: relative;
    border-radius: 5px;
  }

  label input.null-checkBox::after {
    content: " ";
    position: absolute;
    color: ${theme.white};
    top: 50%;
    -webkit-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 3px;
    background: #fff;
    width: calc(100% - 6px);
    height: 3px;
  }

  label span {
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    font-size: 16px;
  }

  label + label {
    margin-top: 15px;
  }

`
