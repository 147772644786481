import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { StyledButton } from '../../Component/Kit/Button';
import { HeaderContent, MainContent } from '../../Component/Kit/Layout';
import Modal from "../../Component/Kit/Modal";
import Table from '../../Component/Shared/Table/Table';
import ApiClient from '../../Requester/ApiClient';
import Urls from '../../Requester/Urls';
import AddBlacklistKeyword from "./add-blacklist-keyword";
import { SetDefaultBlackListKeyword } from './set-default-blacklist-keyword';

export const BlackList = () => {
    const [blacklistItem, setBlacklistItem] = useState({});
    const [isModal, setIsModal] = useState(false);
    const [defualtKeywordModal, setDefualtKeywordModal] = useState(false);

    const tableConfig = {
        header: {
            keyWord: 'کلمه کلیدی',
            type: 'نوع',
            keyWordDefault: 'پیشفرض'
        },
        actions: {
            delete: {
                icon: "fa fa-trash",
                condition: (data) => {
                    return (!data.isDefault)
                }
            },
        },
    }

    const tableActions = (item) => {
        if (item.actionName) {
            deleteKeyword(item.data.id)
        }
    }

    const deleteKeyword = (item) => {
        Swal.fire({
            title: '',
            text: `آیا از حذف این کلیدواژه مطمئن هستید؟`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "انصراف",
            cancelButtonColor: "#d33",
            confirmButtonText: "بله، مطمئن هستم",
        }).then(result => {
            if (result.isConfirmed) {

                ApiClient.Delete(Urls.deleteBlacklistKeyword(item))
                    .then(res => {
                        toast.success('تغییرات  با موفقیت ثبت شد.');
                        getBlackList()
                    })
            }
        })

    }

    const clickHandler = () => {
        setIsModal(true)
    }

    const setDefualtKeywordModalHandler = () => {
        setDefualtKeywordModal(true)
    }
    const getBlackList = () => {
        ApiClient.Get(Urls.getBlacklistKeyword())
            .then(res => {
                let resData = res?.result;
                resData = resData.map((el) => {
                    return {
                        ...el,
                        type: el.isCancellation ? 'لغو' : 'عضویت',
                        keyWordDefault: el.isDefault
                            ? `<i class="fa fa-check-circle text-success fa-lg"/>`
                            : `<i class="fa fa-times-circle text-danger fa-lg"/>`,
                    }
                })
                setBlacklistItem(resData)
            })
    }
    const modalDataChange = (e) => {
        if (e) {
            getBlackList();
        }
    }

    useEffect(() => {
        getBlackList()
    }, [])

    return (
        <>
            <HeaderContent>
                <StyledButton btnStyle="greenBtn" onClick={() => clickHandler()}>
                    ایجاد کلیدواژه
                </StyledButton>

                <StyledButton btnStyle="greenBtn ml-2" onClick={() => setDefualtKeywordModalHandler()}>
                    کلیدواژه پیشفرض
                </StyledButton>
            </HeaderContent>

            <MainContent>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h5 className="mb-3"> کلیدواژه‌های لیست سیاه</h5>
                        <span>
                            مدیریت و ایجاد کلیدواژه‌های پیشفرض از این صفحه امکان‌پذیر است.
                        </span>
                        <Table
                            data={blacklistItem}
                            config={tableConfig}
                            actionDispatched={tableActions}
                        />
                    </div>
                </div>
            </MainContent>
            <Modal
                onClose={() => setIsModal(false)}
                visible={isModal}
                onCancel={() => setIsModal(false)}
                headerTitle="افزودن کلیدواژه برای لیست سیاه"
            >
                <AddBlacklistKeyword onCloseModal={(e) => setIsModal(e)}
                    dataChange={(e) => modalDataChange(e)} />
            </Modal>

            <Modal
                onClose={() => setDefualtKeywordModal(false)}
                onCancel={() => setDefualtKeywordModal(false)}
                visible={defualtKeywordModal}
                headerTitle="تعیین کلیدواژه پیش‌فرض"
            >
                <SetDefaultBlackListKeyword onCloseModal={(e) => setDefualtKeywordModal(e)}
                    dataChange={(e) => modalDataChange(e)} keywordList={blacklistItem} />
            </Modal>
        </>
    )
}

export default BlackList
