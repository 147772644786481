import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { StyledButton } from "../../../../Component/Kit/Button";
import { PageTitleLayout } from "../../../../Component/Kit/Layout";
import Table from "../../../../Component/Shared/Table/Table";
import ApiClient from "../../../../Requester/ApiClient";
import Urls from "../../../../Requester/Urls";
import { ConvertQueryString } from "../../../../Utility/Services/convert-query-string";
import DateConvert from "../../../../Utility/Services/date-convert";
import { SeprateNumberWithComma } from "../../../../Utility/Services/SeprateNumberWithComma";
import {InvoiceNumberingGroupModal} from "../InvoiceAwaitingApproval/InvoiceNumberingGroupModal";

const OldPanelInvoiceAwaitingApproval = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { tableConfig } = props;
  const [deleteItems, setDeleteItem] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [invoiceAwaitingList, setInvoiceAwaitingList] = useState({});
  const [showNumberingGroupModal , setShowNumberingGroupModal] = useState(false)

  let localTableConfig = {
    ...tableConfig,
    actions: {
      ...tableConfig.actions,
      delete: {
        icon: "fa fa-trash",
      },
    },
    hasSelectCol: true,
  };

  const exportActions = (item) => {
    let params = {
      ...ConvertQueryString(location.search),
    };
    ApiClient.Get(`${Urls.GetOldPanelOfficialInvoices()}/excel`, {
      numbered: false,
      ...params,
    })
      .then((res) => {
        let resData = res.result;
        const url = `${Urls.OldPanelBase()}/${resData}`;
        windowOpen(url);
      })
      .catch((err) => console.log(err));
  };

  const windowOpen = (item) => {
    window.open(item);
  };

  const selectedHandler = (item) => {
    const list = [];
    item.forEach((f) => {
      list.push(f.id);
    });

    const formArray = []
    item.forEach(
        (el) => {
          formArray.push( {
            id: el.id,
            number: '',
            date: '0'
          })
        }
    )
    setSelectedInvoice(formArray);

    setDeleteItem(list);
  };

  const actionsHandler = (item) => {
    switch (item.actionName) {
      case "delete":
        deleteAll(item.data.id)
        break;
      default:
        history.push(`/financial/invoice/old/${item.data.id}`);
        break;
    }
  };

  const deleteAll = (id) => {
    Swal.fire({
      title: `آیا از حذف فاکتور ها مطمئن هستید؟`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonText: "انصراف",
      cancelButtonColor: "#d33",
      confirmButtonText: "بله، مطمئن هستم",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiClient.Delete(
          Urls.GetOldPanelOfficialInvoices(),
          id ? [id] : deleteItems
        ).then(() => {
          toast.success("خط  با موفقیت حذف شد.");
          onInit();
        });
      }
    });
  };

  const onInit = useCallback(() => {
    let params = {
      ...ConvertQueryString(location.search),
      numbered: false,
    };

    ApiClient.Get(Urls.GetOldPanelOfficialInvoices(), params).then((res) => {
      let responseData = res.result;
      responseData.items.map((item, i) => {
        item.totalPrice = `${SeprateNumberWithComma(item.totalPrice)} ریال`;
        item.createdAt = DateConvert(item.createdAt);
        item.canModifyDiscountPrice = item.canModifyDiscountPrice
          ? `<span class="grass-green-text">دارد</span>`
          : `<span class="red-text">ندارد</span>`;
        item.isManualPayment = item.isManualPayment
          ? `<span class="grass-green-text">دارد</span>`
          : `<span class="red-text">ندارد</span>`;
      });
      setInvoiceAwaitingList(responseData);
    });
  }, [location.search]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <PageTitleLayout className="mt-4">
          <h5>لیست فاکتورهای بدون شماره (پنل قدیم)</h5>
          <span>
            در این بخش لیستی از فاکتور‌هایی که نیاز به شماره دارند در پنل قدیم،
            قابل مشاهده است.
          </span>
        </PageTitleLayout>
        <div className="d-flex">
          <StyledButton
              btnStyle="redBtn"
              disabled={deleteItems.length === 0}
              onClick={() => deleteAll()}
          >
            حذف موارد انتخاب شده
          </StyledButton>

          <StyledButton
              btnStyle='greenBtn mr-3'
              onClick={() => {
                setShowNumberingGroupModal(true)
              }}
              disabled={!selectedInvoice.length}
          >
            شماره دار کردن گروهی فاکتور ها
          </StyledButton>
        </div>
      </div>
      {invoiceAwaitingList && (
        <Table
          data={invoiceAwaitingList.items}
          config={localTableConfig}
          actionDispatched={(e) => actionsHandler(e)}
          exportDispatched={exportActions}
          pageConfig={{
            pageNumber: invoiceAwaitingList.pageNumber,
            pageSize: invoiceAwaitingList.pageSize,
            totalItemsCount: invoiceAwaitingList.totalItemsCount,
            totalPagesCount: invoiceAwaitingList.totalPagesCount,
          }}
          selectedDispatch={(e) => selectedHandler(e)}
        />
      )}

      <InvoiceNumberingGroupModal
          visible={showNumberingGroupModal}
          onCancel={() => setShowNumberingGroupModal(false)}
          closeModal={() => setShowNumberingGroupModal(false)}
          submit={() => {
            setShowNumberingGroupModal(false)
            onInit()
          }}
          isOldPanel={true}
          selectedInvoice={selectedInvoice}
      />
    </div>
  );
};

export default OldPanelInvoiceAwaitingApproval;
