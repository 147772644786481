import React, { useEffect } from 'react'
import { SeprateNumberWithComma } from "../../../../Utility/Services/SeprateNumberWithComma";
import persianJs from "persianjs";
import {connect} from "react-redux";
import bootstrapper from "../../../../Reducers/bootstrapper";

const TransactionTable = (props) => {
    const { singleInvoiceDetails, bootstrapper, vatPercent } = props;

    const officialInvoiceName = {
        number: "شماره فاکتور",
        creationDateTime: "تاریخ ایجاد",
        type: "نوع فاکتور",
        description: "شرح کالا",
        count: "تعداد/مقدار",
        basePrice: "مبلغ واحد",
        finalPrice: "مبلغ کل ",
        discountPrice: "مبلغ تخفیف ",
        totalPriceAfterDiscount: "مبلغ کل پس از تخفیف ",
        vatPercent: "مالیات ",
        totalPriceAfterVatAndDiscount: "جمع مبلغ کل بعلاوه مالیات و عوارض ",
        fifteenRemarkPrice: "تبصره 6 قانون بودجه",
    };

    useEffect(() => {
    }, [singleInvoiceDetails])


    return (
        <div className="table-responsive">
            <table className="factor-table">
                <thead>
                    <tr className="gray-bg">
                        <td colSpan="8">مشخصات کالا یا خدمات موردمعامله</td>
                    </tr>

                    {officialInvoiceName && (
                        <tr className="gray-bg fo-13">
                            <td>{officialInvoiceName.description}</td>
                            <td>{officialInvoiceName.count}</td>
                            <td>{officialInvoiceName.basePrice}</td>
                            <td>{officialInvoiceName.finalPrice}</td>
                            <td>{officialInvoiceName.discountPrice}</td>
                            <td>{officialInvoiceName.totalPriceAfterDiscount}</td>
                            <td>%{vatPercent} {officialInvoiceName.vatPercent}</td>
                            <td>{officialInvoiceName.totalPriceAfterVatAndDiscount}</td>
                        </tr>
                    )}
                </thead>
                <tbody>

                {singleInvoiceDetails && singleInvoiceDetails?.details?.map((el , i) => (
                        <tr className="fo-13" key={i}>
                            <td>{el.description}</td>
                            <td>{el.count}</td>
                            <td>{SeprateNumberWithComma(el.basePrice)} <small>ريال</small></td>
                            <td>{SeprateNumberWithComma(el.netPrice)} <small>ريال</small></td>
                            <td>{SeprateNumberWithComma(el.discountPrice)} <small>ريال</small></td>
                            <td> {SeprateNumberWithComma(el.priceAfterDiscount)} <small>ريال</small></td>
                            <td>
                                {SeprateNumberWithComma(el.vatPrice)} <small>ريال</small>
                            </td>
                            <td>{SeprateNumberWithComma(el.finalPrice)} <small>ريال</small></td>
                        </tr>
                    ))

                }
                    {singleInvoiceDetails && (
                        <tr className="fo-13 ng-star-inserted">
                            <td colSpan="7">تبصره 6 قانون بودجه</td>
                            <td>{SeprateNumberWithComma(singleInvoiceDetails.fifteenRemarkPrice)} <small>ريال</small></td>
                        </tr>
                    )}


                    {singleInvoiceDetails.totalPrice && (
                        <tr className="fo-13">
                            <td colSpan="7">
                                جمع کل:  &nbsp;
                                {
                                    (singleInvoiceDetails.totalPrice >= 0) &&
                                    persianJs(`${singleInvoiceDetails.totalPrice}`).digitsToWords().toString()
                                }
                                &nbsp;ریال
                            </td>
                            <td>{SeprateNumberWithComma(singleInvoiceDetails.totalPrice)} <small>ريال</small></td>
                        </tr>
                    )}

                </tbody>
            </table>
        </div>
    )
}
const mapStateToProps = (state) => ({
    bootstrapper: state.bootstrapper,
});

export default connect(mapStateToProps)(TransactionTable);
