import React, { useCallback, useEffect, useState } from 'react';
import ApiClient from '../../../Requester/ApiClient';
import Urls from '../../../Requester/Urls';
import Table from '../../../Component/Shared/Table/Table';
import {
  HeaderContent,
  PageTitleLayout,
  WrapperLayout,
} from '../../../Component/Kit/Layout';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledButton } from '../../../Component/Kit/Button';
import moment from 'moment-jalaali';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

let interval;

function RepeatVerifySMs() {
  let history = useHistory();
  let location = useLocation();
  const [pendingData, setpendingData] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [refreshDate, setRefreshDate] = useState(moment().format('HH:mm:ss'));
  const intervalTime = 90000;

  const tableConfig = {
    header: {
      userName: 'نام کاربر',
      mobile: 'شماره موبایل',
      totalSentCount: 'تعداد کل ارسال های انجام شده',
      pendingsCount: 'تعداد ارسال های در حال انتظار',
    },

    tooltipTitle: ['messageText'],
    actions: {
      view: {
        icon: 'fa fa-eye',
      },
    },
  };
  const tableActions = (item) => {
    if (item.actionName === 'view') {
      history.push(`/high-consumption-webservice/${item.data.userId}?onlyPendings=true`);
    }
  };

  const getSMSQueue = useCallback(() => {

    ApiClient.Get(Urls.highConsumptionVerify()).then((res) => {
      let data = res.result;
      setRefreshDate(moment().format('HH:mm:ss'))
      setpendingData(data);
    });
  }, []);

  const updateHandler = () => {
    getSMSQueue();
    setLoadingFlag(true);
    setTimeout(() => {
      setLoadingFlag(false);
    }, 30000);
  };

  const queueSmsHandler = async (item, userId) => {
    try {
      let result = await Swal.fire({
        title: '',
        text: `آیا از ${
            item ? 'تایید' : 'عدم تایید'
        } این متن اطمینان دارید؟`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonText: 'انصراف',
        cancelButtonColor: '#d33',
        confirmButtonText: 'بله، مطمئن هستم',
      });
      if (result.isConfirmed) {
        await ApiClient.Patch(Urls.setFrequentMessageValidation(userId), JSON.stringify(item));
        toast.success('تغییرات  با موفقیت ثبت شد.');
        getSMSQueue();
        autoRefresh();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const autoRefresh = useCallback(() => {
    interval = setInterval(() => {
      getSMSQueue();
    }, intervalTime);
  }, [getSMSQueue]);

  useEffect(() => {
    getSMSQueue();
    autoRefresh();
    return () => clearInterval(interval);
  }, [autoRefresh, getSMSQueue]);

  return (
      <WrapperLayout>
        <div className='c-wrapper'>
          <HeaderContent className='d-flex flex-row-reverse justify-content-between align-items-center'>
            <div className='d-flex flex-row-reverse align-items-center'>
              <StyledButton
                  btnStyle='greenBtn'
                  onClick={() => updateHandler()}
                  disabled={loadingFlag}
              >
                بروزرسانی
              </StyledButton>
              <div className='d-flex mr-3 rtl'>
                <span className='fo-12 mr-2'>آخرین بروزرسانی: </span>
                {/* <span>{new Date().toLocaleTimeString()}</span> */}
                <span>{refreshDate}</span>
              </div>
            </div>
          </HeaderContent>
          <div className="p-4">
            <PageTitleLayout>
              <h5> محدودیت ارسال وب سرویس </h5>
              <span>
              دراین بخش می‌توانید کاربرانی که بیش از حد مجاز سامانه ارسال وب سرویس انجام دادند را مشاهده کنید.
            </span>
            </PageTitleLayout>
            <Table
                data={pendingData}
                config={tableConfig}
                actionDispatched={tableActions}
            />
          </div>
        </div>
      </WrapperLayout>
  );
}

export default RepeatVerifySMs;
