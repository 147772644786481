import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {NavLink, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {MainContent} from "../../Component/Kit/Layout";
import ApiClient from "../../Requester/ApiClient";
import Urls from "../../Requester/Urls";
import SettingSwitchRow from "./Components/switch-row";
import ABTestMinimumContact from "./limitation/abtest-minimum-contact";
import ContactGroupLimitationCount from "./limitation/contact-group";
import FastSendTemplateAddLimitation from "./limitation/fast-send-template-add";
import RecipientLimitationCountInPerSendSms from "./limitation/recipient-limitation-count-in-per-send-sms";
import RecipientLimitationCountInPerWebService from "./limitation/RecipientLimitationCountInPerWebService";
import UserEventLimitationCount from "./limitation/user-event";
import UserTemplateLimitationCount from "./limitation/user-template";
import {WarningLakeCredit} from "./limitation/WarningLakeCredit";
import FifteenRemarkCoefficient from "./other/fifteen-remark-coefficient";
import ManageBlacklist from "./other/manage-blacklist";
import ReactionToUserTicket from "./other/reaction-to-user-ticket";
import CooperationWithdrawalAmount from "./limitation/CooperationWithdrawalAmountLimit";
import CooperationCreditProfitPercent from "./limitation/CooperationCreditProfitPercent";
import CooperationLineProfitPercent from "./limitation/CooperationLineProfitPercent";
import ManageSends from "./other/mange-sends";
import CodeReaderWrongMessage from "./other/code-reader-wrong-message";
import CodeReaderDuplicateMessage from "./other/code-reader-duplicate-message";
import PurchaseRestrictions from "./limitation/PurchaseRestrictions";
import CalledWebserviceCount from "./limitation/CalledWebserviceCount";
import BanksSetting from "./banks";
import AllowTotalSmsSendInADay from "./limitation/AllowedTotalSmsSentInADay";
import SendSmsViaTowerLimitations from "./limitation/SendSmsViaTowerLimitations";
import PublicLineSendLimitations from "./limitation/PublicLineSendLimitations";
import ModifyOffKeywordSettingByProvider from "./other/modify-off-keyword-provider";
import SendSmsViaTowerOperatorConfigurations from "./other/tower-operator-configuration";
import PublicLineCostCoefficient from "./other/public-line-cost";
import VatPercent from "./other/vat-percent";
import SendSmsTypeActivationStatus from "./other/send-sms-type";
import UserTagAddLimitationCount from "./limitation/UserTagAddLimitationCount";

function SettingList({modalChange, match}) {
    const [settingsData, setSettingsData] = useState({})
    const history = useHistory();
    const location = useLocation();

    const getAllSettings = () => {
        ApiClient.Get(Urls.getSettingList()).then(res => {
            setSettingsData(res?.result)
        })
    }

    useEffect(() => {
        if (location.pathname === `${match.url}`) {
            history.push(`${match.url}/limitation`)
        }
        getAllSettings();
    }, [modalChange, history, match.url])

    return (
        <>
            <MainContent>

                <ul className="user-tab-menu">
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/limitation`}>
                            محدودیت ها
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/banks`}>
                            مدیریت بانک ها
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="is-active" to={`${match.url}/other`}>
                            موارد دیگر
                        </NavLink>
                    </li>
                </ul>
                <div className="p-3">
                    <Switch>
                        <Route exact path={`${match.path}/limitation`}>
                            <ul className="settings-list">
                                <li className="settings-list-item">
                                    <ContactGroupLimitationCount data={settingsData.ContactGroupLimitationCount}/>
                                </li>
                                <li className="settings-list-item">
                                    <UserEventLimitationCount data={settingsData.UserEventLimitationCount}/>
                                </li>
                                <li className="settings-list-item">
                                    <UserTemplateLimitationCount data={settingsData.UserTemplateLimitationCount}/>
                                </li>
                                <li className="settings-list-item">
                                    <FastSendTemplateAddLimitation
                                        data={settingsData.FastSendTemplateAddLimitationCount}/>
                                </li>
                                <li className="settings-list-item">
                                    <RecipientLimitationCountInPerSendSms
                                        data={settingsData.RecipientLimitationCountInPerSendSms}/>
                                </li>
                                <li className="settings-list-item">
                                    <ABTestMinimumContact
                                        data={settingsData.ABTestMinimumImportedContacts}/>
                                </li>
                                <li className="settings-list-item">
                                    <CooperationWithdrawalAmount
                                        data={settingsData.MinimumCooperationWithdrawalAmount}/>
                                </li>

                                <li className="settings-list-item">
                                    <CooperationCreditProfitPercent
                                        data={settingsData.CooperationCreditProfitPercent}/>
                                </li>

                                <li className="settings-list-item">
                                    <CooperationLineProfitPercent
                                        data={settingsData.CooperationLineProfitPercent}/>
                                </li>
                                <li className="settings-list-item">
                                    <WarningLakeCredit
                                        data={settingsData.PublicLackOfCreditWarningThreshold}/>
                                </li>
                                <li className="settings-list-item">
                                    <RecipientLimitationCountInPerWebService
                                        data={settingsData.RecipientLimitationCountInApiSend}/>
                                </li>
                                <li className="settings-list-item">
                                    <PurchaseRestrictions
                                        data={settingsData.PaymentLimitations}/>
                                </li>
                                <li className="settings-list-item">
                                    <CalledWebserviceCount
                                        data={settingsData.CalledWebServiceCount}/>
                                </li>
                                <li className="settings-list-item">
                                    <AllowTotalSmsSendInADay data={settingsData.AllowedTotalSmsSentInADay}/>
                                </li>
                                <li className="settings-list-item">
                                    <SendSmsViaTowerLimitations data={settingsData.SendSmsViaTowerLimitations}/>
                                </li>
                                <li className="settings-list-item">
                                    <PublicLineSendLimitations data={settingsData.PublicLineSendLimitations}/>
                                </li>
                                <li className="settings-list-item">
                                    <UserTagAddLimitationCount data={settingsData.UserTagAddLimitationCount}/>
                                </li>


                            </ul>
                        </Route>
                        <Route path={`${match.path}/other`}>
                            <ul className="settings-list">
                                <li className="settings-list-item">
                                    <FifteenRemarkCoefficient data={settingsData.FifteenRemarkCoefficient}/>
                                </li>
                                <li className="settings-list-item">
                                    <ReactionToUserTicket data={settingsData.ReactionToUserTicketType}/>
                                </li>
                                <li className="settings-list-item">
                                    <ManageBlacklist data={settingsData.SendSmsTypesAndBlackListNecessity}/>
                                </li>
                                <li className="settings-list-item">
                                    <ManageSends data={settingsData.SendSmsLimitationSetting}/>
                                </li>
                                <li className="settings-list-item">
                                    <SettingSwitchRow data={settingsData.ShouldCheckLinkInSmsContent}
                                                      url={Urls.linkUrlLimitationInSmsContent()}
                                                      title="محدودیت ارسال لینک :"/>
                                </li>
                                <li className="settings-list-item">
                                    <SettingSwitchRow data={settingsData.IsCooperationActive}
                                                      url={Urls.saleCooperationActivation()}
                                                      title="بخش همکاری در فروش :"/>
                                </li>
                                <li className="settings-list-item">
                                    <SettingSwitchRow data={settingsData.IsRegisterReceiptSepidarActive}
                                                      url={Urls.registerSepidarActivation()}
                                                      title="ثبت رسید دریافت در سپیدار :"/>
                                </li>
                                <li className="settings-list-item">
                                    <CodeReaderWrongMessage data={settingsData.GlobalCodeReaderWrongParameterMessage}/>
                                </li>
                                <li className="settings-list-item">
                                    <CodeReaderDuplicateMessage
                                        data={settingsData.GlobalCodeReaderDuplicateParameterMessage}/>
                                </li>
                                <li className="settings-list-item">
                                    <SettingSwitchRow data={settingsData.SendViaPublicLinesIsActivated}
                                                      url={Urls.publicLineActivation()}
                                                      title="ارسال با خطوط ویژه :"/>
                                </li>

                                <li className="settings-list-item">
                                    <ModifyOffKeywordSettingByProvider
                                        data={settingsData.OffKeywordSettingByProvider}/>
                                </li>

                                <li className="settings-list-item">
                                    <SendSmsViaTowerOperatorConfigurations data={settingsData.SendSmsViaTowerOperatorConfigurations}/>
                                </li>

                                <li className="settings-list-item">
                                    <PublicLineCostCoefficient data={settingsData.PublicLineCostCoefficient}/>
                                </li>

                                <li className="settings-list-item">
                                    <VatPercent data={settingsData.VatPercent}/>
                                </li>

                                <li className="settings-list-item">
                                    <SendSmsTypeActivationStatus data={settingsData.SendSmsTypeActivationStatus}/>
                                </li>


                            </ul>
                        </Route>
                        <Route path={`${match.path}/banks`}>
                            <BanksSetting/>
                        </Route>

                    </Switch>
                </div>
            </MainContent>
        </>
    )
}

const mapStateToProps = (state) => ({
    modalChange: state.modalChange,
});
export default connect(mapStateToProps)(SettingList);
