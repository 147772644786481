import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import Table from "./../../../../../Component/Shared/Table/Table";
import ApiClient from "./../../../../../Requester/ApiClient";
import Urls from "./../../../../../Requester/Urls";
import { HeaderContent, MainContent, PageTitleLayout } from "../../../../../Component/Kit/Layout";
import DateConvert from "../../../../../Utility/Services/date-convert";
import { InvoiceType } from "../../../../../Utility/Services/invoice";
import BackToPage from "../../../../../Component/Kit/back";
import { ConvertQueryString } from "../../../../../Utility/Services/convert-query-string";

function UserInvoice() {
    let match = useRouteMatch();
    let history = useHistory();
    let location = useLocation();
    const [officialInvoiceData, setofficialInvoiceData] = useState({});
    const tableConfig = {
        header: {
            id: "شماره فاکتور",
            typeName: "نوع فاکتور",
            createdAt: "تاریخ و ساعت",
            totalPrice: "مبلغ کل",
            isVerifiedTitle: 'وضعیت',
        },
        actions: {
            view: {
                icon: "fa fa-eye",
            },
        },
        mode: ["filter", "search"],
        filters: ["datePickerFilter", "InvoiceTypeFilter", "isVerifiedFilter"],
    };
    const tableActions = (item) => {
        if (item.actionName === 'view') {
            if (item.data.type === 1) history.push(`/financial/invoice/${item.data.id}/false`)
            else if (item.data.type === 2) history.push(`/financial/invoice/${item.data.id}/true`)
        }
    }
    useEffect(() => {
        ApiClient.Get(Urls.userOfficialInvoice(match.params.id), ConvertQueryString(location.search))
            .then((res) => {
                let data = res.result;
                data.items.map((m) => {
                    m.createdAt = DateConvert(m.createdAt);
                    m.totalPrice = m.totalPrice.toLocaleString();
                    m.typeName = InvoiceType(m.type);
                    m.isVerifiedTitle = m.isVerified ? 'تایید شده' : 'تایید نشده'
                    return m
                });
                setofficialInvoiceData(data);
            });
    }, [match.params.id, location.search]);
    return (
        <>
            <HeaderContent className="justify-content-end">
                <BackToPage toPage={`/userDetail/${match.params.id}/financial`} />
            </HeaderContent>
            <MainContent>
                <PageTitleLayout>
                    <h5>فاکتورها</h5>
                    <span>
                        در اینجا لیست فاکتورهای این کاربر مورد بررسی قرار می‌گیرد.
                    </span>
                </PageTitleLayout>

                <Table
                    data={officialInvoiceData.items}
                    config={tableConfig}
                    pageConfig={{
                        pageNumber: officialInvoiceData.pageNumber,
                        pageSize: officialInvoiceData.pageSize,
                        totalItemsCount: officialInvoiceData.totalItemsCount,
                        totalPagesCount: officialInvoiceData.totalPagesCount,
                    }}
                    actionDispatched={tableActions}
                />
            </MainContent>
        </>
    );
}

export default UserInvoice;
