import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { StyledButton } from '../../../../Component/Kit/Button';
import { PageTitleLayout } from '../../../../Component/Kit/Layout';
import ApiClient from '../../../../Requester/ApiClient';
import Urls from '../../../../Requester/Urls';
import Modal from './../../../../Component/Kit/Modal';
import Table from './../../../../Component/Shared/Table/Table';
import DateConvert from './../../../../Utility/Services/date-convert';
import AddGuestCommentModal from './../AddGuestCommentModal/index';
import GuestCommentDetailModal from './comment-detail-modal';

const GuestCommentsList = () => {
    const match = useRouteMatch();
    const id = match.params.id;
    const [guestCommentsData, setGuestCommentsData] = useState({});
    const [guestCommentModal, setGuestCommentModal] = useState(false);
    const [guestAddCommentModal, setAddGuestCommentModal] = useState(false);
    const [commentModalData, setCommentModalData] = useState();

    const tableConfig = {
        header: {
            adminUserName: "نام‌کاربری ادمین",
            content: "توضیحات",
            createdAt: "تاریخ ایجاد",
        },
        actions: {
            view: {
                icon: "fa fa-eye"
            },
        }
    };

    function tableActions(item) {
        if (item.actionName === 'view') {
            setCommentModalData(item.data);
            setGuestCommentModal(true);
        }
    }

    const modalChangeHandler = (event) => {
        if (event) {
            getGuestComments();
        }
    };

    const getGuestComments = useCallback(() => {
        ApiClient.Get(Urls.GuestComment(id))
            .then(res => {
                res.result.map((item) => {
                    item.createdAt = DateConvert(item.createdAt);
                })
                setGuestCommentsData(res.result)
            })
    }, [])


    useEffect(() => {
        getGuestComments();
    }, [id, getGuestComments])

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <PageTitleLayout className="mt-4">
                    <h5>کامنت‌های کاربر مهمان</h5>
                    <span>در اینجا لیستی از کامنت‌هایی که هنگام تایید کاربر، برای کاربر مهمان ثبت شد‌اند، مورد بررسی قرار می‌گیرد.</span>
                </PageTitleLayout>
                <StyledButton
                    btnStyle="greenBtn"
                    onClick={() => setAddGuestCommentModal(true)}
                >افزودن کامنت
                </StyledButton>
            </div>

            <Table
                data={guestCommentsData}
                config={tableConfig}
                actionDispatched={tableActions}
            />

            <Modal
                onClose={() => setGuestCommentModal(false)}
                onCancel={() => setGuestCommentModal(false)}
                visible={guestCommentModal}
                headerTitle="کامنت ادمین برای کاربر مهمان"
            >
                <GuestCommentDetailModal commentData={commentModalData}
                    onCloseModal={(e) => setGuestCommentModal(e)} />
            </Modal>

            <Modal
                onClose={() => setAddGuestCommentModal(false)}
                onCancel={() => setAddGuestCommentModal(false)}
                visible={guestAddCommentModal}
                headerTitle="افزودن کامنت برای کاربر مهمان"
            >
                <AddGuestCommentModal onCloseModal={e => setAddGuestCommentModal(e)} dataChange={modalChangeHandler} />
            </Modal>

        </>
    );
}

export default GuestCommentsList